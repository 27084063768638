import React, { useState, useEffect } from "react";
import axios from "axios";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import Board from "./Dnd";

import * as ROUTES from "../../constants/routes";

import {
  getData,
  CalendarPicker,
  ListDates,
  formatDate,
  AlertDialog
} from "../../components";
import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: `100%`,

    padding: "0 2em",
  },
  paper: {
    padding: "2em",
    margin: "1em auto",
    borderRadius: "1em",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Configuration de la catégorie",
    "Sélection des dates",
    "Configuration des teams",
  ];
}

function Championnat(props) {
  const classes = useStyles();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState("vet");
  const [postData, setPostData] = useState(undefined);
  const [isEnd, setIsEnd] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [dates, setDates] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [nbEquipes, setNbEquipes] = useState([16,16,16,16,16]);
  const steps = getSteps();
  const history = useHistory();

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  useEffect(() => {
    getData(setData, "teams");
  }, [id, setData, setLoading]);

  const handleChangePostData = (postData) => {
    let data = { ...postData };
    delete data["À ignorer"];
    let nb_conflicts = 0;
    let k = 0;
    if (data) {
      for (let i in data) {
        let equipes = data[i];
        if (Number(equipes.length) !== Number(nbEquipes[k])) {
          nb_conflicts++;
        }
        for (let j in equipes) {
          if (
            Number(equipes[j]?.serial_num) ===
              Number(equipes[j + 1]?.serial_num) ||
            Number(equipes[j - 1]?.serial_num) ===
              Number(equipes[j]?.serial_num)
          ) {
            nb_conflicts++;
          }
        }
        k++;
      }
    }
    console.log("Conflits ?", nb_conflicts);
    if (nb_conflicts >= 1) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    setPostData(data);
    console.log(data);
  };

  const handleNext = () => {
    let newStep = activeStep + 1;
    setActiveStep(newStep);
    if (newStep === steps.length - 1) {
      setIsEnd(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const popupChampionnat = () => {
    setShowDialog(true);
  }

  const postChampionnat = () => {
    let data = { ...postData };
    let json_dates = [];
    for (let i = 0; i <= dates.length; i++) {
      if (dates[i]) {
        let new_date = formatDate(dates[i]);
        json_dates.push(new_date);
      }
    }
    let json = { data, dates: json_dates, type };
    console.log("json :", json);
    axios
      .post(process.env.REACT_APP_API_URL + "rencontres/championnat", json, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        localStorage.removeItem("rencontres_latest_update");
        let data = r.data;
        console.log(data);
        if (r.status === 200) {
          toast.success(data);
          setTimeout(function () {
            history.push(ROUTES.RENCONTRES);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("erreur", error?.response);
        toast.error(error?.response?.data ? error?.response?.data: "Problème lors de la génération du championnat...");
      });
  };

  const handleChangeType = (e) => {
    const value = e.target.value;
    setType(value);
    setDates([]);
  };

  {
    /*const handleChangeNbEquipes = (e) => {
    const value = e.target.value;
    setNbEquipes(value);
  };*/
  }

  return (
    <div className={classes.root}>
    <AlertDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogText={"Êtes-vous sûr de vouloir générer ce championnat ?"}
        dialogTitle={"Générer un championnat"}
        primary_action={"Générer"}
        secondary_action={"Annuler"}
        handlePrimaryAction={() => {
          postChampionnat();
        }}
        handleSecondaryAction={() => setShowDialog(false)}
      />
      {/*<CardHeader addTitle={"Ajouter un championnat"} />*/}
      <Paper className={classes.paper}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {activeStep === 0 && (
                  <div style={{ margin: "1em" }}>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "3em" }}
                    >
                      <FormLabel component="legend">Catégorie</FormLabel>
                      <RadioGroup
                        aria-label="type"
                        name="type1"
                        value={type}
                        onChange={handleChangeType}
                      >
                        <FormControlLabel
                          value="vet"
                          control={<Radio />}
                          label="Vétéran"
                        />
                        <FormControlLabel
                          value="dim"
                          control={<Radio />}
                          label="Dimanche"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {activeStep === 1 && (
                  <div style={{ maxWidth: 600 }}>
                    <ListDates dates={dates} setDates={setDates} type={type} />
                    <CalendarPicker
                      dates={dates}
                      setDates={setDates}
                      type={type}
                    />
                  </div>
                )}
                {activeStep === 2 && (
                  <Board
                    type={type}
                    isEnd={isEnd}
                    dates={dates}
                    nbEquipes={nbEquipes}
                    setNbEquipes={setNbEquipes}
                    handleChangePostData={handleChangePostData}
                  />
                )}
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Précédent
                    </Button>
                    <Button
                      variant="contained"
                      disabled={
                        (isDisabled && activeStep === 2) ||
                        (dates.length < 28 && activeStep !== 0)
                      }
                      color="primary"
                      onClick={
                        activeStep === steps.length - 1
                          ? popupChampionnat
                          : handleNext
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Générer" : "Suivant"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </div>
  );
}

export default Championnat;
