import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import Column from "./column";
import reorder, { reorderEquipeMap } from "./reorder";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { list_divisions, getByDivision, divisions } from "./data_";
import Loading from "../../Loading";
import { Button } from "@material-ui/core";
import toast from "react-hot-toast";

const Container = styled.div`
  display: inline-flex;
`;

export default class Board extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: {},
      ordered: {},
      refresh: false,
      isLoading: true,
      nb_bye: 0,
    };
  }
  componentDidMount() {
    const { type, handleChangePostData } = this.props;
    const equipes = [];
    axios
      .get(process.env.REACT_APP_API_URL + "teams/active", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        console.log("data", data);
        for (let i in data) {
          let club_name = data[i]?.equipe?.club?.name;
          let play_time = data[i]?.play_time;
          let division = data[i]?.division;
          let serial_number = data[i]?.serial_number;
          let index = data[i]?.index;
          let team_id = data[i]?.id;
          let equipe_id = data[i]?.equipe_id;
          if (
            type === "dim" &&
            (division === "Dimanche 1A" ||
              division === "Dimanche 1B" ||
              division === "Dimanche Honneur")
          ) {
            equipes.push({
              serial_num: serial_number,
              play_time: play_time,
              index: index,
              content: club_name,
              division: list_divisions[division].name,
              id: equipes.length.toString(),
              team_id: team_id,
              equipe_id: equipe_id,
            });
          } else if (
            type === "vet" &&
            (division === "Vétérans 1" ||
              division === "Vétérans 2" ||
              division === "Vétérans 3" ||
              division === "Vétérans Honneur")
          ) {
            equipes.push({
              serial_num: serial_number && serial_number.toString(),
              index: index,
              play_time: play_time,
              content: club_name,
              division: list_divisions[division].name,
              id: equipes.length.toString(),
              team_id: team_id,
              equipe_id: equipe_id,
            });
          }
        }
        let _divisions = [];
        if (type === "vet") {
          _divisions = [
            list_divisions["Vétérans 1"],
            list_divisions["Vétérans 2"],
            list_divisions["Vétérans 3"],
            list_divisions["Vétérans Honneur"],
            list_divisions["À ignorer"],
          ];
        } else {
          _divisions = [
            list_divisions["Dimanche Honneur"],
            list_divisions["Dimanche 1A"],
            list_divisions["Dimanche 1B"],
            list_divisions["À ignorer"],
          ];
        }
        let columns = _divisions.reduce((previous, division) => {
          return {
            ...previous,
            [division.name]: getByDivision(division, equipes),
          };
        }, {});
        for (let i in columns) {
          columns[i].sort((a, b) =>
            Number(a.serial_num) > Number(b.serial_num)
              ? 1
              : Number(b.serial_num) > Number(a.serial_num)
              ? -1
              : 0
          );
        }
        this.setState(
          {
            columns,
            ordered: Object.keys(columns),
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
        handleChangePostData(columns);
      })
      .catch((e) => {
        console.log(e);
      });
  }
  onDragEnd = (result) => {
    const { columns, ordered } = this.state;
    const { handleChangePostData } = this.props;
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        this.setState({
          shallow,
        });
        return;
      }
      if (columns) {
        const column = columns[result.source.droppableId];
        const withEquipeRemoved = [...column];
        withEquipeRemoved.splice(result.source.index, 1);
        const _columns = {
          ...columns,
          [result.source.droppableId]: withEquipeRemoved,
        };
        this.setState({ columns: _columns });
        handleChangePostData(_columns);
      }
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const _ordered = reorder(ordered, source.index, destination.index);
      this.setState({ ordered: _ordered });
      return;
    }
    const data = reorderEquipeMap({
      equipeMap: columns,
      source,
      destination,
    });
    this.setState({ columns: data.equipeMap });
    handleChangePostData(data.equipeMap);
  };

  addBye = (e, index) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { columns, nb_bye } = this.state;
    const { handleChangePostData, type } = this.props;
    let _col = columns;
    let col_lg = Object.values(_col).length;
    let lg_tot = 0;
    for (let i = 0; i < col_lg; i++) {
      lg_tot += Object.values(_col)[Number(i)].length;
    }
    lg_tot += 99;
    let _nb_bye = nb_bye + 1;
    let letter = null;
    if (type === "vet") {
      letter = String.fromCharCode(64 + _nb_bye);
    }
    let bye_name = "BYE " + (letter ? letter : _nb_bye);
    Object.values(_col)[Number(index)].push({
      id: lg_tot.toString(),
      index: _nb_bye.toString(),
      content: bye_name,
      division: Object.keys(_col)[Number(index)],
      serial_num: "",
      equipe_id: 0,
      team_id: 0,
      bye_index: letter ? letter : _nb_bye,
      bye: 1,
    });
    this.setState({ columns: _col, nb_bye: _nb_bye }, () => {
      this.setState({
        isLoading: false,
      });
    });
    handleChangePostData(_col);
  };

  handleSubmitPlayTime = (e, id, name) => {
    e.preventDefault();
    console.log(e, id, name);
    this.setState({ isLoading: true });
    const { handleChangePostData } = this.props;
    const { columns } = this.state;
    let val = e.target[0].value;
    let _col = columns;
    let id_col = _col[name].findIndex((e) => e.id === id);
    let key = name;
    _col[key][id_col].play_time = val;
    this.setState(
      {
        columns: _col,
      },
      () => this.setState({ isLoading: false })
    );
    handleChangePostData(_col);
    let team_id = _col[key][id_col]?.team_id;
    if (team_id) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            `team/update/${team_id}/play_time/${val}`,
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          console.log(response);
          toast.success("Heure de rencontre correctement mis à jour", {
            duration: 4000,
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            "Problème lors de la sauvegarde de l'heure de rencontre",
            { duration: 4000 }
          );
        });
    }
  };

  removeColumn = (name) => {
    const { handleChangePostData } = this.props;
    const { columns } = this.state;
    this.setState({ isLoading: true });
    let _col = columns;
    delete _col[name];
    console.log(_col, name);
    this.setState(
      {
        columns: _col,
      },
      () => this.setState({ isLoading: false })
    );
    handleChangePostData(_col);
  };

  handleChangeNb = (value, index) => {
    this.setState({ isLoading: true });
    const { handleChangePostData, nbEquipes, setNbEquipes } = this.props;
    const { columns } = this.state;
    let _col = columns;
    let _nbEquipes = nbEquipes;
    _nbEquipes[index] = value;
    setNbEquipes(_nbEquipes);
    this.setState({ columns: _col }, () => {
      handleChangePostData(_col);
      this.setState({
        isLoading: false,
      });
    });
  };

  handleSubmit = (e, id, name) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { columns } = this.state;
    let _col = columns;
    const { handleChangePostData } = this.props;
    let team_ids = [];
    let id_col = _col[name].findIndex((e) => e.id === id);
    let club_name = _col[name][id_col].content;
    let val = e.target[0].value;

    console.log(e, id, name, id_col);

    // CHANGE ALL NUM. SERIE FOR SAME CLUBS

    for (let name of Object.keys(_col)) {
      for (let id_col in _col[name]) {
        if (_col[name][id_col].content === club_name) {
          _col[name][id_col].serial_num = val;
          let team_id = _col[name][id_col].team_id;
          team_ids.push(team_id);
        }
      }
    }
    _col[name][id_col].serial_num = val;
    _col[name].sort((a, b) =>
      Number(a.serial_num) > Number(b.serial_num)
        ? 1
        : Number(b.serial_num) > Number(a.serial_num)
        ? -1
        : 0
    );
    this.setState(
      {
        columns: _col,
      },
      () => this.setState({ isLoading: false })
    );
    handleChangePostData(_col);
    console.log(club_name, name, team_ids);
    if (team_ids && team_ids.length && team_ids[0] !== 0) {
      axios
        .post(
          process.env.REACT_APP_API_URL + `team/update/serie/array`,
          {
            team_ids: team_ids,
            value: val,
          },
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          toast.success(
            response?.success
              ? response.success
              : "Numéro de séries correctement mis à jour",
            { duration: 4000 }
          );
        })
        .catch((error) => {
          console.log(error);
          toast.error("Problème lors de la sauvegarde des numéros de séries", {
            duration: 4000,
          });
        });
    }
  };
  render() {
    const {
      containerHeight,
      isCombineEnabled,
      withScrollableColumns,
      nbEquipes,
      setNbEquipes,
    } = this.props;
    const { columns, ordered, refresh, isLoading } = this.state;
    return (
      <React.Fragment>
        {!isLoading ? (
          <DragDropContext
            onDragEnd={this.onDragEnd}
            onDragStart={() => this.setState({ isDragging: true })}
          >
            <div style={{ overflowX: "scroll", width: "100%" }}>
              <Droppable
                droppableId="board"
                type="COLUMN"
                direction="horizontal"
                ignoreContainerClipping={Boolean(containerHeight)}
                isCombineEnabled={isCombineEnabled}
              >
                {(provided) => (
                  <Container
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {ordered.map((key, index) => {
                      return (
                        <Column
                          key={key}
                          index={index}
                          title={key}
                          addBye={this.addBye}
                          equipes={columns[key]}
                          removeColumn={this.removeColumn}
                          handleChangeNb={this.handleChangeNb}
                          nbEquipes={nbEquipes}
                          setNbEquipes={setNbEquipes}
                          refresh={refresh}
                          handleSubmit={this.handleSubmit}
                          handleSubmitPlayTime={this.handleSubmitPlayTime}
                          isScrollable={withScrollableColumns}
                          isCombineEnabled={isCombineEnabled}
                        />
                      );
                    })}
                    {provided.placeholder}
                  </Container>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        ) : (
          <div
            style={{
              width: "100%",
              height: "500px",
            }}
          >
            <Loading />
          </div>
        )}
      </React.Fragment>
    );
  }
}

/*
const rootElement = document.getElementById("root");
ReactDOM.render(<Board initial={divisionEquipeMap} />, rootElement);
*/
