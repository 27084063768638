export const columns = [
  { id: "id", label: "ID", width: 20, hidden: true },
  { id: "name", label: "Nom du championnat", width: 200 },
  {
    id: "start_date",
    label: "Date de début",
    width: 200,
    format: (e) => new Date(e).toLocaleDateString("fr-FR"),
  },
  {
    id: "end_date",
    label: "Date de fin",
    width: 200,
    format: (e) => new Date(e).toLocaleDateString("fr-FR"),
  },
];

export const structTable = (el) => {
  if (el?.id)
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archive",
      name: el.name,
      start_date: el.start_date,
      end_date: el.end_date,
    };
};

export const struct = (el) => {
  return { el };
};

export const routeTable = "championnats";
export const route = "championnat";
export const nameTable = "Championnats";
