import React, { useState, useEffect } from "react";
import * as ROUTES from "../../constants/routes";
import { NavLink, useLocation } from "react-router-dom";

import { useStyles } from "../../theme";
import { css } from "@emotion/css";

import { struct, route, routeTable, items, getShortlists } from "./items";

import {
  columns as columnsEquipe,
  structTable as structEquipe,
} from "../Equipes/items";
import {
  columns as columnsMembre,
  structTable as structMembre,
} from "../Membres/items";

import {
  columns as calumnsSanction,
  structTable as structSanction,
} from "../Sanctions/items";

import Loading from "../Loading";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
} from "../../components";
import Entities from "../Entities";

import * as Icon from "@material-ui/icons";

import qs from "qs";

import { Button } from "@material-ui/core";

export default function Affiliation(props) {
  const classes = useStyles();
  const { state } = props?.location;
  const { userInfos } = props;
  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [membreInfos, setMembreInfos] = useState(undefined);
  const [loading, setLoading] = useState(true);
  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  let membre_first_name = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).first_name;
  let membre_name = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .name;
  let transfer_id = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .transfer_id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  useEffect(() => {
    if (!loading) {
      if (id && data) {
        setMembreInfos(
          data?.membre[0]?.first_name +
            " " +
            data?.membre[0]?.name +
            " (Matricule : " +
            data?.registration_number?.value +
            ")"
        );
      } else {
        if (membre_first_name && membre_name)
          setMembreInfos(membre_first_name + " " + membre_name);
      }
    }
  }, [loading, id, data, membre_first_name, membre_name]);
  return (
    <div className={classes.root}>
      <CardHeader
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"affiliation"}
        id={id}
        item={"affiliation"}
        saveable={userInfos?.access_affiliations_rights > 1 || userInfos?.admin}
        editTitle={
          !loading && membreInfos
            ? "Modifier l'affiliation de " + membreInfos
            : !loading
            ? "Modifier l'affiliation"
            : ""
        }
        addTitle={
          !loading && membreInfos && transfer_id
            ? "Transférer l'affiliation de " + membreInfos
            : !loading && membreInfos
            ? "Ajouter une affiliation à " + membreInfos
            : !loading
            ? "Ajouter une affiliation"
            : ""
        }
        data={data}
        routeTable={routeTable}
        nextRoute={"affiliation"}
      />
      {!loading && data ? (
        <div className={classes.content}>
          <FormCards
            cardTitles={["Dénominations"]}
            editable={
              userInfos?.access_affiliations_rights > 1 || userInfos?.admin
            }
            data={data}
            items={items}
            setData={setData}
            getShortlists={getShortlists}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CardActions
              routeTable={routeTable}
              route={route}
              data={data}
              id={id}
              isArchived={archived}
              setArchived={setArchived}
              archivable={
                userInfos?.access_affiliations_rights > 2 || userInfos?.admin
              }
            />
            {id &&
            (userInfos?.access_affiliations_rights > 1 || userInfos?.admin) ? (
              <Button
                color="primary"
                variant="contained"
                startIcon={<Icon.Repeat />}
                component={React.forwardRef((props, ref) => (
                  <NavLink
                    to={{
                      pathname: ROUTES.AFFILIATION,
                      search:
                        "first_name=" +
                        data?.membre[0]?.first_name +
                        "&name=" +
                        data?.membre[0]?.name +
                        "&membre_id=" +
                        data?.membre[0]?.id +
                        "&transfer_id=" +
                        id,
                      state: state,
                    }}
                    {...props}
                    ref={ref}
                  />
                ))}
                className={css`
                  &:hover,
                  &:active,
                  &:focus {
                    color: #ffffff !important;
                  }
                `}
              >
                Transférer
              </Button>
            ) : (
              <></>
            )}
          </div>
          {data?.equipe && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.EQUIPE}
              disableAdd
              items={{
                nameTable: "Equipe associée",
                columns: columnsEquipe,
                routeTable: "equipes",
                structTable: structEquipe,
                data: data?.equipe,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
          {data?.membre && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.MEMBRE}
              disableAdd
              items={{
                nameTable: "Membre associé",
                routeTable: "membres",
                columns: columnsMembre,
                structTable: structMembre,
                data: data?.membre,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
          {data?.sanctions && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.SANCTION}
              disableAdd={true}
              items={{
                nameTable: "Sanctions associées",
                columns: calumnsSanction,
                routeTable: "sanctions",
                structTable: structSanction,
                data: data.sanctions,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
