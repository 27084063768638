import React, {useEffect} from "react";
import { Switch, Route, Router, Redirect, useLocation } from "react-router-dom";

import { fetchUserInfos } from "../../components";

import Navigation from "../Navigation";
import { SignUpForm } from "../SignUp";
import { SignInForm } from "../SignIn";
import { PasswordForgetForm } from "../PasswordForget";
import Home from "../Home";
import Rapports from "../Rapports";
//import Dashboard from "../Dashboard";
import MyAccount from "../MyAccount";
import NotFound from "../NotFound";
import { Toaster } from "react-hot-toast";

import Membre from "../Membres/Membre";
import Equipe from "../Equipes/Equipe";
import Club from "../Clubs/Club";
import Terrain from "../Terrains/Terrain";
import Championnat from "../Championnats/Championnat";
import User from "../Users/User";
import Affiliation from "../Affiliations/Affiliation";
import Team from "../Teams/Team";
import Rencontre from "../Rencontres/Rencontre";
import Sanction from "../Sanctions/Sanction";

import * as itemsMembers from "../Membres/items";
import * as itemsEquipes from "../Equipes/items";
import * as itemsClubs from "../Clubs/items";
import * as itemsTerrains from "../Terrains/items";
import * as itemsUsers from "../Users/items";
import * as itemsChampionnats from "../Championnats/items";
import * as itemsTeams from "../Teams/items";
import * as itemsAffiliations from "../Affiliations/items";
import * as itemsRencontres from "../Rencontres/items";
import * as itemsSanction from "../Sanctions/items";

import Entities from "../Entities";

import * as ROUTES from "../../constants/routes";

import { theme } from "../../theme";
import { ThemeProvider } from "@material-ui/core";
import history from "../../history";
import CreateurRencontres from "../CreateurRencontres";
import Loading from "../Loading";

export default function App(props) {
  const [userInfos, setUserInfos] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    fetchUserInfos(setUserInfos, setLoading);    
   }, []);
   if(!loading) {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <div style={{ display: "flex" }}>
          <Navigation userInfos={userInfos} />
          <Switch>
            <Route
              exact
              path={ROUTES.HOME}
              component={(props) => <Home {...props} userInfos={userInfos} />}
            />
            <Route
              path={ROUTES.SIGN_IN}
              component={(props) => <SignInForm {...props} userInfos={userInfos} />}
            />
            <Route
              path={ROUTES.SIGN_UP}
              component={(props) => <SignUpForm {...props} userInfos={userInfos} />}
            />
            <Route
              path={ROUTES.PASSWORD_FORGET}
              component={(props) => <PasswordForgetForm {...props} userInfos={userInfos} />}
            />
            {/*<Route
            exact
            path={ROUTES.DASHBOARD}
            component={(props) => <Dashboard {...props} />}
          />*/}
          <Route
              path={ROUTES.RAPPORTS}
              component={(props) => <Rapports {...props} userInfos={userInfos} />}
            />

            <Route
              exact
              path={ROUTES.MEMBRES}
              component={(props) => {
                if(userInfos?.access_membres_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  userInfos={userInfos}
                  child_route={ROUTES.MEMBRE}
                  isSearchable
                  items={itemsMembers}
                />
              )}
            return(
              <Redirect to={ROUTES.HOME} />
            )}
            }
            />
            <Route
              path={ROUTES.MEMBRE}
              component={(props) => userInfos?.access_membres_view > 1 || userInfos?.admin  ? <Membre {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />
            <Route
              exact
              path={ROUTES.AFFILIATIONS}
              component={(props) => {
                 if(userInfos?.access_affiliations_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  userInfos={userInfos}
                  child_route={ROUTES.AFFILIATION}
                  isSearchable
                  items={itemsAffiliations}
                />)}
                return (
                  <Redirect to={ROUTES.HOME} />
                )
              }}
            />
            <Route
              path={ROUTES.AFFILIATION}
              component={(props) => userInfos?.access_affiliations_view > 1 || userInfos?.admin  ? <Affiliation {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />
            <Route
              exact
              path={ROUTES.EQUIPES}
              component={(props) => {
                if(userInfos?.access_equipes_view > 0 || userInfos?.admin) {
                return (<Entities
                  {...props}
                  userInfos={userInfos}
                  child_route={ROUTES.EQUIPE}
                  isSearchable
                  items={itemsEquipes}
                />)}
                return (
                  <Redirect to={ROUTES.HOME} />
                )
              }}
            />
            <Route
              path={ROUTES.EQUIPE}
              component={(props) => userInfos?.access_equipes_view > 1 || userInfos?.admin  ? <Equipe {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />
            <Route
              exact
              path={ROUTES.TEAMS}
              component={(props) => {
                if(userInfos?.access_teams_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  userInfos={userInfos}
                  child_route={ROUTES.TEAM}
                  isSearchable
                  items={itemsTeams}
                />
              )}
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />
            <Route
              path={ROUTES.TEAM}
              component={(props) => userInfos?.access_teams_view > 1 || userInfos?.admin  ? <Team {...props} userInfos={userInfos}/> : <Redirect to={ROUTES.HOME} />}
            />
            <Route
              exact
              path={ROUTES.CLUBS}
              component={(props) => {
                if(userInfos?.access_clubs_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  isExpandable={true}
                  child_route={ROUTES.CLUB}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsClubs}
                />
              )}
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />
            <Route
              path={ROUTES.CLUB}
              component={(props) => userInfos?.access_clubs_view > 1 || userInfos?.admin  ? <Club {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />

            <Route
              exact
              path={ROUTES.TERRAINS}
              component={(props) => {
                if(userInfos?.access_terrains_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  child_route={ROUTES.TERRAIN}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsTerrains}
                />
              )}
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />
            <Route
              path={ROUTES.TERRAIN}
              component={(props) => userInfos?.access_terrains_view > 1 || userInfos?.admin  ? <Terrain {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />

            <Route
              exact
              path={ROUTES.CHAMPIONNATS}
              component={(props) => {
                if(userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  child_route={ROUTES.CHAMPIONNAT}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsChampionnats}
                />
              )}
              return(
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />

            <Route
              path={ROUTES.CHAMPIONNAT}
              component={(props) => userInfos?.admin ? <Championnat {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />

            <Route
              path={ROUTES.CREATEUR_RENCONTRES}
              component={(props) => userInfos?.admin ? <CreateurRencontres {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />

            <Route
              exact
              path={ROUTES.USERS}
              component={(props) => {
                if(userInfos?.admin) {
                return(<Entities
                  {...props}
                  child_route={ROUTES.USER}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsUsers}
                />
              )}
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />
            <Route
              path={ROUTES.USER}
              component={(props) => {
                return(
                  userInfos?.admin ? <User {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />
              )}}
            />

            <Route
              exact
              path={ROUTES.RENCONTRES}
              component={(props) => {
                if(userInfos?.access_rencontres_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  child_route={ROUTES.RENCONTRE}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsRencontres}
                />
              )} 
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />

            <Route
              path={ROUTES.RENCONTRE}
              component={(props) => userInfos?.access_rencontres_view > 1 || userInfos?.admin ? <Rencontre {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />

            <Route
              exact
              path={ROUTES.SANCTIONS}
              component={(props) => {
                if(userInfos?.access_sanctions_view > 0 || userInfos?.admin) {
                return(
                <Entities
                  {...props}
                  child_route={ROUTES.SANCTION}
                  userInfos={userInfos}
                  isSearchable
                  items={itemsSanction}
                />
              )}
              return (
                <Redirect to={ROUTES.HOME} />
              )
            }}
            />

            <Route
              path={ROUTES.SANCTION}
              component={(props) => userInfos?.access_sanctions_view > 1 || userInfos?.admin ? <Sanction {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />
            <Route
              path={ROUTES.MY_ACCOUNT}
              component={(props) => userInfos ? <MyAccount {...props} userInfos={userInfos} /> : <Redirect to={ROUTES.HOME} />}
            />
            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
      <Toaster position="bottom-right" reverseOrder={false} />
    </ThemeProvider>
  );}
  return (
    <Loading />
  )
}
