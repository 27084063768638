export const list_divisions = {
  "Dimanche Honneur": {
    id: "1",
    name: "Dimanche Honneur",
  },
  "Dimanche 1A": {
    id: "2",
    name: "Dimanche 1A",
  },
  "Dimanche 1B": {
    id: "3",
    name: "Dimanche 1B",
  },
  "Vétérans Honneur": {
    id: "4",
    name: "Vétérans Honneur",
  },
  "Vétérans 1": {
    id: "5",
    name: "Vétérans 1",
  },
  "Vétérans 2": {
    id: "6",
    name: "Vétérans 2",
  },
  "Vétérans 3": {
    id: "7",
    name: "Vétérans 3",
  },
  "À ignorer": {
    id: "8",
    name: "À ignorer",
  },
};

export const divisions = [
  list_divisions["Dimanche Honneur"],
  list_divisions["Dimanche 1A"],
  list_divisions["Dimanche 1B"],
  list_divisions["Vétérans Honneur"],
  list_divisions["Vétérans 1"],
  list_divisions["Vétérans 2"],
  list_divisions["Vétérans 3"],
  list_divisions["À ignorer"],
];

export const getByDivision = (division, items) =>
  items.filter((equipe) => equipe.division === division.name);
