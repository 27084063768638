import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css";

const error = console.error;

function logError(...parameters) {
  let filter = parameters.find((parameter) => {
    // Vérifiez si le paramètre est une chaîne avant d'appeler includes
    return typeof parameter === "string" && parameter.includes("is deprecated");
  });
  if (!filter) error(...parameters);
}
console.error = logError;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
