import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { CardHeader, FormCards, AlertDialog } from "../../components";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

import { useStyles } from "../../theme";
import clsx from "clsx";
import { Button, TextField, Grid } from "@material-ui/core";
import { ReactComponent as SanctionIcon } from "../../ressources/judgment.svg";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";

const dateFns = new DateFnsUtils();

const ButtonSanctions = styled(Button)({
  padding: "6px 12px",
  backgroundColor: "#f0bd25",
  color: "white",
  padding: "10px 20px",
  fontSize: 15,
  "& svg": {
    fill: "white!important",
    width: 20,
    height: 20,
  },
  "&:hover": {
    backgroundColor: "#f0bd25",
    boxShadow: "none",
    color: "white",
  },
  "&:active": {
    backgroundColor: "#f0bd25",
    color: "white",
  },
});

export default function Rapports(props) {
  const { userInfos } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [fieldsRapport, setFieldsRapport] = useState(Array);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Accéder aux paramètres GET spécifiques
  const preview = searchParams.get("preview");

  useEffect(() => {
    if (userInfos?.email) {
      handleChangeFields(null, "email", userInfos?.email);
    }
  }, []);

  const handleGenerateRapportSanctions = () => {
    return false;
  };

  function RapportSanctions() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required={true}
            fullWidth
            id="email"
            label="Adresse email"
            name="email"
            value={
              fieldsRapport && fieldsRapport?.email
                ? fieldsRapport?.email
                : null
            }
            onChange={handleChangeFields}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              autoOk="true"
              helperText={""}
              name={"start_date"}
              inputVariant="outlined"
              required={true}
              id="start_date"
              label={"Date de début"}
              style={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={
                fieldsRapport && fieldsRapport?.start_date
                  ? fieldsRapport?.start_date
                  : undefined
              }
              onChange={(e) => {
                let value = e;
                if (dateFns?.isValid(e)) {
                  value = dateFns?.format(e, "yyyy-MM-dd");
                }
                handleChangeFields(null, "start_date", value);
              }}
              KeyboardButtonProps={{
                "aria-label": "Date de début",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              autoOk="true"
              helperText={""}
              name={"end_date"}
              inputVariant="outlined"
              required={true}
              style={{ width: "100%" }}
              id="end_date"
              label={"Date de fin"}
              format="dd/MM/yyyy"
              value={
                fieldsRapport && fieldsRapport?.end_date
                  ? fieldsRapport?.end_date
                  : undefined
              }
              onChange={(e) => {
                let value = e;
                if (dateFns?.isValid(e)) {
                  value = dateFns?.format(e, "yyyy-MM-dd");
                }
                handleChangeFields(null, "end_date", value);
              }}
              KeyboardButtonProps={{
                "aria-label": "Date de fin",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }

  const handleChangeFields = (event, name = null, value = undefined) => {
    if (event && !name && !value) {
      name = event?.target?.name;
      value = event?.target?.value;
    }
    setFieldsRapport({ ...fieldsRapport, [name]: value });
    console.log("name", name, "value", value, fieldsRapport);
  };

  const classes = useStyles();
  return !localStorage.getItem("api_token") ? (
    <Redirect to={ROUTES.SIGN_IN} />
  ) : preview ? (
    <div className={classes.root}>
      <CardHeader title="Rapports" />
      <div className={classes.content}>
        <div className={classes.mT25}>
          <ButtonSanctions
            startIcon={<SanctionIcon />}
            variant="contained"
            onClick={() => setShowDialog(true)}
            className={classes.btnRapport}
          >
            Rapport des sanctions
          </ButtonSanctions>
          {showDialog && (
            <AlertDialog
              showDialog={showDialog}
              setShowDialog={setShowDialog}
              dialogComponent={RapportSanctions}
              dialogTitle={"Générer le rapport des sanctions"}
              primary_action={"Générer"}
              secondary_action={"Annuler"}
              handlePrimaryAction={(e) => {
                e.stopPropagation();
                setShowDialog(false);
                handleGenerateRapportSanctions();
              }}
              handleSecondaryAction={(e) => {
                e.stopPropagation();
                setShowDialog(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <CardHeader title="Rapports" />
      <div className={classes.content}>
        <p>En cours de développement...</p>
      </div>
    </div>
  );
}
