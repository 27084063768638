import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { CardHeader, FormCards } from "../../components";
import { useStyles } from "../../theme";
import clsx from "clsx";


export default function Home(props) {
  const {userInfos} = props;
  useEffect(() => {
            (function(d, s, id) {
            if (d.getElementById(id)) {
                if (window.__TOMORROW__) {
                    window.__TOMORROW__.renderWidget();
                }
                return;
            }
            const fjs = d.getElementsByTagName(s)[0];
            const js = d.createElement(s);
            js.id = id;
            js.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";

            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'tomorrow-sdk');
  }, []);
  const classes = useStyles();
  return !localStorage.getItem("api_token") ? (
    <Redirect to={ROUTES.SIGN_IN} />
  ) : (
    <div className={classes.root}>
      <CardHeader
        title="Accueil"
      />
      <div className={classes.content}>
          <div className={classes.mT25}>
            <h3>Bienvenue <strong>{userInfos?.name}</strong></h3>
        <div className={clsx("tomorrow", classes.mT25)}
           data-location-id={"008900"}
           data-language={"FR"}
           data-unit-system={"METRIC"}
           data-skin={"light"}
           data-widget-type={"upcoming"}
           style={{
            paddingBottom:22,
            position:"relative",
           }}
        >
        </div>
          </div>
    </div>
    </div>
  );
}
