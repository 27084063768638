import { Circle } from "../../components";
import { red, green } from "@material-ui/core/colors";
import axios from "axios";

import { CustomLinkButton, CustomArchivingButton } from "../../components";

export const routeTable = "teams";
export const route = "team";
export const nameTable = "Teams";

const DIV_DIMANCHE = [
  {
    value: "Dimanche Honneur",
    label: "Dimanche Honneur",
  },
  {
    value: "Dimanche 1A",
    label: "Dimanche 1A",
  },
  {
    value: "Dimanche 1B",
    label: "Dimanche 1B",
  },
];

const DIV_VETERAN = [
  {
    value: "Vétérans Honneur",
    label: "Vétérans Honneur",
  },
  {
    value: "Vétérans 1",
    label: "Vétérans 1",
  },
  {
    value: "Vétérans 2",
    label: "Vétérans 2",
  },
  {
    value: "Vétérans 3",
    label: "Vétérans 3",
  },
];

//shortlist
export async function getShortlists(setOptions) {
  let terrains = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "terrains" + "/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        terrains.push({
          label: data[i].name,
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });

  let equipes = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "equipes" + "/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        equipes.push({
          label: data[i].id + " " + data[i].club?.name,
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });

  setOptions({
    ["terrain_id"]: terrains,
    ["equipe_id"]: equipes,
  });
  // End shortlist
}

export const struct = (data) => {
  let _terrain = [];
  let _equipe = [];
  let _teams = [];
  _terrain.push(data?.terrain ? data.terrain : {});
  _equipe.push(data?.equipe ? data.equipe : {});
  if (data?.equipe?.teams) {
    for (let i in data?.equipe?.teams) {
      _teams.push(data?.equipe?.teams[i]);
    }
  }
  let equipe_id = "";
  let equipe_name = "";
  let terrain_name = "";
  let terrain_id = "";
  let cat = null;
  for (let equipe of _equipe) {
    equipe_id = equipe?.id;
    equipe_name = equipe?.club?.name;
  }
  for (let terrain of _terrain) {
    terrain_name = terrain?.name;
    terrain_id = terrain?.id;
  }
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("equipe_name")) {
    equipe_name = urlParams.get("equipe_name");
  }
  if (urlParams.get("equipe_id")) {
    equipe_id = urlParams.get("equipe_id");
  }
  if (urlParams.get("terrain_name")) {
    terrain_name = urlParams.get("terrain_name");
  }
  if (urlParams.get("terrain_id")) {
    terrain_id = urlParams.get("terrain_id");
  }
  if (urlParams.get("cat")) {
    cat = urlParams.get("cat");
  }
  return {
    equipe_id: {
      label: "Équipe associée",
      type: "autocomplete",
      value: {
        label: equipe_name,
        value: equipe_id,
      },
      disabled: equipe_id ? true : false,
    },
    terrain_id: {
      label: "Terrain associé",
      type: "autocomplete",
      value: {
        label: terrain_name,
        value: terrain_id,
      },
    },
    creation_date: {
      label: "Date de création",
      type: "date",
      value: data?.creation_date
        ? data.creation_date
        : !data
        ? new Date()
        : null,
    },
    closing_date: {
      label: "Date de clôture",
      type: "date",
      value: data?.closing_date ? data.closing_date : null,
    },
    division: {
      type: "select",
      label: "Division",
      value: data?.division,
      values:
        cat === "Dimanche"
          ? DIV_DIMANCHE
          : cat === "Vétérans"
          ? DIV_VETERAN
          : [
              ...DIV_DIMANCHE,
              ...DIV_VETERAN,
              { label: "Autre", value: "Autre" },
            ],
    },
    general_give_up: {
      label: "Forfait général",
      checked: Boolean(data?.general_give_up),
      type: "checkbox",
      disabled: true,
    },
    serial_number: {
      label: "Numéro de série",
      value: data?.serial_number,
      type: "number",
    },
    index: {
      label: "Index",
      value: data?.index,
      type: "text",
    },
    shirt: {
      label: "Maillot",
      value: data?.shirt,
      type: "text",
    },
    shorts: {
      label: "Culotte",
      value: data?.shorts,
      type: "text",
    },
    play_time: {
      label: "Heure",
      value: data?.play_time,
      type: "time",
    },
    terrain: _terrain,
    equipe: _equipe,
    teams: _teams,
  };
};

export const items = [
  [
    "equipe_id",
    "terrain_id",
    "creation_date",
    "closing_date",
    "division",
    "play_time",
    "serial_number",
    "index",
    "shirt",
    "shorts",
    "general_give_up",
  ],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "club_name",
    label: "Nom du club",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "equipe_id",
    label: "ID équipe",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "index",
    label: "Index",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "serial_number",
    label: "N° de série",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "division",
    label: "Division",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "terrain",
    label: "Terrain",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "creation_date",
    label: "Date de création",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "shirt",
    label: "Couleur - maillot",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const columnsChild = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "club_name",
    label: "Nom du club",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "equipe_id",
    label: "ID équipe",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "index",
    label: "Index",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "serial_number",
    label: "N° de série",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "division",
    label: "Division",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "terrain",
    label: "Terrain",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "creation_date",
    label: "Date de création",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "shirt",
    label: "Couleur - maillot",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const structTable = (el) => {
  if (el?.id)
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archive",
      active: el.active ? "Active" : "Inactive",
      equipe_id: el.equipe?.id,
      serial_number: el.serial_number,
      index: el.index,
      division: el.division,
      terrain: el.terrain?.name,
      club_name: el.equipe?.club?.name,
      creation_date: el.creation_date,
      shirt: el.shirt,
    };
};

export const defaultSort = {
  name: "creation_date",
  direction: "desc",
};
