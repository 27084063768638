import React, { useState, useEffect } from "react";
import { useStyles } from "../../theme";
import { css } from "@emotion/css";
import toast from "react-hot-toast";
import axios from "axios";
import { Button } from "@material-ui/core";
import * as Icon from "@material-ui/icons";
import {
  struct,
  route,
  routeTable,
  items,
  getShortlists,
  itemsRapport,
  itemsConvoquer,
} from "./items";
import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
  AlertDialog,
} from "../../components";
import qs from "qs";
import {
  Card,
  CardContent,
  CardHeader as CardHead,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
} from "@material-ui/core";

export default function Sanction(props) {
  const classes = useStyles();
  const { state } = props?.location;
  const { userInfos } = props;
  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fautes, setFautes] = useState([]);
  const [dialogType, setDialogType] = useState("");

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;

  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
    let fautes = [];
    let cacheFautes = localStorage.getItem("fautes");
    if (!cacheFautes) {
      axios
        .get(process.env.REACT_APP_API_URL + "fautes", {
          headers: { authorization: localStorage.getItem("api_token") },
        })
        .then((r) => {
          let data = r.data;
          for (let i in data) {
            fautes.push({
              label: data[i]?.fault_code,
              value: data[i]?.id,
              helperText:
                "<strong>Faute</strong> : " +
                data[i]?.fault_description +
                "<br/><br/><strong>Catégorie</strong> : " +
                data[i]?.fault_category_description +
                " <br/><br/><strong>Pénalité :</strong> " +
                data[i]?.penalty,
              fault_description: data[i]?.fault_description,
              fault_category_description: data[i]?.fault_category_description,
              penalty: data[i]?.penalty,
            });
          }
          localStorage.setItem("fautes", JSON.stringify(fautes));
          setFautes(fautes);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setFautes(JSON.parse(cacheFautes));
    }
  }, [id, setData, setLoading]);

  const handleGenerateRapport = async (route) => {
    const apiToken = localStorage.getItem("api_token");

    await axios
      .post(
        process.env.REACT_APP_API_URL +
          `simple_generate_report/sanctions/${route}`,
        {
          sanction_id: id,
          authorization: apiToken,
        }
      )
      .then((response) => {
        const pdfUrl = response?.data?.pdf_url;
        if (pdfUrl) {
          window.open(pdfUrl, "_blank"); // Ouvre le PDF dans une nouvelle fenêtre/onglet
        }
        toast.success("Rapport généré avec succès", { duration: 4000 });
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de la génération...",
          { duration: 4000 }
        );
      });
  };

  const renderAlertRapports = () => {
    if (showDialog) {
      let dialogText, dialogTitle, route;
      if (dialogType === "cgac") {
        dialogText = "Sélectionnez la date pour le rapport CGAC.";
        dialogTitle = "Générer le rapport CGAC";
        route = "cgac";
      } else if (dialogType === "sportif") {
        dialogText = "Sélectionnez la date pour le rapport Sportif.";
        dialogTitle = "Générer le rapport Sportif";
        route = "sportif";
      } else if (dialogType === "appel") {
        dialogText = "Sélectionnez la date pour le rapport Appel.";
        dialogTitle = "Générer le rapport Appel";
        route = "appel";
      }

      return (
        <AlertDialog
          showDialog={Boolean(showDialog)}
          setShowDialog={setShowDialog}
          dialogText={dialogText}
          dialogTitle={dialogTitle}
          primary_action={"Générer"}
          secondary_action={"Annuler"}
          handlePrimaryAction={(e) => {
            e.stopPropagation();
            setShowDialog(false);
            handleGenerateRapport(route);
          }}
          handleSecondaryAction={(e) => {
            setShowDialog(false);
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className={classes.root}>
      {showDialog && renderAlertRapports()}
      <CardHeader
        route={"sanction"}
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        id={id}
        data={data}
        editTitle={"Modifier la sanction"}
        addTitle={"Ajouter une sanction"}
        routeTable={routeTable}
        nextRoute={"sanction"}
        saveable={userInfos?.access_sanctions_rights > 1 || userInfos?.admin}
      />
      {!loading && data && (
        <div className={classes.content}>
          <>
            <FormCards
              editable={
                userInfos?.access_sanctions_rights > 1 || userInfos?.admin
              }
              cardTitles={["Informations"]}
              data={data}
              items={items}
              setData={setData}
              getShortlists={getShortlists}
            />
            {data?.color?.value === "R" && (
              <>
                <FormCards
                  editable={
                    userInfos?.access_sanctions_rights > 1 || userInfos?.admin
                  }
                  cardTitles={["Rapport"]}
                  data={data}
                  items={itemsRapport}
                  setData={setData}
                  getShortlists={getShortlists}
                  fullWidthCol={true}
                />

                {data?.is_convocate?.checked && (
                  <FormCards
                    editable={
                      userInfos?.access_sanctions_rights > 1 || userInfos?.admin
                    }
                    cardTitles={["Personnes convoquées"]}
                    data={data}
                    items={itemsConvoquer}
                    setData={setData}
                  />
                )}
              </>
            )}

            {data?.color?.value === "R" &&
              (userInfos?.access_sanctions_rights > 1 || userInfos?.admin) && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Icon.FileCopy />}
                    onClick={() => {
                      setDialogType("cgac");
                      setShowDialog(true);
                    }}
                    className={css`
                      & {
                        margin-bottom: 1rem;
                        margin-right: 1rem; /* Ajoute un espace entre les boutons */
                      }
                      &:hover,
                      &:active,
                      &:focus {
                        color: #ffffff !important;
                      }
                    `}
                  >
                    Générer CGAC
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Icon.FileCopy />}
                    onClick={() => {
                      setDialogType("sportif");
                      setShowDialog(true);
                    }}
                    className={css`
                      & {
                        margin-bottom: 1rem;
                        margin-right: 1rem; /* Ajoute un espace entre les boutons */
                      }
                      &:hover,
                      &:active,
                      &:focus {
                        color: #ffffff !important;
                      }
                    `}
                  >
                    Générer Sportif
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<Icon.FileCopy />}
                    onClick={() => {
                      setDialogType("appel");
                      setShowDialog(true);
                    }}
                    className={css`
                      & {
                        margin-bottom: 1rem;
                        margin-right: 1rem; /* Ajoute un espace entre les boutons */
                      }
                      &:hover,
                      &:active,
                      &:focus {
                        color: #ffffff !important;
                      }
                    `}
                  >
                    Générer Appel
                  </Button>
                </>
              )}
            <Card className={classes.card}>
              <CardHead
                title={"Tableau des sanctions"}
                disableTypography
                className={classes.cardHead}
              />
              <CardContent className={classes.cardContent}>
                <Table>
                  <TableHead>
                    <TableCell className={classes.tableHeadCell}>#</TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Faute
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Catégorie
                    </TableCell>
                    <TableCell className={classes.tableHeadCell}>
                      Pénalité
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {fautes.map((row) => (
                      <TableRow key={row.label} className={classes.tableRow}>
                        <TableCell component="th" scope="row">
                          {" "}
                          {row.label}
                        </TableCell>
                        <TableCell>{row.fault_description}</TableCell>
                        <TableCell>{row.fault_category_description}</TableCell>
                        <TableCell>{row.penalty}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
            <CardActions
              routeTable={routeTable}
              route={route}
              data={data}
              id={id}
              isArchived={archived}
              setArchived={setArchived}
              archivable={
                userInfos?.access_sanctions_rights > 2 || userInfos?.admin
              }
            />
          </>
        </div>
      )}
    </div>
  );
}
