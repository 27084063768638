import axios from "axios";
import { CustomLinkButton, CustomArchivingButton } from "../../components";

export const route = "affiliation";
export const routeTable = "affiliations";
export const nameTable = "Affiliations";

export const defaultSort = {
  name: "last_status_date",
  direction: "desc",
};

//shortlist
export async function getShortlists(setOptions) {
  let membres = [];
  let equipes = [];

  let cacheAffiliations = localStorage.getItem("shortlist_affiliations");
  if (!cacheAffiliations) {
    await axios
      .get(process.env.REACT_APP_API_URL + "membres/shortlist/affiliations", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        for (let i in data) {
          membres.push({
            label:
              data[i]?.registration_number +
              " " +
              data[i].name.toUpperCase() +
              " " +
              data[i].first_name,
            value: data[i]?.id,
          });
        }
        localStorage.setItem("shortlist_affiliations", JSON.stringify(membres));
      })
      .catch((e) => {
        console.log(e);
      });
  } else {
    membres = JSON.parse(cacheAffiliations);
  }

  await axios
    .get(process.env.REACT_APP_API_URL + "equipes/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        equipes.push({
          label: data[i].id + " " + data[i].club?.name,
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });
  setOptions({
    membre_id: membres,
    equipe_id: equipes,
  });
  // End shortlist
}

export const struct = (data = {}) => {
  console.log(data);
  const urlParams = new URLSearchParams(window.location.search);

  // Initialisation des variables
  let _equipe = [];
  let _club = [];
  let _membre = [];
  let membre_name = "";
  let membre_id = "";
  let equipe_name = "";
  let equipe_id = "";
  let registration_number = "";
  let transfer_id = null;

  // Vérifier la présence de l'équipe, du club et du membre
  if (data.equipe) {
    _equipe.push(data.equipe);
    if (data.equipe.club) {
      _club.push(data.equipe.club);
    }
  }
  if (data.membre) {
    _membre.push(data.membre);
  }

  // Définir les noms et les numéros d'enregistrement du membre à partir de data
  if (
    data.membre &&
    data.membre.first_name &&
    data.membre.name &&
    data.membre.id
  ) {
    membre_name = `${data.membre.name.toUpperCase()} ${data.membre.first_name}`;
    membre_id = data.membre.id;
  }
  if (
    data.equipe &&
    data.equipe.id &&
    data.equipe.club &&
    data.equipe.club.name
  ) {
    equipe_name = `${data.equipe.id} ${data.equipe.club.name}`;
    equipe_id = data.equipe.id;
  }
  if (data.registration_number) {
    registration_number = data.registration_number;
  }

  // Surcharger les valeurs avec les paramètres d'URL s'ils existent
  if (urlParams.get("name")) {
    membre_name = urlParams.get("name");
  }
  if (urlParams.get("first_name")) {
    membre_name += ` ${urlParams.get("first_name")}`;
  }
  if (urlParams.get("membre_id")) {
    membre_id = urlParams.get("membre_id");
  }
  if (urlParams.get("registration_number")) {
    registration_number = urlParams.get("registration_number");
  }
  if (urlParams.get("transfer_id")) {
    transfer_id = urlParams.get("transfer_id");
  }

  // Traiter les sanctions filtrées
  let _sanctions = (data.filtered_sanctions || []).map((sanction) => ({
    ...sanction,
    full_name: membre_name,
    registration_number: registration_number,
  }));

  let id = urlParams.get("id");

  return {
    transfer_id: {
      label: "Transfer",
      type: "number",
      value: transfer_id ? Number(transfer_id) : null,
      disabled: true,
      display: "none",
    },
    registration_number: {
      label: "Matricule",
      type: "number",
      value: registration_number,
      disabled: true,
      display: !id ? "none" : false,
    },
    membre_id: {
      label: "Membre associé",
      type: "autocomplete",
      value: {
        label: membre_name,
        value: membre_id,
      },
      disabled: id || (membre_name && membre_id) ? true : false,
    },
    equipe_id: {
      label: "Equipe associée",
      type: "autocomplete",
      value: {
        label: equipe_name,
        value: equipe_id,
      },
      disabled: false,
    },
    various: {
      label: "Divers",
      type: "text",
      value: data?.various,
    },
    status: {
      label: "Statut",
      type: "select",
      onChange: (value, data, setData) => {
        console.log(value);
        if (value === "Désaffilié") {
          setData({
            ...data,
            status: {
              ...data["status"],
              value: value,
            },
            disafilliation_date: {
              ...data["disafilliation_date"],
              value: new Date(),
            },
          });
        } else {
          setData({
            ...data,
            status: {
              ...data["status"],
              value: value,
            },
            disafilliation_date: {
              ...data["disafilliation_date"],
              value: null,
            },
          });
        }
      },
      value: data?.status ? data.status : undefined,
      values: [
        { label: "Actif", value: "Actif" },
        { label: "Club désactivé", value: "Club désactivé" },
        { label: "Désaffilié", value: "Désaffilié" },
        { label: "Problème financier", value: "Problème financier" },
        { label: "Décès", value: "Décès" },
        { label: "Suspendu", value: "Suspendu" },
      ],
    },
    first_affiliation_date: {
      label: "Date d'affiliation",
      type: "date",
      disabled: true,
      value: data?.first_affiliation_date
        ? data.first_affiliation_date
        : new Date(),
    },
    disafilliation_date: {
      disabled: true,
      label: "Date de désaffiliation",
      type: "date",
      value: data?.disafilliation_date ? data.disafilliation_date : null,
    },
    last_status_date: {
      label: "Date de dernier statut",
      type: "date",
      value: data?.last_status_date ? data.last_status_date : null,
    },
    arbitration_start_date: {
      label: "Date de début d'arbitrage",
      type: "date",
      value: data?.arbitration_start_date ? data.arbitration_start_date : null,
    },
    arbitration_end_date: {
      label: "Date de fin d'arbitrage",
      type: "date",
      value: data?.arbitration_end_date ? data.arbitration_end_date : null,
    },
    referee: {
      label: "Arbitre",
      type: "checkbox",
      checked: Boolean(data?.referee),
    },
    card_given: {
      label: "Carte rentrée",
      type: "checkbox",
      checked: Boolean(data?.card_given),
    },
    coach: {
      label: "Entraîneur",
      type: "checkbox",
      checked: Boolean(data?.coach),
    },
    official_correspondent: {
      label: "Correspondant officiel",
      type: "checkbox",
      checked: Boolean(data?.official_correspondent),
    },
    comite: {
      label: "Comité",
      type: "checkbox",
      checked: Boolean(data?.comite),
      onChange: (isChecked, data, setData) => {
        setData({
          ...data,
          role: {
            ...data["role"],
            disabled: !isChecked,
          },
          comite: {
            ...data["comite"],
            checked: isChecked,
          },
        });
      },
    },
    role: {
      label: "Rôle comité",
      type: "multiselect",
      value: data?.role || null,
      values: [
        { label: "Comité sportif", value: "1" },
        { label: "Comité d'appel", value: "2" },
        { label: "Administrateur", value: "3" },
        { label: "CAG", value: "4" },
      ],
      disabled: !data?.comite,
    },

    membre: _membre,
    equipe: _equipe,
    club: _club,
    sanctions: _sanctions,
  };
};

export const items = [
  [
    "transfer_id",
    "registration_number",
    "membre_id",
    "equipe_id",
    "various",
    "status",
    "first_affiliation_date",
    "disafilliation_date",
    "last_status_date",
    "arbitration_start_date",
    "arbitration_end_date",
    "referee",
    "coach",
    "official_correspondent",
    "card_given",
    "comite",
    "role",
  ],
  ["membre"],
  ["equipe"],
  ["club"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "registration_number",
    label: "Matricule",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "status",
    label: "Statut",
    options: {
      filter: true,
      sort: true,
      display: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "all_name",
    label: "Nom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "date_of_birth",
    label: "Date de naissance",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "first_affiliation_date",
    label: "Date d'affiliation",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "disafilliation_date",
    label: "Date de désaffiliation",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "last_status_date",
    label: "Date de dernier statut",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "function",
    label: "Fonction particulière",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const columnsChild = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  /*{
    name: "archived",
    label: "Statut d'archive",
    options: {
      filter: false,
      sort: false,
      display: true,
    },
  },*/
  {
    name: "status",
    label: "Statut",
    options: {
      filter: true,
      sort: true,
      display: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "registration_number",
    label: "Matricule",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "all_name",
    label: "Nom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "date_of_birth",
    label: "Date de naissance",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "first_affiliation_date",
    label: "Date d'affiliation",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "disafilliation_date",
    label: "Date de désaffiliation",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "last_status_date",
    label: "Date de dernier statut",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "function",
    label: "Fonction particulière",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const structTable = (el) => {
  if (el?.id) {
    let liste_fonctions = [];
    if (el.referee) {
      liste_fonctions.push("Arbitres");
    }
    if (el.coach) {
      liste_fonctions.push("Entraîneur");
    }
    if (el.official_correspondent) {
      liste_fonctions.push("Correspondant officiel");
    }
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archivé",
      registration_number: el.registration_number,
      status: el.status,
      all_name:
        el.membre?.first_name && el.membre?.name
          ? el.membre?.name.toUpperCase() + " " + el.membre?.first_name
          : "",
      date_of_birth: el.membre?.date_of_birth,
      affilie_id: el.affilie_id,
      equipe_id: el.equipe_id,
      first_affiliation_date: el.first_affiliation_date,
      disafilliation_date: el.disafilliation_date,
      last_status_date: el.last_status_date,
      function: liste_fonctions.length ? liste_fonctions.join(" - ") : "-",
    };
  }
};
