import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as ROUTES from "../../constants/routes";

import {  useStyles } from "../../theme";

import { struct, route, routeTable, items, getShortlists } from "./items";
import {
  structTable as structTeams,
  columnsChild as columnsTeams,
} from "../Teams/items";
import Entities from "../Entities";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
} from "../../components";
import qs from "qs";

export default function Member(props) {
  const classes = useStyles();
  const { state } = props?.location;
const {userInfos} = props;

  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [loading, setLoading] = useState(true);

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  return (
    <div className={classes.root}>
      <CardHeader
        
isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"terrain"}
        id={id}
        item={"terrain"}
        editTitle={"Modifier le terrain"}
        addTitle={"Ajouter un terrain"}
        data={data}
        routeTable={routeTable}
        nextRoute={'terrain'}
        saveable={userInfos?.access_terrains_rights > 1 || userInfos?.admin}
      />
      {!loading && data && (
        <div className={classes.content}>
        <>
          <FormCards
            editable={userInfos?.access_terrains_rights > 1 || userInfos?.admin}
            cardTitles={["Dénomination", "Coordonnées"]}
            data={data}
            items={items}
            setData={setData}
            getShortlists={getShortlists}
          />
          <CardActions
            routeTable={routeTable}
            route={route}
            data={data}
            id={id}
            archivable={userInfos?.access_terrains_rights > 2 || userInfos?.admin}
isArchived={archived}
        setArchived={setArchived}
          />
          {data?.teams && (
            <Entities
              isSub
              routeTable={"teams"}
              userInfos={userInfos}
              child_route={ROUTES.TEAM}
              items={{
                nameTable: "Team(s) associée(s)",
                routeTable : 'teams',
                columns: columnsTeams,
                structTable: structTeams,
                data: data?.teams,
                state: {
                  prevId: id,
                  prevRoute: route,
                  routeParam:
                    "?terrain_id=" +
                    id +
                    "&terrain_name=" +
                    data?.name?.value
                },
              }}
            />
          )}
        </>
      </div>
      )}
    </div>
  );
}
