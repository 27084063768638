import React, { useEffect, useState, useRef } from "react";
import { red } from "@material-ui/core/colors";
import { Link, withRouter, Locationuse } from "react-router-dom";
import { compose } from "recompose";
import MUIDataTable, { TableBody } from "mui-datatables";
import Loading from "./components/Loading";
import toast from "react-hot-toast";
import { ChromePicker } from "react-color";
import Resizer from "react-image-file-resizer";

import {
  createTheme,
  MuiThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Button,
  ButtonGroup,
  OutlinedInput,
  MenuItem,
  IconButton,
  Tooltip,
  TextField,
  FormControlLabel,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Typography,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  InputAdornment,
  TableFooter,
  TableRow,
  TablePagination,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  Box,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import MaskedInput from "react-text-mask";
import * as Icon from "@material-ui/icons";

import axios from "axios";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

import { primaryColor, themeApp } from "./theme";
import clsx from "clsx";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";

import { startOfWeek, endOfWeek, addDays } from "date-fns";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

//import InfiniteCalendar from "react-infinite-calendar";
//import "react-infinite-calendar/styles.css"; // Make sure to import the default stylesheet
import history from "./history";
import { Switch } from "@material-ui/core";
//const MultipleDatesCalendar = withMultipleDates(Calendar);

import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

const weekDays = ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"];
const months = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

// export function CustomDateInput({ value, updateValue }) {
//   const handleChange = (event) => {
//     updateValue(event.target.value);
//   };

//   return (
//     <TextField
//       type="date"
//       value={value}
//       onChange={handleChange}
//       InputLabelProps={{
//         shrink: true,
//       }}
//       fullWidth
//     />
//   );
// }

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export function CalendarPicker(props) {
  const { dates, setDates, type } = props;
  return (
    <>
      <Calendar
        multiple
        value={dates}
        onChange={setDates}
        shadow={false}
        minDate={new Date()}
        months={months}
        weekDays={weekDays}
        onlyShowInRangeDates={true}
        plugins={[<DatePanel />]}
        numberOfMonths={3}
        mapDays={({ date }) => {
          //let isWeekend = [0, 6].includes(date.weekDay.index);
          //if (!isWeekend)
          //  return {
          //    disabled: true,
          //    style: { color: "#ccc" },
          // };
        }}
      />
      {/*<InfiniteCalendar
        //Component={MultipleDatesCalendar}
        selected={dates}
        width={"100%"}
        height={400}
        //interpolateSelection={defaultMultipleDateInterpolation}
        theme={{
          selectionColor: `${primaryColor}`,
          textColor: {
            default: "#333",
            active: "#FFF",
          },
          weekdayColor: `${primaryColor}`,
          headerColor: `${primaryColor}`,
          floatingNav: {
            background: `${primaryColor}cc`,
            color: "#FFF",
            chevron: "transparent",
          },
        }}
        disabledDays={type === "vet" ? [1, 2, 3, 4, 5, 7] : [1, 2, 3, 4, 5, 6]}
        displayOptions={{
          showOverlay: false,
          showHeader: false,
        }}
        locale={{
          headerFormat: "dddd, D MMM",
          weekdays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
          blank: "Aucune date selectionnée",
          todayLabel: {
            long: "Aujourd'hui",
            short: "Auj.",
          },
          weekStartsOn: 1,
          locale: require("date-fns-1/locale/fr"),
        }}
        onSelect={(_, e) => {
          let aux = defaultMultipleDateInterpolation(_, dates);
          setDates(aux);
        }}
      />*/}
    </>
  );
}

export function CustomLinkButton(props) {
  const { tableMeta, route } = props;
  let id = tableMeta?.rowData[0];
  return (
    <IconButton
      component={React.forwardRef((props, ref) => (
        <Link to={{ pathname: route + "?id=" + id }} {...props} ref={ref} />
      ))}
      onClick={(e) => {
        e.stopPropagation();
      }}
      to={{ pathname: route + "?id=" + id }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon.OpenInNew fontSize={"small"} />
    </IconButton>
  );
}

export function CustomArchivingButton(props) {
  const { tableMeta, route, routeTable } = props;
  let id = tableMeta?.rowData[0];
  const [showDialog, setShowDialog] = useState(false);
  return (
    <div>
      <IconButton
        color="default"
        onClick={(e) => {
          e.stopPropagation();
          setShowDialog(true);
        }}
      >
        <Icon.Archive />
      </IconButton>
      <AlertDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogText={"Êtes-vous sûr de vouloir archiver cette ligne ?"}
        dialogTitle={"Archiver les données"}
        primary_action={"Archiver"}
        secondary_action={"Annuler"}
        handlePrimaryAction={(e) => {
          e.stopPropagation();
          archiveData(route, routeTable, id).then(() => {
            setShowDialog(false);
          });
        }}
        handleSecondaryAction={(e) => {
          e.stopPropagation();
          setShowDialog(false);
        }}
      />
    </div>
  );
}

export function CustomDeleteButton(props) {
  const { tableMeta, route, routeTable } = props;
  let id = tableMeta?.rowData[0];
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  return (
    <div>
      <IconButton
        color="default"
        onClick={(e) => {
          e.stopPropagation();
          setShowDialogDelete(true);
        }}
      >
        <Icon.DeleteForever />
      </IconButton>
      <AlertDialog
        showDialog={showDialogDelete}
        setShowDialog={setShowDialogDelete}
        dialogText={"Êtes-vous sûr de vouloir supprimer cette ligne ?"}
        dialogTitle={"Supprimer les données"}
        primary_action={"Supprimer"}
        secondary_action={"Annuler"}
        handlePrimaryAction={(e) => {
          e.stopPropagation();
          deleteData(route, routeTable, id).then(() => {
            setShowDialogDelete(false);
            emptyCacheByName(routeTable);
          });
        }}
        handleSecondaryAction={(e) => {
          e.stopPropagation();
          setShowDialogDelete(false);
        }}
      />
    </div>
  );
}

export function ListDates(props) {
  const { dates, setDates, type } = props;
  const classes = useStylesButton();
  const handleDelete = (d) => {
    setDates(dates.filter((e) => e !== d));
  };
  let minDates = 28;
  return (
    <>
      {dates?.length === 0 && (
        <Alert severity="info" style={{ marginBottom: ".5em" }}>
          <AlertTitle>
            <strong>Information</strong>
          </AlertTitle>
          Pour activer la pré-sélection rapide, veuillez sélectionner la{" "}
          <span style={{ fontWeight: 600 }}>1ère date</span>
        </Alert>
      )}
      {dates?.length >= 0 && dates?.length < minDates && (
        <Alert severity="warning" style={{ marginBottom: ".5em" }}>
          <AlertTitle>
            <strong>Attention</strong>
          </AlertTitle>
          Il vous reste au minimum{" "}
          <span style={{ fontWeight: 600 }}>
            {minDates - dates.length} date(s)
          </span>{" "}
          à sélectionner
        </Alert>
      )}
      {dates?.length >= 1 && (
        <Alert severity="info" style={{ marginBottom: ".5em" }}>
          <AlertTitle>
            <strong>Information</strong>
          </AlertTitle>
          Vous avez sélectionné{" "}
          <span style={{ fontWeight: 600 }}> {dates.length} date(s)</span>
        </Alert>
      )}
      {/*{dates?.length <= 0 && (
        <Alert severity="info">
          <AlertTitle>Information</AlertTitle>
          Veuillez sélectionner les dates dans la colonne de gauche
        </Alert>
      )}
      <br />
      {dates?.length > 0 && (
        <Alert severity="info">
          <AlertTitle>Information</AlertTitle>
          Nombres de dates sélectionnées: {dates?.length}
        </Alert>
      )}*/}
      <div style={{ margin: ".75em 0", display: "flex" }}>
        <Button
          disabled={dates?.length === 0}
          onClick={() => {
            if (dates.length > 0) {
              let date = new Date(dates[0]);
              let _dates = [];
              for (let i = 0; i < 28; i++) {
                let day = startOfWeek(date);
                if (type === "vet") {
                  day = endOfWeek(date);
                }
                _dates.push(day);
                date = addDays(date, 7);
              }
              setDates(_dates);
            }
          }}
          variant="contained"
        >
          Pré-sélection rapide
        </Button>
        {dates.length >= 1 && (
          <DeleteButton
            onClick={() => {
              setDates([]);
            }}
            style={{ marginLeft: "1em" }}
            startIcon={<Icon.Delete />}
          >
            Tout supprimer
          </DeleteButton>
        )}
      </div>
    </>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertDialog(props) {
  const {
    showDialog,
    setShowDialog,
    dialogComponent,
    dialogText,
    dialogTitle,
    primary_action,
    secondary_action,
    handleSecondaryAction,
    handlePrimaryAction,
  } = props;

  const handleClose = () => {
    setShowDialog(false);
  };

  const shouldShowActions = primary_action || secondary_action;

  return (
    <Dialog
      style={{ borderRadius: "1em" }}
      open={showDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {dialogText}
        </DialogContentText>
        {dialogComponent && dialogComponent()}
      </DialogContent>
      {shouldShowActions && (
        <DialogActions>
          {secondary_action && (
            <Button onClick={handleSecondaryAction} color="default">
              {secondary_action}
            </Button>
          )}
          {primary_action && (
            <Button
              onClick={handlePrimaryAction}
              color="primary"
              variant="contained"
            >
              {primary_action}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

const useFormCardStyles = makeStyles((theme) => ({
  root: {
    width: `100%`,
    padding: "0!important",
  },
  formHead: {
    borderRadius: "1em 1em 0 0",
    fontWeight: 600,
    background: "#FFF",
    color: "#00000080",
    borderBottomColor: "#f1f1f1",
    padding: "18px 28px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
    "& > span": {
      display: "flex",
      alignItems: "center",
      fontSize: "15px",
    },
  },
  formBody: {
    padding: theme.spacing(3),
    margin: "0px!important",
    width: "100%",
  },
  input: {
    width: "100%",
    minWidth: "100%",
    "& fieldset": {
      borderColor: "#00000015!important",
    },
  },
  expInputRoot: {
    margin: "-8px 0 0 14px",
  },
  notEditable: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#00000065",
    },
    "& .Mui-disabled": {
      color: "#000000",
    },
    "& input": {
      backgroundColor: "transparent",
    },
  },
  checkbox: {
    display: "block",
    width: "fit-content",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

export function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
      showMask
    />
  );
}

export function Circle(props) {
  const { color, width } = props;
  return (
    <span
      style={{
        borderRadius: "100%",
        display: "block",
        width: width,
        height: width,
        background: color,
      }}
    />
  );
}

export function FormCards(props) {
  const {
    data,
    cardTitles,
    setData,
    items,
    getShortlists,
    fullWidthCol,
    editable,
  } = props;

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginTop: "1em",
        marginBottom: "1em",
        alignItems: "stretch",
      }}
    >
      {cardTitles &&
        cardTitles.map((title, i) => {
          if (cardTitles?.length > 1) {
            return (
              <Grid
                item
                md={fullWidthCol ? 12 : 6}
                sm={12}
                xl={fullWidthCol ? 6 : 4}
                height="100%"
                key={title + "" + i}
              >
                <FormCard
                  data={data}
                  title={title}
                  items={items[i]}
                  setData={setData}
                  getShortlists={getShortlists}
                  editable={editable}
                />
              </Grid>
            );
          }
          return (
            <Grid
              item
              key={title + "" + i}
              width="100%"
              height="100%"
              sm={12}
              md={12}
              lg={12}
            >
              <FormCard
                data={data}
                style={{ height: "100%" }}
                title={title}
                items={items[i]}
                setData={setData}
                getShortlists={getShortlists}
                isAlone={fullWidthCol ? false : true}
                editable={editable}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

export function FicheMatch(props) {
  const classes = useFormCardStyles();
  const { title } = props;
  return (
    <>
      <Paper elevation={1} style={{ height: "100%", borderRadius: "1em" }}>
        <div className={classes.formHead}>
          <span>{title}</span>
        </div>
        <Grid
          container
          direction="row"
          className={classes.formBody}
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={3}
        >
          -
        </Grid>
      </Paper>
    </>
  );
}

export function FormCard(props) {
  const classes = useFormCardStyles();
  const classesBtn = useStylesButton();
  const [helperText, setHelperText] = useState({});
  const [options, setOptions] = useState([]);
  const {
    data,
    setData,
    title,
    items,
    isAlone,
    getShortlists,
    editable,
  } = props;
  const [colorPicker, setColorPicker] = useState(undefined);
  const OPTIONS_LIMIT = 40;
  const defaultFilterOptions = createFilterOptions();
  const handleChange = (e, name, type = undefined) => {
    if (e) {
      if (type === "checkbox") {
        setData({
          ...data,
          [name]: {
            ...data[name],
            checked: e?.target?.checked,
          },
        });
      } else if (type === "date") {
        setData({
          ...data,
          [name]: {
            ...data[name],
            value: e,
          },
        });
      } else if (type === "color") {
        setData({
          ...data,
          [name]: {
            ...data[name],
            value: e,
          },
        });
      } else {
        setData({
          ...data,
          [name]: {
            ...data[name],
            value: e?.target?.value,
          },
        });
      }
    }
  };
  const handleChangeColor = (color) => {
    setColorPicker(color?.hex);
  };
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        150,
        150,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const uploadToBase64 = async (e, name) => {
    if (e?.target?.files && e?.target?.files[0]) {
      var FR = new FileReader();
      const image = await resizeFile(e?.target?.files[0]);

      FR.addEventListener("load", function (e) {
        setData({
          ...data,
          [name]: {
            ...data[name],
            value: image,
          },
        });
      });
      FR.readAsDataURL(e?.target?.files[0]);
    }
  };
  const filterOptionsUnlimited = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const filterOptions = (options, state, setData) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  useEffect(() => {
    if (getShortlists) {
      getShortlists(setOptions);
    }
  }, [getShortlists]);
  var isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const uploadInputRef = useRef(null);
  return (
    <>
      <Paper elevation={1} style={{ height: "100%", borderRadius: "1em" }}>
        <form
          className={classes.form}
          noValidate
          autoComplete={isChrome ? "disabled" : "off"}
        >
          <div className={classes.formHead}>
            <span>{title}</span>
          </div>
          {data ? (
            <Grid
              container
              direction="row"
              className={classes.formBody}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              {items?.map((el, i) => {
                let name = el;
                let type = data[el]?.type;
                if (data[el]?.display !== "none") {
                  return (
                    <Grid
                      item
                      lg={isAlone ? 3 : 6}
                      md={6}
                      sm={12}
                      key={name + "" + i}
                    >
                      {type === "text" ||
                      type === "number" ||
                      type === "email" ||
                      type === "password" ? (
                        <TextField
                          required={data[el]?.required}
                          label={data[el]?.label}
                          type={type}
                          name={name}
                          disabled={data[el]?.disabled || !editable}
                          error={Boolean(helperText[name])}
                          helperText={helperText[name] ? helperText[name] : ""}
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                          onChange={(e) => {
                            handleChange(e, name, type);
                            if (data[el]?.onChange) {
                              data[el].onChange(
                                e,
                                data,
                                setData,
                                helperText,
                                setHelperText
                              );
                            }
                          }}
                          value={data[el]?.value ?? ""}
                          variant="outlined"
                        />
                      ) : type === "autocomplete" ? (
                        <Autocomplete
                          filterOptions={
                            data[el]?.max == "unlimited"
                              ? filterOptionsUnlimited
                              : filterOptions
                          }
                          options={options[name] ? options[name] : []}
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                          disabled={data[el]?.disabled || !editable}
                          getOptionLabel={(option) =>
                            option && option?.label ? option.label : ""
                          }
                          getOptionSelected={(option, value) =>
                            value === "" || option?.label === value?.label
                          }
                          value={
                            data[el]?.value && data[el]?.value?.value
                              ? {
                                  label: data[el]?.value?.label
                                    ? data[el]?.value?.label
                                    : "",
                                  value: data[el]?.value?.value
                                    ? data[el]?.value?.value
                                    : "",
                                }
                              : null
                          }
                          onChange={(e, value) => {
                            setData({
                              ...data,
                              [name]: {
                                ...data[name],
                                value: value,
                              },
                            });
                            if (data[el]?.onChange) {
                              data[el].onChange(value, data, setData);
                            }
                          }}
                          renderOption={(option) =>
                            option?.label ? option.label : ""
                          }
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "off";
                            return (
                              <>
                                <TextField
                                  {...params}
                                  name={data[el]?.id}
                                  variant="outlined"
                                  disabled={data[el]?.disabled || !editable}
                                  label={data[el]?.label}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: isChrome ? "disabled" : "off",
                                  }}
                                />
                                {data[el]?.value?.helperText && (
                                  <div style={{ margin: "15px 0" }}>
                                    <Alert severity="info">
                                      <div
                                        style={{ fontSize: 14 }}
                                        dangerouslySetInnerHTML={{
                                          __html: data[el]?.value?.helperText,
                                        }}
                                      />
                                    </Alert>
                                  </div>
                                )}
                              </>
                            );
                          }}
                        />
                      ) : type === "space" ? (
                        <div></div>
                      ) : type === "select" ? (
                        <FormControl
                          disabled={data[el]?.disabled || !editable}
                          required={data[el]?.required}
                          variant="outlined"
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                        >
                          <InputLabel id="label">{data[el]?.label}</InputLabel>
                          <Select
                            labelId="label"
                            name={name}
                            value={data[el]?.value ?? ""}
                            onChange={(e) => {
                              handleChange(e, name, type);
                              if (data[el]?.onChange) {
                                data[el].onChange(
                                  e?.target?.value,
                                  data,
                                  setData
                                );
                              }
                            }}
                            label={data[el]?.label}
                          >
                            {data[el]?.values.map((item) => {
                              return (
                                <MenuItem
                                  key={item?.value}
                                  value={item?.value ?? ""}
                                >
                                  {item?.label}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : type === "multiselect" ? (
                        <FormControl
                          variant="outlined"
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                          disabled={data[el]?.disabled || !editable}
                          required={data[el]?.required}
                          style={{ display: !data.comite ? "none" : undefined }}
                        >
                          <InputLabel id={`${name}-label`}>
                            {data[el]?.label}
                          </InputLabel>
                          <Select
                            labelId={`${name}-label`}
                            multiple
                            // S'assure que la valeur est un tableau, quel que soit le type de data[el]?.value
                            value={
                              typeof data[el]?.value === "string"
                                ? data[el]?.value.split(",")
                                : []
                            }
                            onChange={(e) => {
                              const newValue = e.target.value; // newValue est déjà un tableau de chaînes
                              setData({
                                ...data,
                                [name]: {
                                  ...data[name],
                                  value: newValue.join(","), // Convertit le tableau en chaîne pour la sauvegarde
                                },
                              });
                              if (data[el]?.onChange) {
                                data[el].onChange(
                                  newValue.join(","),
                                  data,
                                  setData
                                );
                              }
                            }}
                            input={
                              <OutlinedInput
                                id={`${name}-select-multiple-chip`}
                                label={data[el]?.label}
                              />
                            }
                            renderValue={(selected) => (
                              <div className={classes.chips}>
                                {Array.isArray(selected)
                                  ? selected
                                      .filter((value) => value) // Filtrer pour s'assurer qu'il n'y a pas de chaînes vides ou valeurs nulles
                                      .map((value) => {
                                        const option = data[el]?.values.find(
                                          (opt) => opt.value === value
                                        );
                                        return (
                                          <Chip
                                            key={value}
                                            label={
                                              option ? option.label : value
                                            }
                                            className={classes.chip}
                                          />
                                        );
                                      })
                                  : null}
                              </div>
                            )}
                          >
                            {data[el]?.values.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : type === "date" ? (
                        <MuiPickersUtilsProvider
                          locale={fr}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            variant="inline"
                            autoOk="true"
                            className={
                              !editable
                                ? clsx(classes.input, classes.notEditable)
                                : classes.input
                            }
                            helperText={""}
                            disabled={data[el]?.disabled || !editable}
                            name={name}
                            inputVariant="outlined"
                            required={data[el]?.required}
                            label={data[el]?.label}
                            format="dd/MM/yyyy"
                            value={data[el]?.value ?? null}
                            onChange={(e) => {
                              handleChange(e, name, type);
                              if (data[el]?.onChange) {
                                data[el].onChange(e, data, setData);
                              }
                            }}
                            KeyboardButtonProps={{
                              "aria-label": data[el]?.label,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      ) : type === "time" ? (
                        <FormControl
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                          disabled={data[el]?.disabled || !editable}
                        >
                          <InputLabel
                            className={
                              !editable
                                ? clsx(
                                    classes.input,
                                    classes.notEditable,
                                    classes.expInputRoot
                                  )
                                : clsx(classes.input, classes.expInputRoot)
                            }
                            htmlFor="formatted-text-mask-input"
                          >
                            {data[el]?.label}
                          </InputLabel>
                          <OutlinedInput
                            value={data[el]?.value}
                            defaultValue={"00:00:00"}
                            onChange={(e) => {
                              handleChange(e, name, type);
                              if (data[el]?.onChange) {
                                data[el].onChange(e, data, setData);
                              }
                            }}
                            name={name}
                            id="formatted-text-mask-input"
                            inputComponent={TextMaskCustom}
                            label={data[el]?.label}
                          />
                        </FormControl>
                      ) : type === "date2" ? (
                        <MuiPickersUtilsProvider
                          locale={fr}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            variant="inline"
                            autoOk
                            className={
                              !editable
                                ? clsx(classes.input, classes.notEditable)
                                : classes.input
                            }
                            helperText={""}
                            disabled={data[el]?.disabled || !editable}
                            name={name}
                            inputVariant="outlined"
                            required={data[el]?.required}
                            label={data[el]?.label}
                            format="dd/MM/yyyy"
                            value={data[el]?.value ?? null}
                            onChange={(e) => {
                              handleChange(e, name, type);
                              if (typeof data[el]?.onChange === "function") {
                                data[el].onChange(e, data, setData);
                              } else {
                                console.error(
                                  `onChange is not a function for ${name}`
                                );
                              }
                            }}
                            KeyboardButtonProps={{
                              "aria-label": data[el]?.label,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      ) : type === "checkbox" ? (
                        <FormControlLabel
                          className={classes.checkbox}
                          control={
                            <Switch
                              checked={data[el]?.checked ?? false}
                              disabled={data[el]?.disabled || !editable}
                              onChange={(e) => {
                                handleChange(e, name, type);
                                if (data[el]?.onChange) {
                                  data[el].onChange(
                                    e?.target?.checked,
                                    data,
                                    setData
                                  );
                                }
                              }}
                              name={name}
                            />
                          }
                          label={data[el]?.label}
                        ></FormControlLabel>
                      ) : type === "image" ? (
                        <>
                          <Typography gutterBottom paragraph>
                            {data[el]?.label}
                          </Typography>
                          {!data[el]?.value && (
                            <>
                              <Typography
                                gutterBottom
                                paragraph
                                style={{
                                  color: "#aaa",
                                  fontSize: 14,
                                }}
                              >
                                Aucune image disponible
                              </Typography>
                              <input
                                ref={uploadInputRef}
                                type="file"
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => uploadToBase64(e, name)}
                              />
                              <Button
                                onClick={() =>
                                  uploadInputRef.current &&
                                  uploadInputRef.current.click()
                                }
                                variant="contained"
                                color="primary"
                              >
                                Téléverser
                              </Button>
                            </>
                          )}
                          {data[el]?.value && (
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  width: 200,
                                  height: 200,
                                  position: "relative",
                                  display: "flex",
                                  alignItems: "flex-start",
                                }}
                              >
                                <img
                                  style={{
                                    backgroundColor: data["color"]?.value,

                                    padding: data["color"]?.value ? 15 : 0,
                                  }}
                                  src={data[el]?.value}
                                  width="150"
                                />
                                <IconButton
                                  onClick={() => {
                                    setData({
                                      ...data,
                                      [name]: {
                                        ...data[name],
                                        value: undefined,
                                      },
                                    });
                                  }}
                                  color={"primary"}
                                  variant={"contained"}
                                  style={{ marginLeft: 10 }}
                                >
                                  <Icon.Delete />
                                </IconButton>
                              </div>
                            </div>
                          )}
                        </>
                      ) : type === "color" ? (
                        <>
                          <Typography gutterBottom paragraph>
                            {data[el]?.label}
                          </Typography>
                          <ChromePicker
                            disableAlpha={true}
                            color={
                              data[el]?.value && !colorPicker
                                ? data[el]?.value
                                : colorPicker
                            }
                            onChangeComplete={(color) => {
                              handleChange(color?.hex, name, type);
                            }}
                            onChange={handleChangeColor}
                          />
                        </>
                      ) : type === "textarea" ? (
                        <TextField
                          multiline
                          minRows={6}
                          maxRows={12}
                          required={data[el]?.required}
                          label={data[el]?.label}
                          type={"text"}
                          name={name}
                          disabled={data[el]?.disabled || !editable}
                          error={Boolean(helperText[name])}
                          helperText={helperText[name] ? helperText[name] : ""}
                          className={
                            !editable
                              ? clsx(classes.input, classes.notEditable)
                              : classes.input
                          }
                          onChange={(e) => {
                            handleChange(e, name, type);
                            if (data[el]?.onChange) {
                              data[el].onChange(
                                e,
                                data,
                                setData,
                                helperText,
                                setHelperText
                              );
                            }
                          }}
                          value={data[el]?.value ?? ""}
                          variant="outlined"
                        />
                      ) : (
                        <span key={data[el]?.id}>
                          <Alert severity="error">
                            Problème lors de la récupération de la donnée...
                          </Alert>
                        </span>
                      )}
                    </Grid>
                  );
                }
                return <div key={name + "" + i}></div>;
              })}
            </Grid>
          ) : (
            <div className={classes.formBody}>
              <Alert severity="error">
                Problème lors de la récupération des données...
              </Alert>
            </div>
          )}
        </form>
      </Paper>
    </>
  );
}

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

export async function SaveData(
  route,
  routeTable,
  data,
  id
  //setShowDialogSanctionsSave = undefined
) {
  let _data = {};
  for (let i in data) {
    _data = {
      ..._data,
      [i]:
        data[i]?.type === "date"
          ? formatDate(data[i]?.value)
          : data[i]?.type === "checkbox"
          ? formatBool(data[i]?.checked)
          : data[i]?.type === "autocomplete"
          ? data[i]?.value?.value || null
          : data[i]?.value || null,
    };
  }
  if (data?.feuilles_match) {
    _data = { ..._data, feuilles_match: data.feuilles_match };
  }
  if (data?.confidentiels_arbitrages) {
    _data = {
      ..._data,
      confidentiels_arbitrages: data.confidentiels_arbitrages,
    };
  }
  if (data?.confidentiels_rencontres) {
    _data = {
      ..._data,
      confidentiels_rencontres: data.confidentiels_rencontres,
    };
  }
  if (data?.ballons_or) {
    _data = {
      ..._data,
      ballons_or: data.ballons_or,
    };
  }
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `${route}/update/${id}`, _data, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        toast.success(response?.data?.success, { duration: 4000 });
        /*if(_data.hasOwnProperty('penalties_generated')){
            let match_date = _data?.match_date;
            if(match_date) {
              let fridayDate = checkDateFriday8pm(new Date(match_date));
              if(Boolean(new Date() <= fridayDate) && !Boolean(_data?.penalties_generated) && setShowDialogSanctionsSave) {
                console.log(_data);
                setShowDialogSanctionsSave(true);
              }
            }
          }*/
      })
      .catch((error) => {
        console.log("Erreur:", error.message); // Message de base de l'erreur
        if (error.response) {
          // La requête a été faite et le serveur a répondu avec un statut
          // qui est hors de la plage de 2xx
          console.log("Données: ", error.response.data); // le corps de la réponse
          console.log("Statut: ", error.response.status); // code de statut HTTP
          console.log("En-têtes: ", error.response.headers); // les en-têtes renvoyés par le serveur
        } else if (error.request) {
          // La requête a été faite, mais aucune réponse n'a été reçue
          console.log("Requête: ", error.request);
        } else {
          // Quelque chose s'est mal passé lors de la mise en place de la requête
          console.log(
            "Erreur lors de la mise en place de la requête: ",
            error.message
          );
        }

        const errorMessage = error?.response?.data?.error
          ? error?.response?.data?.error
          : "Problème lors de la sauvegarde...";
        if (error.response.status === 304) {
          toast("Aucune modification effectuée...", {
            duration: 4000,
            icon: "👀",
          });
        } else {
          toast.error(errorMessage, { duration: 4000 });
        }
      });
    if (route !== "account") updateTimeDb(routeTable);
  }
}

async function updateTimeDb(routeTable) {
  let date_now = new Date();
  await axios.post(
    process.env.REACT_APP_API_URL + `latest_update/${routeTable}`,
    date_now,
    {
      headers: { authorization: localStorage.getItem("api_token") },
    }
  );
  localStorage.removeItem(routeTable + "_latest_update");
  if (routeTable === "membres") {
    localStorage.removeItem("shortlist_affiliations");
  }
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date) {
  if (date !== null && isValidDate(new Date(date))) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return null;
}

function formatBool(bool) {
  return bool ? 1 : 0;
}

export async function StoreData(route, routeTable, data, nextRoute) {
  updateTimeDb(routeTable);
  let _data = {};
  for (let i in data) {
    _data = {
      ..._data,
      [i]:
        data[i]?.type === "date"
          ? formatDate(data[i]?.value)
          : data[i]?.type === "checkbox"
          ? formatBool(data[i]?.checked)
          : data[i]?.type === "autocomplete"
          ? data[i]?.value?.value
          : data[i]?.value,
    };
  }
  await axios
    .post(process.env.REACT_APP_API_URL + `${route}/store`, _data, {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      toast.success("Informations enregistrées...");
      // console.log("sjsj", nextRoute);
      if (nextRoute === "new_affiliation") {
        let first_name = r?.data?.created?.first_name;
        let name = r?.data?.created?.name;
        let membre_id = r?.data?.created?.id;
        let url =
          "/affiliation?first_name=" +
          first_name +
          "&name=" +
          name +
          "&membre_id=" +
          membre_id;
        history.push(url);
      } else if (nextRoute === "new_team") {
        let equipe_id = r?.data?.created?.id;
        let club_name = data?.club_id?.value?.label;
        let equipe_name = equipe_id + " " + club_name;
        let cat = data?.category?.value;
        let url =
          "/team?equipe_id=" +
          equipe_id +
          "&equipe_name=" +
          equipe_name +
          "&club_name=" +
          club_name +
          "&cat=" +
          cat;
        toast.success("Équipe créée avec succès !", {
          duration: 4000,
        });

        history.push(url);
      } else if (nextRoute) {
        let _id = r?.data?.created?.id;
        // console.log("dgdggd", _id);
        let url = `/${nextRoute}?id=${_id}`;
        history.push(url);
        toast.success(
          `${
            nextRoute.charAt(0).toUpperCase() + nextRoute.slice(1)
          } ajouté(e) avec succès !`,
          {
            duration: 4000,
          }
        );
      }
    })
    .catch((error) => {
      toast.error(
        error?.response?.data?.error
          ? error?.response?.data?.error
          : "Problème lors de l'enregistrement des données",
        {
          duration: 4000,
        }
      );
    });
}

export async function archiveData(route, routeTable, id, setArchived) {
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `${route}/archive/${id}`, "", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        if (setArchived) setArchived(true);
        toast.success("Les données ont bien été archivées", { duration: 4000 });
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de l'archivage des données",
          {
            duration: 4000,
          }
        );
      });
    updateTimeDb(routeTable);
  }
}

export async function unarchiveData(route, routeTable, id, setArchived) {
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `${route}/unarchive/${id}`, "", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        setArchived(false);
        if (response?.data?.success) {
          toast.success(response?.data?.success, { duration: 4000 });
        } else {
          toast.success("Les données ont bien été désarchivées", {
            duration: 4000,
          });
        }
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors du désarchivage...",
          {
            duration: 4000,
          }
        );
      });
    updateTimeDb(routeTable);
  }
}

export async function multiArchiveData(route, ids) {
  if (ids) {
    await axios
      .post(
        process.env.REACT_APP_API_URL + `${route}/archive_array`,
        {
          archive_array: ids,
        },
        {
          headers: { authorization: localStorage.getItem("api_token") },
        }
      )
      .then((response) => {
        // console.log(response);
        toast.success(
          response?.data?.success
            ? response?.data?.success
            : "Les données ont bien été archivées",
          { duration: 4000 }
        );
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de l'archivage des données",
          {
            duration: 4000,
          }
        );
      });
    updateTimeDb(route);
  }
}

async function deleteData(route, routeTable, id, setDelete) {
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `${route}/delete/${id}`, "", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        if (setDelete) setDelete(true);
        toast.success("Les données ont bien été supprimée", { duration: 4000 });
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de la suppression des données",
          {
            duration: 4000,
          }
        );
      });
    updateTimeDb(routeTable);
  }
}

export async function getData(setData, route) {
  await axios
    .get(process.env.REACT_APP_API_URL + route, {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      if (data) {
        setData(data);
      }
    })
    .catch((e) => {
      // console.log(e?.response);
      toast.error(
        e?.response?.data?.error
          ? e?.response?.data?.error
          : "Problème lors de l'accès aux données",
        { duration: 4000 }
      );
    });
}

export async function fetchUserInfos(setUserInfos, setLoading) {
  let api_token = localStorage.getItem("api_token");
  if (api_token) {
    await axios
      .get(process.env.REACT_APP_API_URL + "user/token/" + api_token, {
        headers: { authorization: api_token },
      })
      .then((r) => {
        let data = r.data;
        if (data) {
          setUserInfos(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.error
            ? e?.response?.data?.error
            : "Veuillez vous reconnecter...",
          { duration: 4000 }
        );
        setLoading(false);
      });
  } else {
    setLoading(false);
  }
}

export async function getDataEntitie(setRows, setLoading, route, struct) {
  let _thereIsAnUpdate = await thereIsAnUpdate(route);
  // console.log("there is an update?", _thereIsAnUpdate);
  if (_thereIsAnUpdate) {
    await axios
      .get(process.env.REACT_APP_API_URL + route, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        let data = response.data;
        if (data) {
          let rows = [];
          for (let el of data) {
            rows.push(struct(el));
          }
          setRows(rows);
          setLoading(false);
          localStorage.setItem(route, JSON.stringify(rows));
          localStorage.setItem(route + "_latest_update", new Date());
          // console.log("data:", data);
        }
      })
      .catch((e) => {
        // console.log(e?.response);
        toast.error(
          e?.response?.data?.error
            ? e?.response?.data?.error
            : "Problème lors de l'accès aux données",
          { duration: 4000 }
        );
        setLoading(false);
      });
  } else {
    let rows = localStorage.getItem(route);
    if (rows) {
      let _rows = JSON.parse(rows);
      setRows(_rows);
      setLoading(false);
    }
  }
}

export function Save(props) {
  const {
    route,
    routeTable,
    data,
    id,
    nextRoute,
    customSaveText,
    customAddText,
    disabled,
  } = props;
  return (
    <SaveButton
      disabled={!data || disabled}
      onClick={() => {
        if (id) {
          SaveData(route, routeTable, data, id);
        } else {
          StoreData(route, routeTable, data, nextRoute);
        }
      }}
    >
      {id
        ? customSaveText
          ? customSaveText
          : "Sauvegarder"
        : customAddText
        ? customAddText
        : "Ajouter"}
    </SaveButton>
  );
}

export const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  },
}))((props) => <Button variant="contained" color="primary" {...props} />);

export function ArchivingButton(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const { route, routeTable, id, isArchived, setArchived } = props;
  const classes = useStylesButton();
  return (
    <div>
      <Button
        className={classes.archivingButton}
        variant="contained"
        color="primary"
        onClick={() => setShowDialog(true)}
        startIcon={<Icon.Archive />}
      >
        {isArchived ? "Désarchiver" : "Archiver"}
      </Button>
      <AlertDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogText={"Êtes-vous sûr de vouloir archiver ces données ?"}
        dialogTitle={"Archiver les données"}
        primary_action={isArchived ? "Désarchiver" : "Archiver"}
        secondary_action={"Annuler"}
        handlePrimaryAction={() => {
          isArchived
            ? unarchiveData(route, routeTable, id, setArchived).then(() => {
                setShowDialog(false);
              })
            : archiveData(route, routeTable, id, setArchived).then(() => {
                setShowDialog(false);
              });
        }}
        handleSecondaryAction={() => setShowDialog(false)}
      />
    </div>
  );
}

export const SaveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: "#11bf53",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      color: theme.palette.common.white,
      backgroundColor: "#11bf53",
    },
  },
}))((props) => (
  <Button variant="contained" elevation={0} color="primary" {...props} />
));

const useStylesButton = makeStyles((theme) => ({
  addButton: {
    fontSize: 16,
  },
  archivingButton: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    fontSize: 14,
    "&:hover": {
      backgroundColor: red[700],
      color: theme.palette.getContrastText(red[700]),
    },
  },
}));

export function AddButton(props) {
  const { route, state, routeParam } = props;
  const classes = useStylesButton();
  return (
    <Tooltip title={"Ajouter"}>
      <IconButton
        className={classes.addButton}
        component={React.forwardRef((props, ref) => (
          <Link
            to={{
              pathname: route,
              search: routeParam ? routeParam : "",
              state,
            }}
            {...props}
            ref={ref}
          />
        ))}
        style={{ order: -1 }}
      >
        <Icon.AddCircleRounded />
      </IconButton>
    </Tooltip>
  );
}

export function RefreshButton(props) {
  const { setRows, setLoading, route, struct } = props;
  const classes = useStylesButton();
  return (
    <Tooltip title={"Rafraîchir"}>
      <IconButton
        className={classes.addButton}
        style={{ order: -1 }}
        onClick={() => {
          toast.success("Les données ont bien été mises à jour");
          localStorage.removeItem(route + "_latest_update");
          getDataEntitie(setRows, setLoading, route, struct);
        }}
      >
        <Icon.SyncRounded />
      </IconButton>
    </Tooltip>
  );
}
/*
export function RemoveButton(props) {
  const { setRows, setLoading, route, struct } = props;
  const classes = useStylesButton();
              const [showPopup, setShowPopup] = useState(false);

  return (
    <>
            <AlertDialog
        showDialog={showPopup}
        setShowDialog={setShowPopup}
        dialogText={"Êtes-vous sûr de vouloir archiver toutes les données ?"}
        dialogTitle={"Archiver les données"}
        primary_action={"Confirmer"}
        secondary_action={"Annuler"}
        handlePrimaryAction={() => {
          toast.success("Les données ont bien été archivées");
          setShowPopup(false);
        }}
        handleSecondaryAction={() => setShowPopup(false)}
      />
    <Tooltip title={"Tout archiver"}>
      <IconButton
        className={classes.addButton}
        style={{ order: 5}}
        onClick={() => {
          setShowPopup(true);
          //localStorage.removeItem(route + "_latest_update");
          //getDataEntitie(setRows, setLoading, route, struct);
        }}
      >
        <Icon.Archive />
      </IconButton>
    </Tooltip>
    </>
  );
}
*/

export function CardActions(props) {
  const { id, route, routeTable, isArchived, setArchived, archivable } = props;
  if (archivable) {
    return (
      <div
        style={{
          margin: ".75em",
        }}
      >
        <ArchivingButton
          id={id}
          route={route}
          routeTable={routeTable}
          isArchived={isArchived}
          setArchived={setArchived}
        />
      </div>
    );
  }
  return <></>;
}

export function padLeadingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    borderBottom: "2px solid",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    fontWeight: "600",
    fontSize: 15,
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
    "& .MuiTab-wrapper": {
      display: "flex",
      flexDirection: "row",
      "& svg": {
        marginRight: ".25em",
        marginBottom: "0!important",
      },
    },
  },
}))((props) => (
  <Tab
    disableRipple
    {...props}
    icon={props?.locked ? <Icon.Lock fontSize="small" /> : undefined}
  />
));

const useStylesCardHeader = makeStyles((theme) => ({
  whiteSpace: {
    marginRight: theme.spacing(6),
  },
  appBar: {
    background: "white",
    boxShadow: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  toolbar: {
    background: "white",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuTitle: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Open Sans",
    },
  },
  menuRight: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
}));

export function CardHeader(props) {
  const {
    route,
    data,
    id,
    nextRoute,
    addTitle,
    editTitle,
    isArchived,
    disabled,
    routeTable,
    title,
    customSave,
    saveable,
  } = props;
  const classes = useStylesCardHeader();
  return (
    <>
      <AppBar
        position="sticky"
        color="default"
        elevation={1}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.whiteSpace}></div>
          {!title && (
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="go back"
              onClick={() => {
                if (history.action !== "POP") history.goBack();
                else if (routeTable) {
                  history.push(routeTable);
                }
              }}
              disabled={history.action === "POP" && !routeTable}
            >
              <Icon.ArrowBack />
            </IconButton>
          )}
          <div className={classes.menuTitle}>
            <Typography className={classes.title} noWrap>
              {id ? editTitle : !id && !title ? addTitle : title}
              {id && title}
            </Typography>
            {Boolean(isArchived) && (
              <div
                style={{
                  border: "1px solid",
                  padding: ".25em .75em",
                  marginLeft: "1.25em",
                  borderRadius: "5px",
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                Archive
              </div>
            )}
          </div>
          <div className={classes.menuRight}>
            {!customSave ? (
              <>
                {routeTable && saveable ? (
                  <Save
                    id={id}
                    routeTable={routeTable}
                    route={route}
                    data={data}
                    nextRoute={nextRoute}
                    disabled={Boolean(disabled)}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              customSave
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: `100%`,
    padding: "2em",
  },
  container: {
    width: "100%",
  },
  sub: {
    width: "100%",
    padding: "1em 0 3em",
  },
  iconButton: {
    fontSize: 22,
    "&:hover": {
      color: "black",
    },
  },
  tableRow: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.03)!important",
    },
  },
  iconMedium: {
    fontSize: 22,
  },
  tableBodyCell: {
    padding: "0 28px",
    height: 54,
    borderBottomColor: "#eee",
  },
  activeCell: {
    color: "#000000cc",
  },
  title: {
    color: "#000000cc",
    fontSize: 28,
  },
  fab: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: "fixed",
    "&:hover": {
      color: "white",
    },
  },
}));

export function checkDateFriday8pm(date = new Date()) {
  // Date donnée
  let newDate = date;
  // Trouver le jour de la semaine correspondant à la date donnée
  const jourSemaine = newDate.getDay();

  // Si la date donnée est un vendredi et l'heure est supérieure ou égale à 20h, ajouter une semaine
  if (jourSemaine === 5) {
    newDate.setDate(newDate.getDate() + 7);
  }

  // Trouver la prochaine date du vendredi à 20h
  while (newDate.getDay() !== 5) {
    newDate.setDate(newDate.getDate() + 1);
  }
  newDate.setHours(20, 0, 0, 0);

  // Afficher la prochaine date du vendredi à 20h
  // console.log("new date :" , newDate);

  return newDate;
}

export async function fetchData(
  route,
  setData,
  struct,
  id,
  setLoading,
  setArchived,
  setValidated
  //setShowDialogSanctionsSave
) {
  if (id) {
    await axios
      .get(process.env.REACT_APP_API_URL + `${route}/${id}`, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        let data = response.data;
        // console.log("data : ", data);
        if (data) {
          setArchived(data?.archived);
          if (data?.validated_game_sheet) {
            setValidated(data?.validated_game_sheet);
          }
          /*
          if(data.hasOwnProperty('penalties_generated')){
            let match_date = data?.match_date;
            if(match_date) {
              let fridayDate = checkDateFriday8pm(new Date(match_date));
              if(Boolean(new Date() <= fridayDate) && !Boolean(data?.penalties_generated)) {
                setShowDialogSanctionsSave(true);
              }
            }
          }
          */
          let _data = struct(data);
          setData(_data);
        }
      })
      .catch((error) => {
        // console.log(error?.response);
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de l'accès aux données",
          { duration: 4000 }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    setData(struct(undefined));
    setLoading(false);
  }
}

export const isDate = (date) => {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};

//calcule de l'âge
export function calculateAge(birthday, date) {
  if (!birthday || !date) return null;

  const referenceDate = new Date(date);
  const birthdayDate = new Date(birthday);
  let age = referenceDate.getFullYear() - birthdayDate.getFullYear();
  const monthDifference = referenceDate.getMonth() - birthdayDate.getMonth();

  if (
    monthDifference < 0 ||
    (monthDifference === 0 && referenceDate.getDate() < birthdayDate.getDate())
  ) {
    age--;
  }

  return age;
}

// traduction colonne statut (rencontre)
export function translateStatus(status) {
  switch (status) {
    case "postponed":
      return "Reporté";
    case "adjourned":
      return "Remis";
    case "stopped":
      return "Arrêté";
    // Ajoute d'autres cas au besoin
    default:
      return status; // Retourne la valeur originale si pas de traduction trouvée
  }
}

//conversion de la date
export function convertDate(date) {
  if (!date) return "Pas de date d'anniversaire";
  // Retourne une chaîne vide si date n'est pas défini
  const parts = date.split("-"); // Divise la chaîne de caractères en un tableau
  if (parts.length === 3) {
    // Réarrange les parties de la date si le format est correct
    return `${parts[2]}/${parts[1]}/${parts[0]}`; // Formate en JJ/MM/AA
  }
  return date; // Retourne la chaîne originale si le format n'est pas attendu
}

export async function thereIsAnUpdate(route) {
  const trigger = await axios
    .get(process.env.REACT_APP_API_URL + `latest_update/${route}`, {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((response) => {
      let data = response.data;
      let latest_update_server = data.updated_at;
      let latest_update_local = localStorage.getItem(`${route}_latest_update`);
      // console.log("latest local :", latest_update_local);
      // console.log("latest server :", latest_update_server);
      if (new Date(latest_update_local) < new Date(latest_update_server)) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      // console.log(error?.response);
      toast.error(
        error?.response?.data?.error
          ? error?.response?.data?.error
          : "Problème lors de l'accès aux données",
        { duration: 4000 }
      );
    });
  return trigger;
}

export const getMuiTheme = () =>
  createTheme({
    ...themeApp,
    overrides: {
      MUIDataTable: {
        root: {
          borderRadius: "1em",
        },
        paper: {
          fontFamily: `"Open Sans", sans-serif!important`,
          borderRadius: "1em",
        },
      },
      MuiToolbar: {
        root: {
          background: "#FFF",
          borderRadius: "1em 1em 0 0",
          paddingTop: ".75em",
          paddingBottom: ".75em",
        },
      },
      MUIDataTableFilterList: {
        root: {
          background: "#FFF",
          margin: 0,
          borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
          padding: "0 .75em .75em .75em",
        },
        chip: {
          display: "none",
        },
      },
      MuiTablePagination: {
        toolbar: {
          background: "white",
          borderRadius: 0,
        },
      },
      MUIDataTableToolbar: {
        titleText: {
          fontWeight: 600,
          color: "#00000080",
        },
        actions: {
          display: "flex",
          flexDirection: "row",
          flex: "initial",
        },
      },
      MuiTableCell: {
        root: {
          padding: "10px 15px",
          fontSize: 13,
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        },
        head: {
          backgroundColor: "#fff!important",
          fontWeight: "600",
          color: "#00000080",
          zIndex: "5!important",
          "& div": {
            fontWeight: "600",
            color: "#00000080",
          },
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          //backgroundColor: 'blue',
        },
      },
      MuiTableFooter: {
        root: {
          "& .MuiToolbar-root": {
            backgroundColor: "white",
          },
        },
      },
    },
  });

function CustomToolbar(props) {
  const {
    isSub,
    setLoading,
    setRows,
    route,
    struct,
    child_route,
    state,
  } = props;
  return (
    <>
      {/*
            <span>
          <RemoveButton
            route={child_route}
            routeParam={state?.routeParam}
            state={state}
          />
        </span>
  */}
      {!isSub && (
        <>
          <span>
            <RefreshButton
              setRows={setRows}
              setLoading={setLoading}
              route={route}
              struct={struct}
            />
          </span>
        </>
      )}
      <span>
        <AddButton
          route={child_route}
          routeParam={state?.routeParam}
          state={state}
        />
      </span>
    </>
  );
}

const useStylesSearch = makeStyles((theme) => ({
  main: {
    display: "flex",
    flex: "1 0 auto",
  },
  searchText: {
    flex: "1 0",
    maxWidth: 350,
    background: "white",
    position: "relative",
  },
  searchInput: {
    borderRadius: ".5em",
    position: "relative",
  },
  clearIcon: {
    "&:hover": {
      color: "black",
    },
  },
}));

export function emptyCache() {
  if ("caches" in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
  }
}

export function emptyCacheByName(name) {
  localStorage.removeItem(name);
  // Makes sure the page reloads. Changes are only visible after you refresh.
  window.location.reload(true);
}

function CustomSearchRender(props) {
  const { onSearch, options, searchText, name, isSub } = props;
  const classes = useStylesSearch();
  useEffect(() => {
    if (!isSub) {
      let route = name.toLowerCase();
      let local_value = localStorage.getItem("search_" + route);
      if (local_value) {
        onSearch(local_value);
      }
    }
  }, [isSub, name, onSearch]);

  const handleTextChange = (event) => {
    onSearch(event.target.value);
    if (!isSub) {
      let route = name.toLowerCase();
      localStorage.setItem("search_" + route, event.target.value);
    }
  };

  const handleTextClear = () => {
    onSearch("");
    if (!isSub) {
      let route = name.toLowerCase();
      localStorage.removeItem("search_" + route);
    }
  };

  return (
    <div className={classes.main}>
      <TextField
        variant="outlined"
        className={classes.searchText}
        size="small"
        placeholder={"Rechercher"}
        InputProps={{
          "aria-label": options.textLabels.toolbar.search,
          className: classes.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <Icon.SearchOutlined />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {searchText && (
                <IconButton
                  size="small"
                  className={classes.clearIcon}
                  onClick={handleTextClear}
                >
                  <Icon.Clear />
                </IconButton>
              )}
            </>
          ),
        }}
        value={searchText || ""}
        onChange={handleTextChange}
        fullWidth={true}
      />
    </div>
  );
}

export function CustomFooter(props) {
  const {
    count,
    page,
    rowsPerPage,
    changeRowsPerPage,
    changePage,
    name,
    route,
  } = props;
  let pages = [];
  let max_page = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
  for (let i = 0; i <= max_page; i++) {
    pages.push(i);
  }
  return (
    <TableFooter key={name}>
      <TableRow>
        <TableCell style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              fontSize: 14,
              margin: 20,
              color: "black",
            }}
          >
            Page n°
          </span>
          <FormControl
            style={{
              fontSize: 14,
            }}
          >
            <Select
              value={page}
              style={{ fontSize: 14 }}
              onChange={(e) => changePage(e?.target?.value)}
            >
              {pages.map((value) => {
                return (
                  <MenuItem key={value} value={value}>
                    {value + 1}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
        <TablePagination
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={`${name} par page`}
          nextIconButtonText={"Page suivante"}
          backIconButtonText={"Page précédente"}
          onPageChange={(_, page) => changePage(page)}
          onRowsPerPageChange={(event) => {
            changeRowsPerPage(event.target.value);
            localStorage.setItem("rows_per_page_" + route, event.target.value);
          }}
          rowsPerPageOptions={[25, 50, count]}
        />
      </TableRow>
    </TableFooter>
  );
}

function CustomBodyRenderArchiving(props) {
  const { value, updateValue, tableMeta } = props;
  const [showDialog, setShowDialog] = useState(false);
  let id = tableMeta?.rowData[0];
  let rowIndex = tableMeta?.rowIndex;
  let routeTable = window.location.pathname.replace(/\//g, "");
  let route = routeTable.slice(0, -1);
  return (
    <>
      <IconButton
        color="default"
        onClick={(e) => {
          e.stopPropagation();
          setShowDialog(true);
        }}
      >
        <Icon.Archive />
      </IconButton>
      <AlertDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogText={"Êtes-vous sûr de vouloir archiver ces données ?"}
        dialogTitle={"Archiver les données"}
        primary_action={"Archiver"}
        secondary_action={"Annuler"}
        handlePrimaryAction={(e) => {
          e.stopPropagation();
          // console.log("tablemeta", tableMeta);
          archiveData(route, routeTable, id).then(() => {
            setShowDialog(false);
            //tableMeta.tableData.slice(rowIndex, 1);
          });
        }}
        handleSecondaryAction={(e) => {
          e.stopPropagation();
          setShowDialog(false);
        }}
      />
    </>
  );
}

function CustomToolbarSelect(props) {
  const { selectedRows, displayData, route } = props;
  const [showDialog, setShowDialog] = useState(false);
  const handleMultiArchive = (rowsIndex, displayData) => {
    let rows_id = [];
    for (let i in rowsIndex) {
      let id = rowsIndex[i]?.index;
      let row_id = displayData[id]?.data[0];
      if (row_id) {
        rows_id.push(row_id);
      }
    }
    // console.log(rows_id);
    multiArchiveData(route, rows_id).then(() => {
      setShowDialog(false);
    });
  };
  return (
    <>
      <IconButton
        color="default"
        onClick={(e) => {
          e.stopPropagation();
          setShowDialog(true);
        }}
      >
        <Icon.Archive />
      </IconButton>
      <AlertDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        dialogText={"Êtes-vous sûr de vouloir archiver ces données ?"}
        dialogTitle={"Archiver les données"}
        primary_action={"Archiver"}
        secondary_action={"Annuler"}
        handlePrimaryAction={(e) => {
          e.stopPropagation();
          let rowsIndex = selectedRows.data;
          handleMultiArchive(rowsIndex, displayData);
        }}
        handleSecondaryAction={(e) => {
          e.stopPropagation();
          setShowDialog(false);
        }}
      />
    </>
  );
}

export const archiving = {
  name: "archiving",
  label: "Archiver",
  options: {
    filter: false,
    sort: false,
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <CustomBodyRenderArchiving
          value={value}
          tableMeta={tableMeta}
          updateValue={updateValue}
        />
      );
    },
  },
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export function RenderExpandableRow(props) {
  let rows = [];
  if (props.rows) {
    rows = JSON.parse(props.rows);
    if (!(typeof rows === "object")) {
      rows = props.rows;
    }
  }
  return (
    <tr style={{ backgroundColor: "rgb(251 251 251)" }}>
      <td
        colSpan={99}
        style={{
          width: "100%",
          padding: "2em",
        }}
      >
        {typeof rows !== "string" ? (
          <table className="table-stats">
            <thead>
              <tr>
                <th align="center">Année</th>
                <th align="center">Mois</th>
                <th align="center">Affiliations</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(rows).map((key, index) => {
                return (
                  <tr key={key}>
                    <td align="center">{rows[key]?.annee}</td>
                    <td align="center">{rows[key]?.mois}</td>
                    <td align="center">{rows[key]?.nb}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p
            style={{
              fontWeight: "bold",
              fontSize: 13,
              textAlign: "center",
            }}
          >
            {rows.replaceAll('"', "")}
          </p>
        )}
      </td>
    </tr>
  );
}

export function EntitieBase(props) {
  const classes = useStyles();
  const {
    name,
    rows,
    columns,
    child_route,
    history,
    isSearchable,
    isExpandable,
    disableAdd,
    isSub,
    state,
    isLoading,
    setRows,
    setLoading,
    route,
    struct,
    sortOrder,
    userInfos,
  } = props;
  const handleClickRow = (pathname, search, state) => {
    history.push({
      pathname,
      search,
      state,
    });
  };
  let _page = 0;
  let _rowsPerPage = 25;
  const [zoomLevel, setZoomLevel] = useState(100);
  if (!isSub) {
    if (localStorage.getItem("page_" + route)) {
      _page = Number(localStorage.getItem("page_" + route));
    }
    if (localStorage.getItem("rows_per_page_" + route)) {
      _rowsPerPage = Number(localStorage.getItem("rows_per_page_" + route));
    }
  }
  const access_view = userInfos["access_" + route + "_view"];
  const access_rights = userInfos["access_" + route + "_rights"];
  const admin = userInfos?.admin;
  let col = columns;
  if (!admin) {
    if (access_rights < 3) {
      col = col.filter((data) => data.name != "archiving");
    }
    if (access_view < 2) {
      col = col.filter((data) => data.name != "link");
    }
  }
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 10);
  };
  const buttonGroupStyle = {
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "0 0 5px #00000015",
    marginBottom: 10,
  };
  const tableContainerStyle = {
    zoom: `${zoomLevel}%`,
  };
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <div className={!isSub ? classes.container : undefined}>
        {!isSub && <CardHeader title={name} />}
        <div className={!isSub ? classes.root : classes.sub}>
          <ButtonGroup style={buttonGroupStyle}>
            <IconButton
              onClick={handleZoomIn}
              aria-label="Zoom In"
              size={"small"}
            >
              <Icon.ZoomIn />
            </IconButton>
            <IconButton
              onClick={handleZoomOut}
              aria-label="Zoom Out"
              size={"small"}
            >
              <Icon.ZoomOut />
            </IconButton>
          </ButtonGroup>
          <div style={tableContainerStyle}>
            <MUIDataTable
              title={isSub && name}
              data={rows}
              columns={col}
              elevation={1}
              options={{
                expandableRows: isExpandable,
                renderExpandableRow: (rowData, rowMeta) => {
                  // console.log(rowData, rowMeta);
                  let data = [];
                  if (rowData && rowData.length) {
                    //console.log(rowData, rowData.length);
                    data = rowData[rowData.length - 2];
                  }
                  // console.log(rowMeta?.rowIndex);
                  return (
                    <React.Fragment key={rowMeta?.rowIndex}>
                      <RenderExpandableRow rows={data} />
                    </React.Fragment>
                  );
                },
                /*onRowsDelete: (e, r) => {
                  let rowsIndex = e.data;
                  for(let i = 0; i<rowsIndex.length; i++) {
                    let index = rowsIndex[i].index;
                    console.log(rowsIndex[i].dataIndex);
                    console.log(r[index]);
                  }
                },*/
                customToolbarSelect: (
                  selectedRows,
                  displayData,
                  setSelectedRows
                ) => (
                  <CustomToolbarSelect
                    selectedRows={selectedRows}
                    displayData={displayData}
                    setSelectedRows={setSelectedRows}
                    route={route}
                  />
                ),
                onChangePage: (e) => {
                  if (!isSub) localStorage.setItem("page_" + route, e);
                },
                filter: true,
                //resizableColumns: true,
                sortOrder: sortOrder,
                searchOpen: Boolean(!isSub),
                searchAlwaysOpen: Boolean(!isSub),
                elevation: 1,
                usePaperPlaceholder: false,
                fixedHeader: true,
                filterType: "multiselect",
                responsive: "standard",
                sort: true,
                rowsPerPage: _rowsPerPage,
                rowHove: true,
                search: isSearchable,
                download: false,
                selectableRows:
                  access_rights > 2 || admin ? "multiple" : "none",
                selectedRowsHeader: access_rights > 2 || admin ? true : false,
                //filterList: filterList,

                componentWillReceiveProps: true,
                onRowClick: (row) => {
                  if (userInfos) {
                    if (child_route && (access_view > 1 || admin)) {
                      handleClickRow(child_route, "?id=" + row[0], state);
                    }
                  }
                },

                /*customBodyRender: (value, tableMeta, updateValue) => {
                  return(
                    <ps></ps>
                  );
                },*/
                page: _page,
                customSearchRender: (
                  searchText,
                  handleSearch,
                  hideSearch,
                  options
                ) => {
                  return (
                    <CustomSearchRender
                      searchText={searchText}
                      onSearch={handleSearch}
                      onHide={hideSearch}
                      options={options}
                      name={name}
                      isSub={isSub}
                    />
                  );
                },
                customToolbar: () => {
                  if (!disableAdd) {
                    return (
                      <CustomToolbar
                        isSub={isSub}
                        setRows={setRows}
                        setLoading={setLoading}
                        route={route}
                        struct={struct}
                        child_route={child_route}
                        routeParam={state?.routeParam}
                        state={state}
                        isLoading={isLoading}
                      />
                    );
                  }
                  return (
                    <>
                      {!isSub && (
                        <Tooltip title={"Rafraîchir"}>
                          <span>
                            <RefreshButton
                              setRows={setRows}
                              setLoading={setLoading}
                              route={route}
                              struct={struct}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </>
                  );
                },
                customFooter: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage
                ) => {
                  return (
                    <CustomFooter
                      count={count}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      changeRowsPerPage={changeRowsPerPage}
                      changePage={changePage}
                      name={name}
                      key={name}
                      route={route}
                    />
                  );
                },
                textLabels: {
                  body: {
                    noMatch: isLoading ? (
                      <div style={{ margin: "250px 0 0" }}>
                        <Loading />
                      </div>
                    ) : (
                      "(aucune donnée)"
                    ),
                    toolTip: "Trier",
                    columnHeaderTooltip: (column) =>
                      `Trier par ${column.label}`,
                  },
                  toolbar: {
                    search: <span>Recherche</span>,
                    downloadCsv: <span>Télécharger au format CSV</span>,
                    print: <span>Imprimer</span>,
                    viewColumns: <span>Colonne(s) visible(s)</span>,
                    filterTable: <span>Filtre(s)</span>,
                  },
                  filter: {
                    all: "Tous",
                    title: "Filtre(s)",
                    reset: "Réinitialiser",
                  },
                  viewColumns: {
                    title: "Colonne(s) visible(s)",
                    titleAria: "Afficher/cacher colonnes de la table",
                  },
                  selectedRows: {
                    text: "ligne(s) sélectionnée(s)",
                    delete: "Archiver",
                    deleteAria: "Supprimer ligne(s) sélectionnée(s)",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  );
}

const Entitie = compose(withRouter)(EntitieBase);

export { Entitie };
