import React from "react";
import styled from "@emotion/styled";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core";
import MaskedInput from "react-text-mask";
import Button from "@material-ui/core/Button";

const Container = styled.div`
  border-radius: 0.5em;
  background-color: white;
  box-shadow: ${({ isDragging }) =>
    isDragging
      ? `0px 0px 10px #ccc`
      : "0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%)"};
  padding: 1em;
  margin-bottom: 8px;
  user-select: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;
  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;
  /* flex parent */
  display: flex;
  flex-direction: column;
`;

const BlockEquipe = styled.div`
  &::before {
    content: open-equipe;
  }
  &::after {
    content: close-equipe;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;
`;

const EquipeId = styled.small`
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  font-weight: normal;
  text-overflow: ellipsis;
  text-align: left;
`;

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
      showMask
    />
  );
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
export default class EquipeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSave: [],
      showSavePlayTime: [],
    };
  }
  render() {
    const {
      equipe,
      isDragging,
      isGroupedOver,
      provided,
      error,
      handleSubmit,
      handleSubmitPlayTime,
      title,
      index,
    } = this.props;
    const { showSave, showSavePlayTime } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/*<div style={{display:'flex', flexDirection:'column'}}>
          <IconButton size={'small'} style={{margin:'.05em .1em'}}><ArrowUpwardIcon fontSize="inherit"/></IconButton>
        <IconButton size={'small'}  style={{margin:'.05em .1em'}}><ArrowDownwardIcon fontSize="inherit"/></IconButton>
    </div>*/}
        <Container
          isDragging={isDragging}
          isGroupedOver={isGroupedOver}
          ref={provided?.innerRef}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
        >
          <Content>
            <BlockEquipe>{equipe?.content}</BlockEquipe>
            <form onSubmit={(e) => handleSubmitPlayTime(e, equipe.id, title)}>
              <FormControl
                style={{ marginTop: "1em", width: "100%" }}
                size={"small"}
              >
                <InputLabel
                  htmlFor={"text-mask" + equipe.id}
                  style={{
                    margin: "-8px 0 0 14px",
                  }}
                >
                  Heure de match
                </InputLabel>
                <OutlinedInput
                  id={"text-mask" + equipe.id}
                  inputComponent={TextMaskCustom}
                  label={"Heure de match"}
                  onChange={() => {
                    this.setState((prevState) => ({
                      showSavePlayTime: {
                        ...prevState.showSavePlayTime,
                        [equipe?.id]: true,
                      },
                    }));
                  }}
                  defaultValue={equipe.play_time}
                  size={"small"}
                />
              </FormControl>
              {showSavePlayTime[equipe?.id] && (
                <Button
                  style={{ width: "100%", marginTop: ".5em" }}
                  variant="contained"
                  size="small"
                  color="primary"
                  type="submit"
                >
                  Sauvegarder
                </Button>
              )}
            </form>
            <Footer>
              <div
                style={{
                  marginTop: ".5em",
                  width: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    paddingRight: "0.5em",
                  }}
                >
                  <TextField
                    label="Index"
                    variant="outlined"
                    size={"small"}
                    value={equipe.index}
                    disabled
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
                <div
                  style={{
                    width: "60%",
                    paddingLeft: "0.5em",
                  }}
                >
                  <form onSubmit={(e) => handleSubmit(e, equipe.id, title)}>
                    <TextField
                      label="N° de série"
                      variant="outlined"
                      error={
                        equipe.serial_num == "" || !equipe.serial_num || error
                      }
                      size={"small"}
                      onChange={() => {
                        this.setState((prevState) => ({
                          showSave: {
                            ...prevState.showSave,
                            [equipe?.id]: true,
                          },
                        }));
                      }}
                      defaultValue={equipe.serial_num}
                      style={{
                        width: "100%",
                      }}
                    />
                    {showSave[equipe?.id] && (
                      <Button
                        style={{ width: "100%", marginTop: ".5em" }}
                        variant="contained"
                        size="small"
                        color="primary"
                        type="submit"
                      >
                        Sauvegarder
                      </Button>
                    )}
                  </form>
                </div>
              </div>
            </Footer>
          </Content>
        </Container>
      </div>
    );
  }
}
