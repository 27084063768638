import { useState, useEffect, useRef } from "react";
import {
  TextField,
  TableCell,
  Tooltip,
  IconButton,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { css } from "@emotion/css";
import * as Icon from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import axios from "axios";
import toast from "react-hot-toast";
import {
  AlertDialog,
  CustomLinkButton,
  CustomArchivingButton,
  TextMaskCustom,
  translateStatus,
  CustomDateInput,
} from "../../components";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

export const routeTable = "rencontres";
export const routeFiche = "feuille/rencontre";
export const route = "rencontre";
export const nameTable = "Rencontres";

export function CustomBodyRenderEquipe(props) {
  const { value, tableMeta, updateValue, equipeNum } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState(null);
  const OPTIONS_LIMIT = 40;
  //let columnIndex = tableMeta?.columnIndex;

  const fetchTeams = () => {
    if (teams.length === 0) {
      console.log("hiyah");
      axios
        .get(process.env.REACT_APP_API_URL + "teams", {
          headers: { authorization: localStorage.getItem("api_token") },
        })
        .then((r) => {
          let data = r.data;
          let _teams = [];
          for (let i in data) {
            _teams.push({
              label:
                data[i]?.equipe?.club?.name +
                " - " +
                data[i]?.equipe?.id +
                (data[i]?.index ? " - " + data[i]?.index : ""),
              value: data[i].id,
            });
          }
          setTeams(_teams);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  var isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const handleChangeEquipe = () => {
    let id = tableMeta?.rowData[0];
    let team_num = equipeNum === 1 ? "team_1" : "team_2";
    team_num = !edit ? team_num : team_num + "_name";
    let value = !edit ? newTeam?.value : newTeam;
    if (value) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "rencontre/update/" +
            id +
            "/" +
            team_num +
            "/" +
            value,
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((r) => {
          toast.success("Équipe correctement mise à jour !", {
            duration: 4000,
          });
          localStorage.removeItem("rencontres_latest_update");
          updateValue(!edit ? newTeam?.label : newTeam);
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Problème lors de la mise à jour de l'équipe...",
            { duration: 4000 }
          );
        });
    }
  };
  function SelectNewTeam() {
    fetchTeams();
    return (
      <div style={{ width: 300, display: "flex", alignItems: "center" }}>
        {!edit ? (
          <Autocomplete
            filterOptions={filterOptions}
            options={teams}
            style={{ minWidth: 250 }}
            //className={classes.input}
            getOptionLabel={(option) => (option?.label ? option.label : "")}
            getOptionSelected={(option, value) =>
              option?.label === value?.label
            }
            value={newTeam ? newTeam : null}
            onChange={(e, value) => {
              setNewTeam(value);
            }}
            renderOption={(option) => (option?.label ? option.label : "")}
            renderInput={(params) => {
              const inputProps = params.inputProps;
              inputProps.autoComplete = "off";
              return (
                <TextField
                  {...params}
                  name={"new_equipe"}
                  variant="outlined"
                  label={"Nouvelle équipe"}
                  autoComplete="current-password"
                  inputProps={inputProps}
                />
              );
            }}
          />
        ) : (
          <TextField
            onChange={(e) => {
              let value = e?.target?.value;
              setNewTeam(value ? value.toUpperCase() : value);
            }}
            name={"new_equipe"}
            variant="outlined"
            style={{ minWidth: 250 }}
            label={"Nom de l'équipe"}
            value={newTeam}
          />
        )}
        <IconButton
          style={{ margin: "0 15px" }}
          onClick={(e) => {
            setEdit(!edit);
            setNewTeam(null);
          }}
        >
          {!edit ? (
            <Icon.Edit fontSize={"small"} />
          ) : (
            <Icon.List fontSize={"small"} />
          )}
        </IconButton>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ width: "80%" }}>{value}</span>
      <div style={{ width: "20%" }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
        >
          <Icon.Edit fontSize="small" />
        </IconButton>
        {showDialog && (
          <AlertDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            dialogComponent={SelectNewTeam}
            dialogTitle={"Modifier l'équipe"}
            primary_action={"Valider"}
            secondary_action={"Annuler"}
            handlePrimaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
              handleChangeEquipe();
            }}
            handleSecondaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export function CustomBodyRenderPlayTime(props) {
  const { value, tableMeta, updateValue } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [newTime, setNewTime] = useState(null);

  const handleChangeTime = () => {
    let id = tableMeta?.rowData[0];
    let value = newTime;
    if (value) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "rencontre/update/" +
            id +
            "/play_time/" +
            newTime,
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((r) => {
          toast.success("Heure de match correctement mise à jour !", {
            duration: 4000,
          });
          localStorage.removeItem("rencontres_latest_update");
          updateValue(newTime);
        })
        .catch((error) => {
          console.log(error);
          toast.error(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Problème lors de la mise à jour de l'heure de match...",
            { duration: 4000 }
          );
        });
    }
  };
  function SelectNewTime() {
    return (
      <div style={{ width: 300 }}>
        <FormControl
          size="medium"
          style={{
            width: 275,
          }}
        >
          <OutlinedInput
            id="formatted-text-mask-input"
            inputComponent={TextMaskCustom}
            onChange={(e) => {
              let value = e?.target?.value;
              setNewTime(value);
            }}
            value={newTime ? newTime : value}
          />
        </FormControl>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ width: "80%" }}>{value}</span>
      <div style={{ width: "20%" }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
        >
          <Icon.Edit fontSize="small" />
        </IconButton>
        {showDialog && (
          <AlertDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            dialogComponent={SelectNewTime}
            dialogTitle={"Modifier l'heure de match"}
            primary_action={"Valider"}
            secondary_action={"Annuler"}
            handlePrimaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
              handleChangeTime();
            }}
            handleSecondaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export function CustomBodyRenderMatchDate(props) {
  const { value, tableMeta, updateValue } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [newDate, setNewDate] = useState(value); // Initialise avec la date actuelle

  const handleChangeDate = () => {
    let id = tableMeta?.rowData[0];
    if (newDate) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}rencontre/update/${id}/match_date/${newDate}`,
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          toast.success("Date du match mise à jour avec succès!", {
            duration: 4000,
          });
          localStorage.removeItem("rencontres_latest_update");
          updateValue(newDate); // Met à jour la valeur affichée dans le tableau
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            error?.response?.data?.error
              ? error?.response?.data?.error
              : "Problème lors de la mise à jour de la date du match...",
            { duration: 4000 }
          );
        });
    }
  };

  function SelectNewDate() {
    return (
      <div style={{ width: 300 }}>
        <FormControl size="medium" style={{ width: 275 }}>
          <OutlinedInput
            id="date-input"
            type="date"
            onChange={(e) => setNewDate(e.target.value)}
            value={newDate}
          />
        </FormControl>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ width: "80%" }}>{value}</span>
      <div style={{ width: "20%" }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
        >
          <Icon.Edit fontSize="small" />
        </IconButton>
        {showDialog && (
          <AlertDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            dialogComponent={SelectNewDate}
            dialogTitle={"Modifier la date du match"}
            primary_action={"Valider"}
            secondary_action={"Annuler"}
            handlePrimaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
              handleChangeDate();
            }}
            handleSecondaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

export function CustomBodyRenderReferee(props) {
  const { value, tableMeta, updateValue } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [newReferee, setNewReferee] = useState(null);
  const OPTIONS_LIMIT = 40;
  //let columnIndex = tableMeta?.columnIndex;

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleChangeReferee = () => {
    let id = tableMeta?.rowData[0];
    if (newReferee) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "rencontre/update/" +
            id +
            "/arbitre/" +
            newReferee?.value,
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((r) => {
          toast.success(
            r?.data?.success
              ? r?.data?.success
              : "Arbitre officiel correctement mise à jour !",
            {
              duration: 4000,
            }
          );
          localStorage.removeItem("rencontres_latest_update");
          updateValue(newReferee?.label);
        })
        .catch((e) => {
          toast.error(
            e?.response?.data?.error
              ? e?.response?.data?.error
              : "Problème lors de la mise à jour de l'arbitre officiel...",
            { duration: 4000 }
          );
        });
    }
  };

  function SelectNewReferee() {
    let referees = [];
    axios
      .get(process.env.REACT_APP_API_URL + "membres/shortlist/arbitres", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        for (let i in data) {
          referees.push({
            label: data[i]
              ? data[i]?.name +
                " " +
                data[i]?.first_name +
                " (" +
                data[i]?.referee_code +
                ")"
              : "",
            value: data[i]?.id,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return (
      <div style={{ width: 300 }}>
        <Autocomplete
          filterOptions={filterOptions}
          options={referees}
          //className={classes.input}
          getOptionLabel={(option) => (option?.label ? option.label : "")}
          getOptionSelected={(option, value) => option?.label === value?.label}
          value={
            newReferee
              ? newReferee
              : {
                  label: value,
                  value: value,
                }
          }
          onChange={(e, value) => {
            setNewReferee(value);
            if (value) {
              setNewReferee(value);
            } else {
              let newReferee = {
                label: null,
                value: null,
              };
              setNewReferee(newReferee);
            }
            console.log("cganfe", value);
          }}
          renderOption={(option) => (option?.label ? option.label : "")}
          renderInput={(params) => {
            const inputProps = params.inputProps;
            inputProps.autoComplete = "off";
            return (
              <TextField
                {...params}
                name={"new_referee"}
                variant="outlined"
                label={""}
                inputProps={inputProps}
              />
            );
          }}
        />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ width: "80%" }}>{value}</span>
      <div style={{ width: "20%" }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
        >
          <Icon.Edit fontSize="small" />
        </IconButton>
        {showDialog && (
          <AlertDialog
            showDialog={showDialog}
            setShowDialog={setShowDialog}
            dialogText={<SelectNewReferee />}
            dialogTitle={"Modifier l'arbitre officiel"}
            primary_action={"Valider"}
            secondary_action={"Annuler"}
            handlePrimaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
              handleChangeReferee();
            }}
            handleSecondaryAction={(e) => {
              e.stopPropagation();
              setShowDialog(false);
            }}
          />
        )}
      </div>
    </div>
  );
}

function CustomBodyRenderScore(props) {
  const { value, tableMeta } = props;
  const [score1, setScore1] = useState(null);
  const [score2, setScore2] = useState(null);
  const [scoreSaved, setScoreSaved] = useState(false);
  const handleSetScore = (score1, score2, id) => {
    if (score1 && score2) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "rencontre/update/" +
            id +
            "/score_1/" +
            Number(score1),
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((r) => {})
        .catch((e) => {
          console.log(e);
        });
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "rencontre/update/" +
            id +
            "/score_2/" +
            Number(score2),
          {},
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((r) => {
          toast.success("Score correctement enregistré !", {
            duration: 4000,
          });
          setScoreSaved(true);
        })
        .catch((e) => {
          toast.error("Problème lors de l'enregistrement du score...", {
            duration: 4000,
          });
        });
      localStorage.removeItem("rencontres_latest_update");
    }
  };
  ///rencontre/update/{id}/score_1
  if (!value && !scoreSaved) {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <TextField
          id="outlined-number"
          label=""
          type="number"
          variant="outlined"
          size="small"
          value={score1 ?? undefined}
          onChange={(e) => {
            setScore1(e?.target?.value);
          }}
          style={{ marginRight: ".5em", width: 60 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <TextField
          id="outlined-number"
          label=""
          type="number"
          variant="outlined"
          size="small"
          value={score2 ?? undefined}
          onChange={(e) => {
            setScore2(e?.target?.value);
          }}
          style={{ width: 60 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <IconButton
          aria-label={"Edit"}
          size={"medium"}
          onClick={(e) => {
            e.stopPropagation();
            let id = tableMeta?.rowData[0];
            handleSetScore(score1, score2, id);
          }}
        >
          <Icon.CheckCircle fontSize="small" />
        </IconButton>
      </div>
    );
  } else if (scoreSaved) {
    return score1 + " - " + score2;
  }
  return value;
}

//shortlist
export async function getShortlists(setOptions) {
  let terrains = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "terrains/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        terrains.push({
          label: data[i].name,
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });

  let teams = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "teams", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        teams.push({
          label:
            data[i]?.equipe?.club?.name +
            " - " +
            data[i]?.equipe?.id +
            (data[i]?.index ? " - " + data[i]?.index : ""),
          value: data[i].id,
          division: data[i]?.division,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });

  let arbitres = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "membres/shortlist/arbitres", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        arbitres.push({
          label:
            data[i].name +
            " " +
            data[i].first_name +
            " (" +
            data[i].referee_code +
            ")",
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });

  setOptions({
    terrain_id: terrains,
    referee_line_1: arbitres,
    referee_official: arbitres,
    referee_line_2: arbitres,
    referee_viewer: arbitres,
    team_1_id: teams,
    team_2_id: teams,
  });
  // End shortlist
}

const DIV_DIMANCHE = [
  {
    value: "Dimanche Honneur",
    label: "Dimanche Honneur",
  },
  {
    value: "Dimanche 1A",
    label: "Dimanche 1A",
  },
  {
    value: "Dimanche 1B",
    label: "Dimanche 1B",
  },
];

const DIV_VETERAN = [
  {
    value: "Vétérans Honneur",
    label: "Vétérans Honneur",
  },
  {
    value: "Vétérans 1",
    label: "Vétérans 1",
  },
  {
    value: "Vétérans 2",
    label: "Vétérans 2",
  },
  {
    value: "Vétérans 3",
    label: "Vétérans 3",
  },
];

function CustomBodyRender(props) {
  const { value, updateValue, type } = props;
  return (
    <div>
      <TextField
        label={""}
        size={"small"}
        type={type ?? "number"}
        name={""}
        //error={}
        //helperText={}
        className={css`
          fieldset {
            border-radius: 8px;
          }
        `}
        onChange={(e) => {
          updateValue(e.target.value);
        }}
        value={value ?? ""}
        variant="outlined"
      />
    </div>
  );
}

function CustomHeadRender(props) {
  const { columnMeta, info, index } = props;
  return (
    <TableCell
      data-colindex={index}
      key={"customHeadCell" + index + columnMeta}
      style={{
        color: "#00000080",
        fontWeight: "600",
        lineHeight: "1.5rem",
      }}
    >
      <CustomTooltip title={info}>
        <span>
          {columnMeta.label + " "} <Icon.HelpRounded fontSize={"small"} />
        </span>
      </CustomTooltip>
    </TableCell>
  );
}

export const struct = (data) => {
  let terrain_id = data?.team_visite?.terrain_id;
  /*terrains.find((x) => x.value === terrain_id).label*/
  let team_1_score = data?.team_1_score ? data.team_1_score : "";
  let team_2_score = data?.team_2_score ? data.team_2_score : "";
  return {
    team_1_id: {
      label: "Équipe à domicile",
      type: "autocomplete",
      value: {
        label: data?.equipe_visite
          ? data?.equipe_visite?.club?.name +
            " - " +
            data?.equipe_visite?.id +
            (data?.team_visite?.index ? " - " + data?.team_visite?.index : "")
          : null,
        value: data?.team_visite?.id,
      },
      onChange: (value, data, setData) => {
        console.log(data);
        setData({
          ...data,
          team_1_id: {
            ...data["team_1_id"],
            value: value,
          },
          team_1_name: {
            ...data["team_1_name"],
            value: value?.label,
            disabled: value?.label ? true : false,
          },
          team_1_division: {
            ...data["team_1_division"],
            value: value?.division,
          },
        });
      },
    },
    team_1_name: {
      label: "Équipe à domicile",
      disabled: Boolean(data?.equipe_visite?.id),
      type: "text",
      value: data?.team_1_name ?? null,
      onChange: (e, data, setData) => {
        let value = e?.target?.value;
        setData({
          ...data,
          team_1_name: {
            ...data["team_1_name"],
            value: value.toUpperCase(),
          },
          team_1_id: {
            ...data["team_1_id"],
            value: null,
          },
        });
      },
    },
    team_2_id: {
      label: "Équipe en déplacement",
      type: "autocomplete",
      value: {
        label: data?.equipe_visiteur
          ? data?.equipe_visiteur?.club?.name +
            " - " +
            data?.equipe_visiteur?.id +
            (data?.team_visiteur?.index
              ? " - " + data?.team_visiteur?.index
              : "")
          : null,
        value: data?.team_visiteur?.id,
      },
      onChange: (value, data, setData) => {
        setData({
          ...data,
          team_2_id: {
            ...data["team_2_id"],
            value: value,
          },
          team_2_name: {
            ...data["team_2_name"],
            value: value?.label,
            disabled: value?.label ? true : false,
          },
          team_2_division: {
            ...data["team_2_division"],
            value: value?.division,
          },
        });
      },
    },
    team_2_name: {
      label: "Équipe en déplacement",
      type: "text",
      value: data?.team_2_name ?? null,
      disabled: Boolean(data?.equipe_visiteur?.id),
      onChange: (e, data, setData) => {
        let value = e?.target?.value;
        setData({
          ...data,
          team_2_name: {
            ...data["team_2_name"],
            value: value.toUpperCase(),
          },
          /*team_1_id: {
            ...data["team_1_id"],
            value: null,
          },*/
        });
      },
    },
    day_code: {
      label: "Code du jour",
      type: "text",
      disabled: data ? true : false,
      //display: data && "none",
      value: data?.day_code ? data.day_code : "",
    },
    day: {
      label: "N° du jour",
      type: "number",
      //display: data && "none",
      disabled: data ? true : false,
      value: data?.day ? data.day : "",
    },
    match_type: {
      label: "Type de rencontre",
      type: "select",
      disabled: data ? true : false,
      value: data?.match_type,
      values: [
        {
          label: "Championnat",
          value: "championnat",
        },
        {
          label: "Coupe",
          value: "coupe",
        },
        {
          label: "Amical",
          value: "amical",
        },
        {
          label: "Barrage",
          value: "barrage",
        },
      ],
    },
    match_date: {
      label: "Date de match",
      type: "date",
      value: data?.match_date ? data.match_date : null,
    },
    play_time: {
      label: "Heure de match",
      type: "time",
      value: data?.play_time ? data.play_time : "",
    },
    team_1_division: {
      label: "Division Team #1",
      type: "select",
      //disabled: true,
      value: data?.team_1_division,
      values: [...DIV_DIMANCHE, ...DIV_VETERAN],
    },
    team_2_division: {
      label: "Division Team #2",
      type: "select",
      //disabled: true,
      value: data?.team_2_division,
      values: [...DIV_DIMANCHE, ...DIV_VETERAN],
    },
    team_1_score: {
      label: "Score #1",
      type: "number",
      value: team_1_score,
      //disabled: Boolean(data?.team_1_score),
    },
    team_2_score: {
      label: "Score #2",
      type: "number",
      value: team_2_score,
      //disabled: Boolean(data?.team_2_score),
    },
    goal_scoring: {
      label: "Score des tirs au but",
      type: "text",
      value: data?.goal_scoring,
    },
    referee_line_1: {
      label: "Juge de ligne #1",
      type: "autocomplete",
      value: {
        label: data?.referee_line_1
          ? data?.referee_line_1?.name +
            " " +
            data?.referee_line_1?.first_name +
            " (" +
            data?.referee_line_1?.referee_code +
            ")"
          : null,
        value: data?.referee_line_1?.id,
      },
    },
    referee_line_2: {
      label: "Juge de ligne #2",
      type: "autocomplete",
      value: {
        label: data?.referee_line_2
          ? data?.referee_line_2?.name +
            " " +
            data?.referee_line_2?.first_name +
            " (" +
            data?.referee_line_2?.referee_code +
            ")"
          : null,
        value: data?.referee_line_2?.id,
      },
    },
    referee_official: {
      label: "Arbitre officiel",
      type: "autocomplete",
      value: {
        label: data?.referee_official
          ? data?.referee_official?.name +
            " " +
            data?.referee_official?.first_name +
            " (" +
            data?.referee_official?.referee_code +
            ")"
          : null,
        value: data?.referee_official?.id,
      },
    },
    referee_occas: {
      label: "Arbitre occasionnel",
      type: "text",
      value: data?.referee_occas ? data.referee_occas : null,
    },
    referee_viewer: {
      label: "Arbitre visionneur",
      type: "autocomplete",
      value: {
        label: data?.referee_viewer
          ? data?.referee_viewer?.name +
            " " +
            data?.referee_viewer?.first_name +
            " (" +
            data?.referee_viewer?.referee_code +
            ")"
          : null,
        value: data?.referee_viewer?.id,
      },
    },
    modified_score: {
      label: "Score modifié",
      type: "checkbox",
      checked: data?.modified_score ? Boolean(data.modified_score) : false,
      disabled: Boolean(data?.modified_score),
    },
    // postponed_match: {
    //   label: "Match reporté",
    //   type: "checkbox",
    //   checked: data?.postponed_match ? Boolean(data.postponed_match) : false,
    //   //disabled: Boolean(data?.postponed_match),
    // },
    status: {
      label: "Statut de la rencontre",
      type: "select",
      value: data?.status,
      values: [
        { label: "(aucun)", value: null },
        { label: "Match arrêté", value: "stopped" },
        { label: "Match remis", value: "adjourned" },
        { label: "Match reporté", value: "postponed" },
      ],
      onChange: (value, data, setData) => {
        setData({
          ...data,
          team_2_score: {
            ...data["team_2_score"],
            value: null,
            type: "text",
            disabled: value ? true : false,
          },
          team_1_score: {
            ...data["team_1_score"],
            value: null,
            disabled: value ? true : false,
          },
          status: {
            ...data["status"],
            value: value,
          },
        });
      },
    },
    match_sheet_date: {
      label: "Date d'encodage",
      type: "date",
      value: data?.match_sheet_date ? data.match_sheet_date : null,
    },
    returned_game_sheet: {
      label: "Feuille rentrée",
      type: "checkbox",
      checked: data?.returned_game_sheet
        ? Boolean(data?.returned_game_sheet)
        : false,
      disabled: Boolean(data?.returned_game_sheet),
    },
    terrain_id: {
      label: "Terrain associé",
      type: "autocomplete",
      value: {
        label: data?.terrain?.name,
        value: terrain_id,
      },
    },
    results_date: {
      label: "Date résultats",
      type: "date",
      value: data?.results_date ? data.results_date : null,
    },
    match_confidential_date: {
      label: "Date conf. rencontre",
      type: "date",
      value: data?.match_confidential_date
        ? data.match_confidential_date
        : null,
    },
    team_1_forfeit: {
      label: "Forfait Team #1",
      type: "checkbox",
      checked: data?.team_1_forfeit ? Boolean(data?.team_1_forfeit) : false,
      disabled: true,
      display: "none",
    },
    team_2_forfeit: {
      label: "Forfait Team #2",
      type: "checkbox",
      checked: data?.team_2_forfeit ? Boolean(data?.team_2_forfeit) : false,
      disabled: true,
      display: "none",
    },
    penalties_generated: data?.penalties_generated,
    confidentiel_arbitrage_visiteurs: data?.confidentiel_arbitrage_visiteurs,
    confidentiel_arbitrage_visites: data?.confidentiel_arbitrage_visites,
    confidentiel_rencontre_visiteurs: data?.confidentiel_rencontre_visiteurs,
    confidentiel_rencontre_visites: data?.confidentiel_rencontre_visites,
    feuilles_visites: data?.feuilles_visites,
    feuilles_visiteurs: data?.feuilles_visiteurs,
    ballons_or: data?.ballons_or,
    ballon_or_visites: data?.ballon_or_visites,
    ballon_or_visiteurs: data?.ballon_or_visiteurs,
    equipe_visite: data?.equipe_visite,
    equipe_visiteur: data?.equipe_visiteur,
    team_visite: data?.team_visite,
    team_visiteur: data?.team_visiteur,
  };
};

export const items = [
  [
    "team_1_id",
    "team_1_name",
    "team_2_id",
    "team_2_name",
    "team_1_division",
    "team_2_division",
    "match_type",
    "match_date",
    "play_time",
    "terrain_id",
    "status",
  ],
  [
    "team_1_score",
    "team_2_score",
    "goal_scoring",
    "modified_score",
    "referee_line_1",
    "referee_official",
    "referee_line_2",
    "referee_occas",
    "referee_viewer",
    "results_date",
    "match_confidential_date",
    "team_1_forfeit",
    "team_2_forfeit",
  ],
  ["match_sheet_date", "returned_game_sheet"],
  ["equipe_visite"],
  ["equipe_visiteur"],
  ["team_visite"],
  ["team_visiteur"],
  ["confidentiel_arbitrage_visiteurs"],
  ["confidentiel_arbitrage_visites"],
  ["confidentiel_rencontre_visiteurs"],
  ["confidentiel_rencontre_visites"],
  ["feuilles_visites"],
  ["feuilles_visiteurs"],
  ["ballons_or"],
  ["ballon_or_visites"],
  ["ballon_or_visiteurs"],
];

export const itemsChampionnat = [
  [
    "team_1_id",
    "team_1_name",
    "team_2_id",
    "team_2_name",
    "day_code",
    "day",
    "match_type",
    "match_date",
    "play_time",
    "terrain_id",
    "team_1_division",
    "team_2_division",
    "status",
  ],
  [
    "team_1_score",
    "team_2_score",
    "goal_scoring",
    "modified_score",
    "referee_line_1",
    "referee_official",
    "referee_line_2",
    "referee_occas",
    "referee_viewer",
    "results_date",
    "match_confidential_date",
    // "postponed_match",
    "team_1_forfeit",
    "team_2_forfeit",
  ],
  ["match_sheet_date", "returned_game_sheet"],
  ["equipe_visite"],
  ["equipe_visiteur"],
  ["team_visite"],
  ["team_visiteur"],
  ["confidentiel_arbitrage_visiteurs"],
  ["confidentiel_arbitrage_visites"],
  ["confidentiel_rencontre_visiteurs"],
  ["confidentiel_rencontre_visites"],
  ["feuilles_visites"],
  ["feuilles_visiteurs"],
  ["ballons_or"],
  ["ballon_or_visites"],
  ["ballon_or_visiteurs"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "validated_game_sheet",
    label: "F. verouillée",
    options: {
      display: false,
      filter: true,
      sort: true,
    },
  },
  {
    name: "returned_game_sheet",
    label: "Feuille rentrée",
    options: {
      display: true,
      filter: false,
      sort: false,
    },
  },
  {
    name: "match_type",
    label: "Type",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "day_code",
    label: "J.",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "division",
    label: "Div.",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "match_date",
    label: "Date",
    options: {
      display: true,
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderMatchDate
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        );
      },
    },
  },
  {
    name: "status",
    label: "Statut",
    options: {
      display: true,
      filter: true,
      sort: true,
    },
  },
  {
    name: "team_1_id",
    label: "Team #1 (id)",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "team_2_id",
    label: "Team #2 (id)",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "equipe_1",
    label: "Équipe à domicile",
    options: {
      display: true,
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderEquipe
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            equipeNum={1}
          />
        );
      },
    },
  },
  {
    name: "equipe_2",
    label: "Équipe en déplacement",
    options: {
      display: true,
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderEquipe
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            equipeNum={2}
          />
        );
      },
    },
  },
  {
    name: "referee_official",
    label: "Arbitre officiel",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderReferee
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        );
      },
    },
  },
  {
    name: "final_score",
    label: "Score",
    options: {
      display: true,
      filter: false,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderScore
            value={value}
            tableMeta={tableMeta}
            updataValue={updateValue}
          />
        );
      },
    },
  },
  {
    name: "play_time",
    label: "Heure",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRenderPlayTime
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
          />
        );
      },
    },
  },
  {
    name: "goal_scoring",
    label: "T.a.b",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const columnsChild = [
  {
    name: "id",
    label: "ID",
    options: {
      display: true,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      viewColumns: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
  {
    name: "full_name",
    label: "Nom complet",
    options: {
      display: true,
      filter: false,
      sort: false,
    },
  },
  {
    name: "remplacement",
    label: "Remplacement",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={2}
            info={"Remplacement - minute de la descente ou montée"}
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return <CustomBodyRender updateValue={updateValue} value={value} />;
      },
    },
  },
  {
    name: "cartes_jaunes",
    label: "Carte(s) Jaune(s)",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={3}
            info={"Nombre de cartes jaunes (1 ou 2)"}
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return <CustomBodyRender updateValue={updateValue} value={value} />;
      },
    },
  },
  {
    name: "buts",
    label: "Buts",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={4}
            info={"Nombre de buts"}
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return <CustomBodyRender updateValue={updateValue} value={value} />;
      },
    },
  },
  {
    name: "cartes_rouge",
    label: "Carte(s) Rouge(s)",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={5}
            info={"Minute à laquelle est atribuée la CR"}
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return <CustomBodyRender updateValue={updateValue} value={value} />;
      },
    },
  },
  {
    name: "blesse",
    label: "Blessé",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={6}
            info={"Minute de l'incident"}
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return <CustomBodyRender updateValue={updateValue} value={value} />;
      },
    },
  },
  {
    name: "adm",
    label: "Administratif",
    options: {
      display: true,
      filter: false,
      sort: false,
      customHeadRender: (columnMeta) => {
        return (
          <CustomHeadRender
            columnMeta={columnMeta}
            index={7}
            info={
              "I, A, IA, AI (absence de carte d'Identité ou/et d'affiliation)"
            }
          />
        );
      },
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <CustomBodyRender
            type={"text"}
            updateValue={updateValue}
            value={value}
          />
        );
      },
    },
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: false,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "link",
    label: " ",
    options: {
      display: true,
      filter: false,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        return (
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        );
      },
    },
  },
];

export const structTable = (el) => {
  let arbitre_officiel = "";
  if (
    el?.arbitre_officiel?.name &&
    el?.arbitre_officiel?.first_name &&
    el?.arbitre_officiel?.referee_code
  ) {
    arbitre_officiel =
      el?.arbitre_officiel?.name +
      " " +
      el?.arbitre_officiel?.first_name +
      " (" +
      el?.arbitre_officiel?.referee_code +
      ")";
  }
  let short_division = "-";
  if (el?.team_1_division) {
    const mots = el.team_1_division.split(" ");
    if (mots[1] === "1A" || mots[1] === "1B") {
      // Si la division est "1A" ou "1B", prendre les deux premières lettres du deuxième mot
      short_division = mots[0].charAt(0) + mots[1].substring(0, 2);
    } else {
      // Sinon, prendre la première lettre de chaque mot
      short_division = mots.map((mot) => mot.charAt(0)).join("");
    }
  } else if (el?.team_2_division) {
    const mots = el.team_2_division.split(" ");
    if (mots[1] === "1A" || mots[1] === "1B") {
      // Si la division est "1A" ou "1B", prendre les deux premières lettres du deuxième mot
      short_division = mots[0].charAt(0) + mots[1].substring(0, 2);
    } else {
      // Sinon, prendre la première lettre de chaque mot
      short_division = mots.map((mot) => mot.charAt(0)).join("");
    }
  }
  let short_match_type = "";
  if (el?.match_type) {
    short_match_type = el?.match_type
      .split(" ")[0] // Sélectionner le premier mot
      .slice(0, 2) // Sélectionner les deux premiers caractères du premier mot
      .toUpperCase(); // Mettre les deux premiers caractères en majuscule
  }
  let status = translateStatus(el?.status);
  if (el?.id) {
    return {
      id: el?.id,
      validated_game_sheet: Boolean(el?.validated_game_sheet) ? "☑️" : "❌",
      returned_game_sheet: Boolean(el?.returned_game_sheet) ? "☑️" : "❌",
      day_code: el?.day_code ?? "-",
      division: short_division,
      match_date: el?.match_date,
      match_type: short_match_type,
      team_1_id: el?.team_1_id,
      team_2_id: el?.team_2_id,
      status: status,
      equipe_1: el?.team_1_name ? el.team_1_name : "BYE",
      equipe_2: el?.team_2_name ? el.team_2_name : "BYE",
      final_score:
        el?.team_1_score && el?.team_2_score
          ? el?.team_1_score + " - " + el.team_2_score
          : "",
      play_time: el?.play_time,
      referee_official: arbitre_officiel,
      goal_scoring: el?.goal_scoring,
    };
  }
};

export const structChild = (el) => {
  if (el?.id)
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archive",
      full_name: el.membre?.name + " " + el.membre?.first_name,
    };
};

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
  },
})(Tooltip);

export const defaultSort = {
  name: "match_date",
  direction: "asc",
};
