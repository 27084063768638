import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import EquipeList from "./primatives/equipe-list";
import Loading from "../../Loading";
import {
  Chip,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as Icon from "@material-ui/icons";

import { DeleteButton, AlertDialog } from "../../../components";

const Title = styled.h2`
  padding: 1em 1em 0;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 8px;
  margin: 8px;
  border-radius: 1em;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Column(props) {
  const {
    title,
    equipes,
    isScrollable,
    isCombineEnabled,
    index,
    handleSubmit,
    handleSubmitPlayTime,
    addBye,
    removeColumn,
    handleChangeNb,
    nbEquipes,
    setNbEquipes,
  } = props;
  const [error, setError] = useState(false);
  const [nbMissing, setNbMissing] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const checkError = () => {
    for (let i in equipes) {
      if (
        Number(equipes[i]?.serial_num) === Number(equipes[i + 1]?.serial_num) ||
        Number(equipes[i - 1]?.serial_num) === Number(equipes[i]?.serial_num)
      ) {
        setError(true);
      }
    }
  };
  const equipeSort = () => {
    if (equipes && equipes?.length) {
      equipes.sort((a, b) =>
        Number(a.serial_num) > Number(b.serial_num)
          ? 1
          : Number(b.serial_num) > Number(a.serial_num)
          ? -1
          : 0
      );
    }
  };
  useEffect(() => {
    checkError();
    if (equipes && equipes?.length) {
      let nbMissing = nbEquipes[index] - equipes.length;
      setNbMissing(nbMissing);
      equipeSort();
    }
  });
  if (equipes) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* <DeleteButton
              style={{
                margin: 10,

              }}
              startIcon={<Icon.Delete />}
              color="secondary"
              size="medium"
              variant="contained"
              onClick={
                () => setShowDialog(true)
              }
            >
              Vider
            </DeleteButton>*/}
        <AlertDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          dialogText={
            "Êtes-vous sûr de vouloir supprimer cette catégorie du championnat ?"
          }
          dialogTitle={"Supprimer la catégorie"}
          primary_action={"Supprimer"}
          secondary_action={"Annuler"}
          handlePrimaryAction={() => {
            removeColumn(title);
          }}
          handleSecondaryAction={() => setShowDialog(false)}
        />
        {title !== "À ignorer" && nbMissing > 0 && (
          <Alert
            severity="warning"
            style={{
              width: "265px",
              margin: "0 auto 1em",
              borderRadius: "1em",
            }}
          >
            Il manque {nbMissing} {nbMissing > 1 ? "teams" : "team"} dans cette
            catégorie
          </Alert>
        )}
        {title !== "À ignorer" && nbMissing < 0 && (
          <Alert
            severity="error"
            style={{
              width: "265px",
              margin: "0 auto 1em",
              borderRadius: "1em",
            }}
          >
            Il y a {Math.abs(nbMissing)}{" "}
            {Math.abs(nbMissing) > 1 ? "teams" : "team"} en trop dans cette
            catégorie
          </Alert>
        )}
        {error && (
          <Alert
            severity="error"
            style={{
              width: "265px",
              margin: "0 auto 1em",
              borderRadius: "1em",
            }}
          >
            Il y a un conflit entre plusieurs numéros de séries dans cette
            catégorie
          </Alert>
        )}
        <Container>
          <Header>
            <Title>
              <span>{title}</span>
              {title !== "À ignorer" && (
                <div
                  style={{
                    width: "100%",
                    marginTop: ".5em",
                  }}
                >
                  <Chip
                    color="primary"
                    size="small"
                    label={equipes?.length + "/" + nbEquipes[index]}
                  />
                </div>
              )}
            </Title>
          </Header>
          {
            <Select
              value={nbEquipes[index]}
              variant={"outlined"}
              size={"small"}
              style={{
                maxHeight: "42px",
                margin: ".5em auto 1em",
              }}
              onChange={(e) => {
                let value = e?.target?.value;
                handleChangeNb(value, index);
              }}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={14}>14</MenuItem>
              <MenuItem value={16}>16</MenuItem>
              <MenuItem value={18}>18</MenuItem>
            </Select>
          }
          <div style={{ margin: "0 auto .5em" }}>
            {title !== "À ignorer" && equipes?.length < nbEquipes[index] && (
              <Button
                style={{
                  padding: "2px 12px!important",
                  borderRadius: "2em",
                  fontSize: "11px",
                }}
                color="secondary"
                size="medium"
                variant="contained"
                onClick={(e) => addBye(e, index)}
              >
                Ajouter BYE
              </Button>
            )}
          </div>
          <EquipeList
            listId={title}
            index={index}
            listType="QUOTE"
            equipes={equipes}
            handleSubmit={handleSubmit}
            handleSubmitPlayTime={handleSubmitPlayTime}
            internalScroll={isScrollable}
            title={title}
            isCombineEnabled={Boolean(isCombineEnabled)}
          />
        </Container>
      </div>
    );
  }
  return <></>;
}
