export const villes = [
  {
    column_4: 50.883392,
    column_3: 4.3487134,
    column_2: "Laeken",
    column_1: "1020",
    geom: null,
    coordonnees: {
      lat: 50.883392,
      lon: 4.3487134,
    },
  },
  {
    column_4: 50.8676041,
    column_3: 4.3737121,
    column_2: "Schaerbeek",
    column_1: "1030",
    geom: null,
    coordonnees: {
      lat: 50.8676041,
      lon: 4.3737121,
    },
  },
  {
    column_4: 50.8777634,
    column_3: 4.3260903,
    column_2: "Jette",
    column_1: "1090",
    geom: null,
    coordonnees: {
      lat: 50.8777634,
      lon: 4.3260903,
    },
  },
  {
    column_4: 50.8704524,
    column_3: 4.4021602,
    column_2: "Evere",
    column_1: "1140",
    geom: null,
    coordonnees: {
      lat: 50.8704524,
      lon: 4.4021602,
    },
  },
  {
    column_4: 50.799394,
    column_3: 4.4158177,
    column_2: "Watermael-Boitsfort",
    column_1: "1170",
    geom: null,
    coordonnees: {
      lat: 50.799394,
      lon: 4.4158177,
    },
  },
  {
    column_4: 50.8466928,
    column_3: 4.4284842,
    column_2: "Woluwe-Saint-Lambert",
    column_1: "1200",
    geom: null,
    coordonnees: {
      lat: 50.8466928,
      lon: 4.4284842,
    },
  },
  {
    column_4: 50.8530739,
    column_3: 4.3723364,
    column_2: "Saint-Josse-Ten-Noode",
    column_1: "1210",
    geom: null,
    coordonnees: {
      lat: 50.8530739,
      lon: 4.3723364,
    },
  },
  {
    column_4: 50.73713045,
    column_3: 4.5463113019639,
    column_2: "Rosières",
    column_1: "1331",
    geom: null,
    coordonnees: {
      lat: 50.73713045,
      lon: 4.54631130196,
    },
  },
  {
    column_4: 50.6726725,
    column_3: 4.5804937701303,
    column_2: "Ottignies-Louvain-La-Neuve",
    column_1: "1340",
    geom: null,
    coordonnees: {
      lat: 50.6726725,
      lon: 4.58049377013,
    },
  },
  {
    column_4: 50.6943193,
    column_3: 4.9260633,
    column_2: "Enines",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.6943193,
      lon: 4.9260633,
    },
  },
  {
    column_4: 50.6816078,
    column_3: 4.9552091,
    column_2: "Jauche",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.6816078,
      lon: 4.9552091,
    },
  },
  {
    column_4: 50.7072943,
    column_3: 4.9529173,
    column_2: "Marilles",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.7072943,
      lon: 4.9529173,
    },
  },
  {
    column_4: 50.7293197,
    column_3: 4.9640083,
    column_2: "Noduwez",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.7293197,
      lon: 4.9640083,
    },
  },
  {
    column_4: 50.7567681,
    column_3: 4.9890154,
    column_2: "Neerheylissem",
    column_1: "1357",
    geom: null,
    coordonnees: {
      lat: 50.7567681,
      lon: 4.9890154,
    },
  },
  {
    column_4: 50.7478407,
    column_3: 4.9780806,
    column_2: "Opheylissem",
    column_1: "1357",
    geom: null,
    coordonnees: {
      lat: 50.7478407,
      lon: 4.9780806,
    },
  },
  {
    column_4: 50.6376269,
    column_3: 4.7631073,
    column_2: "Orbais",
    column_1: "1360",
    geom: null,
    coordonnees: {
      lat: 50.6376269,
      lon: 4.7631073,
    },
  },
  {
    column_4: 50.6584082,
    column_3: 4.8183242,
    column_2: "Thorembais-Les-Béguines",
    column_1: "1360",
    geom: null,
    coordonnees: {
      lat: 50.6584082,
      lon: 4.8183242,
    },
  },
  {
    column_4: 50.7016251,
    column_3: 4.8203333,
    column_2: "Dongelberg",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7016251,
      lon: 4.8203333,
    },
  },
  {
    column_4: 50.7081169,
    column_3: 4.8405644,
    column_2: "Jodoigne-Souveraine",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7081169,
      lon: 4.8405644,
    },
  },
  {
    column_4: 50.7230097,
    column_3: 4.824622,
    column_2: "Lathuy",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7230097,
      lon: 4.824622,
    },
  },
  {
    column_4: 50.7397177,
    column_3: 4.8957659,
    column_2: "Saint-Jean-Geest",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7397177,
      lon: 4.8957659,
    },
  },
  {
    column_4: 50.7471089,
    column_3: 4.8583707,
    column_2: "Saint-Remy-Geest",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7471089,
      lon: 4.8583707,
    },
  },
  {
    column_4: 50.6628104,
    column_3: 4.4825691512066,
    column_2: "Couture-Saint-Germain",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.6628104,
      lon: 4.48256915121,
    },
  },
  {
    column_4: 50.66254755,
    column_3: 4.4212124182736,
    column_2: "Plancenoit",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.66254755,
      lon: 4.42121241827,
    },
  },
  {
    column_4: 50.78382875,
    column_3: 4.6736552349571,
    column_2: "Nethen",
    column_1: "1390",
    geom: null,
    coordonnees: {
      lat: 50.78382875,
      lon: 4.67365523496,
    },
  },
  {
    column_4: 50.6172472,
    column_3: 4.3586482570372,
    column_2: "Baulers",
    column_1: "1401",
    geom: null,
    coordonnees: {
      lat: 50.6172472,
      lon: 4.35864825704,
    },
  },
  {
    column_4: 50.69657845,
    column_3: 4.1227257983451,
    column_2: "Bierghes",
    column_1: "1430",
    geom: null,
    coordonnees: {
      lat: 50.69657845,
      lon: 4.12272579835,
    },
  },
  {
    column_4: 50.6365628,
    column_3: 4.6127019,
    column_2: "Mont-Saint-Guibert",
    column_1: "1435",
    geom: null,
    coordonnees: {
      lat: 50.6365628,
      lon: 4.6127019,
    },
  },
  {
    column_4: 50.68088165,
    column_3: 4.2666690726132,
    column_2: "Braine-Le-Château",
    column_1: "1440",
    geom: null,
    coordonnees: {
      lat: 50.68088165,
      lon: 4.26666907261,
    },
  },
  {
    column_4: 50.63205335,
    column_3: 4.5192034141632,
    column_2: "Bousval",
    column_1: "1470",
    geom: null,
    coordonnees: {
      lat: 50.63205335,
      lon: 4.51920341416,
    },
  },
  {
    column_4: 50.62902485,
    column_3: 4.4015030981612,
    column_2: "Vieux-Genappe",
    column_1: "1472",
    geom: null,
    coordonnees: {
      lat: 50.62902485,
      lon: 4.40150309816,
    },
  },
  {
    column_4: 50.6269861,
    column_3: 4.4729429472611,
    column_2: "Ways",
    column_1: "1474",
    geom: null,
    coordonnees: {
      lat: 50.6269861,
      lon: 4.47294294726,
    },
  },
  {
    column_4: 50.554167,
    column_3: 4.4895548874612,
    column_2: "Sart-Dames-Avelines",
    column_1: "1495",
    geom: null,
    coordonnees: {
      lat: 50.554167,
      lon: 4.48955488746,
    },
  },
  {
    column_4: 50.57640235,
    column_3: 4.5522187220517,
    column_2: "Tilly",
    column_1: "1495",
    geom: null,
    coordonnees: {
      lat: 50.57640235,
      lon: 4.55221872205,
    },
  },
  {
    column_4: 50.747729,
    column_3: 4.0974868,
    column_2: "Herfelingen",
    column_1: "1540",
    geom: null,
    coordonnees: {
      lat: 50.747729,
      lon: 4.0974868,
    },
  },
  {
    column_4: 50.72329555,
    column_3: 4.0260115663497,
    column_2: "Herne",
    column_1: "1540",
    geom: null,
    coordonnees: {
      lat: 50.72329555,
      lon: 4.02601156635,
    },
  },
  {
    column_4: 50.70721955,
    column_3: 3.9985766720315,
    column_2: "Sint-Pieters-Kapelle",
    column_1: "1541",
    geom: null,
    coordonnees: {
      lat: 50.70721955,
      lon: 3.99857667203,
    },
  },
  {
    column_4: 50.76088,
    column_3: 4.0247359,
    column_2: "Vollezele",
    column_1: "1570",
    geom: null,
    coordonnees: {
      lat: 50.76088,
      lon: 4.0247359,
    },
  },
  {
    column_4: 50.7804875,
    column_3: 4.1958992,
    column_2: "Oudenaken",
    column_1: "1600",
    geom: null,
    coordonnees: {
      lat: 50.7804875,
      lon: 4.1958992,
    },
  },
  {
    column_4: 50.78118355,
    column_3: 4.2452186173064,
    column_2: "Sint-Pieters-Leeuw",
    column_1: "1600",
    geom: null,
    coordonnees: {
      lat: 50.78118355,
      lon: 4.24521861731,
    },
  },
  {
    column_4: 50.786532,
    column_3: 4.3173542,
    column_2: "Drogenbos",
    column_1: "1620",
    geom: null,
    coordonnees: {
      lat: 50.786532,
      lon: 4.3173542,
    },
  },
  {
    column_4: 50.76514035,
    column_3: 4.2729986221483,
    column_2: "Lot",
    column_1: "1651",
    geom: null,
    coordonnees: {
      lat: 50.76514035,
      lon: 4.27299862215,
    },
  },
  {
    column_4: 50.73779765,
    column_3: 4.1601450278998,
    column_2: "Bellingen",
    column_1: "1674",
    geom: null,
    coordonnees: {
      lat: 50.73779765,
      lon: 4.1601450279,
    },
  },
  {
    column_4: 50.8627233,
    column_3: 4.2150592,
    column_2: "Sint-Martens-Bodegem",
    column_1: "1700",
    geom: null,
    coordonnees: {
      lat: 50.8627233,
      lon: 4.2150592,
    },
  },
  {
    column_4: 50.8800337,
    column_3: 4.2206526,
    column_2: "Sint-Ulriks-Kapelle",
    column_1: "1700",
    geom: null,
    coordonnees: {
      lat: 50.8800337,
      lon: 4.2206526,
    },
  },
  {
    column_4: 50.8395994,
    column_3: 4.2501187,
    column_2: "Itterbeek",
    column_1: "1701",
    geom: null,
    coordonnees: {
      lat: 50.8395994,
      lon: 4.2501187,
    },
  },
  {
    column_4: 50.8714357,
    column_3: 4.2635668372339,
    column_2: "Groot-Bijgaarden",
    column_1: "1702",
    geom: null,
    coordonnees: {
      lat: 50.8714357,
      lon: 4.26356683723,
    },
  },
  {
    column_4: 50.7773538,
    column_3: 4.1192641,
    column_2: "Leerbeek",
    column_1: "1755",
    geom: null,
    coordonnees: {
      lat: 50.7773538,
      lon: 4.1192641,
    },
  },
  {
    column_4: 50.9277713,
    column_3: 4.2658869,
    column_2: "Brussegem",
    column_1: "1785",
    geom: null,
    coordonnees: {
      lat: 50.9277713,
      lon: 4.2658869,
    },
  },
  {
    column_4: 50.8921419,
    column_3: 4.09834,
    column_2: "Teralfene",
    column_1: "1790",
    geom: null,
    coordonnees: {
      lat: 50.8921419,
      lon: 4.09834,
    },
  },
  {
    column_4: 50.9331623,
    column_3: 4.4960702,
    column_2: "Perk",
    column_1: "1820",
    geom: null,
    coordonnees: {
      lat: 50.9331623,
      lon: 4.4960702,
    },
  },
  {
    column_4: 51.0198436,
    column_3: 4.2437819,
    column_2: "Malderen",
    column_1: "1840",
    geom: null,
    coordonnees: {
      lat: 51.0198436,
      lon: 4.2437819,
    },
  },
  {
    column_4: 50.9944384,
    column_3: 4.2624672,
    column_2: "Steenhuffel",
    column_1: "1840",
    geom: null,
    coordonnees: {
      lat: 50.9944384,
      lon: 4.2624672,
    },
  },
  {
    column_4: 50.95030565,
    column_3: 4.4013732626459,
    column_2: "Grimbergen",
    column_1: "1850",
    geom: null,
    coordonnees: {
      lat: 50.95030565,
      lon: 4.40137326265,
    },
  },
  {
    column_4: 51.0131904,
    column_3: 4.3599725,
    column_2: "Kapelle-Op-Den-Bos",
    column_1: "1880",
    geom: null,
    coordonnees: {
      lat: 51.0131904,
      lon: 4.3599725,
    },
  },
  {
    column_4: 50.937546,
    column_3: 4.617418,
    column_2: "Buken",
    column_1: "1910",
    geom: null,
    coordonnees: {
      lat: 50.937546,
      lon: 4.617418,
    },
  },
  {
    column_4: 50.8606105,
    column_3: 4.4691371,
    column_2: "Kraainem",
    column_1: "1950",
    geom: null,
    coordonnees: {
      lat: 50.8606105,
      lon: 4.4691371,
    },
  },
  {
    column_4: 50.961429,
    column_3: 4.4556575,
    column_2: "Eppegem",
    column_1: "1980",
    geom: null,
    coordonnees: {
      lat: 50.961429,
      lon: 4.4556575,
    },
  },
  {
    column_4: 50.9939033,
    column_3: 4.5042526607237,
    column_2: "Hofstade",
    column_1: "1981",
    geom: null,
    coordonnees: {
      lat: 50.9939033,
      lon: 4.50425266072,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2040",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.21029905,
    column_3: 4.4403895064496,
    column_2: "Borgerhout",
    column_1: "2140",
    geom: null,
    coordonnees: {
      lat: 51.21029905,
      lon: 4.44038950645,
    },
  },
  {
    column_4: 51.06251415,
    column_3: 4.7193765534389,
    column_2: "Heist-Op-Den-Berg",
    column_1: "2220",
    geom: null,
    coordonnees: {
      lat: 51.06251415,
      lon: 4.71937655344,
    },
  },
  {
    column_4: 51.0471677,
    column_3: 4.7692867998141,
    column_2: "Booischot",
    column_1: "2221",
    geom: null,
    coordonnees: {
      lat: 51.0471677,
      lon: 4.76928679981,
    },
  },
  {
    column_4: 51.0324718,
    column_3: 4.8336098,
    column_2: "Ramsel",
    column_1: "2230",
    geom: null,
    coordonnees: {
      lat: 51.0324718,
      lon: 4.8336098,
    },
  },
  {
    column_4: 51.04605145,
    column_3: 4.8012932645531,
    column_2: "Houtvenne",
    column_1: "2235",
    geom: null,
    coordonnees: {
      lat: 51.04605145,
      lon: 4.80129326455,
    },
  },
  {
    column_4: 51.0880889,
    column_3: 4.8729402,
    column_2: "Zoerle-Parwijs",
    column_1: "2260",
    geom: null,
    coordonnees: {
      lat: 51.0880889,
      lon: 4.8729402,
    },
  },
  {
    column_4: 51.18870335,
    column_3: 4.7318844301963,
    column_2: "Grobbendonk",
    column_1: "2280",
    geom: null,
    coordonnees: {
      lat: 51.18870335,
      lon: 4.7318844302,
    },
  },
  {
    column_4: 51.41206885,
    column_3: 5.0182445420616,
    column_2: "Weelde",
    column_1: "2381",
    geom: null,
    coordonnees: {
      lat: 51.41206885,
      lon: 5.01824454206,
    },
  },
  {
    column_4: 51.412750431352,
    column_3: 4.9004051710883,
    column_2: "Baarle-Hertog",
    column_1: "2387",
    geom: null,
    coordonnees: {
      lat: 51.4127504314,
      lon: 4.90040517109,
    },
  },
  {
    column_4: 51.3001124,
    column_3: 4.7149828,
    column_2: "Malle",
    column_1: "2390",
    geom: null,
    coordonnees: {
      lat: 51.3001124,
      lon: 4.7149828,
    },
  },
  {
    column_4: 51.3019209,
    column_3: 4.7331618,
    column_2: "Oostmalle",
    column_1: "2390",
    geom: null,
    coordonnees: {
      lat: 51.3019209,
      lon: 4.7331618,
    },
  },
  {
    column_4: 51.10171295,
    column_3: 4.9972890275518,
    column_2: "Eindhout",
    column_1: "2430",
    geom: null,
    coordonnees: {
      lat: 51.10171295,
      lon: 4.99728902755,
    },
  },
  {
    column_4: 51.2407915,
    column_3: 4.967812,
    column_2: "Kasterlee",
    column_1: "2460",
    geom: null,
    coordonnees: {
      lat: 51.2407915,
      lon: 4.967812,
    },
  },
  {
    column_4: 51.2309182,
    column_3: 4.9037390737022,
    column_2: "Lichtaart",
    column_1: "2460",
    geom: null,
    coordonnees: {
      lat: 51.2309182,
      lon: 4.9037390737,
    },
  },
  {
    column_4: 51.2110687,
    column_3: 4.5972162,
    column_2: "Oelegem",
    column_1: "2520",
    geom: null,
    coordonnees: {
      lat: 51.2110687,
      lon: 4.5972162,
    },
  },
  {
    column_4: 51.1486117,
    column_3: 4.477387,
    column_2: "Hove",
    column_1: "2540",
    geom: null,
    coordonnees: {
      lat: 51.1486117,
      lon: 4.477387,
    },
  },
  {
    column_4: 51.0957176,
    column_3: 4.5061988,
    column_2: "Duffel",
    column_1: "2570",
    geom: null,
    coordonnees: {
      lat: 51.0957176,
      lon: 4.5061988,
    },
  },
  {
    column_4: 51.06299735,
    column_3: 4.6630807412987,
    column_2: "Beerzel",
    column_1: "2580",
    geom: null,
    coordonnees: {
      lat: 51.06299735,
      lon: 4.6630807413,
    },
  },
  {
    column_4: 51.133297,
    column_3: 4.3870241,
    column_2: "Aartselaar",
    column_1: "2630",
    geom: null,
    coordonnees: {
      lat: 51.133297,
      lon: 4.3870241,
    },
  },
  {
    column_4: 51.1757824,
    column_3: 4.3511502171395,
    column_2: "Hoboken",
    column_1: "2660",
    geom: null,
    coordonnees: {
      lat: 51.1757824,
      lon: 4.35115021714,
    },
  },
  {
    column_4: 51.06232435,
    column_3: 4.5114278140147,
    column_2: "Sint-Katelijne-Waver",
    column_1: "2860",
    geom: null,
    coordonnees: {
      lat: 51.06232435,
      lon: 4.51142781401,
    },
  },
  {
    column_4: 51.332759,
    column_3: 4.4312318305558,
    column_2: "Kapellen",
    column_1: "2950",
    geom: null,
    coordonnees: {
      lat: 51.332759,
      lon: 4.43123183056,
    },
  },
  {
    column_4: 51.3347679,
    column_3: 4.5972381789962,
    column_2: "Brecht",
    column_1: "2960",
    geom: null,
    coordonnees: {
      lat: 51.3347679,
      lon: 4.597238179,
    },
  },
  {
    column_4: 50.9095362,
    column_3: 4.7136294,
    column_2: "Wilsele",
    column_1: "3012",
    geom: null,
    coordonnees: {
      lat: 50.9095362,
      lon: 4.7136294,
    },
  },
  {
    column_4: 50.9020979,
    column_3: 4.6255138,
    column_2: "Veltem-Beisem",
    column_1: "3020",
    geom: null,
    coordonnees: {
      lat: 50.9020979,
      lon: 4.6255138,
    },
  },
  {
    column_4: 50.7950895,
    column_3: 4.5828587400028,
    column_2: "Huldenberg",
    column_1: "3040",
    geom: null,
    coordonnees: {
      lat: 50.7950895,
      lon: 4.58285874,
    },
  },
  {
    column_4: 50.7540955,
    column_3: 4.6258024576448,
    column_2: "Ottenburg",
    column_1: "3040",
    geom: null,
    coordonnees: {
      lat: 50.7540955,
      lon: 4.62580245764,
    },
  },
  {
    column_4: 50.8281673,
    column_3: 4.7056796612254,
    column_2: "Blanden",
    column_1: "3052",
    geom: null,
    coordonnees: {
      lat: 50.8281673,
      lon: 4.70567966123,
    },
  },
  {
    column_4: 50.83488335,
    column_3: 4.5565660886042,
    column_2: "Vossem",
    column_1: "3080",
    geom: null,
    coordonnees: {
      lat: 50.83488335,
      lon: 4.5565660886,
    },
  },
  {
    column_4: 50.9769286,
    column_3: 4.6386824,
    column_2: "Haacht",
    column_1: "3150",
    geom: null,
    coordonnees: {
      lat: 50.9769286,
      lon: 4.6386824,
    },
  },
  {
    column_4: 50.97591095,
    column_3: 4.8970494266873,
    column_2: "Rillaar",
    column_1: "3202",
    geom: null,
    coordonnees: {
      lat: 50.97591095,
      lon: 4.89704942669,
    },
  },
  {
    column_4: 50.92172015,
    column_3: 4.7557296717686,
    column_2: "Holsbeek",
    column_1: "3220",
    geom: null,
    coordonnees: {
      lat: 50.92172015,
      lon: 4.75572967177,
    },
  },
  {
    column_4: 51.0278406,
    column_3: 4.9770845794874,
    column_2: "Averbode",
    column_1: "3271",
    geom: null,
    coordonnees: {
      lat: 51.0278406,
      lon: 4.97708457949,
    },
  },
  {
    column_4: 51.0085771,
    column_3: 4.984958799435,
    column_2: "Zichem",
    column_1: "3271",
    geom: null,
    coordonnees: {
      lat: 51.0085771,
      lon: 4.98495879943,
    },
  },
  {
    column_4: 51.0392379,
    column_3: 5.0964572,
    column_2: "Deurne",
    column_1: "3290",
    geom: null,
    coordonnees: {
      lat: 51.0392379,
      lon: 5.0964572,
    },
  },
  {
    column_4: 50.98456645,
    column_3: 5.052873496255,
    column_2: "Diest",
    column_1: "3290",
    geom: null,
    coordonnees: {
      lat: 50.98456645,
      lon: 5.05287349625,
    },
  },
  {
    column_4: 50.7971718,
    column_3: 4.9886552612345,
    column_2: "Hakendover",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.7971718,
      lon: 4.98865526123,
    },
  },
  {
    column_4: 50.7877912,
    column_3: 4.88114193614,
    column_2: "Hoegaarden",
    column_1: "3320",
    geom: null,
    coordonnees: {
      lat: 50.7877912,
      lon: 4.88114193614,
    },
  },
  {
    column_4: 50.7665968,
    column_3: 4.9193571,
    column_2: "Outgaarden",
    column_1: "3321",
    geom: null,
    coordonnees: {
      lat: 50.7665968,
      lon: 4.9193571,
    },
  },
  {
    column_4: 50.78801405,
    column_3: 5.0579336298623,
    column_2: "Neerhespen",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.78801405,
      lon: 5.05793362986,
    },
  },
  {
    column_4: 50.812361,
    column_3: 5.0183106289924,
    column_2: "Wommersom",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.812361,
      lon: 5.01831062899,
    },
  },
  {
    column_4: 50.80732735,
    column_3: 4.7912847996064,
    column_2: "Opvelp",
    column_1: "3360",
    geom: null,
    coordonnees: {
      lat: 50.80732735,
      lon: 4.79128479961,
    },
  },
  {
    column_4: 50.8473461,
    column_3: 4.8336178052558,
    column_2: "Boutersem",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.8473461,
      lon: 4.83361780526,
    },
  },
  {
    column_4: 50.85699665,
    column_3: 4.8643358054912,
    column_2: "Kerkom",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.85699665,
      lon: 4.86433580549,
    },
  },
  {
    column_4: 50.8725203,
    column_3: 4.9513009,
    column_2: "Glabbeek",
    column_1: "3380",
    geom: null,
    coordonnees: {
      lat: 50.8725203,
      lon: 4.9513009,
    },
  },
  {
    column_4: 50.78710135,
    column_3: 5.0074922319452,
    column_2: "Eliksem",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.78710135,
      lon: 5.00749223195,
    },
  },
  {
    column_4: 51.2687557,
    column_3: 4.4410406,
    column_2: "Laar",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 51.2687557,
      lon: 4.4410406,
    },
  },
  {
    column_4: 50.7652288,
    column_3: 5.0409975765006,
    column_2: "Neerwinden",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.7652288,
      lon: 5.0409975765,
    },
  },
  {
    column_4: 50.75242545,
    column_3: 5.0448025257015,
    column_2: "Overwinden",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.75242545,
      lon: 5.0448025257,
    },
  },
  {
    column_4: 50.72608345,
    column_3: 5.0609420487376,
    column_2: "Waasmont",
    column_1: "3401",
    geom: null,
    coordonnees: {
      lat: 50.72608345,
      lon: 5.06094204874,
    },
  },
  {
    column_4: 50.7385007,
    column_3: 5.0878535498936,
    column_2: "Walsbets",
    column_1: "3401",
    geom: null,
    coordonnees: {
      lat: 50.7385007,
      lon: 5.08785354989,
    },
  },
  {
    column_4: 50.8754117,
    column_3: 5.1299277800431,
    column_2: "Grazen",
    column_1: "3450",
    geom: null,
    coordonnees: {
      lat: 50.8754117,
      lon: 5.12992778004,
    },
  },
  {
    column_4: 50.88594765,
    column_3: 5.1564917481959,
    column_2: "Rummen",
    column_1: "3454",
    geom: null,
    coordonnees: {
      lat: 50.88594765,
      lon: 5.1564917482,
    },
  },
  {
    column_4: 51.00313255,
    column_3: 5.2025589998731,
    column_2: "Lummen",
    column_1: "3560",
    geom: null,
    coordonnees: {
      lat: 51.00313255,
      lon: 5.20255899987,
    },
  },
  {
    column_4: 50.8754731,
    column_3: 5.3079873,
    column_2: "Alken",
    column_1: "3570",
    geom: null,
    coordonnees: {
      lat: 50.8754731,
      lon: 5.3079873,
    },
  },
  {
    column_4: 51.06978945,
    column_3: 5.271159654589,
    column_2: "Koersel",
    column_1: "3582",
    geom: null,
    coordonnees: {
      lat: 51.06978945,
      lon: 5.27115965459,
    },
  },
  {
    column_4: 50.9654864,
    column_3: 5.5001456,
    column_2: "Genk",
    column_1: "3600",
    geom: null,
    coordonnees: {
      lat: 50.9654864,
      lon: 5.5001456,
    },
  },
  {
    column_4: 50.9921686,
    column_3: 5.7355142,
    column_2: "Leut",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9921686,
      lon: 5.7355142,
    },
  },
  {
    column_4: 50.9635024,
    column_3: 5.696445,
    column_2: "Maasmechelen",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9635024,
      lon: 5.696445,
    },
  },
  {
    column_4: 51.0197939,
    column_3: 5.7508381619462,
    column_2: "Stokkem",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.0197939,
      lon: 5.75083816195,
    },
  },
  {
    column_4: 51.0429949,
    column_3: 5.581962,
    column_2: "Opglabbeek",
    column_1: "3660",
    geom: null,
    coordonnees: {
      lat: 51.0429949,
      lon: 5.581962,
    },
  },
  {
    column_4: 51.07822635,
    column_3: 5.5029170798132,
    column_2: "Meeuwen",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.07822635,
      lon: 5.50291707981,
    },
  },
  {
    column_4: 51.0770409,
    column_3: 5.532541372001,
    column_2: "Wijshagen",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.0770409,
      lon: 5.532541372,
    },
  },
  {
    column_4: 50.8064756,
    column_3: 5.5030479,
    column_2: "'s Herenelderen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8064756,
      lon: 5.5030479,
    },
  },
  {
    column_4: 51.1695659,
    column_3: 5.1521172,
    column_2: "Berg",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 51.1695659,
      lon: 5.1521172,
    },
  },
  {
    column_4: 50.8919661,
    column_3: 4.4125708732431,
    column_2: "Haren",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8919661,
      lon: 4.41257087324,
    },
  },
  {
    column_4: 50.8027416,
    column_3: 5.4206775,
    column_2: "Kolmont",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8027416,
      lon: 5.4206775,
    },
  },
  {
    column_4: 50.7689604,
    column_3: 5.5223906,
    column_2: "Mal",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7689604,
      lon: 5.5223906,
    },
  },
  {
    column_4: 50.7630913,
    column_3: 5.5102044,
    column_2: "Nerem",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7630913,
      lon: 5.5102044,
    },
  },
  {
    column_4: 51.133268,
    column_3: 2.7568247,
    column_2: "Sluizen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 51.133268,
      lon: 2.7568247,
    },
  },
  {
    column_4: 50.8713356,
    column_3: 5.5163011,
    column_2: "Bilzen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8713356,
      lon: 5.5163011,
    },
  },
  {
    column_4: 50.8638819,
    column_3: 5.5464648,
    column_2: "Waltwilder",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8638819,
      lon: 5.5464648,
    },
  },
  {
    column_4: 50.8017668,
    column_3: 5.5369013,
    column_2: "Genoelselderen",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8017668,
      lon: 5.5369013,
    },
  },
  {
    column_4: 50.72838165,
    column_3: 5.8818776028446,
    column_2: "Remersdaal",
    column_1: "3791",
    geom: null,
    coordonnees: {
      lat: 50.72838165,
      lon: 5.88187760284,
    },
  },
  {
    column_4: 50.8137716,
    column_3: 5.2381902322176,
    column_2: "Ordingen",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.8137716,
      lon: 5.23819023222,
    },
  },
  {
    column_4: 50.83411245,
    column_3: 5.1461581286779,
    column_2: "Duras",
    column_1: "3803",
    geom: null,
    coordonnees: {
      lat: 50.83411245,
      lon: 5.14615812868,
    },
  },
  {
    column_4: 50.78000075,
    column_3: 5.1322112767812,
    column_2: "Velm",
    column_1: "3806",
    geom: null,
    coordonnees: {
      lat: 50.78000075,
      lon: 5.13221127678,
    },
  },
  {
    column_4: 50.8308593,
    column_3: 5.3849412792168,
    column_2: "Gors-Opleeuw",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.8308593,
      lon: 5.38494127922,
    },
  },
  {
    column_4: 50.7366903,
    column_3: 5.2944311,
    column_2: "Opheers",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7366903,
      lon: 5.2944311,
    },
  },
  {
    column_4: 50.7278444,
    column_3: 5.2539162,
    column_2: "Rukkelingen-Loon",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7278444,
      lon: 5.2539162,
    },
  },
  {
    column_4: 50.7633827,
    column_3: 5.3063809,
    column_2: "Veulen",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7633827,
      lon: 5.3063809,
    },
  },
  {
    column_4: 51.2253075,
    column_3: 5.4937001151083,
    column_2: "Sint-Huibrechts-Lille",
    column_1: "3910",
    geom: null,
    coordonnees: {
      lat: 51.2253075,
      lon: 5.49370011511,
    },
  },
  {
    column_4: 51.11664635,
    column_3: 5.3415643775263,
    column_2: "Hechtel",
    column_1: "3940",
    geom: null,
    coordonnees: {
      lat: 51.11664635,
      lon: 5.34156437753,
    },
  },
  {
    column_4: 51.1567865,
    column_3: 5.3440439268887,
    column_2: "Eksel",
    column_1: "3941",
    geom: null,
    coordonnees: {
      lat: 51.1567865,
      lon: 5.34404392689,
    },
  },
  {
    column_4: 51.0681628,
    column_3: 5.0893974,
    column_2: "Tessenderlo",
    column_1: "3980",
    geom: null,
    coordonnees: {
      lat: 51.0681628,
      lon: 5.0893974,
    },
  },
  {
    column_4: 50.648205,
    column_3: 5.541864,
    column_2: "Glain",
    column_1: "4000",
    geom: null,
    coordonnees: {
      lat: 50.648205,
      lon: 5.541864,
    },
  },
  {
    column_4: 50.6451381,
    column_3: 5.5734203,
    column_2: "Liège 1",
    column_1: "4000",
    geom: null,
    coordonnees: {
      lat: 50.6451381,
      lon: 5.5734203,
    },
  },
  {
    column_4: 50.6111024,
    column_3: 5.619261,
    column_2: "Chênee",
    column_1: "4032",
    geom: null,
    coordonnees: {
      lat: 50.6111024,
      lon: 5.619261,
    },
  },
  {
    column_4: 50.672653,
    column_3: 5.5846274,
    column_2: "Vottem",
    column_1: "4041",
    geom: null,
    coordonnees: {
      lat: 50.672653,
      lon: 5.5846274,
    },
  },
  {
    column_4: 50.5847922,
    column_3: 5.6469688,
    column_2: "Chaudfontaine",
    column_1: "4050",
    geom: null,
    coordonnees: {
      lat: 50.5847922,
      lon: 5.6469688,
    },
  },
  {
    column_4: 50.5520337,
    column_3: 5.4514318,
    column_2: "Neuville-En-Condroz",
    column_1: "4121",
    geom: null,
    coordonnees: {
      lat: 50.5520337,
      lon: 5.4514318,
    },
  },
  {
    column_4: 50.5446771,
    column_3: 5.5215011,
    column_2: "Plainevaux",
    column_1: "4122",
    geom: null,
    coordonnees: {
      lat: 50.5446771,
      lon: 5.5215011,
    },
  },
  {
    column_4: 50.5065636,
    column_3: 5.6466570762919,
    column_2: "Sprimont",
    column_1: "4140",
    geom: null,
    coordonnees: {
      lat: 50.5065636,
      lon: 5.64665707629,
    },
  },
  {
    column_4: 50.5248417,
    column_3: 5.7172477995985,
    column_2: "Louveigné",
    column_1: "4141",
    geom: null,
    coordonnees: {
      lat: 50.5248417,
      lon: 5.7172477996,
    },
  },
  {
    column_4: 50.5097627,
    column_3: 5.5827847,
    column_2: "Poulseur",
    column_1: "4171",
    geom: null,
    coordonnees: {
      lat: 50.5097627,
      lon: 5.5827847,
    },
  },
  {
    column_4: 50.4454473,
    column_3: 5.5428656,
    column_2: "Comblain-Fairon",
    column_1: "4180",
    geom: null,
    coordonnees: {
      lat: 50.4454473,
      lon: 5.5428656,
    },
  },
  {
    column_4: 50.4253958,
    column_3: 5.5328465,
    column_2: "Hamoir",
    column_1: "4180",
    geom: null,
    coordonnees: {
      lat: 50.4253958,
      lon: 5.5328465,
    },
  },
  {
    column_4: 50.426751,
    column_3: 5.5680501,
    column_2: "Filot",
    column_1: "4181",
    geom: null,
    coordonnees: {
      lat: 50.426751,
      lon: 5.5680501,
    },
  },
  {
    column_4: 50.3800099,
    column_3: 5.686798,
    column_2: "Werbomont",
    column_1: "4190",
    geom: null,
    coordonnees: {
      lat: 50.3800099,
      lon: 5.686798,
    },
  },
  {
    column_4: 50.583727,
    column_3: 5.0899261,
    column_2: "Lamontzée",
    column_1: "4210",
    geom: null,
    coordonnees: {
      lat: 50.583727,
      lon: 5.0899261,
    },
  },
  {
    column_4: 50.5790977,
    column_3: 5.1448898,
    column_2: "Marneffe",
    column_1: "4210",
    geom: null,
    coordonnees: {
      lat: 50.5790977,
      lon: 5.1448898,
    },
  },
  {
    column_4: 50.66174,
    column_3: 5.182381,
    column_2: "Ligney",
    column_1: "4254",
    geom: null,
    coordonnees: {
      lat: 50.66174,
      lon: 5.182381,
    },
  },
  {
    column_4: 50.7095698,
    column_3: 5.2131541,
    column_2: "Corswarem",
    column_1: "4257",
    geom: null,
    coordonnees: {
      lat: 50.7095698,
      lon: 5.2131541,
    },
  },
  {
    column_4: 50.6287517,
    column_3: 5.1159746,
    column_2: "Avennes",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.6287517,
      lon: 5.1159746,
    },
  },
  {
    column_4: 50.6182889,
    column_3: 5.1157512,
    column_2: "Ville-En-Hesbaye",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.6182889,
      lon: 5.1157512,
    },
  },
  {
    column_4: 50.6941776,
    column_3: 5.089556,
    column_2: "Bertrée",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6941776,
      lon: 5.089556,
    },
  },
  {
    column_4: 50.6605954,
    column_3: 5.0621464,
    column_2: "Crehen",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6605954,
      lon: 5.0621464,
    },
  },
  {
    column_4: 50.6724649,
    column_3: 5.0779916,
    column_2: "Hannut",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6724649,
      lon: 5.0779916,
    },
  },
  {
    column_4: 50.6534024,
    column_3: 5.1329047,
    column_2: "Lens-Saint-Remy",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6534024,
      lon: 5.1329047,
    },
  },
  {
    column_4: 50.6492972,
    column_3: 4.9987365,
    column_2: "Merdorp",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6492972,
      lon: 4.9987365,
    },
  },
  {
    column_4: 50.678627,
    column_3: 5.02018,
    column_2: "Wansin",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.678627,
      lon: 5.02018,
    },
  },
  {
    column_4: 50.6674954,
    column_3: 5.2843336,
    column_2: "Bovenistier",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.6674954,
      lon: 5.2843336,
    },
  },
  {
    column_4: 50.6482877,
    column_3: 5.2683121,
    column_2: "Viemme",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.6482877,
      lon: 5.2683121,
    },
  },
  {
    column_4: 50.7159281,
    column_3: 5.4680939,
    column_2: "Othée",
    column_1: "4340",
    geom: null,
    coordonnees: {
      lat: 50.7159281,
      lon: 5.4680939,
    },
  },
  {
    column_4: 50.6961807,
    column_3: 5.4060353,
    column_2: "Kemexhe",
    column_1: "4367",
    geom: null,
    coordonnees: {
      lat: 50.6961807,
      lon: 5.4060353,
    },
  },
  {
    column_4: 50.707654,
    column_3: 5.4158293,
    column_2: "Odeur",
    column_1: "4367",
    geom: null,
    coordonnees: {
      lat: 50.707654,
      lon: 5.4158293,
    },
  },
  {
    column_4: 50.7225262,
    column_3: 5.3894005,
    column_2: "Thys",
    column_1: "4367",
    geom: null,
    coordonnees: {
      lat: 50.7225262,
      lon: 5.3894005,
    },
  },
  {
    column_4: 50.6018434,
    column_3: 5.4474684,
    column_2: "Flémalle-Haute",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.6018434,
      lon: 5.4474684,
    },
  },
  {
    column_4: 50.6305341,
    column_3: 5.539419,
    column_2: "Saint-Nicolas",
    column_1: "4420",
    geom: null,
    coordonnees: {
      lat: 50.6305341,
      lon: 5.539419,
    },
  },
  {
    column_4: 50.620651,
    column_3: 5.5307061,
    column_2: "Tilleur",
    column_1: "4420",
    geom: null,
    coordonnees: {
      lat: 50.620651,
      lon: 5.5307061,
    },
  },
  {
    column_4: 50.665153,
    column_3: 5.502732,
    column_2: "Loncin",
    column_1: "4431",
    geom: null,
    coordonnees: {
      lat: 50.665153,
      lon: 5.502732,
    },
  },
  {
    column_4: 50.7277451,
    column_3: 5.5064112,
    column_2: "Wihogne",
    column_1: "4452",
    geom: null,
    coordonnees: {
      lat: 50.7277451,
      lon: 5.5064112,
    },
  },
  {
    column_4: 50.569046,
    column_3: 5.3922,
    column_2: "Clermont-Sous-Huy",
    column_1: "4480",
    geom: null,
    coordonnees: {
      lat: 50.569046,
      lon: 5.3922,
    },
  },
  {
    column_4: 50.5332587,
    column_3: 5.2166503,
    column_2: "Wanze",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.5332587,
      lon: 5.2166503,
    },
  },
  {
    column_4: 50.5951052,
    column_3: 5.2254442,
    column_2: "Warnant-Dreye",
    column_1: "4530",
    geom: null,
    coordonnees: {
      lat: 50.5951052,
      lon: 5.2254442,
    },
  },
  {
    column_4: 50.6216405,
    column_3: 5.2989318,
    column_2: "Seraing-Le-Château",
    column_1: "4537",
    geom: null,
    coordonnees: {
      lat: 50.6216405,
      lon: 5.2989318,
    },
  },
  {
    column_4: 50.6075114,
    column_3: 5.3184439,
    column_2: "Verlaine",
    column_1: "4537",
    geom: null,
    coordonnees: {
      lat: 50.6075114,
      lon: 5.3184439,
    },
  },
  {
    column_4: 50.5081151,
    column_3: 5.3708571,
    column_2: "Villers-Le-Temple",
    column_1: "4550",
    geom: null,
    coordonnees: {
      lat: 50.5081151,
      lon: 5.3708571,
    },
  },
  {
    column_4: 50.4735257,
    column_3: 5.3564571,
    column_2: "Abée",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.4735257,
      lon: 5.3564571,
    },
  },
  {
    column_4: 50.504232,
    column_3: 5.335294,
    column_2: "Outrelouxhe",
    column_1: "4577",
    geom: null,
    coordonnees: {
      lat: 50.504232,
      lon: 5.335294,
    },
  },
  {
    column_4: 50.4922647,
    column_3: 5.3271603,
    column_2: "Strée-Lez-Huy",
    column_1: "4577",
    geom: null,
    coordonnees: {
      lat: 50.4922647,
      lon: 5.3271603,
    },
  },
  {
    column_4: 50.4487876,
    column_3: 5.427096,
    column_2: "Warzée",
    column_1: "4590",
    geom: null,
    coordonnees: {
      lat: 50.4487876,
      lon: 5.427096,
    },
  },
  {
    column_4: 50.7806558,
    column_3: 5.6944381,
    column_2: "Lanaye",
    column_1: "4600",
    geom: null,
    coordonnees: {
      lat: 50.7806558,
      lon: 5.6944381,
    },
  },
  {
    column_4: 50.6953487,
    column_3: 5.7536486,
    column_2: "Saint-André",
    column_1: "4606",
    geom: null,
    coordonnees: {
      lat: 50.6953487,
      lon: 5.7536486,
    },
  },
  {
    column_4: 50.7041818,
    column_3: 5.7138885,
    column_2: "Feneur",
    column_1: "4607",
    geom: null,
    coordonnees: {
      lat: 50.7041818,
      lon: 5.7138885,
    },
  },
  {
    column_4: 50.7132408,
    column_3: 5.7513854,
    column_2: "Mortroux",
    column_1: "4607",
    geom: null,
    coordonnees: {
      lat: 50.7132408,
      lon: 5.7513854,
    },
  },
  {
    column_4: 50.6371513,
    column_3: 5.6785831,
    column_2: "Queue-Du-Bois",
    column_1: "4610",
    geom: null,
    coordonnees: {
      lat: 50.6371513,
      lon: 5.6785831,
    },
  },
  {
    column_4: 50.6147718,
    column_3: 5.739941,
    column_2: "Soumagne",
    column_1: "4630",
    geom: null,
    coordonnees: {
      lat: 50.6147718,
      lon: 5.739941,
    },
  },
  {
    column_4: 50.6392112,
    column_3: 5.7935245,
    column_2: "Herve",
    column_1: "4650",
    geom: null,
    coordonnees: {
      lat: 50.6392112,
      lon: 5.7935245,
    },
  },
  {
    column_4: 50.6958409,
    column_3: 5.700802,
    column_2: "Saint-Remy",
    column_1: "4672",
    geom: null,
    coordonnees: {
      lat: 50.6958409,
      lon: 5.700802,
    },
  },
  {
    column_4: 50.7091279,
    column_3: 6.0316891,
    column_2: "Hergenrath",
    column_1: "4728",
    geom: null,
    coordonnees: {
      lat: 50.7091279,
      lon: 6.0316891,
    },
  },
  {
    column_4: 50.4573116,
    column_3: 6.2213827,
    column_2: "Elsenborn",
    column_1: "4750",
    geom: null,
    coordonnees: {
      lat: 50.4573116,
      lon: 6.2213827,
    },
  },
  {
    column_4: 50.3631661,
    column_3: 6.218691,
    column_2: "Heppenbach",
    column_1: "4771",
    geom: null,
    coordonnees: {
      lat: 50.3631661,
      lon: 6.218691,
    },
  },
  {
    column_4: 50.4386821,
    column_3: 5.8901888,
    column_2: "Andrimont",
    column_1: "4821",
    geom: null,
    coordonnees: {
      lat: 50.4386821,
      lon: 5.8901888,
    },
  },
  {
    column_4: 50.6311898,
    column_3: 5.9715011,
    column_2: "Baelen",
    column_1: "4837",
    geom: null,
    coordonnees: {
      lat: 50.6311898,
      lon: 5.9715011,
    },
  },
  {
    column_4: 50.6193792,
    column_3: 5.9953656,
    column_2: "Membach",
    column_1: "4837",
    geom: null,
    coordonnees: {
      lat: 50.6193792,
      lon: 5.9953656,
    },
  },
  {
    column_4: 50.7075126,
    column_3: 5.9620321,
    column_2: "Montzen",
    column_1: "4850",
    geom: null,
    coordonnees: {
      lat: 50.7075126,
      lon: 5.9620321,
    },
  },
  {
    column_4: 50.7463908,
    column_3: 5.9967631,
    column_2: "Gemmenich",
    column_1: "4851",
    geom: null,
    coordonnees: {
      lat: 50.7463908,
      lon: 5.9967631,
    },
  },
  {
    column_4: 50.5921673,
    column_3: 5.7906072,
    column_2: "Soiron",
    column_1: "4861",
    geom: null,
    coordonnees: {
      lat: 50.5921673,
      lon: 5.7906072,
    },
  },
  {
    column_4: 50.4920838,
    column_3: 5.8626233,
    column_2: "Spa",
    column_1: "4900",
    geom: null,
    coordonnees: {
      lat: 50.4920838,
      lon: 5.8626233,
    },
  },
  {
    column_4: 50.542907,
    column_3: 5.873929354985,
    column_2: "Polleur",
    column_1: "4910",
    geom: null,
    coordonnees: {
      lat: 50.542907,
      lon: 5.87392935499,
    },
  },
  {
    column_4: 50.4409138,
    column_3: 5.6672743,
    column_2: "Harzé",
    column_1: "4920",
    geom: null,
    coordonnees: {
      lat: 50.4409138,
      lon: 5.6672743,
    },
  },
  {
    column_4: 50.4054037,
    column_3: 6.1407071,
    column_2: "Faymonville",
    column_1: "4950",
    geom: null,
    coordonnees: {
      lat: 50.4054037,
      lon: 6.1407071,
    },
  },
  {
    column_4: 50.3579736,
    column_3: 5.826789,
    column_2: "Basse-Bodeux",
    column_1: "4983",
    geom: null,
    coordonnees: {
      lat: 50.3579736,
      lon: 5.826789,
    },
  },
  {
    column_4: 50.47887385,
    column_3: 4.891664450549,
    column_2: "Bouge",
    column_1: "5004",
    geom: null,
    coordonnees: {
      lat: 50.47887385,
      lon: 4.89166445055,
    },
  },
  {
    column_4: 50.456602,
    column_3: 4.8120768,
    column_2: "Flawinne",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.456602,
      lon: 4.8120768,
    },
  },
  {
    column_4: 50.5019599,
    column_3: 4.8742389,
    column_2: "Vedrin",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.5019599,
      lon: 4.8742389,
    },
  },
  {
    column_4: 50.5113799,
    column_3: 4.9578287274527,
    column_2: "Gelbressée",
    column_1: "5024",
    geom: null,
    coordonnees: {
      lat: 50.5113799,
      lon: 4.95782872745,
    },
  },
  {
    column_4: 50.5533536,
    column_3: 4.7273547,
    column_2: "Lonzée",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.5533536,
      lon: 4.7273547,
    },
  },
  {
    column_4: 50.57820735,
    column_3: 4.7609593378472,
    column_2: "Grand-Leez",
    column_1: "5031",
    geom: null,
    coordonnees: {
      lat: 50.57820735,
      lon: 4.76095933785,
    },
  },
  {
    column_4: 50.5105186,
    column_3: 4.7406918,
    column_2: "Isnes",
    column_1: "5032",
    geom: null,
    coordonnees: {
      lat: 50.5105186,
      lon: 4.7406918,
    },
  },
  {
    column_4: 50.5127674,
    column_3: 4.6745108,
    column_2: "Mazy",
    column_1: "5032",
    geom: null,
    coordonnees: {
      lat: 50.5127674,
      lon: 4.6745108,
    },
  },
  {
    column_4: 50.4655148,
    column_3: 4.5960062,
    column_2: "Keumiée",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4655148,
      lon: 4.5960062,
    },
  },
  {
    column_4: 50.4409806,
    column_3: 4.6196692,
    column_2: "Sambreville",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4409806,
      lon: 4.6196692,
    },
  },
  {
    column_4: 50.4340969,
    column_3: 4.607595,
    column_2: "Tamines",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4340969,
      lon: 4.607595,
    },
  },
  {
    column_4: 50.4027187,
    column_3: 4.7411668,
    column_2: "Sart-Saint-Laurent",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.4027187,
      lon: 4.7411668,
    },
  },
  {
    column_4: 50.5157255,
    column_3: 4.8279560785714,
    column_2: "Emines",
    column_1: "5080",
    geom: null,
    coordonnees: {
      lat: 50.5157255,
      lon: 4.82795607857,
    },
  },
  {
    column_4: 50.4133942,
    column_3: 5.7266989,
    column_2: "La Bruyère",
    column_1: "5080",
    geom: null,
    coordonnees: {
      lat: 50.4133942,
      lon: 5.7266989,
    },
  },
  {
    column_4: 50.5348173,
    column_3: 4.8587823219021,
    column_2: "Warisoulx",
    column_1: "5080",
    geom: null,
    coordonnees: {
      lat: 50.5348173,
      lon: 4.8587823219,
    },
  },
  {
    column_4: 50.5126287,
    column_3: 4.5757572,
    column_2: "Ligny",
    column_1: "5140",
    geom: null,
    coordonnees: {
      lat: 50.5126287,
      lon: 4.5757572,
    },
  },
  {
    column_4: 50.4923374,
    column_3: 4.6696856,
    column_2: "Onoz",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.4923374,
      lon: 4.6696856,
    },
  },
  {
    column_4: 50.5596814,
    column_3: 4.8573789,
    column_2: "Dhuy",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5596814,
      lon: 4.8573789,
    },
  },
  {
    column_4: 50.5834955,
    column_3: 4.9440702,
    column_2: "Hanret",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5834955,
      lon: 4.9440702,
    },
  },
  {
    column_4: 50.5605615,
    column_3: 4.9103111,
    column_2: "Leuze",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5605615,
      lon: 4.9103111,
    },
  },
  {
    column_4: 50.5730646,
    column_3: 4.8427332,
    column_2: "Saint-Germain",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5730646,
      lon: 4.8427332,
    },
  },
  {
    column_4: 50.3468626,
    column_3: 4.9581149,
    column_2: "Crupet",
    column_1: "5332",
    geom: null,
    coordonnees: {
      lat: 50.3468626,
      lon: 4.9581149,
    },
  },
  {
    column_4: 50.3730757,
    column_3: 5.0704373,
    column_2: "Florée",
    column_1: "5334",
    geom: null,
    coordonnees: {
      lat: 50.3730757,
      lon: 5.0704373,
    },
  },
  {
    column_4: 50.3408694,
    column_3: 5.1594475,
    column_2: "Hamois",
    column_1: "5360",
    geom: null,
    coordonnees: {
      lat: 50.3408694,
      lon: 5.1594475,
    },
  },
  {
    column_4: 50.3413559,
    column_3: 5.0562173,
    column_2: "Natoye",
    column_1: "5360",
    geom: null,
    coordonnees: {
      lat: 50.3413559,
      lon: 5.0562173,
    },
  },
  {
    column_4: 50.3079186,
    column_3: 5.2094413,
    column_2: "Scy",
    column_1: "5361",
    geom: null,
    coordonnees: {
      lat: 50.3079186,
      lon: 5.2094413,
    },
  },
  {
    column_4: 50.3579006,
    column_3: 5.1237159,
    column_2: "Schaltin",
    column_1: "5364",
    geom: null,
    coordonnees: {
      lat: 50.3579006,
      lon: 5.1237159,
    },
  },
  {
    column_4: 50.3870946,
    column_3: 5.23958,
    column_2: "Havelange",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3870946,
      lon: 5.23958,
    },
  },
  {
    column_4: 50.288759,
    column_3: 5.338213,
    column_2: "Baillonville",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.288759,
      lon: 5.338213,
    },
  },
  {
    column_4: 50.3727364,
    column_3: 5.3788758,
    column_2: "Bonsin",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.3727364,
      lon: 5.3788758,
    },
  },
  {
    column_4: 50.2910362,
    column_3: 5.2612996,
    column_2: "Nettinne",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.2910362,
      lon: 5.2612996,
    },
  },
  {
    column_4: 50.567323,
    column_3: 4.958369,
    column_2: "Cortil-Wodon",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.567323,
      lon: 4.958369,
    },
  },
  {
    column_4: 50.5210841,
    column_3: 4.9759589,
    column_2: "Franc-Waret",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5210841,
      lon: 4.9759589,
    },
  },
  {
    column_4: 50.2003101,
    column_3: 4.8971396,
    column_2: "Falmagne",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.2003101,
      lon: 4.8971396,
    },
  },
  {
    column_4: 50.2037856,
    column_3: 4.8924373,
    column_2: "Falmignoul",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.2037856,
      lon: 4.8924373,
    },
  },
  {
    column_4: 50.2798224,
    column_3: 4.9882689,
    column_2: "Thynes",
    column_1: "5502",
    geom: null,
    coordonnees: {
      lat: 50.2798224,
      lon: 4.9882689,
    },
  },
  {
    column_4: 50.274944,
    column_3: 4.8454743,
    column_2: "Sommière",
    column_1: "5523",
    geom: null,
    coordonnees: {
      lat: 50.274944,
      lon: 4.8454743,
    },
  },
  {
    column_4: 50.322619,
    column_3: 5.006949,
    column_2: "Spontin",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.322619,
      lon: 5.006949,
    },
  },
  {
    column_4: 50.3264425,
    column_3: 4.8806618,
    column_2: "Yvoir",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3264425,
      lon: 4.8806618,
    },
  },
  {
    column_4: 50.310778,
    column_3: 4.8796936,
    column_2: "Anhée",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.310778,
      lon: 4.8796936,
    },
  },
  {
    column_4: 50.343103,
    column_3: 4.841866,
    column_2: "Annevoie-Rouillon",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.343103,
      lon: 4.841866,
    },
  },
  {
    column_4: 50.3053165,
    column_3: 4.8417104,
    column_2: "Haut-Le-Wastia",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.3053165,
      lon: 4.8417104,
    },
  },
  {
    column_4: 50.2955787,
    column_3: 4.7815722,
    column_2: "Sosoye",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.2955787,
      lon: 4.7815722,
    },
  },
  {
    column_4: 50.216541,
    column_3: 4.8242423,
    column_2: "Hastière-Lavaux",
    column_1: "5540",
    geom: null,
    coordonnees: {
      lat: 50.216541,
      lon: 4.8242423,
    },
  },
  {
    column_4: 50.161698,
    column_3: 4.831747,
    column_2: "Heer",
    column_1: "5543",
    geom: null,
    coordonnees: {
      lat: 50.161698,
      lon: 4.831747,
    },
  },
  {
    column_4: 49.8612272,
    column_3: 4.9489546,
    column_2: "Chairière",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8612272,
      lon: 4.9489546,
    },
  },
  {
    column_4: 49.8655398,
    column_3: 4.9002694,
    column_2: "Membre",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8655398,
      lon: 4.9002694,
    },
  },
  {
    column_4: 49.8534637,
    column_3: 4.9620137,
    column_2: "Mouzaive",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8534637,
      lon: 4.9620137,
    },
  },
  {
    column_4: 49.8936753,
    column_3: 4.9274653,
    column_2: "Orchimont",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8936753,
      lon: 4.9274653,
    },
  },
  {
    column_4: 49.8994469,
    column_3: 5.0267634,
    column_2: "Baillamont",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.8994469,
      lon: 5.0267634,
    },
  },
  {
    column_4: 49.9177,
    column_3: 4.973101,
    column_2: "Bellefontaine",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9177,
      lon: 4.973101,
    },
  },
  {
    column_4: 49.8626123,
    column_3: 4.9979774,
    column_2: "Cornimont",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.8626123,
      lon: 4.9979774,
    },
  },
  {
    column_4: 49.9514336,
    column_3: 5.0674675,
    column_2: "Graide",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9514336,
      lon: 5.0674675,
    },
  },
  {
    column_4: 50.1178162,
    column_3: 4.8883798,
    column_2: "Dion",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.1178162,
      lon: 4.8883798,
    },
  },
  {
    column_4: 50.0680742,
    column_3: 4.8490158,
    column_2: "Felenne",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.0680742,
      lon: 4.8490158,
    },
  },
  {
    column_4: 50.1018962,
    column_3: 4.9187569,
    column_2: "Javingue",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.1018962,
      lon: 4.9187569,
    },
  },
  {
    column_4: 50.0608665,
    column_3: 4.9800374,
    column_2: "Vonêche",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.0608665,
      lon: 4.9800374,
    },
  },
  {
    column_4: 50.1314538,
    column_3: 5.0408552,
    column_2: "Focant",
    column_1: "5572",
    geom: null,
    coordonnees: {
      lat: 50.1314538,
      lon: 5.0408552,
    },
  },
  {
    column_4: 49.9999798,
    column_3: 4.9521201,
    column_2: "Patignies",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.9999798,
      lon: 4.9521201,
    },
  },
  {
    column_4: 50.0014538,
    column_3: 4.9175574,
    column_2: "Sart-Custinne",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 50.0014538,
      lon: 4.9175574,
    },
  },
  {
    column_4: 49.9874142,
    column_3: 4.8475671,
    column_2: "Willerzie",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.9874142,
      lon: 4.8475671,
    },
  },
  {
    column_4: 50.2092771,
    column_3: 5.120824,
    column_2: "Mont-Gauthier",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.2092771,
      lon: 5.120824,
    },
  },
  {
    column_4: 50.1725817,
    column_3: 5.2140311511356,
    column_2: "Rochefort",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.1725817,
      lon: 5.21403115114,
    },
  },
  {
    column_4: 50.1457902,
    column_3: 4.5846227,
    column_2: "Villers-En-Fagne",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1457902,
      lon: 4.5846227,
    },
  },
  {
    column_4: 50.1896906,
    column_3: 4.4366472,
    column_2: "Soumoy",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.1896906,
      lon: 4.4366472,
    },
  },
  {
    column_4: 50.3346461,
    column_3: 4.6073303,
    column_2: "Biesme",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.3346461,
      lon: 4.6073303,
    },
  },
  {
    column_4: 50.3212094,
    column_3: 4.6584949,
    column_2: "Mettet",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.3212094,
      lon: 4.6584949,
    },
  },
  {
    column_4: 50.2649096,
    column_3: 4.4592009,
    column_2: "Chastrès",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2649096,
      lon: 4.4592009,
    },
  },
  {
    column_4: 50.2691529,
    column_3: 4.3892146,
    column_2: "Rognée",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2691529,
      lon: 4.3892146,
    },
  },
  {
    column_4: 50.0009202,
    column_3: 4.4602842,
    column_2: "Brûly-De-Pesche",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0009202,
      lon: 4.4602842,
    },
  },
  {
    column_4: 50.0247898,
    column_3: 4.4206168,
    column_2: "Presgaux",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0247898,
      lon: 4.4206168,
    },
  },
  {
    column_4: 50.091356,
    column_3: 4.59145,
    column_2: "Dourbes",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.091356,
      lon: 4.59145,
    },
  },
  {
    column_4: 50.0745275,
    column_3: 4.5486173,
    column_2: "Nismes",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.0745275,
      lon: 4.5486173,
    },
  },
  {
    column_4: 50.41571025,
    column_3: 4.4491649999234,
    column_2: "Charleroi",
    column_1: "6000",
    geom: null,
    coordonnees: {
      lat: 50.41571025,
      lon: 4.44916499992,
    },
  },
  {
    column_4: 50.4170168,
    column_3: 4.382402,
    column_2: "Monceau-Sur-Sambre",
    column_1: "6031",
    geom: null,
    coordonnees: {
      lat: 50.4170168,
      lon: 4.382402,
    },
  },
  {
    column_4: 50.4653846,
    column_3: 4.4301757,
    column_2: "Gosselies",
    column_1: "6041",
    geom: null,
    coordonnees: {
      lat: 50.4653846,
      lon: 4.4301757,
    },
  },
  {
    column_4: 50.431853,
    column_3: 4.4489421,
    column_2: "Lodelinsart",
    column_1: "6042",
    geom: null,
    coordonnees: {
      lat: 50.431853,
      lon: 4.4489421,
    },
  },
  {
    column_4: 50.3268072,
    column_3: 4.3713936,
    column_2: "Marbaix",
    column_1: "6120",
    geom: null,
    coordonnees: {
      lat: 50.3268072,
      lon: 4.3713936,
    },
  },
  {
    column_4: 50.4875628,
    column_3: 4.3287132,
    column_2: "Gouy-Lez-Piéton",
    column_1: "6181",
    geom: null,
    coordonnees: {
      lat: 50.4875628,
      lon: 4.3287132,
    },
  },
  {
    column_4: 50.5043242,
    column_3: 4.3622972938902,
    column_2: "Pont-À-Celles",
    column_1: "6230",
    geom: null,
    coordonnees: {
      lat: 50.5043242,
      lon: 4.36229729389,
    },
  },
  {
    column_4: 50.5152429,
    column_3: 4.4243830944654,
    column_2: "Liberchies",
    column_1: "6238",
    geom: null,
    coordonnees: {
      lat: 50.5152429,
      lon: 4.42438309447,
    },
  },
  {
    column_4: 50.51456265,
    column_3: 4.3888817117527,
    column_2: "Luttre",
    column_1: "6238",
    geom: null,
    coordonnees: {
      lat: 50.51456265,
      lon: 4.38888171175,
    },
  },
  {
    column_4: 50.4173702,
    column_3: 4.5459892,
    column_2: "Pont-De-Loup",
    column_1: "6250",
    geom: null,
    coordonnees: {
      lat: 50.4173702,
      lon: 4.5459892,
    },
  },
  {
    column_4: 50.3850088,
    column_3: 4.5786617,
    column_2: "Presles",
    column_1: "6250",
    geom: null,
    coordonnees: {
      lat: 50.3850088,
      lon: 4.5786617,
    },
  },
  {
    column_4: 50.3513008,
    column_3: 4.547498,
    column_2: "Villers-Poterie",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.3513008,
      lon: 4.547498,
    },
  },
  {
    column_4: 50.198614,
    column_3: 4.3059105,
    column_2: "Vergnies",
    column_1: "6440",
    geom: null,
    coordonnees: {
      lat: 50.198614,
      lon: 4.3059105,
    },
  },
  {
    column_4: 50.0479266,
    column_3: 4.3173338,
    column_2: "Chimay",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.0479266,
      lon: 4.3173338,
    },
  },
  {
    column_4: 50.050354,
    column_3: 4.309222,
    column_2: "Saint-Remy",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.050354,
      lon: 4.309222,
    },
  },
  {
    column_4: 49.960822,
    column_3: 4.368311,
    column_2: "Rièzes",
    column_1: "6464",
    geom: null,
    coordonnees: {
      lat: 49.960822,
      lon: 4.368311,
    },
  },
  {
    column_4: 50.1678978,
    column_3: 4.1792797,
    column_2: "Sivry",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.1678978,
      lon: 4.1792797,
    },
  },
  {
    column_4: 50.1640023,
    column_3: 4.1899982,
    column_2: "Sivry-Rance",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.1640023,
      lon: 4.1899982,
    },
  },
  {
    column_4: 50.2355294,
    column_3: 4.2383115,
    column_2: "Beaumont",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.2355294,
      lon: 4.2383115,
    },
  },
  {
    column_4: 50.2349363,
    column_3: 4.2289677,
    column_2: "Leval-Chaudeville",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.2349363,
      lon: 4.2289677,
    },
  },
  {
    column_4: 50.216245,
    column_3: 4.246304,
    column_2: "Solre-Saint-Géry",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.216245,
      lon: 4.246304,
    },
  },
  {
    column_4: 50.3397445,
    column_3: 4.2870475,
    column_2: "Thuin",
    column_1: "6530",
    geom: null,
    coordonnees: {
      lat: 50.3397445,
      lon: 4.2870475,
    },
  },
  {
    column_4: 50.320724,
    column_3: 4.3079897,
    column_2: "Biesme-Sous-Thuin",
    column_1: "6531",
    geom: null,
    coordonnees: {
      lat: 50.320724,
      lon: 4.3079897,
    },
  },
  {
    column_4: 50.3250862,
    column_3: 4.2591396,
    column_2: "Biercée",
    column_1: "6533",
    geom: null,
    coordonnees: {
      lat: 50.3250862,
      lon: 4.2591396,
    },
  },
  {
    column_4: 50.3352549,
    column_3: 4.209803,
    column_2: "Sars-La-Buissière",
    column_1: "6542",
    geom: null,
    coordonnees: {
      lat: 50.3352549,
      lon: 4.209803,
    },
  },
  {
    column_4: 50.0286317,
    column_3: 4.1649981,
    column_2: "Momignies",
    column_1: "6590",
    geom: null,
    coordonnees: {
      lat: 50.0286317,
      lon: 4.1649981,
    },
  },
  {
    column_4: 49.9902268,
    column_3: 5.7857948,
    column_2: "Wardin",
    column_1: "6600",
    geom: null,
    coordonnees: {
      lat: 49.9902268,
      lon: 5.7857948,
    },
  },
  {
    column_4: 49.8313323,
    column_3: 5.7376366,
    column_2: "Martelange",
    column_1: "6630",
    geom: null,
    coordonnees: {
      lat: 49.8313323,
      lon: 5.7376366,
    },
  },
  {
    column_4: 49.9064531,
    column_3: 5.6881541,
    column_2: "Hollange",
    column_1: "6637",
    geom: null,
    coordonnees: {
      lat: 49.9064531,
      lon: 5.6881541,
    },
  },
  {
    column_4: 49.908755829225,
    column_3: 5.5908285596978,
    column_2: "Vaux-Sur-Sûre",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.9087558292,
      lon: 5.5908285597,
    },
  },
  {
    column_4: 50.052317,
    column_3: 5.5136455,
    column_2: "Lavacherie",
    column_1: "6681",
    geom: null,
    coordonnees: {
      lat: 50.052317,
      lon: 5.5136455,
    },
  },
  {
    column_4: 49.6512359,
    column_3: 5.7795299,
    column_2: "Toernich",
    column_1: "6700",
    geom: null,
    coordonnees: {
      lat: 49.6512359,
      lon: 5.7795299,
    },
  },
  {
    column_4: 49.7514982,
    column_3: 5.7872217,
    column_2: "Attert",
    column_1: "6717",
    geom: null,
    coordonnees: {
      lat: 49.7514982,
      lon: 5.7872217,
    },
  },
  {
    column_4: 49.7717529,
    column_3: 5.7861375,
    column_2: "Nothomb",
    column_1: "6717",
    geom: null,
    coordonnees: {
      lat: 49.7717529,
      lon: 5.7861375,
    },
  },
  {
    column_4: 49.7159959,
    column_3: 5.7291343,
    column_2: "Thiaumont",
    column_1: "6717",
    geom: null,
    coordonnees: {
      lat: 49.7159959,
      lon: 5.7291343,
    },
  },
  {
    column_4: 49.7189016,
    column_3: 5.5843337,
    column_2: "Houdemont",
    column_1: "6724",
    geom: null,
    coordonnees: {
      lat: 49.7189016,
      lon: 5.5843337,
    },
  },
  {
    column_4: 49.6987456,
    column_3: 5.5614663,
    column_2: "Villers-Sur-Semois",
    column_1: "6740",
    geom: null,
    coordonnees: {
      lat: 49.6987456,
      lon: 5.5614663,
    },
  },
  {
    column_4: 49.5580794,
    column_3: 5.705881,
    column_2: "Musson",
    column_1: "6750",
    geom: null,
    coordonnees: {
      lat: 49.5580794,
      lon: 5.705881,
    },
  },
  {
    column_4: 49.5680419,
    column_3: 5.6272894,
    column_2: "Bleid",
    column_1: "6760",
    geom: null,
    coordonnees: {
      lat: 49.5680419,
      lon: 5.6272894,
    },
  },
  {
    column_4: 49.5577562,
    column_3: 5.5284511,
    column_2: "Saint-Mard",
    column_1: "6762",
    geom: null,
    coordonnees: {
      lat: 49.5577562,
      lon: 5.5284511,
    },
  },
  {
    column_4: 49.6184295,
    column_3: 5.4287902,
    column_2: "Gérouville",
    column_1: "6769",
    geom: null,
    coordonnees: {
      lat: 49.6184295,
      lon: 5.4287902,
    },
  },
  {
    column_4: 49.595344,
    column_3: 5.5071291,
    column_2: "Robelmont",
    column_1: "6769",
    geom: null,
    coordonnees: {
      lat: 49.595344,
      lon: 5.5071291,
    },
  },
  {
    column_4: 49.9760182,
    column_3: 5.3877654,
    column_2: "Bras",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9760182,
      lon: 5.3877654,
    },
  },
  {
    column_4: 49.9197154,
    column_3: 5.3823537,
    column_2: "Libramont-Chevigny",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9197154,
      lon: 5.3823537,
    },
  },
  {
    column_4: 49.9894846,
    column_3: 5.468077,
    column_2: "Moircy",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9894846,
      lon: 5.468077,
    },
  },
  {
    column_4: 49.9112853,
    column_3: 5.3607943,
    column_2: "Recogne",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9112853,
      lon: 5.3607943,
    },
  },
  {
    column_4: 49.9235741,
    column_3: 5.4578106,
    column_2: "Sainte-Marie-Chevigny",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9235741,
      lon: 5.4578106,
    },
  },
  {
    column_4: 49.693986,
    column_3: 5.3747807,
    column_2: "Izel",
    column_1: "6810",
    geom: null,
    coordonnees: {
      lat: 49.693986,
      lon: 5.3747807,
    },
  },
  {
    column_4: 49.717679,
    column_3: 5.1759524,
    column_2: "Muno",
    column_1: "6820",
    geom: null,
    coordonnees: {
      lat: 49.717679,
      lon: 5.1759524,
    },
  },
  {
    column_4: 49.7949836,
    column_3: 5.0672528,
    column_2: "Bouillon",
    column_1: "6830",
    geom: null,
    coordonnees: {
      lat: 49.7949836,
      lon: 5.0672528,
    },
  },
  {
    column_4: 49.8133379,
    column_3: 5.1456628,
    column_2: "Les Hayons",
    column_1: "6830",
    geom: null,
    coordonnees: {
      lat: 49.8133379,
      lon: 5.1456628,
    },
  },
  {
    column_4: 49.8675973,
    column_3: 5.0388784,
    column_2: "Vivy",
    column_1: "6833",
    geom: null,
    coordonnees: {
      lat: 49.8675973,
      lon: 5.0388784,
    },
  },
  {
    column_4: 49.8359615,
    column_3: 5.4564462,
    column_2: "Hamipré",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8359615,
      lon: 5.4564462,
    },
  },
  {
    column_4: 49.8562222,
    column_3: 5.4609005,
    column_2: "Longlier",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8562222,
      lon: 5.4609005,
    },
  },
  {
    column_4: 49.9035269,
    column_3: 5.1349041,
    column_2: "Paliseul",
    column_1: "6850",
    geom: null,
    coordonnees: {
      lat: 49.9035269,
      lon: 5.1349041,
    },
  },
  {
    column_4: 49.9651106,
    column_3: 5.1805775,
    column_2: "Maissin",
    column_1: "6852",
    geom: null,
    coordonnees: {
      lat: 49.9651106,
      lon: 5.1805775,
    },
  },
  {
    column_4: 49.9185629,
    column_3: 5.1601932,
    column_2: "Framont",
    column_1: "6853",
    geom: null,
    coordonnees: {
      lat: 49.9185629,
      lon: 5.1601932,
    },
  },
  {
    column_4: 49.8003582,
    column_3: 5.5381496,
    column_2: "Léglise",
    column_1: "6860",
    geom: null,
    coordonnees: {
      lat: 49.8003582,
      lon: 5.5381496,
    },
  },
  {
    column_4: 49.8157173,
    column_3: 5.1823728,
    column_2: "Auby-Sur-Semois",
    column_1: "6880",
    geom: null,
    coordonnees: {
      lat: 49.8157173,
      lon: 5.1823728,
    },
  },
  {
    column_4: 49.8343079,
    column_3: 5.3022804,
    column_2: "Orgeo",
    column_1: "6880",
    geom: null,
    coordonnees: {
      lat: 49.8343079,
      lon: 5.3022804,
    },
  },
  {
    column_4: 50.0077873,
    column_3: 5.1601828,
    column_2: "Redu",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 50.0077873,
      lon: 5.1601828,
    },
  },
  {
    column_4: 50.0188027,
    column_3: 5.2662483,
    column_2: "Smuid",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 50.0188027,
      lon: 5.2662483,
    },
  },
  {
    column_4: 50.2053202,
    column_3: 5.2552435,
    column_2: "Humain",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.2053202,
      lon: 5.2552435,
    },
  },
  {
    column_4: 50.1715967,
    column_3: 5.2862777,
    column_2: "On",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.1715967,
      lon: 5.2862777,
    },
  },
  {
    column_4: 50.0762474,
    column_3: 5.092143,
    column_2: "Lomprez",
    column_1: "6924",
    geom: null,
    coordonnees: {
      lat: 50.0762474,
      lon: 5.092143,
    },
  },
  {
    column_4: 50.0828516,
    column_3: 5.1778089,
    column_2: "Resteigne",
    column_1: "6927",
    geom: null,
    coordonnees: {
      lat: 50.0828516,
      lon: 5.1778089,
    },
  },
  {
    column_4: 50.081019,
    column_3: 5.216837,
    column_2: "Tellin",
    column_1: "6927",
    geom: null,
    coordonnees: {
      lat: 50.081019,
      lon: 5.216837,
    },
  },
  {
    column_4: 50.3266395,
    column_3: 5.5307553,
    column_2: "Wéris",
    column_1: "6940",
    geom: null,
    coordonnees: {
      lat: 50.3266395,
      lon: 5.5307553,
    },
  },
  {
    column_4: 50.4176261,
    column_3: 5.4142387,
    column_2: "Bende",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.4176261,
      lon: 5.4142387,
    },
  },
  {
    column_4: 50.3810361,
    column_3: 5.481405,
    column_2: "Tohogne",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.3810361,
      lon: 5.481405,
    },
  },
  {
    column_4: 50.173788,
    column_3: 5.3475606,
    column_2: "Harsin",
    column_1: "6950",
    geom: null,
    coordonnees: {
      lat: 50.173788,
      lon: 5.3475606,
    },
  },
  {
    column_4: 50.1294031,
    column_3: 5.3455462,
    column_2: "Nassogne",
    column_1: "6950",
    geom: null,
    coordonnees: {
      lat: 50.1294031,
      lon: 5.3455462,
    },
  },
  {
    column_4: 50.1549918,
    column_3: 5.3828952,
    column_2: "Grune",
    column_1: "6952",
    geom: null,
    coordonnees: {
      lat: 50.1549918,
      lon: 5.3828952,
    },
  },
  {
    column_4: 50.091248,
    column_3: 5.5242133,
    column_2: "Tenneville",
    column_1: "6970",
    geom: null,
    coordonnees: {
      lat: 50.091248,
      lon: 5.5242133,
    },
  },
  {
    column_4: 50.2145396,
    column_3: 5.5268173,
    column_2: "Marcourt",
    column_1: "6987",
    geom: null,
    coordonnees: {
      lat: 50.2145396,
      lon: 5.5268173,
    },
  },
  {
    column_4: 50.2655197,
    column_3: 5.5571509,
    column_2: "Amonines",
    column_1: "6997",
    geom: null,
    coordonnees: {
      lat: 50.2655197,
      lon: 5.5571509,
    },
  },
  {
    column_4: 50.285577,
    column_3: 5.5100929,
    column_2: "Soy",
    column_1: "6997",
    geom: null,
    coordonnees: {
      lat: 50.285577,
      lon: 5.5100929,
    },
  },
  {
    column_4: 50.4392359,
    column_3: 3.9619447,
    column_2: "Hyon",
    column_1: "7022",
    geom: null,
    coordonnees: {
      lat: 50.4392359,
      lon: 3.9619447,
    },
  },
  {
    column_4: 50.4920472,
    column_3: 4.0181787,
    column_2: "Saint-Denis",
    column_1: "7034",
    geom: null,
    coordonnees: {
      lat: 50.4920472,
      lon: 4.0181787,
    },
  },
  {
    column_4: 50.4020009,
    column_3: 3.9570563,
    column_2: "Asquillies",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.4020009,
      lon: 3.9570563,
    },
  },
  {
    column_4: 50.386751,
    column_3: 3.9391701,
    column_2: "Bougnies",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.386751,
      lon: 3.9391701,
    },
  },
  {
    column_4: 50.3611541,
    column_3: 3.9841693,
    column_2: "Havay",
    column_1: "7041",
    geom: null,
    coordonnees: {
      lat: 50.3611541,
      lon: 3.9841693,
    },
  },
  {
    column_4: 50.5325067,
    column_3: 3.9113179,
    column_2: "Jurbise",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.5325067,
      lon: 3.9113179,
    },
  },
  {
    column_4: 50.5683075,
    column_3: 4.0029098,
    column_2: "Neufvilles",
    column_1: "7063",
    geom: null,
    coordonnees: {
      lat: 50.5683075,
      lon: 4.0029098,
    },
  },
  {
    column_4: 50.473352,
    column_3: 4.0922224,
    column_2: "Thieu",
    column_1: "7070",
    geom: null,
    coordonnees: {
      lat: 50.473352,
      lon: 4.0922224,
    },
  },
  {
    column_4: 50.4767906,
    column_3: 4.0655313,
    column_2: "Ville-Sur-Haine",
    column_1: "7070",
    geom: null,
    coordonnees: {
      lat: 50.4767906,
      lon: 4.0655313,
    },
  },
  {
    column_4: 50.6084328,
    column_3: 4.2235457,
    column_2: "Ronquières",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.6084328,
      lon: 4.2235457,
    },
  },
  {
    column_4: 50.4547557,
    column_3: 4.2000181,
    column_2: "Haine-Saint-Pierre",
    column_1: "7100",
    geom: null,
    coordonnees: {
      lat: 50.4547557,
      lon: 4.2000181,
    },
  },
  {
    column_4: 50.479487,
    column_3: 4.1852682,
    column_2: "La Louvière",
    column_1: "7100",
    geom: null,
    coordonnees: {
      lat: 50.479487,
      lon: 4.1852682,
    },
  },
  {
    column_4: 50.4517378,
    column_3: 4.1475857,
    column_2: "Trivières",
    column_1: "7100",
    geom: null,
    coordonnees: {
      lat: 50.4517378,
      lon: 4.1475857,
    },
  },
  {
    column_4: 50.4879863,
    column_3: 4.1575303,
    column_2: "Houdeng-Goegnies",
    column_1: "7110",
    geom: null,
    coordonnees: {
      lat: 50.4879863,
      lon: 4.1575303,
    },
  },
  {
    column_4: 50.3609408,
    column_3: 4.0758701,
    column_2: "Croix-Lez-Rouveroy",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3609408,
      lon: 4.0758701,
    },
  },
  {
    column_4: 50.3703355,
    column_3: 4.1095709,
    column_2: "Fauroeulx",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3703355,
      lon: 4.1095709,
    },
  },
  {
    column_4: 50.412824846085,
    column_3: 4.1701677159951,
    column_2: "Binche",
    column_1: "7130",
    geom: null,
    coordonnees: {
      lat: 50.4128248461,
      lon: 4.170167716,
    },
  },
  {
    column_4: 50.4364165,
    column_3: 4.1458468,
    column_2: "Péronnes-Lez-Binche",
    column_1: "7134",
    geom: null,
    coordonnees: {
      lat: 50.4364165,
      lon: 4.1458468,
    },
  },
  {
    column_4: 50.4238109,
    column_3: 4.1910482,
    column_2: "Ressaix",
    column_1: "7134",
    geom: null,
    coordonnees: {
      lat: 50.4238109,
      lon: 4.1910482,
    },
  },
  {
    column_4: 50.4414944,
    column_3: 4.2984261,
    column_2: "Piéton",
    column_1: "7160",
    geom: null,
    coordonnees: {
      lat: 50.4414944,
      lon: 4.2984261,
    },
  },
  {
    column_4: 50.5023427,
    column_3: 4.2156931,
    column_2: "Bois-D'haine",
    column_1: "7170",
    geom: null,
    coordonnees: {
      lat: 50.5023427,
      lon: 4.2156931,
    },
  },
  {
    column_4: 50.4898236,
    column_3: 4.2282511,
    column_2: "Fayt-Lez-Manage",
    column_1: "7170",
    geom: null,
    coordonnees: {
      lat: 50.4898236,
      lon: 4.2282511,
    },
  },
  {
    column_4: 50.5036133,
    column_3: 4.2343288,
    column_2: "Manage",
    column_1: "7170",
    geom: null,
    coordonnees: {
      lat: 50.5036133,
      lon: 4.2343288,
    },
  },
  {
    column_4: 50.5640405,
    column_3: 4.287514819651,
    column_2: "Arquennes",
    column_1: "7181",
    geom: null,
    coordonnees: {
      lat: 50.5640405,
      lon: 4.28751481965,
    },
  },
  {
    column_4: 50.5625639,
    column_3: 4.2430926037513,
    column_2: "Feluy",
    column_1: "7181",
    geom: null,
    coordonnees: {
      lat: 50.5625639,
      lon: 4.24309260375,
    },
  },
  {
    column_4: 50.55876715,
    column_3: 4.3186986634815,
    column_2: "Petit-Roeulx-Lez-Nivelles",
    column_1: "7181",
    geom: null,
    coordonnees: {
      lat: 50.55876715,
      lon: 4.31869866348,
    },
  },
  {
    column_4: 50.5696129,
    column_3: 4.1752492,
    column_2: "Ecaussinnes",
    column_1: "7190",
    geom: null,
    coordonnees: {
      lat: 50.5696129,
      lon: 4.1752492,
    },
  },
  {
    column_4: 50.5703563,
    column_3: 4.1785518,
    column_2: "Ecaussinnes-Lalaing",
    column_1: "7191",
    geom: null,
    coordonnees: {
      lat: 50.5703563,
      lon: 4.1785518,
    },
  },
  {
    column_4: 50.5060592,
    column_3: 3.7881267,
    column_2: "Sirault",
    column_1: "7332",
    geom: null,
    coordonnees: {
      lat: 50.5060592,
      lon: 3.7881267,
    },
  },
  {
    column_4: 50.414181,
    column_3: 3.846637,
    column_2: "Wasmes",
    column_1: "7340",
    geom: null,
    coordonnees: {
      lat: 50.414181,
      lon: 3.846637,
    },
  },
  {
    column_4: 50.4292832,
    column_3: 3.7393229,
    column_2: "Thulin",
    column_1: "7350",
    geom: null,
    coordonnees: {
      lat: 50.4292832,
      lon: 3.7393229,
    },
  },
  {
    column_4: 50.3978815,
    column_3: 3.7806897,
    column_2: "Dour",
    column_1: "7370",
    geom: null,
    coordonnees: {
      lat: 50.3978815,
      lon: 3.7806897,
    },
  },
  {
    column_4: 50.4084818,
    column_3: 3.6819263,
    column_2: "Quiévrain",
    column_1: "7380",
    geom: null,
    coordonnees: {
      lat: 50.4084818,
      lon: 3.6819263,
    },
  },
  {
    column_4: 50.3590043,
    column_3: 3.7933404,
    column_2: "Erquennes",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3590043,
      lon: 3.7933404,
    },
  },
  {
    column_4: 50.352089,
    column_3: 3.731019,
    column_2: "Honnelles",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.352089,
      lon: 3.731019,
    },
  },
  {
    column_4: 50.363513,
    column_3: 3.71633,
    column_2: "Onnezies",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.363513,
      lon: 3.71633,
    },
  },
  {
    column_4: 50.445727,
    column_3: 3.8466719,
    column_2: "Wasmuel",
    column_1: "7390",
    geom: null,
    coordonnees: {
      lat: 50.445727,
      lon: 3.8466719,
    },
  },
  {
    column_4: 50.5743852,
    column_3: 3.3038112,
    column_2: "Esplechin",
    column_1: "7502",
    geom: null,
    coordonnees: {
      lat: 50.5743852,
      lon: 3.3038112,
    },
  },
  {
    column_4: 50.573981,
    column_3: 3.350025,
    column_2: "Willemeau",
    column_1: "7506",
    geom: null,
    coordonnees: {
      lat: 50.573981,
      lon: 3.350025,
    },
  },
  {
    column_4: 50.651295,
    column_3: 3.335337,
    column_2: "Ramegnies-Chin",
    column_1: "7520",
    geom: null,
    coordonnees: {
      lat: 50.651295,
      lon: 3.335337,
    },
  },
  {
    column_4: 50.5982618,
    column_3: 3.2931148,
    column_2: "Lamain",
    column_1: "7522",
    geom: null,
    coordonnees: {
      lat: 50.5982618,
      lon: 3.2931148,
    },
  },
  {
    column_4: 50.6078843,
    column_3: 3.3226398,
    column_2: "Marquain",
    column_1: "7522",
    geom: null,
    coordonnees: {
      lat: 50.6078843,
      lon: 3.3226398,
    },
  },
  {
    column_4: 50.5875423,
    column_3: 3.5430623,
    column_2: "Barry",
    column_1: "7534",
    geom: null,
    coordonnees: {
      lat: 50.5875423,
      lon: 3.5430623,
    },
  },
  {
    column_4: 50.5417372,
    column_3: 3.5472808,
    column_2: "Brasmenil",
    column_1: "7604",
    geom: null,
    coordonnees: {
      lat: 50.5417372,
      lon: 3.5472808,
    },
  },
  {
    column_4: 50.551862,
    column_3: 3.4538057,
    column_2: "Péronnes-Lez-Antoing",
    column_1: "7640",
    geom: null,
    coordonnees: {
      lat: 50.551862,
      lon: 3.4538057,
    },
  },
  {
    column_4: 50.578325,
    column_3: 3.4371661,
    column_2: "Calonne",
    column_1: "7642",
    geom: null,
    coordonnees: {
      lat: 50.578325,
      lon: 3.4371661,
    },
  },
  {
    column_4: 50.728033,
    column_3: 3.520558,
    column_2: "Anseroeul",
    column_1: "7750",
    geom: null,
    coordonnees: {
      lat: 50.728033,
      lon: 3.520558,
    },
  },
  {
    column_4: 50.7456511,
    column_3: 3.5110542,
    column_2: "Mont-De-L'enclus",
    column_1: "7750",
    geom: null,
    coordonnees: {
      lat: 50.7456511,
      lon: 3.5110542,
    },
  },
  {
    column_4: 50.7447633,
    column_3: 3.5309528,
    column_2: "Russeignies",
    column_1: "7750",
    geom: null,
    coordonnees: {
      lat: 50.7447633,
      lon: 3.5309528,
    },
  },
  {
    column_4: 50.6930043,
    column_3: 3.4294567,
    column_2: "Molenbaix",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.6930043,
      lon: 3.4294567,
    },
  },
  {
    column_4: 50.6685604,
    column_3: 3.4865934,
    column_2: "Velaines",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.6685604,
      lon: 3.4865934,
    },
  },
  {
    column_4: 50.7868243,
    column_3: 2.9640553,
    column_2: "Houthem",
    column_1: "7781",
    geom: null,
    coordonnees: {
      lat: 50.7868243,
      lon: 2.9640553,
    },
  },
  {
    column_4: 50.7262204,
    column_3: 2.8803139,
    column_2: "Ploegsteert",
    column_1: "7782",
    geom: null,
    coordonnees: {
      lat: 50.7262204,
      lon: 2.8803139,
    },
  },
  {
    column_4: 50.7060943,
    column_3: 2.88999,
    column_2: "Bizet",
    column_1: "7783",
    geom: null,
    coordonnees: {
      lat: 50.7060943,
      lon: 2.88999,
    },
  },
  {
    column_4: 50.639504499385,
    column_3: 3.7926793502157,
    column_2: "Ath",
    column_1: "7800",
    geom: null,
    coordonnees: {
      lat: 50.6395044994,
      lon: 3.79267935022,
    },
  },
  {
    column_4: 50.6103517,
    column_3: 3.8148309,
    column_2: "Arbre",
    column_1: "7811",
    geom: null,
    coordonnees: {
      lat: 50.6103517,
      lon: 3.8148309,
    },
  },
  {
    column_4: 50.637673,
    column_3: 3.8878195,
    column_2: "Gibecq",
    column_1: "7823",
    geom: null,
    coordonnees: {
      lat: 50.637673,
      lon: 3.8878195,
    },
  },
  {
    column_4: 50.6724203,
    column_3: 3.9360497,
    column_2: "Bassilly",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6724203,
      lon: 3.9360497,
    },
  },
  {
    column_4: 50.6182841,
    column_3: 3.9282801,
    column_2: "Fouleng",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6182841,
      lon: 3.9282801,
    },
  },
  {
    column_4: 50.627067,
    column_3: 3.9118667,
    column_2: "Gondregnies",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.627067,
      lon: 3.9118667,
    },
  },
  {
    column_4: 50.6903571,
    column_3: 4.0173479,
    column_2: "Marcq",
    column_1: "7850",
    geom: null,
    coordonnees: {
      lat: 50.6903571,
      lon: 4.0173479,
    },
  },
  {
    column_4: 50.6940794,
    column_3: 3.7998354,
    column_2: "Wannebecq",
    column_1: "7861",
    geom: null,
    coordonnees: {
      lat: 50.6940794,
      lon: 3.7998354,
    },
  },
  {
    column_4: 50.7208405,
    column_3: 3.780225,
    column_2: "Ogy",
    column_1: "7862",
    geom: null,
    coordonnees: {
      lat: 50.7208405,
      lon: 3.780225,
    },
  },
  {
    column_4: 50.69558,
    column_3: 3.7246735,
    column_2: "Lahamaide",
    column_1: "7890",
    geom: null,
    coordonnees: {
      lat: 50.69558,
      lon: 3.7246735,
    },
  },
  {
    column_4: 50.6349335,
    column_3: 3.5785557,
    column_2: "Herquegies",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.6349335,
      lon: 3.5785557,
    },
  },
  {
    column_4: 50.587693,
    column_3: 3.810128,
    column_2: "Chièvres",
    column_1: "7950",
    geom: null,
    coordonnees: {
      lat: 50.587693,
      lon: 3.810128,
    },
  },
  {
    column_4: 50.544393,
    column_3: 3.6351295,
    column_2: "Ramegnies",
    column_1: "7971",
    geom: null,
    coordonnees: {
      lat: 50.544393,
      lon: 3.6351295,
    },
  },
  {
    column_4: 50.5404186,
    column_3: 3.6304664,
    column_2: "Thumaide",
    column_1: "7971",
    geom: null,
    coordonnees: {
      lat: 50.5404186,
      lon: 3.6304664,
    },
  },
  {
    column_4: 51.10996785,
    column_3: 3.2612349549978,
    column_2: "Hertsberge",
    column_1: "8020",
    geom: null,
    coordonnees: {
      lat: 51.10996785,
      lon: 3.261234955,
    },
  },
  {
    column_4: 51.2821424,
    column_3: 3.3523918963854,
    column_2: "Lapscheure",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.2821424,
      lon: 3.35239189639,
    },
  },
  {
    column_4: 51.2422333,
    column_3: 3.3558017705651,
    column_2: "Moerkerke",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.2422333,
      lon: 3.35580177057,
    },
  },
  {
    column_4: 51.2751719,
    column_3: 3.2884674049058,
    column_2: "Oostkerke",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.2751719,
      lon: 3.28846740491,
    },
  },
  {
    column_4: 51.210525,
    column_3: 3.3435315097294,
    column_2: "Sijsele",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.210525,
      lon: 3.34353150973,
    },
  },
  {
    column_4: 51.2685834,
    column_3: 3.1567406094326,
    column_2: "Zuienkerke",
    column_1: "8377",
    geom: null,
    coordonnees: {
      lat: 51.2685834,
      lon: 3.15674060943,
    },
  },
  {
    column_4: 51.2303177,
    column_3: 2.9203275,
    column_2: "Oostende",
    column_1: "8400",
    geom: null,
    coordonnees: {
      lat: 51.2303177,
      lon: 2.9203275,
    },
  },
  {
    column_4: 51.20214645,
    column_3: 2.9200835535007,
    column_2: "Stene",
    column_1: "8400",
    geom: null,
    coordonnees: {
      lat: 51.20214645,
      lon: 2.9200835535,
    },
  },
  {
    column_4: 51.18331695,
    column_3: 2.8063401225473,
    column_2: "Middelkerke",
    column_1: "8430",
    geom: null,
    coordonnees: {
      lat: 51.18331695,
      lon: 2.80634012255,
    },
  },
  {
    column_4: 51.1258003,
    column_3: 2.8183418,
    column_2: "Mannekensvere",
    column_1: "8433",
    geom: null,
    coordonnees: {
      lat: 51.1258003,
      lon: 2.8183418,
    },
  },
  {
    column_4: 51.19765475,
    column_3: 3.0384504834689,
    column_2: "Ettelgem",
    column_1: "8460",
    geom: null,
    coordonnees: {
      lat: 51.19765475,
      lon: 3.03845048347,
    },
  },
  {
    column_4: 51.12819605,
    column_3: 3.023122345129,
    column_2: "Eernegem",
    column_1: "8480",
    geom: null,
    coordonnees: {
      lat: 51.12819605,
      lon: 3.02312234513,
    },
  },
  {
    column_4: 50.7781502,
    column_3: 3.2800137,
    column_2: "Bellegem",
    column_1: "8510",
    geom: null,
    coordonnees: {
      lat: 50.7781502,
      lon: 3.2800137,
    },
  },
  {
    column_4: 50.739664,
    column_3: 3.3261001,
    column_2: "Kooigem",
    column_1: "8510",
    geom: null,
    coordonnees: {
      lat: 50.739664,
      lon: 3.3261001,
    },
  },
  {
    column_4: 50.80406995,
    column_3: 3.3419067426276,
    column_2: "Zwevegem",
    column_1: "8550",
    geom: null,
    coordonnees: {
      lat: 50.80406995,
      lon: 3.34190674263,
    },
  },
  {
    column_4: 50.84493445,
    column_3: 3.2026154878227,
    column_2: "Gullegem",
    column_1: "8560",
    geom: null,
    coordonnees: {
      lat: 50.84493445,
      lon: 3.20261548782,
    },
  },
  {
    column_4: 50.8297876,
    column_3: 3.4271837357678,
    column_2: "Ingooigem",
    column_1: "8570",
    geom: null,
    coordonnees: {
      lat: 50.8297876,
      lon: 3.42718373577,
    },
  },
  {
    column_4: 50.8121917,
    column_3: 3.4976090001837,
    column_2: "Kaster",
    column_1: "8572",
    geom: null,
    coordonnees: {
      lat: 50.8121917,
      lon: 3.49760900018,
    },
  },
  {
    column_4: 50.7754997,
    column_3: 3.4468985313677,
    column_2: "Avelgem",
    column_1: "8580",
    geom: null,
    coordonnees: {
      lat: 50.7754997,
      lon: 3.44689853137,
    },
  },
  {
    column_4: 50.75868715,
    column_3: 3.4257331809137,
    column_2: "Outrijve",
    column_1: "8582",
    geom: null,
    coordonnees: {
      lat: 50.75868715,
      lon: 3.42573318091,
    },
  },
  {
    column_4: 51.08623835,
    column_3: 2.8814585998043,
    column_2: "Keiem",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.08623835,
      lon: 2.8814585998,
    },
  },
  {
    column_4: 50.9974451,
    column_3: 2.8042814087195,
    column_2: "Nieuwkapelle",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 50.9974451,
      lon: 2.80428140872,
    },
  },
  {
    column_4: 51.04353425,
    column_3: 2.7997074366181,
    column_2: "Oostkerke",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.04353425,
      lon: 2.79970743662,
    },
  },
  {
    column_4: 51.06253345,
    column_3: 2.9207800886428,
    column_2: "Vladslo",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.06253345,
      lon: 2.92078008864,
    },
  },
  {
    column_4: 51.0290062,
    column_3: 2.9637026,
    column_2: "Werken",
    column_1: "8610",
    geom: null,
    coordonnees: {
      lat: 51.0290062,
      lon: 2.9637026,
    },
  },
  {
    column_4: 51.03030765,
    column_3: 2.6550439487441,
    column_2: "Wulveringem",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.03030765,
      lon: 2.65504394874,
    },
  },
  {
    column_4: 51.05092295,
    column_3: 2.7515311775286,
    column_2: "Zoutenaaie",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.05092295,
      lon: 2.75153117753,
    },
  },
  {
    column_4: 50.92313355,
    column_3: 2.7492224474635,
    column_2: "Oostvleteren",
    column_1: "8640",
    geom: null,
    coordonnees: {
      lat: 50.92313355,
      lon: 2.74922244746,
    },
  },
  {
    column_4: 50.9103823,
    column_3: 2.7205508961772,
    column_2: "Westvleteren",
    column_1: "8640",
    geom: null,
    coordonnees: {
      lat: 50.9103823,
      lon: 2.72055089618,
    },
  },
  {
    column_4: 50.9484727,
    column_3: 2.8217318560837,
    column_2: "Noordschote",
    column_1: "8647",
    geom: null,
    coordonnees: {
      lat: 50.9484727,
      lon: 2.82173185608,
    },
  },
  {
    column_4: 51.09437775,
    column_3: 2.5806696887141,
    column_2: "De Panne",
    column_1: "8660",
    geom: null,
    coordonnees: {
      lat: 51.09437775,
      lon: 2.58066968871,
    },
  },
  {
    column_4: 51.1091101,
    column_3: 2.6353426015022,
    column_2: "Koksijde",
    column_1: "8670",
    geom: null,
    coordonnees: {
      lat: 51.1091101,
      lon: 2.6353426015,
    },
  },
  {
    column_4: 51.0911765,
    column_3: 2.9682187344976,
    column_2: "Koekelare",
    column_1: "8680",
    geom: null,
    coordonnees: {
      lat: 51.0911765,
      lon: 2.9682187345,
    },
  },
  {
    column_4: 51.1174383,
    column_3: 2.9186353800579,
    column_2: "Zande",
    column_1: "8680",
    geom: null,
    coordonnees: {
      lat: 51.1174383,
      lon: 2.91863538006,
    },
  },
  {
    column_4: 50.9398411,
    column_3: 2.673321,
    column_2: "Stavele",
    column_1: "8691",
    geom: null,
    coordonnees: {
      lat: 50.9398411,
      lon: 2.673321,
    },
  },
  {
    column_4: 51.03700665,
    column_3: 3.3283107336881,
    column_2: "Schuiferskapelle",
    column_1: "8700",
    geom: null,
    coordonnees: {
      lat: 51.03700665,
      lon: 3.32831073369,
    },
  },
  {
    column_4: 50.9245338,
    column_3: 3.3862545030012,
    column_2: "Sint-Baafs-Vijve",
    column_1: "8710",
    geom: null,
    coordonnees: {
      lat: 50.9245338,
      lon: 3.386254503,
    },
  },
  {
    column_4: 50.95014795,
    column_3: 3.394380172379,
    column_2: "Markegem",
    column_1: "8720",
    geom: null,
    coordonnees: {
      lat: 50.95014795,
      lon: 3.39438017238,
    },
  },
  {
    column_4: 51.125095,
    column_3: 3.3646568393591,
    column_2: "Sint-Joris",
    column_1: "8730",
    geom: null,
    coordonnees: {
      lat: 51.125095,
      lon: 3.36465683936,
    },
  },
  {
    column_4: 51.0209559,
    column_3: 3.2540634837689,
    column_2: "Egem",
    column_1: "8740",
    geom: null,
    coordonnees: {
      lat: 51.0209559,
      lon: 3.25406348377,
    },
  },
  {
    column_4: 51.06808895,
    column_3: 3.2847683081115,
    column_2: "Wingene",
    column_1: "8750",
    geom: null,
    coordonnees: {
      lat: 51.06808895,
      lon: 3.28476830811,
    },
  },
  {
    column_4: 50.92196635,
    column_3: 3.2617189739329,
    column_2: "Ingelmunster",
    column_1: "8770",
    geom: null,
    coordonnees: {
      lat: 50.92196635,
      lon: 3.26171897393,
    },
  },
  {
    column_4: 50.93434535,
    column_3: 3.3468206372996,
    column_2: "Oostrozebeke",
    column_1: "8780",
    geom: null,
    coordonnees: {
      lat: 50.93434535,
      lon: 3.3468206373,
    },
  },
  {
    column_4: 50.9057236,
    column_3: 3.4036062,
    column_2: "Sint-Eloois-Vijve",
    column_1: "8793",
    geom: null,
    coordonnees: {
      lat: 50.9057236,
      lon: 3.4036062,
    },
  },
  {
    column_4: 50.91511475,
    column_3: 3.1223026883986,
    column_2: "Rumbeke",
    column_1: "8800",
    geom: null,
    coordonnees: {
      lat: 50.91511475,
      lon: 3.1223026884,
    },
  },
  {
    column_4: 50.98218015,
    column_3: 3.0687215849639,
    column_2: "Hooglede",
    column_1: "8830",
    geom: null,
    coordonnees: {
      lat: 50.98218015,
      lon: 3.06872158496,
    },
  },
  {
    column_4: 50.8346385,
    column_3: 2.9224029,
    column_2: "Zillebeke",
    column_1: "8902",
    geom: null,
    coordonnees: {
      lat: 50.8346385,
      lon: 2.9224029,
    },
  },
  {
    column_4: 50.84722495,
    column_3: 2.8146596555515,
    column_2: "Vlamertinge",
    column_1: "8908",
    geom: null,
    coordonnees: {
      lat: 50.84722495,
      lon: 2.81465965555,
    },
  },
  {
    column_4: 50.7969879,
    column_3: 3.1156565,
    column_2: "Menen",
    column_1: "8930",
    geom: null,
    coordonnees: {
      lat: 50.7969879,
      lon: 3.1156565,
    },
  },
  {
    column_4: 50.7952131,
    column_3: 2.8383675286243,
    column_2: "Kemmel",
    column_1: "8956",
    geom: null,
    coordonnees: {
      lat: 50.7952131,
      lon: 2.83836752862,
    },
  },
  {
    column_4: 50.8170498,
    column_3: 2.7636029,
    column_2: "Reningelst",
    column_1: "8970",
    geom: null,
    coordonnees: {
      lat: 50.8170498,
      lon: 2.7636029,
    },
  },
  {
    column_4: 50.89915125,
    column_3: 3.0054428297242,
    column_2: "Passendale",
    column_1: "8980",
    geom: null,
    coordonnees: {
      lat: 50.89915125,
      lon: 3.00544282972,
    },
  },
  {
    column_4: 50.8151226,
    column_3: 2.9770015427192,
    column_2: "Zandvoorde",
    column_1: "8980",
    geom: null,
    coordonnees: {
      lat: 50.8151226,
      lon: 2.97700154272,
    },
  },
  {
    column_4: 51.0447236,
    column_3: 3.7588813,
    column_2: "Gentbrugge",
    column_1: "9050",
    geom: null,
    coordonnees: {
      lat: 51.0447236,
      lon: 3.7588813,
    },
  },
  {
    column_4: 51.0299989,
    column_3: 3.6575415863856,
    column_2: "Afsnee",
    column_1: "9051",
    geom: null,
    coordonnees: {
      lat: 51.0299989,
      lon: 3.65754158639,
    },
  },
  {
    column_4: 51.2000036,
    column_3: 3.8105002,
    column_2: "Zelzate",
    column_1: "9060",
    geom: null,
    coordonnees: {
      lat: 51.2000036,
      lon: 3.8105002,
    },
  },
  {
    column_4: 51.1078751,
    column_3: 3.9000296,
    column_2: "Zeveneken",
    column_1: "9080",
    geom: null,
    coordonnees: {
      lat: 51.1078751,
      lon: 3.9000296,
    },
  },
  {
    column_4: 51.2652143,
    column_3: 4.2588008641609,
    column_2: "Kallo",
    column_1: "9120",
    geom: null,
    coordonnees: {
      lat: 51.2652143,
      lon: 4.25880086416,
    },
  },
  {
    column_4: 51.12017065,
    column_3: 4.1457568844955,
    column_2: "Elversele",
    column_1: "9140",
    geom: null,
    coordonnees: {
      lat: 51.12017065,
      lon: 4.1457568845,
    },
  },
  {
    column_4: 51.14685855,
    column_3: 4.2873842506575,
    column_2: "Bazel",
    column_1: "9150",
    geom: null,
    coordonnees: {
      lat: 51.14685855,
      lon: 4.28738425066,
    },
  },
  {
    column_4: 51.2564526,
    column_3: 4.0898144,
    column_2: "De Klinge",
    column_1: "9170",
    geom: null,
    coordonnees: {
      lat: 51.2564526,
      lon: 4.0898144,
    },
  },
  {
    column_4: 51.2588943,
    column_3: 4.1511549,
    column_2: "Meerdonk",
    column_1: "9170",
    geom: null,
    coordonnees: {
      lat: 51.2588943,
      lon: 4.1511549,
    },
  },
  {
    column_4: 51.0312293,
    column_3: 4.098112,
    column_2: "Dendermonde",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.0312293,
      lon: 4.098112,
    },
  },
  {
    column_4: 50.9986902,
    column_3: 4.0158906182168,
    column_2: "Schoonaarde",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 50.9986902,
      lon: 4.01589061822,
    },
  },
  {
    column_4: 51.0226035,
    column_3: 4.1276622058379,
    column_2: "Sint-Gillis-Dendermonde",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.0226035,
      lon: 4.12766220584,
    },
  },
  {
    column_4: 50.9559778,
    column_3: 4.1400064082098,
    column_2: "Baardegem",
    column_1: "9310",
    geom: null,
    coordonnees: {
      lat: 50.9559778,
      lon: 4.14000640821,
    },
  },
  {
    column_4: 50.93483295,
    column_3: 4.1339380209476,
    column_2: "Meldert",
    column_1: "9310",
    geom: null,
    coordonnees: {
      lat: 50.93483295,
      lon: 4.13393802095,
    },
  },
  {
    column_4: 50.9660758,
    column_3: 3.9775435,
    column_2: "Lede",
    column_1: "9340",
    geom: null,
    coordonnees: {
      lat: 50.9660758,
      lon: 3.9775435,
    },
  },
  {
    column_4: 50.9741595,
    column_3: 3.9570133,
    column_2: "Wanzele",
    column_1: "9340",
    geom: null,
    coordonnees: {
      lat: 50.9741595,
      lon: 3.9570133,
    },
  },
  {
    column_4: 50.8353611,
    column_3: 4.0242373,
    column_2: "Ninove",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.8353611,
      lon: 4.0242373,
    },
  },
  {
    column_4: 50.81972985,
    column_3: 4.0458501718008,
    column_2: "Meerbeke",
    column_1: "9402",
    geom: null,
    coordonnees: {
      lat: 50.81972985,
      lon: 4.0458501718,
    },
  },
  {
    column_4: 50.8421637,
    column_3: 3.9584406,
    column_2: "Aspelare",
    column_1: "9404",
    geom: null,
    coordonnees: {
      lat: 50.8421637,
      lon: 3.9584406,
    },
  },
  {
    column_4: 51.0545566,
    column_3: 3.4154479,
    column_2: "Bambrugge",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 51.0545566,
      lon: 3.4154479,
    },
  },
  {
    column_4: 50.9407226,
    column_3: 3.9555369,
    column_2: "Erondegem",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.9407226,
      lon: 3.9555369,
    },
  },
  {
    column_4: 50.9022532,
    column_3: 4.0057717,
    column_2: "Haaltert",
    column_1: "9450",
    geom: null,
    coordonnees: {
      lat: 50.9022532,
      lon: 4.0057717,
    },
  },
  {
    column_4: 50.8843903,
    column_3: 4.0665468327116,
    column_2: "Denderleeuw",
    column_1: "9470",
    geom: null,
    coordonnees: {
      lat: 50.8843903,
      lon: 4.06654683271,
    },
  },
  {
    column_4: 50.78113635,
    column_3: 3.8718226231289,
    column_2: "Nederboelare",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.78113635,
      lon: 3.87182262313,
    },
  },
  {
    column_4: 50.77876845,
    column_3: 3.9040294677949,
    column_2: "Onkerzele",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.77876845,
      lon: 3.90402946779,
    },
  },
  {
    column_4: 50.7386591,
    column_3: 3.923884698906,
    column_2: "Viane",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.7386591,
      lon: 3.92388469891,
    },
  },
  {
    column_4: 50.76432255,
    column_3: 3.8252714980661,
    column_2: "Zarlardinge",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.76432255,
      lon: 3.82527149807,
    },
  },
  {
    column_4: 50.935088,
    column_3: 3.9009360317784,
    column_2: "Vlierzele",
    column_1: "9520",
    geom: null,
    coordonnees: {
      lat: 50.935088,
      lon: 3.90093603178,
    },
  },
  {
    column_4: 50.8988376,
    column_3: 3.8381185,
    column_2: "Oombergen",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8988376,
      lon: 3.8381185,
    },
  },
  {
    column_4: 50.8522592,
    column_3: 3.7863829,
    column_2: "Sint-Goriks-Oudenhove",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8522592,
      lon: 3.7863829,
    },
  },
  {
    column_4: 50.8636242,
    column_3: 3.7535689,
    column_2: "Roborst",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8636242,
      lon: 3.7535689,
    },
  },
  {
    column_4: 50.8660879,
    column_3: 3.7130873,
    column_2: "Sint-Denijs-Boekel",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8660879,
      lon: 3.7130873,
    },
  },
  {
    column_4: 50.8867594,
    column_3: 3.6879894099711,
    column_2: "Nederzwalm-Hermelgem",
    column_1: "9636",
    geom: null,
    coordonnees: {
      lat: 50.8867594,
      lon: 3.68798940997,
    },
  },
  {
    column_4: 50.7685711,
    column_3: 5.5556351,
    column_2: "Elst",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.7685711,
      lon: 5.5556351,
    },
  },
  {
    column_4: 50.7685909,
    column_3: 3.7859967948024,
    column_2: "Everbeek",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.7685909,
      lon: 3.7859967948,
    },
  },
  {
    column_4: 50.8052186,
    column_3: 3.7617767441185,
    column_2: "Nederbrakel",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.8052186,
      lon: 3.76177674412,
    },
  },
  {
    column_4: 50.7849168,
    column_3: 3.799316,
    column_2: "Parike",
    column_1: "9661",
    geom: null,
    coordonnees: {
      lat: 50.7849168,
      lon: 3.799316,
    },
  },
  {
    column_4: 50.8553126,
    column_3: 3.6317612,
    column_2: "Ename",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8553126,
      lon: 3.6317612,
    },
  },
  {
    column_4: 50.8853618,
    column_3: 3.5108474,
    column_2: "Nokere",
    column_1: "9771",
    geom: null,
    coordonnees: {
      lat: 50.8853618,
      lon: 3.5108474,
    },
  },
  {
    column_4: 50.8711812,
    column_3: 3.5514004,
    column_2: "Ooike",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.8711812,
      lon: 3.5514004,
    },
  },
  {
    column_4: 50.8533808,
    column_3: 3.5101135,
    column_2: "Wortegem",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.8533808,
      lon: 3.5101135,
    },
  },
  {
    column_4: 50.845989,
    column_3: 3.5329831121506,
    column_2: "Wortegem-Petegem",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.845989,
      lon: 3.53298311215,
    },
  },
  {
    column_4: 51.0078663,
    column_3: 3.4808321,
    column_2: "Vinkt",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 51.0078663,
      lon: 3.4808321,
    },
  },
  {
    column_4: 50.9779856,
    column_3: 3.7726469,
    column_2: "Lemberge",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9779856,
      lon: 3.7726469,
    },
  },
  {
    column_4: 51.0289033,
    column_3: 3.5661276338188,
    column_2: "Vosselare",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.0289033,
      lon: 3.56612763382,
    },
  },
  {
    column_4: 50.9456388,
    column_3: 3.8032713,
    column_2: "Oosterzele",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.9456388,
      lon: 3.8032713,
    },
  },
  {
    column_4: 50.9392571,
    column_3: 3.78971,
    column_2: "Scheldewindeke",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.9392571,
      lon: 3.78971,
    },
  },
  {
    column_4: 50.9206638,
    column_3: 3.4485632,
    column_2: "Zulte",
    column_1: "9870",
    geom: null,
    coordonnees: {
      lat: 50.9206638,
      lon: 3.4485632,
    },
  },
  {
    column_4: 51.0497022,
    column_3: 3.4596784,
    column_2: "Lotenhulle",
    column_1: "9880",
    geom: null,
    coordonnees: {
      lat: 51.0497022,
      lon: 3.4596784,
    },
  },
  {
    column_4: 50.9176673,
    column_3: 3.6894495,
    column_2: "Dikkelvenne",
    column_1: "9890",
    geom: null,
    coordonnees: {
      lat: 50.9176673,
      lon: 3.6894495,
    },
  },
  {
    column_4: 51.0956807,
    column_3: 3.6076129,
    column_2: "Lovendegem",
    column_1: "9920",
    geom: null,
    coordonnees: {
      lat: 51.0956807,
      lon: 3.6076129,
    },
  },
  {
    column_4: 51.1195778,
    column_3: 3.5642436785112,
    column_2: "Zomergem",
    column_1: "9930",
    geom: null,
    coordonnees: {
      lat: 51.1195778,
      lon: 3.56424367851,
    },
  },
  {
    column_4: 51.1515304,
    column_3: 3.6061712,
    column_2: "Waarschoot",
    column_1: "9950",
    geom: null,
    coordonnees: {
      lat: 51.1515304,
      lon: 3.6061712,
    },
  },
  {
    column_4: 51.23004155,
    column_3: 3.7568076657628,
    column_2: "Assenede",
    column_1: "9960",
    geom: null,
    coordonnees: {
      lat: 51.23004155,
      lon: 3.75680766576,
    },
  },
  {
    column_4: 50.8222854,
    column_3: 4.3815707,
    column_2: "Ixelles",
    column_1: "1050",
    geom: null,
    coordonnees: {
      lat: 50.8222854,
      lon: 4.3815707,
    },
  },
  {
    column_4: 50.8622633,
    column_3: 4.3257084,
    column_2: "Koekelberg",
    column_1: "1081",
    geom: null,
    coordonnees: {
      lat: 50.8622633,
      lon: 4.3257084,
    },
  },
  {
    column_4: 50.8919661,
    column_3: 4.4125708732431,
    column_2: "Haren",
    column_1: "1130",
    geom: null,
    coordonnees: {
      lat: 50.8919661,
      lon: 4.41257087324,
    },
  },
  {
    column_4: 50.8292429,
    column_3: 4.4432967,
    column_2: "Woluwe-Saint-Pierre",
    column_1: "1150",
    geom: null,
    coordonnees: {
      lat: 50.8292429,
      lon: 4.4432967,
    },
  },
  {
    column_4: 50.6918004,
    column_3: 4.798944,
    column_2: "Incourt",
    column_1: "1315",
    geom: null,
    coordonnees: {
      lat: 50.6918004,
      lon: 4.798944,
    },
  },
  {
    column_4: 50.681817,
    column_3: 4.7957745,
    column_2: "Opprebais",
    column_1: "1315",
    geom: null,
    coordonnees: {
      lat: 50.681817,
      lon: 4.7957745,
    },
  },
  {
    column_4: 50.7085122,
    column_3: 4.7848246,
    column_2: "Roux-Miroir",
    column_1: "1315",
    geom: null,
    coordonnees: {
      lat: 50.7085122,
      lon: 4.7848246,
    },
  },
  {
    column_4: 50.7021372,
    column_3: 4.6896145,
    column_2: "Bonlez",
    column_1: "1325",
    geom: null,
    coordonnees: {
      lat: 50.7021372,
      lon: 4.6896145,
    },
  },
  {
    column_4: 50.6617107,
    column_3: 4.6747213,
    column_2: "Corroy-Le-Grand",
    column_1: "1325",
    geom: null,
    coordonnees: {
      lat: 50.6617107,
      lon: 4.6747213,
    },
  },
  {
    column_4: 50.6625713,
    column_3: 4.5291742660159,
    column_2: "Céroux-Mousty",
    column_1: "1341",
    geom: null,
    coordonnees: {
      lat: 50.6625713,
      lon: 4.52917426602,
    },
  },
  {
    column_4: 50.6610719,
    column_3: 4.9370984,
    column_2: "Folx-Les-Caves",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.6610719,
      lon: 4.9370984,
    },
  },
  {
    column_4: 50.6234469,
    column_3: 4.8134719,
    column_2: "Perwez",
    column_1: "1360",
    geom: null,
    coordonnees: {
      lat: 50.6234469,
      lon: 4.8134719,
    },
  },
  {
    column_4: 50.6681389,
    column_3: 4.8735246,
    column_2: "Bomal",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6681389,
      lon: 4.8735246,
    },
  },
  {
    column_4: 50.6316098,
    column_3: 4.8674622,
    column_2: "Grand-Rosière-Hottomont",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6316098,
      lon: 4.8674622,
    },
  },
  {
    column_4: 50.6927557,
    column_3: 4.8921703,
    column_2: "Huppaye",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6927557,
      lon: 4.8921703,
    },
  },
  {
    column_4: 50.6550272,
    column_3: 4.8635811,
    column_2: "Mont-Saint-André",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6550272,
      lon: 4.8635811,
    },
  },
  {
    column_4: 50.7218206,
    column_3: 4.8709616,
    column_2: "Jodoigne",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7218206,
      lon: 4.8709616,
    },
  },
  {
    column_4: 50.6860037,
    column_3: 4.4844157,
    column_2: "Lasne",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.6860037,
      lon: 4.4844157,
    },
  },
  {
    column_4: 50.6561328,
    column_3: 4.4559942489341,
    column_2: "Maransart",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.6561328,
      lon: 4.45599424893,
    },
  },
  {
    column_4: 50.7267076,
    column_3: 4.7033973,
    column_2: "Biez",
    column_1: "1390",
    geom: null,
    coordonnees: {
      lat: 50.7267076,
      lon: 4.7033973,
    },
  },
  {
    column_4: 50.67355825,
    column_3: 4.1350275503194,
    column_2: "Rebecq-Rognon",
    column_1: "1430",
    geom: null,
    coordonnees: {
      lat: 50.67355825,
      lon: 4.13502755032,
    },
  },
  {
    column_4: 50.6080835,
    column_3: 4.636678,
    column_2: "Chastre",
    column_1: "1450",
    geom: null,
    coordonnees: {
      lat: 50.6080835,
      lon: 4.636678,
    },
  },
  {
    column_4: 50.57939665,
    column_3: 4.5927221009772,
    column_2: "Gentinnes",
    column_1: "1450",
    geom: null,
    coordonnees: {
      lat: 50.57939665,
      lon: 4.59272210098,
    },
  },
  {
    column_4: 50.55047955,
    column_3: 4.5380975970878,
    column_2: "Marbais",
    column_1: "1495",
    geom: null,
    coordonnees: {
      lat: 50.55047955,
      lon: 4.53809759709,
    },
  },
  {
    column_4: 50.5817537,
    column_3: 4.5691118,
    column_2: "Mellery",
    column_1: "1495",
    geom: null,
    coordonnees: {
      lat: 50.5817537,
      lon: 4.5691118,
    },
  },
  {
    column_4: 50.5640258,
    column_3: 4.516035801462,
    column_2: "Villers-La-Ville",
    column_1: "1495",
    geom: null,
    coordonnees: {
      lat: 50.5640258,
      lon: 4.51603580146,
    },
  },
  {
    column_4: 50.7360524,
    column_3: 4.2374349,
    column_2: "Halle",
    column_1: "1500",
    geom: null,
    coordonnees: {
      lat: 50.7360524,
      lon: 4.2374349,
    },
  },
  {
    column_4: 50.73793415,
    column_3: 4.2605080818496,
    column_2: "Buizingen",
    column_1: "1501",
    geom: null,
    coordonnees: {
      lat: 50.73793415,
      lon: 4.26050808185,
    },
  },
  {
    column_4: 50.7877313,
    column_3: 4.1993838,
    column_2: "Sint-Laureins-Berchem",
    column_1: "1600",
    geom: null,
    coordonnees: {
      lat: 50.7877313,
      lon: 4.1993838,
    },
  },
  {
    column_4: 50.805593,
    column_3: 4.2360410720845,
    column_2: "Vlezenbeek",
    column_1: "1602",
    geom: null,
    coordonnees: {
      lat: 50.805593,
      lon: 4.23604107208,
    },
  },
  {
    column_4: 50.7348458,
    column_3: 4.1126567,
    column_2: "Heikruis",
    column_1: "1670",
    geom: null,
    coordonnees: {
      lat: 50.7348458,
      lon: 4.1126567,
    },
  },
  {
    column_4: 50.7793527,
    column_3: 4.174198071757,
    column_2: "Elingen",
    column_1: "1671",
    geom: null,
    coordonnees: {
      lat: 50.7793527,
      lon: 4.17419807176,
    },
  },
  {
    column_4: 50.84408625,
    column_3: 4.2657299667643,
    column_2: "Dilbeek",
    column_1: "1700",
    geom: null,
    coordonnees: {
      lat: 50.84408625,
      lon: 4.26572996676,
    },
  },
  {
    column_4: 50.87155835,
    column_3: 4.1834171401216,
    column_2: "Ternat",
    column_1: "1740",
    geom: null,
    coordonnees: {
      lat: 50.87155835,
      lon: 4.18341714012,
    },
  },
  {
    column_4: 50.8726659,
    column_3: 4.1535568,
    column_2: "Sint-Katherina-Lombeek",
    column_1: "1742",
    geom: null,
    coordonnees: {
      lat: 50.8726659,
      lon: 4.1535568,
    },
  },
  {
    column_4: 50.9428791,
    column_3: 4.1717632,
    column_2: "Mazenzele",
    column_1: "1745",
    geom: null,
    coordonnees: {
      lat: 50.9428791,
      lon: 4.1717632,
    },
  },
  {
    column_4: 50.8085632,
    column_3: 4.1648626,
    column_2: "Lennik",
    column_1: "1750",
    geom: null,
    coordonnees: {
      lat: 50.8085632,
      lon: 4.1648626,
    },
  },
  {
    column_4: 50.8115319,
    column_3: 4.1695303908975,
    column_2: "Sint-Martens-Lennik",
    column_1: "1750",
    geom: null,
    coordonnees: {
      lat: 50.8115319,
      lon: 4.1695303909,
    },
  },
  {
    column_4: 50.9145955,
    column_3: 4.3108723,
    column_2: "Wemmel",
    column_1: "1780",
    geom: null,
    coordonnees: {
      lat: 50.9145955,
      lon: 4.3108723,
    },
  },
  {
    column_4: 51.0881618,
    column_3: 4.1202807810427,
    column_2: "Hamme",
    column_1: "1785",
    geom: null,
    coordonnees: {
      lat: 51.0881618,
      lon: 4.12028078104,
    },
  },
  {
    column_4: 50.9527061,
    column_3: 4.3638682,
    column_2: "Beigem",
    column_1: "1852",
    geom: null,
    coordonnees: {
      lat: 50.9527061,
      lon: 4.3638682,
    },
  },
  {
    column_4: 50.94924405,
    column_3: 4.5711630873694,
    column_2: "Kampenhout",
    column_1: "1910",
    geom: null,
    coordonnees: {
      lat: 50.94924405,
      lon: 4.57116308737,
    },
  },
  {
    column_4: 50.9722782,
    column_3: 4.479869,
    column_2: "Weerde",
    column_1: "1982",
    geom: null,
    coordonnees: {
      lat: 50.9722782,
      lon: 4.479869,
    },
  },
  {
    column_4: 51.35984975,
    column_3: 4.2836261784487,
    column_2: "Zandvliet",
    column_1: "2040",
    geom: null,
    coordonnees: {
      lat: 51.35984975,
      lon: 4.28362617845,
    },
  },
  {
    column_4: 51.2115284,
    column_3: 4.4695254092764,
    column_2: "Deurne",
    column_1: "2100",
    geom: null,
    coordonnees: {
      lat: 51.2115284,
      lon: 4.46952540928,
    },
  },
  {
    column_4: 51.1203399,
    column_3: 4.8207801,
    column_2: "Morkhoven",
    column_1: "2200",
    geom: null,
    coordonnees: {
      lat: 51.1203399,
      lon: 4.8207801,
    },
  },
  {
    column_4: 51.1415564,
    column_3: 4.8401277,
    column_2: "Noorderwijk",
    column_1: "2200",
    geom: null,
    coordonnees: {
      lat: 51.1415564,
      lon: 4.8401277,
    },
  },
  {
    column_4: 51.08755725,
    column_3: 4.7191120718788,
    column_2: "Hallaar",
    column_1: "2220",
    geom: null,
    coordonnees: {
      lat: 51.08755725,
      lon: 4.71911207188,
    },
  },
  {
    column_4: 51.10609435,
    column_3: 4.7853558986493,
    column_2: "Wiekevorst",
    column_1: "2222",
    geom: null,
    coordonnees: {
      lat: 51.10609435,
      lon: 4.78535589865,
    },
  },
  {
    column_4: 51.0753923,
    column_3: 4.7885461,
    column_2: "Hulshout",
    column_1: "2235",
    geom: null,
    coordonnees: {
      lat: 51.0753923,
      lon: 4.7885461,
    },
  },
  {
    column_4: 51.19902125,
    column_3: 4.6356924683019,
    column_2: "Massenhoven",
    column_1: "2240",
    geom: null,
    coordonnees: {
      lat: 51.19902125,
      lon: 4.6356924683,
    },
  },
  {
    column_4: 51.2147918,
    column_3: 4.6595103,
    column_2: "Zandhoven",
    column_1: "2240",
    geom: null,
    coordonnees: {
      lat: 51.2147918,
      lon: 4.6595103,
    },
  },
  {
    column_4: 51.1220734,
    column_3: 4.9012622781849,
    column_2: "Tongerlo",
    column_1: "2260",
    geom: null,
    coordonnees: {
      lat: 51.1220734,
      lon: 4.90126227818,
    },
  },
  {
    column_4: 51.39574085,
    column_3: 4.7440656414186,
    column_2: "Hoogstraten",
    column_1: "2320",
    geom: null,
    coordonnees: {
      lat: 51.39574085,
      lon: 4.74406564142,
    },
  },
  {
    column_4: 51.3975366,
    column_3: 4.8085930879726,
    column_2: "Wortel",
    column_1: "2323",
    geom: null,
    coordonnees: {
      lat: 51.3975366,
      lon: 4.80859308797,
    },
  },
  {
    column_4: 51.2988018,
    column_3: 4.7821797,
    column_2: "Vlimmeren",
    column_1: "2340",
    geom: null,
    coordonnees: {
      lat: 51.2988018,
      lon: 4.7821797,
    },
  },
  {
    column_4: 51.1840421,
    column_3: 5.1155267,
    column_2: "Mol",
    column_1: "2400",
    geom: null,
    coordonnees: {
      lat: 51.1840421,
      lon: 5.1155267,
    },
  },
  {
    column_4: 51.1813951,
    column_3: 4.5967162,
    column_2: "Broechem",
    column_1: "2520",
    geom: null,
    coordonnees: {
      lat: 51.1813951,
      lon: 4.5967162,
    },
  },
  {
    column_4: 51.1052659,
    column_3: 4.453,
    column_2: "Waarloos",
    column_1: "2550",
    geom: null,
    coordonnees: {
      lat: 51.1052659,
      lon: 4.453,
    },
  },
  {
    column_4: 51.1245635,
    column_3: 4.3361875,
    column_2: "Schelle",
    column_1: "2627",
    geom: null,
    coordonnees: {
      lat: 51.1245635,
      lon: 4.3361875,
    },
  },
  {
    column_4: 51.1574786,
    column_3: 4.4384284,
    column_2: "Edegem",
    column_1: "2650",
    geom: null,
    coordonnees: {
      lat: 51.1574786,
      lon: 4.4384284,
    },
  },
  {
    column_4: 51.0657941,
    column_3: 4.4568748,
    column_2: "Walem",
    column_1: "2800",
    geom: null,
    coordonnees: {
      lat: 51.0657941,
      lon: 4.4568748,
    },
  },
  {
    column_4: 51.0519355,
    column_3: 4.4124762563322,
    column_2: "Heffen",
    column_1: "2801",
    geom: null,
    coordonnees: {
      lat: 51.0519355,
      lon: 4.41247625633,
    },
  },
  {
    column_4: 51.05486435,
    column_3: 4.2808516096232,
    column_2: "Liezele",
    column_1: "2870",
    geom: null,
    coordonnees: {
      lat: 51.05486435,
      lon: 4.28085160962,
    },
  },
  {
    column_4: 51.0802059,
    column_3: 4.3379815821239,
    column_2: "Ruisbroek",
    column_1: "2870",
    geom: null,
    coordonnees: {
      lat: 51.0802059,
      lon: 4.33798158212,
    },
  },
  {
    column_4: 51.0924649,
    column_3: 4.2423260051519,
    column_2: "Bornem",
    column_1: "2880",
    geom: null,
    coordonnees: {
      lat: 51.0924649,
      lon: 4.24232600515,
    },
  },
  {
    column_4: 51.103114,
    column_3: 4.2930862930359,
    column_2: "Hingene",
    column_1: "2880",
    geom: null,
    coordonnees: {
      lat: 51.103114,
      lon: 4.29308629304,
    },
  },
  {
    column_4: 51.06647005,
    column_3: 4.2010309553894,
    column_2: "Mariekerke",
    column_1: "2880",
    geom: null,
    coordonnees: {
      lat: 51.06647005,
      lon: 4.20103095539,
    },
  },
  {
    column_4: 51.0451478,
    column_3: 4.2519213925908,
    column_2: "Lippelo",
    column_1: "2890",
    geom: null,
    coordonnees: {
      lat: 51.0451478,
      lon: 4.25192139259,
    },
  },
  {
    column_4: 51.4677957,
    column_3: 4.4701309,
    column_2: "Essen",
    column_1: "2910",
    geom: null,
    coordonnees: {
      lat: 51.4677957,
      lon: 4.4701309,
    },
  },
  {
    column_4: 51.2955923,
    column_3: 4.5759347,
    column_2: "Sint-Job-In-'t-Goor",
    column_1: "2960",
    geom: null,
    coordonnees: {
      lat: 51.2955923,
      lon: 4.5759347,
    },
  },
  {
    column_4: 51.257095,
    column_3: 4.5955157359888,
    column_2: "Schilde",
    column_1: "2970",
    geom: null,
    coordonnees: {
      lat: 51.257095,
      lon: 4.59551573599,
    },
  },
  {
    column_4: 51.2428446,
    column_3: 4.6455565344486,
    column_2: "Halle",
    column_1: "2980",
    geom: null,
    coordonnees: {
      lat: 51.2428446,
      lon: 4.64555653445,
    },
  },
  {
    column_4: 51.2716725,
    column_3: 4.7132557,
    column_2: "Zoersel",
    column_1: "2980",
    geom: null,
    coordonnees: {
      lat: 51.2716725,
      lon: 4.7132557,
    },
  },
  {
    column_4: 51.3994474,
    column_3: 4.6428328,
    column_2: "Loenhout",
    column_1: "2990",
    geom: null,
    coordonnees: {
      lat: 51.3994474,
      lon: 4.6428328,
    },
  },
  {
    column_4: 51.38525,
    column_3: 4.5651743450402,
    column_2: "Wuustwezel",
    column_1: "2990",
    geom: null,
    coordonnees: {
      lat: 51.38525,
      lon: 4.56517434504,
    },
  },
  {
    column_4: 50.85172925,
    column_3: 4.6931307510281,
    column_2: "Heverlee",
    column_1: "3001",
    geom: null,
    coordonnees: {
      lat: 50.85172925,
      lon: 4.69313075103,
    },
  },
  {
    column_4: 50.9124843,
    column_3: 4.6404471957661,
    column_2: "Winksele",
    column_1: "3020",
    geom: null,
    coordonnees: {
      lat: 50.9124843,
      lon: 4.64044719577,
    },
  },
  {
    column_4: 50.8070345,
    column_3: 4.6067572,
    column_2: "Loonbeek",
    column_1: "3040",
    geom: null,
    coordonnees: {
      lat: 50.8070345,
      lon: 4.6067572,
    },
  },
  {
    column_4: 50.8217682,
    column_3: 4.6678971,
    column_2: "Oud-Heverlee",
    column_1: "3050",
    geom: null,
    coordonnees: {
      lat: 50.8217682,
      lon: 4.6678971,
    },
  },
  {
    column_4: 50.82408165,
    column_3: 4.6852713590102,
    column_2: "Vaalbeek",
    column_1: "3054",
    geom: null,
    coordonnees: {
      lat: 50.82408165,
      lon: 4.68527135901,
    },
  },
  {
    column_4: 50.8706469,
    column_3: 4.6322907,
    column_2: "Bertem",
    column_1: "3060",
    geom: null,
    coordonnees: {
      lat: 50.8706469,
      lon: 4.6322907,
    },
  },
  {
    column_4: 50.847010125156,
    column_3: 4.6299439737893,
    column_2: "Korbeek-Dijle",
    column_1: "3060",
    geom: null,
    coordonnees: {
      lat: 50.8470101252,
      lon: 4.62994397379,
    },
  },
  {
    column_4: 50.9104969,
    column_3: 4.5750864125531,
    column_2: "Erps-Kwerps",
    column_1: "3071",
    geom: null,
    coordonnees: {
      lat: 50.9104969,
      lon: 4.57508641255,
    },
  },
  {
    column_4: 51.0075269,
    column_3: 4.7505367662714,
    column_2: "Baal",
    column_1: "3128",
    geom: null,
    coordonnees: {
      lat: 51.0075269,
      lon: 4.75053676627,
    },
  },
  {
    column_4: 51.02422075,
    column_3: 4.7826403117004,
    column_2: "Begijnendijk",
    column_1: "3130",
    geom: null,
    coordonnees: {
      lat: 51.02422075,
      lon: 4.7826403117,
    },
  },
  {
    column_4: 50.9925865,
    column_3: 4.5553278,
    column_2: "Hever",
    column_1: "3191",
    geom: null,
    coordonnees: {
      lat: 50.9925865,
      lon: 4.5553278,
    },
  },
  {
    column_4: 51.0118664,
    column_3: 4.8856989624279,
    column_2: "Langdorp",
    column_1: "3201",
    geom: null,
    coordonnees: {
      lat: 51.0118664,
      lon: 4.88569896243,
    },
  },
  {
    column_4: 50.87211325,
    column_3: 4.7894086649098,
    column_2: "Pellenberg",
    column_1: "3212",
    geom: null,
    coordonnees: {
      lat: 50.87211325,
      lon: 4.78940866491,
    },
  },
  {
    column_4: 50.9725745,
    column_3: 4.9547327768843,
    column_2: "Scherpenheuvel",
    column_1: "3270",
    geom: null,
    coordonnees: {
      lat: 50.9725745,
      lon: 4.95473277688,
    },
  },
  {
    column_4: 50.9689147,
    column_3: 4.9754325657663,
    column_2: "Scherpenheuvel-Zichem",
    column_1: "3270",
    geom: null,
    coordonnees: {
      lat: 50.9689147,
      lon: 4.97543256577,
    },
  },
  {
    column_4: 51.0192772,
    column_3: 4.9484707287374,
    column_2: "Testelt",
    column_1: "3272",
    geom: null,
    coordonnees: {
      lat: 51.0192772,
      lon: 4.94847072874,
    },
  },
  {
    column_4: 51.0071839,
    column_3: 5.0247465287649,
    column_2: "Molenstede",
    column_1: "3294",
    geom: null,
    coordonnees: {
      lat: 51.0071839,
      lon: 5.02474652876,
    },
  },
  {
    column_4: 50.8284931,
    column_3: 4.9950692073381,
    column_2: "Oplinter",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.8284931,
      lon: 4.99506920734,
    },
  },
  {
    column_4: 50.84486375,
    column_3: 5.0533455578997,
    column_2: "Drieslinter",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.84486375,
      lon: 5.0533455579,
    },
  },
  {
    column_4: 50.843352,
    column_3: 5.0283876,
    column_2: "Neerlinter",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.843352,
      lon: 5.0283876,
    },
  },
  {
    column_4: 50.7968421,
    column_3: 5.0300432882597,
    column_2: "Overhespen",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.7968421,
      lon: 5.03004328826,
    },
  },
  {
    column_4: 50.84022445,
    column_3: 4.8620269340011,
    column_2: "Roosbeek",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.84022445,
      lon: 4.862026934,
    },
  },
  {
    column_4: 50.88734525,
    column_3: 4.9608776189751,
    column_2: "Kapellen",
    column_1: "3381",
    geom: null,
    coordonnees: {
      lat: 50.88734525,
      lon: 4.96087761898,
    },
  },
  {
    column_4: 50.9346235,
    column_3: 4.9155513317582,
    column_2: "Tielt",
    column_1: "3390",
    geom: null,
    coordonnees: {
      lat: 50.9346235,
      lon: 4.91555133176,
    },
  },
  {
    column_4: 50.9242724,
    column_3: 4.8863781815823,
    column_2: "Tielt-Winge",
    column_1: "3390",
    geom: null,
    coordonnees: {
      lat: 50.9242724,
      lon: 4.88637818158,
    },
  },
  {
    column_4: 50.7653693,
    column_3: 5.1006534330464,
    column_2: "Attenhoven",
    column_1: "3404",
    geom: null,
    coordonnees: {
      lat: 50.7653693,
      lon: 5.10065343305,
    },
  },
  {
    column_4: 50.7821026,
    column_3: 5.0793874624497,
    column_2: "Neerlanden",
    column_1: "3404",
    geom: null,
    coordonnees: {
      lat: 50.7821026,
      lon: 5.07938746245,
    },
  },
  {
    column_4: 50.8127265,
    column_3: 5.1205761783641,
    column_2: "Halle-Booienhoven",
    column_1: "3440",
    geom: null,
    coordonnees: {
      lat: 50.8127265,
      lon: 5.12057617836,
    },
  },
  {
    column_4: 50.89519195,
    column_3: 5.1015810029407,
    column_2: "Geetbets",
    column_1: "3450",
    geom: null,
    coordonnees: {
      lat: 50.89519195,
      lon: 5.10158100294,
    },
  },
  {
    column_4: 50.90566935,
    column_3: 5.068392495735,
    column_2: "Kortenaken",
    column_1: "3470",
    geom: null,
    coordonnees: {
      lat: 50.90566935,
      lon: 5.06839249573,
    },
  },
  {
    column_4: 50.83464645,
    column_3: 4.9623805201831,
    column_2: "Sint-Margriete-Houtem",
    column_1: "3470",
    geom: null,
    coordonnees: {
      lat: 50.83464645,
      lon: 4.96238052018,
    },
  },
  {
    column_4: 50.930358,
    column_3: 5.3368383970676,
    column_2: "Hasselt",
    column_1: "3500",
    geom: null,
    coordonnees: {
      lat: 50.930358,
      lon: 5.33683839707,
    },
  },
  {
    column_4: 50.9909835,
    column_3: 5.3677683,
    column_2: "Zonhoven",
    column_1: "3520",
    geom: null,
    coordonnees: {
      lat: 50.9909835,
      lon: 5.3677683,
    },
  },
  {
    column_4: 50.94876395,
    column_3: 5.2098576659451,
    column_2: "Berbroek",
    column_1: "3540",
    geom: null,
    coordonnees: {
      lat: 50.94876395,
      lon: 5.20985766595,
    },
  },
  {
    column_4: 51.08973005,
    column_3: 5.2443565507181,
    column_2: "Beverlo",
    column_1: "3581",
    geom: null,
    coordonnees: {
      lat: 51.08973005,
      lon: 5.24435655072,
    },
  },
  {
    column_4: 51.1492029,
    column_3: 5.8089243331137,
    column_2: "Kessenich",
    column_1: "3640",
    geom: null,
    coordonnees: {
      lat: 51.1492029,
      lon: 5.80892433311,
    },
  },
  {
    column_4: 51.1684178,
    column_3: 5.7260763418645,
    column_2: "Molenbeersel",
    column_1: "3640",
    geom: null,
    coordonnees: {
      lat: 51.1684178,
      lon: 5.72607634186,
    },
  },
  {
    column_4: 51.0328615,
    column_3: 5.6991443859626,
    column_2: "Dilsen",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.0328615,
      lon: 5.69914438596,
    },
  },
  {
    column_4: 51.0284779,
    column_3: 5.7311405,
    column_2: "Dilsen-Stokkem",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.0284779,
      lon: 5.7311405,
    },
  },
  {
    column_4: 51.053086,
    column_3: 5.7205216032604,
    column_2: "Rotem",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.053086,
      lon: 5.72052160326,
    },
  },
  {
    column_4: 51.08477665,
    column_3: 5.5813814347439,
    column_2: "Gruitrode",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.08477665,
      lon: 5.58138143474,
    },
  },
  {
    column_4: 51.0933149,
    column_3: 5.5540751,
    column_2: "Meeuwen-Gruitrode",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.0933149,
      lon: 5.5540751,
    },
  },
  {
    column_4: 50.7993981,
    column_3: 5.4696959,
    column_2: "Henis",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7993981,
      lon: 5.4696959,
    },
  },
  {
    column_4: 50.7678379,
    column_3: 5.4417282,
    column_2: "Koninksem",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7678379,
      lon: 5.4417282,
    },
  },
  {
    column_4: 50.86195255,
    column_3: 5.3770000287821,
    column_2: "Kortessem",
    column_1: "3720",
    geom: null,
    coordonnees: {
      lat: 50.86195255,
      lon: 5.37700002878,
    },
  },
  {
    column_4: 50.8422859,
    column_3: 5.4481669278797,
    column_2: "Schalkhoven",
    column_1: "3732",
    geom: null,
    coordonnees: {
      lat: 50.8422859,
      lon: 5.44816692788,
    },
  },
  {
    column_4: 50.8921566,
    column_3: 5.4734935,
    column_2: "Beverst",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8921566,
      lon: 5.4734935,
    },
  },
  {
    column_4: 50.8743904,
    column_3: 5.5755614,
    column_2: "Eigenbilzen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8743904,
      lon: 5.5755614,
    },
  },
  {
    column_4: 50.8460673,
    column_3: 5.6124793,
    column_2: "Hees",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8460673,
      lon: 5.6124793,
    },
  },
  {
    column_4: 50.8458315,
    column_3: 5.5753682,
    column_2: "Rosmeer",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8458315,
      lon: 5.5753682,
    },
  },
  {
    column_4: 50.883259,
    column_3: 5.556142701219,
    column_2: "Hoelbeek",
    column_1: "3746",
    geom: null,
    coordonnees: {
      lat: 50.883259,
      lon: 5.55614270122,
    },
  },
  {
    column_4: 50.8071071,
    column_3: 5.5744881,
    column_2: "Herderen",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8071071,
      lon: 5.5744881,
    },
  },
  {
    column_4: 50.7844721,
    column_3: 5.560302,
    column_2: "Millen",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.7844721,
      lon: 5.560302,
    },
  },
  {
    column_4: 50.7611375,
    column_3: 5.7719461900567,
    column_2: "Fouron-Le-Comte",
    column_1: "3798",
    geom: null,
    coordonnees: {
      lat: 50.7611375,
      lon: 5.77194619006,
    },
  },
  {
    column_4: 50.79912435,
    column_3: 5.230273192058,
    column_2: "Brustem",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.79912435,
      lon: 5.23027319206,
    },
  },
  {
    column_4: 50.77280875,
    column_3: 5.2518063089799,
    column_2: "Engelmanshoven",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.77280875,
      lon: 5.25180630898,
    },
  },
  {
    column_4: 50.7602497,
    column_3: 5.2509345230414,
    column_2: "Gelinden",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.7602497,
      lon: 5.25093452304,
    },
  },
  {
    column_4: 50.77333875,
    column_3: 5.1787834432079,
    column_2: "Kerkom-Bij-Sint-Truiden",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.77333875,
      lon: 5.17878344321,
    },
  },
  {
    column_4: 50.82694775,
    column_3: 5.2034249116034,
    column_2: "Sint-Truiden",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.82694775,
      lon: 5.2034249116,
    },
  },
  {
    column_4: 50.82338225,
    column_3: 5.3111748927674,
    column_2: "Berlingen",
    column_1: "3830",
    geom: null,
    coordonnees: {
      lat: 50.82338225,
      lon: 5.31117489277,
    },
  },
  {
    column_4: 50.84319895,
    column_3: 5.2936725006792,
    column_2: "Ulbeek",
    column_1: "3832",
    geom: null,
    coordonnees: {
      lat: 50.84319895,
      lon: 5.29367250068,
    },
  },
  {
    column_4: 50.80105865,
    column_3: 5.3055250033294,
    column_2: "Gotem",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.80105865,
      lon: 5.30552500333,
    },
  },
  {
    column_4: 50.7938946,
    column_3: 5.3659957408679,
    column_2: "Groot-Loon",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.7938946,
      lon: 5.36599574087,
    },
  },
  {
    column_4: 50.82372165,
    column_3: 5.3616421143445,
    column_2: "Kerniel",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.82372165,
      lon: 5.36164211434,
    },
  },
  {
    column_4: 50.80429285,
    column_3: 5.2612885523105,
    column_2: "Rijkel",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.80429285,
      lon: 5.26128855231,
    },
  },
  {
    column_4: 50.8972323,
    column_3: 5.2233097,
    column_2: "Wijer",
    column_1: "3850",
    geom: null,
    coordonnees: {
      lat: 50.8972323,
      lon: 5.2233097,
    },
  },
  {
    column_4: 50.7375944,
    column_3: 5.2551977,
    column_2: "Bovelingen",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7375944,
      lon: 5.2551977,
    },
  },
  {
    column_4: 50.7687571,
    column_3: 5.3567635,
    column_2: "Heks",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7687571,
      lon: 5.3567635,
    },
  },
  {
    column_4: 50.7582823,
    column_3: 5.3328245,
    column_2: "Horpmaal",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7582823,
      lon: 5.3328245,
    },
  },
  {
    column_4: 50.7713536,
    column_3: 5.2763925,
    column_2: "Klein-Gelmen",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7713536,
      lon: 5.2763925,
    },
  },
  {
    column_4: 50.7427937,
    column_3: 5.2629291,
    column_2: "Mechelen-Bovelingen",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7427937,
      lon: 5.2629291,
    },
  },
  {
    column_4: 51.2684314,
    column_3: 5.4792895720018,
    column_2: "Achel",
    column_1: "3930",
    geom: null,
    coordonnees: {
      lat: 51.2684314,
      lon: 5.479289572,
    },
  },
  {
    column_4: 51.25458535,
    column_3: 5.5312873136301,
    column_2: "Hamont",
    column_1: "3930",
    geom: null,
    coordonnees: {
      lat: 51.25458535,
      lon: 5.53128731363,
    },
  },
  {
    column_4: 51.16613895,
    column_3: 5.6378178047643,
    column_2: "Beek",
    column_1: "3960",
    geom: null,
    coordonnees: {
      lat: 51.16613895,
      lon: 5.63781780476,
    },
  },
  {
    column_4: 51.1272018,
    column_3: 5.4147469555164,
    column_2: "Wijchmaal",
    column_1: "3990",
    geom: null,
    coordonnees: {
      lat: 51.1272018,
      lon: 5.41474695552,
    },
  },
  {
    column_4: 50.6451381,
    column_3: 5.5734203,
    column_2: "Liège 2",
    column_1: "4020",
    geom: null,
    coordonnees: {
      lat: 50.6451381,
      lon: 5.5734203,
    },
  },
  {
    column_4: 50.6701887,
    column_3: 5.6403906,
    column_2: "Herstal",
    column_1: "4040",
    geom: null,
    coordonnees: {
      lat: 50.6701887,
      lon: 5.6403906,
    },
  },
  {
    column_4: 50.6933806,
    column_3: 5.563754,
    column_2: "Liers",
    column_1: "4042",
    geom: null,
    coordonnees: {
      lat: 50.6933806,
      lon: 5.563754,
    },
  },
  {
    column_4: 50.5589633,
    column_3: 5.6387759,
    column_2: "Beaufays",
    column_1: "4052",
    geom: null,
    coordonnees: {
      lat: 50.5589633,
      lon: 5.6387759,
    },
  },
  {
    column_4: 50.3955089,
    column_3: 5.5512446,
    column_2: "Vieuxville",
    column_1: "4190",
    geom: null,
    coordonnees: {
      lat: 50.3955089,
      lon: 5.5512446,
    },
  },
  {
    column_4: 50.5795921,
    column_3: 5.0495231,
    column_2: "Hannêche",
    column_1: "4210",
    geom: null,
    coordonnees: {
      lat: 50.5795921,
      lon: 5.0495231,
    },
  },
  {
    column_4: 50.5487123,
    column_3: 5.1257368,
    column_2: "Lavoir",
    column_1: "4217",
    geom: null,
    coordonnees: {
      lat: 50.5487123,
      lon: 5.1257368,
    },
  },
  {
    column_4: 50.5331675,
    column_3: 5.1318708,
    column_2: "Couthuin",
    column_1: "4218",
    geom: null,
    coordonnees: {
      lat: 50.5331675,
      lon: 5.1318708,
    },
  },
  {
    column_4: 50.6649171,
    column_3: 5.187047,
    column_2: "Darion",
    column_1: "4253",
    geom: null,
    coordonnees: {
      lat: 50.6649171,
      lon: 5.187047,
    },
  },
  {
    column_4: 50.6977331,
    column_3: 5.2146261,
    column_2: "Berloz",
    column_1: "4257",
    geom: null,
    coordonnees: {
      lat: 50.6977331,
      lon: 5.2146261,
    },
  },
  {
    column_4: 50.6300711,
    column_3: 5.1435257,
    column_2: "Braives",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.6300711,
      lon: 5.1435257,
    },
  },
  {
    column_4: 50.6093088,
    column_3: 5.1738356,
    column_2: "Fallais",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.6093088,
      lon: 5.1738356,
    },
  },
  {
    column_4: 50.6242037,
    column_3: 5.1632447,
    column_2: "Latinne",
    column_1: "4261",
    geom: null,
    coordonnees: {
      lat: 50.6242037,
      lon: 5.1632447,
    },
  },
  {
    column_4: 50.639549,
    column_3: 5.1765528,
    column_2: "Tourinne",
    column_1: "4263",
    geom: null,
    coordonnees: {
      lat: 50.639549,
      lon: 5.1765528,
    },
  },
  {
    column_4: 50.6300028,
    column_3: 5.0813689,
    column_2: "Moxhe",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6300028,
      lon: 5.0813689,
    },
  },
  {
    column_4: 50.6655625,
    column_3: 5.0480724,
    column_2: "Thisnes",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6655625,
      lon: 5.0480724,
    },
  },
  {
    column_4: 50.6893285,
    column_3: 5.2888884,
    column_2: "Bleret",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.6893285,
      lon: 5.2888884,
    },
  },
  {
    column_4: 50.6229561,
    column_3: 5.256235,
    column_2: "Aineffe",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.6229561,
      lon: 5.256235,
    },
  },
  {
    column_4: 50.6547034,
    column_3: 5.4271453,
    column_2: "Voroux-Goreux",
    column_1: "4347",
    geom: null,
    coordonnees: {
      lat: 50.6547034,
      lon: 5.4271453,
    },
  },
  {
    column_4: 50.6807151,
    column_3: 5.3267174,
    column_2: "Remicourt",
    column_1: "4350",
    geom: null,
    coordonnees: {
      lat: 50.6807151,
      lon: 5.3267174,
    },
  },
  {
    column_4: 50.6478971,
    column_3: 5.3199408,
    column_2: "Donceel",
    column_1: "4357",
    geom: null,
    coordonnees: {
      lat: 50.6478971,
      lon: 5.3199408,
    },
  },
  {
    column_4: 50.6387144,
    column_3: 5.3192131,
    column_2: "Haneffe",
    column_1: "4357",
    geom: null,
    coordonnees: {
      lat: 50.6387144,
      lon: 5.3192131,
    },
  },
  {
    column_4: 50.7214501,
    column_3: 5.3537922,
    column_2: "Lens-Sur-Geer",
    column_1: "4360",
    geom: null,
    coordonnees: {
      lat: 50.7214501,
      lon: 5.3537922,
    },
  },
  {
    column_4: 50.7291681,
    column_3: 5.3530675,
    column_2: "Oreye",
    column_1: "4360",
    geom: null,
    coordonnees: {
      lat: 50.7291681,
      lon: 5.3530675,
    },
  },
  {
    column_4: 50.715885,
    column_3: 5.397318,
    column_2: "Crisnée",
    column_1: "4367",
    geom: null,
    coordonnees: {
      lat: 50.715885,
      lon: 5.397318,
    },
  },
  {
    column_4: 50.6022808,
    column_3: 5.4580872,
    column_2: "Flémalle",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.6022808,
      lon: 5.4580872,
    },
  },
  {
    column_4: 50.6879619,
    column_3: 5.5234944,
    column_2: "Lantin",
    column_1: "4450",
    geom: null,
    coordonnees: {
      lat: 50.6879619,
      lon: 5.5234944,
    },
  },
  {
    column_4: 50.7280141,
    column_3: 5.5241784,
    column_2: "Paifve",
    column_1: "4452",
    geom: null,
    coordonnees: {
      lat: 50.7280141,
      lon: 5.5241784,
    },
  },
  {
    column_4: 50.7219179,
    column_3: 5.5714611,
    column_2: "Fexhe-Slins",
    column_1: "4458",
    geom: null,
    coordonnees: {
      lat: 50.7219179,
      lon: 5.5714611,
    },
  },
  {
    column_4: 50.644955,
    column_3: 5.503152,
    column_2: "Grâce-Hollogne",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.644955,
      lon: 5.503152,
    },
  },
  {
    column_4: 50.5445135,
    column_3: 5.4444346,
    column_2: "Ehein",
    column_1: "4480",
    geom: null,
    coordonnees: {
      lat: 50.5445135,
      lon: 5.4444346,
    },
  },
  {
    column_4: 50.5569911,
    column_3: 5.3622542,
    column_2: "Hermalle-Sous-Huy",
    column_1: "4480",
    geom: null,
    coordonnees: {
      lat: 50.5569911,
      lon: 5.3622542,
    },
  },
  {
    column_4: 50.527586,
    column_3: 5.259369,
    column_2: "Tihange",
    column_1: "4500",
    geom: null,
    coordonnees: {
      lat: 50.527586,
      lon: 5.259369,
    },
  },
  {
    column_4: 50.522397,
    column_3: 5.1880583,
    column_2: "Bas-Oha",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.522397,
      lon: 5.1880583,
    },
  },
  {
    column_4: 50.5849842,
    column_3: 5.2835083,
    column_2: "Fize-Fontaine",
    column_1: "4530",
    geom: null,
    coordonnees: {
      lat: 50.5849842,
      lon: 5.2835083,
    },
  },
  {
    column_4: 50.6161817,
    column_3: 5.2044866,
    column_2: "Vieux-Waleffe",
    column_1: "4530",
    geom: null,
    coordonnees: {
      lat: 50.6161817,
      lon: 5.2044866,
    },
  },
  {
    column_4: 50.5857931,
    column_3: 5.3041269,
    column_2: "Bodegnée",
    column_1: "4537",
    geom: null,
    coordonnees: {
      lat: 50.5857931,
      lon: 5.3041269,
    },
  },
  {
    column_4: 50.575487,
    column_3: 5.3202782,
    column_2: "Jehay",
    column_1: "4540",
    geom: null,
    coordonnees: {
      lat: 50.575487,
      lon: 5.3202782,
    },
  },
  {
    column_4: 50.4595451,
    column_3: 5.4029395,
    column_2: "Seny",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.4595451,
      lon: 5.4029395,
    },
  },
  {
    column_4: 50.4811242,
    column_3: 5.2948879,
    column_2: "Vierset-Barse",
    column_1: "4577",
    geom: null,
    coordonnees: {
      lat: 50.4811242,
      lon: 5.2948879,
    },
  },
  {
    column_4: 50.7336644,
    column_3: 5.695536,
    column_2: "Visé",
    column_1: "4600",
    geom: null,
    coordonnees: {
      lat: 50.7336644,
      lon: 5.695536,
    },
  },
  {
    column_4: 50.682809,
    column_3: 5.670164,
    column_2: "Cheratte",
    column_1: "4602",
    geom: null,
    coordonnees: {
      lat: 50.682809,
      lon: 5.670164,
    },
  },
  {
    column_4: 50.6493983,
    column_3: 5.7042703,
    column_2: "Tignée",
    column_1: "4630",
    geom: null,
    coordonnees: {
      lat: 50.6493983,
      lon: 5.7042703,
    },
  },
  {
    column_4: 50.6418082,
    column_3: 5.7060994,
    column_2: "Evegnée",
    column_1: "4631",
    geom: null,
    coordonnees: {
      lat: 50.6418082,
      lon: 5.7060994,
    },
  },
  {
    column_4: 50.6319367,
    column_3: 5.8342711,
    column_2: "Chaineux",
    column_1: "4650",
    geom: null,
    coordonnees: {
      lat: 50.6319367,
      lon: 5.8342711,
    },
  },
  {
    column_4: 50.6072867,
    column_3: 5.8107674,
    column_2: "Grand-Rechain",
    column_1: "4650",
    geom: null,
    coordonnees: {
      lat: 50.6072867,
      lon: 5.8107674,
    },
  },
  {
    column_4: 50.6037018,
    column_3: 5.7726472,
    column_2: "Xhendelesse",
    column_1: "4652",
    geom: null,
    coordonnees: {
      lat: 50.6037018,
      lon: 5.7726472,
    },
  },
  {
    column_4: 50.6953295,
    column_3: 5.7268086,
    column_2: "Trembleur",
    column_1: "4670",
    geom: null,
    coordonnees: {
      lat: 50.6953295,
      lon: 5.7268086,
    },
  },
  {
    column_4: 50.6682189,
    column_3: 5.6969122,
    column_2: "Barchon",
    column_1: "4671",
    geom: null,
    coordonnees: {
      lat: 50.6682189,
      lon: 5.6969122,
    },
  },
  {
    column_4: 50.7316969,
    column_3: 5.6295554,
    column_2: "Heure-Le-Romain",
    column_1: "4682",
    geom: null,
    coordonnees: {
      lat: 50.7316969,
      lon: 5.6295554,
    },
  },
  {
    column_4: 50.6461317,
    column_3: 6.0458005,
    column_2: "Kettenis",
    column_1: "4701",
    geom: null,
    coordonnees: {
      lat: 50.6461317,
      lon: 6.0458005,
    },
  },
  {
    column_4: 50.7154957,
    column_3: 6.013341,
    column_2: "La Calamine",
    column_1: "4720",
    geom: null,
    coordonnees: {
      lat: 50.7154957,
      lon: 6.013341,
    },
  },
  {
    column_4: 50.7199902,
    column_3: 6.0240702,
    column_2: "Neu-Moresnet",
    column_1: "4721",
    geom: null,
    coordonnees: {
      lat: 50.7199902,
      lon: 6.0240702,
    },
  },
  {
    column_4: 50.4075533,
    column_3: 6.257827,
    column_2: "Bullange",
    column_1: "4760",
    geom: null,
    coordonnees: {
      lat: 50.4075533,
      lon: 6.257827,
    },
  },
  {
    column_4: 50.4344078,
    column_3: 6.3009381,
    column_2: "Rocherath",
    column_1: "4761",
    geom: null,
    coordonnees: {
      lat: 50.4344078,
      lon: 6.3009381,
    },
  },
  {
    column_4: 50.2591318,
    column_3: 6.0680547,
    column_2: "Crombach",
    column_1: "4784",
    geom: null,
    coordonnees: {
      lat: 50.2591318,
      lon: 6.0680547,
    },
  },
  {
    column_4: 50.174780874297,
    column_3: 6.1216793580592,
    column_2: "Burg-Reuland",
    column_1: "4790",
    geom: null,
    coordonnees: {
      lat: 50.1747808743,
      lon: 6.12167935806,
    },
  },
  {
    column_4: 50.1954121,
    column_3: 6.1362567,
    column_2: "Reuland",
    column_1: "4790",
    geom: null,
    coordonnees: {
      lat: 50.1954121,
      lon: 6.1362567,
    },
  },
  {
    column_4: 50.5932274,
    column_3: 5.8638544,
    column_2: "Verviers",
    column_1: "4800",
    geom: null,
    coordonnees: {
      lat: 50.5932274,
      lon: 5.8638544,
    },
  },
  {
    column_4: 50.611374,
    column_3: 5.854735,
    column_2: "Dison",
    column_1: "4820",
    geom: null,
    coordonnees: {
      lat: 50.611374,
      lon: 5.854735,
    },
  },
  {
    column_4: 50.5592528,
    column_3: 5.9647526,
    column_2: "Jalhay",
    column_1: "4845",
    geom: null,
    coordonnees: {
      lat: 50.5592528,
      lon: 5.9647526,
    },
  },
  {
    column_4: 50.5747936,
    column_3: 5.7910525,
    column_2: "Cornesse",
    column_1: "4860",
    geom: null,
    coordonnees: {
      lat: 50.5747936,
      lon: 5.7910525,
    },
  },
  {
    column_4: 50.5663484,
    column_3: 5.7227768,
    column_2: "Fraipont",
    column_1: "4870",
    geom: null,
    coordonnees: {
      lat: 50.5663484,
      lon: 5.7227768,
    },
  },
  {
    column_4: 50.7041498,
    column_3: 5.8585553,
    column_2: "Aubel",
    column_1: "4880",
    geom: null,
    coordonnees: {
      lat: 50.7041498,
      lon: 5.8585553,
    },
  },
  {
    column_4: 50.4894593,
    column_3: 5.7905664,
    column_2: "La Reid",
    column_1: "4910",
    geom: null,
    coordonnees: {
      lat: 50.4894593,
      lon: 5.7905664,
    },
  },
  {
    column_4: 50.4772534,
    column_3: 6.1143694,
    column_2: "Sourbrodt",
    column_1: "4950",
    geom: null,
    coordonnees: {
      lat: 50.4772534,
      lon: 6.1143694,
    },
  },
  {
    column_4: 50.4531568,
    column_3: 5.952833,
    column_2: "Francorchamps",
    column_1: "4970",
    geom: null,
    coordonnees: {
      lat: 50.4531568,
      lon: 5.952833,
    },
  },
  {
    column_4: 50.3388474,
    column_3: 5.8378154,
    column_2: "Fosse",
    column_1: "4980",
    geom: null,
    coordonnees: {
      lat: 50.3388474,
      lon: 5.8378154,
    },
  },
  {
    column_4: 50.3557319,
    column_3: 5.9213974,
    column_2: "Wanne",
    column_1: "4980",
    geom: null,
    coordonnees: {
      lat: 50.3557319,
      lon: 5.9213974,
    },
  },
  {
    column_4: 50.4145319,
    column_3: 5.7321495,
    column_2: "Lorcé",
    column_1: "4987",
    geom: null,
    coordonnees: {
      lat: 50.4145319,
      lon: 5.7321495,
    },
  },
  {
    column_4: 50.4069504,
    column_3: 5.80836,
    column_2: "Stoumont",
    column_1: "4987",
    geom: null,
    coordonnees: {
      lat: 50.4069504,
      lon: 5.80836,
    },
  },
  {
    column_4: 50.4687313,
    column_3: 4.9219558,
    column_2: "Beez",
    column_1: "5000",
    geom: null,
    coordonnees: {
      lat: 50.4687313,
      lon: 4.9219558,
    },
  },
  {
    column_4: 50.4382517,
    column_3: 4.7966661,
    column_2: "Malonne",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.4382517,
      lon: 4.7966661,
    },
  },
  {
    column_4: 50.49239555,
    column_3: 4.9291903523068,
    column_2: "Boninne",
    column_1: "5021",
    geom: null,
    coordonnees: {
      lat: 50.49239555,
      lon: 4.92919035231,
    },
  },
  {
    column_4: 50.5171876,
    column_3: 4.9127293838106,
    column_2: "Cognelée",
    column_1: "5022",
    geom: null,
    coordonnees: {
      lat: 50.5171876,
      lon: 4.91272938381,
    },
  },
  {
    column_4: 50.5621964,
    column_3: 4.6986933030313,
    column_2: "Gembloux",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.5621964,
      lon: 4.69869330303,
    },
  },
  {
    column_4: 50.5359706,
    column_3: 4.6546979,
    column_2: "Corroy-Le-Château",
    column_1: "5032",
    geom: null,
    coordonnees: {
      lat: 50.5359706,
      lon: 4.6546979,
    },
  },
  {
    column_4: 50.4273479,
    column_3: 4.6388177,
    column_2: "Arsimont",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4273479,
      lon: 4.6388177,
    },
  },
  {
    column_4: 50.5005948,
    column_3: 4.7909700280289,
    column_2: "Rhisnes",
    column_1: "5080",
    geom: null,
    coordonnees: {
      lat: 50.5005948,
      lon: 4.79097002803,
    },
  },
  {
    column_4: 50.552563,
    column_3: 4.7986774536609,
    column_2: "Meux",
    column_1: "5081",
    geom: null,
    coordonnees: {
      lat: 50.552563,
      lon: 4.79867745366,
    },
  },
  {
    column_4: 50.458883332027,
    column_3: 4.8785294244785,
    column_2: "Jambes",
    column_1: "5100",
    geom: null,
    coordonnees: {
      lat: 50.458883332,
      lon: 4.87852942448,
    },
  },
  {
    column_4: 50.5018413,
    column_3: 4.6119938,
    column_2: "Boignée",
    column_1: "5140",
    geom: null,
    coordonnees: {
      lat: 50.5018413,
      lon: 4.6119938,
    },
  },
  {
    column_4: 50.4348064,
    column_3: 4.758878,
    column_2: "Floreffe",
    column_1: "5150",
    geom: null,
    coordonnees: {
      lat: 50.4348064,
      lon: 4.758878,
    },
  },
  {
    column_4: 50.3776473,
    column_3: 4.8694363,
    column_2: "Profondeville",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.3776473,
      lon: 4.8694363,
    },
  },
  {
    column_4: 50.4660542,
    column_3: 4.6967162,
    column_2: "Moustier-Sur-Sambre",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.4660542,
      lon: 4.6967162,
    },
  },
  {
    column_4: 50.5008367,
    column_3: 4.6472621,
    column_2: "Saint-Martin",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.5008367,
      lon: 4.6472621,
    },
  },
  {
    column_4: 50.4900943,
    column_3: 5.1043923,
    column_2: "Andenne",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4900943,
      lon: 5.1043923,
    },
  },
  {
    column_4: 50.5142495,
    column_3: 5.0646275,
    column_2: "Landenne",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.5142495,
      lon: 5.0646275,
    },
  },
  {
    column_4: 50.6003478,
    column_3: 4.9299565,
    column_2: "Bolinne",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.6003478,
      lon: 4.9299565,
    },
  },
  {
    column_4: 50.405155,
    column_3: 4.9506941,
    column_2: "Sart-Bernard",
    column_1: "5330",
    geom: null,
    coordonnees: {
      lat: 50.405155,
      lon: 4.9506941,
    },
  },
  {
    column_4: 50.378301,
    column_3: 4.9845433,
    column_2: "Courrière",
    column_1: "5336",
    geom: null,
    coordonnees: {
      lat: 50.378301,
      lon: 4.9845433,
    },
  },
  {
    column_4: 50.3675402,
    column_3: 5.2795649,
    column_2: "Verlée",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3675402,
      lon: 5.2795649,
    },
  },
  {
    column_4: 50.3533462,
    column_3: 5.3125869,
    column_2: "Maffe",
    column_1: "5374",
    geom: null,
    coordonnees: {
      lat: 50.3533462,
      lon: 5.3125869,
    },
  },
  {
    column_4: 50.24859,
    column_3: 5.279464,
    column_2: "Hogne",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.24859,
      lon: 5.279464,
    },
  },
  {
    column_4: 50.2976637,
    column_3: 5.3737372,
    column_2: "Noiseux",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.2976637,
      lon: 5.3737372,
    },
  },
  {
    column_4: 50.5580545,
    column_3: 5.0360917,
    column_2: "Bierwart",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5580545,
      lon: 5.0360917,
    },
  },
  {
    column_4: 50.574642,
    column_3: 5.0006655,
    column_2: "Forville",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.574642,
      lon: 5.0006655,
    },
  },
  {
    column_4: 50.5244901,
    column_3: 5.008801,
    column_2: "Hingeon",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5244901,
      lon: 5.008801,
    },
  },
  {
    column_4: 50.2435657,
    column_3: 4.840864,
    column_2: "Onhaye",
    column_1: "5520",
    geom: null,
    coordonnees: {
      lat: 50.2435657,
      lon: 4.840864,
    },
  },
  {
    column_4: 50.2596445,
    column_3: 4.8245344,
    column_2: "Weillen",
    column_1: "5523",
    geom: null,
    coordonnees: {
      lat: 50.2596445,
      lon: 4.8245344,
    },
  },
  {
    column_4: 50.2452911,
    column_3: 4.8158102,
    column_2: "Gerin",
    column_1: "5524",
    geom: null,
    coordonnees: {
      lat: 50.2452911,
      lon: 4.8158102,
    },
  },
  {
    column_4: 50.3202787,
    column_3: 4.9125689,
    column_2: "Evrehailles",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3202787,
      lon: 4.9125689,
    },
  },
  {
    column_4: 50.3335584,
    column_3: 4.7976225,
    column_2: "Bioul",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.3335584,
      lon: 4.7976225,
    },
  },
  {
    column_4: 50.1967082,
    column_3: 4.8189189,
    column_2: "Hermeton-Sur-Meuse",
    column_1: "5540",
    geom: null,
    coordonnees: {
      lat: 50.1967082,
      lon: 4.8189189,
    },
  },
  {
    column_4: 50.2150767,
    column_3: 4.8294854,
    column_2: "Hastière-Par-Delà",
    column_1: "5541",
    geom: null,
    coordonnees: {
      lat: 50.2150767,
      lon: 4.8294854,
    },
  },
  {
    column_4: 50.192216,
    column_3: 4.8352693,
    column_2: "Blaimont",
    column_1: "5542",
    geom: null,
    coordonnees: {
      lat: 50.192216,
      lon: 4.8352693,
    },
  },
  {
    column_4: 49.9106502,
    column_3: 4.9172157,
    column_2: "Nafraiture",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.9106502,
      lon: 4.9172157,
    },
  },
  {
    column_4: 49.9015726,
    column_3: 4.9675442,
    column_2: "Petit-Fays",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9015726,
      lon: 4.9675442,
    },
  },
  {
    column_4: 50.1900646,
    column_3: 5.0051364,
    column_2: "Houyet",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.1900646,
      lon: 5.0051364,
    },
  },
  {
    column_4: 50.1635695,
    column_3: 4.9616357,
    column_2: "Mesnil-Eglise",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.1635695,
      lon: 4.9616357,
    },
  },
  {
    column_4: 50.1613066,
    column_3: 5.0372408,
    column_2: "Hour",
    column_1: "5563",
    geom: null,
    coordonnees: {
      lat: 50.1613066,
      lon: 5.0372408,
    },
  },
  {
    column_4: 50.1593381,
    column_3: 5.061512,
    column_2: "Wanlin",
    column_1: "5564",
    geom: null,
    coordonnees: {
      lat: 50.1593381,
      lon: 5.061512,
    },
  },
  {
    column_4: 50.0666286,
    column_3: 5.0123245,
    column_2: "Honnay",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.0666286,
      lon: 5.0123245,
    },
  },
  {
    column_4: 50.0980233,
    column_3: 4.8930998,
    column_2: "Winenne",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.0980233,
      lon: 4.8930998,
    },
  },
  {
    column_4: 50.0250695,
    column_3: 4.855026,
    column_2: "Bourseigne-Neuve",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 50.0250695,
      lon: 4.855026,
    },
  },
  {
    column_4: 49.9792667,
    column_3: 4.9388966,
    column_2: "Gedinne",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.9792667,
      lon: 4.9388966,
    },
  },
  {
    column_4: 49.942041,
    column_3: 4.9445943,
    column_2: "Houdremont",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.942041,
      lon: 4.9445943,
    },
  },
  {
    column_4: 49.9922547,
    column_3: 4.8847815,
    column_2: "Rienne",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.9922547,
      lon: 4.8847815,
    },
  },
  {
    column_4: 50.1502721,
    column_3: 5.1713525249725,
    column_2: "Eprave",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.1502721,
      lon: 5.17135252497,
    },
  },
  {
    column_4: 50.223801,
    column_3: 5.1188335,
    column_2: "Chevetogne",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.223801,
      lon: 5.1188335,
    },
  },
  {
    column_4: 50.1900466,
    column_3: 4.6393972,
    column_2: "Franchimont",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1900466,
      lon: 4.6393972,
    },
  },
  {
    column_4: 50.17794,
    column_3: 4.534019,
    column_2: "Samart",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.17794,
      lon: 4.534019,
    },
  },
  {
    column_4: 50.1904615,
    column_3: 4.6084845,
    column_2: "Villers-Le-Gambon",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1904615,
      lon: 4.6084845,
    },
  },
  {
    column_4: 50.2496186,
    column_3: 4.7134091,
    column_2: "Flavion",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2496186,
      lon: 4.7134091,
    },
  },
  {
    column_4: 50.2514227,
    column_3: 4.6030187,
    column_2: "Florennes",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2514227,
      lon: 4.6030187,
    },
  },
  {
    column_4: 50.297376,
    column_3: 4.6803521,
    column_2: "Biesmerée",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.297376,
      lon: 4.6803521,
    },
  },
  {
    column_4: 50.273814,
    column_3: 4.4326804,
    column_2: "Pry",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.273814,
      lon: 4.4326804,
    },
  },
  {
    column_4: 50.2532022,
    column_3: 4.4347509,
    column_2: "Walcourt",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2532022,
      lon: 4.4347509,
    },
  },
  {
    column_4: 50.2392649,
    column_3: 4.4947935,
    column_2: "Yves-Gomezée",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2392649,
      lon: 4.4947935,
    },
  },
  {
    column_4: 50.2778386,
    column_3: 4.4950207,
    column_2: "Laneffe",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2778386,
      lon: 4.4950207,
    },
  },
  {
    column_4: 50.3119341,
    column_3: 4.4983015,
    column_2: "Tarcienne",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.3119341,
      lon: 4.4983015,
    },
  },
  {
    column_4: 49.9615402,
    column_3: 4.4549981,
    column_2: "Cul-Des-Sarts",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 49.9615402,
      lon: 4.4549981,
    },
  },
  {
    column_4: 50.0577541,
    column_3: 4.4360525,
    column_2: "Dailly",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0577541,
      lon: 4.4360525,
    },
  },
  {
    column_4: 50.0237221,
    column_3: 4.6396988,
    column_2: "Oignies-En-Thiérache",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.0237221,
      lon: 4.6396988,
    },
  },
  {
    column_4: 50.0726831,
    column_3: 4.607218,
    column_2: "Olloy-Sur-Viroin",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.0726831,
      lon: 4.607218,
    },
  },
  {
    column_4: 50.1841874,
    column_3: 4.7597215,
    column_2: "Gochenée",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1841874,
      lon: 4.7597215,
    },
  },
  {
    column_4: 50.135223,
    column_3: 4.6748777,
    column_2: "Romerée",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.135223,
      lon: 4.6748777,
    },
  },
  {
    column_4: 50.1131712,
    column_3: 4.7424981,
    column_2: "Vaucelles",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1131712,
      lon: 4.7424981,
    },
  },
  {
    column_4: 50.3977245,
    column_3: 4.4442531,
    column_2: "Marcinelle",
    column_1: "6001",
    geom: null,
    coordonnees: {
      lat: 50.3977245,
      lon: 4.4442531,
    },
  },
  {
    column_4: 50.4415002,
    column_3: 4.3907758274107,
    column_2: "Roux",
    column_1: "6044",
    geom: null,
    coordonnees: {
      lat: 50.4415002,
      lon: 4.39077582741,
    },
  },
  {
    column_4: 50.3249486,
    column_3: 4.4466922,
    column_2: "Nalinnes",
    column_1: "6120",
    geom: null,
    coordonnees: {
      lat: 50.3249486,
      lon: 4.4466922,
    },
  },
  {
    column_4: 50.411224661019,
    column_3: 4.3239637925238,
    column_2: "Fontaine-L'evêque",
    column_1: "6140",
    geom: null,
    coordonnees: {
      lat: 50.411224661,
      lon: 4.32396379252,
    },
  },
  {
    column_4: 50.4817059,
    column_3: 4.4935448,
    column_2: "Heppignies",
    column_1: "6220",
    geom: null,
    coordonnees: {
      lat: 50.4817059,
      lon: 4.4935448,
    },
  },
  {
    column_4: 50.4285932,
    column_3: 4.526056,
    column_2: "Pironchamps",
    column_1: "6240",
    geom: null,
    coordonnees: {
      lat: 50.4285932,
      lon: 4.526056,
    },
  },
  {
    column_4: 50.4065228,
    column_3: 4.584081,
    column_2: "Aiseau-Presles",
    column_1: "6250",
    geom: null,
    coordonnees: {
      lat: 50.4065228,
      lon: 4.584081,
    },
  },
  {
    column_4: 50.4289142,
    column_3: 4.5728849,
    column_2: "Roselies",
    column_1: "6250",
    geom: null,
    coordonnees: {
      lat: 50.4289142,
      lon: 4.5728849,
    },
  },
  {
    column_4: 50.3378166,
    column_3: 4.5276771,
    column_2: "Gerpinnes",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.3378166,
      lon: 4.5276771,
    },
  },
  {
    column_4: 50.0298544,
    column_3: 4.3755329,
    column_2: "Baileux",
    column_1: "6464",
    geom: null,
    coordonnees: {
      lat: 50.0298544,
      lon: 4.3755329,
    },
  },
  {
    column_4: 50.200517,
    column_3: 4.1714631,
    column_2: "Grandrieu",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.200517,
      lon: 4.1714631,
    },
  },
  {
    column_4: 50.1430111,
    column_3: 4.2731851,
    column_2: "Rance",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.1430111,
      lon: 4.2731851,
    },
  },
  {
    column_4: 50.189927,
    column_3: 4.2670432,
    column_2: "Renlies",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.189927,
      lon: 4.2670432,
    },
  },
  {
    column_4: 50.3081977,
    column_3: 4.2837674,
    column_2: "Ragnies",
    column_1: "6532",
    geom: null,
    coordonnees: {
      lat: 50.3081977,
      lon: 4.2837674,
    },
  },
  {
    column_4: 50.3460194,
    column_3: 4.2654874,
    column_2: "Lobbes",
    column_1: "6540",
    geom: null,
    coordonnees: {
      lat: 50.3460194,
      lon: 4.2654874,
    },
  },
  {
    column_4: 50.3057605,
    column_3: 4.1783639,
    column_2: "Hantes-Wihéries",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.3057605,
      lon: 4.1783639,
    },
  },
  {
    column_4: 50.3067709,
    column_3: 4.1558267,
    column_2: "Solre-Sur-Sambre",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.3067709,
      lon: 4.1558267,
    },
  },
  {
    column_4: 50.3208131,
    column_3: 4.2137302,
    column_2: "Fontaine-Valmont",
    column_1: "6567",
    geom: null,
    coordonnees: {
      lat: 50.3208131,
      lon: 4.2137302,
    },
  },
  {
    column_4: 50.3141612,
    column_3: 4.194796,
    column_2: "Labuissière",
    column_1: "6567",
    geom: null,
    coordonnees: {
      lat: 50.3141612,
      lon: 4.194796,
    },
  },
  {
    column_4: 50.3552041,
    column_3: 4.1711042,
    column_2: "Merbes-Sainte-Marie",
    column_1: "6567",
    geom: null,
    coordonnees: {
      lat: 50.3552041,
      lon: 4.1711042,
    },
  },
  {
    column_4: 50.0009951,
    column_3: 5.7153203,
    column_2: "Bastogne",
    column_1: "6600",
    geom: null,
    coordonnees: {
      lat: 50.0009951,
      lon: 5.7153203,
    },
  },
  {
    column_4: 50.0249524,
    column_3: 5.8368151,
    column_2: "Longvilly",
    column_1: "6600",
    geom: null,
    coordonnees: {
      lat: 50.0249524,
      lon: 5.8368151,
    },
  },
  {
    column_4: 49.9437838,
    column_3: 5.6866728,
    column_2: "Hompré",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.9437838,
      lon: 5.6866728,
    },
  },
  {
    column_4: 49.957603,
    column_3: 5.5824265,
    column_2: "Morhet",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.957603,
      lon: 5.5824265,
    },
  },
  {
    column_4: 49.9121387,
    column_3: 5.602746,
    column_2: "Nives",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.9121387,
      lon: 5.602746,
    },
  },
  {
    column_4: 49.8805193,
    column_3: 5.5493342,
    column_2: "Juseret",
    column_1: "6642",
    geom: null,
    coordonnees: {
      lat: 49.8805193,
      lon: 5.5493342,
    },
  },
  {
    column_4: 50.098929,
    column_3: 5.7384527,
    column_2: "Mabompré",
    column_1: "6663",
    geom: null,
    coordonnees: {
      lat: 50.098929,
      lon: 5.7384527,
    },
  },
  {
    column_4: 50.1907895,
    column_3: 5.9549305,
    column_2: "Gouvy",
    column_1: "6670",
    geom: null,
    coordonnees: {
      lat: 50.1907895,
      lon: 5.9549305,
    },
  },
  {
    column_4: 50.0097939,
    column_3: 5.5297763,
    column_2: "Tillet",
    column_1: "6680",
    geom: null,
    coordonnees: {
      lat: 50.0097939,
      lon: 5.5297763,
    },
  },
  {
    column_4: 50.0535428,
    column_3: 5.6892466,
    column_2: "Longchamps",
    column_1: "6688",
    geom: null,
    coordonnees: {
      lat: 50.0535428,
      lon: 5.6892466,
    },
  },
  {
    column_4: 50.2883685,
    column_3: 5.9170187,
    column_2: "Vielsalm",
    column_1: "6690",
    geom: null,
    coordonnees: {
      lat: 50.2883685,
      lon: 5.9170187,
    },
  },
  {
    column_4: 49.6499559,
    column_3: 5.8675666,
    column_2: "Autelbas",
    column_1: "6706",
    geom: null,
    coordonnees: {
      lat: 49.6499559,
      lon: 5.8675666,
    },
  },
  {
    column_4: 49.7286061,
    column_3: 5.6503942,
    column_2: "Habay-La-Neuve",
    column_1: "6720",
    geom: null,
    coordonnees: {
      lat: 49.7286061,
      lon: 5.6503942,
    },
  },
  {
    column_4: 49.772082,
    column_3: 5.620414,
    column_2: "Anlier",
    column_1: "6721",
    geom: null,
    coordonnees: {
      lat: 49.772082,
      lon: 5.620414,
    },
  },
  {
    column_4: 49.718767,
    column_3: 5.4851582,
    column_2: "Rossignol",
    column_1: "6730",
    geom: null,
    coordonnees: {
      lat: 49.718767,
      lon: 5.4851582,
    },
  },
  {
    column_4: 49.6774116,
    column_3: 5.475602,
    column_2: "Saint-Vincent",
    column_1: "6730",
    geom: null,
    coordonnees: {
      lat: 49.6774116,
      lon: 5.475602,
    },
  },
  {
    column_4: 49.6732168,
    column_3: 5.5644887,
    column_2: "Sainte-Marie-Sur-Semois",
    column_1: "6740",
    geom: null,
    coordonnees: {
      lat: 49.6732168,
      lon: 5.5644887,
    },
  },
  {
    column_4: 49.6701655,
    column_3: 5.6698138,
    column_2: "Vance",
    column_1: "6741",
    geom: null,
    coordonnees: {
      lat: 49.6701655,
      lon: 5.6698138,
    },
  },
  {
    column_4: 49.6255848,
    column_3: 5.6983633,
    column_2: "Châtillon",
    column_1: "6747",
    geom: null,
    coordonnees: {
      lat: 49.6255848,
      lon: 5.6983633,
    },
  },
  {
    column_4: 49.6134362,
    column_3: 5.6569569,
    column_2: "Saint-Léger",
    column_1: "6747",
    geom: null,
    coordonnees: {
      lat: 49.6134362,
      lon: 5.6569569,
    },
  },
  {
    column_4: 49.5528606,
    column_3: 5.6403955,
    column_2: "Signeulx",
    column_1: "6750",
    geom: null,
    coordonnees: {
      lat: 49.5528606,
      lon: 5.6403955,
    },
  },
  {
    column_4: 49.5677263,
    column_3: 5.5329559,
    column_2: "Virton",
    column_1: "6760",
    geom: null,
    coordonnees: {
      lat: 49.5677263,
      lon: 5.5329559,
    },
  },
  {
    column_4: 49.5553011,
    column_3: 5.4979579,
    column_2: "Dampicourt",
    column_1: "6767",
    geom: null,
    coordonnees: {
      lat: 49.5553011,
      lon: 5.4979579,
    },
  },
  {
    column_4: 49.534288,
    column_3: 5.4996797,
    column_2: "Harnoncourt",
    column_1: "6767",
    geom: null,
    coordonnees: {
      lat: 49.534288,
      lon: 5.4996797,
    },
  },
  {
    column_4: 49.6351714,
    column_3: 5.8350151,
    column_2: "Hondelange",
    column_1: "6780",
    geom: null,
    coordonnees: {
      lat: 49.6351714,
      lon: 5.8350151,
    },
  },
  {
    column_4: 49.5675296,
    column_3: 5.804352,
    column_2: "Aubange",
    column_1: "6790",
    geom: null,
    coordonnees: {
      lat: 49.5675296,
      lon: 5.804352,
    },
  },
  {
    column_4: 49.5903125,
    column_3: 5.7248143,
    column_2: "Rachecourt",
    column_1: "6792",
    geom: null,
    coordonnees: {
      lat: 49.5903125,
      lon: 5.7248143,
    },
  },
  {
    column_4: 49.738581,
    column_3: 5.3394252,
    column_2: "Chiny",
    column_1: "6810",
    geom: null,
    coordonnees: {
      lat: 49.738581,
      lon: 5.3394252,
    },
  },
  {
    column_4: 49.6958089,
    column_3: 5.420477,
    column_2: "Jamoigne",
    column_1: "6810",
    geom: null,
    coordonnees: {
      lat: 49.6958089,
      lon: 5.420477,
    },
  },
  {
    column_4: 49.7293745,
    column_3: 5.2429556,
    column_2: "Sainte-Cécile",
    column_1: "6820",
    geom: null,
    coordonnees: {
      lat: 49.7293745,
      lon: 5.2429556,
    },
  },
  {
    column_4: 49.8254037,
    column_3: 5.0738804,
    column_2: "Sensenruth",
    column_1: "6832",
    geom: null,
    coordonnees: {
      lat: 49.8254037,
      lon: 5.0738804,
    },
  },
  {
    column_4: 50.3912772,
    column_3: 6.0129575,
    column_2: "Bellevaux",
    column_1: "6834",
    geom: null,
    coordonnees: {
      lat: 50.3912772,
      lon: 6.0129575,
    },
  },
  {
    column_4: 49.8947611,
    column_3: 5.0830529,
    column_2: "Carlsbourg",
    column_1: "6850",
    geom: null,
    coordonnees: {
      lat: 49.8947611,
      lon: 5.0830529,
    },
  },
  {
    column_4: 49.8855714,
    column_3: 5.175013,
    column_2: "Offagne",
    column_1: "6850",
    geom: null,
    coordonnees: {
      lat: 49.8855714,
      lon: 5.175013,
    },
  },
  {
    column_4: 49.9348514,
    column_3: 5.1201252,
    column_2: "Opont",
    column_1: "6852",
    geom: null,
    coordonnees: {
      lat: 49.9348514,
      lon: 5.1201252,
    },
  },
  {
    column_4: 49.8029977,
    column_3: 5.4625426,
    column_2: "Assenois",
    column_1: "6860",
    geom: null,
    coordonnees: {
      lat: 49.8029977,
      lon: 5.4625426,
    },
  },
  {
    column_4: 49.8508818,
    column_3: 5.534876707362,
    column_2: "Ebly",
    column_1: "6860",
    geom: null,
    coordonnees: {
      lat: 49.8508818,
      lon: 5.53487670736,
    },
  },
  {
    column_4: 50.0058391,
    column_3: 5.3968709,
    column_2: "Vesqueville",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.0058391,
      lon: 5.3968709,
    },
  },
  {
    column_4: 49.8546564,
    column_3: 5.2529266,
    column_2: "Bertrix",
    column_1: "6880",
    geom: null,
    coordonnees: {
      lat: 49.8546564,
      lon: 5.2529266,
    },
  },
  {
    column_4: 50.2232964,
    column_3: 5.2931158,
    column_2: "Aye",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.2232964,
      lon: 5.2931158,
    },
  },
  {
    column_4: 50.2240814,
    column_3: 5.3428796,
    column_2: "Marche-En-Famenne",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.2240814,
      lon: 5.3428796,
    },
  },
  {
    column_4: 50.1879034,
    column_3: 5.4095929,
    column_2: "Roy",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.1879034,
      lon: 5.4095929,
    },
  },
  {
    column_4: 50.0217646,
    column_3: 5.1184644,
    column_2: "Daverdisse",
    column_1: "6929",
    geom: null,
    coordonnees: {
      lat: 50.0217646,
      lon: 5.1184644,
    },
  },
  {
    column_4: 49.978801,
    column_3: 5.0866787,
    column_2: "Porcheresse",
    column_1: "6929",
    geom: null,
    coordonnees: {
      lat: 49.978801,
      lon: 5.0866787,
    },
  },
  {
    column_4: 50.3760087,
    column_3: 5.5223752,
    column_2: "Bomal-Sur-Ourthe",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.3760087,
      lon: 5.5223752,
    },
  },
  {
    column_4: 50.3783963,
    column_3: 5.4048529,
    column_2: "Borlon",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.3783963,
      lon: 5.4048529,
    },
  },
  {
    column_4: 50.3619168,
    column_3: 5.5764092,
    column_2: "Villers-Sainte-Gertrude",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.3619168,
      lon: 5.5764092,
    },
  },
  {
    column_4: 50.2922823,
    column_3: 5.6625445,
    column_2: "Grandmenil",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.2922823,
      lon: 5.6625445,
    },
  },
  {
    column_4: 50.3516772,
    column_3: 5.6587925,
    column_2: "Harre",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.3516772,
      lon: 5.6587925,
    },
  },
  {
    column_4: 50.1506704,
    column_3: 5.5783344,
    column_2: "Hives",
    column_1: "6984",
    geom: null,
    coordonnees: {
      lat: 50.1506704,
      lon: 5.5783344,
    },
  },
  {
    column_4: 50.2930897,
    column_3: 5.5597229,
    column_2: "Erezée",
    column_1: "6997",
    geom: null,
    coordonnees: {
      lat: 50.2930897,
      lon: 5.5597229,
    },
  },
  {
    column_4: 50.448422,
    column_3: 3.8894392,
    column_2: "Jemappes",
    column_1: "7012",
    geom: null,
    coordonnees: {
      lat: 50.448422,
      lon: 3.8894392,
    },
  },
  {
    column_4: 50.4283672,
    column_3: 3.960668,
    column_2: "Mesvin",
    column_1: "7022",
    geom: null,
    coordonnees: {
      lat: 50.4283672,
      lon: 3.960668,
    },
  },
  {
    column_4: 50.4366253,
    column_3: 4.012574,
    column_2: "Saint-Symphorien",
    column_1: "7030",
    geom: null,
    coordonnees: {
      lat: 50.4366253,
      lon: 4.012574,
    },
  },
  {
    column_4: 50.4254505,
    column_3: 3.9868957,
    column_2: "Spiennes",
    column_1: "7032",
    geom: null,
    coordonnees: {
      lat: 50.4254505,
      lon: 3.9868957,
    },
  },
  {
    column_4: 50.4760289,
    column_3: 4.0062232,
    column_2: "Obourg",
    column_1: "7034",
    geom: null,
    coordonnees: {
      lat: 50.4760289,
      lon: 4.0062232,
    },
  },
  {
    column_4: 50.3922864,
    column_3: 3.9168758,
    column_2: "Genly",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.3922864,
      lon: 3.9168758,
    },
  },
  {
    column_4: 50.367996,
    column_3: 3.93752,
    column_2: "Quévy-Le-Petit",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.367996,
      lon: 3.93752,
    },
  },
  {
    column_4: 50.53309,
    column_3: 3.884409,
    column_2: "Erbaut",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.53309,
      lon: 3.884409,
    },
  },
  {
    column_4: 50.5155495,
    column_3: 3.8961158,
    column_2: "Erbisoeul",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.5155495,
      lon: 3.8961158,
    },
  },
  {
    column_4: 50.5369939,
    column_3: 3.9598418,
    column_2: "Masnuy-Saint-Pierre",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.5369939,
      lon: 3.9598418,
    },
  },
  {
    column_4: 50.5996119,
    column_3: 4.1861019,
    column_2: "Henripont",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.5996119,
      lon: 4.1861019,
    },
  },
  {
    column_4: 50.4525018,
    column_3: 4.1604101,
    column_2: "Saint-Vaast",
    column_1: "7100",
    geom: null,
    coordonnees: {
      lat: 50.4525018,
      lon: 4.1604101,
    },
  },
  {
    column_4: 50.3976702,
    column_3: 4.0975758,
    column_2: "Estinnes",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3976702,
      lon: 4.0975758,
    },
  },
  {
    column_4: 50.3555625,
    column_3: 4.062845,
    column_2: "Rouveroy",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3555625,
      lon: 4.062845,
    },
  },
  {
    column_4: 50.401796,
    column_3: 4.0573258,
    column_2: "Vellereille-Le-Sec",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.401796,
      lon: 4.0573258,
    },
  },
  {
    column_4: 50.4299906,
    column_3: 4.1024947,
    column_2: "Bray",
    column_1: "7130",
    geom: null,
    coordonnees: {
      lat: 50.4299906,
      lon: 4.1024947,
    },
  },
  {
    column_4: 50.4509843,
    column_3: 4.2523562,
    column_2: "Morlanwelz",
    column_1: "7140",
    geom: null,
    coordonnees: {
      lat: 50.4509843,
      lon: 4.2523562,
    },
  },
  {
    column_4: 50.4544626,
    column_3: 4.243909,
    column_2: "Morlanwelz-Mariemont",
    column_1: "7140",
    geom: null,
    coordonnees: {
      lat: 50.4544626,
      lon: 4.243909,
    },
  },
  {
    column_4: 50.4738724,
    column_3: 4.2358174,
    column_2: "La Hestre",
    column_1: "7170",
    geom: null,
    coordonnees: {
      lat: 50.4738724,
      lon: 4.2358174,
    },
  },
  {
    column_4: 50.52567625,
    column_3: 4.199657690926,
    column_2: "Familleureux",
    column_1: "7181",
    geom: null,
    coordonnees: {
      lat: 50.52567625,
      lon: 4.19965769093,
    },
  },
  {
    column_4: 50.475502,
    column_3: 3.6504677,
    column_2: "Bernissart",
    column_1: "7320",
    geom: null,
    coordonnees: {
      lat: 50.475502,
      lon: 3.6504677,
    },
  },
  {
    column_4: 50.4056635,
    column_3: 3.8513958,
    column_2: "Colfontaine",
    column_1: "7340",
    geom: null,
    coordonnees: {
      lat: 50.4056635,
      lon: 3.8513958,
    },
  },
  {
    column_4: 50.436001,
    column_3: 3.703666,
    column_2: "Montroeul-Sur-Haine",
    column_1: "7350",
    geom: null,
    coordonnees: {
      lat: 50.436001,
      lon: 3.703666,
    },
  },
  {
    column_4: 50.3729779,
    column_3: 3.8051335,
    column_2: "Blaugies",
    column_1: "7370",
    geom: null,
    coordonnees: {
      lat: 50.3729779,
      lon: 3.8051335,
    },
  },
  {
    column_4: 50.4036968,
    column_3: 3.7521047,
    column_2: "Elouges",
    column_1: "7370",
    geom: null,
    coordonnees: {
      lat: 50.4036968,
      lon: 3.7521047,
    },
  },
  {
    column_4: 50.3852902,
    column_3: 3.7521224,
    column_2: "Wihéries",
    column_1: "7370",
    geom: null,
    coordonnees: {
      lat: 50.3852902,
      lon: 3.7521224,
    },
  },
  {
    column_4: 50.3830135,
    column_3: 3.7177788,
    column_2: "Audregnies",
    column_1: "7382",
    geom: null,
    coordonnees: {
      lat: 50.3830135,
      lon: 3.7177788,
    },
  },
  {
    column_4: 50.3647711,
    column_3: 3.776059,
    column_2: "Athis",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3647711,
      lon: 3.776059,
    },
  },
  {
    column_4: 50.3463188,
    column_3: 3.7317059,
    column_2: "Autreppe",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3463188,
      lon: 3.7317059,
    },
  },
  {
    column_4: 50.60566,
    column_3: 3.3878259,
    column_2: "Tournai",
    column_1: "7500",
    geom: null,
    coordonnees: {
      lat: 50.60566,
      lon: 3.3878259,
    },
  },
  {
    column_4: 50.57792,
    column_3: 3.3287795,
    column_2: "Froidmont",
    column_1: "7504",
    geom: null,
    coordonnees: {
      lat: 50.57792,
      lon: 3.3287795,
    },
  },
  {
    column_4: 50.6217774,
    column_3: 3.5028761,
    column_2: "Beclers",
    column_1: "7532",
    geom: null,
    coordonnees: {
      lat: 50.6217774,
      lon: 3.5028761,
    },
  },
  {
    column_4: 50.6169646,
    column_3: 3.5483951,
    column_2: "Maulde",
    column_1: "7534",
    geom: null,
    coordonnees: {
      lat: 50.6169646,
      lon: 3.5483951,
    },
  },
  {
    column_4: 50.6501425,
    column_3: 3.5120068,
    column_2: "Quartes",
    column_1: "7540",
    geom: null,
    coordonnees: {
      lat: 50.6501425,
      lon: 3.5120068,
    },
  },
  {
    column_4: 50.6554352,
    column_3: 3.4000171,
    column_2: "Mont-Saint-Aubert",
    column_1: "7542",
    geom: null,
    coordonnees: {
      lat: 50.6554352,
      lon: 3.4000171,
    },
  },
  {
    column_4: 50.5426384,
    column_3: 3.59458,
    column_2: "Bury",
    column_1: "7602",
    geom: null,
    coordonnees: {
      lat: 50.5426384,
      lon: 3.59458,
    },
  },
  {
    column_4: 50.4976184,
    column_3: 3.607483,
    column_2: "Bon-Secours",
    column_1: "7603",
    geom: null,
    coordonnees: {
      lat: 50.4976184,
      lon: 3.607483,
    },
  },
  {
    column_4: 50.561586,
    column_3: 3.552247,
    column_2: "Baugnies",
    column_1: "7604",
    geom: null,
    coordonnees: {
      lat: 50.561586,
      lon: 3.552247,
    },
  },
  {
    column_4: 50.5523518,
    column_3: 3.5828589,
    column_2: "Braffe",
    column_1: "7604",
    geom: null,
    coordonnees: {
      lat: 50.5523518,
      lon: 3.5828589,
    },
  },
  {
    column_4: 50.5297342,
    column_3: 3.5253447,
    column_2: "Callenelle",
    column_1: "7604",
    geom: null,
    coordonnees: {
      lat: 50.5297342,
      lon: 3.5253447,
    },
  },
  {
    column_4: 50.5471385,
    column_3: 3.3663017,
    column_2: "Guignies",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.5471385,
      lon: 3.3663017,
    },
  },
  {
    column_4: 50.5421847,
    column_3: 3.4271843,
    column_2: "Hollain",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.5421847,
      lon: 3.4271843,
    },
  },
  {
    column_4: 50.5468427,
    column_3: 3.388586,
    column_2: "Wez-Velvain",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.5468427,
      lon: 3.388586,
    },
  },
  {
    column_4: 50.5170287,
    column_3: 3.3541900391658,
    column_2: "Howardries",
    column_1: "7624",
    geom: null,
    coordonnees: {
      lat: 50.5170287,
      lon: 3.35419003917,
    },
  },
  {
    column_4: 50.557403,
    column_3: 3.426992,
    column_2: "Bruyelle",
    column_1: "7641",
    geom: null,
    coordonnees: {
      lat: 50.557403,
      lon: 3.426992,
    },
  },
  {
    column_4: 50.6826198,
    column_3: 3.3180722,
    column_2: "Estaimbourg",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.6826198,
      lon: 3.3180722,
    },
  },
  {
    column_4: 50.7059794,
    column_3: 3.2640184,
    column_2: "Estaimpuis",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.7059794,
      lon: 3.2640184,
    },
  },
  {
    column_4: 50.6875576,
    column_3: 3.2703911,
    column_2: "Leers-Nord",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.6875576,
      lon: 3.2703911,
    },
  },
  {
    column_4: 50.685616,
    column_3: 3.3407492,
    column_2: "Pecq",
    column_1: "7740",
    geom: null,
    coordonnees: {
      lat: 50.685616,
      lon: 3.3407492,
    },
  },
  {
    column_4: 50.7008798,
    column_3: 3.3623581,
    column_2: "Hérinnes-Lez-Pecq",
    column_1: "7742",
    geom: null,
    coordonnees: {
      lat: 50.7008798,
      lon: 3.3623581,
    },
  },
  {
    column_4: 50.747596,
    column_3: 3.481663,
    column_2: "Orroir",
    column_1: "7750",
    geom: null,
    coordonnees: {
      lat: 50.747596,
      lon: 3.481663,
    },
  },
  {
    column_4: 50.768736,
    column_3: 2.998666,
    column_2: "Comines",
    column_1: "7780",
    geom: null,
    coordonnees: {
      lat: 50.768736,
      lon: 2.998666,
    },
  },
  {
    column_4: 50.77120012074,
    column_3: 3.0019077817068,
    column_2: "Comines-Warneton",
    column_1: "7780",
    geom: null,
    coordonnees: {
      lat: 50.7712001207,
      lon: 3.00190778171,
    },
  },
  {
    column_4: 50.6201619,
    column_3: 3.7536768,
    column_2: "Irchonwelz",
    column_1: "7801",
    geom: null,
    coordonnees: {
      lat: 50.6201619,
      lon: 3.7536768,
    },
  },
  {
    column_4: 50.6788509,
    column_3: 3.7584252,
    column_2: "Ostiches",
    column_1: "7804",
    geom: null,
    coordonnees: {
      lat: 50.6788509,
      lon: 3.7584252,
    },
  },
  {
    column_4: 50.6717098,
    column_3: 4.0358365,
    column_2: "Hoves",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6717098,
      lon: 4.0358365,
    },
  },
  {
    column_4: 50.6489028,
    column_3: 3.9243973,
    column_2: "Silly",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6489028,
      lon: 3.9243973,
    },
  },
  {
    column_4: 50.6101161,
    column_3: 3.9509699,
    column_2: "Thoricourt",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6101161,
      lon: 3.9509699,
    },
  },
  {
    column_4: 50.6899321,
    column_3: 4.0845939,
    column_2: "Petit-Enghien",
    column_1: "7850",
    geom: null,
    coordonnees: {
      lat: 50.6899321,
      lon: 4.0845939,
    },
  },
  {
    column_4: 50.6953023,
    column_3: 3.8856306,
    column_2: "Bois-De-Lessines",
    column_1: "7866",
    geom: null,
    coordonnees: {
      lat: 50.6953023,
      lon: 3.8856306,
    },
  },
  {
    column_4: 50.6007682,
    column_3: 3.9397913,
    column_2: "Lombise",
    column_1: "7870",
    geom: null,
    coordonnees: {
      lat: 50.6007682,
      lon: 3.9397913,
    },
  },
  {
    column_4: 50.5643065,
    column_3: 3.9433795,
    column_2: "Montignies-Lez-Lens",
    column_1: "7870",
    geom: null,
    coordonnees: {
      lat: 50.5643065,
      lon: 3.9433795,
    },
  },
  {
    column_4: 50.7178459,
    column_3: 3.7444299,
    column_2: "Wodecq",
    column_1: "7890",
    geom: null,
    coordonnees: {
      lat: 50.7178459,
      lon: 3.7444299,
    },
  },
  {
    column_4: 50.599042981582,
    column_3: 3.617736902638,
    column_2: "Leuze-En-Hainaut",
    column_1: "7900",
    geom: null,
    coordonnees: {
      lat: 50.5990429816,
      lon: 3.61773690264,
    },
  },
  {
    column_4: 50.6125001,
    column_3: 3.6551354,
    column_2: "Chapelle-À-Wattines",
    column_1: "7903",
    geom: null,
    coordonnees: {
      lat: 50.6125001,
      lon: 3.6551354,
    },
  },
  {
    column_4: 50.566635,
    column_3: 3.6038961,
    column_2: "Willaupuis",
    column_1: "7904",
    geom: null,
    coordonnees: {
      lat: 50.566635,
      lon: 3.6038961,
    },
  },
  {
    column_4: 50.7282535,
    column_3: 3.5489704,
    column_2: "Arc-Wattripont",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.7282535,
      lon: 3.5489704,
    },
  },
  {
    column_4: 50.6747768,
    column_3: 3.5903652,
    column_2: "Ellignies-Lez-Frasnes",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.6747768,
      lon: 3.5903652,
    },
  },
  {
    column_4: 50.6736151,
    column_3: 3.5373149,
    column_2: "Forest",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.6736151,
      lon: 3.5373149,
    },
  },
  {
    column_4: 50.684567271165,
    column_3: 3.5455253970222,
    column_2: "Frasnes-Lez-Anvaing",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.6845672712,
      lon: 3.54552539702,
    },
  },
  {
    column_4: 50.5658217,
    column_3: 3.7532905,
    column_2: "Huissignies",
    column_1: "7950",
    geom: null,
    coordonnees: {
      lat: 50.5658217,
      lon: 3.7532905,
    },
  },
  {
    column_4: 50.5479197,
    column_3: 3.7361279,
    column_2: "Beloeil",
    column_1: "7970",
    geom: null,
    coordonnees: {
      lat: 50.5479197,
      lon: 3.7361279,
    },
  },
  {
    column_4: 50.5252131,
    column_3: 3.6472042,
    column_2: "Basècles",
    column_1: "7971",
    geom: null,
    coordonnees: {
      lat: 50.5252131,
      lon: 3.6472042,
    },
  },
  {
    column_4: 51.18830895,
    column_3: 3.1750054151928,
    column_2: "Sint-Andries",
    column_1: "8200",
    geom: null,
    coordonnees: {
      lat: 51.18830895,
      lon: 3.17500541519,
    },
  },
  {
    column_4: 51.1557488,
    column_3: 3.1955523,
    column_2: "Loppem",
    column_1: "8210",
    geom: null,
    coordonnees: {
      lat: 51.1557488,
      lon: 3.1955523,
    },
  },
  {
    column_4: 51.1180002,
    column_3: 3.0913265545139,
    column_2: "Aartrijke",
    column_1: "8211",
    geom: null,
    coordonnees: {
      lat: 51.1180002,
      lon: 3.09132655451,
    },
  },
  {
    column_4: 51.34942965,
    column_3: 3.3233738606453,
    column_2: "Knokke",
    column_1: "8300",
    geom: null,
    coordonnees: {
      lat: 51.34942965,
      lon: 3.32337386065,
    },
  },
  {
    column_4: 51.3126954,
    column_3: 3.2505004,
    column_2: "Ramskapelle",
    column_1: "8301",
    geom: null,
    coordonnees: {
      lat: 51.3126954,
      lon: 3.2505004,
    },
  },
  {
    column_4: 51.1933202,
    column_3: 3.2570785402003,
    column_2: "Assebroek",
    column_1: "8310",
    geom: null,
    coordonnees: {
      lat: 51.1933202,
      lon: 3.2570785402,
    },
  },
  {
    column_4: 51.331382,
    column_3: 3.2078842,
    column_2: "Zeebrugge",
    column_1: "8380",
    geom: null,
    coordonnees: {
      lat: 51.331382,
      lon: 3.2078842,
    },
  },
  {
    column_4: 51.20484775,
    column_3: 2.9668008654261,
    column_2: "Zandvoorde",
    column_1: "8400",
    geom: null,
    coordonnees: {
      lat: 51.20484775,
      lon: 2.96680086543,
    },
  },
  {
    column_4: 51.1809498,
    column_3: 2.8401373,
    column_2: "Wilskerke",
    column_1: "8431",
    geom: null,
    coordonnees: {
      lat: 51.1809498,
      lon: 2.8401373,
    },
  },
  {
    column_4: 51.1543955,
    column_3: 2.8476959,
    column_2: "Slijpe",
    column_1: "8433",
    geom: null,
    coordonnees: {
      lat: 51.1543955,
      lon: 2.8476959,
    },
  },
  {
    column_4: 51.1381923,
    column_3: 2.9016605351732,
    column_2: "Zevekote",
    column_1: "8470",
    geom: null,
    coordonnees: {
      lat: 51.1381923,
      lon: 2.90166053517,
    },
  },
  {
    column_4: 51.14886485,
    column_3: 3.0582322396597,
    column_2: "Bekegem",
    column_1: "8480",
    geom: null,
    coordonnees: {
      lat: 51.14886485,
      lon: 3.05823223966,
    },
  },
  {
    column_4: 51.1846386,
    column_3: 3.0902953889402,
    column_2: "Jabbeke",
    column_1: "8490",
    geom: null,
    coordonnees: {
      lat: 51.1846386,
      lon: 3.09029538894,
    },
  },
  {
    column_4: 51.21970915,
    column_3: 3.0730014206469,
    column_2: "Stalhille",
    column_1: "8490",
    geom: null,
    coordonnees: {
      lat: 51.21970915,
      lon: 3.07300142065,
    },
  },
  {
    column_4: 51.1712593,
    column_3: 3.0612486455732,
    column_2: "Zerkegem",
    column_1: "8490",
    geom: null,
    coordonnees: {
      lat: 51.1712593,
      lon: 3.06124864557,
    },
  },
  {
    column_4: 50.81788085,
    column_3: 3.2784153903743,
    column_2: "Kortrijk",
    column_1: "8500",
    geom: null,
    coordonnees: {
      lat: 50.81788085,
      lon: 3.27841539037,
    },
  },
  {
    column_4: 50.8511736,
    column_3: 3.2382004806211,
    column_2: "Heule",
    column_1: "8501",
    geom: null,
    coordonnees: {
      lat: 50.8511736,
      lon: 3.23820048062,
    },
  },
  {
    column_4: 50.77745545,
    column_3: 3.2186046321991,
    column_2: "Aalbeke",
    column_1: "8511",
    geom: null,
    coordonnees: {
      lat: 50.77745545,
      lon: 3.2186046322,
    },
  },
  {
    column_4: 50.7821543,
    column_3: 3.4120184,
    column_2: "Heestert",
    column_1: "8551",
    geom: null,
    coordonnees: {
      lat: 50.7821543,
      lon: 3.4120184,
    },
  },
  {
    column_4: 50.76440175,
    column_3: 3.349745322203,
    column_2: "Sint-Denijs",
    column_1: "8554",
    geom: null,
    coordonnees: {
      lat: 50.76440175,
      lon: 3.3497453222,
    },
  },
  {
    column_4: 50.8279177,
    column_3: 3.5060602,
    column_2: "Gijzelbrechtegem",
    column_1: "8570",
    geom: null,
    coordonnees: {
      lat: 50.8279177,
      lon: 3.5060602,
    },
  },
  {
    column_4: 50.8008418,
    column_3: 3.5058971661987,
    column_2: "Kerkhove",
    column_1: "8581",
    geom: null,
    coordonnees: {
      lat: 50.8008418,
      lon: 3.5058971662,
    },
  },
  {
    column_4: 50.7300021,
    column_3: 3.3837254,
    column_2: "Helchin",
    column_1: "8587",
    geom: null,
    coordonnees: {
      lat: 50.7300021,
      lon: 3.3837254,
    },
  },
  {
    column_4: 51.0358094,
    column_3: 2.8387074,
    column_2: "Kaaskerke",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.0358094,
      lon: 2.8387074,
    },
  },
  {
    column_4: 51.0206897,
    column_3: 2.8040816730756,
    column_2: "Oudekapelle",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.0206897,
      lon: 2.80408167308,
    },
  },
  {
    column_4: 50.99494815,
    column_3: 2.8520595441482,
    column_2: "Woumen",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 50.99494815,
      lon: 2.85205954415,
    },
  },
  {
    column_4: 51.0321939,
    column_3: 2.996075151704,
    column_2: "Handzame",
    column_1: "8610",
    geom: null,
    coordonnees: {
      lat: 51.0321939,
      lon: 2.9960751517,
    },
  },
  {
    column_4: 51.08832895,
    column_3: 2.7399983485035,
    column_2: "Booitshoeke",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.08832895,
      lon: 2.7399983485,
    },
  },
  {
    column_4: 51.0527661,
    column_3: 2.6895389537109,
    column_2: "Steenkerke",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.0527661,
      lon: 2.68953895371,
    },
  },
  {
    column_4: 51.071043,
    column_3: 2.6548858237238,
    column_2: "Veurne",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.071043,
      lon: 2.65488582372,
    },
  },
  {
    column_4: 51.01364415,
    column_3: 2.6570581890086,
    column_2: "Vinkem",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.01364415,
      lon: 2.65705818901,
    },
  },
  {
    column_4: 50.9035513,
    column_3: 2.7887585311487,
    column_2: "Woesten",
    column_1: "8640",
    geom: null,
    coordonnees: {
      lat: 50.9035513,
      lon: 2.78875853115,
    },
  },
  {
    column_4: 50.94228475,
    column_3: 2.7771170853989,
    column_2: "Reninge",
    column_1: "8647",
    geom: null,
    coordonnees: {
      lat: 50.94228475,
      lon: 2.7771170854,
    },
  },
  {
    column_4: 51.05621365,
    column_3: 2.9633414725432,
    column_2: "Bovekerke",
    column_1: "8680",
    geom: null,
    coordonnees: {
      lat: 51.05621365,
      lon: 2.96334147254,
    },
  },
  {
    column_4: 51.0235332,
    column_3: 2.7051716,
    column_2: "Oeren",
    column_1: "8690",
    geom: null,
    coordonnees: {
      lat: 51.0235332,
      lon: 2.7051716,
    },
  },
  {
    column_4: 50.9844835,
    column_3: 2.622401,
    column_2: "Leisele",
    column_1: "8691",
    geom: null,
    coordonnees: {
      lat: 50.9844835,
      lon: 2.622401,
    },
  },
  {
    column_4: 50.966125,
    column_3: 3.4022937173313,
    column_2: "Dentergem",
    column_1: "8720",
    geom: null,
    coordonnees: {
      lat: 50.966125,
      lon: 3.40229371733,
    },
  },
  {
    column_4: 51.166016,
    column_3: 3.3441122702909,
    column_2: "Oedelem",
    column_1: "8730",
    geom: null,
    coordonnees: {
      lat: 51.166016,
      lon: 3.34411227029,
    },
  },
  {
    column_4: 50.87620025,
    column_3: 3.4257380400092,
    column_2: "Waregem",
    column_1: "8790",
    geom: null,
    coordonnees: {
      lat: 50.87620025,
      lon: 3.42573804001,
    },
  },
  {
    column_4: 50.9042588,
    column_3: 3.1627760972777,
    column_2: "Oekene",
    column_1: "8800",
    geom: null,
    coordonnees: {
      lat: 50.9042588,
      lon: 3.16277609728,
    },
  },
  {
    column_4: 50.9444948,
    column_3: 3.124765,
    column_2: "Roeselare",
    column_1: "8800",
    geom: null,
    coordonnees: {
      lat: 50.9444948,
      lon: 3.124765,
    },
  },
  {
    column_4: 51.0585915,
    column_3: 3.0913072045454,
    column_2: "Torhout",
    column_1: "8820",
    geom: null,
    coordonnees: {
      lat: 51.0585915,
      lon: 3.09130720455,
    },
  },
  {
    column_4: 51.0075272,
    column_3: 3.2016142835313,
    column_2: "Koolskamp",
    column_1: "8851",
    geom: null,
    coordonnees: {
      lat: 51.0075272,
      lon: 3.20161428353,
    },
  },
  {
    column_4: 50.8850661,
    column_3: 3.2320134658174,
    column_2: "Lendelede",
    column_1: "8860",
    geom: null,
    coordonnees: {
      lat: 50.8850661,
      lon: 3.23201346582,
    },
  },
  {
    column_4: 50.8689046,
    column_3: 2.8475388,
    column_2: "Brielen",
    column_1: "8900",
    geom: null,
    coordonnees: {
      lat: 50.8689046,
      lon: 2.8475388,
    },
  },
  {
    column_4: 50.8192065,
    column_3: 2.8309734,
    column_2: "Dikkebus",
    column_1: "8900",
    geom: null,
    coordonnees: {
      lat: 50.8192065,
      lon: 2.8309734,
    },
  },
  {
    column_4: 50.851808,
    column_3: 2.8914774512132,
    column_2: "Ieper",
    column_1: "8900",
    geom: null,
    coordonnees: {
      lat: 50.851808,
      lon: 2.89147745121,
    },
  },
  {
    column_4: 50.8056486,
    column_3: 2.9383694,
    column_2: "Hollebeke",
    column_1: "8902",
    geom: null,
    coordonnees: {
      lat: 50.8056486,
      lon: 2.9383694,
    },
  },
  {
    column_4: 50.8169949,
    column_3: 2.8757825,
    column_2: "Voormezele",
    column_1: "8902",
    geom: null,
    coordonnees: {
      lat: 50.8169949,
      lon: 2.8757825,
    },
  },
  {
    column_4: 50.92504495,
    column_3: 2.863108835832,
    column_2: "Bikschote",
    column_1: "8920",
    geom: null,
    coordonnees: {
      lat: 50.92504495,
      lon: 2.86310883583,
    },
  },
  {
    column_4: 50.7662832,
    column_3: 2.7832991,
    column_2: "Dranouter",
    column_1: "8951",
    geom: null,
    coordonnees: {
      lat: 50.7662832,
      lon: 2.7832991,
    },
  },
  {
    column_4: 50.7675035,
    column_3: 2.840869239241,
    column_2: "Wulvergem",
    column_1: "8952",
    geom: null,
    coordonnees: {
      lat: 50.7675035,
      lon: 2.84086923924,
    },
  },
  {
    column_4: 50.7832683,
    column_3: 2.9126192876216,
    column_2: "Wijtschate",
    column_1: "8953",
    geom: null,
    coordonnees: {
      lat: 50.7832683,
      lon: 2.91261928762,
    },
  },
  {
    column_4: 51.0397129,
    column_3: 3.7141549000597,
    column_2: "Gent",
    column_1: "9000",
    geom: null,
    coordonnees: {
      lat: 51.0397129,
      lon: 3.71415490006,
    },
  },
  {
    column_4: 51.0528944,
    column_3: 3.6395168043236,
    column_2: "Drongen",
    column_1: "9031",
    geom: null,
    coordonnees: {
      lat: 51.0528944,
      lon: 3.63951680432,
    },
  },
  {
    column_4: 51.09349535,
    column_3: 3.7034612746567,
    column_2: "Wondelgem",
    column_1: "9032",
    geom: null,
    coordonnees: {
      lat: 51.09349535,
      lon: 3.70346127466,
    },
  },
  {
    column_4: 51.0372053,
    column_3: 3.7414088,
    column_2: "Ledeberg",
    column_1: "9050",
    geom: null,
    coordonnees: {
      lat: 51.0372053,
      lon: 3.7414088,
    },
  },
  {
    column_4: 51.0802144,
    column_3: 3.8792625,
    column_2: "Beervelde",
    column_1: "9080",
    geom: null,
    coordonnees: {
      lat: 51.0802144,
      lon: 3.8792625,
    },
  },
  {
    column_4: 51.3181461,
    column_3: 4.2456374474582,
    column_2: "Doel",
    column_1: "9130",
    geom: null,
    coordonnees: {
      lat: 51.3181461,
      lon: 4.24563744746,
    },
  },
  {
    column_4: 51.2519515,
    column_3: 4.27803,
    column_2: "Kallo",
    column_1: "9130",
    geom: null,
    coordonnees: {
      lat: 51.2519515,
      lon: 4.27803,
    },
  },
  {
    column_4: 51.1487608,
    column_3: 3.965212,
    column_2: "Eksaarde",
    column_1: "9160",
    geom: null,
    coordonnees: {
      lat: 51.1487608,
      lon: 3.965212,
    },
  },
  {
    column_4: 51.2180687,
    column_3: 4.1233014,
    column_2: "Sint-Gillis-Waas",
    column_1: "9170",
    geom: null,
    coordonnees: {
      lat: 51.2180687,
      lon: 4.1233014,
    },
  },
  {
    column_4: 51.1939255,
    column_3: 4.1016628795877,
    column_2: "Sint-Pauwels",
    column_1: "9170",
    geom: null,
    coordonnees: {
      lat: 51.1939255,
      lon: 4.10166287959,
    },
  },
  {
    column_4: 51.1742698,
    column_3: 3.9446436,
    column_2: "Moerbeke-Waas",
    column_1: "9180",
    geom: null,
    coordonnees: {
      lat: 51.1742698,
      lon: 3.9446436,
    },
  },
  {
    column_4: 51.0533487,
    column_3: 4.1025435644905,
    column_2: "Grembergen",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.0533487,
      lon: 4.10254356449,
    },
  },
  {
    column_4: 51.0081807,
    column_3: 4.0513674371884,
    column_2: "Oudegem",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.0081807,
      lon: 4.05136743719,
    },
  },
  {
    column_4: 50.9809343,
    column_3: 3.8735741,
    column_2: "Massemen",
    column_1: "9230",
    geom: null,
    coordonnees: {
      lat: 50.9809343,
      lon: 3.8735741,
    },
  },
  {
    column_4: 51.0683621,
    column_3: 4.0385864,
    column_2: "Zele",
    column_1: "9240",
    geom: null,
    coordonnees: {
      lat: 51.0683621,
      lon: 4.0385864,
    },
  },
  {
    column_4: 51.029657,
    column_3: 3.8504738,
    column_2: "Laarne",
    column_1: "9270",
    geom: null,
    coordonnees: {
      lat: 51.029657,
      lon: 3.8504738,
    },
  },
  {
    column_4: 51.0004013,
    column_3: 4.1307711,
    column_2: "Lebbeke",
    column_1: "9280",
    geom: null,
    coordonnees: {
      lat: 51.0004013,
      lon: 4.1307711,
    },
  },
  {
    column_4: 51.0301606,
    column_3: 4.0113470297154,
    column_2: "Berlare",
    column_1: "9290",
    geom: null,
    coordonnees: {
      lat: 51.0301606,
      lon: 4.01134702972,
    },
  },
  {
    column_4: 51.05073005,
    column_3: 3.9569121644559,
    column_2: "Overmere",
    column_1: "9290",
    geom: null,
    coordonnees: {
      lat: 51.05073005,
      lon: 3.95691216446,
    },
  },
  {
    column_4: 50.95235015,
    column_3: 4.1008746699882,
    column_2: "Moorsel",
    column_1: "9310",
    geom: null,
    coordonnees: {
      lat: 50.95235015,
      lon: 4.10087466999,
    },
  },
  {
    column_4: 50.9160962,
    column_3: 4.0573610045016,
    column_2: "Erembodegem",
    column_1: "9320",
    geom: null,
    coordonnees: {
      lat: 50.9160962,
      lon: 4.0573610045,
    },
  },
  {
    column_4: 50.9605364,
    column_3: 3.9521618,
    column_2: "Impe",
    column_1: "9340",
    geom: null,
    coordonnees: {
      lat: 50.9605364,
      lon: 3.9521618,
    },
  },
  {
    column_4: 50.8463587,
    column_3: 3.9750229,
    column_2: "Nederhasselt",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.8463587,
      lon: 3.9750229,
    },
  },
  {
    column_4: 50.8254777,
    column_3: 3.9454543,
    column_2: "Voorde",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.8254777,
      lon: 3.9454543,
    },
  },
  {
    column_4: 50.84658595,
    column_3: 3.9976558921405,
    column_2: "Outer",
    column_1: "9406",
    geom: null,
    coordonnees: {
      lat: 50.84658595,
      lon: 3.99765589214,
    },
  },
  {
    column_4: 50.9136525,
    column_3: 3.9203695,
    column_2: "Burst",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.9136525,
      lon: 3.9203695,
    },
  },
  {
    column_4: 50.8218582,
    column_3: 3.897035,
    column_2: "Ophasselt",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.8218582,
      lon: 3.897035,
    },
  },
  {
    column_4: 50.7779309,
    column_3: 3.968228,
    column_2: "Waarbeke",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.7779309,
      lon: 3.968228,
    },
  },
  {
    column_4: 50.8013287,
    column_3: 3.9673318,
    column_2: "Zandbergen",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.8013287,
      lon: 3.9673318,
    },
  },
  {
    column_4: 50.8726761,
    column_3: 3.9158325,
    column_2: "Woubrechtegem",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.8726761,
      lon: 3.9158325,
    },
  },
  {
    column_4: 50.815045,
    column_3: 3.8249605,
    column_2: "Lierde",
    column_1: "9570",
    geom: null,
    coordonnees: {
      lat: 50.815045,
      lon: 3.8249605,
    },
  },
  {
    column_4: 50.7476192,
    column_3: 3.6020465,
    column_2: "Renaix",
    column_1: "9600",
    geom: null,
    coordonnees: {
      lat: 50.7476192,
      lon: 3.6020465,
    },
  },
  {
    column_4: 50.8907864,
    column_3: 3.8078492,
    column_2: "Elene",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8907864,
      lon: 3.8078492,
    },
  },
  {
    column_4: 50.8776841,
    column_3: 3.7327378,
    column_2: "Munkzwalm",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8776841,
      lon: 3.7327378,
    },
  },
  {
    column_4: 50.8488955,
    column_3: 3.7533417,
    column_2: "Rozebeke",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8488955,
      lon: 3.7533417,
    },
  },
  {
    column_4: 50.7979739,
    column_3: 3.762778,
    column_2: "Brakel",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.7979739,
      lon: 3.762778,
    },
  },
  {
    column_4: 50.834125,
    column_3: 3.7638335,
    column_2: "Michelbeke",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.834125,
      lon: 3.7638335,
    },
  },
  {
    column_4: 50.82512665,
    column_3: 3.7998568349562,
    column_2: "Sint-Maria-Oudenhove",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.82512665,
      lon: 3.79985683496,
    },
  },
  {
    column_4: 50.8135755,
    column_3: 3.7170799912553,
    column_2: "Zegelsem",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.8135755,
      lon: 3.71707999126,
    },
  },
  {
    column_4: 50.807217,
    column_3: 3.6489314,
    column_2: "Maarke-Kerkem",
    column_1: "9680",
    geom: null,
    coordonnees: {
      lat: 50.807217,
      lon: 3.6489314,
    },
  },
  {
    column_4: 50.7965606,
    column_3: 3.5954667,
    column_2: "Nukerke",
    column_1: "9681",
    geom: null,
    coordonnees: {
      lat: 50.7965606,
      lon: 3.5954667,
    },
  },
  {
    column_4: 50.7903918,
    column_3: 3.5082669,
    column_2: "Berchem",
    column_1: "9690",
    geom: null,
    coordonnees: {
      lat: 50.7903918,
      lon: 3.5082669,
    },
  },
  {
    column_4: 50.8678376,
    column_3: 3.6322868,
    column_2: "Nederename",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8678376,
      lon: 3.6322868,
    },
  },
  {
    column_4: 50.8711812,
    column_3: 3.5514004,
    column_2: "Ooike",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8711812,
      lon: 3.5514004,
    },
  },
  {
    column_4: 50.8902386,
    column_3: 3.5605794173077,
    column_2: "Wannegem-Lede",
    column_1: "9772",
    geom: null,
    coordonnees: {
      lat: 50.8902386,
      lon: 3.56057941731,
    },
  },
  {
    column_4: 51.0175334,
    column_3: 3.5413767,
    column_2: "Meigem",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 51.0175334,
      lon: 3.5413767,
    },
  },
  {
    column_4: 51.0134905,
    column_3: 3.5837978,
    column_2: "Sint-Martens-Leerne",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 51.0134905,
      lon: 3.5837978,
    },
  },
  {
    column_4: 50.959802,
    column_3: 3.5963218,
    column_2: "Nazareth",
    column_1: "9810",
    geom: null,
    coordonnees: {
      lat: 50.959802,
      lon: 3.5963218,
    },
  },
  {
    column_4: 50.9942924,
    column_3: 3.7459119,
    column_2: "Merelbeke",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9942924,
      lon: 3.7459119,
    },
  },
  {
    column_4: 50.9924205,
    column_3: 3.6495958,
    column_2: "De Pinte",
    column_1: "9840",
    geom: null,
    coordonnees: {
      lat: 50.9924205,
      lon: 3.6495958,
    },
  },
  {
    column_4: 50.9786614,
    column_3: 3.6943144,
    column_2: "Zevergem",
    column_1: "9840",
    geom: null,
    coordonnees: {
      lat: 50.9786614,
      lon: 3.6943144,
    },
  },
  {
    column_4: 51.0829292,
    column_3: 3.5715236988666,
    column_2: "Merendree",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.0829292,
      lon: 3.57152369887,
    },
  },
  {
    column_4: 50.9591122,
    column_3: 3.7800022,
    column_2: "Moortsele",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.9591122,
      lon: 3.7800022,
    },
  },
  {
    column_4: 50.9591856,
    column_3: 3.4858873,
    column_2: "Machelen",
    column_1: "9870",
    geom: null,
    coordonnees: {
      lat: 50.9591856,
      lon: 3.4858873,
    },
  },
  {
    column_4: 51.0902776,
    column_3: 3.4994525656039,
    column_2: "Bellem",
    column_1: "9881",
    geom: null,
    coordonnees: {
      lat: 51.0902776,
      lon: 3.4994525656,
    },
  },
  {
    column_4: 50.9459212,
    column_3: 3.6845236,
    column_2: "Vurste",
    column_1: "9890",
    geom: null,
    coordonnees: {
      lat: 50.9459212,
      lon: 3.6845236,
    },
  },
  {
    column_4: 51.1844827,
    column_3: 3.5665965,
    column_2: "Eeklo",
    column_1: "9900",
    geom: null,
    coordonnees: {
      lat: 51.1844827,
      lon: 3.5665965,
    },
  },
  {
    column_4: 51.1285879,
    column_3: 3.4841052,
    column_2: "Ursel",
    column_1: "9910",
    geom: null,
    coordonnees: {
      lat: 51.1285879,
      lon: 3.4841052,
    },
  },
  {
    column_4: 51.1087891,
    column_3: 3.7078565,
    column_2: "Evergem",
    column_1: "9940",
    geom: null,
    coordonnees: {
      lat: 51.1087891,
      lon: 3.7078565,
    },
  },
  {
    column_4: 51.2311917,
    column_3: 3.6789952,
    column_2: "Bassevelde",
    column_1: "9968",
    geom: null,
    coordonnees: {
      lat: 51.2311917,
      lon: 3.6789952,
    },
  },
  {
    column_4: 51.1929943,
    column_3: 3.687828,
    column_2: "Oosteeklo",
    column_1: "9968",
    geom: null,
    coordonnees: {
      lat: 51.1929943,
      lon: 3.687828,
    },
  },
  {
    column_4: 51.2776499,
    column_3: 3.626982177111,
    column_2: "Watervliet",
    column_1: "9988",
    geom: null,
    coordonnees: {
      lat: 51.2776499,
      lon: 3.62698217711,
    },
  },
  {
    column_4: 50.8267409,
    column_3: 4.345668,
    column_2: "Saint-Gilles",
    column_1: "1060",
    geom: null,
    coordonnees: {
      lat: 50.8267409,
      lon: 4.345668,
    },
  },
  {
    column_4: 50.8543551,
    column_3: 4.3227779,
    column_2: "Molenbeek-Saint-Jean",
    column_1: "1080",
    geom: null,
    coordonnees: {
      lat: 50.8543551,
      lon: 4.3227779,
    },
  },
  {
    column_4: 50.8712396,
    column_3: 4.3175103,
    column_2: "Ganshoren",
    column_1: "1083",
    geom: null,
    coordonnees: {
      lat: 50.8712396,
      lon: 4.3175103,
    },
  },
  {
    column_4: 50.89779605,
    column_3: 4.3904888684211,
    column_2: "Neder-Over-Heembeek",
    column_1: "1120",
    geom: null,
    coordonnees: {
      lat: 50.89779605,
      lon: 4.39048886842,
    },
  },
  {
    column_4: 50.7164189,
    column_3: 4.607744,
    column_2: "Wavre",
    column_1: "1300",
    geom: null,
    coordonnees: {
      lat: 50.7164189,
      lon: 4.607744,
    },
  },
  {
    column_4: 50.7111796,
    column_3: 4.5894025,
    column_2: "Bierges",
    column_1: "1301",
    geom: null,
    coordonnees: {
      lat: 50.7111796,
      lon: 4.5894025,
    },
  },
  {
    column_4: 50.7717493,
    column_3: 4.8320079,
    column_2: "L'ecluse",
    column_1: "1320",
    geom: null,
    coordonnees: {
      lat: 50.7717493,
      lon: 4.8320079,
    },
  },
  {
    column_4: 50.666357,
    column_3: 4.5690502,
    column_2: "Ottignies",
    column_1: "1340",
    geom: null,
    coordonnees: {
      lat: 50.666357,
      lon: 4.5690502,
    },
  },
  {
    column_4: 50.6741689,
    column_3: 4.6141567317211,
    column_2: "Louvain-La-Neuve",
    column_1: "1348",
    geom: null,
    coordonnees: {
      lat: 50.6741689,
      lon: 4.61415673172,
    },
  },
  {
    column_4: 50.7034875,
    column_3: 4.9912852,
    column_2: "Orp-Le-Grand",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.7034875,
      lon: 4.9912852,
    },
  },
  {
    column_4: 50.7514034,
    column_3: 4.9822395,
    column_2: "Hélécine",
    column_1: "1357",
    geom: null,
    coordonnees: {
      lat: 50.7514034,
      lon: 4.9822395,
    },
  },
  {
    column_4: 50.7322517,
    column_3: 5.0030144,
    column_2: "Linsmeau",
    column_1: "1357",
    geom: null,
    coordonnees: {
      lat: 50.7322517,
      lon: 5.0030144,
    },
  },
  {
    column_4: 50.6631583,
    column_3: 4.9249295,
    column_2: "Autre-Eglise",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6631583,
      lon: 4.9249295,
    },
  },
  {
    column_4: 50.7386182,
    column_3: 4.6962324,
    column_2: "Grez-Doiceau",
    column_1: "1390",
    geom: null,
    coordonnees: {
      lat: 50.7386182,
      lon: 4.6962324,
    },
  },
  {
    column_4: 50.6940942,
    column_3: 4.3548145440335,
    column_2: "Braine-L'alleud",
    column_1: "1420",
    geom: null,
    coordonnees: {
      lat: 50.6940942,
      lon: 4.35481454403,
    },
  },
  {
    column_4: 50.68083185,
    column_3: 4.3133036337455,
    column_2: "Wauthier-Braine",
    column_1: "1440",
    geom: null,
    coordonnees: {
      lat: 50.68083185,
      lon: 4.31330363375,
    },
  },
  {
    column_4: 50.57660545,
    column_3: 4.6202848419069,
    column_2: "Saint-Géry",
    column_1: "1450",
    geom: null,
    coordonnees: {
      lat: 50.57660545,
      lon: 4.62028484191,
    },
  },
  {
    column_4: 50.6407147,
    column_3: 4.6743285,
    column_2: "Nil-Saint-Vincent-Saint-Martin",
    column_1: "1457",
    geom: null,
    coordonnees: {
      lat: 50.6407147,
      lon: 4.6743285,
    },
  },
  {
    column_4: 50.6182838,
    column_3: 4.6952724,
    column_2: "Walhain",
    column_1: "1457",
    geom: null,
    coordonnees: {
      lat: 50.6182838,
      lon: 4.6952724,
    },
  },
  {
    column_4: 50.6167794,
    column_3: 4.6952241,
    column_2: "Walhain-Saint-Paul",
    column_1: "1457",
    geom: null,
    coordonnees: {
      lat: 50.6167794,
      lon: 4.6952241,
    },
  },
  {
    column_4: 50.6463367,
    column_3: 4.2611675641255,
    column_2: "Ittre",
    column_1: "1460",
    geom: null,
    coordonnees: {
      lat: 50.6463367,
      lon: 4.26116756413,
    },
  },
  {
    column_4: 50.5929226,
    column_3: 4.4319644959116,
    column_2: "Loupoigne",
    column_1: "1471",
    geom: null,
    coordonnees: {
      lat: 50.5929226,
      lon: 4.43196449591,
    },
  },
  {
    column_4: 50.69302,
    column_3: 4.204696,
    column_2: "Tubize",
    column_1: "1480",
    geom: null,
    coordonnees: {
      lat: 50.69302,
      lon: 4.204696,
    },
  },
  {
    column_4: 50.7527192,
    column_3: 3.9709983,
    column_2: "Galmaarden",
    column_1: "1570",
    geom: null,
    coordonnees: {
      lat: 50.7527192,
      lon: 3.9709983,
    },
  },
  {
    column_4: 50.7848617,
    column_3: 4.298610469732,
    column_2: "Ruisbroek",
    column_1: "1601",
    geom: null,
    coordonnees: {
      lat: 50.7848617,
      lon: 4.29861046973,
    },
  },
  {
    column_4: 50.7449476,
    column_3: 4.3281703413079,
    column_2: "Alsemberg",
    column_1: "1652",
    geom: null,
    coordonnees: {
      lat: 50.7449476,
      lon: 4.32817034131,
    },
  },
  {
    column_4: 50.7354888,
    column_3: 4.1853449364222,
    column_2: "Beert",
    column_1: "1673",
    geom: null,
    coordonnees: {
      lat: 50.7354888,
      lon: 4.18534493642,
    },
  },
  {
    column_4: 50.913594,
    column_3: 4.1804375072845,
    column_2: "Asse",
    column_1: "1730",
    geom: null,
    coordonnees: {
      lat: 50.913594,
      lon: 4.18043750728,
    },
  },
  {
    column_4: 50.9091629,
    column_3: 4.2496669,
    column_2: "Kobbegem",
    column_1: "1730",
    geom: null,
    coordonnees: {
      lat: 50.9091629,
      lon: 4.2496669,
    },
  },
  {
    column_4: 50.8842475,
    column_3: 4.2744185,
    column_2: "Zellik",
    column_1: "1731",
    geom: null,
    coordonnees: {
      lat: 50.8842475,
      lon: 4.2744185,
    },
  },
  {
    column_4: 50.81145495,
    column_3: 4.1456163063499,
    column_2: "Sint-Kwintens-Lennik",
    column_1: "1750",
    geom: null,
    coordonnees: {
      lat: 50.81145495,
      lon: 4.14561630635,
    },
  },
  {
    column_4: 50.7631284,
    column_3: 4.1212845,
    column_2: "Kester",
    column_1: "1755",
    geom: null,
    coordonnees: {
      lat: 50.7631284,
      lon: 4.1212845,
    },
  },
  {
    column_4: 50.7720719,
    column_3: 4.0614624,
    column_2: "Oetingen",
    column_1: "1755",
    geom: null,
    coordonnees: {
      lat: 50.7720719,
      lon: 4.0614624,
    },
  },
  {
    column_4: 50.83471975,
    column_3: 4.0820664787057,
    column_2: "Pamel",
    column_1: "1760",
    geom: null,
    coordonnees: {
      lat: 50.83471975,
      lon: 4.08206647871,
    },
  },
  {
    column_4: 50.8405541,
    column_3: 4.1145703,
    column_2: "Strijtem",
    column_1: "1760",
    geom: null,
    coordonnees: {
      lat: 50.8405541,
      lon: 4.1145703,
    },
  },
  {
    column_4: 50.8711313,
    column_3: 4.0860754,
    column_2: "Liedekerke",
    column_1: "1770",
    geom: null,
    coordonnees: {
      lat: 50.8711313,
      lon: 4.0860754,
    },
  },
  {
    column_4: 50.8945555,
    column_3: 4.4365042,
    column_2: "Diegem",
    column_1: "1831",
    geom: null,
    coordonnees: {
      lat: 50.8945555,
      lon: 4.4365042,
    },
  },
  {
    column_4: 50.9341625,
    column_3: 4.3287322,
    column_2: "Meise",
    column_1: "1860",
    geom: null,
    coordonnees: {
      lat: 50.9341625,
      lon: 4.3287322,
    },
  },
  {
    column_4: 50.9799835,
    column_3: 4.3512293,
    column_2: "Nieuwenrode",
    column_1: "1880",
    geom: null,
    coordonnees: {
      lat: 50.9799835,
      lon: 4.3512293,
    },
  },
  {
    column_4: 50.9190127,
    column_3: 4.5645096,
    column_2: "Nederokkerzeel",
    column_1: "1910",
    geom: null,
    coordonnees: {
      lat: 50.9190127,
      lon: 4.5645096,
    },
  },
  {
    column_4: 50.88499735,
    column_3: 4.5081025949464,
    column_2: "Nossegem",
    column_1: "1930",
    geom: null,
    coordonnees: {
      lat: 50.88499735,
      lon: 4.50810259495,
    },
  },
  {
    column_4: 50.8804355,
    column_3: 4.4745515,
    column_2: "Zaventem",
    column_1: "1930",
    geom: null,
    coordonnees: {
      lat: 50.8804355,
      lon: 4.4745515,
    },
  },
  {
    column_4: 50.85395875,
    column_3: 4.5157438438279,
    column_2: "Sterrebeek",
    column_1: "1933",
    geom: null,
    coordonnees: {
      lat: 50.85395875,
      lon: 4.51574384383,
    },
  },
  {
    column_4: 50.96953845,
    column_3: 4.5075638918541,
    column_2: "Elewijt",
    column_1: "1982",
    geom: null,
    coordonnees: {
      lat: 50.96953845,
      lon: 4.50756389185,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2000",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2050",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.2842045,
    column_3: 4.4322870782182,
    column_2: "Ekeren",
    column_1: "2180",
    geom: null,
    coordonnees: {
      lat: 51.2842045,
      lon: 4.43228707822,
    },
  },
  {
    column_4: 51.1855514,
    column_3: 4.83464460985,
    column_2: "Herentals",
    column_1: "2200",
    geom: null,
    coordonnees: {
      lat: 51.1855514,
      lon: 4.83464460985,
    },
  },
  {
    column_4: 51.2257319,
    column_3: 4.7033852099385,
    column_2: "Pulderbos",
    column_1: "2242",
    geom: null,
    coordonnees: {
      lat: 51.2257319,
      lon: 4.70338520994,
    },
  },
  {
    column_4: 51.2011609,
    column_3: 4.6975445276147,
    column_2: "Pulle",
    column_1: "2243",
    geom: null,
    coordonnees: {
      lat: 51.2011609,
      lon: 4.69754452761,
    },
  },
  {
    column_4: 51.26758,
    column_3: 4.8676797,
    column_2: "Gierle",
    column_1: "2275",
    geom: null,
    coordonnees: {
      lat: 51.26758,
      lon: 4.8676797,
    },
  },
  {
    column_4: 51.238218,
    column_3: 4.8242404,
    column_2: "Lille",
    column_1: "2275",
    geom: null,
    coordonnees: {
      lat: 51.238218,
      lon: 4.8242404,
    },
  },
  {
    column_4: 51.43155825,
    column_3: 4.8070790487489,
    column_2: "Minderhout",
    column_1: "2322",
    geom: null,
    coordonnees: {
      lat: 51.43155825,
      lon: 4.80707904875,
    },
  },
  {
    column_4: 51.3580597,
    column_3: 4.8627541,
    column_2: "Merksplas",
    column_1: "2330",
    geom: null,
    coordonnees: {
      lat: 51.3580597,
      lon: 4.8627541,
    },
  },
  {
    column_4: 51.3202315,
    column_3: 5.0864557,
    column_2: "Arendonk",
    column_1: "2370",
    geom: null,
    coordonnees: {
      lat: 51.3202315,
      lon: 5.0864557,
    },
  },
  {
    column_4: 51.3808625,
    column_3: 5.0198122919518,
    column_2: "Ravels",
    column_1: "2380",
    geom: null,
    coordonnees: {
      lat: 51.3808625,
      lon: 5.01981229195,
    },
  },
  {
    column_4: 51.0790117,
    column_3: 5.0188161,
    column_2: "Vorst",
    column_1: "2430",
    geom: null,
    coordonnees: {
      lat: 51.0790117,
      lon: 5.0188161,
    },
  },
  {
    column_4: 51.0801317,
    column_3: 4.9552253,
    column_2: "Varendonk",
    column_1: "2431",
    geom: null,
    coordonnees: {
      lat: 51.0801317,
      lon: 4.9552253,
    },
  },
  {
    column_4: 51.1317433,
    column_3: 5.0772388,
    column_2: "Meerhout",
    column_1: "2450",
    geom: null,
    coordonnees: {
      lat: 51.1317433,
      lon: 5.0772388,
    },
  },
  {
    column_4: 51.2675862,
    column_3: 5.0843883,
    column_2: "Retie",
    column_1: "2470",
    geom: null,
    coordonnees: {
      lat: 51.2675862,
      lon: 5.0843883,
    },
  },
  {
    column_4: 51.13368515,
    column_3: 5.1648214462792,
    column_2: "Olmen",
    column_1: "2491",
    geom: null,
    coordonnees: {
      lat: 51.13368515,
      lon: 5.16482144628,
    },
  },
  {
    column_4: 51.131069,
    column_3: 4.5696516,
    column_2: "Lier",
    column_1: "2500",
    geom: null,
    coordonnees: {
      lat: 51.131069,
      lon: 4.5696516,
    },
  },
  {
    column_4: 51.14453105,
    column_3: 4.6393456001407,
    column_2: "Kessel",
    column_1: "2560",
    geom: null,
    coordonnees: {
      lat: 51.14453105,
      lon: 4.63934560014,
    },
  },
  {
    column_4: 51.1611887,
    column_3: 4.6709517317049,
    column_2: "Nijlen",
    column_1: "2560",
    geom: null,
    coordonnees: {
      lat: 51.1611887,
      lon: 4.6709517317,
    },
  },
  {
    column_4: 51.10303735,
    column_3: 4.6640387634597,
    column_2: "Berlaar",
    column_1: "2590",
    geom: null,
    coordonnees: {
      lat: 51.10303735,
      lon: 4.66403876346,
    },
  },
  {
    column_4: 51.1442105,
    column_3: 4.3420555,
    column_2: "Hemiksem",
    column_1: "2620",
    geom: null,
    coordonnees: {
      lat: 51.1442105,
      lon: 4.3420555,
    },
  },
  {
    column_4: 51.02803335,
    column_3: 4.471321199996,
    column_2: "Mechelen",
    column_1: "2800",
    geom: null,
    coordonnees: {
      lat: 51.02803335,
      lon: 4.4713212,
    },
  },
  {
    column_4: 51.01166405,
    column_3: 4.4211910518318,
    column_2: "Hombeek",
    column_1: "2811",
    geom: null,
    coordonnees: {
      lat: 51.01166405,
      lon: 4.42119105183,
    },
  },
  {
    column_4: 51.0261294,
    column_3: 4.5378217779072,
    column_2: "Bonheiden",
    column_1: "2820",
    geom: null,
    coordonnees: {
      lat: 51.0261294,
      lon: 4.53782177791,
    },
  },
  {
    column_4: 51.0010818,
    column_3: 4.5842221,
    column_2: "Rijmenam",
    column_1: "2820",
    geom: null,
    coordonnees: {
      lat: 51.0010818,
      lon: 4.5842221,
    },
  },
  {
    column_4: 51.0565194,
    column_3: 4.3723517,
    column_2: "Blaasveld",
    column_1: "2830",
    geom: null,
    coordonnees: {
      lat: 51.0565194,
      lon: 4.3723517,
    },
  },
  {
    column_4: 51.0670264,
    column_3: 4.4066584,
    column_2: "Heindonk",
    column_1: "2830",
    geom: null,
    coordonnees: {
      lat: 51.0670264,
      lon: 4.4066584,
    },
  },
  {
    column_4: 51.033992,
    column_3: 4.3598407,
    column_2: "Tisselt",
    column_1: "2830",
    geom: null,
    coordonnees: {
      lat: 51.033992,
      lon: 4.3598407,
    },
  },
  {
    column_4: 51.0626964,
    column_3: 4.3572324644033,
    column_2: "Willebroek",
    column_1: "2830",
    geom: null,
    coordonnees: {
      lat: 51.0626964,
      lon: 4.3572324644,
    },
  },
  {
    column_4: 51.1044854,
    column_3: 4.4058899586301,
    column_2: "Reet",
    column_1: "2840",
    geom: null,
    coordonnees: {
      lat: 51.1044854,
      lon: 4.40588995863,
    },
  },
  {
    column_4: 51.0873789,
    column_3: 4.3667216,
    column_2: "Boom",
    column_1: "2850",
    geom: null,
    coordonnees: {
      lat: 51.0873789,
      lon: 4.3667216,
    },
  },
  {
    column_4: 51.0624913,
    column_3: 4.5799614,
    column_2: "Onze-Lieve-Vrouw-Waver",
    column_1: "2861",
    geom: null,
    coordonnees: {
      lat: 51.0624913,
      lon: 4.5799614,
    },
  },
  {
    column_4: 51.0452687,
    column_3: 4.3136108625549,
    column_2: "Breendonk",
    column_1: "2870",
    geom: null,
    coordonnees: {
      lat: 51.0452687,
      lon: 4.31361086255,
    },
  },
  {
    column_4: 51.3832723,
    column_3: 4.4763255,
    column_2: "Kalmthout",
    column_1: "2920",
    geom: null,
    coordonnees: {
      lat: 51.3832723,
      lon: 4.4763255,
    },
  },
  {
    column_4: 51.3066168,
    column_3: 4.4046996,
    column_2: "Hoevenen",
    column_1: "2940",
    geom: null,
    coordonnees: {
      lat: 51.3066168,
      lon: 4.4046996,
    },
  },
  {
    column_4: 51.2624856,
    column_3: 4.5556038,
    column_2: "'s Gravenwezel",
    column_1: "2970",
    geom: null,
    coordonnees: {
      lat: 51.2624856,
      lon: 4.5556038,
    },
  },
  {
    column_4: 50.88991545,
    column_3: 4.7307614946269,
    column_2: "Kessel Lo",
    column_1: "3010",
    geom: null,
    coordonnees: {
      lat: 50.88991545,
      lon: 4.73076149463,
    },
  },
  {
    column_4: 50.9079329,
    column_3: 4.672714,
    column_2: "Herent",
    column_1: "3020",
    geom: null,
    coordonnees: {
      lat: 50.9079329,
      lon: 4.672714,
    },
  },
  {
    column_4: 50.81115135,
    column_3: 4.6290291125613,
    column_2: "Neerijse",
    column_1: "3040",
    geom: null,
    coordonnees: {
      lat: 50.81115135,
      lon: 4.62902911256,
    },
  },
  {
    column_4: 50.88326665,
    column_3: 4.5364707039624,
    column_2: "Kortenberg",
    column_1: "3070",
    geom: null,
    coordonnees: {
      lat: 50.88326665,
      lon: 4.53647070396,
    },
  },
  {
    column_4: 50.8782935,
    column_3: 4.5972692536847,
    column_2: "Meerbeek",
    column_1: "3078",
    geom: null,
    coordonnees: {
      lat: 50.8782935,
      lon: 4.59726925368,
    },
  },
  {
    column_4: 50.8227718,
    column_3: 4.5144027,
    column_2: "Tervuren",
    column_1: "3080",
    geom: null,
    coordonnees: {
      lat: 50.8227718,
      lon: 4.5144027,
    },
  },
  {
    column_4: 50.94081955,
    column_3: 4.6595165776069,
    column_2: "Tildonk",
    column_1: "3150",
    geom: null,
    coordonnees: {
      lat: 50.94081955,
      lon: 4.65951657761,
    },
  },
  {
    column_4: 50.9670761,
    column_3: 4.8042730440984,
    column_2: "Gelrode",
    column_1: "3200",
    geom: null,
    coordonnees: {
      lat: 50.9670761,
      lon: 4.8042730441,
    },
  },
  {
    column_4: 50.9208131,
    column_3: 4.8291872982184,
    column_2: "Sint-Pieters-Rode",
    column_1: "3220",
    geom: null,
    coordonnees: {
      lat: 50.9208131,
      lon: 4.82918729822,
    },
  },
  {
    column_4: 50.7846322,
    column_3: 4.9339239586832,
    column_2: "Bost",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.7846322,
      lon: 4.93392395868,
    },
  },
  {
    column_4: 50.79215565,
    column_3: 4.9137602382894,
    column_2: "Oorbeek",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.79215565,
      lon: 4.91376023829,
    },
  },
  {
    column_4: 50.84379005,
    column_3: 4.9111431209758,
    column_2: "Vissenaken",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.84379005,
      lon: 4.91114312098,
    },
  },
  {
    column_4: 50.8291537,
    column_3: 5.0409717,
    column_2: "Linter",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.8291537,
      lon: 5.0409717,
    },
  },
  {
    column_4: 50.8249402,
    column_3: 5.0585292480567,
    column_2: "Melkwezer",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.8249402,
      lon: 5.05852924806,
    },
  },
  {
    column_4: 50.86176115,
    column_3: 4.7561775068454,
    column_2: "Korbeek-Lo",
    column_1: "3360",
    geom: null,
    coordonnees: {
      lat: 50.86176115,
      lon: 4.75617750685,
    },
  },
  {
    column_4: 50.8510351,
    column_3: 4.9601107928553,
    column_2: "Bunsbeek",
    column_1: "3380",
    geom: null,
    coordonnees: {
      lat: 50.8510351,
      lon: 4.96011079286,
    },
  },
  {
    column_4: 50.8694607,
    column_3: 4.9167554140969,
    column_2: "Attenrode",
    column_1: "3384",
    geom: null,
    coordonnees: {
      lat: 50.8694607,
      lon: 4.9167554141,
    },
  },
  {
    column_4: 50.8950541,
    column_3: 4.9173431325348,
    column_2: "Meensel-Kiezegem",
    column_1: "3391",
    geom: null,
    coordonnees: {
      lat: 50.8950541,
      lon: 4.91734313253,
    },
  },
  {
    column_4: 50.7766242,
    column_3: 4.997333783131,
    column_2: "Ezemaal",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.7766242,
      lon: 4.99733378313,
    },
  },
  {
    column_4: 50.76917005,
    column_3: 5.0695564232804,
    column_2: "Rumsdorp",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.76917005,
      lon: 5.06955642328,
    },
  },
  {
    column_4: 50.83603965,
    column_3: 5.0802259143417,
    column_2: "Helen-Bos",
    column_1: "3440",
    geom: null,
    coordonnees: {
      lat: 50.83603965,
      lon: 5.08022591434,
    },
  },
  {
    column_4: 50.8668096,
    column_3: 5.0014628020046,
    column_2: "Hoeleden",
    column_1: "3471",
    geom: null,
    coordonnees: {
      lat: 50.8668096,
      lon: 5.001462802,
    },
  },
  {
    column_4: 50.9524495,
    column_3: 5.2509784643733,
    column_2: "Kermt",
    column_1: "3510",
    geom: null,
    coordonnees: {
      lat: 50.9524495,
      lon: 5.25097846437,
    },
  },
  {
    column_4: 51.0281818,
    column_3: 5.3713044,
    column_2: "Houthalen-Helchteren",
    column_1: "3530",
    geom: null,
    coordonnees: {
      lat: 51.0281818,
      lon: 5.3713044,
    },
  },
  {
    column_4: 50.9222911,
    column_3: 5.1884301868953,
    column_2: "Herk-De-Stad",
    column_1: "3540",
    geom: null,
    coordonnees: {
      lat: 50.9222911,
      lon: 5.1884301869,
    },
  },
  {
    column_4: 50.9533364,
    column_3: 5.171672949688,
    column_2: "Schulen",
    column_1: "3540",
    geom: null,
    coordonnees: {
      lat: 50.9533364,
      lon: 5.17167294969,
    },
  },
  {
    column_4: 51.0502026,
    column_3: 5.2208090823008,
    column_2: "Beringen",
    column_1: "3580",
    geom: null,
    coordonnees: {
      lat: 51.0502026,
      lon: 5.2208090823,
    },
  },
  {
    column_4: 50.8839456,
    column_3: 5.6091315,
    column_2: "Gellik",
    column_1: "3620",
    geom: null,
    coordonnees: {
      lat: 50.8839456,
      lon: 5.6091315,
    },
  },
  {
    column_4: 50.907778,
    column_3: 5.680982,
    column_2: "Neerharen",
    column_1: "3620",
    geom: null,
    coordonnees: {
      lat: 50.907778,
      lon: 5.680982,
    },
  },
  {
    column_4: 50.9764002,
    column_3: 5.6456979557208,
    column_2: "Mechelen-Aan-De-Maas",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9764002,
      lon: 5.64569795572,
    },
  },
  {
    column_4: 50.9408858,
    column_3: 5.7147232,
    column_2: "Boorsem",
    column_1: "3631",
    geom: null,
    coordonnees: {
      lat: 50.9408858,
      lon: 5.7147232,
    },
  },
  {
    column_4: 51.14313325,
    column_3: 5.7414564606985,
    column_2: "Kinrooi",
    column_1: "3640",
    geom: null,
    coordonnees: {
      lat: 51.14313325,
      lon: 5.7414564607,
    },
  },
  {
    column_4: 51.1303479,
    column_3: 5.5289463613944,
    column_2: "Ellikom",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.1303479,
      lon: 5.52894636139,
    },
  },
  {
    column_4: 51.09677995,
    column_3: 5.6181697688959,
    column_2: "Neerglabbeek",
    column_1: "3670",
    geom: null,
    coordonnees: {
      lat: 51.09677995,
      lon: 5.6181697689,
    },
  },
  {
    column_4: 50.9318992,
    column_3: 5.5725548,
    column_2: "Zutendaal",
    column_1: "3690",
    geom: null,
    coordonnees: {
      lat: 50.9318992,
      lon: 5.5725548,
    },
  },
  {
    column_4: 50.7399124,
    column_3: 5.4147831,
    column_2: "Lauw",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7399124,
      lon: 5.4147831,
    },
  },
  {
    column_4: 50.8121319,
    column_3: 5.4449386,
    column_2: "Neerrepen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8121319,
      lon: 5.4449386,
    },
  },
  {
    column_4: 50.7873928,
    column_3: 5.4198462,
    column_2: "Piringen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7873928,
      lon: 5.4198462,
    },
  },
  {
    column_4: 50.7520293,
    column_3: 5.495696,
    column_2: "Vreren",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7520293,
      lon: 5.495696,
    },
  },
  {
    column_4: 50.7719092,
    column_3: 5.409701,
    column_2: "Widooie",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7719092,
      lon: 5.409701,
    },
  },
  {
    column_4: 50.7273829,
    column_3: 5.4260923,
    column_2: "Herstappe",
    column_1: "3717",
    geom: null,
    coordonnees: {
      lat: 50.7273829,
      lon: 5.4260923,
    },
  },
  {
    column_4: 50.84255235,
    column_3: 5.4005632276197,
    column_2: "Guigoven",
    column_1: "3723",
    geom: null,
    coordonnees: {
      lat: 50.84255235,
      lon: 5.40056322762,
    },
  },
  {
    column_4: 50.8326029,
    column_3: 5.4259091735787,
    column_2: "Vliermaal",
    column_1: "3724",
    geom: null,
    coordonnees: {
      lat: 50.8326029,
      lon: 5.42590917358,
    },
  },
  {
    column_4: 50.8335665,
    column_3: 5.4804957,
    column_2: "Werm",
    column_1: "3730",
    geom: null,
    coordonnees: {
      lat: 50.8335665,
      lon: 5.4804957,
    },
  },
  {
    column_4: 50.832524,
    column_3: 5.5538483,
    column_2: "Grote-Spouwen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.832524,
      lon: 5.5538483,
    },
  },
  {
    column_4: 50.8178133,
    column_3: 5.5391564,
    column_2: "Membruggen",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8178133,
      lon: 5.5391564,
    },
  },
  {
    column_4: 50.8329146,
    column_3: 5.5895873,
    column_2: "Vlijtingen",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8329146,
      lon: 5.5895873,
    },
  },
  {
    column_4: 50.74087835,
    column_3: 5.8429342279842,
    column_2: "Fouron-Saint-Martin",
    column_1: "3790",
    geom: null,
    coordonnees: {
      lat: 50.74087835,
      lon: 5.84293422798,
    },
  },
  {
    column_4: 50.7465898,
    column_3: 5.839052712207,
    column_2: "Fourons",
    column_1: "3790",
    geom: null,
    coordonnees: {
      lat: 50.7465898,
      lon: 5.83905271221,
    },
  },
  {
    column_4: 50.72830265,
    column_3: 5.8242427123549,
    column_2: "Fouron-Saint-Pierre",
    column_1: "3792",
    geom: null,
    coordonnees: {
      lat: 50.72830265,
      lon: 5.82424271235,
    },
  },
  {
    column_4: 50.75322225,
    column_3: 5.8695304694514,
    column_2: "Teuven",
    column_1: "3793",
    geom: null,
    coordonnees: {
      lat: 50.75322225,
      lon: 5.86953046945,
    },
  },
  {
    column_4: 50.840318,
    column_3: 5.2579163290148,
    column_2: "Zepperen",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.840318,
      lon: 5.25791632901,
    },
  },
  {
    column_4: 50.82704785,
    column_3: 5.3322359627296,
    column_2: "Herten",
    column_1: "3831",
    geom: null,
    coordonnees: {
      lat: 50.82704785,
      lon: 5.33223596273,
    },
  },
  {
    column_4: 50.7819071,
    column_3: 5.3324575,
    column_2: "Broekom",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.7819071,
      lon: 5.3324575,
    },
  },
  {
    column_4: 50.8027416,
    column_3: 5.4206775,
    column_2: "Kolmont",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.8027416,
      lon: 5.4206775,
    },
  },
  {
    column_4: 50.8140833,
    column_3: 5.3310338354044,
    column_2: "Kuttekoven",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.8140833,
      lon: 5.3310338354,
    },
  },
  {
    column_4: 50.86548295,
    column_3: 5.1684479293466,
    column_2: "Binderveld",
    column_1: "3850",
    geom: null,
    coordonnees: {
      lat: 50.86548295,
      lon: 5.16844792935,
    },
  },
  {
    column_4: 50.8752909,
    column_3: 5.2414389,
    column_2: "Kozen",
    column_1: "3850",
    geom: null,
    coordonnees: {
      lat: 50.8752909,
      lon: 5.2414389,
    },
  },
  {
    column_4: 50.87457545,
    column_3: 5.1909741787579,
    column_2: "Nieuwerkerken",
    column_1: "3850",
    geom: null,
    coordonnees: {
      lat: 50.87457545,
      lon: 5.19097417876,
    },
  },
  {
    column_4: 50.7803053,
    column_3: 5.2896635,
    column_2: "Mettekoven",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7803053,
      lon: 5.2896635,
    },
  },
  {
    column_4: 50.7513838,
    column_3: 5.1325789475434,
    column_2: "Gingelom",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.7513838,
      lon: 5.13257894754,
    },
  },
  {
    column_4: 50.7052793,
    column_3: 5.1485502564418,
    column_2: "Kortijs",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.7052793,
      lon: 5.14855025644,
    },
  },
  {
    column_4: 50.71838125,
    column_3: 5.1386239079133,
    column_2: "Montenaken",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.71838125,
      lon: 5.13862390791,
    },
  },
  {
    column_4: 50.7345174,
    column_3: 5.1743785728014,
    column_2: "Borlo",
    column_1: "3891",
    geom: null,
    coordonnees: {
      lat: 50.7345174,
      lon: 5.1743785728,
    },
  },
  {
    column_4: 51.2305657,
    column_3: 5.3076895,
    column_2: "Lommel",
    column_1: "3920",
    geom: null,
    coordonnees: {
      lat: 51.2305657,
      lon: 5.3076895,
    },
  },
  {
    column_4: 51.11664635,
    column_3: 5.3415643775263,
    column_2: "Hechtel-Eksel",
    column_1: "3940",
    geom: null,
    coordonnees: {
      lat: 51.11664635,
      lon: 5.34156437753,
    },
  },
  {
    column_4: 51.11417145,
    column_3: 5.1781109743218,
    column_2: "Ham",
    column_1: "3945",
    geom: null,
    coordonnees: {
      lat: 51.11417145,
      lon: 5.17811097432,
    },
  },
  {
    column_4: 51.1386239,
    column_3: 5.6229805058514,
    column_2: "Bree",
    column_1: "3960",
    geom: null,
    coordonnees: {
      lat: 51.1386239,
      lon: 5.62298050585,
    },
  },
  {
    column_4: 51.11582725,
    column_3: 5.2265355962879,
    column_2: "Heppen",
    column_1: "3971",
    geom: null,
    coordonnees: {
      lat: 51.11582725,
      lon: 5.22653559629,
    },
  },
  {
    column_4: 50.6128164,
    column_3: 5.5954054,
    column_2: "Angleur",
    column_1: "4031",
    geom: null,
    coordonnees: {
      lat: 50.6128164,
      lon: 5.5954054,
    },
  },
  {
    column_4: 50.6904632,
    column_3: 5.5929155,
    column_2: "Milmort",
    column_1: "4041",
    geom: null,
    coordonnees: {
      lat: 50.6904632,
      lon: 5.5929155,
    },
  },
  {
    column_4: 50.5992632,
    column_3: 5.5389943,
    column_2: "Ougrée",
    column_1: "4102",
    geom: null,
    coordonnees: {
      lat: 50.5992632,
      lon: 5.5389943,
    },
  },
  {
    column_4: 50.5431751,
    column_3: 5.4901079,
    column_2: "Neupré",
    column_1: "4120",
    geom: null,
    coordonnees: {
      lat: 50.5431751,
      lon: 5.4901079,
    },
  },
  {
    column_4: 50.5353385,
    column_3: 5.4811796,
    column_2: "Rotheux-Rimière",
    column_1: "4120",
    geom: null,
    coordonnees: {
      lat: 50.5353385,
      lon: 5.4811796,
    },
  },
  {
    column_4: 50.4869156,
    column_3: 5.5009881,
    column_2: "Hody",
    column_1: "4162",
    geom: null,
    coordonnees: {
      lat: 50.4869156,
      lon: 5.5009881,
    },
  },
  {
    column_4: 50.474910743586,
    column_3: 5.5764707268884,
    column_2: "Comblain-Au-Pont",
    column_1: "4170",
    geom: null,
    coordonnees: {
      lat: 50.4749107436,
      lon: 5.57647072689,
    },
  },
  {
    column_4: 50.3999541,
    column_3: 5.605138,
    column_2: "Ferrières",
    column_1: "4190",
    geom: null,
    coordonnees: {
      lat: 50.3999541,
      lon: 5.605138,
    },
  },
  {
    column_4: 50.5508203,
    column_3: 5.0971783,
    column_2: "Héron",
    column_1: "4217",
    geom: null,
    coordonnees: {
      lat: 50.5508203,
      lon: 5.0971783,
    },
  },
  {
    column_4: 50.5557893,
    column_3: 5.0671316,
    column_2: "Waret-L'evêque",
    column_1: "4217",
    geom: null,
    coordonnees: {
      lat: 50.5557893,
      lon: 5.0671316,
    },
  },
  {
    column_4: 50.6841901,
    column_3: 5.1679183,
    column_2: "Boëlhe",
    column_1: "4250",
    geom: null,
    coordonnees: {
      lat: 50.6841901,
      lon: 5.1679183,
    },
  },
  {
    column_4: 50.6637114,
    column_3: 5.16091,
    column_2: "Lens-Saint-Servais",
    column_1: "4250",
    geom: null,
    coordonnees: {
      lat: 50.6637114,
      lon: 5.16091,
    },
  },
  {
    column_4: 50.586887,
    column_3: 5.184607,
    column_2: "Fumal",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.586887,
      lon: 5.184607,
    },
  },
  {
    column_4: 50.6720609,
    column_3: 5.1498313,
    column_2: "Abolens",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6720609,
      lon: 5.1498313,
    },
  },
  {
    column_4: 50.620859,
    column_3: 5.067671,
    column_2: "Avin",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.620859,
      lon: 5.067671,
    },
  },
  {
    column_4: 50.6629289,
    column_3: 5.1255375,
    column_2: "Blehen",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6629289,
      lon: 5.1255375,
    },
  },
  {
    column_4: 50.6941211,
    column_3: 5.0340611,
    column_2: "Grand-Hallet",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6941211,
      lon: 5.0340611,
    },
  },
  {
    column_4: 50.656503,
    column_3: 5.09579,
    column_2: "Villers-Le-Peuplier",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.656503,
      lon: 5.09579,
    },
  },
  {
    column_4: 50.6789987,
    column_3: 5.2243853,
    column_2: "Grand-Axhe",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.6789987,
      lon: 5.2243853,
    },
  },
  {
    column_4: 50.7114397,
    column_3: 5.2947196,
    column_2: "Lantremange",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.7114397,
      lon: 5.2947196,
    },
  },
  {
    column_4: 50.7125474,
    column_3: 5.2808546,
    column_2: "Oleye",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.7125474,
      lon: 5.2808546,
    },
  },
  {
    column_4: 50.665246,
    column_3: 5.262322,
    column_2: "Faimes",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.665246,
      lon: 5.262322,
    },
  },
  {
    column_4: 50.6762489,
    column_3: 5.4376155,
    column_2: "Fooz",
    column_1: "4340",
    geom: null,
    coordonnees: {
      lat: 50.6762489,
      lon: 5.4376155,
    },
  },
  {
    column_4: 50.7028423,
    column_3: 5.4418773,
    column_2: "Villers-L'evêque",
    column_1: "4340",
    geom: null,
    coordonnees: {
      lat: 50.7028423,
      lon: 5.4418773,
    },
  },
  {
    column_4: 50.6808099,
    column_3: 5.4556394,
    column_2: "Hognoul",
    column_1: "4342",
    geom: null,
    coordonnees: {
      lat: 50.6808099,
      lon: 5.4556394,
    },
  },
  {
    column_4: 50.6660818,
    column_3: 5.3985197,
    column_2: "Fexhe-Le-Haut-Clocher",
    column_1: "4347",
    geom: null,
    coordonnees: {
      lat: 50.6660818,
      lon: 5.3985197,
    },
  },
  {
    column_4: 50.6803347,
    column_3: 5.4066762,
    column_2: "Freloux",
    column_1: "4347",
    geom: null,
    coordonnees: {
      lat: 50.6803347,
      lon: 5.4066762,
    },
  },
  {
    column_4: 50.6956401,
    column_3: 5.3034495,
    column_2: "Pousset",
    column_1: "4350",
    geom: null,
    coordonnees: {
      lat: 50.6956401,
      lon: 5.3034495,
    },
  },
  {
    column_4: 50.6600262,
    column_3: 5.3107335,
    column_2: "Limont",
    column_1: "4357",
    geom: null,
    coordonnees: {
      lat: 50.6600262,
      lon: 5.3107335,
    },
  },
  {
    column_4: 50.7145424,
    column_3: 5.3277139,
    column_2: "Bergilers",
    column_1: "4360",
    geom: null,
    coordonnees: {
      lat: 50.7145424,
      lon: 5.3277139,
    },
  },
  {
    column_4: 50.701353,
    column_3: 5.386298,
    column_2: "Fize-Le-Marsal",
    column_1: "4367",
    geom: null,
    coordonnees: {
      lat: 50.701353,
      lon: 5.386298,
    },
  },
  {
    column_4: 50.5985223,
    column_3: 5.409295,
    column_2: "Awirs",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.5985223,
      lon: 5.409295,
    },
  },
  {
    column_4: 50.6155715,
    column_3: 5.4729319,
    column_2: "Flémalle-Grande",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.6155715,
      lon: 5.4729319,
    },
  },
  {
    column_4: 50.64257,
    column_3: 5.5190161,
    column_2: "Montegnée",
    column_1: "4420",
    geom: null,
    coordonnees: {
      lat: 50.64257,
      lon: 5.5190161,
    },
  },
  {
    column_4: 50.7106926,
    column_3: 5.5290689,
    column_2: "Juprelle",
    column_1: "4450",
    geom: null,
    coordonnees: {
      lat: 50.7106926,
      lon: 5.5290689,
    },
  },
  {
    column_4: 50.7277246,
    column_3: 5.5652092,
    column_2: "Slins",
    column_1: "4450",
    geom: null,
    coordonnees: {
      lat: 50.7277246,
      lon: 5.5652092,
    },
  },
  {
    column_4: 50.7097826,
    column_3: 5.5434045,
    column_2: "Villers-Saint-Siméon",
    column_1: "4453",
    geom: null,
    coordonnees: {
      lat: 50.7097826,
      lon: 5.5434045,
    },
  },
  {
    column_4: 50.6547149,
    column_3: 5.4512607,
    column_2: "Bierset",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.6547149,
      lon: 5.4512607,
    },
  },
  {
    column_4: 50.5033196,
    column_3: 5.1773498,
    column_2: "Ben-Ahin",
    column_1: "4500",
    geom: null,
    coordonnees: {
      lat: 50.5033196,
      lon: 5.1773498,
    },
  },
  {
    column_4: 50.5215385,
    column_3: 5.2357453,
    column_2: "Huy",
    column_1: "4500",
    geom: null,
    coordonnees: {
      lat: 50.5215385,
      lon: 5.2357453,
    },
  },
  {
    column_4: 50.56759,
    column_3: 5.1647622,
    column_2: "Huccorgne",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.56759,
      lon: 5.1647622,
    },
  },
  {
    column_4: 50.5471619,
    column_3: 5.1878867,
    column_2: "Moha",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.5471619,
      lon: 5.1878867,
    },
  },
  {
    column_4: 50.6131537,
    column_3: 5.23141,
    column_2: "Vaux-Et-Borset",
    column_1: "4530",
    geom: null,
    coordonnees: {
      lat: 50.6131537,
      lon: 5.23141,
    },
  },
  {
    column_4: 50.5750242,
    column_3: 5.2619239,
    column_2: "Villers-Le-Bouillet",
    column_1: "4530",
    geom: null,
    coordonnees: {
      lat: 50.5750242,
      lon: 5.2619239,
    },
  },
  {
    column_4: 50.609673,
    column_3: 5.2734472,
    column_2: "Chapon-Seraing",
    column_1: "4537",
    geom: null,
    coordonnees: {
      lat: 50.609673,
      lon: 5.2734472,
    },
  },
  {
    column_4: 50.5582387,
    column_3: 5.3354736,
    column_2: "Flône",
    column_1: "4540",
    geom: null,
    coordonnees: {
      lat: 50.5582387,
      lon: 5.3354736,
    },
  },
  {
    column_4: 50.5442809,
    column_3: 5.3371708,
    column_2: "Ombret",
    column_1: "4540",
    geom: null,
    coordonnees: {
      lat: 50.5442809,
      lon: 5.3371708,
    },
  },
  {
    column_4: 50.506355,
    column_3: 5.4190411,
    column_2: "Nandrin",
    column_1: "4550",
    geom: null,
    coordonnees: {
      lat: 50.506355,
      lon: 5.4190411,
    },
  },
  {
    column_4: 50.478693,
    column_3: 5.4196671,
    column_2: "Fraiture",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.478693,
      lon: 5.4196671,
    },
  },
  {
    column_4: 50.4644497,
    column_3: 5.3286278,
    column_2: "Ramelot",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.4644497,
      lon: 5.3286278,
    },
  },
  {
    column_4: 50.4794492,
    column_3: 5.3766541,
    column_2: "Soheit-Tinlot",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.4794492,
      lon: 5.3766541,
    },
  },
  {
    column_4: 50.4115085,
    column_3: 5.3575692,
    column_2: "Clavier",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.4115085,
      lon: 5.3575692,
    },
  },
  {
    column_4: 50.4438855,
    column_3: 5.3491827,
    column_2: "Terwagne",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.4438855,
      lon: 5.3491827,
    },
  },
  {
    column_4: 50.4462845,
    column_3: 5.2683004,
    column_2: "Vyle-Et-Tharoul",
    column_1: "4570",
    geom: null,
    coordonnees: {
      lat: 50.4462845,
      lon: 5.2683004,
    },
  },
  {
    column_4: 50.4664238,
    column_3: 5.442913,
    column_2: "Ellemelle",
    column_1: "4590",
    geom: null,
    coordonnees: {
      lat: 50.4664238,
      lon: 5.442913,
    },
  },
  {
    column_4: 50.7171434,
    column_3: 5.695354,
    column_2: "Richelle",
    column_1: "4600",
    geom: null,
    coordonnees: {
      lat: 50.7171434,
      lon: 5.695354,
    },
  },
  {
    column_4: 50.730153,
    column_3: 5.7429371,
    column_2: "Bombaye",
    column_1: "4607",
    geom: null,
    coordonnees: {
      lat: 50.730153,
      lon: 5.7429371,
    },
  },
  {
    column_4: 50.6220566,
    column_3: 5.6534141,
    column_2: "Beyne-Heusay",
    column_1: "4610",
    geom: null,
    coordonnees: {
      lat: 50.6220566,
      lon: 5.6534141,
    },
  },
  {
    column_4: 50.6315865,
    column_3: 5.7355403,
    column_2: "Micheroux",
    column_1: "4630",
    geom: null,
    coordonnees: {
      lat: 50.6315865,
      lon: 5.7355403,
    },
  },
  {
    column_4: 50.6482697,
    column_3: 5.8194813,
    column_2: "Battice",
    column_1: "4651",
    geom: null,
    coordonnees: {
      lat: 50.6482697,
      lon: 5.8194813,
    },
  },
  {
    column_4: 50.6614055,
    column_3: 5.7594413,
    column_2: "Bolland",
    column_1: "4653",
    geom: null,
    coordonnees: {
      lat: 50.6614055,
      lon: 5.7594413,
    },
  },
  {
    column_4: 50.6727185,
    column_3: 5.7252372,
    column_2: "Blégny",
    column_1: "4670",
    geom: null,
    coordonnees: {
      lat: 50.6727185,
      lon: 5.7252372,
    },
  },
  {
    column_4: 50.6821815,
    column_3: 5.7432715,
    column_2: "Mortier",
    column_1: "4670",
    geom: null,
    coordonnees: {
      lat: 50.6821815,
      lon: 5.7432715,
    },
  },
  {
    column_4: 50.6796259,
    column_3: 5.693985,
    column_2: "Housse",
    column_1: "4671",
    geom: null,
    coordonnees: {
      lat: 50.6796259,
      lon: 5.693985,
    },
  },
  {
    column_4: 50.7087526,
    column_3: 5.6186068,
    column_2: "Hermée",
    column_1: "4680",
    geom: null,
    coordonnees: {
      lat: 50.7087526,
      lon: 5.6186068,
    },
  },
  {
    column_4: 50.7391753,
    column_3: 5.6104199,
    column_2: "Houtain-Saint-Siméon",
    column_1: "4682",
    geom: null,
    coordonnees: {
      lat: 50.7391753,
      lon: 5.6104199,
    },
  },
  {
    column_4: 50.7539251,
    column_3: 5.5788622,
    column_2: "Boirs",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.7539251,
      lon: 5.5788622,
    },
  },
  {
    column_4: 50.7994409,
    column_3: 5.6702417,
    column_2: "Eben-Emael",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.7994409,
      lon: 5.6702417,
    },
  },
  {
    column_4: 50.7518981,
    column_3: 5.5398432,
    column_2: "Glons",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.7518981,
      lon: 5.5398432,
    },
  },
  {
    column_4: 50.756864,
    column_3: 5.59407,
    column_2: "Roclenge-Sur-Geer",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.756864,
      lon: 5.59407,
    },
  },
  {
    column_4: 50.6804233,
    column_3: 6.0073447,
    column_2: "Lontzen",
    column_1: "4710",
    geom: null,
    coordonnees: {
      lat: 50.6804233,
      lon: 6.0073447,
    },
  },
  {
    column_4: 50.6750768,
    column_3: 6.0469422,
    column_2: "Walhorn",
    column_1: "4711",
    geom: null,
    coordonnees: {
      lat: 50.6750768,
      lon: 6.0469422,
    },
  },
  {
    column_4: 50.6761305,
    column_3: 6.1108404,
    column_2: "Raeren",
    column_1: "4730",
    geom: null,
    coordonnees: {
      lat: 50.6761305,
      lon: 6.1108404,
    },
  },
  {
    column_4: 50.4267259,
    column_3: 6.2053573,
    column_2: "Butgenbach",
    column_1: "4750",
    geom: null,
    coordonnees: {
      lat: 50.4267259,
      lon: 6.2053573,
    },
  },
  {
    column_4: 50.3288415,
    column_3: 6.1884932,
    column_2: "Meyerode",
    column_1: "4770",
    geom: null,
    coordonnees: {
      lat: 50.3288415,
      lon: 6.1884932,
    },
  },
  {
    column_4: 50.2190586,
    column_3: 6.0743425,
    column_2: "Thommen",
    column_1: "4791",
    geom: null,
    coordonnees: {
      lat: 50.2190586,
      lon: 6.0743425,
    },
  },
  {
    column_4: 49.7051899,
    column_3: 5.191001,
    column_2: "Lambermont",
    column_1: "4800",
    geom: null,
    coordonnees: {
      lat: 49.7051899,
      lon: 5.191001,
    },
  },
  {
    column_4: 50.593109,
    column_3: 5.8952801,
    column_2: "Stembert",
    column_1: "4801",
    geom: null,
    coordonnees: {
      lat: 50.593109,
      lon: 5.8952801,
    },
  },
  {
    column_4: 50.623535,
    column_3: 5.9212234,
    column_2: "Bilstain",
    column_1: "4831",
    geom: null,
    coordonnees: {
      lat: 50.623535,
      lon: 5.9212234,
    },
  },
  {
    column_4: 50.6627086,
    column_3: 5.9723396,
    column_2: "Welkenraedt",
    column_1: "4840",
    geom: null,
    coordonnees: {
      lat: 50.6627086,
      lon: 5.9723396,
    },
  },
  {
    column_4: 50.7374801,
    column_3: 5.9590725,
    column_2: "Plombières",
    column_1: "4850",
    geom: null,
    coordonnees: {
      lat: 50.7374801,
      lon: 5.9590725,
    },
  },
  {
    column_4: 50.5675217,
    column_3: 5.8037101,
    column_2: "Pepinster",
    column_1: "4860",
    geom: null,
    coordonnees: {
      lat: 50.5675217,
      lon: 5.8037101,
    },
  },
  {
    column_4: 50.573671,
    column_3: 5.7371309,
    column_2: "Nessonvaux",
    column_1: "4870",
    geom: null,
    coordonnees: {
      lat: 50.573671,
      lon: 5.7371309,
    },
  },
  {
    column_4: 50.5728102,
    column_3: 5.6883726,
    column_2: "Trooz",
    column_1: "4870",
    geom: null,
    coordonnees: {
      lat: 50.5728102,
      lon: 5.6883726,
    },
  },
  {
    column_4: 50.5901199,
    column_3: 5.7479948,
    column_2: "Olne",
    column_1: "4877",
    geom: null,
    coordonnees: {
      lat: 50.5901199,
      lon: 5.7479948,
    },
  },
  {
    column_4: 50.6529465,
    column_3: 5.8650125,
    column_2: "Thimister",
    column_1: "4890",
    geom: null,
    coordonnees: {
      lat: 50.6529465,
      lon: 5.8650125,
    },
  },
  {
    column_4: 50.66298735,
    column_3: 5.8634486463407,
    column_2: "Thimister-Clermont",
    column_1: "4890",
    geom: null,
    coordonnees: {
      lat: 50.66298735,
      lon: 5.86344864634,
    },
  },
  {
    column_4: 50.4028521,
    column_3: 5.6660069,
    column_2: "Ernonheid",
    column_1: "4920",
    geom: null,
    coordonnees: {
      lat: 50.4028521,
      lon: 5.6660069,
    },
  },
  {
    column_4: 50.4149134,
    column_3: 6.1117543,
    column_2: "Waimes",
    column_1: "4950",
    geom: null,
    coordonnees: {
      lat: 50.4149134,
      lon: 6.1117543,
    },
  },
  {
    column_4: 50.4415348,
    column_3: 6.0391879,
    column_2: "Bevercé",
    column_1: "4960",
    geom: null,
    coordonnees: {
      lat: 50.4415348,
      lon: 6.0391879,
    },
  },
  {
    column_4: 50.3940846,
    column_3: 5.9308362,
    column_2: "Stavelot",
    column_1: "4970",
    geom: null,
    coordonnees: {
      lat: 50.3940846,
      lon: 5.9308362,
    },
  },
  {
    column_4: 50.3819564,
    column_3: 5.7299282,
    column_2: "Chevron",
    column_1: "4987",
    geom: null,
    coordonnees: {
      lat: 50.3819564,
      lon: 5.7299282,
    },
  },
  {
    column_4: 50.4128545,
    column_3: 5.8459307,
    column_2: "La Gleize",
    column_1: "4987",
    geom: null,
    coordonnees: {
      lat: 50.4128545,
      lon: 5.8459307,
    },
  },
  {
    column_4: 50.3860353,
    column_3: 5.7786187,
    column_2: "Rahier",
    column_1: "4987",
    geom: null,
    coordonnees: {
      lat: 50.3860353,
      lon: 5.7786187,
    },
  },
  {
    column_4: 50.45620385,
    column_3: 4.849314651989,
    column_2: "Namur",
    column_1: "5000",
    geom: null,
    coordonnees: {
      lat: 50.45620385,
      lon: 4.84931465199,
    },
  },
  {
    column_4: 50.48141855,
    column_3: 4.8351266178881,
    column_2: "Saint-Servais",
    column_1: "5002",
    geom: null,
    coordonnees: {
      lat: 50.48141855,
      lon: 4.83512661789,
    },
  },
  {
    column_4: 50.4948666,
    column_3: 4.8469587132153,
    column_2: "Saint-Marc",
    column_1: "5003",
    geom: null,
    coordonnees: {
      lat: 50.4948666,
      lon: 4.84695871322,
    },
  },
  {
    column_4: 50.51724395,
    column_3: 4.8769847282413,
    column_2: "Daussoulx",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.51724395,
      lon: 4.87698472824,
    },
  },
  {
    column_4: 50.5941971,
    column_3: 4.6739886,
    column_2: "Ernage",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.5941971,
      lon: 4.6739886,
    },
  },
  {
    column_4: 50.5810646,
    column_3: 4.7248074,
    column_2: "Sauvenière",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.5810646,
      lon: 4.7248074,
    },
  },
  {
    column_4: 50.521822,
    column_3: 4.6524505,
    column_2: "Bothey",
    column_1: "5032",
    geom: null,
    coordonnees: {
      lat: 50.521822,
      lon: 4.6524505,
    },
  },
  {
    column_4: 50.4182456,
    column_3: 4.621259,
    column_2: "Falisolle",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4182456,
      lon: 4.621259,
    },
  },
  {
    column_4: 50.4048557,
    column_3: 4.6513189,
    column_2: "Aisemont",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.4048557,
      lon: 4.6513189,
    },
  },
  {
    column_4: 50.3959512,
    column_3: 4.6978935,
    column_2: "Fosses-La-Ville",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.3959512,
      lon: 4.6978935,
    },
  },
  {
    column_4: 50.5379166,
    column_3: 4.8345691476021,
    column_2: "Villers-Lez-Heest",
    column_1: "5080",
    geom: null,
    coordonnees: {
      lat: 50.5379166,
      lon: 4.8345691476,
    },
  },
  {
    column_4: 50.51440005,
    column_3: 4.7787022193209,
    column_2: "Bovesse",
    column_1: "5081",
    geom: null,
    coordonnees: {
      lat: 50.51440005,
      lon: 4.77870221932,
    },
  },
  {
    column_4: 50.4146082,
    column_3: 4.9316703499006,
    column_2: "Naninne",
    column_1: "5100",
    geom: null,
    coordonnees: {
      lat: 50.4146082,
      lon: 4.9316703499,
    },
  },
  {
    column_4: 50.4212957,
    column_3: 4.8633768,
    column_2: "Wépion",
    column_1: "5100",
    geom: null,
    coordonnees: {
      lat: 50.4212957,
      lon: 4.8633768,
    },
  },
  {
    column_4: 50.47132665,
    column_3: 4.9384059263709,
    column_2: "Lives-Sur-Meuse",
    column_1: "5101",
    geom: null,
    coordonnees: {
      lat: 50.47132665,
      lon: 4.93840592637,
    },
  },
  {
    column_4: 50.4580989,
    column_3: 4.9409795174479,
    column_2: "Loyers",
    column_1: "5101",
    geom: null,
    coordonnees: {
      lat: 50.4580989,
      lon: 4.94097951745,
    },
  },
  {
    column_4: 50.5157258,
    column_3: 4.623887,
    column_2: "Tongrinne",
    column_1: "5140",
    geom: null,
    coordonnees: {
      lat: 50.5157258,
      lon: 4.623887,
    },
  },
  {
    column_4: 50.4389093,
    column_3: 4.7317891,
    column_2: "Franière",
    column_1: "5150",
    geom: null,
    coordonnees: {
      lat: 50.4389093,
      lon: 4.7317891,
    },
  },
  {
    column_4: 50.4983123,
    column_3: 4.6382422,
    column_2: "Balâtre",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.4983123,
      lon: 4.6382422,
    },
  },
  {
    column_4: 50.4709296,
    column_3: 5.0338525,
    column_2: "Bonneville",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4709296,
      lon: 5.0338525,
    },
  },
  {
    column_4: 50.4897139,
    column_3: 5.0284735,
    column_2: "Sclayn",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4897139,
      lon: 5.0284735,
    },
  },
  {
    column_4: 50.5988682,
    column_3: 4.8359955,
    column_2: "Aische-En-Refail",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5988682,
      lon: 4.8359955,
    },
  },
  {
    column_4: 50.629691,
    column_3: 4.971969,
    column_2: "Branchon",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.629691,
      lon: 4.971969,
    },
  },
  {
    column_4: 50.6082112,
    column_3: 4.8909225,
    column_2: "Noville-Sur-Méhaigne",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.6082112,
      lon: 4.8909225,
    },
  },
  {
    column_4: 50.5417091,
    column_3: 4.9217571,
    column_2: "Waret-La-Chaussée",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5417091,
      lon: 4.9217571,
    },
  },
  {
    column_4: 50.3775518,
    column_3: 4.9699385,
    column_2: "Maillen",
    column_1: "5330",
    geom: null,
    coordonnees: {
      lat: 50.3775518,
      lon: 4.9699385,
    },
  },
  {
    column_4: 50.3896858,
    column_3: 5.0294375,
    column_2: "Sorinne-La-Longue",
    column_1: "5333",
    geom: null,
    coordonnees: {
      lat: 50.3896858,
      lon: 5.0294375,
    },
  },
  {
    column_4: 50.4028535,
    column_3: 5.0776175,
    column_2: "Gesves",
    column_1: "5340",
    geom: null,
    coordonnees: {
      lat: 50.4028535,
      lon: 5.0776175,
    },
  },
  {
    column_4: 50.4508568,
    column_3: 5.0744138,
    column_2: "Haltinne",
    column_1: "5340",
    geom: null,
    coordonnees: {
      lat: 50.4508568,
      lon: 5.0744138,
    },
  },
  {
    column_4: 50.4359066,
    column_3: 5.1238439,
    column_2: "Ohey",
    column_1: "5350",
    geom: null,
    coordonnees: {
      lat: 50.4359066,
      lon: 5.1238439,
    },
  },
  {
    column_4: 50.3176664,
    column_3: 5.1923275,
    column_2: "Mohiville",
    column_1: "5361",
    geom: null,
    coordonnees: {
      lat: 50.3176664,
      lon: 5.1923275,
    },
  },
  {
    column_4: 50.3291239,
    column_3: 5.2607234,
    column_2: "Barvaux-Condroz",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3291239,
      lon: 5.2607234,
    },
  },
  {
    column_4: 50.3883757,
    column_3: 5.1845059,
    column_2: "Flostoy",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3883757,
      lon: 5.1845059,
    },
  },
  {
    column_4: 50.2945343,
    column_3: 5.2963125,
    column_2: "Heure",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.2945343,
      lon: 5.2963125,
    },
  },
  {
    column_4: 50.548118,
    column_3: 4.9746742,
    column_2: "Fernelmont",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.548118,
      lon: 4.9746742,
    },
  },
  {
    column_4: 50.6010427,
    column_3: 4.9869761,
    column_2: "Hemptinne",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.6010427,
      lon: 4.9869761,
    },
  },
  {
    column_4: 50.5231886,
    column_3: 4.9411438,
    column_2: "Marchovelette",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5231886,
      lon: 4.9411438,
    },
  },
  {
    column_4: 50.5555805,
    column_3: 4.982708,
    column_2: "Noville-Les-Bois",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5555805,
      lon: 4.982708,
    },
  },
  {
    column_4: 50.5500591,
    column_3: 5.01707,
    column_2: "Pontillas",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.5500591,
      lon: 5.01707,
    },
  },
  {
    column_4: 50.260159,
    column_3: 4.982409,
    column_2: "Sorinnes",
    column_1: "5503",
    geom: null,
    coordonnees: {
      lat: 50.260159,
      lon: 4.982409,
    },
  },
  {
    column_4: 50.3173377,
    column_3: 4.97325,
    column_2: "Dorinne",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3173377,
      lon: 4.97325,
    },
  },
  {
    column_4: 50.3031942,
    column_3: 4.8978933,
    column_2: "Houx",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3031942,
      lon: 4.8978933,
    },
  },
  {
    column_4: 50.3179627,
    column_3: 4.7512903,
    column_2: "Denée",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.3179627,
      lon: 4.7512903,
    },
  },
  {
    column_4: 50.1973562,
    column_3: 4.8225541872005,
    column_2: "Hastière",
    column_1: "5540",
    geom: null,
    coordonnees: {
      lat: 50.1973562,
      lon: 4.8225541872,
    },
  },
  {
    column_4: 50.2032886,
    column_3: 4.8632452,
    column_2: "Waulsort",
    column_1: "5540",
    geom: null,
    coordonnees: {
      lat: 50.2032886,
      lon: 4.8632452,
    },
  },
  {
    column_4: 49.8241964,
    column_3: 4.8751221,
    column_2: "Bagimont",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8241964,
      lon: 4.8751221,
    },
  },
  {
    column_4: 49.8110791,
    column_3: 4.8697858,
    column_2: "Pussemange",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8110791,
      lon: 4.8697858,
    },
  },
  {
    column_4: 49.8140011,
    column_3: 4.9032847,
    column_2: "Sugny",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8140011,
      lon: 4.9032847,
    },
  },
  {
    column_4: 49.9411216,
    column_3: 5.0168496,
    column_2: "Bièvre",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9411216,
      lon: 5.0168496,
    },
  },
  {
    column_4: 49.9046481,
    column_3: 4.9859857,
    column_2: "Monceau-En-Ardenne",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9046481,
      lon: 4.9859857,
    },
  },
  {
    column_4: 50.201583,
    column_3: 4.947825,
    column_2: "Hulsonniaux",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.201583,
      lon: 4.947825,
    },
  },
  {
    column_4: 50.2312617,
    column_3: 5.0058339,
    column_2: "Celles",
    column_1: "5561",
    geom: null,
    coordonnees: {
      lat: 50.2312617,
      lon: 5.0058339,
    },
  },
  {
    column_4: 50.2117661,
    column_3: 5.0484175,
    column_2: "Custinne",
    column_1: "5562",
    geom: null,
    coordonnees: {
      lat: 50.2117661,
      lon: 5.0484175,
    },
  },
  {
    column_4: 50.15093,
    column_3: 4.910576,
    column_2: "Feschaux",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.15093,
      lon: 4.910576,
    },
  },
  {
    column_4: 50.1487792,
    column_3: 4.9767431,
    column_2: "Wiesme",
    column_1: "5571",
    geom: null,
    coordonnees: {
      lat: 50.1487792,
      lon: 4.9767431,
    },
  },
  {
    column_4: 50.1176141,
    column_3: 5.003949,
    column_2: "Martouzin-Neuville",
    column_1: "5573",
    geom: null,
    coordonnees: {
      lat: 50.1176141,
      lon: 5.003949,
    },
  },
  {
    column_4: 50.099609,
    column_3: 5.008842,
    column_2: "Pondrôme",
    column_1: "5574",
    geom: null,
    coordonnees: {
      lat: 50.099609,
      lon: 5.008842,
    },
  },
  {
    column_4: 50.1254505,
    column_3: 5.1877385,
    column_2: "Han-Sur-Lesse",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.1254505,
      lon: 5.1877385,
    },
  },
  {
    column_4: 50.1442751,
    column_3: 5.1050287385834,
    column_2: "Villers-Sur-Lesse",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.1442751,
      lon: 5.10502873858,
    },
  },
  {
    column_4: 50.2687357,
    column_3: 5.1102616,
    column_2: "Leignon",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2687357,
      lon: 5.1102616,
    },
  },
  {
    column_4: 50.2825466,
    column_3: 5.1707753,
    column_2: "Pessoux",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2825466,
      lon: 5.1707753,
    },
  },
  {
    column_4: 50.2180956,
    column_3: 4.531286,
    column_2: "Jamagne",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.2180956,
      lon: 4.531286,
    },
  },
  {
    column_4: 50.2122411,
    column_3: 4.5079027,
    column_2: "Jamiolle",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.2122411,
      lon: 4.5079027,
    },
  },
  {
    column_4: 50.3868446,
    column_3: 5.7242282,
    column_2: "Neuville",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.3868446,
      lon: 5.7242282,
    },
  },
  {
    column_4: 50.2323028,
    column_3: 4.6876566,
    column_2: "Rosée",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2323028,
      lon: 4.6876566,
    },
  },
  {
    column_4: 50.218825,
    column_3: 4.455439,
    column_2: "Daussois",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.218825,
      lon: 4.455439,
    },
  },
  {
    column_4: 50.189702,
    column_3: 4.4827391,
    column_2: "Villers-Deux-Eglises",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.189702,
      lon: 4.4827391,
    },
  },
  {
    column_4: 50.325597,
    column_3: 4.7203188,
    column_2: "Graux",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.325597,
      lon: 4.7203188,
    },
  },
  {
    column_4: 50.3463414,
    column_3: 4.7395306,
    column_2: "Saint-Gérard",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.3463414,
      lon: 4.7395306,
    },
  },
  {
    column_4: 50.2975439,
    column_3: 4.7197934,
    column_2: "Ermeton-Sur-Biert",
    column_1: "5644",
    geom: null,
    coordonnees: {
      lat: 50.2975439,
      lon: 4.7197934,
    },
  },
  {
    column_4: 50.2892396,
    column_3: 4.4012206,
    column_2: "Berzée",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2892396,
      lon: 4.4012206,
    },
  },
  {
    column_4: 50.0524906,
    column_3: 4.4955009,
    column_2: "Couvin",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0524906,
      lon: 4.4955009,
    },
  },
  {
    column_4: 50.0941307,
    column_3: 4.5233186,
    column_2: "Mariembourg",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0941307,
      lon: 4.5233186,
    },
  },
  {
    column_4: 49.949789,
    column_3: 4.505529,
    column_2: "Petite-Chapelle",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 49.949789,
      lon: 4.505529,
    },
  },
  {
    column_4: 50.030867,
    column_3: 4.6712991,
    column_2: "Le Mesnil",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.030867,
      lon: 4.6712991,
    },
  },
  {
    column_4: 50.391219,
    column_3: 4.468631,
    column_2: "Couillet",
    column_1: "6010",
    geom: null,
    coordonnees: {
      lat: 50.391219,
      lon: 4.468631,
    },
  },
  {
    column_4: 50.45615655,
    column_3: 4.481573078145,
    column_2: "Ransart",
    column_1: "6043",
    geom: null,
    coordonnees: {
      lat: 50.45615655,
      lon: 4.48157307814,
    },
  },
  {
    column_4: 50.4269705,
    column_3: 4.485649013438,
    column_2: "Gilly",
    column_1: "6060",
    geom: null,
    coordonnees: {
      lat: 50.4269705,
      lon: 4.48564901344,
    },
  },
  {
    column_4: 50.4109216,
    column_3: 4.4729234863084,
    column_2: "Montignies-Sur-Sambre",
    column_1: "6061",
    geom: null,
    coordonnees: {
      lat: 50.4109216,
      lon: 4.47292348631,
    },
  },
  {
    column_4: 50.4383794,
    column_3: 4.3198191,
    column_2: "Forchies-La-Marche",
    column_1: "6141",
    geom: null,
    coordonnees: {
      lat: 50.4383794,
      lon: 4.3198191,
    },
  },
  {
    column_4: 50.450999,
    column_3: 4.350827,
    column_2: "Souvret",
    column_1: "6182",
    geom: null,
    coordonnees: {
      lat: 50.450999,
      lon: 4.350827,
    },
  },
  {
    column_4: 50.4665295,
    column_3: 4.3317415,
    column_2: "Trazegnies",
    column_1: "6183",
    geom: null,
    coordonnees: {
      lat: 50.4665295,
      lon: 4.3317415,
    },
  },
  {
    column_4: 50.53495775,
    column_3: 4.4252588130793,
    column_2: "Les Bons Villers",
    column_1: "6210",
    geom: null,
    coordonnees: {
      lat: 50.53495775,
      lon: 4.42525881308,
    },
  },
  {
    column_4: 50.543725,
    column_3: 4.4054735658856,
    column_2: "Rèves",
    column_1: "6210",
    geom: null,
    coordonnees: {
      lat: 50.543725,
      lon: 4.40547356589,
    },
  },
  {
    column_4: 50.5262014,
    column_3: 4.4783773,
    column_2: "Villers-Perwin",
    column_1: "6210",
    geom: null,
    coordonnees: {
      lat: 50.5262014,
      lon: 4.4783773,
    },
  },
  {
    column_4: 50.52262195,
    column_3: 4.5273110200086,
    column_2: "Wagnelée",
    column_1: "6223",
    geom: null,
    coordonnees: {
      lat: 50.52262195,
      lon: 4.52731102001,
    },
  },
  {
    column_4: 50.53893345,
    column_3: 4.3713097181263,
    column_2: "Buzet",
    column_1: "6230",
    geom: null,
    coordonnees: {
      lat: 50.53893345,
      lon: 4.37130971813,
    },
  },
  {
    column_4: 50.53439825,
    column_3: 4.3401322241149,
    column_2: "Obaix",
    column_1: "6230",
    geom: null,
    coordonnees: {
      lat: 50.53439825,
      lon: 4.34013222411,
    },
  },
  {
    column_4: 50.4906258,
    column_3: 4.4030299776693,
    column_2: "Viesville",
    column_1: "6230",
    geom: null,
    coordonnees: {
      lat: 50.4906258,
      lon: 4.40302997767,
    },
  },
  {
    column_4: 50.4273228,
    column_3: 4.553041,
    column_2: "Farciennes",
    column_1: "6240",
    geom: null,
    coordonnees: {
      lat: 50.4273228,
      lon: 4.553041,
    },
  },
  {
    column_4: 50.4111232,
    column_3: 4.5870152,
    column_2: "Aiseau",
    column_1: "6250",
    geom: null,
    coordonnees: {
      lat: 50.4111232,
      lon: 4.5870152,
    },
  },
  {
    column_4: 50.1666667,
    column_3: 4.3166667,
    column_2: "Fourbechies",
    column_1: "6440",
    geom: null,
    coordonnees: {
      lat: 50.1666667,
      lon: 4.3166667,
    },
  },
  {
    column_4: 50.069276,
    column_3: 4.238617,
    column_2: "Bailièvre",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.069276,
      lon: 4.238617,
    },
  },
  {
    column_4: 50.072802,
    column_3: 4.278378,
    column_2: "Robechies",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.072802,
      lon: 4.278378,
    },
  },
  {
    column_4: 50.035369,
    column_3: 4.26287,
    column_2: "Villers-La-Tour",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.035369,
      lon: 4.26287,
    },
  },
  {
    column_4: 50.027359,
    column_3: 4.341293,
    column_2: "Bourlers",
    column_1: "6464",
    geom: null,
    coordonnees: {
      lat: 50.027359,
      lon: 4.341293,
    },
  },
  {
    column_4: 49.947246,
    column_3: 4.431664,
    column_2: "L'escaillère",
    column_1: "6464",
    geom: null,
    coordonnees: {
      lat: 49.947246,
      lon: 4.431664,
    },
  },
  {
    column_4: 50.224642,
    column_3: 4.196155,
    column_2: "Leugnies",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.224642,
      lon: 4.196155,
    },
  },
  {
    column_4: 50.2854167,
    column_3: 4.3119991,
    column_2: "Donstiennes",
    column_1: "6536",
    geom: null,
    coordonnees: {
      lat: 50.2854167,
      lon: 4.3119991,
    },
  },
  {
    column_4: 50.3766211,
    column_3: 4.2377392,
    column_2: "Mont-Sainte-Geneviève",
    column_1: "6540",
    geom: null,
    coordonnees: {
      lat: 50.3766211,
      lon: 4.2377392,
    },
  },
  {
    column_4: 50.3102184,
    column_3: 4.1241436,
    column_2: "Erquelinnes",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.3102184,
      lon: 4.1241436,
    },
  },
  {
    column_4: 50.3234035,
    column_3: 4.1644816,
    column_2: "Merbes-Le-Château",
    column_1: "6567",
    geom: null,
    coordonnees: {
      lat: 50.3234035,
      lon: 4.1644816,
    },
  },
  {
    column_4: 49.9755399,
    column_3: 4.1802891,
    column_2: "Macquenoise",
    column_1: "6593",
    geom: null,
    coordonnees: {
      lat: 49.9755399,
      lon: 4.1802891,
    },
  },
  {
    column_4: 50.015365,
    column_3: 4.156972,
    column_2: "Beauwelz",
    column_1: "6594",
    geom: null,
    coordonnees: {
      lat: 50.015365,
      lon: 4.156972,
    },
  },
  {
    column_4: 49.96543,
    column_3: 4.2520681,
    column_2: "Forge-Philippe",
    column_1: "6596",
    geom: null,
    coordonnees: {
      lat: 49.96543,
      lon: 4.2520681,
    },
  },
  {
    column_4: 50.016087,
    column_3: 4.255615,
    column_2: "Seloignes",
    column_1: "6596",
    geom: null,
    coordonnees: {
      lat: 50.016087,
      lon: 4.255615,
    },
  },
  {
    column_4: 49.9345566,
    column_3: 5.7477792,
    column_2: "Villers-La-Bonne-Eau",
    column_1: "6600",
    geom: null,
    coordonnees: {
      lat: 49.9345566,
      lon: 5.7477792,
    },
  },
  {
    column_4: 49.878638,
    column_3: 5.7518438,
    column_2: "Tintange",
    column_1: "6637",
    geom: null,
    coordonnees: {
      lat: 49.878638,
      lon: 5.7518438,
    },
  },
  {
    column_4: 49.968275,
    column_3: 5.6352461,
    column_2: "Sibret",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.968275,
      lon: 5.6352461,
    },
  },
  {
    column_4: 50.1081487,
    column_3: 5.837773,
    column_2: "Tavigny",
    column_1: "6662",
    geom: null,
    coordonnees: {
      lat: 50.1081487,
      lon: 5.837773,
    },
  },
  {
    column_4: 50.1806741,
    column_3: 5.8646609,
    column_2: "Cherain",
    column_1: "6673",
    geom: null,
    coordonnees: {
      lat: 50.1806741,
      lon: 5.8646609,
    },
  },
  {
    column_4: 50.2390245,
    column_3: 5.8082072,
    column_2: "Bihain",
    column_1: "6690",
    geom: null,
    coordonnees: {
      lat: 50.2390245,
      lon: 5.8082072,
    },
  },
  {
    column_4: 49.7107729,
    column_3: 5.8190143,
    column_2: "Bonnert",
    column_1: "6700",
    geom: null,
    coordonnees: {
      lat: 49.7107729,
      lon: 5.8190143,
    },
  },
  {
    column_4: 49.7007526,
    column_3: 5.7464566,
    column_2: "Heinsch",
    column_1: "6700",
    geom: null,
    coordonnees: {
      lat: 49.7007526,
      lon: 5.7464566,
    },
  },
  {
    column_4: 49.7193575,
    column_3: 5.853381,
    column_2: "Guirsch",
    column_1: "6704",
    geom: null,
    coordonnees: {
      lat: 49.7193575,
      lon: 5.853381,
    },
  },
  {
    column_4: 49.6820986,
    column_3: 5.5185152,
    column_2: "Tintigny",
    column_1: "6730",
    geom: null,
    coordonnees: {
      lat: 49.6820986,
      lon: 5.5185152,
    },
  },
  {
    column_4: 49.6535106,
    column_3: 5.6551334,
    column_2: "Chantemelle",
    column_1: "6742",
    geom: null,
    coordonnees: {
      lat: 49.6535106,
      lon: 5.6551334,
    },
  },
  {
    column_4: 49.6151951,
    column_3: 5.7187012,
    column_2: "Meix-Le-Tige",
    column_1: "6747",
    geom: null,
    coordonnees: {
      lat: 49.6151951,
      lon: 5.7187012,
    },
  },
  {
    column_4: 49.5814209,
    column_3: 5.5766897,
    column_2: "Ethe",
    column_1: "6760",
    geom: null,
    coordonnees: {
      lat: 49.5814209,
      lon: 5.5766897,
    },
  },
  {
    column_4: 49.5581925,
    column_3: 5.5704642,
    column_2: "Latour",
    column_1: "6761",
    geom: null,
    coordonnees: {
      lat: 49.5581925,
      lon: 5.5704642,
    },
  },
  {
    column_4: 49.5085018,
    column_3: 5.4735035,
    column_2: "Torgny",
    column_1: "6767",
    geom: null,
    coordonnees: {
      lat: 49.5085018,
      lon: 5.4735035,
    },
  },
  {
    column_4: 49.5749479,
    column_3: 5.481043,
    column_2: "Villers-La-Loue",
    column_1: "6769",
    geom: null,
    coordonnees: {
      lat: 49.5749479,
      lon: 5.481043,
    },
  },
  {
    column_4: 49.5969055,
    column_3: 5.8169286,
    column_2: "Messancy",
    column_1: "6780",
    geom: null,
    coordonnees: {
      lat: 49.5969055,
      lon: 5.8169286,
    },
  },
  {
    column_4: 49.6350021,
    column_3: 5.7983045,
    column_2: "Wolkrange",
    column_1: "6780",
    geom: null,
    coordonnees: {
      lat: 49.6350021,
      lon: 5.7983045,
    },
  },
  {
    column_4: 49.7626877,
    column_3: 5.4005563,
    column_2: "Suxy",
    column_1: "6812",
    geom: null,
    coordonnees: {
      lat: 49.7626877,
      lon: 5.4005563,
    },
  },
  {
    column_4: 49.6993182,
    column_3: 5.3100518,
    column_2: "Florenville",
    column_1: "6820",
    geom: null,
    coordonnees: {
      lat: 49.6993182,
      lon: 5.3100518,
    },
  },
  {
    column_4: 49.71508,
    column_3: 5.234469,
    column_2: "Fontenoille",
    column_1: "6820",
    geom: null,
    coordonnees: {
      lat: 49.71508,
      lon: 5.234469,
    },
  },
  {
    column_4: 49.7170106,
    column_3: 5.318262,
    column_2: "Lacuisine",
    column_1: "6821",
    geom: null,
    coordonnees: {
      lat: 49.7170106,
      lon: 5.318262,
    },
  },
  {
    column_4: 49.6185771,
    column_3: 5.331466,
    column_2: "Villers-Devant-Orval",
    column_1: "6823",
    geom: null,
    coordonnees: {
      lat: 49.6185771,
      lon: 5.331466,
    },
  },
  {
    column_4: 49.7076905,
    column_3: 5.2621817,
    column_2: "Chassepierre",
    column_1: "6824",
    geom: null,
    coordonnees: {
      lat: 49.7076905,
      lon: 5.2621817,
    },
  },
  {
    column_4: 49.7973741,
    column_3: 5.1418578,
    column_2: "Dohan",
    column_1: "6836",
    geom: null,
    coordonnees: {
      lat: 49.7973741,
      lon: 5.1418578,
    },
  },
  {
    column_4: 49.8576034,
    column_3: 5.3717959,
    column_2: "Grandvoir",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8576034,
      lon: 5.3717959,
    },
  },
  {
    column_4: 49.8543387,
    column_3: 5.3969928,
    column_2: "Tournay",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8543387,
      lon: 5.3969928,
    },
  },
  {
    column_4: 49.8662058,
    column_3: 5.1607144,
    column_2: "Fays-Les-Veneurs",
    column_1: "6856",
    geom: null,
    coordonnees: {
      lat: 49.8662058,
      lon: 5.1607144,
    },
  },
  {
    column_4: 49.7671531,
    column_3: 5.5209461,
    column_2: "Mellier",
    column_1: "6860",
    geom: null,
    coordonnees: {
      lat: 49.7671531,
      lon: 5.5209461,
    },
  },
  {
    column_4: 49.8589203,
    column_3: 5.6138516,
    column_2: "Witry",
    column_1: "6860",
    geom: null,
    coordonnees: {
      lat: 49.8589203,
      lon: 5.6138516,
    },
  },
  {
    column_4: 50.0323986,
    column_3: 5.3166577,
    column_2: "Arville",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.0323986,
      lon: 5.3166577,
    },
  },
  {
    column_4: 50.074242,
    column_3: 5.306188,
    column_2: "Awenne",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.074242,
      lon: 5.306188,
    },
  },
  {
    column_4: 50.0052874,
    column_3: 5.348119,
    column_2: "Hatrival",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.0052874,
      lon: 5.348119,
    },
  },
  {
    column_4: 50.0251428,
    column_3: 5.3738561,
    column_2: "Saint-Hubert",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.0251428,
      lon: 5.3738561,
    },
  },
  {
    column_4: 49.8020427,
    column_3: 5.2033082,
    column_2: "Cugnon",
    column_1: "6880",
    geom: null,
    coordonnees: {
      lat: 49.8020427,
      lon: 5.2033082,
    },
  },
  {
    column_4: 49.7815364,
    column_3: 5.2377736,
    column_2: "Herbeumont",
    column_1: "6887",
    geom: null,
    coordonnees: {
      lat: 49.7815364,
      lon: 5.2377736,
    },
  },
  {
    column_4: 49.7961667,
    column_3: 5.3811714,
    column_2: "Straimont",
    column_1: "6887",
    geom: null,
    coordonnees: {
      lat: 49.7961667,
      lon: 5.3811714,
    },
  },
  {
    column_4: 49.9499043,
    column_3: 5.2215609,
    column_2: "Anloy",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 49.9499043,
      lon: 5.2215609,
    },
  },
  {
    column_4: 50.0822967,
    column_3: 5.1146708,
    column_2: "Wellin",
    column_1: "6920",
    geom: null,
    coordonnees: {
      lat: 50.0822967,
      lon: 5.1146708,
    },
  },
  {
    column_4: 50.0885262,
    column_3: 5.261162,
    column_2: "Bure",
    column_1: "6927",
    geom: null,
    coordonnees: {
      lat: 50.0885262,
      lon: 5.261162,
    },
  },
  {
    column_4: 50.0923432,
    column_3: 5.2798077,
    column_2: "Grupont",
    column_1: "6927",
    geom: null,
    coordonnees: {
      lat: 50.0923432,
      lon: 5.2798077,
    },
  },
  {
    column_4: 49.997198,
    column_3: 5.063233,
    column_2: "Gembes",
    column_1: "6929",
    geom: null,
    coordonnees: {
      lat: 49.997198,
      lon: 5.063233,
    },
  },
  {
    column_4: 50.3299795,
    column_3: 5.4086678,
    column_2: "Grandhan",
    column_1: "6940",
    geom: null,
    coordonnees: {
      lat: 50.3299795,
      lon: 5.4086678,
    },
  },
  {
    column_4: 50.3570338,
    column_3: 5.423028,
    column_2: "Septon",
    column_1: "6940",
    geom: null,
    coordonnees: {
      lat: 50.3570338,
      lon: 5.423028,
    },
  },
  {
    column_4: 50.346445,
    column_3: 5.562158,
    column_2: "Heyd",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.346445,
      lon: 5.562158,
    },
  },
  {
    column_4: 50.3848936,
    column_3: 5.5793252,
    column_2: "Izier",
    column_1: "6941",
    geom: null,
    coordonnees: {
      lat: 50.3848936,
      lon: 5.5793252,
    },
  },
  {
    column_4: 50.1665822,
    column_3: 5.4154822,
    column_2: "Bande",
    column_1: "6951",
    geom: null,
    coordonnees: {
      lat: 50.1665822,
      lon: 5.4154822,
    },
  },
  {
    column_4: 50.1441815,
    column_3: 5.3142288,
    column_2: "Ambly",
    column_1: "6953",
    geom: null,
    coordonnees: {
      lat: 50.1441815,
      lon: 5.3142288,
    },
  },
  {
    column_4: 50.1109713,
    column_3: 5.2799209,
    column_2: "Lesterny",
    column_1: "6953",
    geom: null,
    coordonnees: {
      lat: 50.1109713,
      lon: 5.2799209,
    },
  },
  {
    column_4: 50.2816159,
    column_3: 5.7155249,
    column_2: "Malempré",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.2816159,
      lon: 5.7155249,
    },
  },
  {
    column_4: 50.3021516,
    column_3: 5.6968767,
    column_2: "Vaux-Chavanne",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.3021516,
      lon: 5.6968767,
    },
  },
  {
    column_4: 50.1137831,
    column_3: 5.5507905,
    column_2: "Erneuville",
    column_1: "6972",
    geom: null,
    coordonnees: {
      lat: 50.1137831,
      lon: 5.5507905,
    },
  },
  {
    column_4: 50.182916023074,
    column_3: 5.5701521878995,
    column_2: "La Roche-En-Ardenne",
    column_1: "6980",
    geom: null,
    coordonnees: {
      lat: 50.1829160231,
      lon: 5.5701521879,
    },
  },
  {
    column_4: 50.2579039,
    column_3: 5.4729019,
    column_2: "Hampteau",
    column_1: "6990",
    geom: null,
    coordonnees: {
      lat: 50.2579039,
      lon: 5.4729019,
    },
  },
  {
    column_4: 50.2418351,
    column_3: 5.4165812,
    column_2: "Marenne",
    column_1: "6990",
    geom: null,
    coordonnees: {
      lat: 50.2418351,
      lon: 5.4165812,
    },
  },
  {
    column_4: 50.4901522,
    column_3: 3.962763,
    column_2: "Maisières",
    column_1: "7020",
    geom: null,
    coordonnees: {
      lat: 50.4901522,
      lon: 3.962763,
    },
  },
  {
    column_4: 50.4643434,
    column_3: 4.0453329,
    column_2: "Havre",
    column_1: "7021",
    geom: null,
    coordonnees: {
      lat: 50.4643434,
      lon: 4.0453329,
    },
  },
  {
    column_4: 50.4193042,
    column_3: 3.9438349,
    column_2: "Ciply",
    column_1: "7024",
    geom: null,
    coordonnees: {
      lat: 50.4193042,
      lon: 3.9438349,
    },
  },
  {
    column_4: 50.436171,
    column_3: 3.9206652,
    column_2: "Cuesmes",
    column_1: "7033",
    geom: null,
    coordonnees: {
      lat: 50.436171,
      lon: 3.9206652,
    },
  },
  {
    column_4: 50.528238,
    column_3: 3.8577095,
    column_2: "Herchies",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.528238,
      lon: 3.8577095,
    },
  },
  {
    column_4: 50.6093445,
    column_3: 4.0413076,
    column_2: "Horrues",
    column_1: "7060",
    geom: null,
    coordonnees: {
      lat: 50.6093445,
      lon: 4.0413076,
    },
  },
  {
    column_4: 50.4971875,
    column_3: 4.0622054,
    column_2: "Gottignies",
    column_1: "7070",
    geom: null,
    coordonnees: {
      lat: 50.4971875,
      lon: 4.0622054,
    },
  },
  {
    column_4: 50.5291801,
    column_3: 4.152112,
    column_2: "Mignault",
    column_1: "7070",
    geom: null,
    coordonnees: {
      lat: 50.5291801,
      lon: 4.152112,
    },
  },
  {
    column_4: 50.4087571,
    column_3: 3.8905656,
    column_2: "Frameries",
    column_1: "7080",
    geom: null,
    coordonnees: {
      lat: 50.4087571,
      lon: 3.8905656,
    },
  },
  {
    column_4: 50.407951,
    column_3: 3.8774491,
    column_2: "La Bouverie",
    column_1: "7080",
    geom: null,
    coordonnees: {
      lat: 50.407951,
      lon: 3.8774491,
    },
  },
  {
    column_4: 50.6123481,
    column_3: 4.1425138,
    column_2: "Braine-Le-Comte",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.6123481,
      lon: 4.1425138,
    },
  },
  {
    column_4: 50.6432692,
    column_3: 4.0698505,
    column_2: "Steenkerque",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.6432692,
      lon: 4.0698505,
    },
  },
  {
    column_4: 50.454942,
    column_3: 4.1883094,
    column_2: "Haine-Saint-Paul",
    column_1: "7100",
    geom: null,
    coordonnees: {
      lat: 50.454942,
      lon: 4.1883094,
    },
  },
  {
    column_4: 50.4835958,
    column_3: 4.1524459,
    column_2: "Houdeng-Aimeries",
    column_1: "7110",
    geom: null,
    coordonnees: {
      lat: 50.4835958,
      lon: 4.1524459,
    },
  },
  {
    column_4: 50.3897538,
    column_3: 4.2055425,
    column_2: "Buvrinnes",
    column_1: "7133",
    geom: null,
    coordonnees: {
      lat: 50.3897538,
      lon: 4.2055425,
    },
  },
  {
    column_4: 50.4049792,
    column_3: 4.2093685,
    column_2: "Epinois",
    column_1: "7134",
    geom: null,
    coordonnees: {
      lat: 50.4049792,
      lon: 4.2093685,
    },
  },
  {
    column_4: 50.416547,
    column_3: 4.218073,
    column_2: "Leval-Trahegnies",
    column_1: "7134",
    geom: null,
    coordonnees: {
      lat: 50.416547,
      lon: 4.218073,
    },
  },
  {
    column_4: 50.52505545,
    column_3: 4.2664609240834,
    column_2: "Seneffe",
    column_1: "7180",
    geom: null,
    coordonnees: {
      lat: 50.52505545,
      lon: 4.26646092408,
    },
  },
  {
    column_4: 50.5714795,
    column_3: 4.1776303,
    column_2: "Ecaussinnes-D'enghien",
    column_1: "7190",
    geom: null,
    coordonnees: {
      lat: 50.5714795,
      lon: 4.1776303,
    },
  },
  {
    column_4: 50.5468953,
    column_3: 4.1832813,
    column_2: "Marche-Lez-Ecaussinnes",
    column_1: "7190",
    geom: null,
    coordonnees: {
      lat: 50.5468953,
      lon: 4.1832813,
    },
  },
  {
    column_4: 50.4337613,
    column_3: 3.8276077,
    column_2: "Hornu",
    column_1: "7301",
    geom: null,
    coordonnees: {
      lat: 50.4337613,
      lon: 3.8276077,
    },
  },
  {
    column_4: 50.5011724,
    column_3: 3.6611754,
    column_2: "Blaton",
    column_1: "7321",
    geom: null,
    coordonnees: {
      lat: 50.5011724,
      lon: 3.6611754,
    },
  },
  {
    column_4: 50.4794445,
    column_3: 3.6936,
    column_2: "Harchies",
    column_1: "7321",
    geom: null,
    coordonnees: {
      lat: 50.4794445,
      lon: 3.6936,
    },
  },
  {
    column_4: 50.448359502766,
    column_3: 3.8214781396442,
    column_2: "Saint-Ghislain",
    column_1: "7330",
    geom: null,
    coordonnees: {
      lat: 50.4483595028,
      lon: 3.82147813964,
    },
  },
  {
    column_4: 50.4002638,
    column_3: 3.8223517,
    column_2: "Warquignies",
    column_1: "7340",
    geom: null,
    coordonnees: {
      lat: 50.4002638,
      lon: 3.8223517,
    },
  },
  {
    column_4: 50.4328388,
    column_3: 3.6847215,
    column_2: "Hensies",
    column_1: "7350",
    geom: null,
    coordonnees: {
      lat: 50.4328388,
      lon: 3.6847215,
    },
  },
  {
    column_4: 50.366983,
    column_3: 3.6954509,
    column_2: "Angre",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.366983,
      lon: 3.6954509,
    },
  },
  {
    column_4: 50.3509285,
    column_3: 3.6911259,
    column_2: "Angreau",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3509285,
      lon: 3.6911259,
    },
  },
  {
    column_4: 50.3680079,
    column_3: 3.7333223,
    column_2: "Montignies-Sur-Roc",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3680079,
      lon: 3.7333223,
    },
  },
  {
    column_4: 50.6141111,
    column_3: 3.2880161,
    column_2: "Hertain",
    column_1: "7522",
    geom: null,
    coordonnees: {
      lat: 50.6141111,
      lon: 3.2880161,
    },
  },
  {
    column_4: 50.6349458,
    column_3: 3.5102508,
    column_2: "Thimougies",
    column_1: "7533",
    geom: null,
    coordonnees: {
      lat: 50.6349458,
      lon: 3.5102508,
    },
  },
  {
    column_4: 50.589889,
    column_3: 3.426172,
    column_2: "Vaulx",
    column_1: "7536",
    geom: null,
    coordonnees: {
      lat: 50.589889,
      lon: 3.426172,
    },
  },
  {
    column_4: 50.5683976,
    column_3: 3.5017888,
    column_2: "Vezon",
    column_1: "7538",
    geom: null,
    coordonnees: {
      lat: 50.5683976,
      lon: 3.5017888,
    },
  },
  {
    column_4: 50.646728,
    column_3: 3.4818996,
    column_2: "Melles",
    column_1: "7540",
    geom: null,
    coordonnees: {
      lat: 50.646728,
      lon: 3.4818996,
    },
  },
  {
    column_4: 50.6201039,
    column_3: 3.4372618,
    column_2: "Rumillies",
    column_1: "7540",
    geom: null,
    coordonnees: {
      lat: 50.6201039,
      lon: 3.4372618,
    },
  },
  {
    column_4: 50.6530409,
    column_3: 3.445164,
    column_2: "Mourcourt",
    column_1: "7543",
    geom: null,
    coordonnees: {
      lat: 50.6530409,
      lon: 3.445164,
    },
  },
  {
    column_4: 50.5097897,
    column_3: 3.5908864,
    column_2: "Péruwelz",
    column_1: "7600",
    geom: null,
    coordonnees: {
      lat: 50.5097897,
      lon: 3.5908864,
    },
  },
  {
    column_4: 50.5539524,
    column_3: 3.5352247,
    column_2: "Wasmes-Audemez-Briffoeil",
    column_1: "7604",
    geom: null,
    coordonnees: {
      lat: 50.5539524,
      lon: 3.5352247,
    },
  },
  {
    column_4: 50.508665,
    column_3: 3.533035,
    column_2: "Wiers",
    column_1: "7608",
    geom: null,
    coordonnees: {
      lat: 50.508665,
      lon: 3.533035,
    },
  },
  {
    column_4: 50.5131151,
    column_3: 3.4155171,
    column_2: "Bléharies",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.5131151,
      lon: 3.4155171,
    },
  },
  {
    column_4: 50.520291,
    column_3: 3.3880963285681,
    column_2: "Lesdain",
    column_1: "7621",
    geom: null,
    coordonnees: {
      lat: 50.520291,
      lon: 3.38809632857,
    },
  },
  {
    column_4: 50.50665205,
    column_3: 3.3815632027097,
    column_2: "Rongy",
    column_1: "7623",
    geom: null,
    coordonnees: {
      lat: 50.50665205,
      lon: 3.38156320271,
    },
  },
  {
    column_4: 50.565911,
    column_3: 3.4513116,
    column_2: "Antoing",
    column_1: "7640",
    geom: null,
    coordonnees: {
      lat: 50.565911,
      lon: 3.4513116,
    },
  },
  {
    column_4: 50.7227127,
    column_3: 3.2342044,
    column_2: "Herseaux",
    column_1: "7712",
    geom: null,
    coordonnees: {
      lat: 50.7227127,
      lon: 3.2342044,
    },
  },
  {
    column_4: 50.6669438,
    column_3: 3.2690175,
    column_2: "Néchin",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.6669438,
      lon: 3.2690175,
    },
  },
  {
    column_4: 50.702173,
    column_3: 3.3451985,
    column_2: "Warcoing",
    column_1: "7740",
    geom: null,
    coordonnees: {
      lat: 50.702173,
      lon: 3.3451985,
    },
  },
  {
    column_4: 50.662055,
    column_3: 3.364281,
    column_2: "Obigies",
    column_1: "7743",
    geom: null,
    coordonnees: {
      lat: 50.662055,
      lon: 3.364281,
    },
  },
  {
    column_4: 50.7588153,
    column_3: 2.9625206,
    column_2: "Bas-Warneton",
    column_1: "7784",
    geom: null,
    coordonnees: {
      lat: 50.7588153,
      lon: 2.9625206,
    },
  },
  {
    column_4: 50.7529932,
    column_3: 2.9502023,
    column_2: "Warneton",
    column_1: "7784",
    geom: null,
    coordonnees: {
      lat: 50.7529932,
      lon: 2.9502023,
    },
  },
  {
    column_4: 50.6534242,
    column_3: 3.8042549,
    column_2: "Lanquesaint",
    column_1: "7800",
    geom: null,
    coordonnees: {
      lat: 50.6534242,
      lon: 3.8042549,
    },
  },
  {
    column_4: 50.5947224,
    column_3: 3.7516336,
    column_2: "Ormeignies",
    column_1: "7802",
    geom: null,
    coordonnees: {
      lat: 50.5947224,
      lon: 3.7516336,
    },
  },
  {
    column_4: 50.660198,
    column_3: 3.7836911,
    column_2: "Rebaix",
    column_1: "7804",
    geom: null,
    coordonnees: {
      lat: 50.660198,
      lon: 3.7836911,
    },
  },
  {
    column_4: 50.6222783,
    column_3: 3.7054981,
    column_2: "Ligne",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.6222783,
      lon: 3.7054981,
    },
  },
  {
    column_4: 50.600191,
    column_3: 3.7156806,
    column_2: "Moulbaix",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.600191,
      lon: 3.7156806,
    },
  },
  {
    column_4: 50.617881,
    column_3: 3.7360962,
    column_2: "Villers-Notre-Dame",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.617881,
      lon: 3.7360962,
    },
  },
  {
    column_4: 50.6485784,
    column_3: 3.8480962,
    column_2: "Meslin-L'evêque",
    column_1: "7822",
    geom: null,
    coordonnees: {
      lat: 50.6485784,
      lon: 3.8480962,
    },
  },
  {
    column_4: 50.6311726,
    column_3: 3.9967288,
    column_2: "Graty",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.6311726,
      lon: 3.9967288,
    },
  },
  {
    column_4: 50.663165,
    column_3: 3.8887882,
    column_2: "Hellebecq",
    column_1: "7830",
    geom: null,
    coordonnees: {
      lat: 50.663165,
      lon: 3.8887882,
    },
  },
  {
    column_4: 50.6927553,
    column_3: 4.0406912,
    column_2: "Enghien",
    column_1: "7850",
    geom: null,
    coordonnees: {
      lat: 50.6927553,
      lon: 4.0406912,
    },
  },
  {
    column_4: 50.7123036,
    column_3: 3.8301131,
    column_2: "Lessines",
    column_1: "7860",
    geom: null,
    coordonnees: {
      lat: 50.7123036,
      lon: 3.8301131,
    },
  },
  {
    column_4: 50.6861533,
    column_3: 3.819132,
    column_2: "Papignies",
    column_1: "7861",
    geom: null,
    coordonnees: {
      lat: 50.6861533,
      lon: 3.819132,
    },
  },
  {
    column_4: 50.7307336,
    column_3: 3.8522036,
    column_2: "Deux-Acren",
    column_1: "7864",
    geom: null,
    coordonnees: {
      lat: 50.7307336,
      lon: 3.8522036,
    },
  },
  {
    column_4: 50.5701441,
    column_3: 3.8537045,
    column_2: "Bauffe",
    column_1: "7870",
    geom: null,
    coordonnees: {
      lat: 50.5701441,
      lon: 3.8537045,
    },
  },
  {
    column_4: 50.5827811,
    column_3: 3.9162237,
    column_2: "Cambron-Saint-Vincent",
    column_1: "7870",
    geom: null,
    coordonnees: {
      lat: 50.5827811,
      lon: 3.9162237,
    },
  },
  {
    column_4: 50.582865,
    column_3: 3.575887,
    column_2: "Pipaix",
    column_1: "7904",
    geom: null,
    coordonnees: {
      lat: 50.582865,
      lon: 3.575887,
    },
  },
  {
    column_4: 50.572688,
    column_3: 3.6493593,
    column_2: "Tourpes",
    column_1: "7904",
    geom: null,
    coordonnees: {
      lat: 50.572688,
      lon: 3.6493593,
    },
  },
  {
    column_4: 50.6817661,
    column_3: 3.5617225,
    column_2: "Anvaing",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.6817661,
      lon: 3.5617225,
    },
  },
  {
    column_4: 50.6648633,
    column_3: 3.6551307,
    column_2: "Buissenal",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.6648633,
      lon: 3.6551307,
    },
  },
  {
    column_4: 50.6557475,
    column_3: 3.619931,
    column_2: "Moustier",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.6557475,
      lon: 3.619931,
    },
  },
  {
    column_4: 50.7061803,
    column_3: 3.5977459,
    column_2: "Saint-Sauveur",
    column_1: "7912",
    geom: null,
    coordonnees: {
      lat: 50.7061803,
      lon: 3.5977459,
    },
  },
  {
    column_4: 50.5954253,
    column_3: 3.8546563,
    column_2: "Brugelette",
    column_1: "7940",
    geom: null,
    coordonnees: {
      lat: 50.5954253,
      lon: 3.8546563,
    },
  },
  {
    column_4: 50.5885597,
    column_3: 3.8807488,
    column_2: "Cambron-Casteau",
    column_1: "7940",
    geom: null,
    coordonnees: {
      lat: 50.5885597,
      lon: 3.8807488,
    },
  },
  {
    column_4: 50.6102428,
    column_3: 3.8410951,
    column_2: "Attre",
    column_1: "7941",
    geom: null,
    coordonnees: {
      lat: 50.6102428,
      lon: 3.8410951,
    },
  },
  {
    column_4: 50.6068018,
    column_3: 3.8910777,
    column_2: "Gages",
    column_1: "7943",
    geom: null,
    coordonnees: {
      lat: 50.6068018,
      lon: 3.8910777,
    },
  },
  {
    column_4: 50.5860413,
    column_3: 3.7907921,
    column_2: "Tongre-Saint-Martin",
    column_1: "7950",
    geom: null,
    coordonnees: {
      lat: 50.5860413,
      lon: 3.7907921,
    },
  },
  {
    column_4: 50.573932,
    column_3: 3.6769173,
    column_2: "Aubechies",
    column_1: "7972",
    geom: null,
    coordonnees: {
      lat: 50.573932,
      lon: 3.6769173,
    },
  },
  {
    column_4: 51.1048944,
    column_3: 3.1606518,
    column_2: "Veldegem",
    column_1: "8210",
    geom: null,
    coordonnees: {
      lat: 51.1048944,
      lon: 3.1606518,
    },
  },
  {
    column_4: 51.2996935,
    column_3: 3.1404868099971,
    column_2: "Uitkerke",
    column_1: "8370",
    geom: null,
    coordonnees: {
      lat: 51.2996935,
      lon: 3.14048681,
    },
  },
  {
    column_4: 51.2356311,
    column_3: 3.1469831971503,
    column_2: "Meetkerke",
    column_1: "8377",
    geom: null,
    coordonnees: {
      lat: 51.2356311,
      lon: 3.14698319715,
    },
  },
  {
    column_4: 51.31711375,
    column_3: 3.1991824156265,
    column_2: "Lissewege",
    column_1: "8380",
    geom: null,
    coordonnees: {
      lat: 51.31711375,
      lon: 3.19918241563,
    },
  },
  {
    column_4: 51.1110889,
    column_3: 2.8402112,
    column_2: "Schore",
    column_1: "8433",
    geom: null,
    coordonnees: {
      lat: 51.1110889,
      lon: 2.8402112,
    },
  },
  {
    column_4: 51.15402425,
    column_3: 2.7549864166944,
    column_2: "Lombardsijde",
    column_1: "8434",
    geom: null,
    coordonnees: {
      lat: 51.15402425,
      lon: 2.75498641669,
    },
  },
  {
    column_4: 51.20118315,
    column_3: 3.0061089520182,
    column_2: "Oudenburg",
    column_1: "8460",
    geom: null,
    coordonnees: {
      lat: 51.20118315,
      lon: 3.00610895202,
    },
  },
  {
    column_4: 51.1662204,
    column_3: 3.0333284671459,
    column_2: "Roksem",
    column_1: "8460",
    geom: null,
    coordonnees: {
      lat: 51.1662204,
      lon: 3.03332846715,
    },
  },
  {
    column_4: 51.1582502,
    column_3: 2.9670810948205,
    column_2: "Gistel",
    column_1: "8470",
    geom: null,
    coordonnees: {
      lat: 51.1582502,
      lon: 2.96708109482,
    },
  },
  {
    column_4: 51.17625885,
    column_3: 2.9333678137411,
    column_2: "Snaaskerke",
    column_1: "8470",
    geom: null,
    coordonnees: {
      lat: 51.17625885,
      lon: 2.93336781374,
    },
  },
  {
    column_4: 50.8070452,
    column_3: 3.2336226,
    column_2: "Marke",
    column_1: "8510",
    geom: null,
    coordonnees: {
      lat: 50.8070452,
      lon: 3.2336226,
    },
  },
  {
    column_4: 50.86005145,
    column_3: 3.2736322150643,
    column_2: "Kuurne",
    column_1: "8520",
    geom: null,
    coordonnees: {
      lat: 50.86005145,
      lon: 3.27363221506,
    },
  },
  {
    column_4: 50.8293249,
    column_3: 3.3425511,
    column_2: "Harelbeke",
    column_1: "8530",
    geom: null,
    coordonnees: {
      lat: 50.8293249,
      lon: 3.3425511,
    },
  },
  {
    column_4: 50.83878495,
    column_3: 3.3622790229139,
    column_2: "Deerlijk",
    column_1: "8540",
    geom: null,
    coordonnees: {
      lat: 50.83878495,
      lon: 3.36227902291,
    },
  },
  {
    column_4: 50.80927585,
    column_3: 3.4245499365566,
    column_2: "Otegem",
    column_1: "8553",
    geom: null,
    coordonnees: {
      lat: 50.80927585,
      lon: 3.42454993656,
    },
  },
  {
    column_4: 50.84259085,
    column_3: 3.1549318040629,
    column_2: "Moorsele",
    column_1: "8560",
    geom: null,
    coordonnees: {
      lat: 50.84259085,
      lon: 3.15493180406,
    },
  },
  {
    column_4: 50.72568025,
    column_3: 3.3465264652837,
    column_2: "Espierres",
    column_1: "8587",
    geom: null,
    coordonnees: {
      lat: 50.72568025,
      lon: 3.34652646528,
    },
  },
  {
    column_4: 51.05367055,
    column_3: 2.873227743412,
    column_2: "Beerst",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.05367055,
      lon: 2.87322774341,
    },
  },
  {
    column_4: 50.7059709,
    column_3: 3.9855229,
    column_2: "Driekapellen",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 50.7059709,
      lon: 3.9855229,
    },
  },
  {
    column_4: 51.02078675,
    column_3: 2.8990845692587,
    column_2: "Esen",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.02078675,
      lon: 2.89908456926,
    },
  },
  {
    column_4: 51.0396362,
    column_3: 2.7703028703735,
    column_2: "Lampernisse",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.0396362,
      lon: 2.77030287037,
    },
  },
  {
    column_4: 51.14416005,
    column_3: 2.7283986495034,
    column_2: "Nieuwpoort",
    column_1: "8620",
    geom: null,
    coordonnees: {
      lat: 51.14416005,
      lon: 2.7283986495,
    },
  },
  {
    column_4: 51.10665355,
    column_3: 2.7811249526825,
    column_2: "Ramskapelle",
    column_1: "8620",
    geom: null,
    coordonnees: {
      lat: 51.10665355,
      lon: 2.78112495268,
    },
  },
  {
    column_4: 51.0444613,
    column_3: 2.6434487702288,
    column_2: "Bulskamp",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.0444613,
      lon: 2.64344877023,
    },
  },
  {
    column_4: 50.97407785,
    column_3: 2.9438110508216,
    column_2: "Houthulst",
    column_1: "8650",
    geom: null,
    coordonnees: {
      lat: 50.97407785,
      lon: 2.94381105082,
    },
  },
  {
    column_4: 51.09779175,
    column_3: 2.7073119164927,
    column_2: "Wulpen",
    column_1: "8670",
    geom: null,
    coordonnees: {
      lat: 51.09779175,
      lon: 2.70731191649,
    },
  },
  {
    column_4: 51.01509585,
    column_3: 2.7225688102727,
    column_2: "Alveringem",
    column_1: "8690",
    geom: null,
    coordonnees: {
      lat: 51.01509585,
      lon: 2.72256881027,
    },
  },
  {
    column_4: 51.021401,
    column_3: 3.4035567083156,
    column_2: "Kanegem",
    column_1: "8700",
    geom: null,
    coordonnees: {
      lat: 51.021401,
      lon: 3.40355670832,
    },
  },
  {
    column_4: 50.94008805,
    column_3: 3.429680547389,
    column_2: "Oeselgem",
    column_1: "8720",
    geom: null,
    coordonnees: {
      lat: 50.94008805,
      lon: 3.42968054739,
    },
  },
  {
    column_4: 50.99996475,
    column_3: 3.2622730034453,
    column_2: "Pittem",
    column_1: "8740",
    geom: null,
    coordonnees: {
      lat: 50.99996475,
      lon: 3.26227300345,
    },
  },
  {
    column_4: 50.9687312,
    column_3: 3.14416,
    column_2: "Beveren",
    column_1: "8800",
    geom: null,
    coordonnees: {
      lat: 50.9687312,
      lon: 3.14416,
    },
  },
  {
    column_4: 51.03033065,
    column_3: 3.1422717715338,
    column_2: "Lichtervelde",
    column_1: "8810",
    geom: null,
    coordonnees: {
      lat: 51.03033065,
      lon: 3.14227177153,
    },
  },
  {
    column_4: 50.97048785,
    column_3: 3.001340623187,
    column_2: "Staden",
    column_1: "8840",
    geom: null,
    coordonnees: {
      lat: 50.97048785,
      lon: 3.00134062319,
    },
  },
  {
    column_4: 50.97076545,
    column_3: 3.2045936674459,
    column_2: "Ardooie",
    column_1: "8850",
    geom: null,
    coordonnees: {
      lat: 50.97076545,
      lon: 3.20459366745,
    },
  },
  {
    column_4: 50.91382225,
    column_3: 3.2090654210557,
    column_2: "Izegem",
    column_1: "8870",
    geom: null,
    coordonnees: {
      lat: 50.91382225,
      lon: 3.20906542106,
    },
  },
  {
    column_4: 50.8765142,
    column_3: 3.179116,
    column_2: "Sint-Eloois-Winkel",
    column_1: "8880",
    geom: null,
    coordonnees: {
      lat: 50.8765142,
      lon: 3.179116,
    },
  },
  {
    column_4: 50.84693275,
    column_3: 3.1056665757612,
    column_2: "Dadizele",
    column_1: "8890",
    geom: null,
    coordonnees: {
      lat: 50.84693275,
      lon: 3.10566657576,
    },
  },
  {
    column_4: 50.89006565,
    column_3: 3.0732075972642,
    column_2: "Moorslede",
    column_1: "8890",
    geom: null,
    coordonnees: {
      lat: 50.89006565,
      lon: 3.07320759726,
    },
  },
  {
    column_4: 50.8646017,
    column_3: 2.9038085,
    column_2: "Sint-Jan",
    column_1: "8900",
    geom: null,
    coordonnees: {
      lat: 50.8646017,
      lon: 2.9038085,
    },
  },
  {
    column_4: 50.89329445,
    column_3: 2.8624482523982,
    column_2: "Boezinge",
    column_1: "8904",
    geom: null,
    coordonnees: {
      lat: 50.89329445,
      lon: 2.8624482524,
    },
  },
  {
    column_4: 50.826939,
    column_3: 3.0689009201802,
    column_2: "Geluwe",
    column_1: "8940",
    geom: null,
    coordonnees: {
      lat: 50.826939,
      lon: 3.06890092018,
    },
  },
  {
    column_4: 50.79981455,
    column_3: 3.0429882329964,
    column_2: "Wervik",
    column_1: "8940",
    geom: null,
    coordonnees: {
      lat: 50.79981455,
      lon: 3.042988233,
    },
  },
  {
    column_4: 50.7457387,
    column_3: 2.8251932,
    column_2: "Nieuwkerke",
    column_1: "8950",
    geom: null,
    coordonnees: {
      lat: 50.7457387,
      lon: 2.8251932,
    },
  },
  {
    column_4: 50.84362245,
    column_3: 2.7222592638771,
    column_2: "Poperinge",
    column_1: "8970",
    geom: null,
    coordonnees: {
      lat: 50.84362245,
      lon: 2.72225926388,
    },
  },
  {
    column_4: 50.9150531,
    column_3: 2.6262588,
    column_2: "Roesbrugge-Haringe",
    column_1: "8972",
    geom: null,
    coordonnees: {
      lat: 50.9150531,
      lon: 2.6262588,
    },
  },
  {
    column_4: 50.85048855,
    column_3: 3.0293494282427,
    column_2: "Beselare",
    column_1: "8980",
    geom: null,
    coordonnees: {
      lat: 50.85048855,
      lon: 3.02934942824,
    },
  },
  {
    column_4: 50.8340552,
    column_3: 2.9962568059484,
    column_2: "Geluveld",
    column_1: "8980",
    geom: null,
    coordonnees: {
      lat: 50.8340552,
      lon: 2.99625680595,
    },
  },
  {
    column_4: 51.0556204,
    column_3: 3.7978887,
    column_2: "Destelbergen",
    column_1: "9070",
    geom: null,
    coordonnees: {
      lat: 51.0556204,
      lon: 3.7978887,
    },
  },
  {
    column_4: 51.1323736,
    column_3: 3.8601634,
    column_2: "Zaffelare",
    column_1: "9080",
    geom: null,
    coordonnees: {
      lat: 51.1323736,
      lon: 3.8601634,
    },
  },
  {
    column_4: 50.9842505,
    column_3: 3.7976423,
    column_2: "Gontrode",
    column_1: "9090",
    geom: null,
    coordonnees: {
      lat: 50.9842505,
      lon: 3.7976423,
    },
  },
  {
    column_4: 51.00303,
    column_3: 3.7988833,
    column_2: "Melle",
    column_1: "9090",
    geom: null,
    coordonnees: {
      lat: 51.00303,
      lon: 3.7988833,
    },
  },
  {
    column_4: 51.1933908,
    column_3: 4.1780279,
    column_2: "Nieuwkerken-Waas",
    column_1: "9100",
    geom: null,
    coordonnees: {
      lat: 51.1933908,
      lon: 4.1780279,
    },
  },
  {
    column_4: 51.163813060072,
    column_3: 4.149464320724,
    column_2: "Sint-Niklaas",
    column_1: "9100",
    geom: null,
    coordonnees: {
      lat: 51.1638130601,
      lon: 4.14946432072,
    },
  },
  {
    column_4: 51.1806482,
    column_3: 4.2383861,
    column_2: "Haasdonk",
    column_1: "9120",
    geom: null,
    coordonnees: {
      lat: 51.1806482,
      lon: 4.2383861,
    },
  },
  {
    column_4: 51.2211394,
    column_3: 4.2821446,
    column_2: "Melsele",
    column_1: "9120",
    geom: null,
    coordonnees: {
      lat: 51.2211394,
      lon: 4.2821446,
    },
  },
  {
    column_4: 51.1310863,
    column_3: 4.2646567358993,
    column_2: "Steendorp",
    column_1: "9140",
    geom: null,
    coordonnees: {
      lat: 51.1310863,
      lon: 4.2646567359,
    },
  },
  {
    column_4: 51.1421436,
    column_3: 4.2133505278525,
    column_2: "Temse",
    column_1: "9140",
    geom: null,
    coordonnees: {
      lat: 51.1421436,
      lon: 4.21335052785,
    },
  },
  {
    column_4: 51.1283692,
    column_3: 4.2906315,
    column_2: "Rupelmonde",
    column_1: "9150",
    geom: null,
    coordonnees: {
      lat: 51.1283692,
      lon: 4.2906315,
    },
  },
  {
    column_4: 50.99557415,
    column_3: 4.0652617659305,
    column_2: "Mespelare",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 50.99557415,
      lon: 4.06526176593,
    },
  },
  {
    column_4: 51.06183125,
    column_3: 4.1585956393745,
    column_2: "Moerzeke",
    column_1: "9220",
    geom: null,
    coordonnees: {
      lat: 51.06183125,
      lon: 4.15859563937,
    },
  },
  {
    column_4: 51.0068804,
    column_3: 3.8855494,
    column_2: "Wetteren",
    column_1: "9230",
    geom: null,
    coordonnees: {
      lat: 51.0068804,
      lon: 3.8855494,
    },
  },
  {
    column_4: 51.0286063,
    column_3: 4.2213781726505,
    column_2: "Opdorp",
    column_1: "9255",
    geom: null,
    coordonnees: {
      lat: 51.0286063,
      lon: 4.22137817265,
    },
  },
  {
    column_4: 51.0020843,
    column_3: 3.9806438098974,
    column_2: "Wichelen",
    column_1: "9260",
    geom: null,
    coordonnees: {
      lat: 51.0020843,
      lon: 3.9806438099,
    },
  },
  {
    column_4: 50.9831967,
    column_3: 4.0413684723722,
    column_2: "Gijzegem",
    column_1: "9308",
    geom: null,
    coordonnees: {
      lat: 50.9831967,
      lon: 4.04136847237,
    },
  },
  {
    column_4: 50.92226365,
    column_3: 4.0060975097656,
    column_2: "Nieuwerkerken",
    column_1: "9320",
    geom: null,
    coordonnees: {
      lat: 50.92226365,
      lon: 4.00609750977,
    },
  },
  {
    column_4: 50.9574043,
    column_3: 3.90209,
    column_2: "Oordegem",
    column_1: "9340",
    geom: null,
    coordonnees: {
      lat: 50.9574043,
      lon: 3.90209,
    },
  },
  {
    column_4: 50.819114,
    column_3: 3.9673266115113,
    column_2: "Appelterre-Eichem",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.819114,
      lon: 3.96732661151,
    },
  },
  {
    column_4: 50.7986183,
    column_3: 4.0241352,
    column_2: "Denderwindeke",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.7986183,
      lon: 4.0241352,
    },
  },
  {
    column_4: 50.7918522,
    column_3: 4.0533344,
    column_2: "Lieferinge",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.7918522,
      lon: 4.0533344,
    },
  },
  {
    column_4: 50.803005,
    column_3: 4.0606636,
    column_2: "Neigem",
    column_1: "9403",
    geom: null,
    coordonnees: {
      lat: 50.803005,
      lon: 4.0606636,
    },
  },
  {
    column_4: 50.9341483,
    column_3: 3.9468882,
    column_2: "Ottergem",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.9341483,
      lon: 3.9468882,
    },
  },
  {
    column_4: 50.7689045,
    column_3: 3.879789,
    column_2: "Geraardsbergen",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.7689045,
      lon: 3.879789,
    },
  },
  {
    column_4: 50.7622936,
    column_3: 3.8625157,
    column_2: "Overboelare",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.7622936,
      lon: 3.8625157,
    },
  },
  {
    column_4: 50.92696,
    column_3: 3.8812597,
    column_2: "Letterhoutem",
    column_1: "9521",
    geom: null,
    coordonnees: {
      lat: 50.92696,
      lon: 3.8812597,
    },
  },
  {
    column_4: 50.8920791,
    column_3: 3.9109808,
    column_2: "Ressegem",
    column_1: "9551",
    geom: null,
    coordonnees: {
      lat: 50.8920791,
      lon: 3.9109808,
    },
  },
  {
    column_4: 50.9047179,
    column_3: 3.8945771,
    column_2: "Borsbeke",
    column_1: "9552",
    geom: null,
    coordonnees: {
      lat: 50.9047179,
      lon: 3.8945771,
    },
  },
  {
    column_4: 50.7863579,
    column_3: 3.8409061,
    column_2: "Deftinge",
    column_1: "9570",
    geom: null,
    coordonnees: {
      lat: 50.7863579,
      lon: 3.8409061,
    },
  },
  {
    column_4: 50.8217365,
    column_3: 3.8370299582538,
    column_2: "Sint-Maria-Lierde",
    column_1: "9570",
    geom: null,
    coordonnees: {
      lat: 50.8217365,
      lon: 3.83702995825,
    },
  },
  {
    column_4: 50.872305,
    column_3: 3.8333978,
    column_2: "Grotenberge",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.872305,
      lon: 3.8333978,
    },
  },
  {
    column_4: 50.8880639,
    column_3: 3.829278,
    column_2: "Leeuwergem",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8880639,
      lon: 3.829278,
    },
  },
  {
    column_4: 50.8824839,
    column_3: 3.7825766,
    column_2: "Velzeke-Ruddershove",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8824839,
      lon: 3.7825766,
    },
  },
  {
    column_4: 50.9051876,
    column_3: 3.6992879,
    column_2: "Meilegem",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.9051876,
      lon: 3.6992879,
    },
  },
  {
    column_4: 50.8963806,
    column_3: 3.7148522,
    column_2: "Paulatem",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8963806,
      lon: 3.7148522,
    },
  },
  {
    column_4: 50.890215,
    column_3: 3.7059521,
    column_2: "Sint-Maria-Latem",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.890215,
      lon: 3.7059521,
    },
  },
  {
    column_4: 50.7921614,
    column_3: 3.7463234,
    column_2: "Opbrakel",
    column_1: "9660",
    geom: null,
    coordonnees: {
      lat: 50.7921614,
      lon: 3.7463234,
    },
  },
  {
    column_4: 50.8383442,
    column_3: 3.6875097,
    column_2: "Sint-Maria-Horebeke",
    column_1: "9667",
    geom: null,
    coordonnees: {
      lat: 50.8383442,
      lon: 3.6875097,
    },
  },
  {
    column_4: 50.7973072,
    column_3: 3.642779,
    column_2: "Maarkedal",
    column_1: "9680",
    geom: null,
    coordonnees: {
      lat: 50.7973072,
      lon: 3.642779,
    },
  },
  {
    column_4: 50.8695429,
    column_3: 3.6226347,
    column_2: "Eine",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8695429,
      lon: 3.6226347,
    },
  },
  {
    column_4: 50.8155547,
    column_3: 3.5655354,
    column_2: "Melden",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8155547,
      lon: 3.5655354,
    },
  },
  {
    column_4: 50.8964155,
    column_3: 3.6039655,
    column_2: "Mullem",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8964155,
      lon: 3.6039655,
    },
  },
  {
    column_4: 50.8408663,
    column_3: 3.6356493,
    column_2: "Volkegem",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8408663,
      lon: 3.6356493,
    },
  },
  {
    column_4: 50.9039326,
    column_3: 3.653793,
    column_2: "Zingem",
    column_1: "9750",
    geom: null,
    coordonnees: {
      lat: 50.9039326,
      lon: 3.653793,
    },
  },
  {
    column_4: 50.8247164,
    column_3: 3.5366137,
    column_2: "Elsegem",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.8247164,
      lon: 3.5366137,
    },
  },
  {
    column_4: 50.8330877,
    column_3: 3.5581136,
    column_2: "Petegem-Aan-De-Schelde",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.8330877,
      lon: 3.5581136,
    },
  },
  {
    column_4: 51.0038237,
    column_3: 3.5624453,
    column_2: "Bachte-Maria-Leerne",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 51.0038237,
      lon: 3.5624453,
    },
  },
  {
    column_4: 50.9840526,
    column_3: 3.5274017,
    column_2: "Deinze",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9840526,
      lon: 3.5274017,
    },
  },
  {
    column_4: 50.9646883,
    column_3: 3.4640122,
    column_2: "Gottem",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9646883,
      lon: 3.4640122,
    },
  },
  {
    column_4: 50.9786839,
    column_3: 3.4443004,
    column_2: "Wontergem",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9786839,
      lon: 3.4443004,
    },
  },
  {
    column_4: 50.9967515,
    column_3: 3.5031988,
    column_2: "Zeveren",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9967515,
      lon: 3.5031988,
    },
  },
  {
    column_4: 50.9569377,
    column_3: 3.6418029,
    column_2: "Eke",
    column_1: "9810",
    geom: null,
    coordonnees: {
      lat: 50.9569377,
      lon: 3.6418029,
    },
  },
  {
    column_4: 50.9579283,
    column_3: 3.6928827,
    column_2: "Melsen",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9579283,
      lon: 3.6928827,
    },
  },
  {
    column_4: 51.0000909,
    column_3: 3.6111658724762,
    column_2: "Deurle",
    column_1: "9831",
    geom: null,
    coordonnees: {
      lat: 51.0000909,
      lon: 3.61116587248,
    },
  },
  {
    column_4: 51.0546369,
    column_3: 3.5888091437976,
    column_2: "Landegem",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.0546369,
      lon: 3.5888091438,
    },
  },
  {
    column_4: 51.0330372,
    column_3: 3.5491068,
    column_2: "Nevele",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.0330372,
      lon: 3.5491068,
    },
  },
  {
    column_4: 51.088195,
    column_3: 3.4176803282422,
    column_2: "Aalter",
    column_1: "9880",
    geom: null,
    coordonnees: {
      lat: 51.088195,
      lon: 3.41768032824,
    },
  },
  {
    column_4: 51.0414698,
    column_3: 3.4455642,
    column_2: "Poeke",
    column_1: "9880",
    geom: null,
    coordonnees: {
      lat: 51.0414698,
      lon: 3.4455642,
    },
  },
  {
    column_4: 51.1392692,
    column_3: 3.412339,
    column_2: "Knesselare",
    column_1: "9910",
    geom: null,
    coordonnees: {
      lat: 51.1392692,
      lon: 3.412339,
    },
  },
  {
    column_4: 51.1303816,
    column_3: 3.5532444,
    column_2: "Ronsele",
    column_1: "9932",
    geom: null,
    coordonnees: {
      lat: 51.1303816,
      lon: 3.5532444,
    },
  },
  {
    column_4: 51.131929,
    column_3: 3.6789045,
    column_2: "Sleidinge",
    column_1: "9940",
    geom: null,
    coordonnees: {
      lat: 51.131929,
      lon: 3.6789045,
    },
  },
  {
    column_4: 51.2330483,
    column_3: 3.5374820679851,
    column_2: "Sint-Laureins",
    column_1: "9980",
    geom: null,
    coordonnees: {
      lat: 51.2330483,
      lon: 3.53748206799,
    },
  },
  {
    column_4: 50.8465573,
    column_3: 4.351697,
    column_2: "Bruxelles",
    column_1: "1000",
    geom: null,
    coordonnees: {
      lat: 50.8465573,
      lon: 4.351697,
    },
  },
  {
    column_4: 50.8639835,
    column_3: 4.292702,
    column_2: "Berchem-Sainte-Agathe",
    column_1: "1082",
    geom: null,
    coordonnees: {
      lat: 50.8639835,
      lon: 4.292702,
    },
  },
  {
    column_4: 50.7315053,
    column_3: 4.4796542,
    column_2: "La Hulpe",
    column_1: "1310",
    geom: null,
    coordonnees: {
      lat: 50.7315053,
      lon: 4.4796542,
    },
  },
  {
    column_4: 50.6799948,
    column_3: 4.8384018,
    column_2: "Glimes",
    column_1: "1315",
    geom: null,
    coordonnees: {
      lat: 50.6799948,
      lon: 4.8384018,
    },
  },
  {
    column_4: 50.7311108,
    column_3: 4.7433685,
    column_2: "Piètrebais",
    column_1: "1315",
    geom: null,
    coordonnees: {
      lat: 50.7311108,
      lon: 4.7433685,
    },
  },
  {
    column_4: 50.7810287,
    column_3: 4.7716774,
    column_2: "Beauvechain",
    column_1: "1320",
    geom: null,
    coordonnees: {
      lat: 50.7810287,
      lon: 4.7716774,
    },
  },
  {
    column_4: 50.7819118,
    column_3: 4.7205562,
    column_2: "Hamme-Mille",
    column_1: "1320",
    geom: null,
    coordonnees: {
      lat: 50.7819118,
      lon: 4.7205562,
    },
  },
  {
    column_4: 50.7725266,
    column_3: 4.733891,
    column_2: "Nodebais",
    column_1: "1320",
    geom: null,
    coordonnees: {
      lat: 50.7725266,
      lon: 4.733891,
    },
  },
  {
    column_4: 50.6839808,
    column_3: 4.6974182,
    column_2: "Chaumont-Gistoux",
    column_1: "1325",
    geom: null,
    coordonnees: {
      lat: 50.6839808,
      lon: 4.6974182,
    },
  },
  {
    column_4: 50.7133548,
    column_3: 4.5272896887008,
    column_2: "Rixensart",
    column_1: "1330",
    geom: null,
    coordonnees: {
      lat: 50.7133548,
      lon: 4.5272896887,
    },
  },
  {
    column_4: 50.72074495,
    column_3: 4.4971389344121,
    column_2: "Genval",
    column_1: "1332",
    geom: null,
    coordonnees: {
      lat: 50.72074495,
      lon: 4.49713893441,
    },
  },
  {
    column_4: 50.6851551,
    column_3: 4.5549022761211,
    column_2: "Limelette",
    column_1: "1342",
    geom: null,
    coordonnees: {
      lat: 50.6851551,
      lon: 4.55490227612,
    },
  },
  {
    column_4: 50.6740112,
    column_3: 4.9792652,
    column_2: "Jandrain-Jandrenouille",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.6740112,
      lon: 4.9792652,
    },
  },
  {
    column_4: 50.7001001,
    column_3: 4.9889872,
    column_2: "Orp-Jauche",
    column_1: "1350",
    geom: null,
    coordonnees: {
      lat: 50.7001001,
      lon: 4.9889872,
    },
  },
  {
    column_4: 50.6564717,
    column_3: 4.7801225,
    column_2: "Malèves-Sainte-Marie-Wastines",
    column_1: "1360",
    geom: null,
    coordonnees: {
      lat: 50.6564717,
      lon: 4.7801225,
    },
  },
  {
    column_4: 50.6357579,
    column_3: 4.7839333,
    column_2: "Thorembais-Saint-Trond",
    column_1: "1360",
    geom: null,
    coordonnees: {
      lat: 50.6357579,
      lon: 4.7839333,
    },
  },
  {
    column_4: 50.6467516,
    column_3: 4.8807352,
    column_2: "Gérompont",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6467516,
      lon: 4.8807352,
    },
  },
  {
    column_4: 50.7400918,
    column_3: 4.8286208,
    column_2: "Mélin",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7400918,
      lon: 4.8286208,
    },
  },
  {
    column_4: 50.7255018,
    column_3: 4.9175477,
    column_2: "Piétrain",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7255018,
      lon: 4.9175477,
    },
  },
  {
    column_4: 50.7592057,
    column_3: 4.8838518,
    column_2: "Zétrud-Lumay",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.7592057,
      lon: 4.8838518,
    },
  },
  {
    column_4: 50.6927451,
    column_3: 4.5004885,
    column_2: "Lasne-Chapelle-Saint-Lambert",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.6927451,
      lon: 4.5004885,
    },
  },
  {
    column_4: 50.7616193,
    column_3: 4.6990692,
    column_2: "Bossut-Gottechain",
    column_1: "1390",
    geom: null,
    coordonnees: {
      lat: 50.7616193,
      lon: 4.6990692,
    },
  },
  {
    column_4: 50.5931607,
    column_3: 4.2860216341575,
    column_2: "Monstreux",
    column_1: "1400",
    geom: null,
    coordonnees: {
      lat: 50.5931607,
      lon: 4.28602163416,
    },
  },
  {
    column_4: 50.6048658,
    column_3: 4.2649262209555,
    column_2: "Bornival",
    column_1: "1404",
    geom: null,
    coordonnees: {
      lat: 50.6048658,
      lon: 4.26492622096,
    },
  },
  {
    column_4: 50.7173563,
    column_3: 4.3978047,
    column_2: "Waterloo",
    column_1: "1410",
    geom: null,
    coordonnees: {
      lat: 50.7173563,
      lon: 4.3978047,
    },
  },
  {
    column_4: 50.65630215,
    column_3: 4.3507902353934,
    column_2: "Ophain-Bois-Seigneur-Isaac",
    column_1: "1421",
    geom: null,
    coordonnees: {
      lat: 50.65630215,
      lon: 4.35079023539,
    },
  },
  {
    column_4: 50.6483743,
    column_3: 4.3670582,
    column_2: "Lillois-Witterzée",
    column_1: "1428",
    geom: null,
    coordonnees: {
      lat: 50.6483743,
      lon: 4.3670582,
    },
  },
  {
    column_4: 50.6106296,
    column_3: 4.6377941108725,
    column_2: "Chastre-Villeroux-Blanmont",
    column_1: "1450",
    geom: null,
    coordonnees: {
      lat: 50.6106296,
      lon: 4.63779411087,
    },
  },
  {
    column_4: 50.5908074,
    column_3: 4.6406799,
    column_2: "Cortil-Noirmont",
    column_1: "1450",
    geom: null,
    coordonnees: {
      lat: 50.5908074,
      lon: 4.6406799,
    },
  },
  {
    column_4: 50.6395479,
    column_3: 4.7220185,
    column_2: "Tourinnes-Saint-Lambert",
    column_1: "1457",
    geom: null,
    coordonnees: {
      lat: 50.6395479,
      lon: 4.7220185,
    },
  },
  {
    column_4: 50.6413403,
    column_3: 4.2199759440369,
    column_2: "Virginal-Samme",
    column_1: "1460",
    geom: null,
    coordonnees: {
      lat: 50.6413403,
      lon: 4.21997594404,
    },
  },
  {
    column_4: 50.6488039,
    column_3: 4.2964719513591,
    column_2: "Haut-Ittre",
    column_1: "1461",
    geom: null,
    coordonnees: {
      lat: 50.6488039,
      lon: 4.29647195136,
    },
  },
  {
    column_4: 50.6040225,
    column_3: 4.4880778189719,
    column_2: "Baisy-Thy",
    column_1: "1470",
    geom: null,
    coordonnees: {
      lat: 50.6040225,
      lon: 4.48807781897,
    },
  },
  {
    column_4: 50.60800575,
    column_3: 4.4479459851741,
    column_2: "Genappe",
    column_1: "1470",
    geom: null,
    coordonnees: {
      lat: 50.60800575,
      lon: 4.44794598517,
    },
  },
  {
    column_4: 50.6893737,
    column_3: 4.2214131,
    column_2: "Clabecq",
    column_1: "1480",
    geom: null,
    coordonnees: {
      lat: 50.6893737,
      lon: 4.2214131,
    },
  },
  {
    column_4: 50.6700726,
    column_3: 4.2167874,
    column_2: "Oisquercq",
    column_1: "1480",
    geom: null,
    coordonnees: {
      lat: 50.6700726,
      lon: 4.2167874,
    },
  },
  {
    column_4: 50.70737205,
    column_3: 4.1600612857846,
    column_2: "Saintes",
    column_1: "1480",
    geom: null,
    coordonnees: {
      lat: 50.70737205,
      lon: 4.16006128578,
    },
  },
  {
    column_4: 50.6443208,
    column_3: 4.5685766,
    column_2: "Court-Saint-Etienne",
    column_1: "1490",
    geom: null,
    coordonnees: {
      lat: 50.6443208,
      lon: 4.5685766,
    },
  },
  {
    column_4: 50.7092954,
    column_3: 4.2143430588907,
    column_2: "Lembeek",
    column_1: "1502",
    geom: null,
    coordonnees: {
      lat: 50.7092954,
      lon: 4.21434305889,
    },
  },
  {
    column_4: 50.7674846,
    column_3: 4.474407,
    column_2: "Hoeilaart",
    column_1: "1560",
    geom: null,
    coordonnees: {
      lat: 50.7674846,
      lon: 4.474407,
    },
  },
  {
    column_4: 50.7430853,
    column_3: 4.004789341376,
    column_2: "Tollembeek",
    column_1: "1570",
    geom: null,
    coordonnees: {
      lat: 50.7430853,
      lon: 4.00478934138,
    },
  },
  {
    column_4: 50.73423975,
    column_3: 4.2950152833964,
    column_2: "Dworp",
    column_1: "1653",
    geom: null,
    coordonnees: {
      lat: 50.73423975,
      lon: 4.2950152834,
    },
  },
  {
    column_4: 50.7398062,
    column_3: 4.1367803,
    column_2: "Bogaarden",
    column_1: "1670",
    geom: null,
    coordonnees: {
      lat: 50.7398062,
      lon: 4.1367803,
    },
  },
  {
    column_4: 50.8363077,
    column_3: 4.195149072634,
    column_2: "Schepdaal",
    column_1: "1703",
    geom: null,
    coordonnees: {
      lat: 50.8363077,
      lon: 4.19514907263,
    },
  },
  {
    column_4: 50.8853713,
    column_3: 4.2381789,
    column_2: "Bekkerzeel",
    column_1: "1730",
    geom: null,
    coordonnees: {
      lat: 50.8853713,
      lon: 4.2381789,
    },
  },
  {
    column_4: 50.9328497,
    column_3: 4.2239826,
    column_2: "Mollem",
    column_1: "1730",
    geom: null,
    coordonnees: {
      lat: 50.9328497,
      lon: 4.2239826,
    },
  },
  {
    column_4: 50.9019808,
    column_3: 4.2790265927797,
    column_2: "Relegem",
    column_1: "1731",
    geom: null,
    coordonnees: {
      lat: 50.9019808,
      lon: 4.27902659278,
    },
  },
  {
    column_4: 50.7998923,
    column_3: 4.1888025,
    column_2: "Gaasbeek",
    column_1: "1750",
    geom: null,
    coordonnees: {
      lat: 50.7998923,
      lon: 4.1888025,
    },
  },
  {
    column_4: 50.7979738,
    column_3: 4.1029840674511,
    column_2: "Gooik",
    column_1: "1755",
    geom: null,
    coordonnees: {
      lat: 50.7979738,
      lon: 4.10298406745,
    },
  },
  {
    column_4: 50.8220387,
    column_3: 4.1119573,
    column_2: "Onze-Lieve-Vrouw-Lombeek",
    column_1: "1760",
    geom: null,
    coordonnees: {
      lat: 50.8220387,
      lon: 4.1119573,
    },
  },
  {
    column_4: 50.8973851,
    column_3: 4.1390421,
    column_2: "Essene",
    column_1: "1790",
    geom: null,
    coordonnees: {
      lat: 50.8973851,
      lon: 4.1390421,
    },
  },
  {
    column_4: 50.92813655,
    column_3: 4.4329052514838,
    column_2: "Vilvoorde",
    column_1: "1800",
    geom: null,
    coordonnees: {
      lat: 50.92813655,
      lon: 4.43290525148,
    },
  },
  {
    column_4: 50.9124219,
    column_3: 4.4353614,
    column_2: "Machelen",
    column_1: "1830",
    geom: null,
    coordonnees: {
      lat: 50.9124219,
      lon: 4.4353614,
    },
  },
  {
    column_4: 51.0122466,
    column_3: 4.2990729416338,
    column_2: "Londerzeel",
    column_1: "1840",
    geom: null,
    coordonnees: {
      lat: 51.0122466,
      lon: 4.29907294163,
    },
  },
  {
    column_4: 50.91096285,
    column_3: 4.342355501959,
    column_2: "Strombeek-Bever",
    column_1: "1853",
    geom: null,
    coordonnees: {
      lat: 50.91096285,
      lon: 4.34235550196,
    },
  },
  {
    column_4: 50.9510266,
    column_3: 4.3085198,
    column_2: "Wolvertem",
    column_1: "1861",
    geom: null,
    coordonnees: {
      lat: 50.9510266,
      lon: 4.3085198,
    },
  },
  {
    column_4: 51.0114961,
    column_3: 4.3374964007802,
    column_2: "Ramsdonk",
    column_1: "1880",
    geom: null,
    coordonnees: {
      lat: 51.0114961,
      lon: 4.33749640078,
    },
  },
  {
    column_4: 50.8440554,
    column_3: 4.4871796,
    column_2: "Wezembeek-Oppem",
    column_1: "1970",
    geom: null,
    coordonnees: {
      lat: 50.8440554,
      lon: 4.4871796,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2020",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.2028046,
    column_3: 4.3416362,
    column_2: "Burcht",
    column_1: "2070",
    geom: null,
    coordonnees: {
      lat: 51.2028046,
      lon: 4.3416362,
    },
  },
  {
    column_4: 51.23062085,
    column_3: 4.3188693282188,
    column_2: "Zwijndrecht",
    column_1: "2070",
    geom: null,
    coordonnees: {
      lat: 51.23062085,
      lon: 4.31886932822,
    },
  },
  {
    column_4: 51.2270761,
    column_3: 4.5225318,
    column_2: "Wijnegem",
    column_1: "2110",
    geom: null,
    coordonnees: {
      lat: 51.2270761,
      lon: 4.5225318,
    },
  },
  {
    column_4: 51.2031044,
    column_3: 4.5238022,
    column_2: "Wommelgem",
    column_1: "2160",
    geom: null,
    coordonnees: {
      lat: 51.2031044,
      lon: 4.5238022,
    },
  },
  {
    column_4: 51.10403005,
    column_3: 4.731894344605,
    column_2: "Itegem",
    column_1: "2222",
    geom: null,
    coordonnees: {
      lat: 51.10403005,
      lon: 4.73189434461,
    },
  },
  {
    column_4: 51.0554081,
    column_3: 4.9018080031354,
    column_2: "Herselt",
    column_1: "2230",
    geom: null,
    coordonnees: {
      lat: 51.0554081,
      lon: 4.90180800314,
    },
  },
  {
    column_4: 51.187272,
    column_3: 4.6503063,
    column_2: "Viersel",
    column_1: "2240",
    geom: null,
    coordonnees: {
      lat: 51.187272,
      lon: 4.6503063,
    },
  },
  {
    column_4: 51.1438611,
    column_3: 4.8597257,
    column_2: "Olen",
    column_1: "2250",
    geom: null,
    coordonnees: {
      lat: 51.1438611,
      lon: 4.8597257,
    },
  },
  {
    column_4: 51.1377568,
    column_3: 4.9053101,
    column_2: "Oevel",
    column_1: "2260",
    geom: null,
    coordonnees: {
      lat: 51.1377568,
      lon: 4.9053101,
    },
  },
  {
    column_4: 51.1392427,
    column_3: 4.7543972,
    column_2: "Herenthout",
    column_1: "2270",
    geom: null,
    coordonnees: {
      lat: 51.1392427,
      lon: 4.7543972,
    },
  },
  {
    column_4: 51.2267185,
    column_3: 4.8359528,
    column_2: "Poederlee",
    column_1: "2275",
    geom: null,
    coordonnees: {
      lat: 51.2267185,
      lon: 4.8359528,
    },
  },
  {
    column_4: 51.26541275,
    column_3: 4.7910582697468,
    column_2: "Wechelderzande",
    column_1: "2275",
    geom: null,
    coordonnees: {
      lat: 51.26541275,
      lon: 4.79105826975,
    },
  },
  {
    column_4: 51.2023448,
    column_3: 4.769482,
    column_2: "Vorselaar",
    column_1: "2290",
    geom: null,
    coordonnees: {
      lat: 51.2023448,
      lon: 4.769482,
    },
  },
  {
    column_4: 51.46092495,
    column_3: 4.7372548575575,
    column_2: "Meer",
    column_1: "2321",
    geom: null,
    coordonnees: {
      lat: 51.46092495,
      lon: 4.73725485756,
    },
  },
  {
    column_4: 51.4743516,
    column_3: 4.8051679,
    column_2: "Meerle",
    column_1: "2328",
    geom: null,
    coordonnees: {
      lat: 51.4743516,
      lon: 4.8051679,
    },
  },
  {
    column_4: 51.3129153,
    column_3: 4.8878026,
    column_2: "Vosselaar",
    column_1: "2350",
    geom: null,
    coordonnees: {
      lat: 51.3129153,
      lon: 4.8878026,
    },
  },
  {
    column_4: 51.0802695,
    column_3: 5.0073022,
    column_2: "Laakdal",
    column_1: "2430",
    geom: null,
    coordonnees: {
      lat: 51.0802695,
      lon: 5.0073022,
    },
  },
  {
    column_4: 51.2422595,
    column_3: 4.8965455,
    column_2: "Tielen",
    column_1: "2460",
    geom: null,
    coordonnees: {
      lat: 51.2422595,
      lon: 4.8965455,
    },
  },
  {
    column_4: 51.0929433,
    column_3: 4.6033828110877,
    column_2: "Koningshooikt",
    column_1: "2500",
    geom: null,
    coordonnees: {
      lat: 51.0929433,
      lon: 4.60338281109,
    },
  },
  {
    column_4: 51.1961732,
    column_3: 4.5637563614009,
    column_2: "Ranst",
    column_1: "2520",
    geom: null,
    coordonnees: {
      lat: 51.1961732,
      lon: 4.5637563614,
    },
  },
  {
    column_4: 51.1596941,
    column_3: 4.510406865149,
    column_2: "Boechout",
    column_1: "2530",
    geom: null,
    coordonnees: {
      lat: 51.1596941,
      lon: 4.51040686515,
    },
  },
  {
    column_4: 51.1753099,
    column_3: 4.5226229,
    column_2: "Vremde",
    column_1: "2531",
    geom: null,
    coordonnees: {
      lat: 51.1753099,
      lon: 4.5226229,
    },
  },
  {
    column_4: 51.0570823,
    column_3: 4.6310473,
    column_2: "Putte",
    column_1: "2580",
    geom: null,
    coordonnees: {
      lat: 51.0570823,
      lon: 4.6310473,
    },
  },
  {
    column_4: 51.1918493,
    column_3: 4.4317026387884,
    column_2: "Berchem",
    column_1: "2600",
    geom: null,
    coordonnees: {
      lat: 51.1918493,
      lon: 4.43170263879,
    },
  },
  {
    column_4: 51.1741789,
    column_3: 4.4593335,
    column_2: "Mortsel",
    column_1: "2640",
    geom: null,
    coordonnees: {
      lat: 51.1741789,
      lon: 4.4593335,
    },
  },
  {
    column_4: 51.078277,
    column_3: 4.3993623,
    column_2: "Terhagen",
    column_1: "2840",
    geom: null,
    coordonnees: {
      lat: 51.078277,
      lon: 4.3993623,
    },
  },
  {
    column_4: 51.1098649,
    column_3: 4.3303396,
    column_2: "Niel",
    column_1: "2845",
    geom: null,
    coordonnees: {
      lat: 51.1098649,
      lon: 4.3303396,
    },
  },
  {
    column_4: 51.0463409,
    column_3: 4.2107348196593,
    column_2: "Sint-Amands",
    column_1: "2890",
    geom: null,
    coordonnees: {
      lat: 51.0463409,
      lon: 4.21073481966,
    },
  },
  {
    column_4: 51.2516344,
    column_3: 4.4980446,
    column_2: "Schoten",
    column_1: "2900",
    geom: null,
    coordonnees: {
      lat: 51.2516344,
      lon: 4.4980446,
    },
  },
  {
    column_4: 50.926428,
    column_3: 4.7001214,
    column_2: "Wijgmaal",
    column_1: "3018",
    geom: null,
    coordonnees: {
      lat: 50.926428,
      lon: 4.7001214,
    },
  },
  {
    column_4: 50.8419887,
    column_3: 4.5905043649411,
    column_2: "Leefdaal",
    column_1: "3061",
    geom: null,
    coordonnees: {
      lat: 50.8419887,
      lon: 4.59050436494,
    },
  },
  {
    column_4: 50.87266065,
    column_3: 4.5545949589597,
    column_2: "Everberg",
    column_1: "3078",
    geom: null,
    coordonnees: {
      lat: 50.87266065,
      lon: 4.55459495896,
    },
  },
  {
    column_4: 50.8110528,
    column_3: 4.545208431698,
    column_2: "Duisburg",
    column_1: "3080",
    geom: null,
    coordonnees: {
      lat: 50.8110528,
      lon: 4.5452084317,
    },
  },
  {
    column_4: 50.9900457,
    column_3: 4.6755134,
    column_2: "Tremelo",
    column_1: "3120",
    geom: null,
    coordonnees: {
      lat: 50.9900457,
      lon: 4.6755134,
    },
  },
  {
    column_4: 51.0049242,
    column_3: 4.6265551,
    column_2: "Keerbergen",
    column_1: "3140",
    geom: null,
    coordonnees: {
      lat: 51.0049242,
      lon: 4.6265551,
    },
  },
  {
    column_4: 50.97828585,
    column_3: 4.575800525,
    column_2: "Boortmeerbeek",
    column_1: "3190",
    geom: null,
    coordonnees: {
      lat: 50.97828585,
      lon: 4.575800525,
    },
  },
  {
    column_4: 50.89891075,
    column_3: 4.7756847507269,
    column_2: "Linden",
    column_1: "3210",
    geom: null,
    coordonnees: {
      lat: 50.89891075,
      lon: 4.77568475073,
    },
  },
  {
    column_4: 50.8721422,
    column_3: 4.8908885858013,
    column_2: "Binkom",
    column_1: "3211",
    geom: null,
    coordonnees: {
      lat: 50.8721422,
      lon: 4.8908885858,
    },
  },
  {
    column_4: 50.9470265,
    column_3: 4.8338045392342,
    column_2: "Nieuwrode",
    column_1: "3221",
    geom: null,
    coordonnees: {
      lat: 50.9470265,
      lon: 4.83380453923,
    },
  },
  {
    column_4: 51.0130411,
    column_3: 5.086792549946,
    column_2: "Schaffen",
    column_1: "3290",
    geom: null,
    coordonnees: {
      lat: 51.0130411,
      lon: 5.08679254995,
    },
  },
  {
    column_4: 50.9649291,
    column_3: 5.054414061955,
    column_2: "Webbekom",
    column_1: "3290",
    geom: null,
    coordonnees: {
      lat: 50.9649291,
      lon: 5.05441406195,
    },
  },
  {
    column_4: 50.9776976,
    column_3: 5.0299404926122,
    column_2: "Kaggevinne",
    column_1: "3293",
    geom: null,
    coordonnees: {
      lat: 50.9776976,
      lon: 5.02994049261,
    },
  },
  {
    column_4: 50.77139835,
    column_3: 4.9603301209235,
    column_2: "Goetsenhoven",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.77139835,
      lon: 4.96033012092,
    },
  },
  {
    column_4: 50.8245511,
    column_3: 4.8863798378559,
    column_2: "Kumtich",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.8245511,
      lon: 4.88637983786,
    },
  },
  {
    column_4: 50.83464645,
    column_3: 4.9623805201831,
    column_2: "Sint-Margriete-Houtem",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.83464645,
      lon: 4.96238052018,
    },
  },
  {
    column_4: 50.80843175,
    column_3: 4.9464292134294,
    column_2: "Tienen",
    column_1: "3300",
    geom: null,
    coordonnees: {
      lat: 50.80843175,
      lon: 4.94642921343,
    },
  },
  {
    column_4: 50.78956675,
    column_3: 4.8294566856141,
    column_2: "Meldert",
    column_1: "3320",
    geom: null,
    coordonnees: {
      lat: 50.78956675,
      lon: 4.82945668561,
    },
  },
  {
    column_4: 50.8054143,
    column_3: 5.0674769,
    column_2: "Orsmaal-Gussenhoven",
    column_1: "3350",
    geom: null,
    coordonnees: {
      lat: 50.8054143,
      lon: 5.0674769,
    },
  },
  {
    column_4: 50.8241245,
    column_3: 4.7714318076029,
    column_2: "Bierbeek",
    column_1: "3360",
    geom: null,
    coordonnees: {
      lat: 50.8241245,
      lon: 4.7714318076,
    },
  },
  {
    column_4: 50.80664425,
    column_3: 4.8387022883011,
    column_2: "Willebringen",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.80664425,
      lon: 4.8387022883,
    },
  },
  {
    column_4: 50.93414405,
    column_3: 4.8605997436813,
    column_2: "Houwaart",
    column_1: "3390",
    geom: null,
    coordonnees: {
      lat: 50.93414405,
      lon: 4.86059974368,
    },
  },
  {
    column_4: 50.9121762,
    column_3: 4.8754116537611,
    column_2: "Sint-Joris-Winge",
    column_1: "3390",
    geom: null,
    coordonnees: {
      lat: 50.9121762,
      lon: 4.87541165376,
    },
  },
  {
    column_4: 50.7838394,
    column_3: 5.0302723897201,
    column_2: "Wange",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.7838394,
      lon: 5.03027238972,
    },
  },
  {
    column_4: 50.8653457,
    column_3: 5.0871237674967,
    column_2: "Budingen",
    column_1: "3440",
    geom: null,
    coordonnees: {
      lat: 50.8653457,
      lon: 5.0871237675,
    },
  },
  {
    column_4: 50.8063449,
    column_3: 5.0920421498928,
    column_2: "Dormaal",
    column_1: "3440",
    geom: null,
    coordonnees: {
      lat: 50.8063449,
      lon: 5.09204214989,
    },
  },
  {
    column_4: 50.8415653,
    column_3: 5.1186447480147,
    column_2: "Zoutleeuw",
    column_1: "3440",
    geom: null,
    coordonnees: {
      lat: 50.8415653,
      lon: 5.11864474801,
    },
  },
  {
    column_4: 50.9338977,
    column_3: 4.984616971522,
    column_2: "Bekkevoort",
    column_1: "3460",
    geom: null,
    coordonnees: {
      lat: 50.9338977,
      lon: 4.98461697152,
    },
  },
  {
    column_4: 50.9129375,
    column_3: 5.0037716328589,
    column_2: "Waanrode",
    column_1: "3473",
    geom: null,
    coordonnees: {
      lat: 50.9129375,
      lon: 5.00377163286,
    },
  },
  {
    column_4: 50.90358435,
    column_3: 5.3119462785891,
    column_2: "Sint-Lambrechts-Herk",
    column_1: "3500",
    geom: null,
    coordonnees: {
      lat: 50.90358435,
      lon: 5.31194627859,
    },
  },
  {
    column_4: 50.97085355,
    column_3: 5.2747491372719,
    column_2: "Stokrooie",
    column_1: "3511",
    geom: null,
    coordonnees: {
      lat: 50.97085355,
      lon: 5.27474913727,
    },
  },
  {
    column_4: 51.036563,
    column_3: 5.4121547136036,
    column_2: "Houthalen",
    column_1: "3530",
    geom: null,
    coordonnees: {
      lat: 51.036563,
      lon: 5.4121547136,
    },
  },
  {
    column_4: 50.9582088,
    column_3: 4.9053567,
    column_2: "Donk",
    column_1: "3540",
    geom: null,
    coordonnees: {
      lat: 50.9582088,
      lon: 4.9053567,
    },
  },
  {
    column_4: 50.9321193,
    column_3: 5.0656234079577,
    column_2: "Loksbergen",
    column_1: "3545",
    geom: null,
    coordonnees: {
      lat: 50.9321193,
      lon: 5.06562340796,
    },
  },
  {
    column_4: 51.0233485,
    column_3: 5.2750485,
    column_2: "Heusden-Zolder",
    column_1: "3550",
    geom: null,
    coordonnees: {
      lat: 51.0233485,
      lon: 5.2750485,
    },
  },
  {
    column_4: 51.0236553,
    column_3: 5.3135203,
    column_2: "Zolder",
    column_1: "3550",
    geom: null,
    coordonnees: {
      lat: 51.0236553,
      lon: 5.3135203,
    },
  },
  {
    column_4: 50.9657859,
    column_3: 5.1372552,
    column_2: "Linkhout",
    column_1: "3560",
    geom: null,
    coordonnees: {
      lat: 50.9657859,
      lon: 5.1372552,
    },
  },
  {
    column_4: 50.9264738,
    column_3: 5.7199198507341,
    column_2: "Uikhoven",
    column_1: "3631",
    geom: null,
    coordonnees: {
      lat: 50.9264738,
      lon: 5.71991985073,
    },
  },
  {
    column_4: 51.13225635,
    column_3: 5.7881529659009,
    column_2: "Ophoven",
    column_1: "3640",
    geom: null,
    coordonnees: {
      lat: 51.13225635,
      lon: 5.7881529659,
    },
  },
  {
    column_4: 51.01085805,
    column_3: 5.6747570297643,
    column_2: "Lanklaar",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.01085805,
      lon: 5.67475702976,
    },
  },
  {
    column_4: 51.0182495,
    column_3: 5.6161660037867,
    column_2: "Niel-Bij-As",
    column_1: "3668",
    geom: null,
    coordonnees: {
      lat: 51.0182495,
      lon: 5.61616600379,
    },
  },
  {
    column_4: 51.0946499,
    column_3: 5.7027136767689,
    column_2: "Neeroeteren",
    column_1: "3680",
    geom: null,
    coordonnees: {
      lat: 51.0946499,
      lon: 5.70271367677,
    },
  },
  {
    column_4: 50.8067083,
    column_3: 5.4301056,
    column_2: "Overrepen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8067083,
      lon: 5.4301056,
    },
  },
  {
    column_4: 50.8065023,
    column_3: 5.4616724,
    column_2: "Riksingen",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.8065023,
      lon: 5.4616724,
    },
  },
  {
    column_4: 50.747053,
    column_3: 5.4421316,
    column_2: "Rutten",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.747053,
      lon: 5.4421316,
    },
  },
  {
    column_4: 50.7810151,
    column_3: 5.4648131,
    column_2: "Tongeren",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7810151,
      lon: 5.4648131,
    },
  },
  {
    column_4: 50.8667966,
    column_3: 5.4298574663068,
    column_2: "Vliermaalroot",
    column_1: "3721",
    geom: null,
    coordonnees: {
      lat: 50.8667966,
      lon: 5.42985746631,
    },
  },
  {
    column_4: 50.85566395,
    column_3: 5.4073456316551,
    column_2: "Wintershoven",
    column_1: "3722",
    geom: null,
    coordonnees: {
      lat: 50.85566395,
      lon: 5.40734563166,
    },
  },
  {
    column_4: 50.8500788,
    column_3: 5.4865309,
    column_2: "Hoeselt",
    column_1: "3730",
    geom: null,
    coordonnees: {
      lat: 50.8500788,
      lon: 5.4865309,
    },
  },
  {
    column_4: 50.8379653,
    column_3: 5.5473874,
    column_2: "Kleine-Spouwen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8379653,
      lon: 5.5473874,
    },
  },
  {
    column_4: 50.862295,
    column_3: 5.5760673,
    column_2: "Mopertingen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.862295,
      lon: 5.5760673,
    },
  },
  {
    column_4: 50.8883348,
    column_3: 5.5270242,
    column_2: "Munsterbilzen",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8883348,
      lon: 5.5270242,
    },
  },
  {
    column_4: 50.8523326,
    column_3: 5.5340486411054,
    column_2: "Martenslinde",
    column_1: "3742",
    geom: null,
    coordonnees: {
      lat: 50.8523326,
      lon: 5.53404864111,
    },
  },
  {
    column_4: 50.8141622,
    column_3: 5.6692203,
    column_2: "Kanne",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8141622,
      lon: 5.6692203,
    },
  },
  {
    column_4: 50.8115825,
    column_3: 5.5978716,
    column_2: "Riemst",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.8115825,
      lon: 5.5978716,
    },
  },
  {
    column_4: 50.828018,
    column_3: 5.6374847,
    column_2: "Vroenhoven",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.828018,
      lon: 5.6374847,
    },
  },
  {
    column_4: 50.7943475,
    column_3: 5.6153404,
    column_2: "Zichen-Zussen-Bolder",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.7943475,
      lon: 5.6153404,
    },
  },
  {
    column_4: 50.8477735,
    column_3: 5.1519921,
    column_2: "Runkelen",
    column_1: "3803",
    geom: null,
    coordonnees: {
      lat: 50.8477735,
      lon: 5.1519921,
    },
  },
  {
    column_4: 50.81773655,
    column_3: 5.1423025876022,
    column_2: "Wilderen",
    column_1: "3803",
    geom: null,
    coordonnees: {
      lat: 50.81773655,
      lon: 5.1423025876,
    },
  },
  {
    column_4: 50.78522885,
    column_3: 5.3823531554707,
    column_2: "Bommershoven",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.78522885,
      lon: 5.38235315547,
    },
  },
  {
    column_4: 50.8004351,
    column_3: 5.3569929252994,
    column_2: "Borgloon",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.8004351,
      lon: 5.3569929253,
    },
  },
  {
    column_4: 50.81158915,
    column_3: 5.2852748851765,
    column_2: "Hoepertingen",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.81158915,
      lon: 5.28527488518,
    },
  },
  {
    column_4: 50.8058717,
    column_3: 5.3905151,
    column_2: "Jesseren",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.8058717,
      lon: 5.3905151,
    },
  },
  {
    column_4: 50.7381794,
    column_3: 5.2807989,
    column_2: "Batsheers",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7381794,
      lon: 5.2807989,
    },
  },
  {
    column_4: 50.7456072,
    column_3: 5.2314326932833,
    column_2: "Boekhout",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.7456072,
      lon: 5.23143269328,
    },
  },
  {
    column_4: 50.73257605,
    column_3: 5.2095391018082,
    column_2: "Jeuk",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.73257605,
      lon: 5.20953910181,
    },
  },
  {
    column_4: 50.7404614,
    column_3: 5.14193594683,
    column_2: "Niel-Bij-Sint-Truiden",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.7404614,
      lon: 5.14193594683,
    },
  },
  {
    column_4: 50.705232,
    column_3: 5.1713288748516,
    column_2: "Vorsen",
    column_1: "3890",
    geom: null,
    coordonnees: {
      lat: 50.705232,
      lon: 5.17132887485,
    },
  },
  {
    column_4: 51.2289746,
    column_3: 5.4314419,
    column_2: "Neerpelt",
    column_1: "3910",
    geom: null,
    coordonnees: {
      lat: 51.2289746,
      lon: 5.4314419,
    },
  },
  {
    column_4: 51.2547636,
    column_3: 5.5128696,
    column_2: "Hamont-Achel",
    column_1: "3930",
    geom: null,
    coordonnees: {
      lat: 51.2547636,
      lon: 5.5128696,
    },
  },
  {
    column_4: 51.10876505,
    column_3: 5.1838556043202,
    column_2: "Oostham",
    column_1: "3945",
    geom: null,
    coordonnees: {
      lat: 51.10876505,
      lon: 5.18385560432,
    },
  },
  {
    column_4: 51.19391715,
    column_3: 5.5172805548337,
    column_2: "Kaulille",
    column_1: "3950",
    geom: null,
    coordonnees: {
      lat: 51.19391715,
      lon: 5.51728055483,
    },
  },
  {
    column_4: 51.1304481,
    column_3: 5.5648743785157,
    column_2: "Gerdingen",
    column_1: "3960",
    geom: null,
    coordonnees: {
      lat: 51.1304481,
      lon: 5.56487437852,
    },
  },
  {
    column_4: 51.1305517,
    column_3: 5.6788533966978,
    column_2: "Tongerlo",
    column_1: "3960",
    geom: null,
    coordonnees: {
      lat: 51.1305517,
      lon: 5.6788533967,
    },
  },
  {
    column_4: 51.1220584,
    column_3: 5.2638924294785,
    column_2: "Leopoldsburg",
    column_1: "3970",
    geom: null,
    coordonnees: {
      lat: 51.1220584,
      lon: 5.26389242948,
    },
  },
  {
    column_4: 51.1726086,
    column_3: 5.4623610101885,
    column_2: "Kleine-Brogel",
    column_1: "3990",
    geom: null,
    coordonnees: {
      lat: 51.1726086,
      lon: 5.46236101019,
    },
  },
  {
    column_4: 51.10874,
    column_3: 5.4491572190061,
    column_2: "Peer",
    column_1: "3990",
    geom: null,
    coordonnees: {
      lat: 51.10874,
      lon: 5.44915721901,
    },
  },
  {
    column_4: 50.678042,
    column_3: 5.547616,
    column_2: "Rocourt",
    column_1: "4000",
    geom: null,
    coordonnees: {
      lat: 50.678042,
      lon: 5.547616,
    },
  },
  {
    column_4: 50.6412964,
    column_3: 5.6111443,
    column_2: "Bressoux",
    column_1: "4020",
    geom: null,
    coordonnees: {
      lat: 50.6412964,
      lon: 5.6111443,
    },
  },
  {
    column_4: 50.6431909,
    column_3: 5.6301266,
    column_2: "Jupille-Sur-Meuse",
    column_1: "4020",
    geom: null,
    coordonnees: {
      lat: 50.6431909,
      lon: 5.6301266,
    },
  },
  {
    column_4: 50.670247,
    column_3: 5.6596627,
    column_2: "Wandre",
    column_1: "4020",
    geom: null,
    coordonnees: {
      lat: 50.670247,
      lon: 5.6596627,
    },
  },
  {
    column_4: 50.6018717,
    column_3: 5.6335963,
    column_2: "Vaux-Sous-Chèvremont",
    column_1: "4051",
    geom: null,
    coordonnees: {
      lat: 50.6018717,
      lon: 5.6335963,
    },
  },
  {
    column_4: 50.5742979,
    column_3: 5.5355698,
    column_2: "Boncelles",
    column_1: "4100",
    geom: null,
    coordonnees: {
      lat: 50.5742979,
      lon: 5.5355698,
    },
  },
  {
    column_4: 50.5966392,
    column_3: 5.5083375,
    column_2: "Seraing",
    column_1: "4100",
    geom: null,
    coordonnees: {
      lat: 50.5966392,
      lon: 5.5083375,
    },
  },
  {
    column_4: 50.6168289,
    column_3: 5.5049541,
    column_2: "Jemeppe-Sur-Meuse",
    column_1: "4101",
    geom: null,
    coordonnees: {
      lat: 50.6168289,
      lon: 5.5049541,
    },
  },
  {
    column_4: 50.5337249,
    column_3: 5.5757727440324,
    column_2: "Esneux",
    column_1: "4130",
    geom: null,
    coordonnees: {
      lat: 50.5337249,
      lon: 5.57577274403,
    },
  },
  {
    column_4: 50.5613241,
    column_3: 5.594232762079,
    column_2: "Tilff",
    column_1: "4130",
    geom: null,
    coordonnees: {
      lat: 50.5613241,
      lon: 5.59423276208,
    },
  },
  {
    column_4: 50.54506305,
    column_3: 5.6835772963268,
    column_2: "Gomzé-Andoumont",
    column_1: "4140",
    geom: null,
    coordonnees: {
      lat: 50.54506305,
      lon: 5.68357729633,
    },
  },
  {
    column_4: 50.481662,
    column_3: 5.5225261,
    column_2: "Anthisnes",
    column_1: "4160",
    geom: null,
    coordonnees: {
      lat: 50.481662,
      lon: 5.5225261,
    },
  },
  {
    column_4: 50.4553077,
    column_3: 5.5699525,
    column_2: "Comblain-La-Tour",
    column_1: "4180",
    geom: null,
    coordonnees: {
      lat: 50.4553077,
      lon: 5.5699525,
    },
  },
  {
    column_4: 50.40526,
    column_3: 5.5726031,
    column_2: "My",
    column_1: "4190",
    geom: null,
    coordonnees: {
      lat: 50.40526,
      lon: 5.5726031,
    },
  },
  {
    column_4: 50.5812607,
    column_3: 5.072436,
    column_2: "Burdinne",
    column_1: "4210",
    geom: null,
    coordonnees: {
      lat: 50.5812607,
      lon: 5.072436,
    },
  },
  {
    column_4: 50.5945676,
    column_3: 5.0485902,
    column_2: "Acosse",
    column_1: "4219",
    geom: null,
    coordonnees: {
      lat: 50.5945676,
      lon: 5.0485902,
    },
  },
  {
    column_4: 50.6684881,
    column_3: 5.1710529,
    column_2: "Geer",
    column_1: "4250",
    geom: null,
    coordonnees: {
      lat: 50.6684881,
      lon: 5.1710529,
    },
  },
  {
    column_4: 50.6770806,
    column_3: 5.2045681,
    column_2: "Hollogne-Sur-Geer",
    column_1: "4250",
    geom: null,
    coordonnees: {
      lat: 50.6770806,
      lon: 5.2045681,
    },
  },
  {
    column_4: 50.6978983,
    column_3: 5.122837,
    column_2: "Cras-Avernas",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6978983,
      lon: 5.122837,
    },
  },
  {
    column_4: 50.7228078,
    column_3: 5.0055524,
    column_2: "Pellaines",
    column_1: "4287",
    geom: null,
    coordonnees: {
      lat: 50.7228078,
      lon: 5.0055524,
    },
  },
  {
    column_4: 50.7388269,
    column_3: 5.0290114,
    column_2: "Racour",
    column_1: "4287",
    geom: null,
    coordonnees: {
      lat: 50.7388269,
      lon: 5.0290114,
    },
  },
  {
    column_4: 50.6976875,
    column_3: 5.2546191,
    column_2: "Waremme",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.6976875,
      lon: 5.2546191,
    },
  },
  {
    column_4: 50.6598522,
    column_3: 5.3827164,
    column_2: "Noville",
    column_1: "4347",
    geom: null,
    coordonnees: {
      lat: 50.6598522,
      lon: 5.3827164,
    },
  },
  {
    column_4: 50.6494928,
    column_3: 5.3960063,
    column_2: "Roloux",
    column_1: "4347",
    geom: null,
    coordonnees: {
      lat: 50.6494928,
      lon: 5.3960063,
    },
  },
  {
    column_4: 50.6532256,
    column_3: 5.3559477,
    column_2: "Jeneffe",
    column_1: "4357",
    geom: null,
    coordonnees: {
      lat: 50.6532256,
      lon: 5.3559477,
    },
  },
  {
    column_4: 50.5936483,
    column_3: 5.4528969,
    column_2: "Chokier",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.5936483,
      lon: 5.4528969,
    },
  },
  {
    column_4: 50.7043671,
    column_3: 5.4805775,
    column_2: "Xhendremael",
    column_1: "4432",
    geom: null,
    coordonnees: {
      lat: 50.7043671,
      lon: 5.4805775,
    },
  },
  {
    column_4: 50.687631,
    column_3: 5.552484,
    column_2: "Voroux-Lez-Liers",
    column_1: "4451",
    geom: null,
    coordonnees: {
      lat: 50.687631,
      lon: 5.552484,
    },
  },
  {
    column_4: 50.6323331,
    column_3: 5.501194,
    column_2: "Grâce-Berleur",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.6323331,
      lon: 5.501194,
    },
  },
  {
    column_4: 50.6149668,
    column_3: 5.3886713,
    column_2: "Horion-Hozémont",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.6149668,
      lon: 5.3886713,
    },
  },
  {
    column_4: 50.6000686,
    column_3: 5.3579041,
    column_2: "Saint-Georges-Sur-Meuse",
    column_1: "4470",
    geom: null,
    coordonnees: {
      lat: 50.6000686,
      lon: 5.3579041,
    },
  },
  {
    column_4: 50.582433,
    column_3: 5.4043113,
    column_2: "Engis",
    column_1: "4480",
    geom: null,
    coordonnees: {
      lat: 50.582433,
      lon: 5.4043113,
    },
  },
  {
    column_4: 50.540238,
    column_3: 5.286736,
    column_2: "Ampsin",
    column_1: "4540",
    geom: null,
    coordonnees: {
      lat: 50.540238,
      lon: 5.286736,
    },
  },
  {
    column_4: 50.5284301,
    column_3: 5.3819538,
    column_2: "Yernée-Fraineux",
    column_1: "4550",
    geom: null,
    coordonnees: {
      lat: 50.5284301,
      lon: 5.3819538,
    },
  },
  {
    column_4: 50.4777419,
    column_3: 5.3786954,
    column_2: "Tinlot",
    column_1: "4557",
    geom: null,
    coordonnees: {
      lat: 50.4777419,
      lon: 5.3786954,
    },
  },
  {
    column_4: 50.3946271,
    column_3: 5.3302671,
    column_2: "Bois-Et-Borsu",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.3946271,
      lon: 5.3302671,
    },
  },
  {
    column_4: 50.3965212,
    column_3: 5.3954539,
    column_2: "Ocquier",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.3965212,
      lon: 5.3954539,
    },
  },
  {
    column_4: 50.4416137,
    column_3: 5.3017936,
    column_2: "Modave",
    column_1: "4577",
    geom: null,
    coordonnees: {
      lat: 50.4416137,
      lon: 5.3017936,
    },
  },
  {
    column_4: 50.4377341,
    column_3: 5.4653273,
    column_2: "Ouffet",
    column_1: "4590",
    geom: null,
    coordonnees: {
      lat: 50.4377341,
      lon: 5.4653273,
    },
  },
  {
    column_4: 50.7550757,
    column_3: 5.680109,
    column_2: "Lixhe",
    column_1: "4600",
    geom: null,
    coordonnees: {
      lat: 50.7550757,
      lon: 5.680109,
    },
  },
  {
    column_4: 50.6957645,
    column_3: 5.686015,
    column_2: "Argenteau",
    column_1: "4601",
    geom: null,
    coordonnees: {
      lat: 50.6957645,
      lon: 5.686015,
    },
  },
  {
    column_4: 50.7139302,
    column_3: 5.7229816,
    column_2: "Dalhem",
    column_1: "4607",
    geom: null,
    coordonnees: {
      lat: 50.7139302,
      lon: 5.7229816,
    },
  },
  {
    column_4: 50.6168354,
    column_3: 5.6832219,
    column_2: "Fléron",
    column_1: "4620",
    geom: null,
    coordonnees: {
      lat: 50.6168354,
      lon: 5.6832219,
    },
  },
  {
    column_4: 50.6068155,
    column_3: 5.6655488,
    column_2: "Romsée",
    column_1: "4624",
    geom: null,
    coordonnees: {
      lat: 50.6068155,
      lon: 5.6655488,
    },
  },
  {
    column_4: 50.6460841,
    column_3: 5.7370469,
    column_2: "Melen",
    column_1: "4633",
    geom: null,
    coordonnees: {
      lat: 50.6460841,
      lon: 5.7370469,
    },
  },
  {
    column_4: 50.6856398,
    column_3: 5.7718682,
    column_2: "Julémont",
    column_1: "4650",
    geom: null,
    coordonnees: {
      lat: 50.6856398,
      lon: 5.7718682,
    },
  },
  {
    column_4: 50.7091889,
    column_3: 5.6450671,
    column_2: "Oupeye",
    column_1: "4680",
    geom: null,
    coordonnees: {
      lat: 50.7091889,
      lon: 5.6450671,
    },
  },
  {
    column_4: 50.697932,
    column_3: 5.6527071,
    column_2: "Vivegnis",
    column_1: "4683",
    geom: null,
    coordonnees: {
      lat: 50.697932,
      lon: 5.6527071,
    },
  },
  {
    column_4: 50.7677078,
    column_3: 5.6333621,
    column_2: "Wonck",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.7677078,
      lon: 5.6333621,
    },
  },
  {
    column_4: 50.7080172,
    column_3: 6.0708402,
    column_2: "Hauset",
    column_1: "4730",
    geom: null,
    coordonnees: {
      lat: 50.7080172,
      lon: 6.0708402,
    },
  },
  {
    column_4: 50.6935863,
    column_3: 6.081697,
    column_2: "Eynatten",
    column_1: "4731",
    geom: null,
    coordonnees: {
      lat: 50.6935863,
      lon: 6.081697,
    },
  },
  {
    column_4: 50.3310938,
    column_3: 6.3400555,
    column_2: "Manderfeld",
    column_1: "4760",
    geom: null,
    coordonnees: {
      lat: 50.3310938,
      lon: 6.3400555,
    },
  },
  {
    column_4: 50.3349273,
    column_3: 6.0431296,
    column_2: "Recht",
    column_1: "4780",
    geom: null,
    coordonnees: {
      lat: 50.3349273,
      lon: 6.0431296,
    },
  },
  {
    column_4: 50.2389236,
    column_3: 6.1651484,
    column_2: "Lommersweiler",
    column_1: "4783",
    geom: null,
    coordonnees: {
      lat: 50.2389236,
      lon: 6.1651484,
    },
  },
  {
    column_4: 50.57804,
    column_3: 5.867539,
    column_2: "Heusy",
    column_1: "4802",
    geom: null,
    coordonnees: {
      lat: 50.57804,
      lon: 5.867539,
    },
  },
  {
    column_4: 50.6086202,
    column_3: 5.9562188,
    column_2: "Goé",
    column_1: "4834",
    geom: null,
    coordonnees: {
      lat: 50.6086202,
      lon: 5.9562188,
    },
  },
  {
    column_4: 50.6768234,
    column_3: 5.9318018,
    column_2: "Henri-Chapelle",
    column_1: "4841",
    geom: null,
    coordonnees: {
      lat: 50.6768234,
      lon: 5.9318018,
    },
  },
  {
    column_4: 50.51744,
    column_3: 5.933482,
    column_2: "Sart-Lez-Spa",
    column_1: "4845",
    geom: null,
    coordonnees: {
      lat: 50.51744,
      lon: 5.933482,
    },
  },
  {
    column_4: 50.7208739,
    column_3: 5.9880362,
    column_2: "Moresnet",
    column_1: "4850",
    geom: null,
    coordonnees: {
      lat: 50.7208739,
      lon: 5.9880362,
    },
  },
  {
    column_4: 50.5789617,
    column_3: 5.8175559,
    column_2: "Wegnez",
    column_1: "4860",
    geom: null,
    coordonnees: {
      lat: 50.5789617,
      lon: 5.8175559,
    },
  },
  {
    column_4: 50.583997,
    column_3: 5.7002354,
    column_2: "Forêt",
    column_1: "4870",
    geom: null,
    coordonnees: {
      lat: 50.583997,
      lon: 5.7002354,
    },
  },
  {
    column_4: 50.52586075,
    column_3: 5.8198955843753,
    column_2: "Theux",
    column_1: "4910",
    geom: null,
    coordonnees: {
      lat: 50.52586075,
      lon: 5.81989558438,
    },
  },
  {
    column_4: 50.3727015,
    column_3: 5.8704355,
    column_2: "Trois-Ponts",
    column_1: "4980",
    geom: null,
    coordonnees: {
      lat: 50.3727015,
      lon: 5.8704355,
    },
  },
  {
    column_4: 50.3208964,
    column_3: 5.7309407,
    column_2: "Bra",
    column_1: "4990",
    geom: null,
    coordonnees: {
      lat: 50.3208964,
      lon: 5.7309407,
    },
  },
  {
    column_4: 50.28574,
    column_3: 5.7920929,
    column_2: "Lierneux",
    column_1: "4990",
    geom: null,
    coordonnees: {
      lat: 50.28574,
      lon: 5.7920929,
    },
  },
  {
    column_4: 50.46923485,
    column_3: 4.8222958522639,
    column_2: "Belgrade",
    column_1: "5001",
    geom: null,
    coordonnees: {
      lat: 50.46923485,
      lon: 4.82229585226,
    },
  },
  {
    column_4: 50.49862565,
    column_3: 4.9035112119756,
    column_2: "Champion",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.49862565,
      lon: 4.90351121198,
    },
  },
  {
    column_4: 50.4883008,
    column_3: 4.7780268559488,
    column_2: "Suarlée",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.4883008,
      lon: 4.77802685595,
    },
  },
  {
    column_4: 50.4829793,
    column_3: 4.7509248,
    column_2: "Temploux",
    column_1: "5020",
    geom: null,
    coordonnees: {
      lat: 50.4829793,
      lon: 4.7509248,
    },
  },
  {
    column_4: 50.556923,
    column_3: 4.683029,
    column_2: "Grand-Manil",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.556923,
      lon: 4.683029,
    },
  },
  {
    column_4: 50.52340745,
    column_3: 4.6919946070362,
    column_2: "Bossière",
    column_1: "5032",
    geom: null,
    coordonnees: {
      lat: 50.52340745,
      lon: 4.69199460704,
    },
  },
  {
    column_4: 50.4470193,
    column_3: 4.6354624,
    column_2: "Auvelais",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4470193,
      lon: 4.6354624,
    },
  },
  {
    column_4: 50.3941596,
    column_3: 4.6558828,
    column_2: "Vitrival",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.3941596,
      lon: 4.6558828,
    },
  },
  {
    column_4: 50.4110871,
    column_3: 4.9043004277231,
    column_2: "Dave",
    column_1: "5100",
    geom: null,
    coordonnees: {
      lat: 50.4110871,
      lon: 4.90430042772,
    },
  },
  {
    column_4: 50.4286275,
    column_3: 4.9497445929374,
    column_2: "Wierde",
    column_1: "5100",
    geom: null,
    coordonnees: {
      lat: 50.4286275,
      lon: 4.94974459294,
    },
  },
  {
    column_4: 50.4474046,
    column_3: 4.9094515331252,
    column_2: "Erpent",
    column_1: "5101",
    geom: null,
    coordonnees: {
      lat: 50.4474046,
      lon: 4.90945153313,
    },
  },
  {
    column_4: 50.5246589,
    column_3: 4.6025925,
    column_2: "Sombreffe",
    column_1: "5140",
    geom: null,
    coordonnees: {
      lat: 50.5246589,
      lon: 4.6025925,
    },
  },
  {
    column_4: 50.4515822,
    column_3: 4.7694348,
    column_2: "Floriffoux",
    column_1: "5150",
    geom: null,
    coordonnees: {
      lat: 50.4515822,
      lon: 4.7694348,
    },
  },
  {
    column_4: 50.4494828,
    column_3: 4.730789,
    column_2: "Soye",
    column_1: "5150",
    geom: null,
    coordonnees: {
      lat: 50.4494828,
      lon: 4.730789,
    },
  },
  {
    column_4: 50.365529,
    column_3: 4.820627,
    column_2: "Arbre",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.365529,
      lon: 4.820627,
    },
  },
  {
    column_4: 50.389973,
    column_3: 4.823377,
    column_2: "Bois-De-Villers",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.389973,
      lon: 4.823377,
    },
  },
  {
    column_4: 50.3580729,
    column_3: 4.8724268,
    column_2: "Rivière",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.3580729,
      lon: 4.8724268,
    },
  },
  {
    column_4: 50.46299585,
    column_3: 4.6545986012407,
    column_2: "Jemeppe-Sur-Sambre",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.46299585,
      lon: 4.65459860124,
    },
  },
  {
    column_4: 50.4984249,
    column_3: 5.0805501,
    column_2: "Seilles",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4984249,
      lon: 5.0805501,
    },
  },
  {
    column_4: 50.4966018,
    column_3: 5.0114611,
    column_2: "Vezin",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4966018,
      lon: 5.0114611,
    },
  },
  {
    column_4: 50.5917416,
    column_3: 4.9049523,
    column_2: "Eghezée",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5917416,
      lon: 4.9049523,
    },
  },
  {
    column_4: 50.5821124,
    column_3: 4.8311032,
    column_2: "Liernu",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5821124,
      lon: 4.8311032,
    },
  },
  {
    column_4: 50.5772925,
    column_3: 4.8962185,
    column_2: "Longchamps",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5772925,
      lon: 4.8962185,
    },
  },
  {
    column_4: 50.5942203,
    column_3: 4.8762345,
    column_2: "Mehaigne",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5942203,
      lon: 4.8762345,
    },
  },
  {
    column_4: 50.6170592,
    column_3: 4.9318579,
    column_2: "Taviers",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.6170592,
      lon: 4.9318579,
    },
  },
  {
    column_4: 50.4272928,
    column_3: 5.0191438,
    column_2: "Faulx-Les-Tombes",
    column_1: "5340",
    geom: null,
    coordonnees: {
      lat: 50.4272928,
      lon: 5.0191438,
    },
  },
  {
    column_4: 50.4001817,
    column_3: 5.1258806,
    column_2: "Sorée",
    column_1: "5340",
    geom: null,
    coordonnees: {
      lat: 50.4001817,
      lon: 5.1258806,
    },
  },
  {
    column_4: 50.4405961,
    column_3: 5.1491871,
    column_2: "Haillot",
    column_1: "5351",
    geom: null,
    coordonnees: {
      lat: 50.4405961,
      lon: 5.1491871,
    },
  },
  {
    column_4: 50.3384304,
    column_3: 5.2408578,
    column_2: "Porcheresse",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3384304,
      lon: 5.2408578,
    },
  },
  {
    column_4: 50.365689,
    column_3: 5.24941,
    column_2: "Miécret",
    column_1: "5376",
    geom: null,
    coordonnees: {
      lat: 50.365689,
      lon: 5.24941,
    },
  },
  {
    column_4: 50.260584,
    column_3: 5.304196,
    column_2: "Waillet",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.260584,
      lon: 5.304196,
    },
  },
  {
    column_4: 50.23791,
    column_3: 4.907923,
    column_2: "Anseremme",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.23791,
      lon: 4.907923,
    },
  },
  {
    column_4: 50.2368273,
    column_3: 4.9388148,
    column_2: "Dréhance",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.2368273,
      lon: 4.9388148,
    },
  },
  {
    column_4: 50.2471757,
    column_3: 4.9897127,
    column_2: "Foy-Notre-Dame",
    column_1: "5504",
    geom: null,
    coordonnees: {
      lat: 50.2471757,
      lon: 4.9897127,
    },
  },
  {
    column_4: 50.3380783,
    column_3: 4.9857278,
    column_2: "Durnal",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3380783,
      lon: 4.9857278,
    },
  },
  {
    column_4: 50.5341607,
    column_3: 5.7909538,
    column_2: "Mont",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.5341607,
      lon: 5.7909538,
    },
  },
  {
    column_4: 50.3238206,
    column_3: 4.836084,
    column_2: "Warnant",
    column_1: "5537",
    geom: null,
    coordonnees: {
      lat: 50.3238206,
      lon: 4.836084,
    },
  },
  {
    column_4: 50.16421,
    column_3: 4.7942801,
    column_2: "Agimont",
    column_1: "5544",
    geom: null,
    coordonnees: {
      lat: 50.16421,
      lon: 4.7942801,
    },
  },
  {
    column_4: 49.9226206,
    column_3: 5.0894297,
    column_2: "Naomé",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.9226206,
      lon: 5.0894297,
    },
  },
  {
    column_4: 50.1666464,
    column_3: 5.0899109,
    column_2: "Ciergnon",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.1666464,
      lon: 5.0899109,
    },
  },
  {
    column_4: 50.1105211,
    column_3: 4.9576144,
    column_2: "Beauraing",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.1105211,
      lon: 4.9576144,
    },
  },
  {
    column_4: 50.0920374,
    column_3: 4.961015,
    column_2: "Wancennes",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.0920374,
      lon: 4.961015,
    },
  },
  {
    column_4: 50.0121497,
    column_3: 4.9609972,
    column_2: "Malvoisin",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 50.0121497,
      lon: 4.9609972,
    },
  },
  {
    column_4: 50.0323702,
    column_3: 4.9198406,
    column_2: "Vencimont",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 50.0323702,
      lon: 4.9198406,
    },
  },
  {
    column_4: 50.0604028,
    column_3: 5.000634,
    column_2: "Froidfontaine",
    column_1: "5576",
    geom: null,
    coordonnees: {
      lat: 50.0604028,
      lon: 5.000634,
    },
  },
  {
    column_4: 50.11195305,
    column_3: 5.1530857761777,
    column_2: "Ave-Et-Auffe",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.11195305,
      lon: 5.15308577618,
    },
  },
  {
    column_4: 50.1624278,
    column_3: 5.2624584,
    column_2: "Jemelle",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.1624278,
      lon: 5.2624584,
    },
  },
  {
    column_4: 50.11639205,
    column_3: 5.2419236738324,
    column_2: "Wavreille",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.11639205,
      lon: 5.24192367383,
    },
  },
  {
    column_4: 50.3129076,
    column_3: 5.0629626,
    column_2: "Braibant",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.3129076,
      lon: 5.0629626,
    },
  },
  {
    column_4: 50.2949558,
    column_3: 5.0974251,
    column_2: "Ciney",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2949558,
      lon: 5.0974251,
    },
  },
  {
    column_4: 50.1050216,
    column_3: 4.5703749,
    column_2: "Fagnolle",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1050216,
      lon: 4.5703749,
    },
  },
  {
    column_4: 50.1728667,
    column_3: 4.6072911,
    column_2: "Merlemont",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1728667,
      lon: 4.6072911,
    },
  },
  {
    column_4: 50.1933288,
    column_3: 4.6995791,
    column_2: "Omezée",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1933288,
      lon: 4.6995791,
    },
  },
  {
    column_4: 50.1356527,
    column_3: 4.5381501,
    column_2: "Roly",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1356527,
      lon: 4.5381501,
    },
  },
  {
    column_4: 50.1579167,
    column_3: 4.6199447,
    column_2: "Sart-En-Fagne",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1579167,
      lon: 4.6199447,
    },
  },
  {
    column_4: 50.1694005,
    column_3: 4.5612425,
    column_2: "Sautour",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1694005,
      lon: 4.5612425,
    },
  },
  {
    column_4: 50.1972001,
    column_3: 4.5920788,
    column_2: "Vodecée",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1972001,
      lon: 4.5920788,
    },
  },
  {
    column_4: 50.2519501,
    column_3: 4.6792792,
    column_2: "Corenne",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2519501,
      lon: 4.6792792,
    },
  },
  {
    column_4: 50.2330003,
    column_3: 4.7440112,
    column_2: "Morville",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2330003,
      lon: 4.7440112,
    },
  },
  {
    column_4: 50.2949873,
    column_3: 4.5607099,
    column_2: "Hanzinelle",
    column_1: "5621",
    geom: null,
    coordonnees: {
      lat: 50.2949873,
      lon: 4.5607099,
    },
  },
  {
    column_4: 50.3084666,
    column_3: 4.5435524,
    column_2: "Hanzinne",
    column_1: "5621",
    geom: null,
    coordonnees: {
      lat: 50.3084666,
      lon: 4.5435524,
    },
  },
  {
    column_4: 50.2776442,
    column_3: 4.5634203,
    column_2: "Morialmé",
    column_1: "5621",
    geom: null,
    coordonnees: {
      lat: 50.2776442,
      lon: 4.5634203,
    },
  },
  {
    column_4: 50.1687764,
    column_3: 4.4125932,
    column_2: "Cerfontaine",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.1687764,
      lon: 4.4125932,
    },
  },
  {
    column_4: 50.1762834,
    column_3: 4.466208,
    column_2: "Senzeille",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.1762834,
      lon: 4.466208,
    },
  },
  {
    column_4: 50.3004056,
    column_3: 4.615806,
    column_2: "Oret",
    column_1: "5640",
    geom: null,
    coordonnees: {
      lat: 50.3004056,
      lon: 4.615806,
    },
  },
  {
    column_4: 50.3080016,
    column_3: 4.7055271,
    column_2: "Furnaux",
    column_1: "5641",
    geom: null,
    coordonnees: {
      lat: 50.3080016,
      lon: 4.7055271,
    },
  },
  {
    column_4: 50.2828212,
    column_3: 4.6590441,
    column_2: "Stave",
    column_1: "5646",
    geom: null,
    coordonnees: {
      lat: 50.2828212,
      lon: 4.6590441,
    },
  },
  {
    column_4: 50.2613846,
    column_3: 4.5081639,
    column_2: "Fraire",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2613846,
      lon: 4.5081639,
    },
  },
  {
    column_4: 50.0754838,
    column_3: 4.4710191,
    column_2: "Boussu-En-Fagne",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0754838,
      lon: 4.4710191,
    },
  },
  {
    column_4: 50.0922698,
    column_3: 4.6692715,
    column_2: "Treignes",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.0922698,
      lon: 4.6692715,
    },
  },
  {
    column_4: 50.080269,
    column_3: 4.634249,
    column_2: "Vierves-Sur-Viroin",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.080269,
      lon: 4.634249,
    },
  },
  {
    column_4: 50.0516361,
    column_3: 4.6270287,
    column_2: "Viroinval",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.0516361,
      lon: 4.6270287,
    },
  },
  {
    column_4: 50.1357152,
    column_3: 4.7458219,
    column_2: "Doische",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1357152,
      lon: 4.7458219,
    },
  },
  {
    column_4: 50.1315986,
    column_3: 4.7141334,
    column_2: "Gimnée",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1315986,
      lon: 4.7141334,
    },
  },
  {
    column_4: 50.1171293,
    column_3: 4.6236448,
    column_2: "Matagne-La-Grande",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1171293,
      lon: 4.6236448,
    },
  },
  {
    column_4: 50.1186008,
    column_3: 4.6468816,
    column_2: "Matagne-La-Petite",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1186008,
      lon: 4.6468816,
    },
  },
  {
    column_4: 50.1177554,
    column_3: 4.7026857,
    column_2: "Niverlée",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1177554,
      lon: 4.7026857,
    },
  },
  {
    column_4: 50.1702393,
    column_3: 4.7323157,
    column_2: "Vodelée",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1702393,
      lon: 4.7323157,
    },
  },
  {
    column_4: 50.41684425,
    column_3: 4.3611946147288,
    column_2: "Goutroux",
    column_1: "6030",
    geom: null,
    coordonnees: {
      lat: 50.41684425,
      lon: 4.36119461473,
    },
  },
  {
    column_4: 50.4067102,
    column_3: 4.3960065,
    column_2: "Marchienne-Au-Pont",
    column_1: "6030",
    geom: null,
    coordonnees: {
      lat: 50.4067102,
      lon: 4.3960065,
    },
  },
  {
    column_4: 50.44466065,
    column_3: 4.4360232749854,
    column_2: "Jumet",
    column_1: "6040",
    geom: null,
    coordonnees: {
      lat: 50.44466065,
      lon: 4.43602327499,
    },
  },
  {
    column_4: 50.369072045553,
    column_3: 4.3602314806535,
    column_2: "Montigny-Le-Tilleul",
    column_1: "6110",
    geom: null,
    coordonnees: {
      lat: 50.3690720456,
      lon: 4.36023148065,
    },
  },
  {
    column_4: 50.2974701,
    column_3: 4.3868963,
    column_2: "Cour-Sur-Heure",
    column_1: "6120",
    geom: null,
    coordonnees: {
      lat: 50.2974701,
      lon: 4.3868963,
    },
  },
  {
    column_4: 50.3219897,
    column_3: 4.3889214,
    column_2: "Ham-Sur-Heure",
    column_1: "6120",
    geom: null,
    coordonnees: {
      lat: 50.3219897,
      lon: 4.3889214,
    },
  },
  {
    column_4: 50.3974398,
    column_3: 4.3307851,
    column_2: "Leernes",
    column_1: "6142",
    geom: null,
    coordonnees: {
      lat: 50.3974398,
      lon: 4.3307851,
    },
  },
  {
    column_4: 50.4079969,
    column_3: 4.2696102,
    column_2: "Anderlues",
    column_1: "6150",
    geom: null,
    coordonnees: {
      lat: 50.4079969,
      lon: 4.2696102,
    },
  },
  {
    column_4: 50.4601576,
    column_3: 4.3763406,
    column_2: "Courcelles",
    column_1: "6180",
    geom: null,
    coordonnees: {
      lat: 50.4601576,
      lon: 4.3763406,
    },
  },
  {
    column_4: 50.5376477,
    column_3: 4.4501525,
    column_2: "Frasnes-Lez-Gosselies",
    column_1: "6210",
    geom: null,
    coordonnees: {
      lat: 50.5376477,
      lon: 4.4501525,
    },
  },
  {
    column_4: 50.4896309,
    column_3: 4.4723314,
    column_2: "Wayaux",
    column_1: "6210",
    geom: null,
    coordonnees: {
      lat: 50.4896309,
      lon: 4.4723314,
    },
  },
  {
    column_4: 50.50462075,
    column_3: 4.4782005997401,
    column_2: "Mellet",
    column_1: "6211",
    geom: null,
    coordonnees: {
      lat: 50.50462075,
      lon: 4.47820059974,
    },
  },
  {
    column_4: 50.4776689,
    column_3: 4.519112,
    column_2: "Wangenies",
    column_1: "6220",
    geom: null,
    coordonnees: {
      lat: 50.4776689,
      lon: 4.519112,
    },
  },
  {
    column_4: 50.53007005,
    column_3: 4.561356496071,
    column_2: "Brye",
    column_1: "6222",
    geom: null,
    coordonnees: {
      lat: 50.53007005,
      lon: 4.56135649607,
    },
  },
  {
    column_4: 50.3528064,
    column_3: 4.5125952,
    column_2: "Joncret",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.3528064,
      lon: 4.5125952,
    },
  },
  {
    column_4: 50.374997,
    column_3: 4.47342,
    column_2: "Loverval",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.374997,
      lon: 4.47342,
    },
  },
  {
    column_4: 50.2118893,
    column_3: 4.3512321,
    column_2: "Erpion",
    column_1: "6441",
    geom: null,
    coordonnees: {
      lat: 50.2118893,
      lon: 4.3512321,
    },
  },
  {
    column_4: 50.0560102,
    column_3: 4.247403,
    column_2: "Salles",
    column_1: "6460",
    geom: null,
    coordonnees: {
      lat: 50.0560102,
      lon: 4.247403,
    },
  },
  {
    column_4: 50.0648008,
    column_3: 4.3329805,
    column_2: "Virelles",
    column_1: "6461",
    geom: null,
    coordonnees: {
      lat: 50.0648008,
      lon: 4.3329805,
    },
  },
  {
    column_4: 50.022579,
    column_3: 4.3211041,
    column_2: "Forges",
    column_1: "6464",
    geom: null,
    coordonnees: {
      lat: 50.022579,
      lon: 4.3211041,
    },
  },
  {
    column_4: 50.163816,
    column_3: 4.226143,
    column_2: "Sautin",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.163816,
      lon: 4.226143,
    },
  },
  {
    column_4: 50.2206905,
    column_3: 4.2859259,
    column_2: "Barbençon",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.2206905,
      lon: 4.2859259,
    },
  },
  {
    column_4: 50.2608137,
    column_3: 4.2364418,
    column_2: "Thirimont",
    column_1: "6500",
    geom: null,
    coordonnees: {
      lat: 50.2608137,
      lon: 4.2364418,
    },
  },
  {
    column_4: 50.3332279,
    column_3: 4.3515595,
    column_2: "Gozée",
    column_1: "6534",
    geom: null,
    coordonnees: {
      lat: 50.3332279,
      lon: 4.3515595,
    },
  },
  {
    column_4: 50.2947304,
    column_3: 4.329007,
    column_2: "Thuillies",
    column_1: "6536",
    geom: null,
    coordonnees: {
      lat: 50.2947304,
      lon: 4.329007,
    },
  },
  {
    column_4: 50.2815734,
    column_3: 4.1884975,
    column_2: "Montignies-Saint-Christophe",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.2815734,
      lon: 4.1884975,
    },
  },
  {
    column_4: 50.052122,
    column_3: 4.2096331,
    column_2: "Macon",
    column_1: "6591",
    geom: null,
    coordonnees: {
      lat: 50.052122,
      lon: 4.2096331,
    },
  },
  {
    column_4: 49.8509306,
    column_3: 5.665031,
    column_2: "Fauvillers",
    column_1: "6637",
    geom: null,
    coordonnees: {
      lat: 49.8509306,
      lon: 5.665031,
    },
  },
  {
    column_4: 50.1323761,
    column_3: 5.7886928,
    column_2: "Houffalize",
    column_1: "6660",
    geom: null,
    coordonnees: {
      lat: 50.1323761,
      lon: 5.7886928,
    },
  },
  {
    column_4: 50.1611829,
    column_3: 5.6807442,
    column_2: "Nadrin",
    column_1: "6660",
    geom: null,
    coordonnees: {
      lat: 50.1611829,
      lon: 5.6807442,
    },
  },
  {
    column_4: 50.0292955,
    column_3: 5.5272931,
    column_2: "Amberloup",
    column_1: "6680",
    geom: null,
    coordonnees: {
      lat: 50.0292955,
      lon: 5.5272931,
    },
  },
  {
    column_4: 50.0362888,
    column_3: 5.5245094,
    column_2: "Sainte-Ode",
    column_1: "6680",
    geom: null,
    coordonnees: {
      lat: 50.0362888,
      lon: 5.5245094,
    },
  },
  {
    column_4: 50.3074461,
    column_3: 5.9672766,
    column_2: "Petit-Thier",
    column_1: "6692",
    geom: null,
    coordonnees: {
      lat: 50.3074461,
      lon: 5.9672766,
    },
  },
  {
    column_4: 50.3262753,
    column_3: 5.9072517,
    column_2: "Grand-Halleux",
    column_1: "6698",
    geom: null,
    coordonnees: {
      lat: 50.3262753,
      lon: 5.9072517,
    },
  },
  {
    column_4: 49.7399333,
    column_3: 5.7187077,
    column_2: "Nobressart",
    column_1: "6717",
    geom: null,
    coordonnees: {
      lat: 49.7399333,
      lon: 5.7187077,
    },
  },
  {
    column_4: 49.7266352,
    column_3: 5.8099377,
    column_2: "Tontelange",
    column_1: "6717",
    geom: null,
    coordonnees: {
      lat: 49.7266352,
      lon: 5.8099377,
    },
  },
  {
    column_4: 49.7015507,
    column_3: 5.6805003,
    column_2: "Hachy",
    column_1: "6720",
    geom: null,
    coordonnees: {
      lat: 49.7015507,
      lon: 5.6805003,
    },
  },
  {
    column_4: 49.6649541,
    column_3: 5.4979103,
    column_2: "Bellefontaine",
    column_1: "6730",
    geom: null,
    coordonnees: {
      lat: 49.6649541,
      lon: 5.4979103,
    },
  },
  {
    column_4: 49.6478969,
    column_3: 5.5942184,
    column_2: "Buzenol",
    column_1: "6743",
    geom: null,
    coordonnees: {
      lat: 49.6478969,
      lon: 5.5942184,
    },
  },
  {
    column_4: 49.5714143,
    column_3: 5.6617004,
    column_2: "Mussy-La-Ville",
    column_1: "6750",
    geom: null,
    coordonnees: {
      lat: 49.5714143,
      lon: 5.6617004,
    },
  },
  {
    column_4: 49.5376723,
    column_3: 5.5937884,
    column_2: "Ruette",
    column_1: "6760",
    geom: null,
    coordonnees: {
      lat: 49.5376723,
      lon: 5.5937884,
    },
  },
  {
    column_4: 49.6081105,
    column_3: 5.848409,
    column_2: "Sélange",
    column_1: "6781",
    geom: null,
    coordonnees: {
      lat: 49.6081105,
      lon: 5.848409,
    },
  },
  {
    column_4: 49.9764499,
    column_3: 5.494626,
    column_2: "Remagne",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9764499,
      lon: 5.494626,
    },
  },
  {
    column_4: 49.9047868,
    column_3: 5.386433,
    column_2: "Saint-Pierre",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9047868,
      lon: 5.386433,
    },
  },
  {
    column_4: 49.8710591,
    column_3: 5.1220764,
    column_2: "Nollevaux",
    column_1: "6851",
    geom: null,
    coordonnees: {
      lat: 49.8710591,
      lon: 5.1220764,
    },
  },
  {
    column_4: 50.0569945,
    column_3: 5.2653304,
    column_2: "Mirwart",
    column_1: "6870",
    geom: null,
    coordonnees: {
      lat: 50.0569945,
      lon: 5.2653304,
    },
  },
  {
    column_4: 49.9244612,
    column_3: 5.2781878,
    column_2: "Ochamps",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 49.9244612,
      lon: 5.2781878,
    },
  },
  {
    column_4: 50.0004466,
    column_3: 5.2026639,
    column_2: "Transinne",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 50.0004466,
      lon: 5.2026639,
    },
  },
  {
    column_4: 49.9709626,
    column_3: 5.2221196,
    column_2: "Villance",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 49.9709626,
      lon: 5.2221196,
    },
  },
  {
    column_4: 50.2119666,
    column_3: 5.3441742,
    column_2: "Waha",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.2119666,
      lon: 5.3441742,
    },
  },
  {
    column_4: 50.3524116,
    column_3: 5.456248,
    column_2: "Durbuy",
    column_1: "6940",
    geom: null,
    coordonnees: {
      lat: 50.3524116,
      lon: 5.456248,
    },
  },
  {
    column_4: 50.1143521,
    column_3: 5.3136921,
    column_2: "Masbourg",
    column_1: "6953",
    geom: null,
    coordonnees: {
      lat: 50.1143521,
      lon: 5.3136921,
    },
  },
  {
    column_4: 50.234199,
    column_3: 5.6230297,
    column_2: "Dochamps",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.234199,
      lon: 5.6230297,
    },
  },
  {
    column_4: 50.2563686,
    column_3: 5.682551,
    column_2: "Odeigne",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.2563686,
      lon: 5.682551,
    },
  },
  {
    column_4: 50.170322,
    column_3: 5.5532565,
    column_2: "Beausaint",
    column_1: "6980",
    geom: null,
    coordonnees: {
      lat: 50.170322,
      lon: 5.5532565,
    },
  },
  {
    column_4: 50.245425,
    column_3: 5.5230608,
    column_2: "Beffe",
    column_1: "6987",
    geom: null,
    coordonnees: {
      lat: 50.245425,
      lon: 5.5230608,
    },
  },
  {
    column_4: 50.2330466,
    column_3: 5.5043243,
    column_2: "Rendeux",
    column_1: "6987",
    geom: null,
    coordonnees: {
      lat: 50.2330466,
      lon: 5.5043243,
    },
  },
  {
    column_4: 50.2688011,
    column_3: 5.4463304,
    column_2: "Hotton",
    column_1: "6990",
    geom: null,
    coordonnees: {
      lat: 50.2688011,
      lon: 5.4463304,
    },
  },
  {
    column_4: 50.4549568,
    column_3: 3.951958,
    column_2: "Mons",
    column_1: "7000",
    geom: null,
    coordonnees: {
      lat: 50.4549568,
      lon: 3.951958,
    },
  },
  {
    column_4: 50.4100798,
    column_3: 3.9689185,
    column_2: "Nouvelles",
    column_1: "7022",
    geom: null,
    coordonnees: {
      lat: 50.4100798,
      lon: 3.9689185,
    },
  },
  {
    column_4: 50.340829,
    column_3: 3.9031862,
    column_2: "Aulnois",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.340829,
      lon: 3.9031862,
    },
  },
  {
    column_4: 50.357777,
    column_3: 3.8980429,
    column_2: "Blaregnies",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.357777,
      lon: 3.8980429,
    },
  },
  {
    column_4: 50.3422836,
    column_3: 3.9442029,
    column_2: "Goegnies-Chaussée",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.3422836,
      lon: 3.9442029,
    },
  },
  {
    column_4: 50.3609637,
    column_3: 3.9492347,
    column_2: "Quévy-Le-Grand",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.3609637,
      lon: 3.9492347,
    },
  },
  {
    column_4: 50.5536883,
    column_3: 4.1011662,
    column_2: "Naast",
    column_1: "7062",
    geom: null,
    coordonnees: {
      lat: 50.5536883,
      lon: 4.1011662,
    },
  },
  {
    column_4: 50.5921606,
    column_3: 3.9979808,
    column_2: "Chaussée-Notre-Dame-Louvignies",
    column_1: "7063",
    geom: null,
    coordonnees: {
      lat: 50.5921606,
      lon: 3.9979808,
    },
  },
  {
    column_4: 50.3906692,
    column_3: 3.8883703,
    column_2: "Eugies",
    column_1: "7080",
    geom: null,
    coordonnees: {
      lat: 50.3906692,
      lon: 3.8883703,
    },
  },
  {
    column_4: 50.6386981,
    column_3: 4.1715689,
    column_2: "Hennuyères",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.6386981,
      lon: 4.1715689,
    },
  },
  {
    column_4: 50.4588091,
    column_3: 4.081995,
    column_2: "Boussoit",
    column_1: "7110",
    geom: null,
    coordonnees: {
      lat: 50.4588091,
      lon: 4.081995,
    },
  },
  {
    column_4: 50.4577279,
    column_3: 4.0979858,
    column_2: "Maurage",
    column_1: "7110",
    geom: null,
    coordonnees: {
      lat: 50.4577279,
      lon: 4.0979858,
    },
  },
  {
    column_4: 50.3831523,
    column_3: 4.0801348,
    column_2: "Haulchin",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3831523,
      lon: 4.0801348,
    },
  },
  {
    column_4: 50.3512136,
    column_3: 4.1208612,
    column_2: "Peissant",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3512136,
      lon: 4.1208612,
    },
  },
  {
    column_4: 50.3790081,
    column_3: 4.1507915,
    column_2: "Vellereille-Les-Brayeux",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3790081,
      lon: 4.1507915,
    },
  },
  {
    column_4: 50.4137808,
    column_3: 4.150981,
    column_2: "Waudrez",
    column_1: "7131",
    geom: null,
    coordonnees: {
      lat: 50.4137808,
      lon: 4.150981,
    },
  },
  {
    column_4: 50.4331253,
    column_3: 3.7960902,
    column_2: "Boussu",
    column_1: "7300",
    geom: null,
    coordonnees: {
      lat: 50.4331253,
      lon: 3.7960902,
    },
  },
  {
    column_4: 50.471652,
    column_3: 3.7304437,
    column_2: "Ville-Pommeroeul",
    column_1: "7322",
    geom: null,
    coordonnees: {
      lat: 50.471652,
      lon: 3.7304437,
    },
  },
  {
    column_4: 50.4814352,
    column_3: 3.8327577,
    column_2: "Baudour",
    column_1: "7331",
    geom: null,
    coordonnees: {
      lat: 50.4814352,
      lon: 3.8327577,
    },
  },
  {
    column_4: 50.5304672,
    column_3: 3.7918519,
    column_2: "Neufmaison",
    column_1: "7332",
    geom: null,
    coordonnees: {
      lat: 50.5304672,
      lon: 3.7918519,
    },
  },
  {
    column_4: 50.4673149,
    column_3: 3.8081735,
    column_2: "Tertre",
    column_1: "7333",
    geom: null,
    coordonnees: {
      lat: 50.4673149,
      lon: 3.8081735,
    },
  },
  {
    column_4: 50.4311514,
    column_3: 3.7661218,
    column_2: "Hainin",
    column_1: "7350",
    geom: null,
    coordonnees: {
      lat: 50.4311514,
      lon: 3.7661218,
    },
  },
  {
    column_4: 50.377885,
    column_3: 3.666982,
    column_2: "Marchipont",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.377885,
      lon: 3.666982,
    },
  },
  {
    column_4: 50.5822217,
    column_3: 3.3668477,
    column_2: "Ere",
    column_1: "7500",
    geom: null,
    coordonnees: {
      lat: 50.5822217,
      lon: 3.3668477,
    },
  },
  {
    column_4: 50.6049549,
    column_3: 3.3492864,
    column_2: "Orcq",
    column_1: "7501",
    geom: null,
    coordonnees: {
      lat: 50.6049549,
      lon: 3.3492864,
    },
  },
  {
    column_4: 50.6449056,
    column_3: 3.2831577,
    column_2: "Templeuve",
    column_1: "7520",
    geom: null,
    coordonnees: {
      lat: 50.6449056,
      lon: 3.2831577,
    },
  },
  {
    column_4: 50.5924891,
    column_3: 3.4834445,
    column_2: "Gaurain-Ramecroix",
    column_1: "7530",
    geom: null,
    coordonnees: {
      lat: 50.5924891,
      lon: 3.4834445,
    },
  },
  {
    column_4: 50.6156953,
    column_3: 3.4655917,
    column_2: "Havinnes",
    column_1: "7531",
    geom: null,
    coordonnees: {
      lat: 50.6156953,
      lon: 3.4655917,
    },
  },
  {
    column_4: 50.5557678,
    column_3: 3.3039251,
    column_2: "Rumes",
    column_1: "7610",
    geom: null,
    coordonnees: {
      lat: 50.5557678,
      lon: 3.3039251,
    },
  },
  {
    column_4: 50.5298416,
    column_3: 3.3010202,
    column_2: "La Glanerie",
    column_1: "7611",
    geom: null,
    coordonnees: {
      lat: 50.5298416,
      lon: 3.3010202,
    },
  },
  {
    column_4: 50.52296155,
    column_3: 3.4418587625525,
    column_2: "Laplaigne",
    column_1: "7622",
    geom: null,
    coordonnees: {
      lat: 50.52296155,
      lon: 3.44185876255,
    },
  },
  {
    column_4: 50.567887,
    column_3: 3.473373,
    column_2: "Fontenoy",
    column_1: "7643",
    geom: null,
    coordonnees: {
      lat: 50.567887,
      lon: 3.473373,
    },
  },
  {
    column_4: 50.7386186,
    column_3: 3.2344942,
    column_2: "Luingne",
    column_1: "7700",
    geom: null,
    coordonnees: {
      lat: 50.7386186,
      lon: 3.2344942,
    },
  },
  {
    column_4: 50.7433351,
    column_3: 3.2139093,
    column_2: "Mouscron",
    column_1: "7700",
    geom: null,
    coordonnees: {
      lat: 50.7433351,
      lon: 3.2139093,
    },
  },
  {
    column_4: 50.743719,
    column_3: 3.5066795,
    column_2: "Amougies",
    column_1: "7750",
    geom: null,
    coordonnees: {
      lat: 50.743719,
      lon: 3.5066795,
    },
  },
  {
    column_4: 50.6621501,
    column_3: 3.5192597,
    column_2: "Popuelles",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.6621501,
      lon: 3.5192597,
    },
  },
  {
    column_4: 50.615068,
    column_3: 3.8060162,
    column_2: "Maffle",
    column_1: "7810",
    geom: null,
    coordonnees: {
      lat: 50.615068,
      lon: 3.8060162,
    },
  },
  {
    column_4: 50.6495829,
    column_3: 3.7184421,
    column_2: "Mainvault",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.6495829,
      lon: 3.7184421,
    },
  },
  {
    column_4: 50.6226624,
    column_3: 3.730712,
    column_2: "Villers-Saint-Amand",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.6226624,
      lon: 3.730712,
    },
  },
  {
    column_4: 50.7283427,
    column_3: 3.8094385,
    column_2: "Ghoy",
    column_1: "7863",
    geom: null,
    coordonnees: {
      lat: 50.7283427,
      lon: 3.8094385,
    },
  },
  {
    column_4: 50.6179826,
    column_3: 3.6085857,
    column_2: "Thieulain",
    column_1: "7901",
    geom: null,
    coordonnees: {
      lat: 50.6179826,
      lon: 3.6085857,
    },
  },
  {
    column_4: 50.5878005,
    column_3: 3.6857513,
    column_2: "Blicquy",
    column_1: "7903",
    geom: null,
    coordonnees: {
      lat: 50.5878005,
      lon: 3.6857513,
    },
  },
  {
    column_4: 50.6060715,
    column_3: 3.5748349,
    column_2: "Gallaix",
    column_1: "7906",
    geom: null,
    coordonnees: {
      lat: 50.6060715,
      lon: 3.5748349,
    },
  },
  {
    column_4: 50.688202,
    column_3: 3.5342514,
    column_2: "Cordes",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.688202,
      lon: 3.5342514,
    },
  },
  {
    column_4: 50.6522435,
    column_3: 3.5938505,
    column_2: "Hacquegnies",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.6522435,
      lon: 3.5938505,
    },
  },
  {
    column_4: 50.642909,
    column_3: 3.571359,
    column_2: "Montroeul-Au-Bois",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.642909,
      lon: 3.571359,
    },
  },
  {
    column_4: 50.6780664,
    column_3: 3.7117971,
    column_2: "Oeudeghien",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.6780664,
      lon: 3.7117971,
    },
  },
  {
    column_4: 50.712579,
    column_3: 3.5666,
    column_2: "Dergneau",
    column_1: "7912",
    geom: null,
    coordonnees: {
      lat: 50.712579,
      lon: 3.5666,
    },
  },
  {
    column_4: 50.6044689,
    column_3: 3.8507081,
    column_2: "Mévergnies-Lez-Lens",
    column_1: "7942",
    geom: null,
    coordonnees: {
      lat: 50.6044689,
      lon: 3.8507081,
    },
  },
  {
    column_4: 50.567984,
    column_3: 3.7713023,
    column_2: "Ladeuze",
    column_1: "7950",
    geom: null,
    coordonnees: {
      lat: 50.567984,
      lon: 3.7713023,
    },
  },
  {
    column_4: 50.581313,
    column_3: 3.7748043,
    column_2: "Tongre-Notre-Dame",
    column_1: "7951",
    geom: null,
    coordonnees: {
      lat: 50.581313,
      lon: 3.7748043,
    },
  },
  {
    column_4: 50.5385955,
    column_3: 3.6483678,
    column_2: "Wadelincourt",
    column_1: "7971",
    geom: null,
    coordonnees: {
      lat: 50.5385955,
      lon: 3.6483678,
    },
  },
  {
    column_4: 50.5302519,
    column_3: 3.6909731,
    column_2: "Quevaucamps",
    column_1: "7972",
    geom: null,
    coordonnees: {
      lat: 50.5302519,
      lon: 3.6909731,
    },
  },
  {
    column_4: 51.08464525,
    column_3: 3.2014781027698,
    column_2: "Ruddervoorde",
    column_1: "8020",
    geom: null,
    coordonnees: {
      lat: 51.08464525,
      lon: 3.20147810277,
    },
  },
  {
    column_4: 51.1123296,
    column_3: 3.2222872,
    column_2: "Waardamme",
    column_1: "8020",
    geom: null,
    coordonnees: {
      lat: 51.1123296,
      lon: 3.2222872,
    },
  },
  {
    column_4: 51.1355326,
    column_3: 3.1404473279372,
    column_2: "Zedelgem",
    column_1: "8210",
    geom: null,
    coordonnees: {
      lat: 51.1355326,
      lon: 3.14044732794,
    },
  },
  {
    column_4: 51.3207807,
    column_3: 3.3172865697943,
    column_2: "Westkapelle",
    column_1: "8300",
    geom: null,
    coordonnees: {
      lat: 51.3207807,
      lon: 3.31728656979,
    },
  },
  {
    column_4: 51.2142018,
    column_3: 3.2506508,
    column_2: "Sint-Kruis",
    column_1: "8310",
    geom: null,
    coordonnees: {
      lat: 51.2142018,
      lon: 3.2506508,
    },
  },
  {
    column_4: 51.2553021,
    column_3: 3.2920911130762,
    column_2: "Damme",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.2553021,
      lon: 3.29209111308,
    },
  },
  {
    column_4: 51.2936953,
    column_3: 3.3302838831505,
    column_2: "Hoeke",
    column_1: "8340",
    geom: null,
    coordonnees: {
      lat: 51.2936953,
      lon: 3.33028388315,
    },
  },
  {
    column_4: 51.31651475,
    column_3: 3.1297578035057,
    column_2: "Blankenberge",
    column_1: "8370",
    geom: null,
    coordonnees: {
      lat: 51.31651475,
      lon: 3.12975780351,
    },
  },
  {
    column_4: 51.275802,
    column_3: 3.0994149719104,
    column_2: "Nieuwmunster",
    column_1: "8377",
    geom: null,
    coordonnees: {
      lat: 51.275802,
      lon: 3.09941497191,
    },
  },
  {
    column_4: 51.2747463,
    column_3: 3.2292277,
    column_2: "Dudzele",
    column_1: "8380",
    geom: null,
    coordonnees: {
      lat: 51.2747463,
      lon: 3.2292277,
    },
  },
  {
    column_4: 51.2415107,
    column_3: 3.0049248,
    column_2: "De Haan",
    column_1: "8420",
    geom: null,
    coordonnees: {
      lat: 51.2415107,
      lon: 3.0049248,
    },
  },
  {
    column_4: 51.1228258,
    column_3: 2.8859081205773,
    column_2: "Sint-Pieters-Kapelle",
    column_1: "8433",
    geom: null,
    coordonnees: {
      lat: 51.1228258,
      lon: 2.88590812058,
    },
  },
  {
    column_4: 51.23890495,
    column_3: 2.9725769468284,
    column_2: "Bredene",
    column_1: "8450",
    geom: null,
    coordonnees: {
      lat: 51.23890495,
      lon: 2.97257694683,
    },
  },
  {
    column_4: 51.16355795,
    column_3: 3.0047445512126,
    column_2: "Westkerke",
    column_1: "8460",
    geom: null,
    coordonnees: {
      lat: 51.16355795,
      lon: 3.00474455121,
    },
  },
  {
    column_4: 51.1319588,
    column_3: 2.9565554411399,
    column_2: "Moere",
    column_1: "8470",
    geom: null,
    coordonnees: {
      lat: 51.1319588,
      lon: 2.95655544114,
    },
  },
  {
    column_4: 51.17552695,
    column_3: 3.1229871043255,
    column_2: "Snellegem",
    column_1: "8490",
    geom: null,
    coordonnees: {
      lat: 51.17552695,
      lon: 3.12298710433,
    },
  },
  {
    column_4: 51.19920035,
    column_3: 3.1361350320817,
    column_2: "Varsenare",
    column_1: "8490",
    geom: null,
    coordonnees: {
      lat: 51.19920035,
      lon: 3.13613503208,
    },
  },
  {
    column_4: 50.8228226,
    column_3: 3.2284723,
    column_2: "Bissegem",
    column_1: "8501",
    geom: null,
    coordonnees: {
      lat: 50.8228226,
      lon: 3.2284723,
    },
  },
  {
    column_4: 50.8790473,
    column_3: 3.3126044844799,
    column_2: "Bavikhove",
    column_1: "8531",
    geom: null,
    coordonnees: {
      lat: 50.8790473,
      lon: 3.31260448448,
    },
  },
  {
    column_4: 50.8820656,
    column_3: 3.2990273,
    column_2: "Hulste",
    column_1: "8531",
    geom: null,
    coordonnees: {
      lat: 50.8820656,
      lon: 3.2990273,
    },
  },
  {
    column_4: 50.775415,
    column_3: 3.3825263241918,
    column_2: "Moen",
    column_1: "8552",
    geom: null,
    coordonnees: {
      lat: 50.775415,
      lon: 3.38252632419,
    },
  },
  {
    column_4: 50.7903789,
    column_3: 3.4854202,
    column_2: "Waarmaarde",
    column_1: "8581",
    geom: null,
    coordonnees: {
      lat: 50.7903789,
      lon: 3.4854202,
    },
  },
  {
    column_4: 50.74804505,
    column_3: 3.4080819304802,
    column_2: "Bossuit",
    column_1: "8583",
    geom: null,
    coordonnees: {
      lat: 50.74804505,
      lon: 3.40808193048,
    },
  },
  {
    column_4: 50.72568025,
    column_3: 3.3465264652837,
    column_2: "Espierres-Helchin",
    column_1: "8587",
    geom: null,
    coordonnees: {
      lat: 50.72568025,
      lon: 3.34652646528,
    },
  },
  {
    column_4: 51.10066415,
    column_3: 2.8993179258716,
    column_2: "Leke",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.10066415,
      lon: 2.89931792587,
    },
  },
  {
    column_4: 51.07690075,
    column_3: 2.823381776939,
    column_2: "Stuivekenskerke",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.07690075,
      lon: 2.82338177694,
    },
  },
  {
    column_4: 51.0181095,
    column_3: 2.9597764,
    column_2: "Zarren",
    column_1: "8610",
    geom: null,
    coordonnees: {
      lat: 51.0181095,
      lon: 2.9597764,
    },
  },
  {
    column_4: 51.03587905,
    column_3: 2.5953950379549,
    column_2: "De Moeren",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.03587905,
      lon: 2.59539503795,
    },
  },
  {
    column_4: 51.0495264,
    column_3: 2.7298247748665,
    column_2: "Eggewaartskapelle",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.0495264,
      lon: 2.72982477487,
    },
  },
  {
    column_4: 51.0069146,
    column_3: 2.604305716244,
    column_2: "Houtem",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.0069146,
      lon: 2.60430571624,
    },
  },
  {
    column_4: 50.9931276,
    column_3: 2.7607800723908,
    column_2: "Lo",
    column_1: "8647",
    geom: null,
    coordonnees: {
      lat: 50.9931276,
      lon: 2.76078007239,
    },
  },
  {
    column_4: 51.06926315,
    column_3: 2.5896028375712,
    column_2: "Adinkerke",
    column_1: "8660",
    geom: null,
    coordonnees: {
      lat: 51.06926315,
      lon: 2.58960283757,
    },
  },
  {
    column_4: 51.12531835,
    column_3: 2.7012679966518,
    column_2: "Oostduinkerke",
    column_1: "8670",
    geom: null,
    coordonnees: {
      lat: 51.12531835,
      lon: 2.70126799665,
    },
  },
  {
    column_4: 50.9800701,
    column_3: 2.6903321,
    column_2: "Hoogstade",
    column_1: "8690",
    geom: null,
    coordonnees: {
      lat: 50.9800701,
      lon: 2.6903321,
    },
  },
  {
    column_4: 50.9945231,
    column_3: 2.6881434,
    column_2: "Sint-Rijkers",
    column_1: "8690",
    geom: null,
    coordonnees: {
      lat: 50.9945231,
      lon: 2.6881434,
    },
  },
  {
    column_4: 50.9761749,
    column_3: 2.6727552,
    column_2: "Gijverinkhove",
    column_1: "8691",
    geom: null,
    coordonnees: {
      lat: 50.9761749,
      lon: 2.6727552,
    },
  },
  {
    column_4: 50.9972412,
    column_3: 3.4139884196625,
    column_2: "Aarsele",
    column_1: "8700",
    geom: null,
    coordonnees: {
      lat: 50.9972412,
      lon: 3.41398841966,
    },
  },
  {
    column_4: 50.93483565,
    column_3: 3.4009830853574,
    column_2: "Wakken",
    column_1: "8720",
    geom: null,
    coordonnees: {
      lat: 50.93483565,
      lon: 3.40098308536,
    },
  },
  {
    column_4: 51.12774395,
    column_3: 3.3144732597608,
    column_2: "Beernem",
    column_1: "8730",
    geom: null,
    coordonnees: {
      lat: 51.12774395,
      lon: 3.31447325976,
    },
  },
  {
    column_4: 50.88302825,
    column_3: 3.3669119563274,
    column_2: "Desselgem",
    column_1: "8792",
    geom: null,
    coordonnees: {
      lat: 50.88302825,
      lon: 3.36691195633,
    },
  },
  {
    column_4: 50.9975916,
    column_3: 3.1103285411089,
    column_2: "Gits",
    column_1: "8830",
    geom: null,
    coordonnees: {
      lat: 50.9975916,
      lon: 3.11032854111,
    },
  },
  {
    column_4: 50.94159445,
    column_3: 3.0641586047981,
    column_2: "Oostnieuwkerke",
    column_1: "8840",
    geom: null,
    coordonnees: {
      lat: 50.94159445,
      lon: 3.0641586048,
    },
  },
  {
    column_4: 50.933009,
    column_3: 3.0095525945894,
    column_2: "Westrozebeke",
    column_1: "8840",
    geom: null,
    coordonnees: {
      lat: 50.933009,
      lon: 3.00955259459,
    },
  },
  {
    column_4: 50.86898195,
    column_3: 3.1176598783352,
    column_2: "Ledegem",
    column_1: "8880",
    geom: null,
    coordonnees: {
      lat: 50.86898195,
      lon: 3.11765987834,
    },
  },
  {
    column_4: 50.87005025,
    column_3: 3.1510232055719,
    column_2: "Rollegem-Kapelle",
    column_1: "8880",
    geom: null,
    coordonnees: {
      lat: 50.87005025,
      lon: 3.15102320557,
    },
  },
  {
    column_4: 50.88120105,
    column_3: 2.8086072093094,
    column_2: "Elverdinge",
    column_1: "8906",
    geom: null,
    coordonnees: {
      lat: 50.88120105,
      lon: 2.80860720931,
    },
  },
  {
    column_4: 50.9278167,
    column_3: 2.9632606863338,
    column_2: "Poelkapelle",
    column_1: "8920",
    geom: null,
    coordonnees: {
      lat: 50.9278167,
      lon: 2.96326068633,
    },
  },
  {
    column_4: 50.78879055,
    column_3: 3.1938978797182,
    column_2: "Lauwe",
    column_1: "8930",
    geom: null,
    coordonnees: {
      lat: 50.78879055,
      lon: 3.19389787972,
    },
  },
  {
    column_4: 50.8003371,
    column_3: 2.7409610507934,
    column_2: "Westouter",
    column_1: "8954",
    geom: null,
    coordonnees: {
      lat: 50.8003371,
      lon: 2.74096105079,
    },
  },
  {
    column_4: 50.7596526,
    column_3: 2.9001214234264,
    column_2: "Messines",
    column_1: "8957",
    geom: null,
    coordonnees: {
      lat: 50.7596526,
      lon: 2.90012142343,
    },
  },
  {
    column_4: 50.78372855,
    column_3: 2.7797309731213,
    column_2: "Loker",
    column_1: "8958",
    geom: null,
    coordonnees: {
      lat: 50.78372855,
      lon: 2.77973097312,
    },
  },
  {
    column_4: 50.9045753,
    column_3: 2.6729063768433,
    column_2: "Krombeke",
    column_1: "8972",
    geom: null,
    coordonnees: {
      lat: 50.9045753,
      lon: 2.67290637684,
    },
  },
  {
    column_4: 50.8731885,
    column_3: 2.9874678,
    column_2: "Zonnebeke",
    column_1: "8980",
    geom: null,
    coordonnees: {
      lat: 50.8731885,
      lon: 2.9874678,
    },
  },
  {
    column_4: 51.061875,
    column_3: 3.7488657,
    column_2: "Sint-Amandsberg",
    column_1: "9040",
    geom: null,
    coordonnees: {
      lat: 51.061875,
      lon: 3.7488657,
    },
  },
  {
    column_4: 51.1541943,
    column_3: 3.8234371,
    column_2: "Sint-Kruis-Winkel",
    column_1: "9042",
    geom: null,
    coordonnees: {
      lat: 51.1541943,
      lon: 3.8234371,
    },
  },
  {
    column_4: 51.00092235,
    column_3: 3.7021790930106,
    column_2: "Zwijnaarde",
    column_1: "9052",
    geom: null,
    coordonnees: {
      lat: 51.00092235,
      lon: 3.70217909301,
    },
  },
  {
    column_4: 51.0991574,
    column_3: 3.8271355,
    column_2: "Lochristi",
    column_1: "9080",
    geom: null,
    coordonnees: {
      lat: 51.0991574,
      lon: 3.8271355,
    },
  },
  {
    column_4: 51.1570236,
    column_3: 4.0917750185456,
    column_2: "Belsele",
    column_1: "9111",
    geom: null,
    coordonnees: {
      lat: 51.1570236,
      lon: 4.09177501855,
    },
  },
  {
    column_4: 51.3038751,
    column_3: 4.1983386657846,
    column_2: "Kieldrecht",
    column_1: "9130",
    geom: null,
    coordonnees: {
      lat: 51.3038751,
      lon: 4.19833866578,
    },
  },
  {
    column_4: 51.2540739,
    column_3: 4.1871839695525,
    column_2: "Verrebroek",
    column_1: "9130",
    geom: null,
    coordonnees: {
      lat: 51.2540739,
      lon: 4.18718396955,
    },
  },
  {
    column_4: 51.12202775,
    column_3: 4.1736809973609,
    column_2: "Tielrode",
    column_1: "9140",
    geom: null,
    coordonnees: {
      lat: 51.12202775,
      lon: 4.17368099736,
    },
  },
  {
    column_4: 51.12798595,
    column_3: 3.9808547859604,
    column_2: "Daknam",
    column_1: "9160",
    geom: null,
    coordonnees: {
      lat: 51.12798595,
      lon: 3.98085478596,
    },
  },
  {
    column_4: 51.1042159,
    column_3: 3.9911114,
    column_2: "Lokeren",
    column_1: "9160",
    geom: null,
    coordonnees: {
      lat: 51.1042159,
      lon: 3.9911114,
    },
  },
  {
    column_4: 51.2065228,
    column_3: 4.0771037,
    column_2: "Kemzeke",
    column_1: "9190",
    geom: null,
    coordonnees: {
      lat: 51.2065228,
      lon: 4.0771037,
    },
  },
  {
    column_4: 51.02983825,
    column_3: 4.0562275450955,
    column_2: "Appels",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.02983825,
      lon: 4.0562275451,
    },
  },
  {
    column_4: 50.9697855,
    column_3: 3.8614248,
    column_2: "Westrem",
    column_1: "9230",
    geom: null,
    coordonnees: {
      lat: 50.9697855,
      lon: 3.8614248,
    },
  },
  {
    column_4: 51.0113077,
    column_3: 4.192804319009,
    column_2: "Buggenhout",
    column_1: "9255",
    geom: null,
    coordonnees: {
      lat: 51.0113077,
      lon: 4.19280431901,
    },
  },
  {
    column_4: 51.0370133,
    column_3: 3.918568,
    column_2: "Kalken",
    column_1: "9270",
    geom: null,
    coordonnees: {
      lat: 51.0370133,
      lon: 3.918568,
    },
  },
  {
    column_4: 51.0278435,
    column_3: 3.9639785004366,
    column_2: "Uitbergen",
    column_1: "9290",
    geom: null,
    coordonnees: {
      lat: 51.0278435,
      lon: 3.96397850044,
    },
  },
  {
    column_4: 50.9429755,
    column_3: 4.0396424203486,
    column_2: "Aalst",
    column_1: "9300",
    geom: null,
    coordonnees: {
      lat: 50.9429755,
      lon: 4.03964242035,
    },
  },
  {
    column_4: 50.96682385,
    column_3: 4.0298563154701,
    column_2: "Hofstade",
    column_1: "9308",
    geom: null,
    coordonnees: {
      lat: 50.96682385,
      lon: 4.02985631547,
    },
  },
  {
    column_4: 50.9660712,
    column_3: 3.9269566,
    column_2: "Smetlede",
    column_1: "9340",
    geom: null,
    coordonnees: {
      lat: 50.9660712,
      lon: 3.9269566,
    },
  },
  {
    column_4: 50.8168641,
    column_3: 3.9969451,
    column_2: "Pollare",
    column_1: "9401",
    geom: null,
    coordonnees: {
      lat: 50.8168641,
      lon: 3.9969451,
    },
  },
  {
    column_4: 50.8894174,
    column_3: 3.9366909,
    column_2: "Aaigem",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.8894174,
      lon: 3.9366909,
    },
  },
  {
    column_4: 50.878059,
    column_3: 4.0059338596505,
    column_2: "Denderhoutem",
    column_1: "9450",
    geom: null,
    coordonnees: {
      lat: 50.878059,
      lon: 4.00593385965,
    },
  },
  {
    column_4: 50.88457295,
    column_3: 3.9792346670559,
    column_2: "Kerksken",
    column_1: "9451",
    geom: null,
    coordonnees: {
      lat: 50.88457295,
      lon: 3.97923466706,
    },
  },
  {
    column_4: 50.9005405,
    column_3: 4.0448599,
    column_2: "Welle",
    column_1: "9473",
    geom: null,
    coordonnees: {
      lat: 50.9005405,
      lon: 4.0448599,
    },
  },
  {
    column_4: 50.8043243,
    column_3: 3.9295308016087,
    column_2: "Idegem",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.8043243,
      lon: 3.92953080161,
    },
  },
  {
    column_4: 50.81624535,
    column_3: 3.9259210427614,
    column_2: "Smeerebbe-Vloerzegem",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.81624535,
      lon: 3.92592104276,
    },
  },
  {
    column_4: 50.8988376,
    column_3: 3.8381185,
    column_2: "Oombergen",
    column_1: "9520",
    geom: null,
    coordonnees: {
      lat: 50.8988376,
      lon: 3.8381185,
    },
  },
  {
    column_4: 50.93017155,
    column_3: 3.9163011372068,
    column_2: "Zonnegem",
    column_1: "9520",
    geom: null,
    coordonnees: {
      lat: 50.93017155,
      lon: 3.91630113721,
    },
  },
  {
    column_4: 50.88830225,
    column_3: 3.8873953834097,
    column_2: "Herzele",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.88830225,
      lon: 3.88739538341,
    },
  },
  {
    column_4: 50.8553127,
    column_3: 3.8871129,
    column_2: "Sint-Lievens-Esse",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.8553127,
      lon: 3.8871129,
    },
  },
  {
    column_4: 50.8088369,
    column_3: 3.8638783508569,
    column_2: "Hemelveerdegem",
    column_1: "9571",
    geom: null,
    coordonnees: {
      lat: 50.8088369,
      lon: 3.86387835086,
    },
  },
  {
    column_4: 50.80452055,
    column_3: 3.8157846440419,
    column_2: "Sint-Martens-Lierde",
    column_1: "9572",
    geom: null,
    coordonnees: {
      lat: 50.80452055,
      lon: 3.81578464404,
    },
  },
  {
    column_4: 50.8558332,
    column_3: 3.8142328,
    column_2: "Erwetegem",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8558332,
      lon: 3.8142328,
    },
  },
  {
    column_4: 50.8594123,
    column_3: 3.8204374,
    column_2: "Godveerdegem",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8594123,
      lon: 3.8204374,
    },
  },
  {
    column_4: 50.82512665,
    column_3: 3.7998568349562,
    column_2: "Sint-Maria-Oudenhove",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.82512665,
      lon: 3.79985683496,
    },
  },
  {
    column_4: 50.86909165,
    column_3: 3.8155460176798,
    column_2: "Zottegem",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.86909165,
      lon: 3.81554601768,
    },
  },
  {
    column_4: 50.90267645,
    column_3: 3.719796314005,
    column_2: "Beerlegem",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.90267645,
      lon: 3.719796314,
    },
  },
  {
    column_4: 50.9034583,
    column_3: 3.7418503,
    column_2: "Dikkele",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.9034583,
      lon: 3.7418503,
    },
  },
  {
    column_4: 50.8891513,
    column_3: 3.7552865,
    column_2: "Hundelgem",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8891513,
      lon: 3.7552865,
    },
  },
  {
    column_4: 50.8793307,
    column_3: 3.719334,
    column_2: "Zwalm",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8793307,
      lon: 3.719334,
    },
  },
  {
    column_4: 50.8348628,
    column_3: 3.6920346,
    column_2: "Horebeke",
    column_1: "9667",
    geom: null,
    coordonnees: {
      lat: 50.8348628,
      lon: 3.6920346,
    },
  },
  {
    column_4: 50.8356339,
    column_3: 3.6991826,
    column_2: "Sint-Kornelis-Horebeke",
    column_1: "9667",
    geom: null,
    coordonnees: {
      lat: 50.8356339,
      lon: 3.6991826,
    },
  },
  {
    column_4: 50.8129246,
    column_3: 3.625998,
    column_2: "Etikhove",
    column_1: "9680",
    geom: null,
    coordonnees: {
      lat: 50.8129246,
      lon: 3.625998,
    },
  },
  {
    column_4: 50.7782986,
    column_3: 3.5212498,
    column_2: "Kluisbergen",
    column_1: "9690",
    geom: null,
    coordonnees: {
      lat: 50.7782986,
      lon: 3.5212498,
    },
  },
  {
    column_4: 50.7739701,
    column_3: 3.4860074,
    column_2: "Ruien",
    column_1: "9690",
    geom: null,
    coordonnees: {
      lat: 50.7739701,
      lon: 3.4860074,
    },
  },
  {
    column_4: 50.7912081,
    column_3: 3.569982,
    column_2: "Zulzeke",
    column_1: "9690",
    geom: null,
    coordonnees: {
      lat: 50.7912081,
      lon: 3.569982,
    },
  },
  {
    column_4: 50.8513634,
    column_3: 3.5955691,
    column_2: "Bevere",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8513634,
      lon: 3.5955691,
    },
  },
  {
    column_4: 50.9200896,
    column_3: 3.5404462768494,
    column_2: "Kruishoutem",
    column_1: "9770",
    geom: null,
    coordonnees: {
      lat: 50.9200896,
      lon: 3.54044627685,
    },
  },
  {
    column_4: 50.8503548,
    column_3: 3.5623575,
    column_2: "Moregem",
    column_1: "9790",
    geom: null,
    coordonnees: {
      lat: 50.8503548,
      lon: 3.5623575,
    },
  },
  {
    column_4: 50.9764412,
    column_3: 3.468333,
    column_2: "Grammene",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9764412,
      lon: 3.468333,
    },
  },
  {
    column_4: 50.9635195,
    column_3: 3.7552702,
    column_2: "Bottelare",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9635195,
      lon: 3.7552702,
    },
  },
  {
    column_4: 50.9452452,
    column_3: 3.7445458,
    column_2: "Munte",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9452452,
      lon: 3.7445458,
    },
  },
  {
    column_4: 50.9698809,
    column_3: 3.7131906,
    column_2: "Schelderode",
    column_1: "9820",
    geom: null,
    coordonnees: {
      lat: 50.9698809,
      lon: 3.7131906,
    },
  },
  {
    column_4: 51.0134462,
    column_3: 3.6306291238782,
    column_2: "Sint-Martens-Latem",
    column_1: "9830",
    geom: null,
    coordonnees: {
      lat: 51.0134462,
      lon: 3.63062912388,
    },
  },
  {
    column_4: 50.971061,
    column_3: 3.8164475,
    column_2: "Gijzenzele",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.971061,
      lon: 3.8164475,
    },
  },
  {
    column_4: 50.9357958,
    column_3: 3.4651619,
    column_2: "Olsene",
    column_1: "9870",
    geom: null,
    coordonnees: {
      lat: 50.9357958,
      lon: 3.4651619,
    },
  },
  {
    column_4: 50.9292335,
    column_3: 3.6614192,
    column_2: "Gavere",
    column_1: "9890",
    geom: null,
    coordonnees: {
      lat: 50.9292335,
      lon: 3.6614192,
    },
  },
  {
    column_4: 51.150867,
    column_3: 3.5253176,
    column_2: "Oostwinkel",
    column_1: "9931",
    geom: null,
    coordonnees: {
      lat: 51.150867,
      lon: 3.5253176,
    },
  },
  {
    column_4: 51.1547125,
    column_3: 3.732612,
    column_2: "Kluizen",
    column_1: "9940",
    geom: null,
    coordonnees: {
      lat: 51.1547125,
      lon: 3.732612,
    },
  },
  {
    column_4: 51.228952,
    column_3: 3.6219251372375,
    column_2: "Kaprijke",
    column_1: "9970",
    geom: null,
    coordonnees: {
      lat: 51.228952,
      lon: 3.62192513724,
    },
  },
  {
    column_4: 51.19424,
    column_3: 3.6346312134542,
    column_2: "Lembeke",
    column_1: "9971",
    geom: null,
    coordonnees: {
      lat: 51.19424,
      lon: 3.63463121345,
    },
  },
  {
    column_4: 51.26767195,
    column_3: 3.5315005565828,
    column_2: "Sint-Margriete",
    column_1: "9981",
    geom: null,
    coordonnees: {
      lat: 51.26767195,
      lon: 3.53150055658,
    },
  },
  {
    column_4: 51.2035291,
    column_3: 3.4858471,
    column_2: "Adegem",
    column_1: "9991",
    geom: null,
    coordonnees: {
      lat: 51.2035291,
      lon: 3.4858471,
    },
  },
  {
    column_4: 51.25583235,
    column_3: 3.4071425562584,
    column_2: "Middelburg",
    column_1: "9992",
    geom: null,
    coordonnees: {
      lat: 51.25583235,
      lon: 3.40714255626,
    },
  },
  {
    column_4: 50.8368511,
    column_3: 4.3895104,
    column_2: "Etterbeek",
    column_1: "1040",
    geom: null,
    coordonnees: {
      lat: 50.8368511,
      lon: 4.3895104,
    },
  },
  {
    column_4: 50.8381411,
    column_3: 4.3123401,
    column_2: "Anderlecht",
    column_1: "1070",
    geom: null,
    coordonnees: {
      lat: 50.8381411,
      lon: 4.3123401,
    },
  },
  {
    column_4: 50.8156571,
    column_3: 4.4331389,
    column_2: "Auderghem",
    column_1: "1160",
    geom: null,
    coordonnees: {
      lat: 50.8156571,
      lon: 4.4331389,
    },
  },
  {
    column_4: 50.8018201,
    column_3: 4.3372348,
    column_2: "Uccle",
    column_1: "1180",
    geom: null,
    coordonnees: {
      lat: 50.8018201,
      lon: 4.3372348,
    },
  },
  {
    column_4: 50.8091426,
    column_3: 4.3177512,
    column_2: "Forest",
    column_1: "1190",
    geom: null,
    coordonnees: {
      lat: 50.8091426,
      lon: 4.3177512,
    },
  },
  {
    column_4: 50.6930127,
    column_3: 4.5738899,
    column_2: "Limal",
    column_1: "1300",
    geom: null,
    coordonnees: {
      lat: 50.6930127,
      lon: 4.5738899,
    },
  },
  {
    column_4: 50.7777654,
    column_3: 4.7472104,
    column_2: "Tourinnes-La-Grosse",
    column_1: "1320",
    geom: null,
    coordonnees: {
      lat: 50.7777654,
      lon: 4.7472104,
    },
  },
  {
    column_4: 50.7158849,
    column_3: 4.6614812,
    column_2: "Dion-Valmont",
    column_1: "1325",
    geom: null,
    coordonnees: {
      lat: 50.7158849,
      lon: 4.6614812,
    },
  },
  {
    column_4: 50.7024404,
    column_3: 4.7392389,
    column_2: "Longueville",
    column_1: "1325",
    geom: null,
    coordonnees: {
      lat: 50.7024404,
      lon: 4.7392389,
    },
  },
  {
    column_4: 50.6366892,
    column_3: 4.9148994,
    column_2: "Ramillies",
    column_1: "1367",
    geom: null,
    coordonnees: {
      lat: 50.6366892,
      lon: 4.9148994,
    },
  },
  {
    column_4: 50.6866092,
    column_3: 4.8435069,
    column_2: "Jauchelette",
    column_1: "1370",
    geom: null,
    coordonnees: {
      lat: 50.6866092,
      lon: 4.8435069,
    },
  },
  {
    column_4: 50.695114,
    column_3: 4.4505534162388,
    column_2: "Ohain",
    column_1: "1380",
    geom: null,
    coordonnees: {
      lat: 50.695114,
      lon: 4.45055341624,
    },
  },
  {
    column_4: 50.7516077,
    column_3: 4.6702286,
    column_2: "Archennes",
    column_1: "1390",
    geom: null,
    coordonnees: {
      lat: 50.7516077,
      lon: 4.6702286,
    },
  },
  {
    column_4: 50.58916295,
    column_3: 4.3319091758631,
    column_2: "Nivelles",
    column_1: "1400",
    geom: null,
    coordonnees: {
      lat: 50.58916295,
      lon: 4.33190917586,
    },
  },
  {
    column_4: 50.58678735,
    column_3: 4.3825954093731,
    column_2: "Thines",
    column_1: "1402",
    geom: null,
    coordonnees: {
      lat: 50.58678735,
      lon: 4.38259540937,
    },
  },
  {
    column_4: 50.6719076,
    column_3: 4.1627021400155,
    column_2: "Quenast",
    column_1: "1430",
    geom: null,
    coordonnees: {
      lat: 50.6719076,
      lon: 4.16270214002,
    },
  },
  {
    column_4: 50.6645894,
    column_3: 4.1355466,
    column_2: "Rebecq",
    column_1: "1430",
    geom: null,
    coordonnees: {
      lat: 50.6645894,
      lon: 4.1355466,
    },
  },
  {
    column_4: 50.6491686,
    column_3: 4.6408286063936,
    column_2: "Corbais",
    column_1: "1435",
    geom: null,
    coordonnees: {
      lat: 50.6491686,
      lon: 4.64082860639,
    },
  },
  {
    column_4: 50.6220893,
    column_3: 4.6167772,
    column_2: "Hévillers",
    column_1: "1435",
    geom: null,
    coordonnees: {
      lat: 50.6220893,
      lon: 4.6167772,
    },
  },
  {
    column_4: 50.63691535,
    column_3: 4.4464886939844,
    column_2: "Glabais",
    column_1: "1473",
    geom: null,
    coordonnees: {
      lat: 50.63691535,
      lon: 4.44648869398,
    },
  },
  {
    column_4: 50.5762935,
    column_3: 4.4110339517433,
    column_2: "Houtain-Le-Val",
    column_1: "1476",
    geom: null,
    coordonnees: {
      lat: 50.5762935,
      lon: 4.41103395174,
    },
  },
  {
    column_4: 50.7164481,
    column_3: 3.943147,
    column_2: "Biévène",
    column_1: "1547",
    geom: null,
    coordonnees: {
      lat: 50.7164481,
      lon: 3.943147,
    },
  },
  {
    column_4: 50.7716092,
    column_3: 4.3345641,
    column_2: "Linkebeek",
    column_1: "1630",
    geom: null,
    coordonnees: {
      lat: 50.7716092,
      lon: 4.3345641,
    },
  },
  {
    column_4: 50.74503,
    column_3: 4.3463687,
    column_2: "Rhode-Saint-Genèse",
    column_1: "1640",
    geom: null,
    coordonnees: {
      lat: 50.74503,
      lon: 4.3463687,
    },
  },
  {
    column_4: 50.7684432,
    column_3: 4.3051321494591,
    column_2: "Beersel",
    column_1: "1650",
    geom: null,
    coordonnees: {
      lat: 50.7684432,
      lon: 4.30513214946,
    },
  },
  {
    column_4: 50.74931535,
    column_3: 4.2732545968764,
    column_2: "Huizingen",
    column_1: "1654",
    geom: null,
    coordonnees: {
      lat: 50.74931535,
      lon: 4.27325459688,
    },
  },
  {
    column_4: 50.7511702,
    column_3: 4.1785882891377,
    column_2: "Pepingen",
    column_1: "1670",
    geom: null,
    coordonnees: {
      lat: 50.7511702,
      lon: 4.17858828914,
    },
  },
  {
    column_4: 50.85672975,
    column_3: 4.1646372476962,
    column_2: "Wambeek",
    column_1: "1741",
    geom: null,
    coordonnees: {
      lat: 50.85672975,
      lon: 4.1646372477,
    },
  },
  {
    column_4: 50.9690529,
    column_3: 4.1896665,
    column_2: "Opwijk",
    column_1: "1745",
    geom: null,
    coordonnees: {
      lat: 50.9690529,
      lon: 4.1896665,
    },
  },
  {
    column_4: 50.8341383,
    column_3: 4.0972238,
    column_2: "Roosdaal",
    column_1: "1760",
    geom: null,
    coordonnees: {
      lat: 50.8341383,
      lon: 4.0972238,
    },
  },
  {
    column_4: 50.84817805,
    column_3: 4.1369148649611,
    column_2: "Borchtlombeek",
    column_1: "1761",
    geom: null,
    coordonnees: {
      lat: 50.84817805,
      lon: 4.13691486496,
    },
  },
  {
    column_4: 50.9591981,
    column_3: 4.2327769,
    column_2: "Merchtem",
    column_1: "1785",
    geom: null,
    coordonnees: {
      lat: 50.9591981,
      lon: 4.2327769,
    },
  },
  {
    column_4: 50.9035063,
    column_3: 4.1174675,
    column_2: "Affligem",
    column_1: "1790",
    geom: null,
    coordonnees: {
      lat: 50.9035063,
      lon: 4.1174675,
    },
  },
  {
    column_4: 50.9137991,
    column_3: 4.1118354895388,
    column_2: "Hekelgem",
    column_1: "1790",
    geom: null,
    coordonnees: {
      lat: 50.9137991,
      lon: 4.11183548954,
    },
  },
  {
    column_4: 50.9271744,
    column_3: 4.4551981,
    column_2: "Peutie",
    column_1: "1800",
    geom: null,
    coordonnees: {
      lat: 50.9271744,
      lon: 4.4551981,
    },
  },
  {
    column_4: 50.9170725,
    column_3: 4.4758811,
    column_2: "Melsbroek",
    column_1: "1820",
    geom: null,
    coordonnees: {
      lat: 50.9170725,
      lon: 4.4758811,
    },
  },
  {
    column_4: 50.9093488,
    column_3: 4.5110471,
    column_2: "Steenokkerzeel",
    column_1: "1820",
    geom: null,
    coordonnees: {
      lat: 50.9093488,
      lon: 4.5110471,
    },
  },
  {
    column_4: 50.9728229,
    column_3: 4.3777599282045,
    column_2: "Humbeek",
    column_1: "1851",
    geom: null,
    coordonnees: {
      lat: 50.9728229,
      lon: 4.3777599282,
    },
  },
  {
    column_4: 51.1695659,
    column_3: 5.1521172,
    column_2: "Berg",
    column_1: "1910",
    geom: null,
    coordonnees: {
      lat: 51.1695659,
      lon: 5.1521172,
    },
  },
  {
    column_4: 50.8662434,
    column_3: 4.4359939711919,
    column_2: "Sint-Stevens-Woluwe",
    column_1: "1932",
    geom: null,
    coordonnees: {
      lat: 50.8662434,
      lon: 4.43599397119,
    },
  },
  {
    column_4: 50.9865028,
    column_3: 4.44412333945,
    column_2: "Zemst",
    column_1: "1980",
    geom: null,
    coordonnees: {
      lat: 50.9865028,
      lon: 4.44412333945,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2018",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2030",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.3455396,
    column_3: 4.319401,
    column_2: "Berendrecht",
    column_1: "2040",
    geom: null,
    coordonnees: {
      lat: 51.3455396,
      lon: 4.319401,
    },
  },
  {
    column_4: 51.3045534,
    column_3: 4.2900122,
    column_2: "Lillo",
    column_1: "2040",
    geom: null,
    coordonnees: {
      lat: 51.3045534,
      lon: 4.2900122,
    },
  },
  {
    column_4: 51.2211097,
    column_3: 4.3997081,
    column_2: "Antwerpen",
    column_1: "2060",
    geom: null,
    coordonnees: {
      lat: 51.2211097,
      lon: 4.3997081,
    },
  },
  {
    column_4: 51.1928907,
    column_3: 4.488953,
    column_2: "Borsbeek",
    column_1: "2150",
    geom: null,
    coordonnees: {
      lat: 51.1928907,
      lon: 4.488953,
    },
  },
  {
    column_4: 51.24994295,
    column_3: 4.4453401635115,
    column_2: "Merksem",
    column_1: "2170",
    geom: null,
    coordonnees: {
      lat: 51.24994295,
      lon: 4.44534016351,
    },
  },
  {
    column_4: 51.2907379,
    column_3: 4.4361161,
    column_2: "Schriek",
    column_1: "2223",
    geom: null,
    coordonnees: {
      lat: 51.2907379,
      lon: 4.4361161,
    },
  },
  {
    column_4: 51.0603638,
    column_3: 4.8351334,
    column_2: "Westmeerbeek",
    column_1: "2235",
    geom: null,
    coordonnees: {
      lat: 51.0603638,
      lon: 4.8351334,
    },
  },
  {
    column_4: 51.086694,
    column_3: 4.9168675,
    column_2: "Westerlo",
    column_1: "2260",
    geom: null,
    coordonnees: {
      lat: 51.086694,
      lon: 4.9168675,
    },
  },
  {
    column_4: 51.16210045,
    column_3: 4.7370614385825,
    column_2: "Bouwel",
    column_1: "2288",
    geom: null,
    coordonnees: {
      lat: 51.16210045,
      lon: 4.73706143858,
    },
  },
  {
    column_4: 51.3233812,
    column_3: 4.948461,
    column_2: "Turnhout",
    column_1: "2300",
    geom: null,
    coordonnees: {
      lat: 51.3233812,
      lon: 4.948461,
    },
  },
  {
    column_4: 51.3495046,
    column_3: 4.760708,
    column_2: "Rijkevorsel",
    column_1: "2310",
    geom: null,
    coordonnees: {
      lat: 51.3495046,
      lon: 4.760708,
    },
  },
  {
    column_4: 51.3134921,
    column_3: 4.837570452313,
    column_2: "Beerse",
    column_1: "2340",
    geom: null,
    coordonnees: {
      lat: 51.3134921,
      lon: 4.83757045231,
    },
  },
  {
    column_4: 51.3178319,
    column_3: 4.9817086,
    column_2: "Oud-Turnhout",
    column_1: "2360",
    geom: null,
    coordonnees: {
      lat: 51.3178319,
      lon: 4.9817086,
    },
  },
  {
    column_4: 51.45063155,
    column_3: 5.0488069670452,
    column_2: "Poppel",
    column_1: "2382",
    geom: null,
    coordonnees: {
      lat: 51.45063155,
      lon: 5.04880696705,
    },
  },
  {
    column_4: 51.29940565,
    column_3: 4.6713095211155,
    column_2: "Westmalle",
    column_1: "2390",
    geom: null,
    coordonnees: {
      lat: 51.29940565,
      lon: 4.67130952112,
    },
  },
  {
    column_4: 51.0592049,
    column_3: 4.9805536902848,
    column_2: "Veerle",
    column_1: "2431",
    geom: null,
    coordonnees: {
      lat: 51.0592049,
      lon: 4.98055369028,
    },
  },
  {
    column_4: 51.1610826,
    column_3: 4.9903187,
    column_2: "Geel",
    column_1: "2440",
    geom: null,
    coordonnees: {
      lat: 51.1610826,
      lon: 4.9903187,
    },
  },
  {
    column_4: 51.2395713,
    column_3: 5.113243,
    column_2: "Dessel",
    column_1: "2480",
    geom: null,
    coordonnees: {
      lat: 51.2395713,
      lon: 5.113243,
    },
  },
  {
    column_4: 51.171804,
    column_3: 5.1937730761009,
    column_2: "Balen",
    column_1: "2490",
    geom: null,
    coordonnees: {
      lat: 51.171804,
      lon: 5.1937730761,
    },
  },
  {
    column_4: 51.1625761,
    column_3: 4.6047327,
    column_2: "Emblem",
    column_1: "2520",
    geom: null,
    coordonnees: {
      lat: 51.1625761,
      lon: 4.6047327,
    },
  },
  {
    column_4: 51.1268305,
    column_3: 4.4922187,
    column_2: "Lint",
    column_1: "2547",
    geom: null,
    coordonnees: {
      lat: 51.1268305,
      lon: 4.4922187,
    },
  },
  {
    column_4: 51.134377,
    column_3: 4.4456359,
    column_2: "Kontich",
    column_1: "2550",
    geom: null,
    coordonnees: {
      lat: 51.134377,
      lon: 4.4456359,
    },
  },
  {
    column_4: 51.1364316,
    column_3: 4.6922875770195,
    column_2: "Bevel",
    column_1: "2560",
    geom: null,
    coordonnees: {
      lat: 51.1364316,
      lon: 4.69228757702,
    },
  },
  {
    column_4: 51.12758685,
    column_3: 4.6646313199891,
    column_2: "Gestel",
    column_1: "2590",
    geom: null,
    coordonnees: {
      lat: 51.12758685,
      lon: 4.66463131999,
    },
  },
  {
    column_4: 51.1638451,
    column_3: 4.3876309610455,
    column_2: "Wilrijk",
    column_1: "2610",
    geom: null,
    coordonnees: {
      lat: 51.1638451,
      lon: 4.38763096105,
    },
  },
  {
    column_4: 51.0350559,
    column_3: 4.4150058,
    column_2: "Leest",
    column_1: "2811",
    geom: null,
    coordonnees: {
      lat: 51.0350559,
      lon: 4.4150058,
    },
  },
  {
    column_4: 51.0083206,
    column_3: 4.5182999731643,
    column_2: "Muizen",
    column_1: "2812",
    geom: null,
    coordonnees: {
      lat: 51.0083206,
      lon: 4.51829997316,
    },
  },
  {
    column_4: 51.0792853,
    column_3: 4.4240472,
    column_2: "Rumst",
    column_1: "2840",
    geom: null,
    coordonnees: {
      lat: 51.0792853,
      lon: 4.4240472,
    },
  },
  {
    column_4: 51.07199845,
    column_3: 4.2861928678797,
    column_2: "Puurs",
    column_1: "2870",
    geom: null,
    coordonnees: {
      lat: 51.07199845,
      lon: 4.28619286788,
    },
  },
  {
    column_4: 51.10151195,
    column_3: 4.1855882750296,
    column_2: "Weert",
    column_1: "2880",
    geom: null,
    coordonnees: {
      lat: 51.10151195,
      lon: 4.18558827503,
    },
  },
  {
    column_4: 51.06220625,
    column_3: 4.2389889523045,
    column_2: "Oppuurs",
    column_1: "2890",
    geom: null,
    coordonnees: {
      lat: 51.06220625,
      lon: 4.2389889523,
    },
  },
  {
    column_4: 51.2930933,
    column_3: 4.489307,
    column_2: "Brasschaat",
    column_1: "2930",
    geom: null,
    coordonnees: {
      lat: 51.2930933,
      lon: 4.489307,
    },
  },
  {
    column_4: 51.33518735,
    column_3: 4.370312016044,
    column_2: "Stabroek",
    column_1: "2940",
    geom: null,
    coordonnees: {
      lat: 51.33518735,
      lon: 4.37031201604,
    },
  },
  {
    column_4: 51.3453831,
    column_3: 4.6782162,
    column_2: "Sint-Lenaarts",
    column_1: "2960",
    geom: null,
    coordonnees: {
      lat: 51.3453831,
      lon: 4.6782162,
    },
  },
  {
    column_4: 50.8812533,
    column_3: 4.6929903215189,
    column_2: "Leuven",
    column_1: "3000",
    geom: null,
    coordonnees: {
      lat: 50.8812533,
      lon: 4.69299032152,
    },
  },
  {
    column_4: 50.7794761,
    column_3: 4.6329556561262,
    column_2: "Sint-Agatha-Rode",
    column_1: "3040",
    geom: null,
    coordonnees: {
      lat: 50.7794761,
      lon: 4.63295565613,
    },
  },
  {
    column_4: 50.8051035,
    column_3: 4.6458289787229,
    column_2: "Sint-Joris-Weert",
    column_1: "3051",
    geom: null,
    coordonnees: {
      lat: 50.8051035,
      lon: 4.64582897872,
    },
  },
  {
    column_4: 50.81785645,
    column_3: 4.7283706944623,
    column_2: "Haasrode",
    column_1: "3053",
    geom: null,
    coordonnees: {
      lat: 50.81785645,
      lon: 4.72837069446,
    },
  },
  {
    column_4: 50.7729254,
    column_3: 4.5385226,
    column_2: "Overijse",
    column_1: "3090",
    geom: null,
    coordonnees: {
      lat: 50.7729254,
      lon: 4.5385226,
    },
  },
  {
    column_4: 50.9543637,
    column_3: 4.7143205960258,
    column_2: "Rotselaar",
    column_1: "3110",
    geom: null,
    coordonnees: {
      lat: 50.9543637,
      lon: 4.71432059603,
    },
  },
  {
    column_4: 50.9482552,
    column_3: 4.7683306885409,
    column_2: "Wezemaal",
    column_1: "3111",
    geom: null,
    coordonnees: {
      lat: 50.9482552,
      lon: 4.76833068854,
    },
  },
  {
    column_4: 50.9707189,
    column_3: 4.7026877230529,
    column_2: "Werchter",
    column_1: "3118",
    geom: null,
    coordonnees: {
      lat: 50.9707189,
      lon: 4.70268772305,
    },
  },
  {
    column_4: 50.99602795,
    column_3: 4.7908301771478,
    column_2: "Betekom",
    column_1: "3130",
    geom: null,
    coordonnees: {
      lat: 50.99602795,
      lon: 4.79083017715,
    },
  },
  {
    column_4: 50.95645295,
    column_3: 4.6325890563885,
    column_2: "Wespelaar",
    column_1: "3150",
    geom: null,
    coordonnees: {
      lat: 50.95645295,
      lon: 4.63258905639,
    },
  },
  {
    column_4: 50.9841594,
    column_3: 4.8245687,
    column_2: "Aarschot",
    column_1: "3200",
    geom: null,
    coordonnees: {
      lat: 50.9841594,
      lon: 4.8245687,
    },
  },
  {
    column_4: 50.8821122,
    column_3: 4.8400599406417,
    column_2: "Lubbeek",
    column_1: "3210",
    geom: null,
    coordonnees: {
      lat: 50.8821122,
      lon: 4.84005994064,
    },
  },
  {
    column_4: 50.91851005,
    column_3: 4.7854555627527,
    column_2: "Kortrijk-Dutsel",
    column_1: "3220",
    geom: null,
    coordonnees: {
      lat: 50.91851005,
      lon: 4.78545556275,
    },
  },
  {
    column_4: 50.98948425,
    column_3: 4.924667681525,
    column_2: "Messelbroek",
    column_1: "3272",
    geom: null,
    coordonnees: {
      lat: 50.98948425,
      lon: 4.92466768153,
    },
  },
  {
    column_4: 50.85475565,
    column_3: 4.7866873360324,
    column_2: "Lovenjoel",
    column_1: "3360",
    geom: null,
    coordonnees: {
      lat: 50.85475565,
      lon: 4.78668733603,
    },
  },
  {
    column_4: 50.8166881,
    column_3: 4.8136699565725,
    column_2: "Neervelp",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.8166881,
      lon: 4.81366995657,
    },
  },
  {
    column_4: 50.83400235,
    column_3: 4.8270179962162,
    column_2: "Vertrijk",
    column_1: "3370",
    geom: null,
    coordonnees: {
      lat: 50.83400235,
      lon: 4.82701799622,
    },
  },
  {
    column_4: 50.7493167,
    column_3: 5.0790966118688,
    column_2: "Landen",
    column_1: "3400",
    geom: null,
    coordonnees: {
      lat: 50.7493167,
      lon: 5.07909661187,
    },
  },
  {
    column_4: 50.71820555,
    column_3: 5.0869975953341,
    column_2: "Walshoutem",
    column_1: "3401",
    geom: null,
    coordonnees: {
      lat: 50.71820555,
      lon: 5.08699759533,
    },
  },
  {
    column_4: 50.732168,
    column_3: 5.1094961371256,
    column_2: "Wezeren",
    column_1: "3401",
    geom: null,
    coordonnees: {
      lat: 50.732168,
      lon: 5.10949613713,
    },
  },
  {
    column_4: 50.94677135,
    column_3: 5.0136688435307,
    column_2: "Assent",
    column_1: "3460",
    geom: null,
    coordonnees: {
      lat: 50.94677135,
      lon: 5.01366884353,
    },
  },
  {
    column_4: 50.9212106,
    column_3: 4.9541212,
    column_2: "Molenbeek-Wersbeek",
    column_1: "3461",
    geom: null,
    coordonnees: {
      lat: 50.9212106,
      lon: 4.9541212,
    },
  },
  {
    column_4: 50.8758246,
    column_3: 5.0360646174319,
    column_2: "Ransberg",
    column_1: "3470",
    geom: null,
    coordonnees: {
      lat: 50.8758246,
      lon: 5.03606461743,
    },
  },
  {
    column_4: 50.8930932577,
    column_3: 5.0065116045983,
    column_2: "Kersbeek-Miskom",
    column_1: "3472",
    geom: null,
    coordonnees: {
      lat: 50.8930932577,
      lon: 5.0065116046,
    },
  },
  {
    column_4: 50.8769276,
    column_3: 5.3512906396928,
    column_2: "Wimmertingen",
    column_1: "3501",
    geom: null,
    coordonnees: {
      lat: 50.8769276,
      lon: 5.35129063969,
    },
  },
  {
    column_4: 50.9490449,
    column_3: 5.2277944313068,
    column_2: "Spalbeek",
    column_1: "3510",
    geom: null,
    coordonnees: {
      lat: 50.9490449,
      lon: 5.22779443131,
    },
  },
  {
    column_4: 50.9439384,
    column_3: 5.3052007,
    column_2: "Kuringen",
    column_1: "3511",
    geom: null,
    coordonnees: {
      lat: 50.9439384,
      lon: 5.3052007,
    },
  },
  {
    column_4: 50.91698375,
    column_3: 5.2444291944072,
    column_2: "Stevoort",
    column_1: "3512",
    geom: null,
    coordonnees: {
      lat: 50.91698375,
      lon: 5.24442919441,
    },
  },
  {
    column_4: 51.0743268,
    column_3: 5.3844821648536,
    column_2: "Helchteren",
    column_1: "3530",
    geom: null,
    coordonnees: {
      lat: 51.0743268,
      lon: 5.38448216485,
    },
  },
  {
    column_4: 50.9384339,
    column_3: 5.1073913444455,
    column_2: "Halen",
    column_1: "3545",
    geom: null,
    coordonnees: {
      lat: 50.9384339,
      lon: 5.10739134445,
    },
  },
  {
    column_4: 50.980158,
    column_3: 5.1092670735921,
    column_2: "Zelem",
    column_1: "3545",
    geom: null,
    coordonnees: {
      lat: 50.980158,
      lon: 5.10926707359,
    },
  },
  {
    column_4: 51.04153335,
    column_3: 5.2835531292784,
    column_2: "Heusden",
    column_1: "3550",
    geom: null,
    coordonnees: {
      lat: 51.04153335,
      lon: 5.28355312928,
    },
  },
  {
    column_4: 51.0067479,
    column_3: 5.1472099707378,
    column_2: "Meldert",
    column_1: "3560",
    geom: null,
    coordonnees: {
      lat: 51.0067479,
      lon: 5.14720997074,
    },
  },
  {
    column_4: 51.04746375,
    column_3: 5.1616725572109,
    column_2: "Paal",
    column_1: "3583",
    geom: null,
    coordonnees: {
      lat: 51.04746375,
      lon: 5.16167255721,
    },
  },
  {
    column_4: 50.9078485,
    column_3: 5.4200307,
    column_2: "Diepenbeek",
    column_1: "3590",
    geom: null,
    coordonnees: {
      lat: 50.9078485,
      lon: 5.4200307,
    },
  },
  {
    column_4: 50.8892784,
    column_3: 5.6513208,
    column_2: "Lanaken",
    column_1: "3620",
    geom: null,
    coordonnees: {
      lat: 50.8892784,
      lon: 5.6513208,
    },
  },
  {
    column_4: 50.8655982,
    column_3: 5.6323869,
    column_2: "Veldwezelt",
    column_1: "3620",
    geom: null,
    coordonnees: {
      lat: 50.8655982,
      lon: 5.6323869,
    },
  },
  {
    column_4: 50.9284078,
    column_3: 5.6626094755366,
    column_2: "Rekem",
    column_1: "3621",
    geom: null,
    coordonnees: {
      lat: 50.9284078,
      lon: 5.66260947554,
    },
  },
  {
    column_4: 50.9863045,
    column_3: 5.7128515,
    column_2: "Eisden",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9863045,
      lon: 5.7128515,
    },
  },
  {
    column_4: 51.001413,
    column_3: 5.7468352,
    column_2: "Meeswijk",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 51.001413,
      lon: 5.7468352,
    },
  },
  {
    column_4: 50.9436011,
    column_3: 5.6810305,
    column_2: "Opgrimbie",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9436011,
      lon: 5.6810305,
    },
  },
  {
    column_4: 50.9770425,
    column_3: 5.7134261,
    column_2: "Vucht",
    column_1: "3630",
    geom: null,
    coordonnees: {
      lat: 50.9770425,
      lon: 5.7134261,
    },
  },
  {
    column_4: 51.06941595,
    column_3: 5.7404005925371,
    column_2: "Elen",
    column_1: "3650",
    geom: null,
    coordonnees: {
      lat: 51.06941595,
      lon: 5.74040059254,
    },
  },
  {
    column_4: 51.0005396,
    column_3: 5.5722025852462,
    column_2: "As",
    column_1: "3665",
    geom: null,
    coordonnees: {
      lat: 51.0005396,
      lon: 5.57220258525,
    },
  },
  {
    column_4: 51.0947181,
    column_3: 5.791733,
    column_2: "Maaseik",
    column_1: "3680",
    geom: null,
    coordonnees: {
      lat: 51.0947181,
      lon: 5.791733,
    },
  },
  {
    column_4: 51.05340005,
    column_3: 5.6487312543959,
    column_2: "Opoeteren",
    column_1: "3680",
    geom: null,
    coordonnees: {
      lat: 51.05340005,
      lon: 5.6487312544,
    },
  },
  {
    column_4: 50.7450193,
    column_3: 5.4843527,
    column_2: "Diets-Heur",
    column_1: "3700",
    geom: null,
    coordonnees: {
      lat: 50.7450193,
      lon: 5.4843527,
    },
  },
  {
    column_4: 50.8580527,
    column_3: 5.4593316,
    column_2: "Romershoven",
    column_1: "3730",
    geom: null,
    coordonnees: {
      lat: 50.8580527,
      lon: 5.4593316,
    },
  },
  {
    column_4: 50.8292918,
    column_3: 5.4487057,
    column_2: "Sint-Huibrechts-Hern",
    column_1: "3730",
    geom: null,
    coordonnees: {
      lat: 50.8292918,
      lon: 5.4487057,
    },
  },
  {
    column_4: 50.8342984,
    column_3: 5.5163495,
    column_2: "Rijkhoven",
    column_1: "3740",
    geom: null,
    coordonnees: {
      lat: 50.8342984,
      lon: 5.5163495,
    },
  },
  {
    column_4: 50.7871228,
    column_3: 5.5971013,
    column_2: "Val-Meer",
    column_1: "3770",
    geom: null,
    coordonnees: {
      lat: 50.7871228,
      lon: 5.5971013,
    },
  },
  {
    column_4: 50.7551858,
    column_3: 5.7148469912943,
    column_2: "Mouland",
    column_1: "3790",
    geom: null,
    coordonnees: {
      lat: 50.7551858,
      lon: 5.71484699129,
    },
  },
  {
    column_4: 50.7813901,
    column_3: 5.2127604,
    column_2: "Aalst",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.7813901,
      lon: 5.2127604,
    },
  },
  {
    column_4: 50.78320635,
    column_3: 5.2621170756458,
    column_2: "Groot-Gelmen",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.78320635,
      lon: 5.26211707565,
    },
  },
  {
    column_4: 50.80055285,
    column_3: 5.1499121468986,
    column_2: "Halmaal",
    column_1: "3800",
    geom: null,
    coordonnees: {
      lat: 50.80055285,
      lon: 5.1499121469,
    },
  },
  {
    column_4: 50.8380493,
    column_3: 5.1658988739064,
    column_2: "Gorsem",
    column_1: "3803",
    geom: null,
    coordonnees: {
      lat: 50.8380493,
      lon: 5.16589887391,
    },
  },
  {
    column_4: 50.8434693,
    column_3: 5.3421653176727,
    column_2: "Wellen",
    column_1: "3830",
    geom: null,
    coordonnees: {
      lat: 50.8434693,
      lon: 5.34216531767,
    },
  },
  {
    column_4: 50.7913472,
    column_3: 5.3972985,
    column_2: "Haren",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.7913472,
      lon: 5.3972985,
    },
  },
  {
    column_4: 50.797246,
    column_3: 5.3287966873717,
    column_2: "Hendrieken",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 50.797246,
      lon: 5.32879668737,
    },
  },
  {
    column_4: 51.1674467,
    column_3: 4.6797906,
    column_2: "Voort",
    column_1: "3840",
    geom: null,
    coordonnees: {
      lat: 51.1674467,
      lon: 4.6797906,
    },
  },
  {
    column_4: 50.750995,
    column_3: 5.3017509,
    column_2: "Heers",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.750995,
      lon: 5.3017509,
    },
  },
  {
    column_4: 50.7608875,
    column_3: 5.373733,
    column_2: "Vechmaal",
    column_1: "3870",
    geom: null,
    coordonnees: {
      lat: 50.7608875,
      lon: 5.373733,
    },
  },
  {
    column_4: 50.75022405,
    column_3: 5.1788950800586,
    column_2: "Buvingen",
    column_1: "3891",
    geom: null,
    coordonnees: {
      lat: 50.75022405,
      lon: 5.17889508006,
    },
  },
  {
    column_4: 50.7573342,
    column_3: 5.2141041746035,
    column_2: "Mielen-Boven-Aalst",
    column_1: "3891",
    geom: null,
    coordonnees: {
      lat: 50.7573342,
      lon: 5.2141041746,
    },
  },
  {
    column_4: 50.7614659,
    column_3: 5.1781361499823,
    column_2: "Muizen",
    column_1: "3891",
    geom: null,
    coordonnees: {
      lat: 50.7614659,
      lon: 5.17813614998,
    },
  },
  {
    column_4: 51.2113458,
    column_3: 5.422976,
    column_2: "Overpelt",
    column_1: "3900",
    geom: null,
    coordonnees: {
      lat: 51.2113458,
      lon: 5.422976,
    },
  },
  {
    column_4: 51.0966275,
    column_3: 5.1325674800534,
    column_2: "Kwaadmechelen",
    column_1: "3945",
    geom: null,
    coordonnees: {
      lat: 51.0966275,
      lon: 5.13256748005,
    },
  },
  {
    column_4: 51.1924818,
    column_3: 5.5995581946268,
    column_2: "Bocholt",
    column_1: "3950",
    geom: null,
    coordonnees: {
      lat: 51.1924818,
      lon: 5.59955819463,
    },
  },
  {
    column_4: 51.1470494,
    column_3: 5.5522997799873,
    column_2: "Reppel",
    column_1: "3950",
    geom: null,
    coordonnees: {
      lat: 51.1470494,
      lon: 5.55229977999,
    },
  },
  {
    column_4: 51.1184171,
    column_3: 5.6477954812084,
    column_2: "Opitter",
    column_1: "3960",
    geom: null,
    coordonnees: {
      lat: 51.1184171,
      lon: 5.64779548121,
    },
  },
  {
    column_4: 51.1420032,
    column_3: 5.5087098376886,
    column_2: "Grote-Brogel",
    column_1: "3990",
    geom: null,
    coordonnees: {
      lat: 51.1420032,
      lon: 5.50870983769,
    },
  },
  {
    column_4: 50.621845,
    column_3: 5.599573,
    column_2: "Grivegnee",
    column_1: "4030",
    geom: null,
    coordonnees: {
      lat: 50.621845,
      lon: 5.599573,
    },
  },
  {
    column_4: 50.5893639,
    column_3: 5.619201,
    column_2: "Embourg",
    column_1: "4053",
    geom: null,
    coordonnees: {
      lat: 50.5893639,
      lon: 5.619201,
    },
  },
  {
    column_4: 50.5445135,
    column_3: 5.4444346,
    column_2: "Ehein",
    column_1: "4120",
    geom: null,
    coordonnees: {
      lat: 50.5445135,
      lon: 5.4444346,
    },
  },
  {
    column_4: 50.53758625,
    column_3: 5.628000939321,
    column_2: "Dolembreux",
    column_1: "4140",
    geom: null,
    coordonnees: {
      lat: 50.53758625,
      lon: 5.62800093932,
    },
  },
  {
    column_4: 50.4893408,
    column_3: 5.6642552366155,
    column_2: "Rouvreux",
    column_1: "4140",
    geom: null,
    coordonnees: {
      lat: 50.4893408,
      lon: 5.66425523662,
    },
  },
  {
    column_4: 50.4969412,
    column_3: 5.5122974,
    column_2: "Villers-Aux-Tours",
    column_1: "4161",
    geom: null,
    coordonnees: {
      lat: 50.4969412,
      lon: 5.5122974,
    },
  },
  {
    column_4: 50.4960227,
    column_3: 5.4707571,
    column_2: "Tavier",
    column_1: "4163",
    geom: null,
    coordonnees: {
      lat: 50.4960227,
      lon: 5.4707571,
    },
  },
  {
    column_4: 50.443983,
    column_3: 5.602069,
    column_2: "Xhoris",
    column_1: "4190",
    geom: null,
    coordonnees: {
      lat: 50.443983,
      lon: 5.602069,
    },
  },
  {
    column_4: 50.5823574,
    column_3: 5.1270159,
    column_2: "Oteppe",
    column_1: "4210",
    geom: null,
    coordonnees: {
      lat: 50.5823574,
      lon: 5.1270159,
    },
  },
  {
    column_4: 50.6266779,
    column_3: 5.0305133,
    column_2: "Ambresin",
    column_1: "4219",
    geom: null,
    coordonnees: {
      lat: 50.6266779,
      lon: 5.0305133,
    },
  },
  {
    column_4: 50.608964,
    column_3: 5.016335,
    column_2: "Meeffe",
    column_1: "4219",
    geom: null,
    coordonnees: {
      lat: 50.608964,
      lon: 5.016335,
    },
  },
  {
    column_4: 50.6221762,
    column_3: 5.006384,
    column_2: "Wasseiges",
    column_1: "4219",
    geom: null,
    coordonnees: {
      lat: 50.6221762,
      lon: 5.006384,
    },
  },
  {
    column_4: 50.6554534,
    column_3: 5.1969427,
    column_2: "Omal",
    column_1: "4252",
    geom: null,
    coordonnees: {
      lat: 50.6554534,
      lon: 5.1969427,
    },
  },
  {
    column_4: 50.6171393,
    column_3: 5.0953487,
    column_2: "Ciplet",
    column_1: "4260",
    geom: null,
    coordonnees: {
      lat: 50.6171393,
      lon: 5.0953487,
    },
  },
  {
    column_4: 50.694616,
    column_3: 5.0789239,
    column_2: "Avernas-Le-Bauduin",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.694616,
      lon: 5.0789239,
    },
  },
  {
    column_4: 50.6875594,
    column_3: 5.0184357,
    column_2: "Petit-Hallet",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6875594,
      lon: 5.0184357,
    },
  },
  {
    column_4: 50.6777766,
    column_3: 5.1124332,
    column_2: "Poucet",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6777766,
      lon: 5.1124332,
    },
  },
  {
    column_4: 50.6878033,
    column_3: 5.1242082,
    column_2: "Trognée",
    column_1: "4280",
    geom: null,
    coordonnees: {
      lat: 50.6878033,
      lon: 5.1242082,
    },
  },
  {
    column_4: 50.711613,
    column_3: 5.0318166,
    column_2: "Lincent",
    column_1: "4287",
    geom: null,
    coordonnees: {
      lat: 50.711613,
      lon: 5.0318166,
    },
  },
  {
    column_4: 50.7099793,
    column_3: 5.235361,
    column_2: "Bettincourt",
    column_1: "4300",
    geom: null,
    coordonnees: {
      lat: 50.7099793,
      lon: 5.235361,
    },
  },
  {
    column_4: 50.632984,
    column_3: 5.245072,
    column_2: "Borlez",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.632984,
      lon: 5.245072,
    },
  },
  {
    column_4: 50.6538847,
    column_3: 5.2455346,
    column_2: "Celles",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.6538847,
      lon: 5.2455346,
    },
  },
  {
    column_4: 50.6402367,
    column_3: 5.2180365,
    column_2: "Les Waleffes",
    column_1: "4317",
    geom: null,
    coordonnees: {
      lat: 50.6402367,
      lon: 5.2180365,
    },
  },
  {
    column_4: 50.6669712,
    column_3: 5.4620018,
    column_2: "Awans",
    column_1: "4340",
    geom: null,
    coordonnees: {
      lat: 50.6669712,
      lon: 5.4620018,
    },
  },
  {
    column_4: 50.689076,
    column_3: 5.333978,
    column_2: "Lamine",
    column_1: "4350",
    geom: null,
    coordonnees: {
      lat: 50.689076,
      lon: 5.333978,
    },
  },
  {
    column_4: 50.6852325,
    column_3: 5.3730111,
    column_2: "Momalle",
    column_1: "4350",
    geom: null,
    coordonnees: {
      lat: 50.6852325,
      lon: 5.3730111,
    },
  },
  {
    column_4: 50.6960127,
    column_3: 5.3426579,
    column_2: "Hodeige",
    column_1: "4351",
    geom: null,
    coordonnees: {
      lat: 50.6960127,
      lon: 5.3426579,
    },
  },
  {
    column_4: 50.7220498,
    column_3: 5.3379451,
    column_2: "Grandville",
    column_1: "4360",
    geom: null,
    coordonnees: {
      lat: 50.7220498,
      lon: 5.3379451,
    },
  },
  {
    column_4: 50.7370788,
    column_3: 5.384458,
    column_2: "Otrange",
    column_1: "4360",
    geom: null,
    coordonnees: {
      lat: 50.7370788,
      lon: 5.384458,
    },
  },
  {
    column_4: 50.5822766,
    column_3: 5.4523491,
    column_2: "Ivoz-Ramet",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.5822766,
      lon: 5.4523491,
    },
  },
  {
    column_4: 50.6224546,
    column_3: 5.4600597,
    column_2: "Mons-Lez-Liège",
    column_1: "4400",
    geom: null,
    coordonnees: {
      lat: 50.6224546,
      lon: 5.4600597,
    },
  },
  {
    column_4: 50.6624326,
    column_3: 5.5190612,
    column_2: "Ans",
    column_1: "4430",
    geom: null,
    coordonnees: {
      lat: 50.6624326,
      lon: 5.5190612,
    },
  },
  {
    column_4: 50.6742549,
    column_3: 5.5129926,
    column_2: "Alleur",
    column_1: "4432",
    geom: null,
    coordonnees: {
      lat: 50.6742549,
      lon: 5.5129926,
    },
  },
  {
    column_4: 50.6385937,
    column_3: 5.4757847,
    column_2: "Hollogne-Aux-Pierres",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.6385937,
      lon: 5.4757847,
    },
  },
  {
    column_4: 50.6436521,
    column_3: 5.4254194,
    column_2: "Velroux",
    column_1: "4460",
    geom: null,
    coordonnees: {
      lat: 50.6436521,
      lon: 5.4254194,
    },
  },
  {
    column_4: 50.5512026,
    column_3: 5.2358517,
    column_2: "Antheit",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.5512026,
      lon: 5.2358517,
    },
  },
  {
    column_4: 50.5627464,
    column_3: 5.2261382,
    column_2: "Vinalmont",
    column_1: "4520",
    geom: null,
    coordonnees: {
      lat: 50.5627464,
      lon: 5.2261382,
    },
  },
  {
    column_4: 50.5497761,
    column_3: 5.3240992,
    column_2: "Amay",
    column_1: "4540",
    geom: null,
    coordonnees: {
      lat: 50.5497761,
      lon: 5.3240992,
    },
  },
  {
    column_4: 50.5290054,
    column_3: 5.4130878,
    column_2: "Saint-Séverin",
    column_1: "4550",
    geom: null,
    coordonnees: {
      lat: 50.5290054,
      lon: 5.4130878,
    },
  },
  {
    column_4: 50.4154227,
    column_3: 5.299356,
    column_2: "Les Avins",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.4154227,
      lon: 5.299356,
    },
  },
  {
    column_4: 50.4229708,
    column_3: 5.2579288,
    column_2: "Pailhe",
    column_1: "4560",
    geom: null,
    coordonnees: {
      lat: 50.4229708,
      lon: 5.2579288,
    },
  },
  {
    column_4: 50.4802051,
    column_3: 5.2263372,
    column_2: "Marchin",
    column_1: "4570",
    geom: null,
    coordonnees: {
      lat: 50.4802051,
      lon: 5.2263372,
    },
  },
  {
    column_4: 50.7423427,
    column_3: 5.731253,
    column_2: "Berneau",
    column_1: "4607",
    geom: null,
    coordonnees: {
      lat: 50.7423427,
      lon: 5.731253,
    },
  },
  {
    column_4: 50.7195828,
    column_3: 5.7764464,
    column_2: "Neufchâteau",
    column_1: "4608",
    geom: null,
    coordonnees: {
      lat: 50.7195828,
      lon: 5.7764464,
    },
  },
  {
    column_4: 50.73466495,
    column_3: 5.773045637996,
    column_2: "Warsage",
    column_1: "4608",
    geom: null,
    coordonnees: {
      lat: 50.73466495,
      lon: 5.773045638,
    },
  },
  {
    column_4: 50.6447087,
    column_3: 5.6654685,
    column_2: "Bellaire",
    column_1: "4610",
    geom: null,
    coordonnees: {
      lat: 50.6447087,
      lon: 5.6654685,
    },
  },
  {
    column_4: 50.6302993,
    column_3: 5.6978166,
    column_2: "Retinne",
    column_1: "4621",
    geom: null,
    coordonnees: {
      lat: 50.6302993,
      lon: 5.6978166,
    },
  },
  {
    column_4: 50.6028765,
    column_3: 5.6811009,
    column_2: "Magnée",
    column_1: "4623",
    geom: null,
    coordonnees: {
      lat: 50.6028765,
      lon: 5.6811009,
    },
  },
  {
    column_4: 50.6087645,
    column_3: 5.7147473,
    column_2: "Ayeneux",
    column_1: "4630",
    geom: null,
    coordonnees: {
      lat: 50.6087645,
      lon: 5.7147473,
    },
  },
  {
    column_4: 50.6508837,
    column_3: 5.7262388,
    column_2: "Cérexhe-Heuseux",
    column_1: "4632",
    geom: null,
    coordonnees: {
      lat: 50.6508837,
      lon: 5.7262388,
    },
  },
  {
    column_4: 50.669117,
    column_3: 5.8048204,
    column_2: "Charneux",
    column_1: "4654",
    geom: null,
    coordonnees: {
      lat: 50.669117,
      lon: 5.8048204,
    },
  },
  {
    column_4: 50.6525478,
    column_3: 5.6820303,
    column_2: "Saive",
    column_1: "4671",
    geom: null,
    coordonnees: {
      lat: 50.6525478,
      lon: 5.6820303,
    },
  },
  {
    column_4: 50.710544,
    column_3: 5.6808711,
    column_2: "Hermalle-Sous-Argenteau",
    column_1: "4681",
    geom: null,
    coordonnees: {
      lat: 50.710544,
      lon: 5.6808711,
    },
  },
  {
    column_4: 50.7339784,
    column_3: 5.6667674,
    column_2: "Haccourt",
    column_1: "4684",
    geom: null,
    coordonnees: {
      lat: 50.7339784,
      lon: 5.6667674,
    },
  },
  {
    column_4: 50.7583596,
    column_3: 5.6094772,
    column_2: "Bassenge",
    column_1: "4690",
    geom: null,
    coordonnees: {
      lat: 50.7583596,
      lon: 5.6094772,
    },
  },
  {
    column_4: 50.6306134,
    column_3: 6.031452,
    column_2: "Eupen",
    column_1: "4700",
    geom: null,
    coordonnees: {
      lat: 50.6306134,
      lon: 6.031452,
    },
  },
  {
    column_4: 50.3543055,
    column_3: 6.1705559,
    column_2: "Amblève",
    column_1: "4770",
    geom: null,
    coordonnees: {
      lat: 50.3543055,
      lon: 6.1705559,
    },
  },
  {
    column_4: 50.2803881,
    column_3: 6.1258953,
    column_2: "Saint-Vith",
    column_1: "4780",
    geom: null,
    coordonnees: {
      lat: 50.2803881,
      lon: 6.1258953,
    },
  },
  {
    column_4: 50.2888095,
    column_3: 6.2642498,
    column_2: "Schoenberg",
    column_1: "4782",
    geom: null,
    coordonnees: {
      lat: 50.2888095,
      lon: 6.2642498,
    },
  },
  {
    column_4: 50.582172,
    column_3: 5.843182,
    column_2: "Ensival",
    column_1: "4800",
    geom: null,
    coordonnees: {
      lat: 50.582172,
      lon: 5.843182,
    },
  },
  {
    column_4: 50.6143044,
    column_3: 5.8341784,
    column_2: "Petit-Rechain",
    column_1: "4800",
    geom: null,
    coordonnees: {
      lat: 50.6143044,
      lon: 5.8341784,
    },
  },
  {
    column_4: 50.542907,
    column_3: 5.873929354985,
    column_2: "Polleur",
    column_1: "4800",
    geom: null,
    coordonnees: {
      lat: 50.542907,
      lon: 5.87392935499,
    },
  },
  {
    column_4: 50.6122761,
    column_3: 5.9402987,
    column_2: "Limbourg",
    column_1: "4830",
    geom: null,
    coordonnees: {
      lat: 50.6122761,
      lon: 5.9402987,
    },
  },
  {
    column_4: 50.7509012,
    column_3: 5.9336285,
    column_2: "Sippenaeken",
    column_1: "4851",
    geom: null,
    coordonnees: {
      lat: 50.7509012,
      lon: 5.9336285,
    },
  },
  {
    column_4: 50.723243,
    column_3: 5.9207511,
    column_2: "Hombourg",
    column_1: "4852",
    geom: null,
    coordonnees: {
      lat: 50.723243,
      lon: 5.9207511,
    },
  },
  {
    column_4: 50.6589677,
    column_3: 5.883668,
    column_2: "Clermont",
    column_1: "4890",
    geom: null,
    coordonnees: {
      lat: 50.6589677,
      lon: 5.883668,
    },
  },
  {
    column_4: 50.4734827,
    column_3: 5.6742167,
    column_2: "Aywaille",
    column_1: "4920",
    geom: null,
    coordonnees: {
      lat: 50.4734827,
      lon: 5.6742167,
    },
  },
  {
    column_4: 50.5248417,
    column_3: 5.7172477995985,
    column_2: "Louveigné",
    column_1: "4920",
    geom: null,
    coordonnees: {
      lat: 50.5248417,
      lon: 5.7172477996,
    },
  },
  {
    column_4: 50.4550294,
    column_3: 6.1224661,
    column_2: "Robertville",
    column_1: "4950",
    geom: null,
    coordonnees: {
      lat: 50.4550294,
      lon: 6.1224661,
    },
  },
  {
    column_4: 50.4239904,
    column_3: 6.0266001,
    column_2: "Malmedy",
    column_1: "4960",
    geom: null,
    coordonnees: {
      lat: 50.4239904,
      lon: 6.0266001,
    },
  },
  {
    column_4: 50.3012606,
    column_3: 5.8353999,
    column_2: "Arbrefontaine",
    column_1: "4990",
    geom: null,
    coordonnees: {
      lat: 50.3012606,
      lon: 5.8353999,
    },
  },
  {
    column_4: 50.4864453,
    column_3: 4.9615056,
    column_2: "Marche-Les-Dames",
    column_1: "5024",
    geom: null,
    coordonnees: {
      lat: 50.4864453,
      lon: 4.9615056,
    },
  },
  {
    column_4: 50.5329777,
    column_3: 4.7481633,
    column_2: "Beuzet",
    column_1: "5030",
    geom: null,
    coordonnees: {
      lat: 50.5329777,
      lon: 4.7481633,
    },
  },
  {
    column_4: 50.4378354,
    column_3: 4.5885625,
    column_2: "Moignelée",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4378354,
      lon: 4.5885625,
    },
  },
  {
    column_4: 50.4713313,
    column_3: 4.60725,
    column_2: "Velaine-Sur-Sambre",
    column_1: "5060",
    geom: null,
    coordonnees: {
      lat: 50.4713313,
      lon: 4.60725,
    },
  },
  {
    column_4: 50.3885408,
    column_3: 4.6237797,
    column_2: "Le Roux",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.3885408,
      lon: 4.6237797,
    },
  },
  {
    column_4: 50.3760693,
    column_3: 4.5998195,
    column_2: "Sart-Eustache",
    column_1: "5070",
    geom: null,
    coordonnees: {
      lat: 50.3760693,
      lon: 4.5998195,
    },
  },
  {
    column_4: 50.3773499,
    column_3: 4.7748094,
    column_2: "Lesve",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.3773499,
      lon: 4.7748094,
    },
  },
  {
    column_4: 50.379305,
    column_3: 4.8965971,
    column_2: "Lustin",
    column_1: "5170",
    geom: null,
    coordonnees: {
      lat: 50.379305,
      lon: 4.8965971,
    },
  },
  {
    column_4: 50.44449,
    column_3: 4.6734915,
    column_2: "Ham-Sur-Sambre",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.44449,
      lon: 4.6734915,
    },
  },
  {
    column_4: 50.4555129,
    column_3: 4.7043344,
    column_2: "Mornimont",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.4555129,
      lon: 4.7043344,
    },
  },
  {
    column_4: 50.4811696,
    column_3: 4.7027047,
    column_2: "Spy",
    column_1: "5190",
    geom: null,
    coordonnees: {
      lat: 50.4811696,
      lon: 4.7027047,
    },
  },
  {
    column_4: 50.4636595,
    column_3: 5.1169603,
    column_2: "Coutisse",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4636595,
      lon: 5.1169603,
    },
  },
  {
    column_4: 50.4598458,
    column_3: 4.978173,
    column_2: "Maizeret",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4598458,
      lon: 4.978173,
    },
  },
  {
    column_4: 50.4712487,
    column_3: 4.9947316,
    column_2: "Namêche",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4712487,
      lon: 4.9947316,
    },
  },
  {
    column_4: 50.4641615,
    column_3: 5.0133577,
    column_2: "Thon",
    column_1: "5300",
    geom: null,
    coordonnees: {
      lat: 50.4641615,
      lon: 5.0133577,
    },
  },
  {
    column_4: 50.623868,
    column_3: 4.957465,
    column_2: "Boneffe",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.623868,
      lon: 4.957465,
    },
  },
  {
    column_4: 50.5756144,
    column_3: 4.8653991,
    column_2: "Upigny",
    column_1: "5310",
    geom: null,
    coordonnees: {
      lat: 50.5756144,
      lon: 4.8653991,
    },
  },
  {
    column_4: 50.3704203,
    column_3: 5.0233625,
    column_2: "Assesse",
    column_1: "5330",
    geom: null,
    coordonnees: {
      lat: 50.3704203,
      lon: 5.0233625,
    },
  },
  {
    column_4: 50.4416402,
    column_3: 4.9846704,
    column_2: "Mozet",
    column_1: "5340",
    geom: null,
    coordonnees: {
      lat: 50.4416402,
      lon: 4.9846704,
    },
  },
  {
    column_4: 50.4135772,
    column_3: 5.171436,
    column_2: "Evelette",
    column_1: "5350",
    geom: null,
    coordonnees: {
      lat: 50.4135772,
      lon: 5.171436,
    },
  },
  {
    column_4: 50.440101,
    column_3: 5.2217845,
    column_2: "Goesnes",
    column_1: "5353",
    geom: null,
    coordonnees: {
      lat: 50.440101,
      lon: 5.2217845,
    },
  },
  {
    column_4: 50.4432621,
    column_3: 5.1821601,
    column_2: "Jallet",
    column_1: "5354",
    geom: null,
    coordonnees: {
      lat: 50.4432621,
      lon: 5.1821601,
    },
  },
  {
    column_4: 50.3334777,
    column_3: 5.1751963,
    column_2: "Achet",
    column_1: "5362",
    geom: null,
    coordonnees: {
      lat: 50.3334777,
      lon: 5.1751963,
    },
  },
  {
    column_4: 50.3253905,
    column_3: 5.1212547,
    column_2: "Emptinne",
    column_1: "5363",
    geom: null,
    coordonnees: {
      lat: 50.3253905,
      lon: 5.1212547,
    },
  },
  {
    column_4: 50.3563281,
    column_3: 5.2451952,
    column_2: "Jeneffe",
    column_1: "5370",
    geom: null,
    coordonnees: {
      lat: 50.3563281,
      lon: 5.2451952,
    },
  },
  {
    column_4: 50.3619241,
    column_3: 5.333856,
    column_2: "Méan",
    column_1: "5372",
    geom: null,
    coordonnees: {
      lat: 50.3619241,
      lon: 5.333856,
    },
  },
  {
    column_4: 50.275734,
    column_3: 5.2623625,
    column_2: "Sinsin",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.275734,
      lon: 5.2623625,
    },
  },
  {
    column_4: 50.336585,
    column_3: 5.3669021,
    column_2: "Somme-Leuze",
    column_1: "5377",
    geom: null,
    coordonnees: {
      lat: 50.336585,
      lon: 5.3669021,
    },
  },
  {
    column_4: 50.544287,
    column_3: 4.946342,
    column_2: "Tillier",
    column_1: "5380",
    geom: null,
    coordonnees: {
      lat: 50.544287,
      lon: 4.946342,
    },
  },
  {
    column_4: 50.2725677,
    column_3: 4.8984129,
    column_2: "Bouvignes-Sur-Meuse",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.2725677,
      lon: 4.8984129,
    },
  },
  {
    column_4: 50.2608166,
    column_3: 4.91242,
    column_2: "Dinant",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.2608166,
      lon: 4.91242,
    },
  },
  {
    column_4: 50.223335,
    column_3: 4.9584822,
    column_2: "Furfooz",
    column_1: "5500",
    geom: null,
    coordonnees: {
      lat: 50.223335,
      lon: 4.9584822,
    },
  },
  {
    column_4: 50.2814293,
    column_3: 4.9715412,
    column_2: "Lisogne",
    column_1: "5501",
    geom: null,
    coordonnees: {
      lat: 50.2814293,
      lon: 4.9715412,
    },
  },
  {
    column_4: 50.2395304,
    column_3: 4.7604034,
    column_2: "Anthée",
    column_1: "5520",
    geom: null,
    coordonnees: {
      lat: 50.2395304,
      lon: 4.7604034,
    },
  },
  {
    column_4: 50.2500754,
    column_3: 4.7806526,
    column_2: "Serville",
    column_1: "5521",
    geom: null,
    coordonnees: {
      lat: 50.2500754,
      lon: 4.7806526,
    },
  },
  {
    column_4: 50.2782156,
    column_3: 4.793357,
    column_2: "Falaen",
    column_1: "5522",
    geom: null,
    coordonnees: {
      lat: 50.2782156,
      lon: 4.793357,
    },
  },
  {
    column_4: 50.3505281,
    column_3: 4.875987,
    column_2: "Godinne",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3505281,
      lon: 4.875987,
    },
  },
  {
    column_4: 50.3142896,
    column_3: 4.9455473,
    column_2: "Purnode",
    column_1: "5530",
    geom: null,
    coordonnees: {
      lat: 50.3142896,
      lon: 4.9455473,
    },
  },
  {
    column_4: 49.8421207,
    column_3: 4.9726784,
    column_2: "Alle",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8421207,
      lon: 4.9726784,
    },
  },
  {
    column_4: 49.864219,
    column_3: 4.8860106,
    column_2: "Bohan",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.864219,
      lon: 4.8860106,
    },
  },
  {
    column_4: 49.8639125,
    column_3: 4.9294849,
    column_2: "Laforet",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8639125,
      lon: 4.9294849,
    },
  },
  {
    column_4: 49.8729769,
    column_3: 4.9340633,
    column_2: "Vresse-Sur-Semois",
    column_1: "5550",
    geom: null,
    coordonnees: {
      lat: 49.8729769,
      lon: 4.9340633,
    },
  },
  {
    column_4: 49.869524,
    column_3: 4.9830915,
    column_2: "Gros-Fays",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.869524,
      lon: 4.9830915,
    },
  },
  {
    column_4: 49.8934868,
    column_3: 5.0089274,
    column_2: "Oizy",
    column_1: "5555",
    geom: null,
    coordonnees: {
      lat: 49.8934868,
      lon: 5.0089274,
    },
  },
  {
    column_4: 50.1581671,
    column_3: 4.9452635,
    column_2: "Finnevaux",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.1581671,
      lon: 4.9452635,
    },
  },
  {
    column_4: 50.1671763,
    column_3: 4.8854633,
    column_2: "Mesnil-Saint-Blaise",
    column_1: "5560",
    geom: null,
    coordonnees: {
      lat: 50.1671763,
      lon: 4.8854633,
    },
  },
  {
    column_4: 50.1262206,
    column_3: 4.9468551,
    column_2: "Baronville",
    column_1: "5570",
    geom: null,
    coordonnees: {
      lat: 50.1262206,
      lon: 4.9468551,
    },
  },
  {
    column_4: 50.0221032,
    column_3: 4.871198,
    column_2: "Bourseigne-Vieille",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 50.0221032,
      lon: 4.871198,
    },
  },
  {
    column_4: 49.9589147,
    column_3: 4.9572488,
    column_2: "Louette-Saint-Denis",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.9589147,
      lon: 4.9572488,
    },
  },
  {
    column_4: 49.960145,
    column_3: 4.9276767,
    column_2: "Louette-Saint-Pierre",
    column_1: "5575",
    geom: null,
    coordonnees: {
      lat: 49.960145,
      lon: 4.9276767,
    },
  },
  {
    column_4: 50.2172183,
    column_3: 5.1958839,
    column_2: "Buissonville",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.2172183,
      lon: 5.1958839,
    },
  },
  {
    column_4: 50.13800485,
    column_3: 5.1458087816947,
    column_2: "Lessive",
    column_1: "5580",
    geom: null,
    coordonnees: {
      lat: 50.13800485,
      lon: 5.14580878169,
    },
  },
  {
    column_4: 50.2668644,
    column_3: 5.0458772,
    column_2: "Achêne",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2668644,
      lon: 5.0458772,
    },
  },
  {
    column_4: 50.2496153,
    column_3: 5.0603857,
    column_2: "Conneux",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2496153,
      lon: 5.0603857,
    },
  },
  {
    column_4: 50.2531051,
    column_3: 5.208871,
    column_2: "Haversin",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2531051,
      lon: 5.208871,
    },
  },
  {
    column_4: 50.2319269,
    column_3: 5.2343634,
    column_2: "Serinchamps",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.2319269,
      lon: 5.2343634,
    },
  },
  {
    column_4: 50.296303,
    column_3: 5.0349178,
    column_2: "Sovet",
    column_1: "5590",
    geom: null,
    coordonnees: {
      lat: 50.296303,
      lon: 5.0349178,
    },
  },
  {
    column_4: 50.195588,
    column_3: 4.5449033,
    column_2: "Philippeville",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.195588,
      lon: 4.5449033,
    },
  },
  {
    column_4: 50.1724056,
    column_3: 4.6979645,
    column_2: "Romedenne",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1724056,
      lon: 4.6979645,
    },
  },
  {
    column_4: 50.1824646,
    column_3: 4.6962604,
    column_2: "Surice",
    column_1: "5600",
    geom: null,
    coordonnees: {
      lat: 50.1824646,
      lon: 4.6962604,
    },
  },
  {
    column_4: 50.2282738,
    column_3: 4.5631279,
    column_2: "Hemptinne-Lez-Florennes",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2282738,
      lon: 4.5631279,
    },
  },
  {
    column_4: 50.2469253,
    column_3: 4.5778492,
    column_2: "Saint-Aubin",
    column_1: "5620",
    geom: null,
    coordonnees: {
      lat: 50.2469253,
      lon: 4.5778492,
    },
  },
  {
    column_4: 50.2251302,
    column_3: 4.4103561,
    column_2: "Silenrieux",
    column_1: "5630",
    geom: null,
    coordonnees: {
      lat: 50.2251302,
      lon: 4.4103561,
    },
  },
  {
    column_4: 50.2472813,
    column_3: 4.3528279,
    column_2: "Castillon",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2472813,
      lon: 4.3528279,
    },
  },
  {
    column_4: 50.2598853,
    column_3: 4.3173399,
    column_2: "Clermont",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2598853,
      lon: 4.3173399,
    },
  },
  {
    column_4: 50.2490122,
    column_3: 4.3827342,
    column_2: "Fontenelle",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2490122,
      lon: 4.3827342,
    },
  },
  {
    column_4: 50.2398955,
    column_3: 4.4523592,
    column_2: "Vogenée",
    column_1: "5650",
    geom: null,
    coordonnees: {
      lat: 50.2398955,
      lon: 4.4523592,
    },
  },
  {
    column_4: 50.2899588,
    column_3: 4.456597,
    column_2: "Gourdinne",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2899588,
      lon: 4.456597,
    },
  },
  {
    column_4: 50.2951613,
    column_3: 4.4829777,
    column_2: "Somzée",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2951613,
      lon: 4.4829777,
    },
  },
  {
    column_4: 50.2827004,
    column_3: 4.4278716,
    column_2: "Thy-Le-Château",
    column_1: "5651",
    geom: null,
    coordonnees: {
      lat: 50.2827004,
      lon: 4.4278716,
    },
  },
  {
    column_4: 50.0672297,
    column_3: 4.4090395,
    column_2: "Aublain",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0672297,
      lon: 4.4090395,
    },
  },
  {
    column_4: 49.9695906,
    column_3: 4.5296813,
    column_2: "Brûly",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 49.9695906,
      lon: 4.5296813,
    },
  },
  {
    column_4: 50.0796144,
    column_3: 4.5105493,
    column_2: "Frasnes",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0796144,
      lon: 4.5105493,
    },
  },
  {
    column_4: 50.0374018,
    column_3: 4.4236449,
    column_2: "Gonrieux",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0374018,
      lon: 4.4236449,
    },
  },
  {
    column_4: 50.0431267,
    column_3: 4.4584819,
    column_2: "Pesche",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0431267,
      lon: 4.4584819,
    },
  },
  {
    column_4: 50.0583821,
    column_3: 4.5324267,
    column_2: "Petigny",
    column_1: "5660",
    geom: null,
    coordonnees: {
      lat: 50.0583821,
      lon: 4.5324267,
    },
  },
  {
    column_4: 50.1008805,
    column_3: 4.6964875,
    column_2: "Mazée",
    column_1: "5670",
    geom: null,
    coordonnees: {
      lat: 50.1008805,
      lon: 4.6964875,
    },
  },
  {
    column_4: 50.1873423,
    column_3: 4.7367955,
    column_2: "Soulme",
    column_1: "5680",
    geom: null,
    coordonnees: {
      lat: 50.1873423,
      lon: 4.7367955,
    },
  },
  {
    column_4: 50.4186181,
    column_3: 4.4322464,
    column_2: "Dampremy",
    column_1: "6020",
    geom: null,
    coordonnees: {
      lat: 50.4186181,
      lon: 4.4322464,
    },
  },
  {
    column_4: 50.3902046,
    column_3: 4.4046365,
    column_2: "Mont-Sur-Marchienne",
    column_1: "6032",
    geom: null,
    coordonnees: {
      lat: 50.3902046,
      lon: 4.4046365,
    },
  },
  {
    column_4: 50.377822,
    column_3: 4.3496404,
    column_2: "Landelies",
    column_1: "6111",
    geom: null,
    coordonnees: {
      lat: 50.377822,
      lon: 4.3496404,
    },
  },
  {
    column_4: 50.3528702,
    column_3: 4.4127635,
    column_2: "Jamioulx",
    column_1: "6120",
    geom: null,
    coordonnees: {
      lat: 50.3528702,
      lon: 4.4127635,
    },
  },
  {
    column_4: 50.390213,
    column_3: 4.5151961,
    column_2: "Bouffioulx",
    column_1: "6200",
    geom: null,
    coordonnees: {
      lat: 50.390213,
      lon: 4.5151961,
    },
  },
  {
    column_4: 50.4046075,
    column_3: 4.5243511,
    column_2: "Châtelet",
    column_1: "6200",
    geom: null,
    coordonnees: {
      lat: 50.4046075,
      lon: 4.5243511,
    },
  },
  {
    column_4: 50.4155938,
    column_3: 4.5179328,
    column_2: "Châtelineau",
    column_1: "6200",
    geom: null,
    coordonnees: {
      lat: 50.4155938,
      lon: 4.5179328,
    },
  },
  {
    column_4: 50.47194905,
    column_3: 4.5446545325944,
    column_2: "Fleurus",
    column_1: "6220",
    geom: null,
    coordonnees: {
      lat: 50.47194905,
      lon: 4.54465453259,
    },
  },
  {
    column_4: 50.45472595,
    column_3: 4.5579198971642,
    column_2: "Lambusart",
    column_1: "6220",
    geom: null,
    coordonnees: {
      lat: 50.45472595,
      lon: 4.55791989716,
    },
  },
  {
    column_4: 50.5111679,
    column_3: 4.5319238043004,
    column_2: "Saint-Amand",
    column_1: "6221",
    geom: null,
    coordonnees: {
      lat: 50.5111679,
      lon: 4.5319238043,
    },
  },
  {
    column_4: 50.47584335,
    column_3: 4.5816274059151,
    column_2: "Wanfercée-Baulet",
    column_1: "6224",
    geom: null,
    coordonnees: {
      lat: 50.47584335,
      lon: 4.58162740592,
    },
  },
  {
    column_4: 50.4842821,
    column_3: 4.4306784623925,
    column_2: "Thiméon",
    column_1: "6230",
    geom: null,
    coordonnees: {
      lat: 50.4842821,
      lon: 4.43067846239,
    },
  },
  {
    column_4: 50.3588065,
    column_3: 4.5323568,
    column_2: "Acoz",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.3588065,
      lon: 4.5323568,
    },
  },
  {
    column_4: 50.3544437,
    column_3: 4.5771625,
    column_2: "Gougnies",
    column_1: "6280",
    geom: null,
    coordonnees: {
      lat: 50.3544437,
      lon: 4.5771625,
    },
  },
  {
    column_4: 50.2262753,
    column_3: 4.3767743,
    column_2: "Boussu-Lez-Walcourt",
    column_1: "6440",
    geom: null,
    coordonnees: {
      lat: 50.2262753,
      lon: 4.3767743,
    },
  },
  {
    column_4: 50.1511597,
    column_3: 4.3269129,
    column_2: "Froidchapelle",
    column_1: "6440",
    geom: null,
    coordonnees: {
      lat: 50.1511597,
      lon: 4.3269129,
    },
  },
  {
    column_4: 50.061113,
    column_3: 4.363579,
    column_2: "Vaulx-Lez-Chimay",
    column_1: "6462",
    geom: null,
    coordonnees: {
      lat: 50.061113,
      lon: 4.363579,
    },
  },
  {
    column_4: 50.0642146,
    column_3: 4.3790041,
    column_2: "Lompret",
    column_1: "6463",
    geom: null,
    coordonnees: {
      lat: 50.0642146,
      lon: 4.3790041,
    },
  },
  {
    column_4: 50.1344457,
    column_3: 4.224365,
    column_2: "Montbliart",
    column_1: "6470",
    geom: null,
    coordonnees: {
      lat: 50.1344457,
      lon: 4.224365,
    },
  },
  {
    column_4: 50.2766332,
    column_3: 4.2980462,
    column_2: "Strée",
    column_1: "6511",
    geom: null,
    coordonnees: {
      lat: 50.2766332,
      lon: 4.2980462,
    },
  },
  {
    column_4: 50.3041589,
    column_3: 4.244767,
    column_2: "Leers-Et-Fosteau",
    column_1: "6530",
    geom: null,
    coordonnees: {
      lat: 50.3041589,
      lon: 4.244767,
    },
  },
  {
    column_4: 50.3512087,
    column_3: 4.2155807,
    column_2: "Bienne-Lez-Happart",
    column_1: "6543",
    geom: null,
    coordonnees: {
      lat: 50.3512087,
      lon: 4.2155807,
    },
  },
  {
    column_4: 50.2631919,
    column_3: 4.1520931,
    column_2: "Bersillies-L'abbaye",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.2631919,
      lon: 4.1520931,
    },
  },
  {
    column_4: 50.3287642,
    column_3: 4.0705909,
    column_2: "Grand-Reng",
    column_1: "6560",
    geom: null,
    coordonnees: {
      lat: 50.3287642,
      lon: 4.0705909,
    },
  },
  {
    column_4: 50.036452,
    column_3: 4.224118,
    column_2: "Monceau-Imbrechies",
    column_1: "6592",
    geom: null,
    coordonnees: {
      lat: 50.036452,
      lon: 4.224118,
    },
  },
  {
    column_4: 50.0651684,
    column_3: 5.7616864,
    column_2: "Noville",
    column_1: "6600",
    geom: null,
    coordonnees: {
      lat: 50.0651684,
      lon: 5.7616864,
    },
  },
  {
    column_4: 49.9133632,
    column_3: 5.5705339,
    column_2: "Vaux-Lez-Rosières",
    column_1: "6640",
    geom: null,
    coordonnees: {
      lat: 49.9133632,
      lon: 5.5705339,
    },
  },
  {
    column_4: 50.5341607,
    column_3: 5.7909538,
    column_2: "Mont",
    column_1: "6661",
    geom: null,
    coordonnees: {
      lat: 50.5341607,
      lon: 5.7909538,
    },
  },
  {
    column_4: 50.2272012,
    column_3: 5.7447558,
    column_2: "Tailles",
    column_1: "6661",
    geom: null,
    coordonnees: {
      lat: 50.2272012,
      lon: 5.7447558,
    },
  },
  {
    column_4: 50.1661182,
    column_3: 5.7156009,
    column_2: "Wibrin",
    column_1: "6666",
    geom: null,
    coordonnees: {
      lat: 50.1661182,
      lon: 5.7156009,
    },
  },
  {
    column_4: 50.1608553,
    column_3: 5.9393768987183,
    column_2: "Limerlé",
    column_1: "6670",
    geom: null,
    coordonnees: {
      lat: 50.1608553,
      lon: 5.93937689872,
    },
  },
  {
    column_4: 50.2244199,
    column_3: 5.9182421,
    column_2: "Bovigny",
    column_1: "6671",
    geom: null,
    coordonnees: {
      lat: 50.2244199,
      lon: 5.9182421,
    },
  },
  {
    column_4: 50.220365,
    column_3: 5.9972692,
    column_2: "Beho",
    column_1: "6672",
    geom: null,
    coordonnees: {
      lat: 50.220365,
      lon: 5.9972692,
    },
  },
  {
    column_4: 50.1876021,
    column_3: 5.8288862,
    column_2: "Montleban",
    column_1: "6674",
    geom: null,
    coordonnees: {
      lat: 50.1876021,
      lon: 5.8288862,
    },
  },
  {
    column_4: 50.0333519,
    column_3: 5.6038758,
    column_2: "Flamierge",
    column_1: "6686",
    geom: null,
    coordonnees: {
      lat: 50.0333519,
      lon: 5.6038758,
    },
  },
  {
    column_4: 50.0842908,
    column_3: 5.6675577,
    column_2: "Bertogne",
    column_1: "6687",
    geom: null,
    coordonnees: {
      lat: 50.0842908,
      lon: 5.6675577,
    },
  },
  {
    column_4: 49.6827625,
    column_3: 5.811919,
    column_2: "Arlon",
    column_1: "6700",
    geom: null,
    coordonnees: {
      lat: 49.6827625,
      lon: 5.811919,
    },
  },
  {
    column_4: 49.73558035,
    column_3: 5.5932514325174,
    column_2: "Habay",
    column_1: "6720",
    geom: null,
    coordonnees: {
      lat: 49.73558035,
      lon: 5.59325143252,
    },
  },
  {
    column_4: 49.7228424,
    column_3: 5.6225107,
    column_2: "Habay-La-Vieille",
    column_1: "6723",
    geom: null,
    coordonnees: {
      lat: 49.7228424,
      lon: 5.6225107,
    },
  },
  {
    column_4: 49.7181041,
    column_3: 5.558116,
    column_2: "Rulles",
    column_1: "6724",
    geom: null,
    coordonnees: {
      lat: 49.7181041,
      lon: 5.558116,
    },
  },
  {
    column_4: 49.6729527,
    column_3: 5.5982415,
    column_2: "Etalle",
    column_1: "6740",
    geom: null,
    coordonnees: {
      lat: 49.6729527,
      lon: 5.5982415,
    },
  },
  {
    column_4: 49.5262083,
    column_3: 5.4795124,
    column_2: "Lamorteau",
    column_1: "6767",
    geom: null,
    coordonnees: {
      lat: 49.5262083,
      lon: 5.4795124,
    },
  },
  {
    column_4: 49.539075,
    column_3: 5.48971,
    column_2: "Rouvroy",
    column_1: "6767",
    geom: null,
    coordonnees: {
      lat: 49.539075,
      lon: 5.48971,
    },
  },
  {
    column_4: 49.6055933,
    column_3: 5.4816156,
    column_2: "Meix-Devant-Virton",
    column_1: "6769",
    geom: null,
    coordonnees: {
      lat: 49.6055933,
      lon: 5.4816156,
    },
  },
  {
    column_4: 49.5764582,
    column_3: 5.4456876,
    column_2: "Sommethonne",
    column_1: "6769",
    geom: null,
    coordonnees: {
      lat: 49.5764582,
      lon: 5.4456876,
    },
  },
  {
    column_4: 49.6153377,
    column_3: 5.7608307,
    column_2: "Habergy",
    column_1: "6782",
    geom: null,
    coordonnees: {
      lat: 49.6153377,
      lon: 5.7608307,
    },
  },
  {
    column_4: 49.5642065,
    column_3: 5.8317661,
    column_2: "Athus",
    column_1: "6791",
    geom: null,
    coordonnees: {
      lat: 49.5642065,
      lon: 5.8317661,
    },
  },
  {
    column_4: 49.5590364,
    column_3: 5.7422467,
    column_2: "Halanzy",
    column_1: "6792",
    geom: null,
    coordonnees: {
      lat: 49.5590364,
      lon: 5.7422467,
    },
  },
  {
    column_4: 49.9708428,
    column_3: 5.4497307,
    column_2: "Freux",
    column_1: "6800",
    geom: null,
    coordonnees: {
      lat: 49.9708428,
      lon: 5.4497307,
    },
  },
  {
    column_4: 49.7031011,
    column_3: 5.4263273,
    column_2: "Les Bulles",
    column_1: "6811",
    geom: null,
    coordonnees: {
      lat: 49.7031011,
      lon: 5.4263273,
    },
  },
  {
    column_4: 49.7071328,
    column_3: 5.4579753,
    column_2: "Termes",
    column_1: "6813",
    geom: null,
    coordonnees: {
      lat: 49.7071328,
      lon: 5.4579753,
    },
  },
  {
    column_4: 49.8115586,
    column_3: 5.0035785,
    column_2: "Poupehan",
    column_1: "6830",
    geom: null,
    coordonnees: {
      lat: 49.8115586,
      lon: 5.0035785,
    },
  },
  {
    column_4: 49.8404522,
    column_3: 5.0070138,
    column_2: "Rochehaut",
    column_1: "6830",
    geom: null,
    coordonnees: {
      lat: 49.8404522,
      lon: 5.0070138,
    },
  },
  {
    column_4: 50.6175774,
    column_3: 5.412251,
    column_2: "Noirfontaine",
    column_1: "6831",
    geom: null,
    coordonnees: {
      lat: 50.6175774,
      lon: 5.412251,
    },
  },
  {
    column_4: 49.8314004,
    column_3: 5.0573896,
    column_2: "Ucimont",
    column_1: "6833",
    geom: null,
    coordonnees: {
      lat: 49.8314004,
      lon: 5.0573896,
    },
  },
  {
    column_4: 49.7976297,
    column_3: 5.0065337,
    column_2: "Corbion",
    column_1: "6838",
    geom: null,
    coordonnees: {
      lat: 49.7976297,
      lon: 5.0065337,
    },
  },
  {
    column_4: 49.8215033,
    column_3: 5.4076005,
    column_2: "Grapfontaine",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8215033,
      lon: 5.4076005,
    },
  },
  {
    column_4: 49.8411765,
    column_3: 5.4339147,
    column_2: "Neufchâteau",
    column_1: "6840",
    geom: null,
    coordonnees: {
      lat: 49.8411765,
      lon: 5.4339147,
    },
  },
  {
    column_4: 49.9065515,
    column_3: 5.2022069,
    column_2: "Jehonville",
    column_1: "6880",
    geom: null,
    coordonnees: {
      lat: 49.9065515,
      lon: 5.2022069,
    },
  },
  {
    column_4: 49.8158829,
    column_3: 5.3277128,
    column_2: "Saint-Médard",
    column_1: "6887",
    geom: null,
    coordonnees: {
      lat: 49.8158829,
      lon: 5.3277128,
    },
  },
  {
    column_4: 49.9809464,
    column_3: 5.2569017,
    column_2: "Libin",
    column_1: "6890",
    geom: null,
    coordonnees: {
      lat: 49.9809464,
      lon: 5.2569017,
    },
  },
  {
    column_4: 50.1825868,
    column_3: 5.3094652,
    column_2: "Hargimont",
    column_1: "6900",
    geom: null,
    coordonnees: {
      lat: 50.1825868,
      lon: 5.3094652,
    },
  },
  {
    column_4: 50.0685885,
    column_3: 5.0702515,
    column_2: "Sohier",
    column_1: "6920",
    geom: null,
    coordonnees: {
      lat: 50.0685885,
      lon: 5.0702515,
    },
  },
  {
    column_4: 50.0786533,
    column_3: 5.1559991,
    column_2: "Chanly",
    column_1: "6921",
    geom: null,
    coordonnees: {
      lat: 50.0786533,
      lon: 5.1559991,
    },
  },
  {
    column_4: 50.0754135,
    column_3: 5.1374206,
    column_2: "Halma",
    column_1: "6922",
    geom: null,
    coordonnees: {
      lat: 50.0754135,
      lon: 5.1374206,
    },
  },
  {
    column_4: 50.0017421,
    column_3: 5.016947,
    column_2: "Haut-Fays",
    column_1: "6929",
    geom: null,
    coordonnees: {
      lat: 50.0017421,
      lon: 5.016947,
    },
  },
  {
    column_4: 50.1324941,
    column_3: 5.2842239,
    column_2: "Forrières",
    column_1: "6953",
    geom: null,
    coordonnees: {
      lat: 50.1324941,
      lon: 5.2842239,
    },
  },
  {
    column_4: 50.2929635,
    column_3: 5.6756341,
    column_2: "Manhay",
    column_1: "6960",
    geom: null,
    coordonnees: {
      lat: 50.2929635,
      lon: 5.6756341,
    },
  },
  {
    column_4: 50.1077503,
    column_3: 5.5034047,
    column_2: "Champlon",
    column_1: "6971",
    geom: null,
    coordonnees: {
      lat: 50.1077503,
      lon: 5.5034047,
    },
  },
  {
    column_4: 50.209256,
    column_3: 5.6394828,
    column_2: "Samrée",
    column_1: "6982",
    geom: null,
    coordonnees: {
      lat: 50.209256,
      lon: 5.6394828,
    },
  },
  {
    column_4: 50.1249111,
    column_3: 5.6138065,
    column_2: "Ortho",
    column_1: "6983",
    geom: null,
    coordonnees: {
      lat: 50.1249111,
      lon: 5.6138065,
    },
  },
  {
    column_4: 50.1717133,
    column_3: 5.5020384,
    column_2: "Halleux",
    column_1: "6986",
    geom: null,
    coordonnees: {
      lat: 50.1717133,
      lon: 5.5020384,
    },
  },
  {
    column_4: 50.2006652,
    column_3: 5.4954367,
    column_2: "Hodister",
    column_1: "6987",
    geom: null,
    coordonnees: {
      lat: 50.2006652,
      lon: 5.4954367,
    },
  },
  {
    column_4: 50.2943293,
    column_3: 5.4221284,
    column_2: "Fronville",
    column_1: "6990",
    geom: null,
    coordonnees: {
      lat: 50.2943293,
      lon: 5.4221284,
    },
  },
  {
    column_4: 50.1458065,
    column_3: 5.7189108,
    column_2: "Mormont",
    column_1: "6997",
    geom: null,
    coordonnees: {
      lat: 50.1458065,
      lon: 5.7189108,
    },
  },
  {
    column_4: 50.4760502,
    column_3: 3.903746,
    column_2: "Ghlin",
    column_1: "7011",
    geom: null,
    coordonnees: {
      lat: 50.4760502,
      lon: 3.903746,
    },
  },
  {
    column_4: 50.4367571,
    column_3: 3.8878381,
    column_2: "Flénu",
    column_1: "7012",
    geom: null,
    coordonnees: {
      lat: 50.4367571,
      lon: 3.8878381,
    },
  },
  {
    column_4: 50.4759358,
    column_3: 3.9545894,
    column_2: "Nimy",
    column_1: "7020",
    geom: null,
    coordonnees: {
      lat: 50.4759358,
      lon: 3.9545894,
    },
  },
  {
    column_4: 50.4076446,
    column_3: 4.0177899,
    column_2: "Harmignies",
    column_1: "7022",
    geom: null,
    coordonnees: {
      lat: 50.4076446,
      lon: 4.0177899,
    },
  },
  {
    column_4: 50.3949004,
    column_3: 3.986884,
    column_2: "Harveng",
    column_1: "7022",
    geom: null,
    coordonnees: {
      lat: 50.3949004,
      lon: 3.986884,
    },
  },
  {
    column_4: 50.4312421,
    column_3: 4.0389071,
    column_2: "Villers-Saint-Ghislain",
    column_1: "7031",
    geom: null,
    coordonnees: {
      lat: 50.4312421,
      lon: 4.0389071,
    },
  },
  {
    column_4: 50.3667226,
    column_3: 3.9439033,
    column_2: "Quévy",
    column_1: "7040",
    geom: null,
    coordonnees: {
      lat: 50.3667226,
      lon: 3.9439033,
    },
  },
  {
    column_4: 50.3795142,
    column_3: 4.0293435,
    column_2: "Givry",
    column_1: "7041",
    geom: null,
    coordonnees: {
      lat: 50.3795142,
      lon: 4.0293435,
    },
  },
  {
    column_4: 50.5310206,
    column_3: 3.9423599,
    column_2: "Masnuy-Saint-Jean",
    column_1: "7050",
    geom: null,
    coordonnees: {
      lat: 50.5310206,
      lon: 3.9423599,
    },
  },
  {
    column_4: 50.5775309,
    column_3: 4.0732968,
    column_2: "Soignies",
    column_1: "7060",
    geom: null,
    coordonnees: {
      lat: 50.5775309,
      lon: 4.0732968,
    },
  },
  {
    column_4: 50.5130195,
    column_3: 4.0031319,
    column_2: "Casteau",
    column_1: "7061",
    geom: null,
    coordonnees: {
      lat: 50.5130195,
      lon: 4.0031319,
    },
  },
  {
    column_4: 50.5151015,
    column_3: 4.0489552,
    column_2: "Thieusies",
    column_1: "7061",
    geom: null,
    coordonnees: {
      lat: 50.5151015,
      lon: 4.0489552,
    },
  },
  {
    column_4: 50.5034034,
    column_3: 4.112245,
    column_2: "Le Roeulx",
    column_1: "7070",
    geom: null,
    coordonnees: {
      lat: 50.5034034,
      lon: 4.112245,
    },
  },
  {
    column_4: 50.4018707,
    column_3: 3.9298869,
    column_2: "Noirchain",
    column_1: "7080",
    geom: null,
    coordonnees: {
      lat: 50.4018707,
      lon: 3.9298869,
    },
  },
  {
    column_4: 50.3719019,
    column_3: 3.8764088,
    column_2: "Sars-La-Bruyère",
    column_1: "7080",
    geom: null,
    coordonnees: {
      lat: 50.3719019,
      lon: 3.8764088,
    },
  },
  {
    column_4: 50.6240857,
    column_3: 4.0894781,
    column_2: "Petit-Roeulx-Lez-Braine",
    column_1: "7090",
    geom: null,
    coordonnees: {
      lat: 50.6240857,
      lon: 4.0894781,
    },
  },
  {
    column_4: 50.474474,
    column_3: 4.1207031,
    column_2: "Strépy-Bracquegnies",
    column_1: "7110",
    geom: null,
    coordonnees: {
      lat: 50.474474,
      lon: 4.1207031,
    },
  },
  {
    column_4: 50.3952117,
    column_3: 4.0989347,
    column_2: "Estinnes-Au-Mont",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.3952117,
      lon: 4.0989347,
    },
  },
  {
    column_4: 50.4127908,
    column_3: 4.1064835,
    column_2: "Estinnes-Au-Val",
    column_1: "7120",
    geom: null,
    coordonnees: {
      lat: 50.4127908,
      lon: 4.1064835,
    },
  },
  {
    column_4: 50.4165125,
    column_3: 4.1685575,
    column_2: "Battignies",
    column_1: "7130",
    geom: null,
    coordonnees: {
      lat: 50.4165125,
      lon: 4.1685575,
    },
  },
  {
    column_4: 50.443039,
    column_3: 4.2542126,
    column_2: "Carnières",
    column_1: "7141",
    geom: null,
    coordonnees: {
      lat: 50.443039,
      lon: 4.2542126,
    },
  },
  {
    column_4: 50.4293887,
    column_3: 4.2340727,
    column_2: "Mont-Sainte-Aldegonde",
    column_1: "7141",
    geom: null,
    coordonnees: {
      lat: 50.4293887,
      lon: 4.2340727,
    },
  },
  {
    column_4: 50.4713265,
    column_3: 4.2804202,
    column_2: "Chapelle-Lez-Herlaimont",
    column_1: "7160",
    geom: null,
    coordonnees: {
      lat: 50.4713265,
      lon: 4.2804202,
    },
  },
  {
    column_4: 50.4955503,
    column_3: 4.2865899,
    column_2: "Godarville",
    column_1: "7160",
    geom: null,
    coordonnees: {
      lat: 50.4955503,
      lon: 4.2865899,
    },
  },
  {
    column_4: 50.4843145,
    column_3: 4.254013,
    column_2: "Bellecourt",
    column_1: "7170",
    geom: null,
    coordonnees: {
      lat: 50.4843145,
      lon: 4.254013,
    },
  },
  {
    column_4: 50.4611322,
    column_3: 3.7107354,
    column_2: "Pommeroeul",
    column_1: "7322",
    geom: null,
    coordonnees: {
      lat: 50.4611322,
      lon: 3.7107354,
    },
  },
  {
    column_4: 50.4823864,
    column_3: 3.7650049,
    column_2: "Hautrage",
    column_1: "7334",
    geom: null,
    coordonnees: {
      lat: 50.4823864,
      lon: 3.7650049,
    },
  },
  {
    column_4: 50.4861629,
    column_3: 3.7902777,
    column_2: "Villerot",
    column_1: "7334",
    geom: null,
    coordonnees: {
      lat: 50.4861629,
      lon: 3.7902777,
    },
  },
  {
    column_4: 50.4136507,
    column_3: 3.8629223,
    column_2: "Paturages",
    column_1: "7340",
    geom: null,
    coordonnees: {
      lat: 50.4136507,
      lon: 3.8629223,
    },
  },
  {
    column_4: 50.387884,
    column_3: 3.6957835,
    column_2: "Baisieux",
    column_1: "7380",
    geom: null,
    coordonnees: {
      lat: 50.387884,
      lon: 3.6957835,
    },
  },
  {
    column_4: 50.3566928,
    column_3: 3.7724828,
    column_2: "Fayt-Le-Franc",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.3566928,
      lon: 3.7724828,
    },
  },
  {
    column_4: 50.333082,
    column_3: 3.6938101,
    column_2: "Roisin",
    column_1: "7387",
    geom: null,
    coordonnees: {
      lat: 50.333082,
      lon: 3.6938101,
    },
  },
  {
    column_4: 50.4421804,
    column_3: 3.8636971,
    column_2: "Quaregnon",
    column_1: "7390",
    geom: null,
    coordonnees: {
      lat: 50.4421804,
      lon: 3.8636971,
    },
  },
  {
    column_4: 50.5721668,
    column_3: 3.3923658,
    column_2: "Saint-Maur",
    column_1: "7500",
    geom: null,
    coordonnees: {
      lat: 50.5721668,
      lon: 3.3923658,
    },
  },
  {
    column_4: 50.6195489,
    column_3: 3.3569842,
    column_2: "Froyennes",
    column_1: "7503",
    geom: null,
    coordonnees: {
      lat: 50.6195489,
      lon: 3.3569842,
    },
  },
  {
    column_4: 50.5885516,
    column_3: 3.4212542,
    column_2: "Chercq",
    column_1: "7521",
    geom: null,
    coordonnees: {
      lat: 50.5885516,
      lon: 3.4212542,
    },
  },
  {
    column_4: 50.6249665,
    column_3: 3.3035015,
    column_2: "Blandain",
    column_1: "7522",
    geom: null,
    coordonnees: {
      lat: 50.6249665,
      lon: 3.3035015,
    },
  },
  {
    column_4: 50.6379097,
    column_3: 3.3809106,
    column_2: "Kain",
    column_1: "7540",
    geom: null,
    coordonnees: {
      lat: 50.6379097,
      lon: 3.3809106,
    },
  },
  {
    column_4: 50.6096187,
    column_3: 3.4267528,
    column_2: "Warchin",
    column_1: "7548",
    geom: null,
    coordonnees: {
      lat: 50.6096187,
      lon: 3.4267528,
    },
  },
  {
    column_4: 50.529165,
    column_3: 3.5870901,
    column_2: "Roucourt",
    column_1: "7601",
    geom: null,
    coordonnees: {
      lat: 50.529165,
      lon: 3.5870901,
    },
  },
  {
    column_4: 50.5502028,
    column_3: 3.3416855,
    column_2: "Taintignies",
    column_1: "7618",
    geom: null,
    coordonnees: {
      lat: 50.5502028,
      lon: 3.3416855,
    },
  },
  {
    column_4: 50.5178063,
    column_3: 3.3795619,
    column_2: "Brunehaut",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.5178063,
      lon: 3.3795619,
    },
  },
  {
    column_4: 50.54598165,
    column_3: 3.4041047456312,
    column_2: "Jollain-Merlin",
    column_1: "7620",
    geom: null,
    coordonnees: {
      lat: 50.54598165,
      lon: 3.40410474563,
    },
  },
  {
    column_4: 50.5515108,
    column_3: 3.5020267,
    column_2: "Maubray",
    column_1: "7640",
    geom: null,
    coordonnees: {
      lat: 50.5515108,
      lon: 3.5020267,
    },
  },
  {
    column_4: 50.7273981,
    column_3: 3.3037301,
    column_2: "Dottignies",
    column_1: "7711",
    geom: null,
    coordonnees: {
      lat: 50.7273981,
      lon: 3.3037301,
    },
  },
  {
    column_4: 50.6689214,
    column_3: 3.3176015,
    column_2: "Bailleul",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.6689214,
      lon: 3.3176015,
    },
  },
  {
    column_4: 50.7130523,
    column_3: 3.2875443,
    column_2: "Evregnies",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.7130523,
      lon: 3.2875443,
    },
  },
  {
    column_4: 50.7056533,
    column_3: 3.3149729,
    column_2: "Saint-Léger",
    column_1: "7730",
    geom: null,
    coordonnees: {
      lat: 50.7056533,
      lon: 3.3149729,
    },
  },
  {
    column_4: 50.665077,
    column_3: 3.347779,
    column_2: "Esquelmes",
    column_1: "7743",
    geom: null,
    coordonnees: {
      lat: 50.665077,
      lon: 3.347779,
    },
  },
  {
    column_4: 50.7125435,
    column_3: 3.4571664,
    column_2: "Celles",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.7125435,
      lon: 3.4571664,
    },
  },
  {
    column_4: 50.7546292,
    column_3: 3.4486114,
    column_2: "Escanaffles",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.7546292,
      lon: 3.4486114,
    },
  },
  {
    column_4: 50.7314164,
    column_3: 3.4050325,
    column_2: "Pottes",
    column_1: "7760",
    geom: null,
    coordonnees: {
      lat: 50.7314164,
      lon: 3.4050325,
    },
  },
  {
    column_4: 50.6477056,
    column_3: 3.7677307,
    column_2: "Bouvignies",
    column_1: "7803",
    geom: null,
    coordonnees: {
      lat: 50.6477056,
      lon: 3.7677307,
    },
  },
  {
    column_4: 50.6397485,
    column_3: 3.67998,
    column_2: "Houtaing",
    column_1: "7812",
    geom: null,
    coordonnees: {
      lat: 50.6397485,
      lon: 3.67998,
    },
  },
  {
    column_4: 50.6566864,
    column_3: 3.875818,
    column_2: "Ghislenghien",
    column_1: "7822",
    geom: null,
    coordonnees: {
      lat: 50.6566864,
      lon: 3.875818,
    },
  },
  {
    column_4: 50.6637962,
    column_3: 3.8181687,
    column_2: "Isières",
    column_1: "7822",
    geom: null,
    coordonnees: {
      lat: 50.6637962,
      lon: 3.8181687,
    },
  },
  {
    column_4: 50.6874157,
    column_3: 3.8598773,
    column_2: "Ollignies",
    column_1: "7866",
    geom: null,
    coordonnees: {
      lat: 50.6874157,
      lon: 3.8598773,
    },
  },
  {
    column_4: 50.5570816,
    column_3: 3.9005461,
    column_2: "Lens",
    column_1: "7870",
    geom: null,
    coordonnees: {
      lat: 50.5570816,
      lon: 3.9005461,
    },
  },
  {
    column_4: 50.7371675,
    column_3: 3.7378707,
    column_2: "Flobecq",
    column_1: "7880",
    geom: null,
    coordonnees: {
      lat: 50.7371675,
      lon: 3.7378707,
    },
  },
  {
    column_4: 50.7338701,
    column_3: 3.6802062,
    column_2: "Ellezelles",
    column_1: "7890",
    geom: null,
    coordonnees: {
      lat: 50.7338701,
      lon: 3.6802062,
    },
  },
  {
    column_4: 50.6220544,
    column_3: 3.6322713,
    column_2: "Grandmetz",
    column_1: "7900",
    geom: null,
    coordonnees: {
      lat: 50.6220544,
      lon: 3.6322713,
    },
  },
  {
    column_4: 50.5967927,
    column_3: 3.6709445,
    column_2: "Chapelle-À-Oie",
    column_1: "7903",
    geom: null,
    coordonnees: {
      lat: 50.5967927,
      lon: 3.6709445,
    },
  },
  {
    column_4: 50.7282535,
    column_3: 3.5489704,
    column_2: "Wattripont",
    column_1: "7910",
    geom: null,
    coordonnees: {
      lat: 50.7282535,
      lon: 3.5489704,
    },
  },
  {
    column_4: 50.667755,
    column_3: 3.6194481,
    column_2: "Frasnes-Lez-Buissenal",
    column_1: "7911",
    geom: null,
    coordonnees: {
      lat: 50.667755,
      lon: 3.6194481,
    },
  },
  {
    column_4: 50.5444446,
    column_3: 3.7571017,
    column_2: "Grosage",
    column_1: "7950",
    geom: null,
    coordonnees: {
      lat: 50.5444446,
      lon: 3.7571017,
    },
  },
  {
    column_4: 50.5044413,
    column_3: 3.6966742,
    column_2: "Grandglise",
    column_1: "7973",
    geom: null,
    coordonnees: {
      lat: 50.5044413,
      lon: 3.6966742,
    },
  },
  {
    column_4: 50.508349,
    column_3: 3.7202435,
    column_2: "Stambruges",
    column_1: "7973",
    geom: null,
    coordonnees: {
      lat: 50.508349,
      lon: 3.7202435,
    },
  },
  {
    column_4: 51.2147083,
    column_3: 3.2073611,
    column_2: "Brugge",
    column_1: "8000",
    geom: null,
    coordonnees: {
      lat: 51.2147083,
      lon: 3.2073611,
    },
  },
  {
    column_4: 51.2419954,
    column_3: 3.2473789,
    column_2: "Koolkerke",
    column_1: "8000",
    geom: null,
    coordonnees: {
      lat: 51.2419954,
      lon: 3.2473789,
    },
  },
  {
    column_4: 51.1511469,
    column_3: 3.2497137262681,
    column_2: "Oostkamp",
    column_1: "8020",
    geom: null,
    coordonnees: {
      lat: 51.1511469,
      lon: 3.24971372627,
    },
  },
  {
    column_4: 51.1884619,
    column_3: 3.2111722,
    column_2: "Sint-Michiels",
    column_1: "8200",
    geom: null,
    coordonnees: {
      lat: 51.1884619,
      lon: 3.2111722,
    },
  },
  {
    column_4: 51.3396066,
    column_3: 3.2702133,
    column_2: "Knokke-Heist",
    column_1: "8300",
    geom: null,
    coordonnees: {
      lat: 51.3396066,
      lon: 3.2702133,
    },
  },
  {
    column_4: 51.2388018,
    column_3: 3.1071768079055,
    column_2: "Houtave",
    column_1: "8377",
    geom: null,
    coordonnees: {
      lat: 51.2388018,
      lon: 3.10717680791,
    },
  },
  {
    column_4: 51.242521,
    column_3: 3.0238828,
    column_2: "Klemskerke",
    column_1: "8420",
    geom: null,
    coordonnees: {
      lat: 51.242521,
      lon: 3.0238828,
    },
  },
  {
    column_4: 51.3019771,
    column_3: 3.0927740709639,
    column_2: "Wenduine",
    column_1: "8420",
    geom: null,
    coordonnees: {
      lat: 51.3019771,
      lon: 3.09277407096,
    },
  },
  {
    column_4: 51.2641695,
    column_3: 3.0635598193415,
    column_2: "Vlissegem",
    column_1: "8421",
    geom: null,
    coordonnees: {
      lat: 51.2641695,
      lon: 3.06355981934,
    },
  },
  {
    column_4: 51.1746761,
    column_3: 2.8776766,
    column_2: "Leffinge",
    column_1: "8432",
    geom: null,
    coordonnees: {
      lat: 51.1746761,
      lon: 2.8776766,
    },
  },
  {
    column_4: 51.15833025,
    column_3: 2.7777512824641,
    column_2: "Westende",
    column_1: "8434",
    geom: null,
    coordonnees: {
      lat: 51.15833025,
      lon: 2.77775128246,
    },
  },
  {
    column_4: 51.08742745,
    column_3: 3.0296222129927,
    column_2: "Ichtegem",
    column_1: "8480",
    geom: null,
    coordonnees: {
      lat: 51.08742745,
      lon: 3.02962221299,
    },
  },
  {
    column_4: 50.77232975,
    column_3: 3.2564730180436,
    column_2: "Rollegem",
    column_1: "8510",
    geom: null,
    coordonnees: {
      lat: 50.77232975,
      lon: 3.25647301804,
    },
  },
  {
    column_4: 50.8121065,
    column_3: 3.1864450062666,
    column_2: "Wevelgem",
    column_1: "8560",
    geom: null,
    coordonnees: {
      lat: 50.8121065,
      lon: 3.18644500627,
    },
  },
  {
    column_4: 50.844003,
    column_3: 3.463734552107,
    column_2: "Anzegem",
    column_1: "8570",
    geom: null,
    coordonnees: {
      lat: 50.844003,
      lon: 3.46373455211,
    },
  },
  {
    column_4: 50.8353247,
    column_3: 3.4068379,
    column_2: "Vichte",
    column_1: "8570",
    geom: null,
    coordonnees: {
      lat: 50.8353247,
      lon: 3.4068379,
    },
  },
  {
    column_4: 50.8130531,
    column_3: 3.4697738543929,
    column_2: "Tiegem",
    column_1: "8573",
    geom: null,
    coordonnees: {
      lat: 50.8130531,
      lon: 3.46977385439,
    },
  },
  {
    column_4: 51.0333408,
    column_3: 2.8647185,
    column_2: "Diksmuide",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.0333408,
      lon: 2.8647185,
    },
  },
  {
    column_4: 51.08053165,
    column_3: 2.7914713556336,
    column_2: "Pervijze",
    column_1: "8600",
    geom: null,
    coordonnees: {
      lat: 51.08053165,
      lon: 2.79147135563,
    },
  },
  {
    column_4: 51.0285109,
    column_3: 3.0441178,
    column_2: "Kortemark",
    column_1: "8610",
    geom: null,
    coordonnees: {
      lat: 51.0285109,
      lon: 3.0441178,
    },
  },
  {
    column_4: 51.1300222,
    column_3: 2.7781339,
    column_2: "Sint-Joris",
    column_1: "8620",
    geom: null,
    coordonnees: {
      lat: 51.1300222,
      lon: 2.7781339,
    },
  },
  {
    column_4: 51.0631103,
    column_3: 2.7457710712354,
    column_2: "Avekapelle",
    column_1: "8630",
    geom: null,
    coordonnees: {
      lat: 51.0631103,
      lon: 2.74577107124,
    },
  },
  {
    column_4: 50.930143,
    column_3: 2.7289867,
    column_2: "Vleteren",
    column_1: "8640",
    geom: null,
    coordonnees: {
      lat: 50.930143,
      lon: 2.7289867,
    },
  },
  {
    column_4: 50.9638066,
    column_3: 2.7643391,
    column_2: "Lo-Reninge",
    column_1: "8647",
    geom: null,
    coordonnees: {
      lat: 50.9638066,
      lon: 2.7643391,
    },
  },
  {
    column_4: 50.9726562,
    column_3: 2.7198585358008,
    column_2: "Pollinkhove",
    column_1: "8647",
    geom: null,
    coordonnees: {
      lat: 50.9726562,
      lon: 2.7198585358,
    },
  },
  {
    column_4: 50.99044185,
    column_3: 2.9155135952546,
    column_2: "Klerken",
    column_1: "8650",
    geom: null,
    coordonnees: {
      lat: 50.99044185,
      lon: 2.91551359525,
    },
  },
  {
    column_4: 50.962074,
    column_3: 2.8736172801014,
    column_2: "Merkem",
    column_1: "8650",
    geom: null,
    coordonnees: {
      lat: 50.962074,
      lon: 2.8736172801,
    },
  },
  {
    column_4: 50.9896601,
    column_3: 2.6608332486701,
    column_2: "Izenberge",
    column_1: "8691",
    geom: null,
    coordonnees: {
      lat: 50.9896601,
      lon: 2.66083324867,
    },
  },
  {
    column_4: 50.99996475,
    column_3: 3.3414263284671,
    column_2: "Tielt",
    column_1: "8700",
    geom: null,
    coordonnees: {
      lat: 50.99996475,
      lon: 3.34142632847,
    },
  },
  {
    column_4: 50.8949817,
    column_3: 3.3304964142362,
    column_2: "Ooigem",
    column_1: "8710",
    geom: null,
    coordonnees: {
      lat: 50.8949817,
      lon: 3.33049641424,
    },
  },
  {
    column_4: 50.91140755,
    column_3: 3.3604037488967,
    column_2: "Wielsbeke",
    column_1: "8710",
    geom: null,
    coordonnees: {
      lat: 50.91140755,
      lon: 3.3604037489,
    },
  },
  {
    column_4: 51.0422255,
    column_3: 3.2053756921268,
    column_2: "Zwevezele",
    column_1: "8750",
    geom: null,
    coordonnees: {
      lat: 51.0422255,
      lon: 3.20537569213,
    },
  },
  {
    column_4: 51.062755,
    column_3: 3.3789495803068,
    column_2: "Ruiselede",
    column_1: "8755",
    geom: null,
    coordonnees: {
      lat: 51.062755,
      lon: 3.37894958031,
    },
  },
  {
    column_4: 50.9484788,
    column_3: 3.2859605761212,
    column_2: "Meulebeke",
    column_1: "8760",
    geom: null,
    coordonnees: {
      lat: 50.9484788,
      lon: 3.28596057612,
    },
  },
  {
    column_4: 50.871701,
    column_3: 3.3427472358752,
    column_2: "Beveren",
    column_1: "8791",
    geom: null,
    coordonnees: {
      lat: 50.871701,
      lon: 3.34274723588,
    },
  },
  {
    column_4: 50.9336597,
    column_3: 3.2212852352972,
    column_2: "Emelgem",
    column_1: "8870",
    geom: null,
    coordonnees: {
      lat: 50.9336597,
      lon: 3.2212852353,
    },
  },
  {
    column_4: 50.93988415,
    column_3: 3.1890400909612,
    column_2: "Kachtem",
    column_1: "8870",
    geom: null,
    coordonnees: {
      lat: 50.93988415,
      lon: 3.18904009096,
    },
  },
  {
    column_4: 50.9129617,
    column_3: 2.8285726,
    column_2: "Zuidschote",
    column_1: "8904",
    geom: null,
    coordonnees: {
      lat: 50.9129617,
      lon: 2.8285726,
    },
  },
  {
    column_4: 50.91011515,
    column_3: 2.912173087276,
    column_2: "Langemark",
    column_1: "8920",
    geom: null,
    coordonnees: {
      lat: 50.91011515,
      lon: 2.91217308728,
    },
  },
  {
    column_4: 50.9122567,
    column_3: 2.9338635,
    column_2: "Langemark-Poelkapelle",
    column_1: "8920",
    geom: null,
    coordonnees: {
      lat: 50.9122567,
      lon: 2.9338635,
    },
  },
  {
    column_4: 50.7743769,
    column_3: 3.1658835832872,
    column_2: "Rekkem",
    column_1: "8930",
    geom: null,
    coordonnees: {
      lat: 50.7743769,
      lon: 3.16588358329,
    },
  },
  {
    column_4: 50.76764855,
    column_3: 2.8457459110391,
    column_2: "Heuvelland",
    column_1: "8950",
    geom: null,
    coordonnees: {
      lat: 50.76764855,
      lon: 2.84574591104,
    },
  },
  {
    column_4: 50.8903437,
    column_3: 2.6542871,
    column_2: "Proven",
    column_1: "8972",
    geom: null,
    coordonnees: {
      lat: 50.8903437,
      lon: 2.6542871,
    },
  },
  {
    column_4: 50.84518645,
    column_3: 2.6449117149406,
    column_2: "Watou",
    column_1: "8978",
    geom: null,
    coordonnees: {
      lat: 50.84518645,
      lon: 2.64491171494,
    },
  },
  {
    column_4: 51.0731177,
    column_3: 3.6781210300259,
    column_2: "Mariakerke",
    column_1: "9030",
    geom: null,
    coordonnees: {
      lat: 51.0731177,
      lon: 3.67812103003,
    },
  },
  {
    column_4: 51.1001986,
    column_3: 3.7644128,
    column_2: "Oostakker",
    column_1: "9041",
    geom: null,
    coordonnees: {
      lat: 51.1001986,
      lon: 3.7644128,
    },
  },
  {
    column_4: 51.1221778,
    column_3: 3.7836111,
    column_2: "Desteldonk",
    column_1: "9042",
    geom: null,
    coordonnees: {
      lat: 51.1221778,
      lon: 3.7836111,
    },
  },
  {
    column_4: 51.1467255,
    column_3: 3.8221384,
    column_2: "Mendonk",
    column_1: "9042",
    geom: null,
    coordonnees: {
      lat: 51.1467255,
      lon: 3.8221384,
    },
  },
  {
    column_4: 51.0199405,
    column_3: 3.6676767,
    column_2: "Sint-Denijs-Westrem",
    column_1: "9051",
    geom: null,
    coordonnees: {
      lat: 51.0199405,
      lon: 3.6676767,
    },
  },
  {
    column_4: 51.0287341,
    column_3: 3.8003347,
    column_2: "Heusden",
    column_1: "9070",
    geom: null,
    coordonnees: {
      lat: 51.0287341,
      lon: 3.8003347,
    },
  },
  {
    column_4: 51.1866966,
    column_3: 3.9795579,
    column_2: "Sinaai-Waas",
    column_1: "9112",
    geom: null,
    coordonnees: {
      lat: 51.1866966,
      lon: 3.9795579,
    },
  },
  {
    column_4: 51.2191283,
    column_3: 4.194377,
    column_2: "Vrasene",
    column_1: "9120",
    geom: null,
    coordonnees: {
      lat: 51.2191283,
      lon: 4.194377,
    },
  },
  {
    column_4: 51.1712275,
    column_3: 4.3091107,
    column_2: "Kruibeke",
    column_1: "9150",
    geom: null,
    coordonnees: {
      lat: 51.1712275,
      lon: 4.3091107,
    },
  },
  {
    column_4: 51.1701927,
    column_3: 3.8574284,
    column_2: "Wachtebeke",
    column_1: "9185",
    geom: null,
    coordonnees: {
      lat: 51.1701927,
      lon: 3.8574284,
    },
  },
  {
    column_4: 51.2104744,
    column_3: 4.0240412532131,
    column_2: "Stekene",
    column_1: "9190",
    geom: null,
    coordonnees: {
      lat: 51.2104744,
      lon: 4.02404125321,
    },
  },
  {
    column_4: 51.03987315,
    column_3: 4.1444016705284,
    column_2: "Baasrode",
    column_1: "9200",
    geom: null,
    coordonnees: {
      lat: 51.03987315,
      lon: 4.14440167053,
    },
  },
  {
    column_4: 51.0881618,
    column_3: 4.1202807810427,
    column_2: "Hamme",
    column_1: "9220",
    geom: null,
    coordonnees: {
      lat: 51.0881618,
      lon: 4.12028078104,
    },
  },
  {
    column_4: 51.1097147,
    column_3: 4.0842158,
    column_2: "Waasmunster",
    column_1: "9250",
    geom: null,
    coordonnees: {
      lat: 51.1097147,
      lon: 4.0842158,
    },
  },
  {
    column_4: 51.0102518,
    column_3: 3.928141226004,
    column_2: "Schellebelle",
    column_1: "9260",
    geom: null,
    coordonnees: {
      lat: 51.0102518,
      lon: 3.928141226,
    },
  },
  {
    column_4: 50.98997155,
    column_3: 3.9309567805899,
    column_2: "Serskamp",
    column_1: "9260",
    geom: null,
    coordonnees: {
      lat: 50.98997155,
      lon: 3.93095678059,
    },
  },
  {
    column_4: 51.0011357,
    column_3: 4.0954432870762,
    column_2: "Denderbelle",
    column_1: "9280",
    geom: null,
    coordonnees: {
      lat: 51.0011357,
      lon: 4.09544328708,
    },
  },
  {
    column_4: 50.9786275,
    column_3: 4.0981810157403,
    column_2: "Wieze",
    column_1: "9280",
    geom: null,
    coordonnees: {
      lat: 50.9786275,
      lon: 4.09818101574,
    },
  },
  {
    column_4: 50.97349005,
    column_3: 4.0687165350537,
    column_2: "Herdersem",
    column_1: "9310",
    geom: null,
    coordonnees: {
      lat: 50.97349005,
      lon: 4.06871653505,
    },
  },
  {
    column_4: 50.8563924,
    column_3: 4.0561164,
    column_2: "Okegem",
    column_1: "9400",
    geom: null,
    coordonnees: {
      lat: 50.8563924,
      lon: 4.0561164,
    },
  },
  {
    column_4: 50.9333485,
    column_3: 3.976602,
    column_2: "Erpe",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.9333485,
      lon: 3.976602,
    },
  },
  {
    column_4: 50.9226391,
    column_3: 3.9459888,
    column_2: "Erpe-Mere",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.9226391,
      lon: 3.9459888,
    },
  },
  {
    column_4: 50.923005,
    column_3: 3.9710267,
    column_2: "Mere",
    column_1: "9420",
    geom: null,
    coordonnees: {
      lat: 50.923005,
      lon: 3.9710267,
    },
  },
  {
    column_4: 50.8816742,
    column_3: 3.9548012,
    column_2: "Heldergem",
    column_1: "9450",
    geom: null,
    coordonnees: {
      lat: 50.8816742,
      lon: 3.9548012,
    },
  },
  {
    column_4: 50.8746795,
    column_3: 4.0440509206633,
    column_2: "Iddergem",
    column_1: "9472",
    geom: null,
    coordonnees: {
      lat: 50.8746795,
      lon: 4.04405092066,
    },
  },
  {
    column_4: 50.7654043,
    column_3: 3.8392513,
    column_2: "Goeferdinge",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 50.7654043,
      lon: 3.8392513,
    },
  },
  {
    column_4: 51.1742698,
    column_3: 3.9446436,
    column_2: "Moerbeke",
    column_1: "9500",
    geom: null,
    coordonnees: {
      lat: 51.1742698,
      lon: 3.9446436,
    },
  },
  {
    column_4: 50.7945316,
    column_3: 3.9525416,
    column_2: "Grimminge",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.7945316,
      lon: 3.9525416,
    },
  },
  {
    column_4: 50.7875051,
    column_3: 3.9891935,
    column_2: "Nieuwenhove",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.7875051,
      lon: 3.9891935,
    },
  },
  {
    column_4: 50.7968671,
    column_3: 3.8989486,
    column_2: "Schendelbeke",
    column_1: "9506",
    geom: null,
    coordonnees: {
      lat: 50.7968671,
      lon: 3.8989486,
    },
  },
  {
    column_4: 50.9449691,
    column_3: 3.8680104604272,
    column_2: "Bavegem",
    column_1: "9520",
    geom: null,
    coordonnees: {
      lat: 50.9449691,
      lon: 3.86801046043,
    },
  },
  {
    column_4: 50.91972825,
    column_3: 3.8521321366835,
    column_2: "Sint-Lievens-Houtem",
    column_1: "9520",
    geom: null,
    coordonnees: {
      lat: 50.91972825,
      lon: 3.85213213668,
    },
  },
  {
    column_4: 50.895806,
    column_3: 3.853568,
    column_2: "Hillegem",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.895806,
      lon: 3.853568,
    },
  },
  {
    column_4: 50.8495126,
    column_3: 3.9249438,
    column_2: "Sint-Antelinks",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.8495126,
      lon: 3.9249438,
    },
  },
  {
    column_4: 50.8411146,
    column_3: 3.8903745,
    column_2: "Steenhuize-Wijnhuize",
    column_1: "9550",
    geom: null,
    coordonnees: {
      lat: 50.8411146,
      lon: 3.8903745,
    },
  },
  {
    column_4: 50.8690354,
    column_3: 3.7893247,
    column_2: "Strijpen",
    column_1: "9620",
    geom: null,
    coordonnees: {
      lat: 50.8690354,
      lon: 3.7893247,
    },
  },
  {
    column_4: 50.8515679,
    column_3: 3.7206404,
    column_2: "Sint-Blasius-Boekel",
    column_1: "9630",
    geom: null,
    coordonnees: {
      lat: 50.8515679,
      lon: 3.7206404,
    },
  },
  {
    column_4: 50.8061799,
    column_3: 3.6782376,
    column_2: "Schorisse",
    column_1: "9688",
    geom: null,
    coordonnees: {
      lat: 50.8061799,
      lon: 3.6782376,
    },
  },
  {
    column_4: 50.7750908,
    column_3: 3.5230939,
    column_2: "Kwaremont",
    column_1: "9690",
    geom: null,
    coordonnees: {
      lat: 50.7750908,
      lon: 3.5230939,
    },
  },
  {
    column_4: 50.8316945,
    column_3: 3.6281646,
    column_2: "Edelare",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8316945,
      lon: 3.6281646,
    },
  },
  {
    column_4: 50.8878782,
    column_3: 3.6405818,
    column_2: "Heurne",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8878782,
      lon: 3.6405818,
    },
  },
  {
    column_4: 50.8336184,
    column_3: 3.6086057,
    column_2: "Leupegem",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8336184,
      lon: 3.6086057,
    },
  },
  {
    column_4: 50.8428971,
    column_3: 3.6636488,
    column_2: "Mater",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8428971,
      lon: 3.6636488,
    },
  },
  {
    column_4: 50.8442604,
    column_3: 3.604782,
    column_2: "Oudenaarde",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8442604,
      lon: 3.604782,
    },
  },
  {
    column_4: 50.8791033,
    column_3: 3.6536587,
    column_2: "Welden",
    column_1: "9700",
    geom: null,
    coordonnees: {
      lat: 50.8791033,
      lon: 3.6536587,
    },
  },
  {
    column_4: 50.8988246,
    column_3: 3.5907777,
    column_2: "Huise",
    column_1: "9750",
    geom: null,
    coordonnees: {
      lat: 50.8988246,
      lon: 3.5907777,
    },
  },
  {
    column_4: 50.9121208,
    column_3: 3.5978246,
    column_2: "Ouwegem",
    column_1: "9750",
    geom: null,
    coordonnees: {
      lat: 50.9121208,
      lon: 3.5978246,
    },
  },
  {
    column_4: 50.9845547,
    column_3: 3.5628186,
    column_2: "Astene",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.9845547,
      lon: 3.5628186,
    },
  },
  {
    column_4: 50.969075,
    column_3: 3.5324987,
    column_2: "Petegem-Aan-De-Leie",
    column_1: "9800",
    geom: null,
    coordonnees: {
      lat: 50.969075,
      lon: 3.5324987,
    },
  },
  {
    column_4: 51.0747776,
    column_3: 3.5345703,
    column_2: "Hansbeke",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.0747776,
      lon: 3.5345703,
    },
  },
  {
    column_4: 51.03238865,
    column_3: 3.5117304815871,
    column_2: "Poesele",
    column_1: "9850",
    geom: null,
    coordonnees: {
      lat: 51.03238865,
      lon: 3.51173048159,
    },
  },
  {
    column_4: 50.9252028,
    column_3: 3.7896135,
    column_2: "Balegem",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.9252028,
      lon: 3.7896135,
    },
  },
  {
    column_4: 50.9694937,
    column_3: 3.7928259,
    column_2: "Landskouter",
    column_1: "9860",
    geom: null,
    coordonnees: {
      lat: 50.9694937,
      lon: 3.7928259,
    },
  },
  {
    column_4: 50.9309503,
    column_3: 3.721942,
    column_2: "Baaigem",
    column_1: "9890",
    geom: null,
    coordonnees: {
      lat: 50.9309503,
      lon: 3.721942,
    },
  },
  {
    column_4: 51.0876396,
    column_3: 3.6412401,
    column_2: "Vinderhoute",
    column_1: "9921",
    geom: null,
    coordonnees: {
      lat: 51.0876396,
      lon: 3.6412401,
    },
  },
  {
    column_4: 51.1793505,
    column_3: 3.7471789,
    column_2: "Ertvelde",
    column_1: "9940",
    geom: null,
    coordonnees: {
      lat: 51.1793505,
      lon: 3.7471789,
    },
  },
  {
    column_4: 51.2509311,
    column_3: 3.7059614986083,
    column_2: "Boekhoute",
    column_1: "9961",
    geom: null,
    coordonnees: {
      lat: 51.2509311,
      lon: 3.70596149861,
    },
  },
  {
    column_4: 51.2682182,
    column_3: 3.580094,
    column_2: "Sint-Jan-In-Eremo",
    column_1: "9982",
    geom: null,
    coordonnees: {
      lat: 51.2682182,
      lon: 3.580094,
    },
  },
  {
    column_4: 51.2891181,
    column_3: 3.5905485,
    column_2: "Waterland-Oudeman",
    column_1: "9988",
    geom: null,
    coordonnees: {
      lat: 51.2891181,
      lon: 3.5905485,
    },
  },
  {
    column_4: 51.2263133,
    column_3: 3.4154538,
    column_2: "Maldegem",
    column_1: "9990",
    geom: null,
    coordonnees: {
      lat: 51.2263133,
      lon: 3.4154538,
    },
  },
];
