import { createTheme, makeStyles } from "@material-ui/core/styles";


import blueGrey from "@material-ui/core/colors/blueGrey";
import teal from "@material-ui/core/colors/teal";
import "./index.css";

export const style = {
  root: {
    width: `100%`,

    padding: "0",
  },
  content: {
    padding: ".5em 2em",
  },
  mT25: {
    marginTop: 25,
  },
  card: {
    borderRadius: "1em"
  },
  tableHeadCell: {
    fontWeight: 600,
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
  cardHead: {
    fontWeight: 600,
    color: "#00000080",
    padding: "18px 28px",
    background: "#FFF",
    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
    borderRadius: "1em 1em 0 0",
    borderBottomColor: "#f1f1f1",
    fontSize: 15,
  },
  cardContent: {
    padding: 24
  }
};

export const useStyles = makeStyles((theme) => ({
  ...style,
}));

export const primaryColor = blueGrey[800];
export const secondaryColor = teal[800];

export const themeApp = {
  shape: {
    borderRadius: ".5em",
  },
  typography: {
    fontFamily: `"Open Sans", sans-serif`,
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: 25,
      fontWeight: 600,
      lineHeight: 1.2,
      color: "#000000cc",
    },
    h2: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#000000aa",
    },
  },
  palette: {
    background: {
      default: "#F0F2F5",
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: ".4em 1.6em",
        borderRadius: ".5em",
        fontWeight: 600,
      },
      text: {
        padding: ".35em 1.6em",
      },
      contained: {
        transition: ".2s all ease-in",
        boxShadow: 'none',
        "&:hover": {
          boxShadow: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.08)",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.08)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.08)",
          },
        },
        "& $disabled": {
          borderColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
  },
};

export const theme = createTheme({ ...themeApp });
