import axios from "axios";

import { CustomLinkButton, CustomArchivingButton } from "../../components";


export const route = "equipe";
export const routeTable = "equipes";
export const nameTable = "Équipes";

//shortlist

export async function getShortlists(setOptions) {
  let clubs = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "clubs" + "/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        clubs.push({
          label: data[i].name,
          value: data[i].id,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });
  setOptions({
    club_id: clubs,
  });
  // End shortlist
}

export const struct = (data) => {
  const club = data?.club;
  let _club = [];
  _club.push(club);
  let club_name = null;
  let club_id = null;
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("club_id")) {
    club_id = urlParams.get("club_id");
  }
  if (urlParams.get("club_name")) {
    club_name = urlParams.get("club_name");
  }
  return {
    end_date: {
      label: "Date de fin",
      type: "date",
      value: data?.end_date ? data.end_date : null,
      //disabled: Boolean(data?.end_date) || !Boolean(data?.foundation_date),
    },
    foundation_date: {
      label: "Date de fondation",
      type: "date",
      value: data?.foundation_date ? data.foundation_date : new Date(),
    },
    club_id: {
      label: "Club associé",
      type: "autocomplete",
      value: {
        value: club_id ? club_id : data?.club?.id,
        label: club_name ? club_name : data?.club?.name,
      },
    },
    category: {
      label: "Catégorie",
      type: "select",

      value: data?.category ? data.category : "",
      values: [
        {
          value: "Dimanche",
          label: "Dimanche",
        },
        {
          value: "Vétérans",
          label: "Vétérans",
        },
        {
          value: "RIL",
          label: "RIL",
        },
        {
          value: "ALFA",
          label: "ALFA",
        },
      ],
    },
    active: {
      type: "checkbox",
      label: "Active",
      checked: data ? Boolean(data?.active) : true,
      disabled: !data,
    },
    club: _club,
    teams: data?.teams,
    affiliations: data?.affiliations,
  };
};

export const items = [
  ["club_id", "category", "foundation_date", "end_date", "active"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display:false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "id_equipe",
    label: "ID",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "name",
    label: "Nom du club",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "category",
    label: "Catégorie",
    options: {
      filter: true,
      sort: true,
    },
  },

  {
    name: "foundation_date",
    label: "Date de fondation",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "end_date",
    label: "Date de fin",
    options: {
      filter: false,
      sort: true,
    },
  },
      {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const columnsChild = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display:false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "id_equipe",
    label: "ID",
    options: {
      filter: false,
      sort: false,
      display:false,
    },
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "category",
    label: "Catégorie",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "foundation_date",
    label: "Date de fondation",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "end_date",
    label: "Date de fin",
    options: {
      filter: false,
      sort: true,
    },
  },
    {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const structTable = (el) => {
  return {
    id: el?.id,
    id_equipe: el?.id,
    archived: el?.archived === 0 ? "Actif" : "Archive",
    active: el?.active ? "Active" : "Inactive",
    name: el?.club?.name,
    category: el?.category,
    foundation_date: el?.foundation_date,
    end_date: el?.end_date,
  };
};

export const defaultSort = {
  name: "foundation_date",
  direction: "desc",
};
