import React, { useEffect, useLayoutEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { css } from "@emotion/css";
import Color from "color";
import clsx from "clsx";

import toast from "react-hot-toast";
import axios from "axios";

import { emptyCache, AlertDialog } from "../../components";

import * as ROUTES from "../../constants/routes";

import { makeStyles } from "@material-ui/core/styles";
/* import AppBar from "@material-ui/core/AppBar";*/
import { ReactComponent as SoccerField } from "../../ressources/soccer-field.svg";
import { ReactComponent as HandShake } from "../../ressources/hand-shake.svg";
import { ReactComponent as Football } from "../../ressources/football.svg";
import { ReactComponent as SanctionIcon } from "../../ressources/judgment.svg";
import { ReactComponent as TeamsIcon } from "../../ressources/teams.svg";

import { withRouter } from "react-router-dom";
import Logo from "../../ressources/ALFA.png";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import * as Icon from "@material-ui/icons";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";

const useStylesDrawer = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 36,
    width: 36,
    position: "relative",
    marginRight: 10,
  },
  listItemIconAdm: {
    minWidth: 20,
    width: 20,
    position: "relative",
    marginRight: 10,
    "& svg": {
      fill: "#00000080",
      width: 20,
      minWidth: 20,
    },
  },
  listItemText: {
    color: "#00000080",
    fontWeight: 600,
    fontSize: "15px",
    "&:hover": {
      color: "#00000080",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    transition: "all ease-in .2s",
    zIndex: 1200,
    width: 220,
    position: "relative",
    flexShrink: 0,
    borderRight: "1px solid rgba(0, 0, 0, 0.04)",
    "& .MuiListItemText-primary": {
      fontSize: 14,
    },
  },
  drawerClose: {
    transform: "translateX(-220px)",
    width: 0,
  },
  drawerContainer: {
    overflow: "auto",
    zIndex: 1200,
    width: 220,
    height: "100%",
  },
  drawerContainerClose: {
    position: "absolute",
  },
  logo: {
    padding: "0 5px",
    "&:hover, &:focus, &:active": {
      backgroundColor: "transparent",
      color: "black",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: "fixed",
    left: "245px",
    top: "8px",
    zIndex: 999999,
    color: "white",
    transition: "all ease-in .2s",
  },
  menuButtonDrawerClose: {
    left: 25,
  },
}));

function DrawerNav(props) {
  const [selectedSanction, setSelectedSanction] = useState("");
  const [committeeDate, setCommitteeDate] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorRapports, setAnchorRapports] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [showDialog, setShowDialog] = React.useState(false);
  const [
    showDialogCleanAffiliations,
    setShowDialogCleanAffiliations,
  ] = React.useState(false);
  const [
    showDialogResetChampionnat,
    setShowDialogResetChampionnat,
  ] = React.useState(false);
  const [fieldsRapport, setFieldsRapport] = React.useState(Array);

  const isMenuAdminOpen = Boolean(anchorEl);
  const isMenuRapportsOpen = Boolean(anchorRapports);
  const { userInfos } = props;

  useEffect(() => {
    /*if(userInfos?.email) {
      handleChangeFields(null, 'email', userInfos?.email);
    }*/
  }, []);

  const dateFns = new DateFnsUtils();

  const handleMenuAdminClose = () => {
    setAnchorEl(null);
  };

  const handleMenuRapportsClose = () => {
    setAnchorRapports(null);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleGenerateRapport = (type = null) => {
    console.log("type", type);
    return false;
  };

  function Rapport() {
    return (
      <Grid container spacing={2}>
        {/*<Alert severity="warning" style={{width:'100%', margin:10}}>
          <AlertTitle>Attention</AlertTitle>
          Cette partie est en cours de développement...
    </Alert>*/}
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              autoOk="true"
              helperText={""}
              name={"start_date"}
              inputVariant="outlined"
              required={true}
              id="start_date"
              label={"Date de début"}
              style={{ width: "100%" }}
              format="dd/MM/yyyy"
              value={
                fieldsRapport && fieldsRapport?.start_date
                  ? fieldsRapport?.start_date
                  : null
              }
              onChange={(e) => {
                let value = e;
                if (dateFns?.isValid(e)) {
                  value = dateFns?.format(e, "yyyy-MM-dd");
                }
                handleChangeFields(null, "start_date", value);
              }}
              KeyboardButtonProps={{
                "aria-label": "Date de début",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              autoOk="true"
              helperText={""}
              name={"end_date"}
              inputVariant="outlined"
              required={true}
              style={{ width: "100%" }}
              id="end_date"
              label={"Date de fin"}
              format="dd/MM/yyyy"
              value={
                fieldsRapport && fieldsRapport?.end_date
                  ? fieldsRapport?.end_date
                  : null
              }
              onChange={(e) => {
                let value = e;
                if (dateFns?.isValid(e)) {
                  value = dateFns?.format(e, "yyyy-MM-dd");
                }
                handleChangeFields(null, "end_date", value);
              }}
              KeyboardButtonProps={{
                "aria-label": "Date de fin",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    );
  }

  function RapportNoCalendar() {
    //   return (
    //     <Grid container spacing={2}>
    //       {/*<Alert severity="warning" style={{width:'100%', margin:10}}>
    //         <AlertTitle>Attention</AlertTitle>
    //         Cette partie est en cours de développement...
    //   </Alert>*/}
    //       {/*<Grid item xs={12}>
    //     <TextField
    //                   variant="outlined"
    //                   required={true}
    //                   fullWidth
    //                   id="email"
    //                   label="Adresse email"
    //                   name="email"
    //                   value={fieldsRapport && fieldsRapport?.email ? fieldsRapport?.email : null}
    //                   onChange={handleChangeFields}
    //                   autoComplete="email"
    // /></Grid>*/}
    //     </Grid>
    //   );
    return <></>;
  }

  const SelectSanctions = ({ onClose }) => {
    const [selectedSanction, setSelectedSanction] = useState("");
    const [committeeDate, setCommitteeDate] = useState(null);

    const handleChange = (event) => {
      setSelectedSanction(event.target.value);
    };

    const handleDateChange = (date) => {
      if (dateFns.isValid(date)) {
        setCommitteeDate(dateFns.format(date, "yyyy-MM-dd"));
      } else {
        setCommitteeDate(null);
      }
    };

    const handleGenerate = () => {
      if (selectedSanction && committeeDate) {
        const route = `sanctions/${selectedSanction.toLowerCase()}`;

        // Créer un formulaire pour soumettre les données
        const form = document.createElement("form");
        form.method = "POST";
        form.action = `${process.env.REACT_APP_API_URL}generate_report/${route}`;

        // Ajouter la date du rassemblement du comité comme champ caché
        const hiddenDateField = document.createElement("input");
        hiddenDateField.type = "hidden";
        hiddenDateField.name = "committee_date";
        hiddenDateField.value = committeeDate; // Assurez-vous que cette variable est correctement formatée
        form.appendChild(hiddenDateField);

        // Ajouter le token d'authentification
        const authField = document.createElement("input");
        authField.type = "hidden";
        authField.name = "authorization";
        authField.value = localStorage.getItem("api_token"); // Assumer que le token est stocké dans localStorage
        form.appendChild(authField);

        // Ajouter le formulaire au corps de la page et le soumettre
        document.body.appendChild(form);
        form.submit();

        // Supprimer le formulaire du corps de la page après la soumission
        document.body.removeChild(form);

        onClose(); // Fermer la modal ou le dialogue si nécessaire
      } else {
        console.log("Sanction ou date du rassemblement manquante.");
      }
    };

    return (
      <div>
        <FormControl fullWidth>
          <InputLabel id="sanction-select-label">Comité</InputLabel>
          <Select
            labelId="sanction-select-label"
            value={selectedSanction}
            onChange={handleChange}
          >
            <MenuItem value="CGAC">CGAC</MenuItem>
            <MenuItem value="Sportif">Sportif</MenuItem>
            <MenuItem value="Appel">Appel</MenuItem>
          </Select>
        </FormControl>

        {/* Ajouter un champ de date ici */}
        <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
          <KeyboardDatePicker
            variant="inline"
            autoOk
            helperText=""
            name="committee_date"
            inputVariant="outlined"
            required
            style={{ width: "100%", marginTop: 20 }}
            id="committee_date"
            label="Date du comité"
            format="dd/MM/yyyy"
            value={committeeDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              "aria-label": "Date du rassemblement du comité",
            }}
          />
        </MuiPickersUtilsProvider>

        <div
          style={{ marginTop: 20, display: "flex", justifyContent: "flex-end" }}
        >
          <Button onClick={onClose} color="default" style={{ marginRight: 10 }}>
            Annuler
          </Button>
          <Button
            onClick={handleGenerate}
            color="primary"
            variant="contained"
            disabled={!selectedSanction || !committeeDate}
          >
            Générer
          </Button>
        </div>
      </div>
    );
  };

  const handleChangeFields = (event, name = null, value = undefined) => {
    if (event && !name && !value) {
      name = event?.target?.name;
      value = event?.target?.value;
    }
    setFieldsRapport({ ...fieldsRapport, [name]: value });
  };

  const renderMenu = () => (
    <Menu
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "right",
        horizontal: "right",
      }}
      id={"admin-menu"}
      open={isMenuAdminOpen}
      onClose={handleMenuAdminClose}
      style={{ zIndex: 15500 }}
    >
      {userInfos?.admin ? (
        <MenuItem
          component={React.forwardRef((props, ref) => (
            <Link
              to={{
                pathname: ROUTES.CHAMPIONNAT,
              }}
              {...props}
              ref={ref}
            />
          ))}
          onClick={handleMenuAdminClose}
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.EmojiEvents fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Créer un championnat
          </Typography>
        </MenuItem>
      ) : null}
      {userInfos?.admin ? (
        <MenuItem
          component={React.forwardRef((props, ref) => (
            <Link
              to={{
                pathname: ROUTES.CREATEUR_RENCONTRES,
              }}
              {...props}
              ref={ref}
            />
          ))}
          onClick={handleMenuAdminClose}
          className={classes.menuItem}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.Edit fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Créateur de rencontres
          </Typography>
        </MenuItem>
      ) : null}
      {userInfos?.admin ? (
        <MenuItem
          component={React.forwardRef((props, ref) => (
            <Link
              to={{
                pathname: ROUTES.USERS,
              }}
              {...props}
              ref={ref}
            />
          ))}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.AccountBox fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Gestion des utilisateurs
          </Typography>
        </MenuItem>
      ) : null}
      {!userInfos?.admin ? (
        <MenuItem
          component={React.forwardRef((props, ref) => (
            <Link
              to={{
                pathname: ROUTES.MY_ACCOUNT,
              }}
              {...props}
              ref={ref}
            />
          ))}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.Person fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Mon profil
          </Typography>
        </MenuItem>
      ) : null}
      <MenuItem
        onClick={() => {
          handleMenuAdminClose();
          let token = localStorage.getItem("api_token");
          localStorage.clear();
          emptyCache();
          localStorage.setItem("api_token", token);
          props.history.push(ROUTES.HOME);
        }}
      >
        <ListItemIcon className={classes.listItemIconAdm}>
          <Icon.DeleteSweep fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" className={classes.listItemText}>
          Vider les caches
        </Typography>
      </MenuItem>
      {userInfos?.admin ? (
        <MenuItem
          onClick={() => {
            handleMenuAdminClose();
            setShowDialogCleanAffiliations(true);
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.DeleteSweep fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Nettoyer les affiliations
          </Typography>
        </MenuItem>
      ) : null}
      {userInfos?.admin ? (
        <MenuItem
          onClick={() => {
            handleMenuAdminClose();
            setShowDialogResetChampionnat(true);
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.RotateLeft fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Réinitialiser le championnat
          </Typography>
        </MenuItem>
      ) : null}
      <MenuItem
        className={classes.menuItem}
        onClick={() => {
          handleMenuAdminClose();
          localStorage.clear();
          emptyCache();
          props.history.push(ROUTES.HOME);
        }}
      >
        <ListItemIcon className={classes.listItemIconAdm}>
          <Icon.ExitToApp fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit" className={classes.listItemText}>
          Se déconnecter
        </Typography>
      </MenuItem>
    </Menu>
  );

  const handleSubmitRapport = async (route, additionalData) => {
    // 1. Create a hidden form dynamically
    const form = document.createElement("form");
    form.method = "POST";
    if (route === "cartes jaunes") {
      route = "cartes-jaunes";
    }
    form.action = process.env.REACT_APP_API_URL + `generate_report/${route}`;

    // Add POST data as hidden fields
    for (const key in fieldsRapport) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = fieldsRapport[key];
      form.appendChild(hiddenField);
    }

    // Set the authorization header
    const authField = document.createElement("input");
    authField.type = "hidden";
    authField.name = "authorization";
    authField.value = localStorage.getItem("api_token");
    form.appendChild(authField);

    // Append the form to the body and submit it
    document.body.appendChild(form);
    form.submit();

    // Remove the form from the body
    document.body.removeChild(form);
  };

  const handleCleanAffiliations = async () => {
    console.log("clean affiliations");
    await axios
      .get(process.env.REACT_APP_API_URL + `affiliations/clean`, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        toast.success(response?.data?.success, { duration: 4000 });
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de la génération...",
          { duration: 4000 }
        );
      });
  };

  const handleResetChampionnat = async () => {
    console.log("reset championnat");
    await axios
      .get(process.env.REACT_APP_API_URL + `championnat/reset`, {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((response) => {
        toast.success(response?.data?.success, { duration: 4000 });
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Problème lors de la génération...",
          { duration: 4000 }
        );
      });
  };

  const renderAlertRapports = () => {
    if (!showDialog) {
      return null;
    }

    // Déterminer le composant de dialogue et le titre en fonction de showDialog
    let dialogComponent, dialogTitle;
    let showActions = true; // Contrôle l'affichage des boutons

    if (
      showDialog === "press-calendar" ||
      showDialog === "championnat" ||
      showDialog === "cartes jaunes"
    ) {
      dialogComponent = RapportNoCalendar;
      dialogTitle =
        showDialog === "press-calendar"
          ? "Générer le calendrier de presse"
          : "Générer le rapport des " + showDialog;
    } else if (showDialog === "sanctions") {
      dialogComponent = () => (
        <SelectSanctions onClose={() => setShowDialog(false)} />
      );
      dialogTitle = "Rapport comité";
      showActions = false; // Ne pas afficher les actions pour les sanctions
    } else {
      dialogComponent = Rapport;
      dialogTitle = "Générer le rapport des " + showDialog;
    }

    return (
      <AlertDialog
        showDialog={Boolean(showDialog)}
        setShowDialog={setShowDialog}
        dialogComponent={dialogComponent}
        dialogTitle={dialogTitle}
        primary_action={showActions ? "Générer" : undefined}
        secondary_action={showActions ? "Annuler" : undefined}
        handlePrimaryAction={
          showActions
            ? (e) => {
                e.stopPropagation();
                setShowDialog(false);
                handleSubmitRapport(showDialog);
                handleGenerateRapport(showDialog);
              }
            : undefined
        }
        handleSecondaryAction={
          showActions
            ? (e) => {
                e.stopPropagation();
                setFieldsRapport([]);
                setShowDialog(false);
              }
            : undefined
        }
      />
    );
  };

  const renderAlertCleanAffiliations = () => {
    if (showDialogCleanAffiliations) {
      return (
        <AlertDialog
          showDialog={Boolean(showDialogCleanAffiliations)}
          setShowDialog={setShowDialogCleanAffiliations}
          dialogTitle={"Nettoyer les affiliations"}
          dialogText={"Êtes-vous sûr de vouloir nettoyer les affiliations ?"}
          primary_action={"Nettoyer"}
          secondary_action={"Annuler"}
          handlePrimaryAction={(e) => {
            e.stopPropagation();
            setShowDialogCleanAffiliations(false);
            handleCleanAffiliations();
          }}
          handleSecondaryAction={(e) => {
            e.stopPropagation();
            setShowDialogCleanAffiliations(false);
          }}
        />
      );
    }
    return <></>;
  };

  const renderAlertResetChampionnat = () => {
    if (showDialogResetChampionnat) {
      return (
        <AlertDialog
          showDialog={Boolean(showDialogResetChampionnat)}
          setShowDialog={setShowDialogResetChampionnat}
          dialogTitle={"Réinitialiser le championnat"}
          dialogText={"Êtes-vous sûr de vouloir réinitialiser le championnat ?"}
          primary_action={"Réinitialiser"}
          secondary_action={"Annuler"}
          handlePrimaryAction={(e) => {
            e.stopPropagation();
            setShowDialogResetChampionnat(false);
            handleResetChampionnat();
          }}
          handleSecondaryAction={(e) => {
            e.stopPropagation();
            setShowDialogResetChampionnat(false);
          }}
        />
      );
    }
    return <></>;
  };

  const renderMenuRapports = () => (
    <>
      <Menu
        anchorEl={anchorRapports}
        transformOrigin={{
          vertical: "right",
          horizontal: "right",
        }}
        id={"rapports-menu"}
        open={isMenuRapportsOpen}
        onClose={() => {
          handleMenuRapportsClose();
          setShowDialog(false);
        }}
        style={{ zIndex: 15500 }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("affiliations");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.SupervisedUserCircle />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer rapport d'affiliations
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("rencontres");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <HandShake />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer rapport de rencontres
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("cartes jaunes");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <SanctionIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer rapport des cartes jaunes
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("sanctions");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <SanctionIcon />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer rapport de comité
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("championnat");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.EmojiEvents fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer rapport classement championnat
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleMenuRapportsClose();
            setShowDialog("press-calendar");
          }}
        >
          <ListItemIcon className={classes.listItemIconAdm}>
            <Icon.ImportContacts />
          </ListItemIcon>
          <Typography variant="inherit" className={classes.listItemText}>
            Générer le calendrier presse
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );

  const { pathname } = props.location;
  let colors = [
    "#6062ea",
    "#db46a7",
    "#e52d46",
    "#F5691D",
    "#40c473",
    "#f0bd24",
  ];
  const classes = useStylesDrawer();

  const listItems = [
    /*{
      name: "Dashboard",
      route: ROUTES.DASHBOARD,
      icon: <Icon.DonutLarge size={"small"} />,
      pathnames: ['/dashboard'],
    },*/
    {
      name: "Membres",
      access: "access_membres_view",
      route: ROUTES.MEMBRES,
      pathnames: ["/membre", "/membres"],
      icon: <Icon.GroupOutlined size={"small"} />,
      sub: [
        {
          name: "Affiliations",
          access: "access_affiliations_view",
          pathnames: ["/affiliations", "/affiliation"],
          route: ROUTES.AFFILIATIONS,
          icon: <Icon.SupervisedUserCircle size={"small"} />,
        },
      ],
    },
    {
      name: "Équipes",
      access: "access_equipes_view",
      route: ROUTES.EQUIPES,
      pathnames: ["/equipe", "/equipes"],
      icon: <Football />,
      sub: [
        {
          name: "Teams",
          route: ROUTES.TEAMS,
          pathnames: ["/teams", "/team"],
          icon: <TeamsIcon />,
        },
      ],
    },
    {
      name: "Clubs",
      route: ROUTES.CLUBS,
      access: "access_clubs_view",
      icon: <Icon.HomeOutlined size={"small"} />,
      pathnames: ["/clubs", "/club"],
    },
    {
      name: "Terrains",
      route: ROUTES.TERRAINS,
      access: "access_terrains_view",
      icon: <SoccerField />,
      pathnames: ["/terrains", "/terrain"],
    },
    {
      name: "Rencontres",
      route: ROUTES.RENCONTRES,
      access: "access_rencontres_view",
      icon: <HandShake />,
      pathnames: ["/rencontres", "/rencontre"],
    },
    {
      name: "Sanctions",
      route: ROUTES.SANCTIONS,
      icon: <SanctionIcon />,
      access: "access_sanctions_view",
      pathnames: ["/sanctions", "/sanction"],
    },
  ];

  const list = () => {
    return (
      <>
        <div
          className={
            drawerOpen
              ? classes.drawerContainer
              : clsx(classes.drawerContainer, classes.drawerContainerClose)
          }
          role="menu"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexWrap: "nowrap",
              alignItems: "stretch",
              alignContent: "stretch",
              height: "100%",
            }}
          >
            <div style={{ height: "100%" }}>
              <Button
                component={React.forwardRef((props, ref) => (
                  <NavLink to={ROUTES.HOME} {...props} ref={ref} />
                ))}
                disableRipple
                disableTouchRipple
                className={classes.logo}
              >
                <div
                  style={{
                    margin: ".5em 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <img src={Logo} width={80} height={80} />
                  <div>
                    <h1
                      style={{
                        fontSize: 13,
                        marginLeft: 5,
                        marginBottom: 0,
                        fontWeight: "bold",
                        opacity: 0.9,
                      }}
                    >
                      Gesclub - A.L.F.A
                    </h1>
                    <h1
                      style={{
                        fontSize: 11,
                        marginLeft: 5,
                        marginBottom: 0,
                        fontWeight: "normal",
                        lineHeight: 1.05,
                        opacity: 0.7,
                      }}
                    >
                      Association Liégeoise <br />
                      de Football Amateur
                    </h1>
                  </div>
                </div>
              </Button>
              <List>
                {listItems.map((item, i) => {
                  let color = colors[i];
                  let userAccess = userInfos[item?.access];
                  let admin = userInfos["admin"];
                  let lightColor = Color(color).lightness(93).hex();
                  let isActive = item?.pathnames.includes(pathname);
                  let isOpen = false;
                  if (item?.sub) {
                    for (let el of item.sub) {
                      isOpen = isActive || el?.pathnames.includes(pathname);
                    }
                  }
                  let isLast = listItems.length - 1 === i;
                  if (item?.sub || admin || userAccess) {
                    return (
                      <div key={"listItem" + i}>
                        {admin || userAccess ? (
                          <ListItem
                            key={"listItem" + item?.name}
                            className={css`
                              width: 95%;
                              height: 48px;
                              padding: 10px;
                              border-radius: 0.75em;
                              margin: 0 auto 0.25em;
                              background-color: ${isActive && lightColor};
                              &:hover,
                              &:active,
                              &:focus {
                                background-color: ${isActive && lightColor};
                              }
                              & span,
                              & div {
                                font-weight: ${isActive ? "700" : "600"};
                                color: ${isActive ? color : "#00000060"};
                              }
                              & > div:first-child::before {
                                content: "";
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                border-radius: 50px;
                                top: 0;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                margin: auto;
                                background: ${isActive ? color : lightColor};
                              }
                              & svg {
                                position: relative;
                                margin: auto;
                                fill: ${isActive ? "white" : color};
                                font-size: 20px;
                                width: 20px;
                                height: 20px;
                              }
                            `}
                            button
                            component={React.forwardRef((props, ref) => (
                              <NavLink
                                to={{ pathname: item.route }}
                                {...props}
                                ref={ref}
                              />
                            ))}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.name} />
                          </ListItem>
                        ) : null}
                        {item?.sub?.map((el, _i) => {
                          let color = colors[i];
                          let lightColor = Color(color).lightness(93).hex();
                          let isActive = el?.pathnames.includes(pathname);
                          let userAccessSub = userInfos[el.access];
                          if (admin || userAccessSub) {
                            return (
                              <Collapse
                                in={true}
                                key={"collapse" + _i}
                                timeout="auto"
                                unmountOnExit
                                className={css`
                                  border-bottom: ${!isLast &&
                                  "1px solid rgba(0, 0, 0, 0.04)"};
                                  margin-bottom: 0.5em;
                                `}
                              >
                                <List component="div" disablePadding>
                                  <ListItem
                                    key={"listItem" + item?.name}
                                    className={css`
                                      width: 95%;
                                      height: 40px;
                                      padding: 10px;
                                      border-radius: 0.75em;
                                      margin: 0 auto 0.25em;
                                      background-color: ${isActive &&
                                      lightColor};
                                      &:hover,
                                      &:active,
                                      &:focus {
                                        background-color: ${isActive &&
                                        lightColor};
                                      }
                                      & span,
                                      & div {
                                        font-weight: ${isActive
                                          ? "700"
                                          : "600"};
                                        color: ${isActive
                                          ? color
                                          : "#00000060"};
                                      }
                                      & > div:first-child::before {
                                        content: "";
                                        position: absolute;
                                        width: 26px;
                                        height: 26px;
                                        border-radius: 50px;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        margin: auto;
                                        background: ${isActive
                                          ? color
                                          : lightColor};
                                      }
                                      & svg {
                                        position: relative;
                                        margin: auto;
                                        fill: ${isActive ? "white" : color};
                                        font-size: 18px;
                                        width: 18px;
                                        height: 18px;
                                      }
                                    `}
                                    button
                                    component={React.forwardRef(
                                      (props, ref) => (
                                        <NavLink
                                          to={{ pathname: el.route }}
                                          {...props}
                                          ref={ref}
                                        />
                                      )
                                    )}
                                  >
                                    <ListItemIcon
                                      className={classes.listItemIcon}
                                    >
                                      {el.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={el.name} />
                                  </ListItem>
                                </List>
                              </Collapse>
                            );
                          }
                        })}
                      </div>
                    );
                  }
                })}
              </List>
            </div>
            <div>
              <List>
                {userInfos?.admin ? (
                  <ListItem
                    button
                    className={css`
                      width: 95%;
                      height: 53px;
                      padding: 12px 10px;
                      border-radius: 1em;
                      margin: 0 auto 0.25em;
                      &:hover,
                      &:focus,
                      &:active {
                        color: black;
                      }
                      & span,
                      & div {
                        font-size: 15px;
                        font-weight: 600;
                      }
                      & > div:first-child::before {
                        content: "";
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        border-radius: 4em;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        background: #36a2fd40;
                      }
                      & svg {
                        border-radius: 5em;
                        width: 20px;
                        height: 20px;
                        position: relative;
                        margin: auto;
                        fill: #36a2fd;
                      }
                    `}
                    onClick={(e) => setAnchorRapports(e.currentTarget)}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Icon.PostAddOutlined />
                    </ListItemIcon>
                    <ListItemText
                      style={{ color: "#00000060" }}
                      primary={"Rapports"}
                    />
                  </ListItem>
                ) : null}
                {anchorRapports && renderMenuRapports()}
                {showDialog && renderAlertRapports()}
                {showDialogResetChampionnat && renderAlertResetChampionnat()}
                {showDialogCleanAffiliations && renderAlertCleanAffiliations()}
                <ListItem
                  button
                  className={css`
                    width: 95%;
                    height: 53px;
                    padding: 12px 10px;
                    border-radius: 1em;
                    margin: 0 auto 0.25em;
                    &:hover,
                    &:focus,
                    &:active {
                      color: black;
                    }
                    & span,
                    & div {
                      font-size: 15px;
                      font-weight: 600;
                    }
                    & > div:first-child::before {
                      content: "";
                      position: absolute;
                      width: 30px;
                      height: 30px;
                      border-radius: 4em;
                      top: 0;
                      bottom: 0;
                      left: 0;
                      right: 0;
                      margin: auto;
                      background: #99999940;
                    }
                    & svg {
                      border-radius: 5em;
                      width: 20px;
                      height: 20px;
                      position: relative;
                      margin: auto;
                      fill: #aaaaaa;
                    }
                  `}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    <Icon.Settings size={"small"} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "#00000060" }}
                    primary={userInfos?.admin ? "Administration" : "Paramètres"}
                  />
                </ListItem>
                {anchorEl && renderMenu()}
              </List>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className={
          drawerOpen
            ? classes.menuButton
            : clsx(classes.menuButton, classes.menuButtonDrawerClose)
        }
      >
        <IconButton
          edge="start"
          color="default"
          aria-label="open drawer"
          onClick={() => {
            setDrawerOpen(!drawerOpen);
          }}
        >
          <Icon.Menu />
        </IconButton>
      </div>
      <Drawer
        variant="permanent"
        elevation={0}
        className={
          drawerOpen
            ? classes.drawer
            : clsx(classes.drawerClose, classes.drawer)
        }
      >
        {list()}
      </Drawer>
    </>
  );
}

const useStylesAuth = makeStyles((theme) => ({
  appBar: {
    zIndex: 1200,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  menuItem: {
    color: "black!important",
    "&:hover span, &:focus span, &:active span": {
      color: "black!important",
    },
    "&:hover, &:focus, &:active": {
      color: "black!important",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function NavigationAuth(props) {
  const classes = useStylesAuth();
  return <DrawerNav {...props} />;
}

const useStylesNonAuth = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    color: "white",
    textDecoration: "none",
    marginLeft: theme.spacing(1),
  },
  navbar: {
    backgroundColor: "#ffffff",
  },
}));

function NavigationNonAuth(props) {
  const classes = useStylesNonAuth();
  return (
    <div>
      {/*<AppBar position="static" elevation={2}>
        <Toolbar className={classes.navbar}>
          <Typography variant="h6" className={classes.title}></Typography>
          <Link to={ROUTES.SIGN_IN} className={classes.link}>
            <Button variant="text" >
              Se connecter
            </Button>
          </Link>
          <Link to={ROUTES.SIGN_UP} className={classes.link}>
            <Button variant="contained" color="primary" >
              S'inscrire
            </Button>
            </Link>
        </Toolbar>
  </AppBar>*/}
    </div>
  );
}

function Navigation(props) {
  return localStorage.getItem("api_token") ? (
    <NavigationAuth {...props} />
  ) : (
    <NavigationNonAuth {...props} />
  );
}

export default withRouter(Navigation);
