export const HOME = "/";

export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";

//export const DASHBOARD = "/dashboard";
export const PASSWORD_FORGET = "/pw-forget";

export const EQUIPES = "/equipes";
export const EQUIPE = "/equipe";

export const TEAMS = "/teams";
export const TEAM = "/team";

export const MEMBRES = "/membres";
export const MEMBRE = "/membre";

export const AFFILIATIONS = "/affiliations";
export const AFFILIATION = "/affiliation";

export const CLUBS = "/clubs";
export const CLUB = "/club";

export const TERRAINS = "/terrains";
export const TERRAIN = "/terrain";

export const USERS = "/users";
export const USER = "/user";

export const CHAMPIONNATS = "/championnats";
export const CHAMPIONNAT = "/championnat";

export const CREATEUR_RENCONTRES = "/createur-rencontres";

export const RENCONTRES = "/rencontres";
export const RENCONTRE = "/rencontre";

export const SANCTIONS = "/sanctions";
export const SANCTION = "/sanction";

export const MY_ACCOUNT = "/my-account";

export const RAPPORTS = "/rapports";
