export const struct = (data) => {
  return {
    name: {
      name: "name",
      label: "Nom d'utilisateur",
      type: "text",

      value: data?.name,
    },
    email: {
      name: "email",
      value: data?.email,
      label: "Adresse email",
      type: "text"
    },
    new_password: {
      name: "new_password",
      label : "Nouveau mot de passe",
      type: "password",
      value: "",
            onChange: (e, data, setData, helperText, setHelperText) => {
        let value = e?.target?.value;
        if(value !== data?.repeat_new_password?.value) {
        setHelperText({
              ...helperText,
              repeat_new_password: "Les 2 mots de passe sont différents",
          });
        } else {
          setHelperText({
              ...helperText,
              repeat_new_password: undefined
          });
        }
          setData({
            ...data,
            new_password: {
              ...data["new_password"],
              value: value,
            },
          });
        }
    },
    repeat_new_password: {
      name: "repeat_new_password",
      label : "Répéter le mot de passe",
      type:"password",
      value: "",
                  onChange: (e, data, setData, helperText, setHelperText) => {
        let value = e?.target?.value;
        if(value !== data?.new_password?.value) {
        setHelperText({
              ...helperText,
              repeat_new_password: "Les 2 mots de passe sont différents",
          });
        } else {
          setHelperText({
              ...helperText,
              repeat_new_password: undefined
          });
        }
          setData({
            ...data,
            repeat_new_password: {
              ...data["repeat_new_password"],
              value: value,
            },
          });
        }
    }
  };
};

export const items = [["name", "email"], ["new_password", "repeat_new_password"]];

export const route = "user";
