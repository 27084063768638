import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as ROUTES from "../../constants/routes";

import { useStyles } from "../../theme";

import { struct, route, routeTable, items, getShortlists } from "./items";
import {
  structTable as structEquipes,
  columnsChild as columnsEquipes,
} from "../Equipes/items";
import {
  structTable as structAffiliations,
  columnsChild as columnsAffiliations,
} from "../Affiliations/items";
import {
  structTable as structTeams,
  columns as columnsTeams,
} from "../Teams/items";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
} from "../../components";
import Entities from "../Entities";
import qs from "qs";

const cardTitles = [
  "Dénomination",
  "Adresse",
  "Coordonnées",
  "Assurance",
  "Logo",
];

export default function Club(props) {
  const classes = useStyles();
  const { state } = props?.location;
const {userInfos} = props;
  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [clubName, setClubName] = useState(undefined);
  const [clubId, setClubId] = useState(undefined);

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  useEffect(() => {
    if (!loading && data && data?.equipes) {
      let club_name = data?.name?.value;
      let club_id = undefined;
      for (let equipe of data?.equipes) {
        club_id = equipe?.club_id;
      }
      setClubName(club_name);
      if (!id) setClubId(club_id);
      else setClubId(id);
    }
  });
  return (
    <div className={classes.root}>
      <CardHeader
        route={"club"}
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        id={id}
        data={data}
        editTitle={"Modifier le club"}
        addTitle={"Ajouter un club"}
        routeTable={routeTable}
        nextRoute={"club"}
        saveable={userInfos?.access_clubs_rights > 1 || userInfos?.admin}
      />
      {!loading && data && (
        <div className={classes.content}>
          <>
            <FormCards
              cardTitles={cardTitles}
              data={data}
              editable={userInfos?.access_clubs_rights > 1 || userInfos?.admin}
              items={items}
              setData={setData}
              getShortlists={getShortlists}
            />
            <CardActions
              routeTable={routeTable}
              route={route}
              data={data}
              id={id}
              isArchived={archived}
              setArchived={setArchived}
              archivable={userInfos?.access_clubs_rights > 2 || userInfos?.admin}
            />
          </>
          {data?.teams && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.TEAM}
              disableAdd
              items={{
                nameTable: "Teams associées",
                columns: columnsTeams,
                structTable: structTeams,
                routeTable: "teams",
                data: data?.teams,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
          {data?.equipes && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.EQUIPE}
              items={{
                nameTable: "Équipes associées",
                routeTable: "equipes",
                columns: columnsEquipes,
                structTable: structEquipes,
                data: data?.equipes,
                state: {
                  prevId: id,
                  prevRoute: route,
                  routeParam: "?club_id=" + clubId + "&club_name=" + clubName,
                },
              }}
            />
          )}
          {data?.affiliations && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.AFFILIATION}
              disableAdd
              items={{
                nameTable: "Affiliations associées",
                routeTable: "affiliations",
                columns: columnsAffiliations,
                structTable: structAffiliations,
                data: data.affiliations,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
