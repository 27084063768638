import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { compose } from "recompose";

import * as ROUTES from "../../constants/routes";

import { theme } from "../../theme";

import { withStyles } from "@material-ui/styles";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
};

const styles = (theme) => ({
  paper: {
    margin: "4em auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3em",
    maxWidth: 450,
    borderRadius: "1em",
  },
  avatar: {
    margin: "1em",
  },
  form: {
    width: "100%",
    marginTop: "0.5em",
  },
  submit: {
    margin: "1em 0 1em",
  },
});

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    /* const { email } = this.state; */
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";
    const { classes } = this.props;

    return (
      <Container component="main">
        <CssBaseline />
        <Paper className={classes.paper}>
          <div className={"text-center m-auto"}>
            <Avatar className={"m-auto"}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Réinitialiser mon mot de passe
            </Typography>
          </div>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              value={email}
              margin="normal"
              required
              onChange={this.onChange}
              fullWidth
              id="email"
              label="Adresse email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button
              disabled={isInvalid}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              className={classes.submit}
              onClick={this.onSubmit}
            >
              Réinitialiser
            </Button>
          </form>
        </Paper>
        {error && <p>{error.message}</p>}
        {/* <Box mt={8}>
        <Copyright />
      </Box> */}
      </Container>
    );
  }
}

const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Mot de passe oublié ?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(
  withRouter,
  withStyles(styles)
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
