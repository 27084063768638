import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Paper,
  Toolbar,
  Typography,
  TableContainer,
  TableHead,
  Select,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  MenuItem,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";

import * as Icon from "@material-ui/icons";

import { ReactComponent as SanctionIcon } from "../../ressources/judgment.svg";

import axios from "axios";
import toast from "react-hot-toast";

import { style } from "../../theme";

import moment from 'moment';
import 'moment/locale/fr';

import {
  struct,
  route,
  routeTable,
  items,
  getShortlists,
  itemsChampionnat,
} from "./items";

import { Autocomplete, createFilterOptions, Alert } from "@material-ui/lab";

import Loading from "../Loading";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
  SaveButton,
  StyledTab,
  StyledTabs,
  AlertDialog,
  SaveData,
  StoreData,
  padLeadingZeros,
  calculateAge,
  isEmpty,
  isIterable,
  TabPanel,
  checkDateFriday8pm,
  convertDate,
  translateStatus,
} from "../../components";
import qs from "qs";
import { setStatic } from "recompose";

const MAX_PLAYERS = 11;
const MAX_REMPLACANTS = 5;

const quality_label = [
  {
    label: "Entraîneur",
    quality: "E",
  },
  {
    label: "Commissaire au terrain",
    quality: "T",
  },
  {
    label: "Capitaine",
    quality: "C",
  },
  {
    label: "Délégué au terrain",
    quality: "D",
  },
  {
    label: "Joueur",
    quality: "J",
  },
  {
    label: "Remplaçant",
    quality: "R",
  },
];

const useStyles = makeStyles((theme) => ({
  ...style,
  title: {
    color: "#00000080",
    fontWeight: 600,
    marginBottom: ".5em",
  },
  tableCell: {
    padding: 0,
    borderRight: '1px solid rgba(224, 224, 224, 1)'
  },
  tableCellAutoComplete: {
    padding: "0px",
    borderRight: '1px solid rgba(224, 224, 224, 1)'
  },
  input: {
    "& input": {
      fontSize: "15px!important",
      padding: 0,
      border: 'none'
    },
    "& fieldset": {
      border: 'none!important'
    },
    "& .MuiSelect-outlined": {
      padding: "0px 20px 0px 0px!important"
    },
    "& .MuiAutocomplete-inputRoot": {
      padding: "2px 20px 2px 2px!important"
    }
  },
  toolbar: {
    backgroundColor: "#FFF",
    borderRadius: "1em 1em 0 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "1em 2em",
  },
  tableHeadCell: {
    color: "#00000080",
    fontWeight: 600,
    padding: "16px 24px",
    lineHeight: "1.5rem",
    backgroundColor: "#fbfbfbd1",
  },
  inputAutoComplete: {
    marginRight: ".75em",
    marginBottom: ".75em",
    minWidth: 200,
  },
  table_conf: {
    "& th, & td": {
      height: 60,
      fontSize: 14,
      padding: 15,
    },
    "& tr": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
    },
    "& tr:last-child": {
      border: "none",
    },
  },
}));

export const SanctionButton = withStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: "#f0bd24",
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: "#f0bd24",
    },
  },
}))((props) => (
  <Button variant="contained" color="primary"  {...props} />
));

function CustomHeadRender(props) {
  const { columnMeta, info, index, classes, width } = props;
  return (
    <TableCell
      className={classes.tableHeadCell}
      data-colindex={index}
      key={"customHeadCell" + index + columnMeta}
      style={{
        color: "#00000080",
        fontWeight: "600",
        lineHeight: "1.5rem",
        padding: 10,
        width: width ? width : "auto",
        minWidth: width ? width : "auto",
      }}
    >
      <CustomTooltip title={info}>
        <span>
          {columnMeta + " "} <br />
          <Icon.HelpRounded fontSize={"small"} />
        </span>
      </CustomTooltip>
    </TableCell>
  );
}

function ConfidentielRencontre(props) {
  const { classes, confidentielRencontre, setConfidentielRencontre } = props;
  return (
    <Paper style={{ borderRadius: "1em" }}>
      <Box>
        <table className={classes.table_conf}>
          <thead>
            <tr>
              <th style={{ width: 450 }}></th>
              <th style={{ width: 200 }}>Équipe à domicile</th>
              <th style={{ width: 200 }}>Équipe en déplacement</th>
            </tr>
          </thead>
          {/* 
          points_player: null,
          points_delegate: null,
          points_admin: null,
          points_cloackroom: null,
          points_field: null,
          */}
          <tbody>
            <tr>
              <td>
                <p>Attitude générale des joueurs à l'égard du referee</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visite?.points_player
                      ? confidentielRencontre?.team_visite.points_player
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visite: {
                        ...confidentielRencontre?.team_visite,
                        points_player: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visiteur?.points_player
                      ? confidentielRencontre?.team_visiteur.points_player
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visiteur: {
                        ...confidentielRencontre?.team_visiteur,
                        points_player: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Rôle du délégué (accueil, au terrain, après match)</p>
              </td>
              <td>
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visite?.points_delegate
                      ? confidentielRencontre?.team_visite.points_delegate
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visite: {
                        ...confidentielRencontre?.team_visite,
                        points_delegate: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Rôle administratif du délégué (feuille, cartes, ...)</p>
              </td>
              <td>
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visite?.points_admin
                      ? confidentielRencontre?.team_visite.points_admin
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visite: {
                        ...confidentielRencontre?.team_visite,
                        points_admin: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
              <td>
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visiteur?.points_admin
                      ? confidentielRencontre?.team_visiteur.points_admin
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visiteur: {
                        ...confidentielRencontre?.team_visiteur,
                        points_admin: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Etat du vestiaire de l'arbitre (propreté, chauffage, ...)</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visite?.points_cloackroom
                      ? confidentielRencontre?.team_visite.points_cloackroom
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visite: {
                        ...confidentielRencontre?.team_visite,
                        points_cloackroom: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Conformité du terrain (zones tracées, marquages, ...)</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielRencontre?.team_visite?.points_field
                      ? confidentielRencontre?.team_visite.points_field
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielRencontre({
                      ...confidentielRencontre,
                      team_visite: {
                        ...confidentielRencontre?.team_visite,
                        points_field: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

function ConfidentielArbitrage(props) {
  const {
    classes,
    confidentielArbitrage,
    setConfidentielArbitrage,
    visiteur,
  } = props;
  let key = visiteur ? "team_visiteur" : "team_visite";
  return (
    <Paper style={{ borderRadius: "1em" }}>
      <Box>
        <table className={classes.table_conf}>
          <thead>
            <tr>
              <th style={{ width: 450 }}>Personnalité de l'arbitre</th>
              <th style={{ width: 200 }}>Note (/10)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Apparence et présentation</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_look
                      ? confidentielArbitrage[key].points_look
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_look: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Condition physique</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_physical_condition
                      ? confidentielArbitrage[key].points_physical_condition
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_physical_condition: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Autorité</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_authority
                      ? confidentielArbitrage[key].points_authority
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_authority: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Comportement</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_behavior
                      ? confidentielArbitrage[key].points_behavior
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_behavior: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Talents de l'arbitre</strong>
              </td>{" "}
            </tr>
            <tr>
              <td>
                <p>Lois du jeu - Signalisation</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_game_laws
                      ? confidentielArbitrage[key].points_game_laws
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_game_laws: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p>Justesse des décisions</p>
              </td>
              <td>
                {" "}
                <TextField
                  style={{ width: "100px" }}
                  size={"small"}
                  inputProps={{ min: "0", max: "10", step: "1" }}
                  type={"number"}
                  variant="outlined"
                  value={
                    confidentielArbitrage[key]?.points_game_correctness
                      ? confidentielArbitrage[key].points_game_correctness
                      : null
                  }
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setConfidentielArbitrage({
                      ...confidentielArbitrage,
                      [key]: {
                        ...confidentielArbitrage[key],
                        points_game_correctness: value ? Number(value) : null,
                      },
                    });
                  }}
                  onInput={(e) => {
                    if (e.target.value > 10) {
                      e.target.value = 10;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

function BallonDor(props) {
  const {
    classes,
    ballonDor,
    setBallonDor,
    visiteur,
    filterOptions,
    equipe,
    division,
    eventDate,
    teamId,
    data,
  } = props;
  const [options, setOptions] = useState({});
  let key = visiteur ? "team_visiteur" : "team_visite";
  useEffect(() => {
    let options = equipe?.affiliations.map((el) => {
      return {
        label:
          el?.registration_number +
          " " +
          el?.membre?.name +
          " " +
          el?.membre?.first_name
        ,
        value: el,
      };
    });
    if (data) {
      let _ballonDor = ballonDor;
      for (let i in data) {
        _ballonDor = {
          ..._ballonDor,
          [key]: {
            ..._ballonDor[key],
            [i]: {
              ...data[i],
            },
          },
        };
      }
      setBallonDor(_ballonDor);
    }
    setOptions(options);
  }, []);
  return (
    <Paper style={{ borderRadius: "1em" }}>
      <Box>
        <table className={classes.table_conf}>
          <thead>
            <tr>
              <th style={{ width: 150 }}>Classement</th>
              <th style={{ width: 300 }}>Joueur</th>
            </tr>
          </thead>
          <tbody>
            {[0, 1, 2].map((i) => {
              let gold_points = 0;
              if (i === 2) {
                gold_points = 1;
              } else if (i === 1) {
                gold_points = 2;
              } else if (i === 0) {
                gold_points = 3;
              }
              return (
                <tr key={"player_" + i}>
                  <td>
                    <p>#{i + 1}</p>
                  </td>
                  <td>
                    <Autocomplete
                      autoComplete="current-password"
                      inputProps={{
                        autocomplete: 'new-password',
                        form: {
                          autocomplete: 'off',
                        },
                      }}
                      filterOptions={filterOptions}
                      options={options}
                      getOptionLabel={(option) =>
                        option?.label ? option.label : ""
                      }
                      getOptionSelected={(option, value) =>
                        option?.label === value?.label
                      }
                      value={{
                        label:
                          ballonDor[key] && ballonDor[key][i]?.membre_label
                            ? ballonDor[key][i]?.membre_label
                            : null,
                        value:
                          ballonDor[key] && ballonDor[key][i]?.membre_id
                            ? ballonDor[key][i]?.membre_id
                            : null,
                      }}
                      onChange={(e, value) => {
                        let membre_label = null;
                        if (value?.value) {
                          membre_label =
                            value?.value?.registration_number +
                            " " +
                            value?.value?.membre?.name +
                            " " +
                            value?.value?.membre?.first_name;
                        }
                        setBallonDor({
                          ...ballonDor,
                          [key]: {
                            ...ballonDor[key],
                            [i]: {
                              affiliation_id: value?.value?.id,
                              membre_id: value?.value?.membre_id,
                              gold_points: gold_points,
                              division: division,
                              equipe_id: value?.value?.equipe_id,
                              team_id: teamId,
                              event_date: eventDate,
                              membre_label: membre_label,
                            },
                          },
                        });
                      }}
                      renderOption={(option) =>
                        option?.label ? option.label : ""
                      }
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "off";
                        return (
                          <TextField
                            {...params}
                            name={"joueur"}
                            variant="outlined"
                            label={"Nom du joueur"}
                            size={"small"}
                            style={{ width: 300 }}
                            autoComplete="current-password"
                            inputProps={inputProps}
                          />
                        )
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </Paper>
  );
}

function FicheMatch(props) {
  const {
    classes,
    equipe,
    ficheMatch,
    filterOptions,
    visiteur,
    setFicheMatch,
    validated,
    rencontreId,
    data,
    setData,
    dateRencontre,
    forfaitGeneral,
    forfaitTeam1,
    forfaitTeam2
  } = props;

  function verifierSanctionJoueur(affiliationId, rencontreId, num, i) {
    if (!affiliationId) {
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}rencontre/${rencontreId}/affilie_suspended/${affiliationId}`, {
      headers: { authorization: localStorage.getItem("api_token") },
    })
      .then(response => {
        if (response?.data?.suspended) {
          // Logique en cas de succès (joueur suspendu)
          ficheMatch[num][i].was_suspended = true;
          setFicheMatch([...ficheMatch]);
          // Afficher la notification de suspension        
          toast((t) => (
            <div>
              <p>
                <b>Attention</b> : Ce joueur ne pouvait pas jouer cette rencontre (Suspendu du : {response.data.suspension_start} au : {response.data.suspension_end})
              </p>
              <Button size="small" color="primary" variant="contained" disableElevation={true} onClick={() => {
                toast.dismiss(t.id)
                if (num == 0) {
                  if (!(response.data?.team_1_forfeit?.checked)) {
                    forfaitTeam1();
                  } else {
                    forfaitGeneral();
                  }
                } else if (num == 1) {
                  if (!(response.data?.team_2_forfeit?.checked)) {
                    forfaitTeam2();
                  } else {
                    forfaitGeneral();
                  }
                }
              }}>Appliquer un forfait</Button>
              <Button size="small" style={{ marginLeft: 5 }} color="default" variant="contained" disableElevation={true} onClick={() => {
                toast.dismiss(t.id)
              }}>Ignorer</Button>
            </div>
          ), { duration: 10000, icon: "⚠️" });
        } else {

        }
      })
      .catch(error => {

      });
  }


  function onChangeAffilie(i, value) {
    console.log("change");
    let num = visiteur ? 1 : 0;
    ficheMatch[num][i].membre_id = value?.value?.membre_id;
    ficheMatch[num][i].membre_label =
      value?.value?.registration_number +
      " " +
      value?.value?.membre?.name +
      " " +
      value?.value?.membre?.first_name;
    ficheMatch[num][i].affiliation_id = value?.value?.id;

    // Réinitialiser l'état de suspension
    ficheMatch[num][i].was_suspended = false;

    // Vérifier et gérer la suspension du joueur
    if (value?.value?.id) {
      verifierSanctionJoueur(value.value.id, rencontreId, num, i);
    }

    let team_2_division = data?.team_2_division?.value;
    let team_1_division = data?.team_1_division?.value;
    let date_of_birth = value?.value?.membre?.date_of_birth;
    if (date_of_birth && dateRencontre) {
      let age = calculateAge(new Date(date_of_birth), dateRencontre);
      let dateConvert = convertDate(date_of_birth);

      if ((team_1_division.includes("Vétérans") && team_2_division.includes("Vétérans") && age < 35) ||
        (!(team_1_division.includes("Vétérans") && team_2_division.includes("Vétérans")) && age < 16)) {
        ficheMatch[num][i].young_veteran = "J";
        ficheMatch[num][i].age = age;
        ficheMatch[num][i].date_of_birth = dateConvert;
      } else {
        ficheMatch[num][i].young_veteran = null;
        ficheMatch[num][i].age = null;
        ficheMatch[num][i].date_of_birth = null;
      }
    }

    // Mise à jour de l'état global des feuilles de match
    setFicheMatch([...ficheMatch]);

    // Appel de la fonction qui vérifie et affiche une popup si nécessaire
    popupYoungVeterans();
  }


  function popupYoungVeterans() {
    let totalYoungVeterans = 0;
    if (ficheMatch && data) {
      let num = visiteur ? 1 : 0;
      for (let i in ficheMatch[num]) {
        if (ficheMatch[num][i].young_veteran) {
          totalYoungVeterans++;
        }
      }
      if (totalYoungVeterans >= 3) {
        toast("Attention : Il semblerait qu'il y ai " + totalYoungVeterans + " joueurs ayant un âge inférieur ou égal à 34 ans...", { duration: 3500, icon: "⚠️" });
      }
    }
  }


  useEffect(() => {
    popupYoungVeterans();
  }, [ficheMatch, visiteur]);
  return (
    <Paper className={"rencontre-datatable"} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="h6"
        >
          {equipe?.club?.name} (ID: {equipe?.id})
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table className={classes.table} size={"medium"}>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.tableHeadCell}
                style={{
                  width: 230,
                  minWidth: 230,
                }}
              >
                Fonction
              </TableCell>
              <TableCell
                className={classes.tableHeadCell}
                style={{
                  width: 350,
                  minWidth: 350,
                }}
              >
                Affilié
              </TableCell>
              <CustomHeadRender
                classes={classes}
                columnMeta={"Rpl"}
                index={2}
                width={50}
                info={"Remplacement - minute de la descente ou montée"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"CJ"}
                index={3}
                width={50}
                info={"Nombre de cartes jaunes (1 ou 2)"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"Buts"}
                index={4}
                width={50}
                info={"Nombre de buts"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"CR"}
                index={5}
                width={50}
                info={"Minute à laquelle est attribuée la CR"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"Bless"}
                index={6}
                width={50}
                info={"Minute de l'incident"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"D. bless."}
                index={7}
                width={225}
                info={"Description de l'incident"}
              />
              <CustomHeadRender
                classes={classes}
                columnMeta={"Adm"}
                width={50}
                index={8}
                info={
                  "I, A, IA, AI (absence de carte d'Identité ou/et d'affiliation)"
                }
              />
              {/*<CustomHeadRender
                classes={classes}
                columnMeta={"JV"}
                index={9}
                info={"Jeune Vétéran - TJ = < 30 ans, J = Entre 30 et 35 ans"}
              />*/}
            </TableRow>
          </TableHead>
          <TableBody>

            {ficheMatch[visiteur ? 1 : 0]?.map((el, i) => {
              let _label = quality_label.find((x) => x.quality === el?.quality)
                .label;
              if (_label === "Joueur") {
                _label += " n°" + (i - 3);
              }
              if (_label === "Remplaçant") {
                _label += " n°" + (i - 14);
              }
              let membre_id = el?.membre_id;
              let membre_label = "";
              if (el?.membre) {
                membre_label =
                  el?.equipe_id +
                  "" +
                  padLeadingZeros(
                    el?.affiliation?.affilie_id
                      ? Number(el?.affiliation?.affilie_id)
                      : 0,
                    3
                  ) +
                  " " +
                  el?.membre?.name +
                  " " +
                  el?.membre?.first_name;

              }
              return (
                <TableRow key={"player" + i} className={`${el?.young_veteran ? "young_veteran" : ""} ${el.was_suspended ? "ligne_suspendue" : ""}  ${el.was_suspended ? "ligne_suspendue" : ""}`}>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="0"
                    style={{ minWidth: 200, padding: "0px 8px" }}
                  >
                    {
                      el?.young_veteran
                        ? (
                          <>
                            {_label} - <b>{el?.age} ans</b> (<b>{el?.date_of_birth}</b>)
                          </>
                        )
                        : (_label)
                    }
                  </TableCell>
                  <TableCell className={classes.tableCellAutoComplete} data-colindex="1">
                    <Autocomplete
                      autoComplete={true}
                      autoHighlight={true}
                      className={classes.input}
                      size="small"
                      disabled={validated}
                      filterOptions={filterOptions}
                      options={equipe?.affiliations
                        .filter((el) => el.registration_number != null) // Exclure les éléments avec registration_number null
                        .map((el) => {
                          // Ici, vous ajoutez le console.log pour inspecter "el"
                          // Utilisation d'un opérateur ternaire pour ajuster la valeur de label selon que registration_number soit null ou non
                          const label = el.registration_number
                            ? `${el.registration_number} ${el.membre?.name} ${el.membre?.first_name}` // Si registration_number n'est pas null
                            : `${el.membre?.name} ${el.membre?.first_name}`; // Si registration_number est null

                          // Après avoir loggé "el", vous continuez en retournant l'objet attendu
                          return {
                            label: label,
                            value: el,
                          };
                        })}
                      getOptionLabel={(option) => option.label || ""}
                      getOptionSelected={(option, value) => option.label === value.label}
                      defaultValue={equipe?.affiliations
                        .filter((el) => el.membre_id === membre_id)
                        .map((el) => ({
                          label: el.registration_number
                            ? `${el.registration_number} ${el.membre?.name} ${el.membre?.first_name}`
                            : `${el.membre?.name} ${el.membre?.first_name}`,
                          value: el,
                        }))[0] || null}
                      onChange={(e, value) => {
                        onChangeAffilie(i, value);
                      }}
                      renderOption={(option) => option.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{ shrink: false }}
                          name={"joueur" + i}
                          variant="outlined"
                          size="small"
                          label=""
                          className={classes.input}
                          autoComplete="off" // Changé pour assurer la désactivation de l'auto-complétion du navigateur
                          disabled={validated}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="2"
                    style={{ minWidth: 50, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"rpl" + i}
                      style={{ width: "100%" }}
                      disabled={Boolean(i < 4 || validated) && true}
                      size={"small"}
                      type={"number"}
                      className={classes.input}
                      variant="outlined"
                      defaultValue={el?.replacement}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].replacement = Number(
                          e?.target?.value
                        );
                        setFicheMatch(ficheMatch);
                      }}
                      inputProps={{ min: "0", max: "120", step: "1" }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="3"
                    style={{ minWidth: 50, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"cj" + i}
                      className={classes.input}
                      disabled={Boolean(i < 4 || validated) && true}
                      style={{ width: "100%" }}
                      size={"small"}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].yellow_card = Number(
                          e?.target?.value
                        );
                        setFicheMatch(ficheMatch);
                      }}
                      onInput={(e) => {
                        if (e.target.value > 2) {
                          e.target.value = 2;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      inputProps={{ min: "0", max: "2", step: "1" }}
                      type={"number"}
                      variant="outlined"
                      defaultValue={el?.yellow_card}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="4"
                    style={{ minWidth: 50, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"buts" + i}
                      className={classes.input}
                      disabled={Boolean(i < 4 || validated) && true}
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].goals = Number(e?.target?.value);
                        setFicheMatch(ficheMatch);
                      }}
                      inputProps={{ min: "0", max: "50", step: "1" }}
                      onInput={(e) => {
                        if (e.target.value > 50) {
                          e.target.value = 50;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      size={"small"}
                      type={"number"}
                      variant="outlined"
                      defaultValue={el?.goals}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="5"
                    style={{ minWidth: 50, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"cr" + i}
                      className={classes.input}
                      style={{ width: "100%" }}
                      size={"small"}
                      type={"number"}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].red_card = Number(e?.target?.value);
                        setFicheMatch(ficheMatch);
                      }}
                      variant="outlined"
                      defaultValue={el?.red_card}
                      disabled={Boolean(validated && true)}
                      inputProps={{ min: "0", max: "120", step: "1" }}
                      onInput={(e) => {
                        if (e.target.value > 120) {
                          e.target.value = 120;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="6"
                    style={{ minWidth: 50, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"blesse" + i}
                      className={classes.input}
                      style={{ width: "100%" }}
                      disabled={Boolean(validated && true)}
                      size={"small"}
                      variant="outlined"
                      type={"number"}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].injury = Number(e?.target?.value);
                        setFicheMatch(ficheMatch);
                      }}
                      onInput={(e) => {
                        if (e.target.value > 120) {
                          e.target.value = 120;
                        }
                        if (e.target.value < 0) {
                          e.target.value = 0;
                        }
                      }}
                      inputProps={{ min: "0", max: "120", step: "1" }}
                      defaultValue={el?.injury}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="7"
                    style={{ minWidth: 150, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"desc_bless" + i}
                      style={{ width: "100%" }}
                      className={classes.input}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].injury_detail = e?.target?.value;
                        setFicheMatch(ficheMatch);
                      }}
                      size={"small"}
                      variant="outlined"
                      disabled={Boolean(validated && true)}
                      type={"text"}
                      defaultValue={el?.injury_detail}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    data-colindex="8"
                    style={{ minWidth: 65, padding: "0px 8px" }}
                  >
                    <TextField
                      name={"adm" + i}
                      label=""
                      select
                      style={{ width: "100%" }}
                      disabled={Boolean(validated && true)}
                      className={classes.input}
                      onChange={(e) => {
                        let num = visiteur ? 1 : 0;
                        ficheMatch[num][i].admin = e?.target?.value;
                        setFicheMatch(ficheMatch);
                      }}
                      size={"small"}
                      defaultValue={el?.admin ? el?.admin : ""}
                      variant="outlined"
                    >
                      <MenuItem value={null}>(vide)</MenuItem>
                      <MenuItem value="IA">IA</MenuItem>
                      <MenuItem value="I">I</MenuItem>
                      <MenuItem value="A">A</MenuItem>
                    </TextField>
                  </TableCell>
                  {/*<TableCell
                      classes={classes.tableCell}
                      data-colindex="9"
                      style={{ minWidth: 150, padding: "0px 8px" }}
                    >
                      <TextField
                        name={"young_veteran" + i}
                        label=""
                        select
                        style={{ width: "100%" }}
                        disabled={validated && true}
                        onChange={(e) => {
                          let num = visiteur ? 1 : 0;
                          ficheMatch[num][i].young_veteran = e?.target?.value;
                          setFicheMatch(ficheMatch);
                        }}
                        size={"small"}
                        defaultValue={el?.young_veteran ? el?.young_veteran : ""}
                        variant="outlined"
                      >
                        <MenuItem value={null}>(vide)</MenuItem>
                        <MenuItem value="TJ">{`< de 30 ans`}</MenuItem>
                        <MenuItem value="J">30 à 35 ans</MenuItem>
                      </TextField>
                    </TableCell>*/}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}



function createNewConfidentielRencontre(id) {
  let confidentielRencontreInit = {
    team_visite: {
      membre_id: null,
      rencontre_id: Number(id),
      team_id: null,
      visited: 1,
      points_player: null,
      points_delegate: null,
      points_admin: null,
      points_cloackroom: null,
      points_field: null,
    },
    team_visiteur: {
      membre_id: null,
      rencontre_id: Number(id),
      team_id: null,
      visited: 0,
      points_player: null,
      points_delegate: null,
      points_admin: null,
      points_cloackroom: null,
      points_field: null,
    },
  };
  return confidentielRencontreInit;
}

function createNewConfidentielArbitrage(id) {
  let confidentielArbitrage = {
    membre_id: null,
    rencontre_id: Number(id),
    team_id: null,
    points_look: null,
    points_physical_condition: null,
    points_authority: null,
    points_behavior: null,
    points_game_laws: null,
    points_game_correctness: null,
  };
  let confidentielArbitrageInit = {
    team_visite: {
      ...confidentielArbitrage,
    },
    team_visiteur: {
      ...confidentielArbitrage,
    },
  };
  return confidentielArbitrageInit;
}

function createNewFicheMatch(id, data) {
  let affilie = {
    rencontre_id: Number(id),
    equipe_id: null,
    team_id: null,
    membre_id: null,
    membre_label: null,
    affiliation_id: null,
    replacement: null,
    admin: null,
    yellow_card: null,
    goals: null,
    red_card: null,
    injury: null,
    injury_status: null,
    injury_detail: "",
    quality: "J",
    status: "A",
    young_veteran: null,
  };
  let ficheMatchInit = [[], []];
  for (let i = 0; i < 2; i++) {
    let equipe_id =
      i === 0 ? data?.equipe_visite?.id : data?.equipe_visiteur?.id;
    let team_id = i === 0 ? data?.team_visite?.id : data?.team_visiteur?.id;
    ficheMatchInit[i].push({
      ...affilie,
      equipe_id,
      team_id,
      quality: "E",
    });
    ficheMatchInit[i].push({
      ...affilie,
      equipe_id,
      team_id,
      quality: "T",
    });
    ficheMatchInit[i].push({
      ...affilie,
      equipe_id,
      team_id,
      quality: "C",
    });
    ficheMatchInit[i].push({
      ...affilie,
      equipe_id,
      team_id,
      quality: "D",
    });
  }
  for (let i = 0; i < MAX_PLAYERS; i++) {
    for (let j = 0; j < 2; j++) {
      let equipe_id =
        j === 0 ? data?.equipe_visite?.id : data?.equipe_visiteur?.id;
      let team_id = j === 0 ? data?.team_visite?.id : data?.team_visiteur?.id;
      ficheMatchInit[j].push({
        ...affilie,
        equipe_id,
        team_id,
        quality: "J",
      });
    }
  }
  for (let i = 0; i < MAX_REMPLACANTS; i++) {
    for (let j = 0; j < 2; j++) {
      let equipe_id =
        j === 0 ? data?.equipe_visite?.id : data?.equipe_visiteur?.id;
      let team_id = j === 0 ? data?.team_visite?.id : data?.team_visiteur?.id;
      ficheMatchInit[j].push({
        ...affilie,
        equipe_id,
        team_id,
        quality: "R",
      });
    }
  }
  return ficheMatchInit;
}

export default function Rencontre(props) {
  const classes = useStyles();

  const { state } = props?.location;
  const { userInfos } = props;

  const [tab, setTab] = useState(0);
  const [tabFiche, setTabFiche] = useState(0);
  const [tabConfArbitre, setTabConfArbitre] = useState(0);
  const [tabBallonDor, setTabBallonDor] = useState(0);
  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [ficheMatch, setFicheMatch] = useState([], []);
  const [confidentielArbitrage, setConfidentielArbitrage] = useState({});
  const [confidentielRencontre, setConfidentielRencontre] = useState({});
  const [ballonDor, setBallonDor] = useState({});
  const [suspended, setSuspended] = useState({});

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;

  //FICHE MATCH INIT
  useEffect(() => {

    if (!loading && data) {

      // Configuration initiale de ficheMatch
      const ficheMatchInit = [
        data?.feuilles_visites?.map(feuille => ({
          ...feuille
        })) || [],
        data?.feuilles_visiteurs?.map(feuille => ({
          ...feuille
        })) || []
      ];

      // Vérifiez si les valeurs des champs team_1_id et team_2_id sont définies et non nulles
      const hasValidTeam1Id = data?.team_1_id?.value?.value != null;
      const hasValidTeam2Id = data?.team_2_id?.value?.value != null;

      if (hasValidTeam1Id && hasValidTeam2Id) {
        // Si tous les sous-tableaux dans ficheMatchInit sont vides, réinitialisez la feuille de match
        if (ficheMatchInit.every(subArray => subArray.length === 0)) {
          resetGameSheet();
        } else {
          // Sinon, mettez à jour la fiche de match
          setFicheMatch(ficheMatchInit);
        }
      }

      // Autres configurations
      if (data?.match_date?.value) {
        const match_date = new Date(data.match_date.value);
        setFridayDate(checkDateFriday8pm(match_date));

        if (data?.match_type?.value == "championnat") {
          youngVeterans(data.match_date.value, ficheMatchInit, data);
        }
      }

      // Confidentiel rencontre
      setConfidentielRencontre({
        team_visite: data?.confidentiel_rencontre_visites || {},
        team_visiteur: data?.confidentiel_rencontre_visiteurs || {}
      });

      // Confidentiel arbitrage
      setConfidentielArbitrage({
        team_visite: data?.confidentiel_arbitrage_visites || {},
        team_visiteur: data?.confidentiel_arbitrage_visiteurs || {}
      });
    }
  }, [loading, data]);  // Simplification des dépendances



  const [validated, setValidated] = useState(false);
  const [reset, setReset] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogSanctions, setShowDialogSanctions] = useState(false);
  //const [showDialogSanctionsSave, setShowDialogSanctionsSave] = useState(false);
  //const [disabledSanctionButton, setDisabledSanctionButton] = useState(false);
  const [showDialogReset, setShowDialogReset] = useState(false);
  const [showDialogForfaitGeneral, setShowDialogForfaitGeneral] = useState(false);
  const [showDialogForfaitTeam1, setShowDialogForfaitTeam1] = useState(false);
  const [showDialogForfaitTeam2, setShowDialogForfaitTeam2] = useState(false);
  const [showDialogForfaitReset, setShowDialogForfaitReset] = useState(false);
  const [showDialogReported, setShowDialogReported] = useState(false);
  const [fridayDate, setFridayDate] = useState(undefined);
  const OPTIONS_LIMIT = 25;

  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };
  const handleChangeTabFiche = (e, newValue) => {
    setTabFiche(newValue);
  };
  const handleChangeTabConfArbitre = (e, newValue) => {
    setTabConfArbitre(newValue);
  };
  const handleChangeTabBallonDor = (e, newValue) => {
    setTabBallonDor(newValue);
  };
  useEffect(() => {
    fetchData(
      route,
      setData,
      struct,
      id,
      setLoading,
      setArchived,
      setValidated,
      //setShowDialogSanctionsSave
    );
  }, [id, setData, setLoading]);
  function reportedMatch() {
    if (id) {
      axios
        .get(
          process.env.REACT_APP_API_URL + `rencontre/${id}/match_reporte`,
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          toast.success(response?.data?.success, { duration: 4000 });
          setData({
            ...data,
            ['postponed_match']: {
              ...data.postponed_match,
              checked: true,
            }
          })
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error, { duration: 4000 });
        });
    }
  }
  function forfaitGeneral() {
    setData({
      ...data,
      ["team_1_score"]: {
        ...data["team_1_score"],
        value: undefined,
        disabled: true,
      },
      ["team_2_score"]: {
        ...data["team_2_score"],
        value: undefined,
        disabled: true,
      },
      ["team_1_forfeit"]: {
        ...data["team_1_forfeit"],
        checked: true,
      },
      ["team_2_forfeit"]: {
        ...data["team_2_forfeit"],
        checked: true,
      },
    });
  }

  function forfaitTeam1() {
    let team_1_score = data?.team_1_score?.value ? data?.team_1_score?.value : 0;
    let team_2_score = data?.team_2_score?.value ? data?.team_2_score?.value : 0;
    if (Number(team_2_score) < 5) {
      team_2_score = 5;
    }
    setData({
      ...data,
      ["team_1_score"]: {
        ...data["team_1_score"],
        value: team_1_score,
      },
      ["team_2_score"]: {
        ...data["team_2_score"],
        value: team_2_score,
      },
      ["team_1_forfeit"]: {
        ...data["team_1_forfeit"],
        checked: true,
      },
      ["team_2_forfeit"]: {
        ...data["team_2_forfeit"],
        checked: false,
      },
    });
  }

  function forfaitTeam2() {
    let team_1_score = data?.team_1_score?.value ? data?.team_1_score?.value : 0;
    let team_2_score = data?.team_2_score?.value ? data?.team_2_score?.value : 0;
    if (team_1_score < 5) {
      team_1_score = 5;
    }
    setData({
      ...data,
      ["team_2_score"]: {
        ...data["team_2_score"],
        value: team_2_score,
      },
      ["team_1_score"]: {
        ...data["team_1_score"],
        value: team_1_score,
      },
      ["team_2_forfeit"]: {
        ...data["team_2_forfeit"],
        checked: true,
      },
      ["team_1_forfeit"]: {
        ...data["team_1_forfeit"],
        checked: false,
      },
    });
  }

  function forfaitReset() {
    setData({
      ...data,
      ["team_2_score"]: {
        ...data["team_2_score"],
        value: null,
        disabled: false,
      },
      ["team_1_score"]: {
        ...data["team_1_score"],
        value: null,
        disabled: false,
      },
      ["team_2_forfeit"]: {
        ...data["team_2_forfeit"],
        checked: false,
      },
      ["team_1_forfeit"]: {
        ...data["team_1_forfeit"],
        checked: false,
      },
    });
  }

  function youngVeterans(dateRencontre, ficheMatch, data) {
    if (ficheMatch && dateRencontre && data && data.team_1_division && data.team_2_division) {

      let team_2_division = data.team_2_division.value;
      let team_1_division = data.team_1_division.value;

      for (let num in ficheMatch) {
        for (let i in ficheMatch[num]) {
          let membre = ficheMatch[num][i]?.membre;
          if (membre && membre.date_of_birth) {
            let date_of_birth = membre.date_of_birth;
            let age = calculateAge(date_of_birth, dateRencontre);
            let dateConvert = convertDate(date_of_birth);
            if (age) {
              if ((team_1_division.includes("Vétérans") && team_2_division.includes("Vétérans") && age < 35) ||
                (!(team_1_division.includes("Vétérans") && team_2_division.includes("Vétérans")) && age < 16)) {
                ficheMatch[num][i].young_veteran = "J";
                ficheMatch[num][i].age = age;
                ficheMatch[num][i].date_of_birth = dateConvert;
              } else {
                ficheMatch[num][i].young_veteran = null;
                ficheMatch[num][i].age = null;
                ficheMatch[num][i].date_of_birth = null;
              }
            }
          }
        }
      }
    }
    setFicheMatch([...ficheMatch]);
  }


  async function saveAllData(feuilles_match) {
    // 2 FEUILLES DE MATCH CONCATENEES
    // CONFIDENIEL DE RENCONTRE
    let confidentiels_rencontres = confidentielRencontre;
    confidentiels_rencontres = {
      ...confidentiels_rencontres,
      ["team_visite"]: {
        ...confidentiels_rencontres["team_visite"],
        team_id: data?.team_1_id?.value?.value
          ? data.team_1_id.value.value
          : null,
        membre_id: data?.referee_official?.value?.value
          ? data.referee_official.value.value
          : null,
      },
      ["team_visiteur"]: {
        ...confidentiels_rencontres["team_visiteur"],
        team_id: data?.team_2_id?.value?.value
          ? data.team_2_id.value.value
          : null,
        membre_id: data?.referee_official?.value?.value
          ? data.referee_official.value.value
          : null,
      },
    };
    // CONFIDENTIEL D'ARBITRAGE
    let confidentiels_arbitrages = confidentielArbitrage;
    confidentiels_arbitrages = {
      ...confidentiels_arbitrages,
      ["team_visite"]: {
        ...confidentiels_arbitrages["team_visite"],
        team_id: data?.team_1_id?.value?.value
          ? data.team_1_id.value.value
          : null,
        membre_id: ficheMatch[0][3]?.membre_id,
      },
      ["team_visiteur"]: {
        ...confidentiels_arbitrages["team_visiteur"],
        team_id: data?.team_2_id?.value?.value
          ? data.team_2_id.value.value
          : null,
        membre_id: ficheMatch[1][3]?.membre_id,
      },
    };
    if (!(ficheMatch[1][3]?.membre_id && ficheMatch[0][3]?.membre_id)) {
      toast.error(
        "Il faut impérativement des délégués au terrain pour enregistrer le confidentiel d'arbitrage...",
        4000
      );
    }
    //BALLON D'OR
    let ballons_team_visite = ballonDor?.team_visite
      ? Object.values(ballonDor?.team_visite)
      : {};
    let ballons_team_visiteur = ballonDor?.team_visiteur
      ? Object.values(ballonDor?.team_visiteur)
      : {};
    let ballons_or = [];
    if (isIterable(ballons_team_visite)) {
      ballons_or.push(...ballons_team_visite);
    }
    if (isIterable(ballons_team_visiteur)) {
      ballons_or.push(...ballons_team_visiteur);
    }
    // TOUTES LES DONNEES
    let _data = {
      ...data,
      feuilles_match,
      confidentiels_arbitrages,
      confidentiels_rencontres,
      ballons_or,
    };
    //showDialogSanctionsSave
    if (id) {
      await SaveData(
        route,
        routeTable,
        _data,
        id,
        //setShowDialogSanctionsSave
      );
    } else {
      await StoreData(
        route,
        routeTable,
        _data,
        //setShowDialogSanctionsSave
      );
    }
  }

  const customSave = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SaveButton
          onClick={() => {
            if (ficheMatch && ficheMatch[0] && ficheMatch[1]) {
              let feuilles_match = ficheMatch[0].concat(ficheMatch[1]);
              saveAllData(feuilles_match);
            }
          }}
        >
          Sauvegarder
        </SaveButton>
      </div>
    );
  };
  let team_1_score = data?.team_1_score?.value
    ? data?.team_1_score?.value
    : "-";
  let team_2_score = data?.team_2_score?.value
    ? data?.team_2_score?.value
    : "-";
  if (data?.status?.value) {
    team_1_score = translateStatus(data?.status?.value);
    team_2_score = translateStatus(data?.status?.value);
  }
  if (data?.team_1_forfeit?.checked) {
    team_1_score = "FF";
    team_2_score = data?.team_2_score?.value;
  }
  if (data?.team_2_forfeit?.checked) {
    team_1_score = data?.team_1_score?.value;
    team_2_score = "FF";
  }
  if (data?.team_1_forfeit?.checked && data?.team_2_forfeit?.checked) {
    team_1_score = "FF";
    team_2_score = "FF";
  }

  function resetGameSheet() {
    if (id) {
      // Définir reset sur true
      setReset(true);
  
      // Attendre une seconde
      setTimeout(() => {
        // Créer une nouvelle fiche de match
        let ficheMatchInit = createNewFicheMatch(id, data);
  
        // Mettre à jour l'état avec la nouvelle fiche de match
        setFicheMatch(ficheMatchInit);
  
        // Effectuer la requête pour supprimer les données liées à la rencontre
        axios
          .get(
            process.env.REACT_APP_API_URL + `rencontre/${id}/feuilles/delete`,
            {
              headers: { authorization: localStorage.getItem("api_token") },
            }
          )
          .then((response) => {
            // Réinitialiser reset sur false après la requête réussie
            setReset(false);
            toast.success(response?.data?.success, { duration: 4000 });
          })
          .catch((error) => {
            // Réinitialiser reset sur false en cas d'erreur
            setReset(false);
            toast.error(error?.response?.data?.error, { duration: 4000 });
          });
      }, 1000); // Délai d'une seconde
    }
  }

  function generateSanctions(ficheMatch) {
    if (id) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          `sanctions/generate/${id}`,
          { feuilles_match: ficheMatch },
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          localStorage.removeItem("rencontres_latest_update");
          localStorage.removeItem("sanctions_latest_update");
          toast.success(response?.data?.success ? response?.data?.success : "Les sanctions ont été générés !", { duration: 4000 });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.error ? error?.response?.data?.error : "Problème lors de la génération des sanctions", {
            duration: 4000,
          });
        });
    }
  }

  function validatedGameSheet() {
    if (id) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          `${route}/update/${id}/validated_game_sheet`,
          "",
          {
            headers: { authorization: localStorage.getItem("api_token") },
          }
        )
        .then((response) => {
          setValidated(true);
          localStorage.removeItem("rencontres_latest_update");
          toast.success("La fiche de match a été validée", { duration: 4000 });
        })
        .catch((error) => {
          toast.error("Problème lors de la validation de la fiche de match", {
            duration: 4000,
          });
        });
    }
  }

  function ReportedDate() {
    const [newMatchDate, setNewMatchDate] = useState(data?.match_date?.value ? data?.match_date?.value : null);
    return (
      <MuiPickersUtilsProvider
        locale={fr}
        utils={DateFnsUtils}
      >
        <KeyboardDatePicker
          variant="inline"
          autoOk="true"
          //className={classes.input}
          helperText={""}
          name={'date'}
          inputVariant="outlined"
          required={true}
          label="Date de match"
          format="dd/MM/yyyy"
          value={newMatchDate}
          onChange={(e) => {
            setNewMatchDate(e);
          }}
          KeyboardButtonProps={{
            "aria-label": 'Date de match',
          }}
        />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <div className={classes.root}>
      <CardHeader
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"rencontre"}
        id={id}
        item={"rencontre"}
        editTitle={"Modifier la rencontre"}
        addTitle={"Ajouter une rencontre"}
        data={data}
        routeTable={routeTable}
        customSave={id && customSave()}
        nextRoute={"rencontre"}
        saveable={userInfos?.access_rencontres_rights > 1 || userInfos?.admin}
      />
      {!loading && data ? (
        <div className={classes.content}>
          {data?.equipe_visiteur && data?.equipe_visite && (
            <div
              style={{
                padding: "2em 0 0",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  {data?.equipe_visite && (
                    <Paper
                      style={{
                        padding: 20,
                        fontSize: "x-large",
                        marginRight: 15,
                      }}
                      elevation={1}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <strong style={{ fontSize: 14 }}>
                          {data?.equipe_visite
                            ? " " +
                            data?.equipe_visite?.club?.name +
                            " - " +
                            data?.equipe_visite?.id +
                            (data?.team_visite?.index
                              ? " - " + data?.team_visite?.index
                              : "")
                            : ""}
                        </strong>
                        <strong style={{ fontSize: 25 }}>{team_1_score}</strong>
                      </div>
                      {(!data?.team_2_forfeit?.checked && !data?.team_1_forfeit?.checked) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="default"

                            startIcon={<Icon.PanTool />}
                            onClick={() => {
                              setShowDialogForfaitTeam1(true);
                            }}
                          >
                            Forfait
                          </Button>
                          <AlertDialog
                            showDialog={showDialogForfaitTeam1}
                            setShowDialog={setShowDialogForfaitTeam1}
                            dialogText={
                              "Êtes-vous sûr de vouloir appliquer un forfait à cette équipe ?"
                            }
                            dialogTitle={"Forfait"}
                            primary_action={"Appliquer"}
                            secondary_action={"Annuler"}
                            handlePrimaryAction={() => {
                              forfaitTeam1();
                              setShowDialogForfaitTeam1(false);
                            }}
                            handleSecondaryAction={() => {
                              setShowDialogForfaitTeam1(false);
                            }}
                          />
                        </div>
                      )}
                    </Paper>
                  )}
                </div>
                <div>
                  {!data?.team_1_score?.value && !data?.team_2_score?.value && !data?.team_2_forfeit?.checked && !data?.team_1_forfeit?.checked && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        width: '100%',
                        margin: 10
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          minWidth: 175,
                        }}

                        startIcon={<Icon.PanTool />}
                        onClick={() => {
                          setShowDialogForfaitGeneral(true);
                        }}
                      >
                        Double forfait
                      </Button>
                      <AlertDialog
                        showDialog={showDialogForfaitGeneral}
                        setShowDialog={setShowDialogForfaitGeneral}
                        dialogText={
                          "Êtes-vous sûr de vouloir appliquer un double forfait à cette rencontre ?"
                        }
                        dialogTitle={"Double forfait"}
                        primary_action={"Appliquer"}
                        secondary_action={"Annuler"}
                        handlePrimaryAction={() => {
                          forfaitGeneral();
                          setShowDialogForfaitGeneral(false);
                        }}
                        handleSecondaryAction={() => {
                          setShowDialogForfaitGeneral(false);
                        }}
                      />
                    </div>
                  )}
                  {!data?.team_1_score?.value && !data?.team_2_score?.value && !data?.team_2_forfeit?.checked && !data?.team_1_forfeit?.checked && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        width: '100%',
                        margin: 10
                      }}
                    >
                      {!(data?.postponed_match?.checked) &&
                        (<Button
                          variant="contained"
                          color="primary"
                          style={{
                            minWidth: 175,
                          }}

                          startIcon={<Icon.Replay />}
                          onClick={() => {
                            setShowDialogReported(true);
                          }}
                        >
                          Match reporté
                        </Button>)}
                      <AlertDialog
                        showDialog={showDialogReported}
                        setShowDialog={setShowDialogReported}
                        dialogText={'Voulez-vous reporter la rencontre ?'}
                        dialogTitle={"Reporter le match"}
                        primary_action={"Reporter"}
                        secondary_action={"Annuler"}
                        handlePrimaryAction={() => {
                          reportedMatch();
                          setShowDialogReported(false);
                        }}
                        handleSecondaryAction={() => {
                          setShowDialogReported(false);
                        }}
                      />
                    </div>
                  )}
                  {(data?.team_2_forfeit?.checked || data?.team_1_forfeit?.checked) &&
                    (<div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: '100%',
                        margin: 10
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          minWidth: 250,
                        }}

                        startIcon={<Icon.Undo />}
                        onClick={() => {
                          setShowDialogForfaitReset(true);
                        }}
                      >
                        Réinitialiser Forfait(s)
                      </Button>
                      <AlertDialog
                        showDialog={showDialogForfaitReset}
                        setShowDialog={setShowDialogForfaitReset}
                        dialogText={
                          "Êtes-vous sûr de vouloir réinitialiser le(s) forfait(s) pour cette rencontre ?"
                        }
                        dialogTitle={"Réinitialiser le(s) forfait(s)"}
                        primary_action={"Réinitialiser"}
                        secondary_action={"Annuler"}
                        handlePrimaryAction={() => {
                          forfaitReset();
                          setShowDialogForfaitReset(false);
                        }}
                        handleSecondaryAction={() => {
                          setShowDialogForfaitReset(false);
                        }}
                      />
                    </div>)}
                </div>
                <div
                  style={{
                    width: "50%",
                  }}
                >
                  {data?.equipe_visiteur && (
                    <Paper
                      style={{
                        padding: 20,
                        fontSize: "x-large",
                        marginLeft: 15,
                      }}
                      elevation={1}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <strong style={{ fontSize: 25 }}>{team_2_score}</strong>

                        <strong
                          style={{
                            fontSize: 14,
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {data?.equipe_visiteur
                            ? " " +
                            data?.equipe_visiteur?.club?.name +
                            " - " +
                            data?.equipe_visiteur?.id +
                            (data?.team_visiteur?.index
                              ? " - " + data?.team_visiteur?.index
                              : "")
                            : ""}
                        </strong>
                      </div>
                      {(!data?.team_2_forfeit?.checked && !data?.team_1_forfeit?.checked) && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="contained"
                            color="default"

                            startIcon={<Icon.PanTool />}
                            onClick={() => {
                              setShowDialogForfaitTeam2(true);
                            }}
                          >
                            Forfait
                          </Button>
                          <AlertDialog
                            showDialog={showDialogForfaitTeam2}
                            setShowDialog={setShowDialogForfaitTeam2}
                            dialogText={
                              "Êtes-vous sûr de vouloir appliquer un forfait à cette équipe ?"
                            }
                            dialogTitle={"Forfait"}
                            primary_action={"Appliquer"}
                            secondary_action={"Annuler"}
                            handlePrimaryAction={() => {
                              forfaitTeam2();
                              setShowDialogForfaitTeam2(false);
                            }}
                            handleSecondaryAction={() => {
                              setShowDialogForfaitTeam2(false);
                            }}
                          />
                        </div>
                      )}
                    </Paper>
                  )}
                </div>
              </div>
              {/*<div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1em",
              }}
            >
              <Paper
                elevation={1}
                style={{ padding: ".75em", fontSize: 13, fontWeight: 600 }}
                variant="outlined"
              >
                {data?.day_code?.value} {" - "}
                Jour n°{data?.day?.value}
              </Paper>
            </div>*/}
            </div>
          )}
          <FormCards
            editable={userInfos?.access_rencontres_rights > 1 || userInfos?.admin}
            cardTitles={
              id

                ? ["Rencontre", "Arbitre et score", "Feuille de match"]
                : ["Rencontre"]
            }
            data={data}
            items={
              data?.match_type?.value === "championnat"
                ? itemsChampionnat
                : items
            }
            setData={setData}
            getShortlists={getShortlists}
          />
          {id && data?.equipe_visiteur && data?.equipe_visite && (
            <StyledTabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              scrollButtons="auto"
              style={{
                marginBottom: "2em",
                borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
              }}
            >
              <StyledTab locked={Boolean(validated) ? 1 : 0} label="Fiche de match" />
              <StyledTab locked={0} label="Confidentiel de rencontre" />
              <StyledTab locked={0} label="Confidentiel d'arbitrage" />
              {data?.match_type?.value === "championnat" &&
                (data?.team_1_division?.value === "Vétérans Honneur" ||
                  data?.team_2_division?.value === "Vétérans Honneur" ||
                  data?.team_1_division?.value === "Dimanche Honneur" ||
                  data?.team_2_division?.value === "Dimanche Honneur") && (
                  <StyledTab locked={0} label="Ballon d'or" />
                )}
            </StyledTabs>
          )}
          <TabPanel value={tab} index={0}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {!validated && (
                <div>
                  <AlertDialog
                    showDialog={showDialog}
                    setShowDialog={setShowDialog}
                    dialogText={
                      "Êtes-vous sûr de vouloir verrouiller cette feuille de match ?"
                    }
                    dialogTitle={"Verrouiller la feuille de match"}
                    primary_action={"Verrouiller"}
                    secondary_action={"Annuler"}
                    handlePrimaryAction={() => {
                      if (ficheMatch && ficheMatch[0] && ficheMatch[1]) {
                        let feuilles_match = ficheMatch[0].concat(
                          ficheMatch[1]
                        );
                        saveAllData(feuilles_match).then(() => {
                          validatedGameSheet();
                        });
                      }
                      setShowDialog(false);
                    }}
                    handleSecondaryAction={() => setShowDialog(false)}
                  />
                  <AlertDialog
                    showDialog={showDialogReset}
                    setShowDialog={setShowDialogReset}
                    dialogText={
                      "Êtes-vous sûr de vouloir réinitialiser cette feuille de match ?"
                    }
                    dialogTitle={"Réinitialiser la feuille de match"}
                    primary_action={"Réinitialiser"}
                    secondary_action={"Annuler"}
                    handlePrimaryAction={() => {
                      resetGameSheet();
                      setShowDialogReset(false);
                    }}
                    handleSecondaryAction={() => setShowDialogReset(false)}
                  />
                  <AlertDialog
                    showDialog={showDialogSanctions}
                    setShowDialog={setShowDialogSanctions}
                    dialogText={
                      "Êtes-vous sûr de vouloir générer les sanctions ?"
                    }
                    dialogTitle={"Générer les sanctions"}
                    primary_action={"Générer"}
                    secondary_action={"Annuler"}
                    handlePrimaryAction={() => {
                      if (ficheMatch && ficheMatch[0] && ficheMatch[1]) {
                        let feuilles_match = ficheMatch[0].concat(ficheMatch[1]);
                        generateSanctions(feuilles_match);
                      }
                      setShowDialogSanctions(false);
                    }}
                    handleSecondaryAction={() => setShowDialogSanctions(false)}
                  />
                  {/*<AlertDialog
                    showDialog={Boolean(showDialogSanctionsSave)}
                    setShowDialog={setShowDialogSanctionsSave}
                    dialogText={
                      "Les sanctions n'ont pas été générée... Voulez-vous les générer ?"
                    }
                    dialogTitle={"Générer les sanctions"}
                    primary_action={"Générer"}
                    secondary_action={"Annuler"}
                    handlePrimaryAction={() => {
                      if (ficheMatch && ficheMatch[0] && ficheMatch[1]) {
                            let feuilles_match = ficheMatch[0].concat(ficheMatch[1]);
                            generateSanctions(feuilles_match);            
                      }
                      setShowDialogSanctionsSave(false);
                    }}
                    handleSecondaryAction={() => setShowDialogSanctionsSave(false)}
                  />*/}
                </div>
              )}
            </div>
            {id && data?.equipe_visiteur && data?.equipe_visite && (
              <StyledTabs
                value={tabFiche}
                onChange={handleChangeTabFiche}
                indicatorColor="primary"
                scrollButtons="auto"
                style={{
                  marginBottom: "2em",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                }}
              >
                <StyledTab label="Équipe à domicile" />
                <StyledTab label="Équipe en déplacement" />
                {Boolean(!validated) && (
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}>
                    <Button
                      variant="outlined"
                      color="primary"

                      onClick={() => {
                        setShowDialogReset(true);
                      }}
                      style={{
                        margin: ".75em",
                      }}
                      startIcon={<Icon.Refresh />}
                    >
                      Réinitialiser
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"

                      onClick={() => {
                        setShowDialog(true);
                      }}
                      style={{
                        margin: ".75em",
                      }}
                      startIcon={<Icon.Lock />}
                    >
                      Verrouiller
                    </Button>
                    {/*<CustomTooltip title={"Les sanctions peuvent être générées jusqu'à "+ moment(fridayDate ? fridayDate : new Date()).format('dddd DD MMMM YYYY [à] HH[h]mm')}>*/}
                    <span>
                      <SanctionButton
                        //disabled={Boolean(new Date() > fridayDate)}
                        disabled={data.penalties_generated === 1}
                        color="default"
                        variant="contained"
                        onClick={() => {
                          setShowDialogSanctions(true);
                        }}
                        style={{
                          margin: ".75em",
                        }}
                        startIcon={
                          <SanctionIcon
                            style={{
                              fill: "white",
                              width: 20,
                            }}
                          />
                        }
                      >
                        Sanctions
                      </SanctionButton>
                    </span>
                    {/*</CustomTooltip>*/}
                  </div>
                )}
              </StyledTabs>
            )}
            {data?.equipe_visite?.affiliations &&
              data?.equipe_visiteur?.affiliations &&
              !reset && (
                <div>
                  <TabPanel value={tabFiche} index={0}>
                    <div>
                      <FicheMatch
                        equipe={data?.equipe_visite}
                        visiteur={false}
                        classes={classes}
                        ficheMatch={ficheMatch}
                        setFicheMatch={setFicheMatch}
                        filterOptions={filterOptions}
                        validated={validated}
                        rencontreId={id}
                        data={data}
                        setData={setData}
                        dateRencontre={data?.match_date?.value}
                        forfaitGeneral={forfaitGeneral}
                        forfaitTeam1={forfaitTeam1}
                        forfaitTeam2={forfaitTeam2}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={tabFiche} index={1}>
                    <div>
                      <FicheMatch
                        equipe={data?.equipe_visiteur}
                        visiteur={true}
                        classes={classes}
                        ficheMatch={ficheMatch}
                        setFicheMatch={setFicheMatch}
                        filterOptions={filterOptions}
                        validated={validated}
                        rencontreId={id}
                        data={data}
                        setData={setData}
                        dateRencontre={data?.match_date?.value}
                        forfaitGeneral={forfaitGeneral}
                        forfaitTeam1={forfaitTeam1}
                        forfaitTeam2={forfaitTeam2}
                      />
                    </div>
                  </TabPanel>
                </div>
              )}
            {reset && <CircularProgress />}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <div>
              <ConfidentielRencontre
                classes={classes}
                confidentielRencontre={confidentielRencontre}
                setConfidentielRencontre={setConfidentielRencontre}
              />
            </div>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <div>
              {id && data?.equipe_visiteur && data?.equipe_visite && (
                <StyledTabs
                  value={tabConfArbitre}
                  onChange={handleChangeTabConfArbitre}
                  indicatorColor="primary"
                  scrollButtons="auto"
                  style={{
                    marginBottom: "2em",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                  }}
                >
                  <StyledTab label="Équipe à domicile" />
                  <StyledTab label="Équipe en déplacement" />
                </StyledTabs>
              )}
            </div>
            <TabPanel value={tabConfArbitre} index={0}>
              <ConfidentielArbitrage
                classes={classes}
                confidentielArbitrage={confidentielArbitrage}
                setConfidentielArbitrage={setConfidentielArbitrage}
                visiteur={false}
              />
            </TabPanel>
            <TabPanel value={tabConfArbitre} index={1}>
              <ConfidentielArbitrage
                classes={classes}
                confidentielArbitrage={confidentielArbitrage}
                setConfidentielArbitrage={setConfidentielArbitrage}
                visiteur={true}
              />
            </TabPanel>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <div>
              {id && data?.team_1_name && data?.team_2_name && (
                <StyledTabs
                  value={tabBallonDor}
                  onChange={handleChangeTabBallonDor}
                  indicatorColor="primary"
                  scrollButtons="auto"
                  style={{
                    marginBottom: "2em",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                  }}
                >
                  <StyledTab label="Équipe à domicile" />
                  <StyledTab label="Équipe en déplacement" />
                </StyledTabs>
              )}
              <TabPanel value={tabBallonDor} index={0}>
                <BallonDor
                  classes={classes}
                  ballonDor={ballonDor}
                  setBallonDor={setBallonDor}
                  visiteur={false}
                  filterOptions={filterOptions}
                  equipe={data?.equipe_visiteur}
                  division={data?.team_2_division?.value}
                  eventDate={data?.match_date?.value}
                  teamId={data?.team_2_id?.value?.value}
                  data={data?.ballon_or_visites}
                />
              </TabPanel>
              <TabPanel value={tabBallonDor} index={1}>
                <BallonDor
                  classes={classes}
                  ballonDor={ballonDor}
                  setBallonDor={setBallonDor}
                  visiteur={true}
                  filterOptions={filterOptions}
                  equipe={data?.equipe_visite}
                  division={data?.team_1_division?.value}
                  eventDate={data?.match_date?.value}
                  teamId={data?.team_1_id?.value?.value}
                  data={data?.ballon_or_visiteurs}
                />
              </TabPanel>
            </div>
          </TabPanel>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CardActions
              routeTable={routeTable}
              route={route}
              data={data}
              id={id}
              isArchived={archived}
              setArchived={setArchived}
              archivable={userInfos?.access_rencontres_rights > 2 || userInfos?.admin}
            />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
  },
})(Tooltip);
