import React, { useState, useEffect } from "react";
import { villes as v } from "../../constants/villes";
import axios from "axios";

import { CustomLinkButton, CustomArchivingButton } from "../../components";


export const route = "club";
export const routeTable = "clubs";
export const nameTable = "Clubs";


export async function getShortlists(setOptions) {
  let villes = v.map((v) => {
    return { label: v.column_2, value: v.column_2, cp: v.column_1 };
  });
  setOptions({
    address_city: villes,
  });
}

export const struct = (data) => {
  let _affiliations = [];
  let _teams = [];
  if (data?.affiliations) {
    console.log(data?.affiliations);
    for (let i in data?.affiliations) {
      _affiliations.push(data?.affiliations[i]);
    }
  }
  if (data?.equipes) {
    for (let equipe of data?.equipes) {
      if (equipe?.teams) {
        for (let i in equipe?.teams) {
          _teams.push(equipe?.teams[i]);
        }
      }
    }
  }
  return {
    logo: {
      label: "Logo",
      type: "image",
      value: data?.logo,
    },
    color: {
      label: "Couleur",
      type: "color",
      value: data?.color,
    },
    prefix: {
      label: "Préfixe",
      type: "text",

      value: data?.prefix,
    },
    name: {
      label: "Nom du club",
      type: "text",

      value: data?.name,
    },
    usual_name: {
      label: "Nom usuel",
      type: "text",

      value: data?.usual_name,
    },
    address_city: {
      label: "Ville",
      type: "autocomplete",
      value: {
        label: data?.address_city,
        value: data?.address_city,
      },
      onChange: (value, data, setData) => {
        setData({
          ...data,
          address_city: {
            ...data["address_city"],
            value: value,
          },
          address_postal_code: {
            ...data["address_postal_code"],
            value: value?.cp ? value.cp : null,
          },
        });
      },
    },
    address_street: {
      label: "Rue",
      type: "text",

      value: data?.address_street,
    },
    address_number: {
      label: "Numéro",
      type: "text",
      value: data?.address_number,
    },
    address_postal_code: {
      label: "Code Postal",
      type: "number",

      value: data?.address_postal_code,
    },
    phone_mobile: {
      label: "Téléphone mobile",
      type: "text",

      value: data?.phone_mobile,
    },
    phone_fix: {
      label: "Téléphone fixe",
      type: "text",

      value: data?.phone_fix,
    },
    email: {
      label: "Email",
      type: "email",

      value: data?.email,
    },
    website: {
      label: "Site internet",
      type: "text",

      value: data?.website,
    },
    insurance: {
      label: "Assureur",
      type: "select",
      value: data?.insurance,
      values: [
        {
          value: "Fédération",
          label: "Fédération",
        },
        {
          value: "Autre",
          label: "Autre",
        },
      ],
    },
    insurance_ok: {
      label: "En ordre d'assurance",
      checked: Boolean(data?.insurance_ok),
      type: "checkbox",
    },
    certificates: {
      label: "Nb. d'attestations rentrée(s)",
      type: "number",
      value: data?.certificates,
    },
    various: {
      label: "Divers",
      type: "text",
      value: data?.various,
    },
    equipes: data?.equipes,
    teams: _teams,
    affiliations: _affiliations,
  };
};

export const items = [
  ["prefix", "name", "usual_name"],
  ["address_city", "address_street", "address_number", "address_postal_code"],
  ["phone_fix", "phone_mobile", "email", "website"],
  ["insurance", "certificates", "various", "insurance_ok"],
  ["logo", "color"],
  ["equipes"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "prefix",
    label: "Préfixe",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nom du club",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "usual_name",
    label: "Nom usuel",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address",
    label: "Adresse",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "phone_mobile",
    label: "Numéro de téléphone",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "phone_fix",
    label: "Numéro fixe",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "affiliations_club_count",
    label: "affiliations_club_count",
    options: {
      filter: false,
      sort: false,
      display: false,
    },
  },
  {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const columnsChild = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "prefix",
    label: "Préfixe",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nom du club",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "usual_name",
    label: "Nom usuel",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address",
    label: "Adresse",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "phone_mobile",
    label: "Numéro de téléphone",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "phone_fix",
    label: "Numéro fixe",
    options: {
      filter: false,
      sort: false,
    },
  },
    {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const structTable = (el) => {
  let address_street = el?.address_street ? el?.address_street + " " : "";
  let address_number = el?.address_number ? el?.address_number + " " : " ";
  let address_city = el?.address_city ? el?.address_city + " " : " ";
  let address_postal_code =
    el?.address_postal_code + " " ? el?.address_postal_code + " " : " ";
  let address =
    address_street + address_number + address_city + address_postal_code;
  let affiliations_club_count = JSON.stringify(el?.affiliations_club_count);
  return {
    id: el?.id,
    archived: el?.archived ? "Archive" : "Actif",
    prefix: el?.prefix,
    usual_name: el?.usual_name,
    name: el?.name,
    address: address,
    phone_fix: el?.phone_fix,
    phone_mobile: el?.phone_mobile,
    affiliations_club_count: affiliations_club_count,
  };
};