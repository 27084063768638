import React from "react";
import { Redirect } from "react-router-dom";
import Status from "../Status";
import * as ROUTES from "../../constants/routes";

function NotFound() {
  return (
    <Status code={404}>
      <Redirect to={ROUTES.HOME} />
    </Status>
  );
}

export default NotFound;
