import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as ROUTES from "../../constants/routes";

import {  useStyles } from "../../theme";

import { struct, route, items } from "./items";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
  FormCard,
} from "../../components";
import qs from "qs";

export default function Member(props) {
  const classes = useStyles();
  const { state } = props?.location;
const {userInfos} = props;

  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [loading, setLoading] = useState(true);

  let id = userInfos?.id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  return (
    <div className={classes.root}>
      <CardHeader
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"account"}
        id={id}
        item={"user"}
        data={data}
        saveable={true}
        title={"Mon profil"}
        routeTable={"account"}
      />
      {!loading && (
         <div className={classes.content}>
          <FormCards
            editable={true}
            cardTitles={["Dénomination","Mot de passe"]}
            data={data}
            items={items}
            setData={setData}
          />
          <CardActions
            route={route}
            data={data}
            id={id}
            archivable={false}
          />
        </div>
      )}
    </div>
  );
}
