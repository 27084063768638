import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { Entitie, EntitieLoader, getDataEntitie } from "../../components";

export default function Entities(props) {
  const {
    structTable,
    routeTable,
    columns,
    nameTable,
    data,
    state,
    filterList,
    defaultSort
  } = props.items;
  const { isSearchable, isSub, child_route, disableAdd, isExpandable, userInfos} = props;
  const [rows, setRows] = useState(undefined);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!data) {
      getDataEntitie(setRows, setLoading, routeTable, structTable);
    } else {
      let rows = [];
      for (let el of data) {
        rows.push(structTable(el));
      }
      setRows(rows);
      setLoading(false);
    }
  }, [routeTable, structTable]);
  if (localStorage.getItem("api_token")) {
    return (
      <Entitie
        name={nameTable}
        rows={rows}
        columns={columns}
        child_route={child_route}
        isSearchable={isSearchable}
        isExpandable={isExpandable}
        filterList={filterList}
        disableAdd={disableAdd}
        isSub={isSub}
        state={state}
        isLoading={loading}
        userInfos={userInfos}
        setRows={setRows}
        setLoading={setLoading}
        route={routeTable}
        struct={structTable}
        sortOrder={defaultSort}
      />
    );
  }
  return <Redirect to={ROUTES.HOME} />;
}
