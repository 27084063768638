import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import toast from "react-hot-toast";

import Grid from "@material-ui/core/Grid";
import * as Icon from "@material-ui/icons";
import Button from "@material-ui/core/Button";

import * as ROUTES from "../../constants/routes";
import { css } from "@emotion/css";

import { useStyles } from "../../theme";

import { struct, route, routeTable, items, getShortlists } from "./items";

import {
  structTable as structTerrains,
  columns as columnsTerrains,
} from "../Terrains/items";
import {
  structTable as structTeams,
  columnsChild as columnsTeams,
} from "../Teams/items";
import {
  structTable as structEquipes,
  columns as columnsEquipes,
} from "../Equipes/items";

import Loading from "../Loading";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
  AlertDialog,
} from "../../components";
import Entities from "../Entities";
import qs from "qs";

async function forfaitGeneral(id) {
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `team/forfait_general/${id}`, "", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        toast.success(r?.data?.success, {
          duration: 4000,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Problème lors de l'application du forfait général", {
          duration: 4000,
        });
      });
  }
}

async function reactiverTeam(id) {
  if (id) {
    await axios
      .post(process.env.REACT_APP_API_URL + `team/reactiver_team/${id}`, "", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        toast.success(r?.data?.success, {
          duration: 4000,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Problème lors de la réactivation de la team...", {
          duration: 4000,
        });
      });
  }
}

export default function Member(props) {
  const classes = useStyles();

  const { state } = props?.location;
const {userInfos} = props;

  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [equipeInfos, setEquipeInfos] = useState(undefined);
  const [showDialogForfaitGeneral, setShowDialogForfaitGeneral] = useState(
    false
  );
  const [showDialogReactiverTeam, setShowDialogReactiverTeam] = useState(
    false
  );
  const [loading, setLoading] = useState(true);
  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  let club_name = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .club_name;
  let equipe_id = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .equipe_id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  useEffect(() => {
    if (!loading) {
      if (id && data) {
        setEquipeInfos(
          data?.equipe[0]?.club?.name +
            " - " +
            data?.equipe[0]?.id +
            (data?.index?.value ? " - " + data?.index?.value : "")
        );
      } else {
        if (club_name && equipe_id)
          setEquipeInfos(club_name + " (" + equipe_id + ")");
      }
    }
  });
  return (
    <div className={classes.root}>
      <CardHeader
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"team"}
        id={id}
        item={"team"}
        editTitle={
          !loading && equipeInfos
            ? "Modifier la team de " + equipeInfos
            : !loading
            ? "Modifier la team"
            : ""
        }
        addTitle={
          !loading && equipeInfos
            ? "Ajouter une team à " + equipeInfos
            : !loading
            ? "Ajouter une team"
            : ""
        }
        data={data}
        routeTable={routeTable}
        nextRoute={"team"}
        saveable={userInfos?.access_teams_rights > 1 || userInfos?.admin}
      />

      {!loading && data ? (
        <div className={classes.content}>
          <FormCards
            editable={userInfos?.access_teams_rights > 1 || userInfos?.admin}
            cardTitles={["Dénomination"]}
            data={data}
            items={items}
            setData={setData}
            getShortlists={getShortlists}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <CardActions
              routeTable={routeTable}
              route={route}
              data={data}
              id={id}
              isArchived={archived}
              setArchived={setArchived}
              archivable={userInfos?.access_teams_rights > 2 || userInfos?.admin}
            />
            {id && !data?.general_give_up?.checked && (
              <Button
                color="primary"
                variant="contained"
                
                disabled={data?.general_give_up?.checked}
                startIcon={<Icon.PanTool />}
                className={css`
                  svg {
                    font-size: 15px !important;
                  }
                  &:hover,
                  &:active,
                  &:focus {
                    color: #ffffff !important;
                  }
                `}
                onClick={() => {
                  setShowDialogForfaitGeneral(true);
                }}
              >
                Forfait Général
              </Button>
            )}
            {id && data?.general_give_up?.checked && (
              <Button
                color="primary"
                variant="contained"
                startIcon={<Icon.Replay />}
                className={css`
                  svg {
                    font-size: 15px !important;
                  }
                  &:hover,
                  &:active,
                  &:focus {
                    color: #ffffff !important;
                  }
                `}
                onClick={() => {
                  setShowDialogReactiverTeam(true);
                }}
              >
                Réactiver
              </Button>
            )}
          </div>
          <AlertDialog
            showDialog={showDialogForfaitGeneral}
            setShowDialog={setShowDialogForfaitGeneral}
            dialogText={
              "Êtes-vous sûr de vouloir appliquer un forfait général à cette team ?"
            }
            dialogTitle={"Forfait général"}
            primary_action={"Appliquer"}
            secondary_action={"Annuler"}
            handlePrimaryAction={() => {
              forfaitGeneral(id);
              setShowDialogForfaitGeneral(false);
            }}
            handleSecondaryAction={() => {
              setShowDialogForfaitGeneral(false);
            }}
          />
          <AlertDialog
            showDialog={showDialogReactiverTeam}
            setShowDialog={setShowDialogReactiverTeam}
            dialogText={
              "Êtes-vous sûr de vouloir réactiver cette team ?"
            }
            dialogTitle={"Réactiver la team"}
            primary_action={"Réactiver"}
            secondary_action={"Annuler"}
            handlePrimaryAction={() => {
              reactiverTeam(id);
              setShowDialogReactiverTeam(false);
            }}
            handleSecondaryAction={() => {
              setShowDialogReactiverTeam(false);
            }}
          />
          {data?.equipe && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.EQUIPE}
              disableAdd
              items={{
                nameTable: "Équipe associée",
                columns: columnsEquipes,
                structTable: structEquipes,
                data: data?.equipe,
                routeTable: "equipes",
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
          {data?.teams && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.TEAM}
              disableAdd
              items={{
                nameTable: "Teams associées",
                columns: columnsTeams,
                structTable: structTeams,
                data: data.teams,
                routeTable: "teams",

                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
          {data?.terrain && id && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.TERRAIN}
              disableAdd
              items={{
                nameTable: "Terrain associé",
                columns: columnsTerrains,
                routeTable: "terrains",
                structTable: structTerrains,
                data: data?.terrain,
                state: {
                  prevId: id,
                  prevRoute: route,
                },
              }}
            />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
