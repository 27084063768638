import { Circle } from "../../components";
import { red, green } from "@material-ui/core/colors";
import { villes as v } from "../../constants/villes";

import { CustomLinkButton, CustomArchivingButton } from "../../components";

export const route = "terrain";
export const routeTable = "terrains";
export const nameTable = "Terrains";

export async function getShortlists(setOptions) {
  let villes = v.map((v) => {
    return { label: v.column_2, value: v.column_2, cp: v.column_1 };
  });
  setOptions({
    address_city: villes,
  });
}

export const struct = (data) => {
  return {
    name: {
      label: "Nom du terrain",
      type: "text",

      value: data?.name,
    },
    address_city: {
      label: "Ville",
      type: "autocomplete",
      value: {
        label: data?.address_city,
        value: data?.address_city,
      },
      onChange: (value, data, setData) => {
        setData({
          ...data,
          address_city: {
            ...data["address_city"],
            value: value,
          },
          address_postal_code: {
            ...data["address_postal_code"],
            value: value?.cp ? value.cp : null,
          },
        });
      },
    },
    address_street: {
      label: "Rue",
      type: "text",
      value: data?.address_street,
    },
    address_number: {
      label: "Numéro",
      type: "text",
      value: data?.address_number,
    },
    address_postal_code: {
      label: "Code Postal",
      type: "number",
      value: data?.address_postal_code,
    },
    phone: {
      label: "Numéro de téléphone",
      type: "text",
      value: data?.phone,
    },
    in_use: {
      label: "Fréquenté",
      type: "checkbox",
      checked: Boolean(data?.in_use),
    },
    synthetic: {
      label: "Synthétique",
      type: "checkbox",
      checked: Boolean(data?.synthetic),
    },
    courts_number: {
      type: "number",
      label: "Nb. de terrains",
      value: data?.courts_number,
    },
    cloakroom: {
      label: "Vestiaire",
      value: data?.cloakroom,
      type: "text",
    },
    teams: data?.teams,
  };
};

export const items = [
  ["name", "in_use", "cloakroom", "synthetic", "courts_number"],
  [
    "address_city",
    "address_street",
    "address_number",
    "address_postal_code",
    "phone",
  ],
  ["teams"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "in_use",
    label: "Statut",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nom du terrain",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "address",
    label: "Adresse",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "phone",
    label: "Numéro de téléphone",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "synthetic",
    label: "Type de terrain",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "nb_teams",
    label: "Nb. de teams",
    options: {
      filter: true,
      sort: true,
    },
  },
    {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const structTable = (el) => {
    if (el?.id) {
  let address_street = el?.address_street ? el?.address_street + " " : "";
  let address_number = el?.address_number ? el?.address_number + " " : " ";
  let address_city = el?.address_city ? el?.address_city + " " : " ";
  let address_postal_code =
    el?.address_postal_code + " " ? el?.address_postal_code + " " : " ";
  let address =
    address_street + address_number + address_city + address_postal_code;
  let nb_teams = el?.teams?.length;
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archive",
      in_use: el.in_use ? "Fréquenté" : "Disponible",
      name: el.name,
      address: address,
      phone: el.phone,
      synthetic: el.synthetic ? "Synthétique" : "Naturel",
      nb_teams: nb_teams ? nb_teams : 0,
    };
  }
};
