import React from "react";
import styled from "@emotion/styled";
import { Droppable, Draggable } from "react-beautiful-dnd";
import EquipeItem from "./equipe-item";

/*const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return colors.R50;
  }
  if (isDraggingFrom) {
    return colors.T50;
  }
  return colors.N30;
};*/

/*background-color: ${(props) =>
    getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};*/

const Wrapper = styled.div`
  display: flex;
  background-color: #eee;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: 7px;
  border: 7px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 250px;
`;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: 250px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 7px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

class InnerEquipeList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.equipes !== this.props.equipes) {
      return true;
    }

    return false;
  }

  render() {
    const { equipes, index, handleSubmit, handleSubmitPlayTime, title } = this.props;
    let _index = index;
    return equipes
      .sort((a, b) =>
        Number(a.serial_num) > Number(b.serial_num)
          ? 1
          : Number(b.serial_num) > Number(a.serial_num)
          ? -1
          : 0
      )
      .map((equipe, index) => (
        <Draggable
          key={equipe.id}
          draggableId={equipe.id}
          index={index}
          shouldRespectForceTouch={false}
        >
          {(dragProvided, dragSnapshot) => {
            let error = false;
            if (
              Number(equipes[index]?.serial_num) ===
                Number(equipes[index + 1]?.serial_num) ||
              Number(equipes[index - 1]?.serial_num) ===
                Number(equipes[index]?.serial_num)
            ) {
              error = true;
            }
            return (
              <EquipeItem
                key={equipe.id}
                equipe={equipe}
                title={title}
                handleSubmit={handleSubmit}
                index={_index}
                error={error}
                isDragging={dragSnapshot.isDragging}
                isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                provided={dragProvided}
                handleSubmitPlayTime={handleSubmitPlayTime}
              />
            );
          }}
        </Draggable>
      ));
  }
}

function InnerList(props) {
  const {
    equipes,
    dropProvided,
    index,
    handleSubmit,
    handleSubmitPlayTime,
    title
  } = props;

  return (
    <Container>
      <DropZone ref={dropProvided.innerRef}>
        <InnerEquipeList
          equipes={equipes}
          index={index}
          handleSubmit={handleSubmit}
          title={title}
          handleSubmitPlayTime={handleSubmitPlayTime}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

export default function EquipeList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId,
    listType,
    style,
    equipes,
    title,
    index,
    handleSubmit,
    handleSubmitPlayTime,
  } = props;

  return (
    <Droppable
      droppableId={listId ? listId : "LIST"}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer style={scrollContainerStyle}>
              <InnerList
                equipes={equipes}
                title={title}
                dropProvided={dropProvided}
                index={index}
                handleSubmit={handleSubmit}
                handleSubmitPlayTime={handleSubmitPlayTime}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              equipes={equipes}
              title={title}
              dropProvided={dropProvided}
              index={index}
              handleSubmit={handleSubmit}
              handleSubmitPlayTime={handleSubmitPlayTime}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  );
}
