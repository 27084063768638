import React, { Component } from "react";
import { Link, withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";

import * as ROUTES from "../../constants/routes";

import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import * as Icon from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import toast from 'react-hot-toast';

import { SignInLink } from "../SignIn";

import axios from "axios";
import { AddCircle } from "@material-ui/icons";

function SignUpPage(props) {
  return (
    <div>
      <SignUpForm {...props} />
    </div>
  );
}

const INITIAL_STATE = {
  name: "",
  email: "",
  password: "",
  passwordConf: "",
  error: null,
};

const styles = (theme) => ({
  paper: {
    margin: "4em auto 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3em",
    maxWidth: 500,
    borderRadius: "1em",
  },
  avatar: {
    margin: "1em",
  },
  form: {
    width: "100%",
    marginTop: "1.5em",
  },
  submit: {
    margin: "1em 0 1em",
  },
});

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = (event) => {
    event.preventDefault();
    const { history } = this.props;
    const { name, email, password, passwordConf } = this.state;
    let bodyFormData = new FormData();
    bodyFormData.set("name", name);
    bodyFormData.set("email", email);
    bodyFormData.set("password", password);
    bodyFormData.set("confirm_password", passwordConf);
    axios.post(process.env.REACT_APP_API_URL + "register", bodyFormData).then(
      (response) => {
        //localStorage.setItem("api_token", response.data.api_token);
        if (response.data.api_token) {
          //history.push(ROUTES.MEMBRES);
          toast.success('Nouvel utilisateur enregistré avec succès !');
        } else {
          toast.error(`Problème lors de l'ajout de l'utilisateur...`);
        }
      },
      (e) => {
        let errors = e?.response?.data?.errors
          ? e.response.data.errors
          : e?.response?.data;
        if (errors) {
          Object.keys(errors).map((e, i, arr) =>
            toast.error(errors[e].toString())
          );
        }
      }
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
  };
  render() {
    const { name, email, password, passwordConf } = this.state;
    const isInvalid =
      password !== passwordConf ||
      password === "" ||
      email === "" ||
      name === "";
    const { classes } = this.props;
    return localStorage.getItem("api_token") ? (
      <Container component="main">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Icon.PersonAdd />
          </Avatar>
          <Typography component="h1" variant="h5">
            Nouvel utilisateur
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Nom"
                  name="name"
                  value={name}
                  onChange={this.handleChange}
                  autoComplete="name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Adresse email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  label="Mot de passe"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="passwordConf"
                  value={passwordConf}
                  onChange={this.handleChange}
                  label="Confirmation du mot de passe"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isInvalid}
              onClick={this.onSubmit}
            >
              Ajouter
            </Button>
          </form>
        </Paper>
      </Container>
    ) : (
      <Redirect to={ROUTES.HOME} />
    );
  }
}

const SignUpForm = compose(withRouter, withStyles(styles))(SignUpFormBase);

export default SignUpPage;

export { SignUpForm };
