/*
Pouvoir définir "admin true/false", "lecture : 1", "écriture : 2", "suppression : 3" (archivage)
Et le type d'accès : "Listing : 1 ", "Fiche : 2"
*/

const access_view = [
  {
    label: "Masquer",
    value: 0
  },
  {
    label : "Listing",
    value : 1
  },
  {
    label : "Listing et fiche",
    value : 2
  }
];

const access_rights = [
  {
    label: "Lecture",
    value: 1
  },
  {
    label: "Lecture / Écriture",
    value: 2
  },
  {
    label: "Lecture / Écriture / Suppression",
    value: 3
  },
]


export const struct = (data) => {
  return {
    name: {
      name: "name",
      label: "Nom d'utilisateur",
      type: "text",

      value: data?.name,
    },
    email: {
      name: "email",
      value: data?.email,
      label: "Adresse email",
      type: "text",
    },
    admin: {
       name: "admin",
       checked: Boolean(data?.admin),
       label : "Administrateur",
       type: "checkbox"
    },
    access_membres_view : {
      name: "access_membres_view",
      label: "Membres - Accès",
      type: "select",
      values: access_view,
      value: data?.access_membres_view
    },
    access_membres_rights : {
      name: "access_membres_rights",
      label: "Membres - Droits",
      type: "select",
      values: access_rights,
      disabled: data?.access_membres_view?.value === 0 ? true : false,
      value: data?.access_membres_rights
    },
    access_affiliations_view : {
      name: "access_affiliations_view",
      label: "Affiliations - Accès",
      type: "select",
      values: access_view,
      value: data?.access_affiliations_view
    },
    access_affiliations_rights : {
      name: "access_affiliations_rights",
      label: "Affiliations - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_affiliations_rights
    },
    access_equipes_view : {
      name: "access_equipes_view",
      label: "Équipes - Accès",
      type: "select",
      values: access_view,
      value: data?.access_equipes_view
    },
    access_equipes_rights : {
      name: "access_affiliations_rights",
      label: "Équipes - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_equipes_rights
    },
    access_teams_view : {
      name: "access_teams_view",
      label: "Teams - Accès",
      type: "select",
      values: access_view,
      value: data?.access_teams_view
    },
    access_teams_rights : {
      name: "access_teams_rights",
      label: "Teams - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_teams_rights
    },
    access_clubs_view : {
      name: "access_clubs_view",
      label: "Clubs - Accès",
      type: "select",
      values: access_view,
      value: data?.access_clubs_view
    },
    access_clubs_rights : {
      name: "access_clubs_rights",
      label: "Clubs - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_clubs_rights
    },
    access_terrains_view : {
      name: "access_terrains_view",
      label: "Terrains - Accès",
      type: "select",
      values: access_view,
      value: data?.access_terrains_view
    },
    access_terrains_rights : {
      name: "access_terrains_rights",
      label: "Terrains - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_terrains_rights
    },
    access_rencontres_view : {
      name: "access_rencontres_view",
      label: "Rencontres - Accès",
      type: "select",
      values: access_view,
      value: data?.access_rencontres_view
    },
    access_rencontres_rights : {
      name: "access_rencontres_rights",
      label: "Rencontres - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_rencontres_rights
    },
    access_sanctions_view : {
      name: "access_sanctions_view",
      label: "Sanctions - Accès",
      type: "select",
      values: access_view,
      value: data?.access_sanctions_view
    },
    access_sanctions_rights : {
      name: "access_sanctions_rights",
      label: "Sanctions - Droits",
      type: "select",
      values: access_rights,
      value: data?.access_sanctions_rights
    },
    new_password: {
      name: "new_password",
      label : "Nouveau mot de passe",
      type: "password",
      value: "",
            onChange: (e, data, setData, helperText, setHelperText) => {
        let value = e?.target?.value;
        if(value !== data?.repeat_new_password?.value) {
        setHelperText({
              ...helperText,
              repeat_new_password: "Les 2 mots de passe sont différents",
          });
        } else {
          setHelperText({
              ...helperText,
              repeat_new_password: undefined
          });
        }
          setData({
            ...data,
            new_password: {
              ...data["new_password"],
              value: value,
            },
          });
        }
    },
    repeat_new_password: {
      name: "repeat_new_password",
      label : "Répéter le mot de passe",
      type:"password",
      value: "",
                  onChange: (e, data, setData, helperText, setHelperText) => {
        let value = e?.target?.value;
        if(value !== data?.new_password?.value) {
        setHelperText({
              ...helperText,
              repeat_new_password: "Les 2 mots de passe sont différents",
          });
        } else {
          setHelperText({
              ...helperText,
              repeat_new_password: undefined
          });
        }
          setData({
            ...data,
            repeat_new_password: {
              ...data["repeat_new_password"],
              value: value,
            },
          });
        }
    }
  };
};

export const items = [["name", "email", "admin"], ["new_password", "repeat_new_password"], ["access_membres_view", "access_membres_rights", "access_affiliations_view", "access_affiliations_rights", "access_equipes_view", "access_equipes_rights", "access_teams_view","access_teams_rights", "access_clubs_view", "access_clubs_rights", "access_terrains_view", "access_terrains_rights", "access_rencontres_view", "access_rencontres_rights", "access_sanctions_view", "access_sanctions_rights"]];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: true,
      filter: false,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Nom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "email",
    label: "Adresse email",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "admin",
    label: "Administrateur",
    options: {
      filter:true,
      sort:true
    }
  }
];

export const structTable = (el) => {
  if (el?.id)
    return {
      id: el.id,
      email: el.email,
      admin: el.admin ? "✅" : "❌",
      archived: el.archived === 0 ? "Actif" : "Archive",
      name: el.name,
    };
};

export const route = "user";
export const routeTable = "users";
export const nameTable = "Utilisateurs";
