import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useStyles,
  getMuiTheme,
  CardHeader,
  formatDate,
  SaveButton,
  TextMaskCustom,
  AlertDialog
} from "../../components";
import toast from "react-hot-toast";
import history from "../../history";
import * as ROUTES from "../../constants/routes";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { MuiThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import fr from "date-fns/locale/fr";
import {
  TableRow,
  TableFooter,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  IconButton,
  TableCell
} from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import MUIDataTable from "mui-datatables";
import * as Icon from "@material-ui/icons";

/*
function CustomBodyRenderTeam(props) {
  const { value, tableMeta, updateValue, teams, setRows, rows } = props;
  const OPTIONS_LIMIT = 40;

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  var isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
        <Autocomplete
        filterOptions={filterOptions}
        options={teams}
        size={"small"}
        //autoComplete="new-password"
        //className={classes.input}
        getOptionLabel={(option) => (option?.label  || "")}
        getOptionSelected={(option, value) => option?.label === value?.label}
        value={value ?? null}
        onChange={(e, value) => {
          updateValue(value);
          let _rows = rows;
            let i = tableMeta.rowIndex;
            let j = tableMeta.columnData?.name;
            _rows[i][j] = value;
          setRows(_rows);
        }}
        renderOption={(option) => (option?.label || "")}
        renderInput={(params) => {
          const inputProps = params.inputProps;
          inputProps.autoComplete = "off";
          return(
            <TextField
              {...params}
              name={"new_equipe"}
              variant="outlined"
              style={{ minWidth: 250}}
              label={"Nom de l'équipe"}
              autoComplete="new-password"
              inputProps={inputProps}
            />
        )}}
      />
    </MuiThemeProvider>
  );
}
*/

/*
export function CustomBodyRenderEdit(props) {
  const {setEdit, edit, rows, setRows, tableMeta} = props;
  return (
    <IconButton
        onClick={(e) => {
          e.stopPropagation();
            let _rows = rows;
            let i = tableMeta.rowIndex;
            if(!edit) {
              _rows[i]["team_1_id"] = null;
              _rows[i]["team_2_id"] = null;
            } else {
              _rows[i]["team_1_name"] = "";
              _rows[i]["team_2_name"] = "";
            }
            setEdit(!edit);
          }}
      >
        {!edit ? <Icon.Edit fontSize={"small"} /> : <Icon.List fontSize={"small"} />}
      </IconButton>
  )
}
*/

/*
function CustomBodyRenderTeamText(props) {
    const { value, tableMeta, updateValue, teams, setRows, rows } = props;
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
        <TextField
          name={"new_equipe"}
          value={value}
          onChange={(e) => {
            let value= e?.target?.value;
            value = value.toUpperCase();
            updateValue(value);
          let _rows = rows;
            let i = tableMeta.rowIndex;
            let j = tableMeta.columnData?.name;
            _rows[i][j] = value;
            console.log(_rows);
            setRows(_rows);
          }}
          autoComplete="new-password"
          variant="outlined"
          size="small"
          style={{minWidth:250}}
          label={"Nom de l'équipe"}
          />
          </MuiThemeProvider>
          );
}
*/

function CustomBodyRenderTerrain(props) {
  const { value, tableMeta, updateValue, terrains, setRows, rows } = props;
  const OPTIONS_LIMIT = 40;

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };
  var isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Autocomplete
        filterOptions={filterOptions}
        options={terrains}
        size={"small"}
        //className={classes.input}
        getOptionLabel={(option) => (option?.label ? option.label : "")}
        getOptionSelected={(option, value) => option?.label === value?.label}
        value={value}
        onChange={(e, value) => {
          updateValue(value);
          let _rows = rows;
          let i = tableMeta.rowIndex;
          let j = tableMeta?.columnData?.name;
          _rows[i][j] = value;
          setRows(_rows);
        }}
        renderOption={(option) => (option?.label ? option.label : "")}
        renderInput={(params) => {
         //const inputProps = params.inputProps;
         //inputProps.autoComplete = "off";
          return(
          <TextField
            {...params}
            name={"new_terrain"}
            variant="outlined"
            style={{ minWidth: 170 }}
            label={"Terrain"}
            //autoComplete="current-password" 
            //inputProps={inputProps}
          />
        )
      }}

      />
    </MuiThemeProvider>
  );
}

function CustomBodyRenderType(props) {
  const { updateValue, value, setRows, rows, tableMeta } = props;
  const types = [
    {
      label: "Championnat",
      value: "championnat",
    },
    {
      label: "Coupe",
      value: "coupe",
    },
    {
      label: "Amical",
      value: "amical",
    },
    {
      label: "Barrage",
      value: "barrage",
    },
  ];
  return (
    <FormControl variant="outlined" size={"small"}>
      <InputLabel id="label">Type</InputLabel>
      <Select
        size={"small"}
        style={{ minWidth: 170 }}
        labelId="label"
        label="Type"
        value={value}
        onChange={(e) => {
          updateValue(e.target.value);
          let _rows = rows;
          let i = tableMeta.rowIndex;
          let j = tableMeta?.columnData?.name;
          _rows[i][j] = e.target.value;
          setRows(_rows);
        }}
      >
        {types.map((item) => {
          return (
            <MenuItem key={item?.value} value={item?.value ?? ""}>
              {item?.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

function CustomBodyRenderTime(props) {
  const { updateValue, value, setRows, rows, tableMeta } = props;

  return (
    <FormControl
      size="small"
      style={{
        width: 100,
      }}
    >
      <OutlinedInput
        value={value}
        defaultValue={"00:00:00"}
        onChange={(e) => {
          updateValue(e.target.value);
          let _rows = rows;
          let i = tableMeta.rowIndex;
          let j = tableMeta?.columnData?.name;
          _rows[i][j] = e.target.value;
          setRows(_rows);
        }}
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
      />
    </FormControl>
  );
}

function CustomBodyRenderDate(props) {
  const { updateValue, value, setRows, rows, tableMeta } = props;
  return (
    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{
          width: 165,
        }}
        size="small"
        variant="inline"
        autoOk="true"
        helperText={""}
        inputVariant="outlined"
        format="dd/MM/yyyy"
        value={value}
        onChange={(e) => {
          updateValue(formatDate(e));
          let _rows = rows;
          let i = tableMeta.rowIndex;
          let j = tableMeta?.columnData?.name;
          _rows[i][j] = formatDate(e);
          setRows(_rows);
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

function generateRencontres(rencontres) {
  let _rencontres = rencontres;
  console.log(rencontres);
  for (let i in _rencontres) {
    if(_rencontres[i]?.team_1_name === ""){
      delete _rencontres[i]?.team_1_name;
    }
    if(_rencontres[i]?.team_2_name === ""){
      delete _rencontres[i]?.team_2_name;
    }
    if(!_rencontres[i]?.team_1_id){
      delete _rencontres[i]?.team_1_id;
    }
    if(!_rencontres[i]?.team_2_id){
      delete _rencontres[i]?.team_2_id;
    }
    _rencontres[i].terrain_id = _rencontres[i]?.terrain_id?.value
      ? _rencontres[i].terrain_id.value
      : null;
  }
  console.log(_rencontres);
  axios
    .post(
      process.env.REACT_APP_API_URL + "rencontres/generate",
      {
        data: _rencontres,
      },
      {
        headers: { authorization: localStorage.getItem("api_token") },
      }
    )
    .then((r) => {
      toast.success(r?.data, {
        duration: 4000,
      });
      localStorage.removeItem("rencontres_latest_update");
      history.push(ROUTES.RENCONTRES);
    })
    .catch((e) => {
      toast.error(e?.data, {
        duration: 4000,
      });
    });
}

export function CustomBodyRenderEquipe(props) {
  const { value, tableMeta, updateValue, equipeNum, rows, setRows } = props;
  const [edit, setEdit] = useState(false);
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState(null);
  const OPTIONS_LIMIT = 40;
  //let columnIndex = tableMeta?.columnIndex;

  const fetchTeams = () => {
    if(teams.length === 0) {
      axios
      .get(process.env.REACT_APP_API_URL + "teams", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        let _teams = [];
        for (let i in data) {
          _teams.push({
            label:
              data[i]?.equipe?.club?.name +
              " - " +
              data[i]?.equipe?.id +
              (data[i]?.index ? " - " + data[i]?.index : ""),
            value: data[i].id,
          });
        }
        setTeams(_teams);
      })
      .catch((e) => {
        console.log(e);
      });
    }
  }

  useEffect(() => {
    fetchTeams();
  }, []);

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  };

  const handleChangeEquipe = (value) => {
    let id = tableMeta?.rowIndex;
    let team = equipeNum === 1 ? "team_1" : "team_2";
    team = !edit ? team + "_id" : team + "_name";
    if (value) {
      let _rows = rows;
      _rows[id][team] = value;
      console.log(_rows);
      setRows(_rows);
    }
  };

  const clear = () => {
    let _rows = rows;
    let id = tableMeta?.rowIndex;
    let team = equipeNum === 1 ? "team_1" : "team_2";
    _rows[id][team + "_name"] =  "";
    _rows[id][team + "_id"] =  undefined;
    console.log(_rows);
    setRows(_rows);
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 300, display: "flex", alignItems:"center"}}>
        {!edit ?
        <Autocomplete
          filterOptions={filterOptions}
          options={teams}
          style={{ minWidth: 250}}
          size={"small"}
          //className={classes.input}
          getOptionLabel={(option) => (option?.label ? option.label : "")}
          getOptionSelected={(option, value) => option?.label === value?.label}
          value={
            newTeam
              ? newTeam
              : null
          }
          onChange={(e, value) => {
            handleChangeEquipe(value?.value);
            setNewTeam(value);
          }}
          renderOption={(option) => (option?.label ? option.label : "")}
          renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "off";
            return(<TextField
              {...params}
              name={"new_equipe"}
              variant="outlined"
              label={"Équipe #"+equipeNum}
              autoComplete="current-password"
              inputProps={inputProps}
            />);
          }}
        /> : 
        <TextField
              onChange={(e) => {
                let value = e?.target?.value;
                value = value ? value.toUpperCase() : value;
                handleChangeEquipe(value);
                setNewTeam(value);
              }}
              size={"small"}
              name={"new_equipe"}
              variant="outlined"
              style={{ minWidth: 250}}
              label={"Nom de l'équipe #"+equipeNum}
              value={newTeam}
            />
        }
            <IconButton
            style={{ margin: "0 15px"}}
            onClick={(e) => {
              setEdit(!edit);
              setNewTeam(null);
              clear();
            }}
      >
        {!edit ? <Icon.Edit fontSize={"small"} /> : <Icon.List fontSize={"small"} />}
      </IconButton>
      </div>
    </div>
  );
}

export default function CreateurRencontres(props) {
  const classes = useStyles();
  const rencontre = {
    id: 0,
    match_date: formatDate(new Date()),
    match_type: "coupe",
    terrain_id: null,
    equipe_1: null,
    equipe_2:null,
    team_1_id: null,
    team_2_id: null,
    team_1_name: "",
    team_2_name: ""
  };
  const [rows, setRows] = useState([rencontre]);
  const [edit, setEdit] = useState(false);
  const [teams, setTeams] = useState({});
  const [terrains, setTerrains] = useState({});
  useEffect(() => {
    let teams = [];
    axios
      .get(process.env.REACT_APP_API_URL + "teams", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        for (let i in data) {
          teams.push({
            label:
              data[i]?.equipe?.club?.name +
              " - " +
              data[i]?.equipe?.id +
              (data[i]?.index ? " - " + data[i]?.index : ""),
            value: data[i].id,
          });
        }
        setTeams(teams);
      })
      .catch((e) => {
        console.log(e);
      });
    let terrains = [];
    axios
      .get(process.env.REACT_APP_API_URL + "terrains" + "/shortlist", {
        headers: { authorization: localStorage.getItem("api_token") },
      })
      .then((r) => {
        let data = r.data;
        for (let i in data) {
          terrains.push({
            label: data[i].name,
            value: data[i].id,
          });
        }
        setTerrains(terrains);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  
  let columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "match_date",
      label: "Date de match",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CustomBodyRenderDate
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              rows={rows}
              setRows={setRows}
            />
          );
        },
      },
    },
    {
      name: "play_time",
      label: "Heure de match",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CustomBodyRenderTime
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              rows={rows}
              setRows={setRows}
            />
          );
        },
      },
    },
    {
      name: "match_type",
      label: "Type de rencontre",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CustomBodyRenderType
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              rows={rows}
              setRows={setRows}
            />
          );
        },
      },
    },
    {
      name: "terrain_id",
      label: "Terrain",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <CustomBodyRenderTerrain
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              terrains={terrains}
              rows={rows}
              setRows={setRows}
            />
          );
        },
      },
    },
    {
      name: "equipe_1",
      label: "Équipe à domicile",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
          <CustomBodyRenderEquipe
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            equipeNum={1}
            rows={rows}
            setRows={setRows}
          />
          );
        },
      },
    },
    {
      name: "equipe_2",
      label: "Équipe en déplacement",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
          <CustomBodyRenderEquipe
            value={value}
            tableMeta={tableMeta}
            updateValue={updateValue}
            equipeNum={2}
            rows={rows}
            setRows={setRows}
          />
          );
        },
      },
    },
  ];
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <div className={classes.container}>
        <CardHeader title={"Créateur de rencontres"} />
        <div className={classes.root}>
          <MUIDataTable
            title={"Rencontres"}
            data={rows}
            columns={columns}
            elevation={1}
            options={{
              elevation: 1,
              usePaperPlaceholder: false,
              filterType: "multiselect",
              responsive: "standard",
              rowsPerPage: 999,
              search: false,
              print: false,
              viewColumns: false,
              filter: false,
              download: false,
              selectableRows: "none",
              //selectableRows: "multiple",
              //filterList: filterList,
              componentWillReceiveProps: true,
              page: 0,
              /*onRowsDelete: (e, r) => {
                setRows(r);
              },*/
              customFooter: () => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "1em",
                        }}
                      >
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            disableElevation={true}
                            style={{
                              marginRight: ".75em",
                            }}
                            onClick={() => {
                              setRows([...rows, rencontre]);
                            }}
                          >
                            Ajouter
                          </Button>
                          <Button
                            onClick={() => {
                              setRows([]);
                            }}
                          >
                            Réinitialiser
                          </Button>
                        </div>
                        <div>
                          <SaveButton
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              generateRencontres(rows);
                            }}
                          >
                            Créer les rencontres
                          </SaveButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              textLabels: {
                body: {
                  toolTip: "Trier",
                  noMatch: "(aucune donnée)",
                  columnHeaderTooltip: (column) => `Trier par ${column.label}`,
                },
                toolbar: {
                  search: <span>Recherche</span>,
                  downloadCsv: <span>Télécharger au format CSV</span>,
                  print: <span>Imprimer</span>,
                  viewColumns: <span>Colonne(s) visible(s)</span>,
                  filterTable: <span>Filtre(s)</span>,
                },
                filter: {
                  all: "Tous",
                  title: "Filtre(s)",
                  reset: "Réinitialiser",
                },
                viewColumns: {
                  title: "Colonne(s) visible(s)",
                  titleAria: "Afficher/cacher colonnes de la table",
                },
                selectedRows: {
                  text: "ligne(s) sélectionnée(s)",
                  delete: "Archiver",
                  deleteAria: "Supprimer ligne(s) sélectionnée(s)",
                },
              },
            }}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
}