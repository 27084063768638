import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as ROUTES from "../../constants/routes";

import {  useStyles } from "../../theme";

import { struct, route, routeTable, items, getShortlists } from "./items";
import {
  structTable as structClub,
  columnsChild as columnsClub,
} from "../Clubs/items";
import {
  structTable as structTeams,
  columnsChild as columnsTeams,
} from "../Teams/items";

import {
  structTable as structAffiliations,
  columns as columnsAffiliations,
} from "../Affiliations/items";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
} from "../../components";
import Entities from "../Entities";
import qs from "qs";

export default function Equipe(props) {
  const classes = useStyles();
  const { state } = props?.location;
  const {userInfos} = props;

  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [clubName, setClubName] = useState(undefined);
  const [equipeName, setEquipeName] = useState(undefined);
  const [cat, setCat] = useState(undefined);
  const [loading, setLoading] = useState(true);

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  /*let club_id = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    .club_id;*/
  let club_name = qs.parse(props.location.search, {
    ignoreQueryPrefix:true
  }).club_name;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  useEffect(() => {
    if (!loading && data) {
      club_name = data?.club_id?.value?.label;
      setClubName(club_name);
      let cat = data?.category?.value;
      setCat(cat);
      let equipeName =  id + " " + club_name;
      setEquipeName(equipeName);
    }
  });
  return (
    <div className={classes.root}>
      <CardHeader
        
isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"equipe"}
        id={id}
        item={"équipe"}
        editTitle={"Modifier l'équipe"}
        addTitle={"Ajouter une équipe"}
        data={data}
        routeTable={routeTable}
        nextRoute={"new_team"}
        saveable={userInfos?.access_equipes_rights > 1 || userInfos?.admin}
      />
      {!loading && data && (
        <div className={classes.content}>
          <FormCards
            cardTitles={["Dénomination"]}
            data={data}
            items={items}
            editable={userInfos?.access_equipes_rights > 1 || userInfos?.admin}
            setData={setData}
            getShortlists={getShortlists}
          />
          <CardActions
            routeTable={routeTable}
            route={route}
            data={data}
            id={id}
            archivable={userInfos?.access_equipes_rights > 2 || userInfos?.admin}
isArchived={archived}
        setArchived={setArchived}
          />
      {data?.club && id && (
        <Entities
          isSub
          userInfos={userInfos}
          child_route={ROUTES.CLUB}
          disableAdd
          items={{
            routeTable: "clubs",
            nameTable: "Club associé",
            columns: columnsClub,
            structTable: structClub,
                        routeTable : 'clubs',

            data: data.club,
            state: {
              prevId: id,
              prevRoute: route,
            },
          }}
        />
      )}
      {data?.teams && id && (
        <Entities
          isSub
          userInfos={userInfos}
          child_route={ROUTES.TEAM}
          items={{
            nameTable: "Teams associées",
            columns: columnsTeams,
            structTable: structTeams,
            data: data.teams,
                        routeTable : 'teams',

            state: {
              prevId: id,
              prevRoute: route,
              routeParam: "?equipe_id=" + id + "&equipe_name=" + equipeName + "&club_name=" + clubName + "&cat="+ cat,
            },
          }}
        />
      )}
      {data?.affiliations && id && (
        <Entities
          isSub
          userInfos={userInfos}
          child_route={ROUTES.AFFILIATION}
          disableAdd
          items={{
            nameTable: "Affiliations associées",
                        routeTable : 'affiliations',
            columns: columnsAffiliations,
            routeTable: 'affiliations',
            structTable: structAffiliations,
            data: data.affiliations,
            state: {
              prevId: id,
              prevRoute: route,
            },
          }}
        />
      )}
    </div>
    )}
    </div>
  );
}
