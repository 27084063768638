import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as ROUTES from "../../constants/routes";

import { useStyles } from "../../theme";

import { struct, route, routeTable, items, getShortlists } from "./items";

import {
  columnsChild as columnsAffiliations,
  structTable as structAffiliations,
} from "../Affiliations/items";

import Loading from "../Loading";

import {
  FormCards,
  fetchData,
  CardActions,
  CardHeader,
} from "../../components";
import Entities from "../Entities";
import qs from "qs";

export default function Member(props) {
  const classes = useStyles();

  const { state } = props?.location;
const {userInfos} = props;

  const [data, setData] = useState(undefined);
  const [archived, setArchived] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [disableAdd, setDisableAdd] = useState(false);

  let id = qs.parse(props.location.search, { ignoreQueryPrefix: true }).id;
  useEffect(() => {
    fetchData(route, setData, struct, id, setLoading, setArchived);
  }, [id, setData, setLoading]);
  useEffect(() => {
    if (!loading) {
      let affiliations = data?.affiliations;
      let cpt_active = 0;
      for (let i in affiliations) {
        let affiliation = affiliations[i];
        if (affiliation?.status === "Actif") {
          cpt_active++;
        }
      }
      if (cpt_active >= 2) {
        setDisableAdd(true);
      }
    }
  });
  return (
    <div className={classes.root}>
      <CardHeader
        isArchived={archived}
        setArchived={setArchived}
        prevRoute={state?.prevRoute}
        prevId={state?.prevId}
        route={"membre"}
        id={id}
        item={"membre"}
        editTitle={"Modifier le membre"}
        addTitle={"Ajouter un membre"}
        data={data}
        routeTable={routeTable}
        nextRoute={"new_affiliation"}
        saveable={userInfos?.access_membres_rights > 1 || userInfos?.admin}
        disabled={
          !(
            data?.first_name?.value &&
            data?.name?.value &&
            data?.date_of_birth?.value &&
            (data?.national_register?.value || data?.foreign_member?.checked)
          )
        }
      />
      {!loading && data ? (
        <div className={classes.content}>
          <FormCards
            cardTitles={["Dénomination", "Adresse", "Coordonnées", "Photo"]}
            data={data}
            editable={userInfos?.access_membres_rights > 1 || userInfos?.admin}
            items={items}
            setData={setData}
            getShortlists={getShortlists}
          />
          <CardActions
            routeTable={routeTable}
            route={route}
            data={data}
            id={id}
            isArchived={archived}
            setArchived={setArchived}
            archivable={userInfos?.access_membres_rights > 2 || userInfos?.admin}
          />
          {data?.affiliations && (
            <Entities
              isSub
              userInfos={userInfos}
              child_route={ROUTES.AFFILIATION}
              disableAdd={disableAdd}
              items={{
                nameTable: "Affiliations associées",
                columns: columnsAffiliations,
                structTable: structAffiliations,
                data: data?.affiliations,
                routeTable: "affiliations",
                state: {
                  prevId: id,
                  prevRoute: route,
                  routeParam:
                    "?membre_id=" +
                    id +
                    "&first_name=" +
                    data?.first_name?.value +
                    "&name=" +
                    data?.name?.value,
                },
              }}
            />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
