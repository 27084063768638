import { villes as v } from "../../constants/villes";
import axios from "axios";
import toast from "react-hot-toast";
import history from "../../history";
import Button from "@material-ui/core/Button";
import { CustomLinkButton, CustomArchivingButton } from "../../components";

let referee_code = [
  { label: "Classe 1", value: "1" },
  { label: "Classe 1A", value: "1A" },
  { label: "Classe 1B", value: "1B" },
  { label: "Classe 2", value: "2" },
  { label: "Classe 3", value: "3" },
  { label: "Classe 4", value: "4" },
  { label: "Occasionnel", value: "OC" },
  { label: "Stagiaire", value: "ST" },
  { label: "Visionneur", value: "VI" },
];

export const routeTable = "membres";
export const route = "membre";
export const nameTable = "Membres";

export const defaultSort = {
  name: "register_date",
  direction: "desc",
};

//shortlist
export async function getShortlists(setOptions) {
  let codes_arbitres = [];
  await axios
    .get(process.env.REACT_APP_API_URL + "codes_arbitres" + "/shortlist", {
      headers: { authorization: localStorage.getItem("api_token") },
    })
    .then((r) => {
      let data = r.data;
      for (let i in data) {
        codes_arbitres.push({
          label: data[i].classe_arbitre,
          value: data[i].code_arbitre,
        });
      }
    })
    .catch((e) => {
      console.log(e);
    });
  let villes = v.map((v) => {
    return { label: v.column_2, value: v.column_2, cp: v.column_1 };
  });
  setOptions({
    address_city: villes,
    referee_code: codes_arbitres,
  });
  // End shortlist
}

export const struct = (data) => {
  return {
    photo: {
      label: "Photo du membre",
      type: "image",
      value: data?.photo,
    },
    name: {
      label: "Nom",
      type: "text",
      value: data?.name ? data.name : "",
      onChange: (e, data, setData) => {
        let value = e?.target?.value;
        setData({
          ...data,
          name: {
            ...data["name"],
            value: value.toUpperCase(),
          },
        });
      },
    },
    first_name: {
      label: "Prénom",
      type: "text",
      value: data?.first_name ? data.first_name : "",
    },
    national_register: {
      label: "Registre national",
      type: "text",
      value: data?.national_register ? data.national_register : "",
      onChange: (e, data, setData, helperText, setHelperText) => {
        let value = e?.target?.value;
        if (value?.length === 11) {
          let year = value.substring(0, 2);
          if (year[0] === "0") {
            year = "20" + year;
          } else {
            year = "19" + year;
          }
          let month = value.substring(2, 4);
          let day = value.substring(4, 6);
          let date = year + "-" + month + "-" + day;
          let check = 0;
          if (Number(year) < 2000) {
            check = Number(value.substring(0, 9)); // 9 premiers chiffres
          } else {
            check = Number("2" + value.substring(0, 9)); //"2" + 9 premiers chiffres
          }
          check = 97 - (check % 97);
          let check_num = Number(value.slice(-2)); // 2 derniers chiffres
          if (check !== check_num) {
            setHelperText({
              ...helperText,
              national_register: "Le numéro n'est pas valide",
            });
          }
          setData({
            ...data,
            date_of_birth: {
              ...data["date_of_birth"],
              value: new Date(date),
            },
            national_register: {
              ...data["national_register"],
              value: value,
            },
          });
          //Check db
          axios
            .get(process.env.REACT_APP_API_URL + "membre/reg_nat/" + value, {
              headers: { authorization: localStorage.getItem("api_token") },
            })
            .then((r) => {
              let data = r.data;
              if (!data?.success) {
                toast((t) => (
                  <div>
                    <p style={{ marginBottom: "1em" }}>
                      Le membre{" "}
                      <b>
                        {data?.name?.toUpperCase()} {data?.first_name}
                      </b>{" "}
                      existe déjà.
                      <br />
                      Souhaitez-vous consulter sa fiche membre ?
                    </p>
                    <Button
                      style={{ marginRight: ".5em" }}
                      onClick={() => {
                        let url = `/membre?id=${data?.id}`;
                        history.push(url);
                        toast.dismiss(t.id);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Voir fiche
                    </Button>
                    <Button
                      onClick={() => {
                        toast.dismiss(t.id);
                      }}
                      color="primary"
                    >
                      Annuler
                    </Button>
                  </div>
                ));
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
    },
    date_of_birth: {
      label: "Date de naissance",
      type: "date",

      value: data?.date_of_birth ? data.date_of_birth : null,
    },
    sex: {
      label: "Sexe",
      type: "select",
      value: data?.sex ?? "M",
      values: [
        {
          value: "M",
          label: "Masculin",
        },
        {
          value: "F",
          label: "Féminin",
        },
        {
          value: "X",
          label: "Non spécifié",
        },
      ],
    },
    register_date: {
      label: "Date d'inscription",
      type: "date",
      value: data?.register_date
        ? data.register_date
        : !data
        ? new Date()
        : null,
    },
    referee_code: {
      label: "Code arbitre",
      values: referee_code,
      type: "autocomplete",
      value: {
        label: data?.referee_code ? referee_code.find((x) => x.value === data?.referee_code).label : null,
        value: data?.referee_code ? data?.referee_code : null,
      },
    },
    medical_certificate: {
      label: "Certificat médical",
      type: "checkbox",
      checked: Boolean(data?.medical_certificate),
    },
    foreign_member: {
      label : "Membre étranger",
      type: "checkbox",
      checked: Boolean(data?.foreign_member)
    },
    address_city: {
      label: "Ville",
      type: "autocomplete",
      value: {
        label: data?.address_city,
        value: data?.address_city,
      },
      onChange: (value, data, setData) => {
        setData({
          ...data,
          address_city: {
            ...data["address_city"],
            value: value,
          },
          address_postal_code: {
            ...data["address_postal_code"],
            value: value?.cp ? value.cp : null,
          },
        });
      },
    },
    address_street: {
      label: "Rue",
      type: "text",

      value: data?.address_street,
    },
    address_number: {
      label: "Numéro",
      type: "text",
      value: data?.address_number,
    },
    address_postal_code: {
      label: "Code Postal",
      type: "number",

      value: data?.address_postal_code,
    },
    phone_mobile: {
      label: "Téléphone mobile",
      type: "text",

      value: data?.phone_mobile,
    },
    phone_fix: {
      label: "Téléphone fixe",
      type: "text",

      value: data?.phone_fix,
    },
    bank_account: {
      label: "Compte en banque",
      type: "text",

      value: data?.bank_account,
    },
    email: {
      label:'Adresse email',
      type:'text',
      value:data?.email,
    },
    affiliations: data?.affiliations,
  };
};

export const items = [
  [
    "name",
    "first_name",
    "national_register",
    "date_of_birth",
    "sex",
    "register_date",
    "referee_code",
    "medical_certificate",
    "foreign_member"
  ],
  ["address_city", "address_street", "address_number", "address_postal_code"],
  ["phone_mobile", "phone_fix", "bank_account", "email"],
  ["photo"],
  ["affiliations"],
];

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      display: false,
      filter: false,
      sort: false,
    },
  },
  {
    name: "archiving",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomArchivingButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  },
  {
    name: "archived",
    label: "Statut",
    options: {
      display: false,
      filter: true,
      sort: false,
      filterList: ["Actif"],
    },
  },
  {
    name: "national_register",
    label: "Registre national",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "all_name",
    label: "Nom",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "sex",
    label: "Sexe",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "register_date",
    label: "Date d'inscription",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "date_of_birth",
    label: "Date de naissance",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "referee_code",
    label: "Code arbitre",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "link",
    label : " ",
    options: {
      display:true,
      filter:false,
      sort:false,
      viewColumns:false,
      customBodyRender: (value, tableMeta) => {
        return(
          <CustomLinkButton
            value={value}
            tableMeta={tableMeta}
            route={route}
            routeTable={routeTable}
          />
        )
      }
    }
  }
];

export const structTable = (el) => {
  if (el?.id) {
    return {
      id: el.id,
      archived: el.archived === 0 ? "Actif" : "Archive",
      national_register: el.national_register,
      date_of_birth: el.date_of_birth,
      all_name:
        el.first_name && el.name
          ? el?.name.toUpperCase() + " " + el?.first_name
          : "",
      sex: el.sex ? el.sex.toUpperCase() : el.sex,
      register_date: el.register_date,
      referee_code: el.referee_code
        ? referee_code.find((x) => x.value === el.referee_code).label
        : "",
    };
  }
};
